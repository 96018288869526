import { FEATURE_FLAG_TYPES } from 'Webapp/action-types';
import GlobalVars from 'Utils/global-vars';
import logger from 'Utils/logger';
import isValueless from 'Utils/is-valueless';

import {
  SeoExperiments,
  isInSEOExperiment,
  SEO_EXPERIMENT_PRIMARY_TREATMENT_GROUP,
} from 'Webapp/shared/utils/seo-experiments';
import {
  AB_TESTS,
  AB_TESTS_PRIMARY_TREATMENT_GROUP,
} from 'Webapp/shared/utils/ab-tests';

import { isInABTest } from 'Webapp/shared/app/redux/selectors/ab-tests';

import { getActiveUserFeatureFlags } from 'Webapp/shared/concepts/user-features';

/**
 * Can add custom groups, USER_FEATURES and AB_TESTS are special cases. Anything
 * else will be used simply to "group" flags together in the UI, and allow for
 * toggling them on/off all at once.
 */

export enum FeatureFlagGroupTypes {
  USER_FEATURES = 'USER_FEATURES',
  AB_TESTS = 'AB_TESTS',
  DEV_FLAGS = 'DEV_FLAGS',
  VENETIA_DEC_13TH = 'VENETIA_DEC_13TH',
  VENETIA_MAR_14TH = 'VENETIA_MAR_14TH',
  VENETIA_MAY_11TH = 'VENETIA_MAY_11TH',
  VENETIA_FUTURE = 'VENETIA_FUTURE',
}

type FlagGroupType = { displayText: string };
export const FLAG_GROUPS: Record<FeatureFlagGroupTypes, FlagGroupType> = {
  [FeatureFlagGroupTypes.USER_FEATURES]: {
    displayText: '🦸 User Features',
  },
  [FeatureFlagGroupTypes.AB_TESTS]: {
    displayText: '🔬 AB Tests',
  },
  [FeatureFlagGroupTypes.DEV_FLAGS]: {
    displayText: '👨🏽‍💻 Dev Features',
  },
  [FeatureFlagGroupTypes.VENETIA_DEC_13TH]: {
    displayText: '☕ Venetia (Dec 13th)',
  },
  [FeatureFlagGroupTypes.VENETIA_MAR_14TH]: {
    displayText: '🍰 Venetia (Mar 14th)',
  },
  [FeatureFlagGroupTypes.VENETIA_MAY_11TH]: {
    displayText: '🥮 Venetia (May 11th)',
  },
  [FeatureFlagGroupTypes.VENETIA_FUTURE]: {
    displayText: '🍷  Venetia Ongoing',
  },
};

export type FlagType = {
  group: FeatureFlagGroupTypes;
  displayText: string;
  treatmentGroups?: Array<{ key: string; displayText: string }>;
};
/**
 * Prefix the values below with the flag group key above. The prefix will be used
 * to match the flag with the group, the rest of the string will be used as the
 * label for the flag in the UI.
 *
 * Example for Multi Treatment Experiments:
    displayText: 'Flip Compose Rework (Pre-Venetia Flow)',
    treatmentGroups: [
      { key: 'experiment_1', displayText: 'Pre-Venetia Flow' },
      { key: 'experiment_2', displayText: 'Single Pane Flow' },
    ],
 */
export const FLAG_MAPPINGS: Record<string, FlagType> = {
  // VENETIA FEATURES
  COMMENTS: {
    group: FeatureFlagGroupTypes.VENETIA_DEC_13TH,
    displayText: 'Comments',
  },
  MAGAZINE_COVER_COMMENTS: {
    group: FeatureFlagGroupTypes.VENETIA_MAY_11TH,
    displayText: 'Magazine Cover Comments',
  },
  STANDALONE_POST: {
    group: FeatureFlagGroupTypes.VENETIA_DEC_13TH,
    displayText: 'Standalone Post',
  },
  NEW_CREATE_MAGAZINE: {
    group: FeatureFlagGroupTypes.VENETIA_DEC_13TH,
    displayText: 'New Create Magazine',
  },
  NEW_FLIP_COMPOSE: {
    group: FeatureFlagGroupTypes.VENETIA_DEC_13TH,
    displayText: 'New Flip Compose',
  },
  INVITE_MAGAZINE_CONTRIBUTOR: {
    group: FeatureFlagGroupTypes.VENETIA_MAR_14TH,
    displayText: 'Invite Magazine Contributor',
  },
  NEW_MAGAZINE_COVER: {
    group: FeatureFlagGroupTypes.VENETIA_MAY_11TH,
    displayText: 'New Magazine Cover',
  },
  PINNED_FLIP: {
    group: FeatureFlagGroupTypes.VENETIA_MAR_14TH,
    displayText: 'Pinned Flip',
  },
  ITEM_METRIC_DETAIL: {
    group: FeatureFlagGroupTypes.VENETIA_DEC_13TH,
    displayText: 'Item Metric Details',
  },
  NOTIFICATIONS: {
    group: FeatureFlagGroupTypes.VENETIA_DEC_13TH,
    displayText: 'Notifications',
  },
  ADD_TO_MAGAZINE_BUTTON: {
    group: FeatureFlagGroupTypes.VENETIA_DEC_13TH,
    displayText: 'Add To Magazine Button',
  },
  PILL_REMOVAL: {
    group: FeatureFlagGroupTypes.VENETIA_MAY_11TH,
    displayText: 'Mention Pill Removal',
  },
  DISPLAY_COMMENT_BUBBLE: {
    group: FeatureFlagGroupTypes.VENETIA_FUTURE,
    displayText: 'Display Comment Bubble',
  },
  MAGAZINE_SUBSCRIPTIONS: {
    group: FeatureFlagGroupTypes.VENETIA_FUTURE,
    displayText: 'Magazine Subscriptions',
  },
  // DEV_FEATURES
  INCLUDE_JS_EXTERNALS: {
    group: FeatureFlagGroupTypes.DEV_FLAGS,
    displayText: 'Include JS Externals',
  },
  STORYBOARD_RECOMMEND: {
    group: FeatureFlagGroupTypes.DEV_FLAGS,
    displayText: 'Storyboard Recommend',
  },
  TOPIC_VERTICAL_SUBSECTIONS: {
    group: FeatureFlagGroupTypes.DEV_FLAGS,
    displayText: 'Topic Vertical Subsections',
  },
  FORCE_GDPR_APPLIES: {
    group: FeatureFlagGroupTypes.DEV_FLAGS,
    displayText: 'Force GDPR Applies',
  },
  FORCE_USNAT_APPLIES: {
    group: FeatureFlagGroupTypes.DEV_FLAGS,
    displayText: 'Force USNAT Applies',
  },
  WIDGET_TOOL: {
    group: FeatureFlagGroupTypes.DEV_FLAGS,
    displayText: 'Widget Generator Tool',
  },
  WIDGET_TOOL_INLINE: {
    group: FeatureFlagGroupTypes.DEV_FLAGS,
    displayText: 'Show Widget Tool Inline',
  },
  // AB_TESTS
  POST_ONBOARDING_FAVORITES: {
    group: FeatureFlagGroupTypes.AB_TESTS,
    displayText: 'Post Onboarding Favorites',
  },
  UNVERIFIED_MESSAGING: {
    group: FeatureFlagGroupTypes.AB_TESTS,
    displayText: 'Unverified Messaging',
  },
  TOPIC_PICKER_CTA: {
    group: FeatureFlagGroupTypes.AB_TESTS,
    displayText: 'Topic Picker CTA',
  },
  PRIVACY_CHANGE_NOTICE: {
    group: FeatureFlagGroupTypes.AB_TESTS,
    displayText: 'Privacy Change Notice',
  },
  FLIP_WIZARD_REWORK: {
    group: FeatureFlagGroupTypes.AB_TESTS,
    displayText: 'Flip Compose Single Pane',
  },
  // USER_FEATURES
  PACKAGE_MANAGEMENT: {
    group: FeatureFlagGroupTypes.USER_FEATURES,
    displayText: 'Package Management',
  },
  INTERNAL_PACKAGE_MANAGEMENT: {
    group: FeatureFlagGroupTypes.USER_FEATURES,
    displayText: 'Internal Package Management',
  },
  FAST_FLIP: {
    group: FeatureFlagGroupTypes.USER_FEATURES,
    displayText: 'Fast Flip',
  },
  // seoExperiments
};

const getKnownFlags = () =>
  Object.entries(FLAG_MAPPINGS).reduce((acc, [key, value]) => {
    acc.push(key);
    if (value.treatmentGroups) {
      value.treatmentGroups.forEach((treatment) => {
        acc.push(`${key}_${treatment.key}`);
      });
    }
    return acc;
  }, [] as Array<string>);

export type FlagKey = keyof typeof FLAG_MAPPINGS;

const flagState = (activeFlags: Array<FlagKey> = []) =>
  getKnownFlags().reduce((flags, flag) => {
    flags[flag] = activeFlags.includes(flag as FlagKey);
    return flags;
  }, {} as Record<FlagKey, boolean>);

const initialState = flagState();
export const reducer = (
  state = initialState,
  action: { type: FEATURE_FLAG_TYPES; flags: Array<FlagKey> },
) => {
  if (action.type === FEATURE_FLAG_TYPES.SET) {
    return flagState(action.flags);
  }
  return state;
};

// actions
export const setActiveFeatureFlags = (flags: FlagKey[]) => {
  const cleanedUpFlags = flags.filter((f) => !isValueless(f));
  if (GlobalVars.isDevelopment) {
    const knownFlags = getKnownFlags();
    const unknownFlags = cleanedUpFlags.filter(
      (flag) => !knownFlags.find((kf) => flag === kf || flag.startsWith(kf)),
    );
    if (unknownFlags.length > 0) {
      logger.error(
        `You tried to set a feature flag that is unknown (${unknownFlags})! Incoming flags: ${cleanedUpFlags} | Known Flags: ${knownFlags}`,
      );
    }
  }
  return {
    type: FEATURE_FLAG_TYPES.SET,
    flags: cleanedUpFlags,
  };
};

export const setUserFeatureFlags =
  (): Flipboard.Thunk => (dispatch, getState) =>
    dispatch(
      setActiveFeatureFlags([
        ...getActiveFeatureFlags(getState()),
        ...getActiveUserFeatureFlags(getState()),
      ]),
    );

const getMultiTreatmentExperimentGroups = (groups) => {
  const multiTreatmentPattern = new RegExp(
    `^${AB_TESTS_PRIMARY_TREATMENT_GROUP}_[^_]+$`,
  );
  return Object.keys(groups).filter((key) => multiTreatmentPattern.test(key));
};

export const setABTestFeatureFlags =
  (): Flipboard.Thunk => (dispatch, getState) => {
    let activeFeatureFlags = getActiveFeatureFlags(getState());
    const activeABTestFlags = Object.keys(AB_TESTS).reduce((acc, key) => {
      const { id, groups, loggedOutOnly } =
        AB_TESTS[key as keyof typeof AB_TESTS];
      const multiTreatmentExperimentGroups =
        getMultiTreatmentExperimentGroups(groups);
      if (
        isInABTest(
          id,
          groups[AB_TESTS_PRIMARY_TREATMENT_GROUP],
          loggedOutOnly,
        )(getState())
      ) {
        acc.push(key);
      } else if (multiTreatmentExperimentGroups.length > 0) {
        /**
         * Remove any "active" flags matching this group, as we'll re-add them according
         * to the experiment group they're in.
         */
        activeFeatureFlags = activeFeatureFlags.filter(
          (aff) => !aff.startsWith(key),
        );
        multiTreatmentExperimentGroups.forEach((experimentGroup) => {
          const experimentGroupKey = `${key}_${experimentGroup}`;
          if (
            isInABTest(id, groups[experimentGroup], loggedOutOnly)(getState())
          ) {
            acc.push(key);
            acc.push(experimentGroupKey);
          }
        });
      }
      return acc;
    }, [] as Array<string>);

    dispatch(
      setActiveFeatureFlags([...activeFeatureFlags, ...activeABTestFlags]),
    );
  };
export const getFeatureFlagsByGroup = (groupType: FeatureFlagGroupTypes) =>
  Object.entries(FLAG_MAPPINGS)
    .filter(([_, value]) => value.group === groupType)
    .map(([key, _]) => key) as FlagKey[];

// selectors

const getActiveFeatureFlags = (state: Flipboard.State) => {
  const { featureFlags } = state;
  return Object.keys(featureFlags).reduce((acc, flag) => {
    if (featureFlags[flag]) {
      acc.push(flag);
    }
    return acc;
  }, [] as Array<string>);
};

export const getActiveSEOExperimentFeatureFlags = (state: Flipboard.State) =>
  Object.keys(SeoExperiments).filter((key) =>
    isInSEOExperiment(
      key as SeoExperiments,
      SEO_EXPERIMENT_PRIMARY_TREATMENT_GROUP,
      state.app.seoExperiments,
    ),
  );
