import { Connector } from 'Webapp/utils/connector';
import {
  startOnboardingFlow,
  continueOnboardingFlow,
  setCurrentOnboardingStep,
  getCurrentOnboardingStep,
  getCurrentOnboardingFlow,
  onboardingPendingSelector,
  onboardingDataSelector,
} from 'Webapp/shared/concepts/onboarding';

const selectors = {
  onboardingFlow: getCurrentOnboardingFlow,
  currentOnboardingStep: getCurrentOnboardingStep,
  onboardingPending: onboardingPendingSelector,
  onboardingData: onboardingDataSelector,
};
const actions = {
  startOnboardingFlow,
  continueOnboardingFlow,
  setCurrentOnboardingStep,
  nextOnboardingStep: (): Flipboard.Thunk => (dispatch, getState) => {
    const currentOnboardingStep = getCurrentOnboardingStep(getState());
    const newOnboardingStep =
      currentOnboardingStep === null ? 0 : currentOnboardingStep + 1;
    dispatch(setCurrentOnboardingStep(newOnboardingStep));
  },
  runOnboardingFlowStep:
    (step: Flipboard.Thunk): Flipboard.Thunk =>
    (dispatch) => {
      dispatch(step);
    },
};

export type ConnectOnboardingFlowProps = Flipboard.ConnectorProps<
  typeof selectors,
  typeof actions
>;

const connectOnboardingFlow: Connector = {
  selectors,
  actions,
};

export default connectOnboardingFlow;
