import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

import onboardingTopicPicker from 'Webapp/shared/app/components/onboarding-flows/steps/onboarding-topic-picker';
import { frictionlessUserHasInsufficientFollowsSelector } from 'Webapp/shared/concepts/onboarding';

const postVerificationOnboardingTopicPicker: OnboardingStep =
  (nextStep) => async (dispatch, getState) => {
    const frictionlessUserHasInsufficientFollows =
      frictionlessUserHasInsufficientFollowsSelector(getState());
    if (frictionlessUserHasInsufficientFollows) {
      dispatch(onboardingTopicPicker(nextStep));
    } else {
      nextStep();
    }
  };

export default postVerificationOnboardingTopicPicker;
