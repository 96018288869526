import { CURATOR_PRO_STORYBOARD_TYPES } from 'Webapp/action-types';
import StoryboardSkeletonUtil from 'Utils/content/storyboard-skeleton-util';

interface StoryboardsReducerState {
  entries: Array<unknown>;
  nextPageKey: null | Flipboard.NextPageKey;
  draftsNextPageKey: null | Flipboard.NextPageKey;
  draftEntries: Array<{ id: Flipboard.SectionId }>;
  isLoading: boolean;
  isLoadingDrafts: boolean;
}

const initialState: StoryboardsReducerState = {
  entries: [],
  nextPageKey: null,
  isLoading: false,
  draftEntries: [],
  draftsNextPageKey: null,
  isLoadingDrafts: false,
};

export default function storyboardsReducer(state = initialState, action) {
  switch (action.type) {
    case CURATOR_PRO_STORYBOARD_TYPES.GET_CURATOR_PRO_STORYBOARDS: {
      return Object.assign({}, state, { isLoading: true });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.GET_CURATOR_PRO_STORYBOARDS_SUCCESS: {
      const { storyboards, nextPageKey, append } = action;
      const projections = storyboards.map((item) =>
        StoryboardSkeletonUtil.curatorProProjection(item),
      );
      const updatedEntries = append
        ? state.entries.concat(projections)
        : projections;

      return Object.assign({}, state, {
        entries: updatedEntries,
        nextPageKey,
        isLoading: false,
      });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.GET_CURATOR_PRO_STORYBOARDS_FAILED: {
      return Object.assign({}, state, { isLoading: false });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.GET_CURATOR_PRO_DRAFT_STORYBOARDS: {
      return Object.assign({}, state, { isLoadingDrafts: true });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.GET_CURATOR_PRO_DRAFT_STORYBOARDS_SUCCESS: {
      const { storyboards, schedules, nextPageKey, append } = action;
      const projections = storyboards.map((item) => {
        const schedule = schedules[item.id];
        return StoryboardSkeletonUtil.curatorProProjection(
          item,
          schedule && schedule.publishAt,
        );
      });
      const updatedEntries = append
        ? state.draftEntries.concat(projections)
        : projections;

      return Object.assign({}, state, {
        draftEntries: updatedEntries,
        draftsNextPageKey: nextPageKey,
        isLoadingDrafts: false,
      });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.GET_CURATOR_PRO_DRAFT_STORYBOARDS_FAILED: {
      return Object.assign({}, state, { isLoadingDrafts: false });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.PUBLISH_CURATOR_PRO_STORYBOARD_SUCCESS: {
      return Object.assign({}, state, {
        draftEntries: state.draftEntries.filter(
          (e) => e.id !== action.draftStoryboardId,
        ),
      });
    }
    default:
      return state;
  }
}
