import { USAGE_TYPES } from 'Webapp/action-types';
import { USAGE_PROD_TYPES } from 'Utils/analytics/usage';
import { merger } from 'Utils/redux';

enum EVENT_NAMES {
  ITEM_FLIP = 'itemFlip',
  ITEM_LIKE = 'itemLike',
  ITEM_UNLIKE = 'itemUnlike',
  ITEM_SHARE = 'itemShare',
  ITEM_ENTER = 'itemEnter',
  SECTION_FLIP = 'sectionFlip',
  SECTION_ENTER = 'sectionEnter',
  CURATOR_PRO_ENTER = 'curatorProEnter',
  CURATOR_PRO_MAGAZINE_INVITE_CONTRIBUTOR = 'curatorProMagazineInviteContributor',
  ANALYTICS_ENTER = 'analyticsEnter',
  SECTION_HEARTBEAT = 'sectionHeartbeat',
  TAP_SECTION_SUBSCRIBE = 'tapSectionSubscribe',
  SECTION_SUBSCRIBE = 'sectionSubscribe',
  SECTION_UNSUBSCRIBE = 'sectionUnsubscribe',
  TAP_MAGAZINE_CREATE = 'tapMagazineCreate',
  MAGAZINE_CREATE = 'magazineCreate',
  DISPLAY_MOBILE_GATE = 'displayMobileGate',
  OPEN_IN_APP = 'openInApp',
  TAP_FLIP = 'tapFlip',
  URL_FLIP = 'urlFlip',
  BANNER_ENTER = 'bannerEnter',
}

type EVENT_NAMES_TYPE = `${EVENT_NAMES}`;
interface UsageReducerState {
  session: null | string;
  navFrom: Record<EVENT_NAMES_TYPE, null | string>;
  navFromSection: null | Flipboard.Section;
  mobileGateSection: null | Flipboard.Section;
  mobileGateArticle: null | Flipboard.Item;
  isIntegration: boolean;
  uid: Flipboard.UserId;
  appVersion: null | string;
  locale: string;
  prodType: typeof USAGE_PROD_TYPES[keyof typeof USAGE_PROD_TYPES];
  enableExperiments: boolean;
  abTests: null;
  seoExperiments: null;
  flurl: null | string;
  referrer: null | string;
  eventQueue: Array<unknown>;
  trackingEnabled: boolean;
  sessionRequest: null;
  sectionHeartbeatTimerId: null;
  sectionHeartbeat: null;
  sectionDisplayedItems: Record<string, unknown>;
  // only to be used in development
  developmentEventLog: Array<unknown>;
}

const initialState: UsageReducerState = {
  session: null,
  navFrom: Object.keys(EVENT_NAMES).reduce((collect, current) => {
    collect[EVENT_NAMES[current]] = null;
    return collect;
  }, {} as UsageReducerState['navFrom']),
  navFromSection: null,
  // Mobile gate tracking
  mobileGateSection: null,
  mobileGateArticle: null,
  isIntegration: false,
  uid: 0 as Flipboard.UserId, // Default to 0 because that represents unauthenticated
  appVersion: null,
  locale: 'en_US',
  prodType: 'web',
  enableExperiments: true,
  abTests: null,
  seoExperiments: null,
  flurl: null,
  referrer: null,

  // Queue used to delay sending events until we have all the
  // necessary app state information (specifically, experiments)
  eventQueue: [],
  trackingEnabled: false,
  sessionRequest: null,

  sectionHeartbeatTimerId: null,
  sectionHeartbeat: null,
  sectionDisplayedItems: {},
  // only to be used in development
  developmentEventLog: [],
};

export default function usageReducer(state = initialState, action) {
  const merge = merger(state);

  switch (action.type) {
    case USAGE_TYPES.USAGE_ENQUEUE_EVENT: {
      const {
        payload: { event },
      } = action;
      return merge({ eventQueue: [...state.eventQueue, event] });
    }
    case USAGE_TYPES.USAGE_EMPTY_QUEUE: {
      return merge({ eventQueue: [] });
    }
    case USAGE_TYPES.USAGE_SET_NAV_FROM: {
      const {
        payload: { eventName, value },
      } = action;
      const navFrom = {
        ...state.navFrom,
        [eventName]: value,
      };

      // NOTE: Hack to make mobile gate navFroms match section enters
      if (eventName === EVENT_NAMES.SECTION_ENTER) {
        navFrom[EVENT_NAMES.DISPLAY_MOBILE_GATE] = value;
      }
      return merge({ navFrom });
    }
    case USAGE_TYPES.USAGE_DEVELOPMENT_EVENT_LOG: {
      return merge({
        developmentEventLog: [...state.developmentEventLog, action.payload],
      });
    }
    case USAGE_TYPES.USAGE_ENABLE_TRACKING:
    case USAGE_TYPES.USAGE_SET_PROD_TYPE:
    case USAGE_TYPES.USAGE_SET_REFERRER:
    case USAGE_TYPES.USAGE_SET_SESSION:
    case USAGE_TYPES.USAGE_SET_FLURL:
    case USAGE_TYPES.USAGE_SET_IS_INTEGRATION:
    case USAGE_TYPES.USAGE_SET_SECTION_HEARTBEAT:
    case USAGE_TYPES.USAGE_SET_SECTION_HEARTBEAT_TIMER_ID:
    case USAGE_TYPES.USAGE_SET_NAV_FROM_SECTION:
    case USAGE_TYPES.USAGE_SET_MOBILE_GATE_SECTION:
    case USAGE_TYPES.USAGE_SET_MOBILE_GATE_ARTICLE:
    case USAGE_TYPES.USAGE_UPDATE_SECTION_DISPLAYED_ITEMS:
    case USAGE_TYPES.USAGE_UPDATE_SECTION_HEARTBEAT:
      return merge(action.payload);
    default:
      return state;
  }
}
