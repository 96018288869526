import React, { Component } from 'react';
import styled from '@emotion/styled';
import classNames from 'classnames';
import { SPACING } from 'Style/spacing';
import { BREAKPOINTS } from 'Style/breakpoints';
import { truncate } from 'Style/truncate';
import { DefaultTextColorStyle } from 'Style/colors';
import {
  ARTICLE_TEXT_TITLE,
  CONTENT_STORY_HEADING_TYPES,
} from 'Style/typography';

// Utils
import StoryboardRenderingUtil from 'Utils/content/storyboard-rendering-util';
import { getOriginalItem } from 'Utils/content/item-util';

// Components
import Media from '../base/media';
import SourceAttribution from '../attribution/source';
import ImageUnavailable from '../image-unavailable';
import ItemLink from './item-link';
import Header from '../base/header';
import ItemStructuredData from '../../structured-data/item';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectIsArticleRoute, {
  ConnectIsArticleRouteProps,
} from 'Webapp/shared/app/connectors/connectIsArticleRoute';

export enum CondensedItemModifiersType {
  WIDE_VIDEOS = 'wide-videos',
}

const { sizes } = StoryboardRenderingUtil;

const getBaseCondensedItemStyle = (props) => {
  const { coverSize } = props;
  return {
    display: 'grid',
    gridTemplateColumns: `${coverSize}px auto`,
    gridColumnGap: SPACING.MEDIUM,
    padding: '0',
    '.post-attribution__source': {
      margin: '0',
    },
    '.post__media': {
      width: coverSize,
      height: coverSize,
      overflow: 'hidden',
    },
  };
};

const getCondensedMagazineStyle = (coverSize) => ({
  display: 'flex',
  position: 'relative',
  '.post__media': {
    width: '100%',
    height: `${coverSize}px`,
    '.cropped-image': {
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
        zIndex: '1',
        background: 'rgba(0,0,0,.5)',
      },
    },
  },
  '.post__body': {
    position: 'absolute',
    top: SPACING.MEDIUM,
    left: '16px',
    zIndex: '2',
    width: '90%',
    pointerEvents: 'none',
    '.post__title': {
      marginBottom: SPACING.SMALL,
      ...truncate('100%', 2),
      '.outbound-link': {
        ...truncate('100%', 2),
        ...DefaultTextColorStyle(true),
      },
    },
    '.post-attribution__link': {
      ...DefaultTextColorStyle(true),
      pointerEvents: 'all',
    },
  },
});

const CONDENSED_STORYBOARD = {
  backgroundColor: 'var(--color--surface-new-storyboard-default-cover)', // Overridden in code by cover item
  cursor: 'pointer',
  '.post-attribution__link': DefaultTextColorStyle(true),

  '.post__body': {
    position: 'relative',
    paddingTop: SPACING.BASE,
    paddingRight: SPACING.MEDIUM,
  },
  '.post__title': {
    marginBottom: SPACING.BASE,
    ...DefaultTextColorStyle(true),
    ...truncate('98%', 3),
  },

  '.post-attribution__source': {
    marginBottom: SPACING.SMALL,
  },
  '.post__excerpt': {
    ...BREAKPOINTS.desktopUp({ display: 'none' }),
    marginBottom: SPACING.BASE4X,
  },
};

const getCondensedFirstPartyVideoStyle = (coverSize) => ({
  background: 'none',
  gridTemplateColumns: '177px auto',
  '.post__media': {
    width: '177px',
    height: `${coverSize}px`,
  },
  '.post__title': {
    '.internal-link': truncate('98%', 3),
  },
});

const getTypeItemCondensed = (itemToRender, showWideVideos, coverSize) => {
  const { section, isFirstPartyVideo, isMagazine } = itemToRender;
  const isStoryboard = section?.isStoryboard;
  if (isFirstPartyVideo && showWideVideos) {
    return getCondensedFirstPartyVideoStyle(coverSize);
  } else if (isStoryboard) {
    return CONDENSED_STORYBOARD;
  } else if (isMagazine) {
    return getCondensedMagazineStyle(coverSize);
  }
  return null;
};

const StyledItemCondensed = styled.article(
  getBaseCondensedItemStyle,
  (props) => ({
    ...props.typeItemCondensed,
    ...props?.backgroundStyle,
  }),
);

const StyledHeader = styled(Header)({
  margin: `0 0 ${SPACING.BASE}`,
});

const StyledItemlink = styled(ItemLink)(
  ({ item: { isSection, isMagazine, section }, isArticleRoute }) => {
    const isStoryboard = section?.isStoryboard;
    if (isArticleRoute && !isStoryboard) {
      return CONTENT_STORY_HEADING_TYPES.XSMALL;
    }
    if (isArticleRoute && isStoryboard) {
      return {
        ...CONTENT_STORY_HEADING_TYPES.SMALL,
        ...DefaultTextColorStyle(true),
      };
    }
    if (!isArticleRoute && isStoryboard) {
      return {
        ...ARTICLE_TEXT_TITLE.SMALL,
        ...DefaultTextColorStyle(true),
        textTransform: 'capitalize',
      };
    }
    if (!isArticleRoute && !isSection && !isMagazine) {
      return {
        ...ARTICLE_TEXT_TITLE.SMALL,
        textTransform: 'capitalize',
      };
    }
    if (isMagazine) {
      return CONTENT_STORY_HEADING_TYPES.LARGE_OVERLAY;
    }
  },
  {
    display: 'block',
  },
);

interface ItemCondensedStates {
  imageLoadFailed: boolean;
}

interface ItemCondensedPassedProps {
  item: Flipboard.Item;
  section?: Flipboard.Section;
  shownInList?: boolean;
  avatarSize: number;
  coverSize?: number;
  className?: string;
  shouldRenderAvatar?: boolean;
  condensedItemModifiers?: Array<CondensedItemModifiersType>;
}

type ItemCondensedProps = ConnectIsArticleRouteProps &
  ItemCondensedPassedProps & { t: Flipboard.TFunction };

class ItemCondensed extends Component<ItemCondensedProps, ItemCondensedStates> {
  static defaultProps = {
    section: null,
    shownInList: true,
    truncateTitleLength: null,
    condensedItemModifiers: [],
    coverSize: 104,
    shouldRenderAvatar: false,
  };

  constructor(props) {
    super(props);

    this.handleError = this.handleError.bind(this);

    this.state = {
      imageLoadFailed: false,
    };
  }

  handleError() {
    this.setState({ imageLoadFailed: true });
  }

  renderBodyContent(itemToRender) {
    const {
      section,
      shownInList,
      isArticleRoute,
      shouldRenderAvatar,
      avatarSize,
    } = this.props;
    const { sanitizedTitle, title } = itemToRender;
    const itemTitle = sanitizedTitle || title;

    const titleElement = itemTitle && (
      <StyledHeader className="post__title" primary={!shownInList}>
        <StyledItemlink
          isArticleRoute={isArticleRoute}
          item={itemToRender}
          section={section}
          shownInList={shownInList}
          dataTestId="more-stories"
        >
          {itemToRender.isTopic && '#'}
          {itemTitle}
        </StyledItemlink>
      </StyledHeader>
    );
    return (
      <React.Fragment>
        {titleElement}
        <SourceAttribution
          className={classNames({ 'ui-body--small-standard': isArticleRoute })}
          item={itemToRender}
          section={section}
          hideFlipDateCreated
          isCondensedItem
          shouldRenderAvatar={shouldRenderAvatar}
          avatarSize={avatarSize}
        />
      </React.Fragment>
    );
  }

  render() {
    const {
      className,
      item,
      section,
      shownInList,
      t,
      condensedItemModifiers,
      coverSize,
    } = this.props;
    const { type } = item;

    // We may want to render the "original" item, as in the case of
    // "status" items
    let itemToRender = item;
    if (type === 'status') {
      itemToRender = getOriginalItem(item);
    }

    const showWideVideos = condensedItemModifiers?.includes(
      CondensedItemModifiersType.WIDE_VIDEOS,
    );

    const typeItemCondensed = getTypeItemCondensed(
      itemToRender,
      showWideVideos,
      coverSize,
    );

    let backgroundStyle = {};
    const isStoryboard =
      itemToRender.section && itemToRender.section.isStoryboard;
    if (isStoryboard) {
      backgroundStyle = StoryboardRenderingUtil.getBackgroundColorStyle(
        itemToRender.image,
        0,
      );
    }

    const mediaElement = (
      <Media
        shownInList
        item={itemToRender}
        section={section}
        sectionToBecomeItem
        allowPlayableMedia={false}
        onError={this.handleError}
        fallbackElementSize={ImageUnavailable.fallbackElementSizes.CONDENSED}
        previewSize={sizes.SMALL}
        showWideVideos={showWideVideos}
        ampClass="condensed"
        ampWidth={370}
        ampHeight={coverSize}
      />
    );

    return (
      <StyledItemCondensed
        coverSize={coverSize}
        className={classNames(className, { 'post--condensed': true })}
        backgroundStyle={backgroundStyle}
        key={itemToRender.id}
        typeItemCondensed={typeItemCondensed}
        onClick={(e) => {
          const isBody = e.target.classList.contains('post__body');
          if (isBody) {
            e.target.querySelector('.post__title > a')?.click();
          }
        }}
      >
        {mediaElement}
        <div className="post__body">
          {!shownInList && !item.isSection && (
            <ItemStructuredData item={itemToRender} section={section} t={t} />
          )}
          {this.renderBodyContent(itemToRender)}
        </div>
      </StyledItemCondensed>
    );
  }
}

export default connector(connectIsArticleRoute)(withT(ItemCondensed));
