/**
 * Compares two topic objects for equality.
 * @param {Object} topicA - A topic object to compare
 * @param {Object} topicB - A topic object to compate
 * @returns {Boolean}     - True if the topics are equal
 */
function isEqual(topicA, topicB) {
  // If remoteid properties are present, use them
  if (topicA.remoteid && topicB.remoteid) {
    return topicA.remoteid === topicB.remoteid;
  }

  // Fall back to comparing titles
  return topicA.title === topicB.title;
}

/**
 * Adds necessary properties for following a topic.  This
 * is needed because the list of topics presented to the
 * user is sometimes populated by endpoints that don't
 * provide all the standard properties we need.
 * @param {Object} topic - A topic object
 * @returns {Object} - The topic decorated with essential properties
 * for following using social/putState
 */
function decorateForFollowing(topic) {
  // Default property values
  const topicDefaults = {
    service: 'flipboard',
  };

  const decoratedTopic = Object.assign({}, topicDefaults, topic);

  // Copy title prop to sectionTitle
  if (!decoratedTopic.sectionTitle && decoratedTopic.title) {
    decoratedTopic.sectionTitle = decoratedTopic.title;
  }

  return decoratedTopic;
}

/**
 * Returns editorial board topics keyed by remote id.
 * * Note that due to the logic consuming this function having the remoteids stored
 * in cookies, we decode here to ensure they are equal
 * @param {Array} topics - editorial board topics
 * @return {Object} Topics keyed by remoteid
 */
function getEditorialTopicsById(topics = []) {
  return topics.reduce(
    (acc, t) =>
      Object.assign({}, acc, {
        [t.remoteid]: Object.assign({ service: 'flipboard' }, t),
      }),
    {},
  );
}

/**
 * Takes an Array of strings (remoteids) and returns Topic objects found in editorial boards
 * @param {Array<String>} selectedTopics
 * @param {Array} editorialBoards
 * @return {Array<Object>}
 */
function getSelectedTopicsFromEditorialBoards(
  selectedTopics = [],
  editorialBoards = [],
) {
  const topicsById = getEditorialTopicsById(editorialBoards);
  return selectedTopics.map((id) => topicsById[id]).filter((t) => t);
}

const TOPIC_ID_PREFIX = 'flipboard/topic%2F';

function getTopicRemoteId(topic) {
  if (topic && !topic.includes(TOPIC_ID_PREFIX)) {
    return `${TOPIC_ID_PREFIX}${topic}`;
  }
}

export default {
  isEqual,
  decorateForFollowing,
  getEditorialTopicsById,
  getSelectedTopicsFromEditorialBoards,
  getTopicRemoteId,
};
