import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const LABEL_POSITION = {
  BEFORE: 'before',
  AFTER: 'after',
};

function Label({
  className,
  textClassName,
  text,
  labelComponent,
  position,
  children,
  forCheckbox,
  onClick,
}) {
  // Labels with checkboxes need more prominence than labels with text fields
  const textClass = forCheckbox ? 'ui-text--body-2' : 'ui-text--supporting';
  const labelElement = labelComponent || (
    <div className={classNames(textClass, textClassName)}>{text}</div>
  );

  return (
    <label className={className} onClick={onClick}>
      {position === LABEL_POSITION.BEFORE && labelElement}
      {children}
      {position === LABEL_POSITION.AFTER && labelElement}
    </label>
  );
}

Label.defaultProps = {
  position: LABEL_POSITION.BEFORE,
  text: null,
  labelComponent: null,
  forCheckbox: false,
  onClick() {},
};

Label.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  textClassName: PropTypes.string,
  position: PropTypes.oneOf(Object.values(LABEL_POSITION)),
  text: PropTypes.node,
  labelComponent: PropTypes.node,
  forCheckbox: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Label;
