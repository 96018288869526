import React from 'react';
import Button, { StyleVariations } from 'Webapp/shared/app/components/button';

import connector from 'Utils/connector';
import connectAcceptMagazineInvite, {
  ConnectAcceptMagazineInviteProps,
} from 'Webapp/shared/app/connectors/connectAcceptMagazineInvite';
import connectFeatureFlags, {
  ConnectFeatureFlagsProps,
} from 'Webapp/shared/app/connectors/connectFeatureFlags';
import withT from 'ComponentLibrary/hocs/withT';

interface MagazineInviteAcceptButtonPassedProps {
  className: string;
  isTooltipMenu: boolean;
  styleVariation: StyleVariations;
  section: Flipboard.Section;
}

type MagazineInviteAcceptButtonProps = MagazineInviteAcceptButtonPassedProps &
  ConnectAcceptMagazineInviteProps &
  ConnectFeatureFlagsProps & {
    t: Flipboard.TFunction;
  };

class MagazineInviteAcceptButton extends React.Component<MagazineInviteAcceptButtonProps> {
  componentDidMount() {
    const { section, usageTrackShowAcceptContributorInvite } = this.props;
    usageTrackShowAcceptContributorInvite(section, section.invite);
  }
  render() {
    const {
      className,
      section,
      styleVariation,
      featureFlags,
      startOldAcceptMagazineFlow,
      startAcceptMagazineFlow,
      isMagazineInvitePending,
      usageTapAcceptContributorInvite,
      t,
    } = this.props;
    return (
      <Button
        className={className}
        name="accept-contributor-invite"
        styleVariation={styleVariation}
        loading={isMagazineInvitePending}
        onClick={async () => {
          // section.invite should always be true if this is
          // rendering, but typescript is mad
          usageTapAcceptContributorInvite(section, section.invite);
          if (section.invite) {
            if (featureFlags.INVITE_MAGAZINE_CONTRIBUTOR) {
              startAcceptMagazineFlow(section);
            } else {
              startOldAcceptMagazineFlow(section);
            }
          }
        }}
      >
        {t('accept_invite')}
      </Button>
    );
  }
}

export default connector(
  connectAcceptMagazineInvite,
  connectFeatureFlags,
)(withT(MagazineInviteAcceptButton));
