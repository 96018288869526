import { Connector } from 'Webapp/utils/connector';
import {
  usageTrackTapFlip,
  usageTrackTapFlipItem,
  usageTrackTapFlipSection,
} from 'Webapp/shared/app/redux/actions/usage-actions';

const actions = {
  usageTrackTapFlip,
  usageTrackTapFlipItem,
  usageTrackTapFlipSection,
};

export type ConnectUsageTrackFlipProps = Flipboard.ConnectorProps<
  unknown,
  typeof actions
>;

const connectUsageTrackFlip: Connector = {
  actions,
};

export default connectUsageTrackFlip;
