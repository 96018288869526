import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

import { deleteCookie } from 'Webapp/utils/client-cookie';
import codeSplitLoad from 'Webapp/shared/utils/code-split-load';

import {
  showModal,
  dismissSpecificModal,
} from 'Webapp/shared/app/redux/actions/modal-actions';
const PublisherOnboardModal = codeSplitLoad('PublisherOnboardModal');

const publisherModal: OnboardingStep = (nextStep) => (dispatch) => {
  dispatch(
    showModal(PublisherOnboardModal, {
      onComplete: () => {
        deleteCookie('publisher-onboard');
        dispatch(dismissSpecificModal(PublisherOnboardModal));
        nextStep();
      },
    }),
  );
};

export default publisherModal;
