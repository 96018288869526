import getWindow from 'Utils/get-window';
import { getCLS, getFID, getLCP, getFCP, getTTFB } from 'web-vitals';

const reportVitals = (tags) => {
  // fire and forget / hope for best, will often be called on unload
  const callback = (metric) => {
    const body = JSON.stringify({
      method: 'histogram',
      name: metric.name,
      value: metric.value,
      tags,
    });
    // Use `navigator.sendBeacon()` if available, falling back to `fetch()`.
    if (getWindow().navigator.sendBeacon) {
      getWindow().navigator.sendBeacon('/api/v2/reporting/datadog', body);
    } else {
      getWindow().fetch('/api/v2/reporting/datadog', {
        body,
        method: 'POST',
        keepalive: true,
      });
    }
  };
  getCLS(callback);
  getFID(callback);
  getLCP(callback);
  getFCP(callback);
  getTTFB(callback);
};

export default reportVitals;
