import React from 'react';
import PropTypes from 'prop-types';

// Components
import ModalControls from 'Webapp/shared/app/components/modal-controls';
import { MODAL_THEMES } from 'ComponentLibrary/modals/modal';
import Button, { StyleVariations } from 'Webapp/shared/app/components/button';
import ModalWrapper from './modal-wrapper';
import MagazineContributors from '../components/magazine-contributors';

import {
  ACCESSORY_SECTIONS,
  getAccessorySections,
  accessorySectionMagazineId,
} from 'Webapp/shared/concepts/accessory-sections';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectModal from 'Webapp/shared/app/connectors/connectModal';

function AcceptContributorInvite({
  section,
  isPhone,
  dismissModal,
  onComplete,
  t,
}) {
  const modalTheme = isPhone ? MODAL_THEMES.FULLSCREEN : MODAL_THEMES.WINDOW;

  const contributors = getAccessorySections(
    ACCESSORY_SECTIONS.MAGAZINE_CONTRIBUTORS,
    accessorySectionMagazineId(
      section.invite ? section.requestedRemoteId : section.remoteid,
    ),
  );

  return (
    <ModalWrapper name="invite-contributors-modal" modalTheme={modalTheme}>
      <section className="modal__content">
        <h1 className="invite-contributors__title ui-text--title">
          {t('accept_invite')}
        </h1>
        <div className="field-block">
          <p className="ui-text--body">
            {t('accept_magazine_contributor_invite', {
              sectionTitle: section.title,
            })}
          </p>
        </div>
        <MagazineContributors
          section={section}
          profiles={[section.author, ...contributors]}
        />
      </section>
      <ModalControls>
        <Button
          name="accept-contributor-invite-close"
          styleVariation={StyleVariations.SECONDARY}
          onClick={dismissModal}
        >
          {t('close')}
        </Button>
        <Button
          name="accept-contributor-invite-accept"
          styleVariation={StyleVariations.PRIMARY}
          onClick={onComplete}
        >
          {t('accept')}
        </Button>
      </ModalControls>
    </ModalWrapper>
  );
}

AcceptContributorInvite.propTypes = {
  section: PropTypes.object.isRequired,
  uid: PropTypes.number,
  isPhone: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  dismissModal: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default connector(
  connectResponsive,
  connectModal,
)(withT(AcceptContributorInvite));
