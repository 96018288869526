import React from 'react';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { UI_TEXT_TYPES } from 'Style/typography';

// Components
import Button, { StyleVariations } from 'Webapp/shared/app/components/button';
import ModalWrapper from './modal-wrapper';
import ModalControls from 'Webapp/shared/app/components/modal-controls';
import {
  MODAL_THEMES,
  MODAL_WINDOW_SIZES,
} from 'ComponentLibrary/modals/modal';
import { noOpFn } from 'Utils/no-op-fn';

import connector from 'Utils/connector';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';

import withT from 'ComponentLibrary/hocs/withT';

const ConfirmTitle = styled.h1(
  { marginBottom: SPACING.MEDIUM },
  UI_TEXT_TYPES.TITLE,
);

const ConfirmDescription = styled.p(UI_TEXT_TYPES.BODY);

interface ConfirmDialogPassedProps {
  name: string;
  message: string;
  title?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
  onlyDisplayCofirmButton?: () => void;
  preventButtonSentenceCase?: boolean;
  dismiss?: boolean;
}

type ConfirmDialogProps = ConnectModalProps &
  ConfirmDialogPassedProps & {
    t: Flipboard.TFunction;
  };

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  const {
    name,
    message,
    confirmLabel = null,
    cancelLabel = null,
    onCancel = noOpFn,
    onConfirm,
    dismissModal,
    title = null,
    onlyDisplayCofirmButton,
    preventButtonSentenceCase = false,
    dismiss = true,
    t,
  } = props;

  const handleConfirm = () => {
    onConfirm();
    if (dismiss) {
      dismissModal();
    }
  };

  const handleCancel = () => {
    onCancel();
    if (dismiss) {
      dismissModal();
    }
  };

  return (
    <ModalWrapper
      name={`${name}-confirm-dialog`}
      modalTheme={MODAL_THEMES.WINDOW}
      size={MODAL_WINDOW_SIZES.SMALL}
    >
      <div className="modal__content">
        {title && <ConfirmTitle>{title}</ConfirmTitle>}
        <ConfirmDescription>{message}</ConfirmDescription>
      </div>
      <ModalControls>
        {!onlyDisplayCofirmButton && (
          <Button
            name={`${name}.confirm-dialog-dismiss`}
            label={cancelLabel || t('cancel')}
            styleVariation={StyleVariations.SECONDARY}
            onClick={handleCancel}
          />
        )}
        <Button
          name={`${name}.confirm-dialog-confirm`}
          label={confirmLabel || t('confirm')}
          styleVariation={StyleVariations.PRIMARY}
          onClick={handleConfirm}
          preventSentenceCase={preventButtonSentenceCase}
        />
      </ModalControls>
    </ModalWrapper>
  );
};

export default connector(connectModal)(withT(ConfirmDialog));
