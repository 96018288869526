import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Config from '../../config';

// Utils
import {
  USAGE_NAV_FROMS,
  USAGE_EVENT_NAMES,
  USAGE_MOBILE_GATE_EXIT_METHODS,
} from 'Utils/analytics/usage';

// Components
import withAdjustUrl from '../hocs/withAdjustUrl';
import Button, { StyleVariations } from 'Webapp/shared/app/components/button';
import FlipboardLogo from 'ComponentLibrary/logos/flipboard';
import ModalWrapper from './modal-wrapper';
import {
  MODAL_THEMES,
  MODAL_OVERlAY_MODIFIERS,
} from 'ComponentLibrary/modals/modal';
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectUsageSetNavFrom from 'Webapp/shared/app/connectors/connectUsageSetNavFrom';

class MobileSocialActionGate extends Component {
  constructor(props) {
    super(props);

    this.handleClickOpenInApp = this.handleClickOpenInApp.bind(this);
  }

  componentDidMount() {
    const { usageTrackMobileSocialGatePickerDisplayed } = this.props;
    usageTrackMobileSocialGatePickerDisplayed();
  }

  componentWillUnmount() {
    const { usageTrackMobileSocialGatePickerExit } = this.props;
    usageTrackMobileSocialGatePickerExit(
      USAGE_MOBILE_GATE_EXIT_METHODS.DISMISS,
    );
  }

  handleClickOpenInApp() {
    const {
      usageSetNavFrom,
      usageTrackTapOpenInAppButton,
      usageTrackMobileSocialGatePickerExit,
    } = this.props;
    usageSetNavFrom(
      USAGE_EVENT_NAMES.OPEN_IN_APP,
      USAGE_NAV_FROMS.MOBILE_GATE_BOTTOM_PICKER,
    );
    usageTrackTapOpenInAppButton();
    usageTrackMobileSocialGatePickerExit(
      USAGE_MOBILE_GATE_EXIT_METHODS.OPEN_IN_APP,
    );
  }

  render() {
    const { t } = this.props;
    const gateCopy = t('download_the_app_gate');
    const adjustUrl = this.props.getAdjustUrl(
      Config.APP_STORE_URL_FOR_MOBILE_GATE,
      USAGE_NAV_FROMS.MOBILE_GATE_BOTTOM_PICKER,
    );

    return (
      <ModalWrapper
        name="social-see-this-in-gate"
        modalTheme={MODAL_THEMES.BOTTOM_SHEET}
        overlayModifier={MODAL_OVERlAY_MODIFIERS.LIGHT}
      >
        <div className="mobile-gate mobile-gate--picker">
          <h1 className="mobile-gate__header ui-text--body">{gateCopy}</h1>
          <div className="mobile-gate__buttons">
            <div className="mobile-gate__buttons-app">
              <div className="mobile-gate__app-label">
                <FlipboardLogo size={40} />
                <span className="ui-text--body">{t('flipboard_app')}</span>
              </div>
              <Button
                name="social-gate__use-app"
                label={t('open')}
                styleVariation={StyleVariations.PRIMARY}
                href={adjustUrl}
                onClick={this.handleClickOpenInApp}
              />
            </div>
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

MobileSocialActionGate.propTypes = {
  experiments: PropTypes.object,
  t: PropTypes.func.isRequired,
  getAdjustUrl: PropTypes.func.isRequired,
  usageSetNavFrom: PropTypes.func.isRequired,
  usageTrackMobileSocialGatePickerDisplayed: PropTypes.func.isRequired,
  usageTrackMobileSocialGatePickerExit: PropTypes.func.isRequired,
  usageTrackTapOpenInAppButton: PropTypes.func.isRequired,
};

export default connector(connectUsageSetNavFrom)(
  withAdjustUrl(withT(MobileSocialActionGate)),
);
