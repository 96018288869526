import basicTitle from './shared/basic-title';
import { getCurrentArticle } from 'Webapp/shared/app/redux/selectors/article';

export default (_, getState) => {
  const article = getCurrentArticle(getState());

  if (article && article.title) {
    return basicTitle(article.title);
  }
};
