import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { BREAKPOINTS } from 'Style/breakpoints';
import { SPACING } from 'Style/spacing';
import { BODY_TYPES } from 'Style/typography';
// Components
import ModalWrapper from './modal-wrapper';
import ModalControls from 'Webapp/shared/app/components/modal-controls';
import { MODAL_THEMES } from 'ComponentLibrary/modals/modal';
import Button, { StyleVariations } from 'Webapp/shared/app/components/button';
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectModal from 'Webapp/shared/app/connectors/connectModal';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectRouting from 'Webapp/shared/app/connectors/connectRouting';
import withHistory from 'Webapp/shared/app/hocs/withHistory';
const SuccessModalContent = styled.div(
  {
    padding: `${SPACING.BASE8X} ${SPACING.BASE4X} ${SPACING.LARGE} ${SPACING.BASE4X}`,
  },
  BREAKPOINTS.phone({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }),
);
const Title = styled.h1(BODY_TYPES.BRAND_HEADING_MEDIUM, {
  textAlign: 'center',
  marginBottom: SPACING.LARGE,
});
const SubTitle = styled.p(BODY_TYPES.LARGE_STANDARD, {
  textAlign: 'center',
});

function FrictionlessSignupSuccess({
  history,
  isPhone,
  onComplete,
  routing,
  t,
}) {
  const modalTheme = isPhone ? MODAL_THEMES.FULLSCREEN : MODAL_THEMES.WINDOW;

  const refresh = () => {
    if (
      routing.pathname.indexOf('/newsletters') === 0 ||
      routing.pathname.indexOf('/signup') === 0
    ) {
      history.push('/');
    }
    onComplete();
  };

  return (
    <ModalWrapper
      name="frictionless-signup-success"
      modalTheme={modalTheme}
      onDismiss={refresh}
      disableDismiss
    >
      <SuccessModalContent className="modal__content">
        <Title>{t('newsletter_signup_success_title')}</Title>
        <SubTitle>{t('newsletter_signup_success_subtitle')}</SubTitle>
      </SuccessModalContent>
      <ModalControls
        modalName="frictionless-signup-success"
        showCloseButton
        closeButtonSize={16}
        onDismiss={refresh}
      >
        <Button
          name="frictionless-signup-continue"
          label="Got it!"
          styleVariation={StyleVariations.PRIMARY}
          onClick={refresh}
        />
      </ModalControls>
    </ModalWrapper>
  );
}

FrictionlessSignupSuccess.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
  isPhone: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  routing: PropTypes.object.isRequired,
};

export default connector(
  connectResponsive,
  connectModal,
  connectRouting,
)(withHistory(withT(FrictionlessSignupSuccess)));
