import isUnknownType from 'Webapp/shared/app/components/notifications/notification-text-click-strategies/shared/is-unknown-type';
import LinkItem from 'Webapp/shared/app/components/notifications/content/link-item';

const strategy = (notification: Flipboard.Notification) => {
  if (isUnknownType(notification)) {
    return null;
  }
  const item = notification?.referredByItems?.[0];
  if (!item) {
    return null;
  }
  return LinkItem;
};

export default strategy;
