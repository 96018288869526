import { cloneDeep } from 'lodash';
import { COMMENT_TYPES } from 'Webapp/action-types';
import { CommentAreYouSureError } from 'Webapp/utils/errors';
import { currentUserUid } from 'Webapp/shared/app/redux/selectors/auth';
import sentry from 'Webapp/utils/sentry';
import { type, setPayloadReducer } from 'Utils/redux';
import { updateItemSocialActivity } from 'Webapp/shared/concepts/social-activity';
import { toastGenericError } from '../app/redux/actions/toast-actions';

const defaultComments = {
  loading: false,
  loaded: false,
  comments: [],
  nextPageKey: null,
};

export interface CommentsReducerState {
  [key: Flipboard.SocialId]: Flipboard.Comments;
}

const initialState: CommentsReducerState = {};

export const reducer = setPayloadReducer<
  typeof COMMENT_TYPES,
  CommentsReducerState
>(COMMENT_TYPES, initialState, COMMENT_TYPES.RESET_COMMENTS);

export const loadComments =
  (
    rootItemSocialId: Flipboard.SocialId,
    pageKey: Flipboard.NextPageKey | false = false,
    childSocialId: Flipboard.SocialId | false = false,
    limit = 32,
  ): Flipboard.Thunk =>
  async (dispatch, getState, { flap }) => {
    const target = childSocialId || rootItemSocialId;
    const existing = getCommentsForSocialId(target, getComments(getState()));
    dispatch({
      type: COMMENT_TYPES.GET_COMMENTS,
      payload: {
        [target]: {
          ...existing,
          loading: true,
        },
      },
    });
    const uid = currentUserUid(getState());
    const params: Flipboard.FlapSocialCommentsParams = {
      limit,
      oid: [rootItemSocialId],
      userid: uid,
    };
    if (childSocialId) {
      params.parent = childSocialId;
    }
    if (pageKey) {
      params.pageKey = pageKey;
    }
    const response = await flap.get<Flipboard.FlapSocialCommentsResponse>(
      '/social/comments',
      {
        params,
      },
    );
    const {
      data: { items },
    } = response;
    const item = items[0];
    if (item) {
      const existingCommentIds = existing.comments.map((comment) => comment.id);
      dispatch({
        type: COMMENT_TYPES.GET_COMMENTS_SUCCEEDED,
        payload: {
          [target]: {
            loading: false,
            loaded: true,
            comments: [
              ...existing.comments,
              ...item.commentary.filter(
                (comment) => !existingCommentIds.includes(comment.id),
              ),
            ],
            nextPageKey: item.commentsPageKey || null,
          },
        },
      });
    }
  };

interface CreateCommentParams {
  oid: Flipboard.Item['flipboardSocialId'];
  text: string;
  parent?: Flipboard.SocialId;
  link?: Array<Flipboard.MentionLink>;
  force?: boolean;
}
export const createComment =
  (
    item: Flipboard.Item,
    childSocialId: Flipboard.SocialId | false,
    text: string,
    links: Array<Flipboard.MentionLink>,
    force: boolean,
  ): Flipboard.Thunk<Promise<Flipboard.SocialId | undefined>> =>
  async (dispatch, _getState, { flap }) => {
    try {
      const params: CreateCommentParams = {
        oid: item.flipboardSocialId,
        text,
      };
      if (childSocialId) {
        params.parent = childSocialId;
      }
      if (links && links.length > 0) {
        params.link = links;
      }
      if (force) {
        params.force = force;
      }
      const {
        data: { id },
      } = await flap.get<{ id: Flipboard.SocialId }>('/social/reply', {
        params,
      });

      const target = childSocialId || item.flipboardSocialId;

      dispatch(appendNewComment(target, id));

      if (childSocialId) {
        // in case no existing replies on comment, causes replies to load
        dispatch(
          newCommentBumpCommentCount(item.flipboardSocialId, childSocialId),
        );
      }
      dispatch(updateItemSocialActivity(item));

      if (id) {
        return id;
      }
    } catch (e) {
      if ((e as Flipboard.FlapError)?.areYouSure) {
        throw new CommentAreYouSureError();
      }
      sentry.captureException(e as Error);
    }
  };

export const newCommentBumpCommentCount =
  (
    parentSocialId: Flipboard.SocialId,
    childSocialId: Flipboard.SocialId,
  ): Flipboard.Thunk =>
  (dispatch, getState) => {
    const updatedComments = { ...getState().comments };
    const parentComments = updatedComments[parentSocialId];
    updatedComments[parentSocialId] = {
      ...parentComments,
      comments: parentComments.comments.reduce<Flipboard.Comments['comments']>(
        (acc, x) => {
          if (x.id === childSocialId) {
            acc.push({
              ...x,
              commentCount: (x.commentCount || 0) + 1,
            } as Flipboard.Comment);
          } else {
            acc.push(x);
          }
          return acc;
        },
        [],
      ),
    };
    dispatch({
      type: COMMENT_TYPES.NEW_COMMENT_BUMP_COMMENT_COUNT,
      payload: updatedComments,
    });
  };

export const appendNewComment =
  (
    parent: Flipboard.SocialId,
    newCommentId: Flipboard.SocialId,
  ): Flipboard.Thunk =>
  async (dispatch, getState, { flap }) => {
    const {
      data: { items },
    } = await flap.get<Flipboard.FlapSocialCommentsResponse>(
      '/social/comment',
      {
        params: {
          oid: newCommentId,
        },
      },
    );
    const newComment = items[0].commentary.find((x) => x.id === newCommentId);

    if (!newComment) {
      dispatch(dispatch(toastGenericError()));
      return;
    }

    const updatedComments = { ...getState().comments };
    const parentComments = updatedComments[parent] || defaultComments;
    updatedComments[parent] = {
      ...parentComments,
      comments: parentComments.comments.concat(newComment),
    };
    dispatch({
      type: COMMENT_TYPES.APPEND_NEW_COMMENT,
      payload: updatedComments,
    });
  };

const filterComments = (
  comments: CommentsReducerState,
  filterFn: (comments: Flipboard.FlapCommentaryComment) => boolean,
) => {
  const commentKeys = Object.keys(comments) as Array<Flipboard.SocialId>;
  return commentKeys.reduce<CommentsReducerState>((acc, key) => {
    const value = { ...comments[key] };
    value.comments = value.comments.filter(filterFn);
    acc[key] = value;
    return acc;
  }, {});
};

export const removeComment =
  (comment: Flipboard.Comment, item: Flipboard.Item): Flipboard.Thunk =>
  async (dispatch, getState, { flap }) => {
    try {
      const params = {
        oid: comment.id,
        target: comment.id,
      };
      await flap.get('/social/replyRemove', {
        params,
      });
      const updatedComments = filterComments(
        getState().comments,
        (c) => c.id !== comment.id,
      );
      dispatch({
        type: COMMENT_TYPES.REMOVE_COMMENT,
        payload: updatedComments,
      });

      dispatch(updateItemSocialActivity(item));
    } catch (e) {
      sentry.captureException(e as Error);
    }
  };

export const reportComment =
  (
    section: Flipboard.Section,
    item: Flipboard.Item,
    comment: Flipboard.Comment,
  ): Flipboard.Thunk =>
  async (_dispatch, _getState, { flap }) =>
    flap.post(
      '/social/flagItem',
      {},
      {
        params: {
          type: 'reportComment',
          section: section.ssid?.remoteidPlain || section.remoteid,
          oid: item.id,
          commentid: comment.id,
          fuid: comment.userid,
          url: item.sourceURL,
        },
      },
    );

export const purgeComments = () => type(COMMENT_TYPES.RESET_COMMENTS);

export const removeBlockedUserComments =
  (blockedUserId: Flipboard.UserId): Flipboard.Thunk =>
  (dispatch, getState) => {
    const comments = getState().comments;
    const updatedComments = filterComments(
      comments,
      (c) => c.userid !== blockedUserId,
    );
    dispatch({
      type: COMMENT_TYPES.REMOVE_BLOCKED_USER_COMMENTS,
      payload: updatedComments,
    });
  };

// selectors
export const getComments = ({ comments }: Flipboard.State) => comments;

// helpers
export const getCommentsForSocialId = (
  socialId: Flipboard.SocialId,
  comments: CommentsReducerState,
) => cloneDeep(comments[socialId] || defaultComments);
