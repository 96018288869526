import newsletterSignup from 'Webapp/shared/app/components/onboarding-flows/steps/newsletter-signup';
import trackNewsletterSubscriptions from 'Webapp/shared/app/components/onboarding-flows/steps/track-newsletter-subscriptions';
import trackFrictionlessComplete from 'Webapp/shared/app/components/onboarding-flows/steps/track-frictionless-complete';
import performPostActivationActions from 'Webapp/shared/app/components/onboarding-flows/steps/perform-post-activation-actions';
import modalFrictionlessSignupSuccess from 'Webapp/shared/app/components/onboarding-flows/steps/modal-frictionless-signup-success';
import completeSignup from 'Webapp/shared/app/components/onboarding-flows/steps/complete-signup';

import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

const steps: Array<OnboardingStep> = [
  newsletterSignup,
  trackNewsletterSubscriptions,
  modalFrictionlessSignupSuccess,
  performPostActivationActions,
  trackFrictionlessComplete,
  completeSignup,
];

export default steps;
