import React from 'react';
import PropTypes from 'prop-types';
import withT from 'ComponentLibrary/hocs/withT';

function BannerImage({ smallImage, mediumImage, largeImage, t }) {
  return (
    <picture>
      <source media="(max-width: 1199px)" srcSet={smallImage} />
      <source
        media="(min-width: 1200px) and (max-width: 1799px)"
        srcSet={mediumImage}
      />
      <source media="(min-width: 1800px)" srcSet={largeImage} />
      <img src={smallImage} className="banner__image" alt={t('banner')} />
    </picture>
  );
}

BannerImage.propTypes = {
  smallImage: PropTypes.string.isRequired,
  mediumImage: PropTypes.string.isRequired,
  largeImage: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withT(BannerImage);
