import qs from 'qs';
import Config from 'Config';
import WebConfig from 'Webapp/shared/config';
import { getOverridesParams } from 'Utils/content/flab';
import SectionUtil from 'Utils/content/section-util';
import {
  isAbsoluteUrl,
  euc,
  rfc3986EncodeURIComponent,
  safeURL,
} from 'Utils/url';
import sentry from 'Utils/sentry';

const SID_NOTIFICATIONS = 'sid/26l71bvl6mq7j1r3';
const STRIP_QUERY_STRING_REGEX = /([^?]+)(\?.*)?/;
const TITLE_MAX_LENGTH = 80;
export const SLUG_DELIMITER = '-';

/**
 * Returns whether the given url is External or not
 * @param  {String}  url           - Any url
 * @return {Boolean}               - True if the given url is external, false otherwise.
 */
export const isExternalUrl = (url = '') => {
  // redirect is always flipbaord root site, not webu-beta.flipboard.com, etc
  if (url?.indexOf(`https://flipboard.com/redirect`) === 0) {
    return true;
  }
  return (
    isAbsoluteUrl(url) &&
    url.indexOf(Config.ABOUT_BASE_URL) !== 0 &&
    url.indexOf(Config.FLIPBOARD_URL) !== 0 &&
    url.indexOf(Config.LOGIN_URL) !== 0 &&
    url.indexOf(Config.SIGNUP_URL) !== 0
  );
};

/**
 * Removes query strings from a given url. Eg.
 * https://flipboard.com/topic/tech?someparam=1 becomes https://flipboard.com/topic/tech
 * @param  {String} url Any url
 * @return {String}     Url without query strings
 */
export const stripQueryStrings = (url) => {
  if (!url) {
    return null;
  }
  const matches = url.match(STRIP_QUERY_STRING_REGEX);
  return matches && matches.length > 1 ? matches[1] : url;
};

/**
 * Returns whether a relative path is to an "owned" section (magazine or storyboard)
 * @param {String} path     - A relative path
 * @return {Boolean}        - True if the path is for a magazine or storyboard
 */
export const isOwnedSectionPath = (path) => {
  const ownedSectionRegex = /^\/@[^/]+\/[^/]+?[^/]*$/;

  return ownedSectionRegex.test(path);
};

/**
 * Generate a sectionId for FLAP requests from the
 * username and slug parameters of a request
 * @param {Object} sectionParams - An object with topicId, username, slug
 * @return {String} - The sectionId that can be passed to updateFeed, etc.
 */
export const getSectionId = (sectionParams) => {
  const { shortcut, shortcutId, username, slug, sid, ssid } = sectionParams;

  if (shortcut) {
    return `flipboard/${euc(`${shortcut}/${decodeURIComponent(shortcutId)}`)}`;
  }

  // sid format
  if (sid) {
    return sid;
  }

  if (slug) {
    const slugSid = getSectionDataFromSlug(slug).sid;

    // with a username
    if (username && slugSid) {
      return `sid/${slugSid}/${username}`;
    }

    // webNonce format
    if (isWebNonce(slugSid)) {
      return `flipboard/webNonce/${slugSid}`;
    }

    // without a username
    if (slugSid) {
      return `sid/${slugSid}`;
    }
  }

  if (username) {
    return `flipboard/username/${username}`;
  }
  if (slug) {
    return decodeURIComponent(slug);
  }

  if (ssid?.remoteidPlain) {
    return ssid.remoteidPlain;
  }

  if (ssid?.sid) {
    if (ssid.authorUsername) {
      return `sid/${ssid.sid}/${ssid.authorUsername}`;
    }
    return `sid/${ssid.sid}`;
  }

  return null;
};

/**
 * Determines whether a sourceURLKey is valid for inclusion
 * in a remoteid for requesting an article from FLAP.  For some
 * reason, we get the value of "-" for some articles...this will
 * return false in those cases.
 * @param {String} sourceURLKey - The sourceURLKey from an item
 * @return {Boolean}            - True if the value is not "-"
 */
function isValidSourceURLKey(sourceURLKey) {
  return sourceURLKey && sourceURLKey !== '-';
}

/**
 * Takes an absolute flipboard.com URL and returns one that matches the current env's domain
 * @param {String} url
 */
export const getCurrentDomainFlipboardUrl = (url, asAbsoluteUrl = false) =>
  url.replace(
    Config.FLIPBOARD_TOP_DOMAIN_URL,
    asAbsoluteUrl ? Config.FLIPBOARD_URL : '',
  );

export const getProductionFlipboardUrl = (url) => {
  if (!isAbsoluteUrl(url)) {
    return `${Config.FLIPBOARD_TOP_DOMAIN_URL}${url}`;
  }
  return url.replace(Config.FLIPBOARD_URL, Config.FLIPBOARD_TOP_DOMAIN_URL);
};

export const getFlipboardSignupUrl = (experimentOverrides = {}) => {
  let url = Config.SIGNUP_URL;
  const params = getOverridesParams(experimentOverrides);
  if (params) {
    url += `?${qs.stringify(params)}`;
  }
  return url;
};

/**
 * Splits slug ("<section-title>-<sid>") into section title and section sid (short section id)
 * @param {String} slug - The slug from a path
 * @return {Object} - An object containing the title and sid
 */
export const getSectionDataFromSlug = (slug = '') => {
  let index = slug.lastIndexOf('-');
  if (index > -1) {
    return {
      title: slug.substr(0, index),
      sid: slug.substr(index + 1),
    };
  }

  index = slug.indexOf('__');
  if (index === 0) {
    return {
      title: '',
      sid: slug,
    };
  }

  return {
    title: slug,
    sid: null,
  };
};
/**
 * check if given string is a "webnonce"
 * @param {String} input - The slug from a path
 * @return {Boolean} - True if the slug is a webnonce
 */
export const isWebNonce = (input = '') =>
  // TODO
  // eslint-disable-next-line no-useless-escape
  /^[_]{2}|(^[ab][a-zA-Z0-9\+\/]{1,5})$/.test(input);

/**
 * Generate a remoteId for FLAP requests from the
 * various parameters of an article request
 * @param {Object} articleParams - An object with sourceURLKey and sourceURL params
 * @return {String} - The remoteId that can be passed to updateFeed, etc.
 */
export const getArticleRemoteId = (articleParams) => {
  const { sourceURL, sourceURLKey } = articleParams;
  let remoteid = null;

  if (isValidSourceURLKey(sourceURLKey)) {
    remoteid = `flipboard/itemlink/${decodeURIComponent(sourceURLKey)}`;
  } else if (sourceURL) {
    remoteid = `resolve/flipboard/url/${decodeURIComponent(sourceURL)}`;
  }

  return remoteid;
};

/**
 * Generate the path for a profile section
 * @param {Object} profile - The projected profile section
 * @param {Boolean} allowSidPath  - Specify whether to return URLs based on remoteid (default: true)
 * @return {String}        - The path to the profile
 */
export const getProfilePath = (section, allowSidPath = true) => {
  const { authorUsername, username, remoteid } = section;
  if (authorUsername !== undefined) {
    return `/@${euc(authorUsername)}`;
  }
  if (
    section.isCommunityGroup &&
    section.author &&
    section.author.authorUsername
  ) {
    return `/@${euc(section.author.authorUsername)}`;
  }
  if (username !== undefined) {
    return `/@${euc(username)}`;
  }
  if (allowSidPath && remoteid !== undefined) {
    return `/section/sid/${euc(remoteid)}`;
  }
  if (remoteid !== undefined) {
    return `/section/${euc(remoteid)}`;
  }

  return null;
};

/**
 * Generate the canonical path for a topic section
 * @param {Object} topic   - Topic section
 * @return {String}        - The path to the topic
 */
export const getTopicPath = (topic) => {
  if (!SectionUtil.isTopic(topic)) {
    return null;
  }

  const { topicTag, remoteid } = topic;

  if (topicTag !== undefined) {
    return `/topic/${topicTag}`;
  }
  if (topic.topic !== undefined) {
    return `/topic/${topic.topic}`;
  }
  if (remoteid !== undefined) {
    return decodeURIComponent(
      remoteid
        .replace(/auth\//, '')
        .replace('flipboard', '')
        .replace(/\/\//, ''),
    );
  }

  return null;
};

/**
 * Generate the canonical path for a subsection topic
 * @param {Object} subsection   - Projected subsection
 * @return {String}             - The path to the topic
 */
export const getSubSectionTopicPath = (subsection) => {
  const { remoteid, feedType, type } = subsection;
  if (
    type &&
    type === 'subsection' &&
    feedType &&
    feedType === 'topic' &&
    remoteid
  ) {
    return decodeURIComponent(subsection.remoteid.replace('flipboard', ''));
  }
  return null;
};

/**
 * Returns Path for a Service
 * @param  {String} service   - Projected service section
 */
export const getServicePath = (serviceSection) => {
  if (!serviceSection.isService) {
    return null;
  }
  if (
    serviceSection.remoteid &&
    serviceSection.remoteid.indexOf(serviceSection.service) > -1
  ) {
    return `/section/${euc(serviceSection.remoteid)}`;
  }
  if (
    serviceSection.ssid?.remoteidPlain &&
    serviceSection.ssid.remoteidPlain.indexOf('auth/') !== 0
  ) {
    return `/section/${euc('auth/')}${euc(serviceSection.ssid.remoteidPlain)}`;
  }
  return `/section/${euc(serviceSection.remoteid)}`;
};

/** Returns the slug title for a title string
 * @param {String} title   - A title
 * @param {Integer} max    - The max characters to allow in the slug title
 * @return {String}        - The slug title portion of the section path
 */
export const getSlugTitle = (title, max = TITLE_MAX_LENGTH) =>
  title
    // lowercase
    .toLowerCase()
    // strip special characters
    .replace(/[^\w-]/g, ' ')
    // trim whitespace
    .trim()
    // truncate
    .substr(0, max)
    // replace interior whitespace with delimiter
    .replace(/\s+/g, SLUG_DELIMITER);

/** Returns the slug portion of the section path
 * @param {Object} section           - A projected section
 * @param {Integer} titleMaxChars    - The max characters to allow in the slug title
 * @return {String}                  - The slug portion of the section path
 */
export const getSectionSlug = (section) => {
  if (!section || !section.ssid || !section.ssid.sid) {
    return null;
  }
  const { displayName, title } = section;

  let slugTitle = displayName || title || SLUG_DELIMITER;
  slugTitle = getSlugTitle(slugTitle).replace(/-$/, '');

  return `${slugTitle}${SLUG_DELIMITER}${section.ssid.sid}`;
};

/**
 * Returns Path for a general section.  This is for when the section
 * is not a profile, topic or service
 * @param  {Object} section   - A projected section
 * @return {String}           - Path for section
 */
export const getGeneralSectionPath = (section) => {
  const { remoteid } = section;
  const username =
    (section && section.ssid && section.ssid.authorUsername) ||
    (section && section.author && section.author.authorUsername);
  const slug = getSectionSlug(section);

  // redirect to / for specific remoteids
  if (
    [WebConfig.FOR_YOU_FEED_REMOTE_ID, SID_NOTIFICATIONS].includes(remoteid)
  ) {
    return '/';
  }

  // TODO: Check for default magazines?

  if (slug === null && !remoteid) {
    return null;
  }
  if (username) {
    return `/@${euc(username)}/${rfc3986EncodeURIComponent(slug || remoteid)}`;
  }
  return `/section/${rfc3986EncodeURIComponent(slug || remoteid)}`;
};

/**
 * Returns Path for a section.  Can be used with any section type.
 * @param  {Object} section   - A projected section
 * @param  {Boolean} allowSidProfilePath - Specify whether to return URLs based on remoteid (default: true)
 * @return {String}           - Path for section
 */
export const getSectionPath = (section, allowSidProfilePath = true) => {
  const {
    sourceURL,
    isProfile,
    isTopic,
    isService,
    isCommunityGroup,
    isSmartMagazine,
  } = section;

  if (isService) {
    return getServicePath(section);
  }

  const { FLIPBOARD_TOP_DOMAIN_URL } = Config;
  // If provided with a sourceURL from Flap, use that as the canonical URL
  if (sourceURL && sourceURL.indexOf(FLIPBOARD_TOP_DOMAIN_URL) === 0) {
    const url = safeURL(sourceURL);
    if (url?.origin === FLIPBOARD_TOP_DOMAIN_URL) {
      return url.pathname;
    }
  }
  if (isProfile || isCommunityGroup || SectionUtil.isContributor(section)) {
    return getProfilePath(section, allowSidProfilePath);
  }
  if (isTopic) {
    return getTopicPath(section);
  }
  if (isSmartMagazine) {
    return getBoardUrl(section);
  }

  return getGeneralSectionPath(section);
};

/**
 * Returns the URL for a board type section
 * @param  {Object} section     - Projected section
 * @return {String}             - The board URL
 */
export const getBoardUrl = (section) => {
  const slug = getSectionSlug(section);
  if (slug === null) {
    return null;
  }
  return `/section/${slug}`;
};

/**
 * Returns the URL for the author profile
 * @param  {Object} author         - Projected author profile section
 * @return {String}                - The author proflie URL
 */
export const getAuthorUrl = (author) => {
  if (author === null) {
    return null;
  }
  const stripTopDomain = (url) => {
    const stripped = url
      ? url.replace(Config.FLIPBOARD_TOP_DOMAIN_URL, '')
      : null;
    return stripped;
  };
  const authorUrl = author.authorURL || author.sourceURL;
  if (!author.isFlipboard && authorUrl) {
    return stripTopDomain(authorUrl);
  }
  const profilePath = getProfilePath(author);
  return stripTopDomain(
    profilePath || getSectionPath(author) || author.authorURL,
  );
};

/**
 * Returns Path for an Article
 * @param  {Object} item     - Projected article item
 * @param  {Object} section  - Projected section the item is presented in (optional)
 * @return {String}          - Path for article
 */
export const getArticlePath = (item, section = null) => {
  let prefix = null;

  // NOTE: I'm not sure if this rule is correct. This solves this issue:
  // https://flipboard.atlassian.net/browse/FL-9048
  // where a feed item of type "post" is actually a Flipboard section, and not an article...
  // Seems like on iOS, these items open correctly natively, by using:
  // static NSString *const kFLShowSectionFromURLSectionIdentifierFormat = @"resolve/flipboard/url/%@";
  if (
    !item.isFirstPartyVideo &&
    !item.isNote &&
    item.isFlipboard &&
    item.sourceURL &&
    item.sourceURL.indexOf(Config.FLIPBOARD_TOP_DOMAIN_URL) === 0
  ) {
    return getCurrentDomainFlipboardUrl(item.sourceURL, true);
  }
  // }
  if (!item.excludeContext) {
    // Try to generate prefix with section where item is displayed
    if (section !== null) {
      prefix = getSectionPath(section);
    }

    // invalid prefix, try to generate with reason sectionLink
    if (prefix === null || prefix === '/') {
      const reasonSection =
        item &&
        item.reason &&
        Array.isArray(item.reason.sectionLinks) &&
        item.reason.sectionLinks[0];
      if (reasonSection) {
        prefix = getSectionPath(reasonSection);
      }
    }
  }

  // can't generate prefix, revert to /article/
  if (prefix === null || prefix === '/') {
    prefix = '/article/';
  } else if (prefix.substr(-1) !== '/') {
    // ensure trailing forward slash
    prefix = `${prefix}/`;
  }

  const sourceURLKey = item.mainItem
    ? item.mainItem.sourceURLKey
    : item.sourceURLKey;
  if (!sourceURLKey) {
    return null;
  }

  let encodedSourceURLKey;
  if (sourceURLKey.includes('/')) {
    const [key, source] = sourceURLKey.split('/');
    encodedSourceURLKey = `${euc(key)}%2F${source}`;
  } else {
    encodedSourceURLKey = euc(sourceURLKey);
  }

  const title = item ? item.title || '-' : '-';
  const slugTitle = getSlugTitle(title);
  let encodedSlugTitle;
  try {
    encodedSlugTitle = euc(slugTitle) || '-';
  } catch (e) {
    sentry.captureException(e, { slugTitle });
    encodedSlugTitle = 'title';
  }
  if (encodedSlugTitle === null) {
    return null;
  }
  return `${prefix}${encodedSlugTitle}/${encodedSourceURLKey}`;
};

/**
 * Returns the canonical path for an article.  Resolves to the
 * "/article/:sourceURL" format
 * @param {Object} article    - Flipboard article item
 * @return {String}           - Canonical article path or null
 */
export const getCanonicalArticlePath = (item) => {
  const { sourceURL } = item;
  return (sourceURL && `/article/${euc(sourceURL)}`) || null;
};

/**
 * Returns the absolute URL for the editor view for a given magazine
 * @param {Object} magazine - Flipboard magazine
 * @return {String}
 */
export const getEditorUrl = (magazine) => {
  const { author, remoteid } = magazine;
  if (!author) {
    return null;
  }
  return `/@${author.authorUsername}/magazines/${euc(remoteid)}/edit`;
};

export const getStoryboardEditUrl = (username, storyboardId) =>
  `/@${username}/storyboards/${storyboardId}/edit`;

export const getStoryboardAnalyticsUrl = (username, storyboardId) =>
  `/@${username}/storyboards/${storyboardId}/analytics`;

export const getMagazineAnalyticsUrl = (username, magazineId) =>
  `/@${username}/magazines/${encodeURIComponent(magazineId)}/analytics`;

export const getCampaignEditUrl = (username, campaignId) =>
  `/@${username}/campaigns/${campaignId}/edit`;

export const getStoryboardAppUrl = (storyboardSkeleton) =>
  `flipboard://showSection/${storyboardSkeleton.remoteid}?feedType=bundle`;

export const isAboutFlipboardUrl = (item) => {
  if (item.sourceURL) {
    return item.sourceURL.indexOf('https://about.flipboard.com') === 0;
  }
  return false;
};

export const isCdnFlipboardUrl = (item) => {
  if (item.sourceURL) {
    return item.sourceURL.indexOf('http://cdn.flipboard.com') === 0;
  }
  return false;
};

export const getSectionShareLink = (section, currentUserid) => {
  const url = getProductionFlipboardUrl(section.canonicalPath);

  const urlObj = safeURL(url);
  if (urlObj === null) {
    return urlObj;
  }
  urlObj.searchParams.append('from', 'share');
  urlObj.searchParams.append('utm_source', 'flipboard');
  const isOwner = SectionUtil.isOwner(section, currentUserid);
  urlObj.searchParams.append('utm_medium', isOwner ? 'curator_share' : 'share');

  return urlObj.toString();
};

export const homeFeedAppUrl = 'flipboard://showHomeFeed';
export const searchAppUrl = (term) => `flipboard://showSearch/?term=${term}`;

export const getAmpUrl = (path) => {
  const urlObj = safeURL(`${Config.FLIPBOARD_URL}${path}`);
  if (urlObj === null) {
    return urlObj;
  }
  urlObj.searchParams.append('format', 'amp');
  return urlObj.toString();
};

export const getUserUrlFromUsername = (username) => {
  if (!username || typeof username !== 'string') {
    return null;
  }
  return `/@${euc(username)}`;
};
