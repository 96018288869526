import {
  usageSetNavFromSection,
  usageTrackItemEnter,
  usageTrackPublisherSiteLinkClick,
} from 'Webapp/shared/app/redux/actions/usage-actions';

export default {
  actions: {
    usageSetNavFromSection,
    usageTrackItemEnter,
    usageTrackPublisherSiteLinkClick,
  },
};
