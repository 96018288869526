import { ANALYTICS_TYPES } from 'Webapp/action-types';

interface AnalyticsProfileReducerState {
  storyboard: {
    isLoading: boolean;
  };
}

const initialState: AnalyticsProfileReducerState = {
  storyboard: {
    isLoading: false,
  },
};

export default function storyboardAnalyticsReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case ANALYTICS_TYPES.GET_PROFILE_STORYBOARD_ANALYTICS_SUCCEESS: {
      return Object.assign({}, state, {
        storyboard: Object.assign(
          {
            isLoading: false,
          },
          action.metrics,
        ),
      });
    }
    case ANALYTICS_TYPES.GET_PROFILE_STORYBOARD_ANALYTICS_FAILED: {
      return Object.assign({}, state, {
        storyboard: Object.assign({}, state.storyboard, {
          isLoading: false,
        }),
      });
    }
    case ANALYTICS_TYPES.GET_PROFILE_STORYBOARD_ANALYTICS: {
      return Object.assign({}, state, {
        storyboard: Object.assign({}, state.storyboard, {
          isLoading: true,
        }),
      });
    }
    default:
      return state;
  }
}
