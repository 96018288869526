import React from 'react';
import PropTypes from 'prop-types';
import codeSplitLoad from 'Webapp/shared/utils/code-split-load';

// Utils
import HocUtil from 'Utils/hoc-util';
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';

// Components
import { SocialActionType } from 'Webapp/enums';

import withNavFrom from '../hocs/withNavFrom';
import withSocialActionGate from '../hocs/withSocialActionGate';

import connector from 'Utils/connector';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectModal from 'Webapp/shared/app/connectors/connectModal';
import connectCurrentUser from 'Webapp/shared/app/connectors/connectCurrentUser';
import connectUsageSetNavFrom from 'Webapp/shared/app/connectors/connectUsageSetNavFrom';
import connectUsageTrackFlip from 'Webapp/shared/app/connectors/connectUsageTrackFlip';

const FlipComposeModal = codeSplitLoad('FlipComposeModal');

function withFlip(Component) {
  class WrappedComponent extends React.Component {
    constructor(props) {
      super(props);
      this.handleFlip = this.handleFlip.bind(this);
    }

    handleFlip(flipModalProps) {
      const { item, section, onClick, callback } = flipModalProps;
      const {
        isAuthenticated,
        showModal,
        navFrom,
        showSocialActionGate,
        profile,
        currentUserVerified,
        usageSetNavFrom,
        usageTrackTapFlipItem,
        usageTrackTapFlipSection,
        addPostOnboardingSocialAction,
      } = this.props;

      // Track flip tap
      if (item) {
        usageSetNavFrom(USAGE_EVENT_NAMES.TAP_FLIP, navFrom);
        usageTrackTapFlipItem(section, item, profile);
      } else if (section) {
        usageSetNavFrom(USAGE_EVENT_NAMES.TAP_FLIP, navFrom);
        usageTrackTapFlipSection(section, profile);
      }

      if (isAuthenticated && currentUserVerified) {
        if (onClick) {
          onClick();
        }
        showModal(FlipComposeModal, {
          item,
          section,
          navFrom,
        });
        callback && callback();
      } else {
        addPostOnboardingSocialAction({
          flip: { item, section, navFrom },
        });
        showSocialActionGate(SocialActionType.FLIP);
        flipModalProps.callback && flipModalProps.callback();
      }
    }

    render() {
      return <Component {...this.props} onFlip={this.handleFlip} />;
    }
  }

  // Proxy the wrapped component displayName
  WrappedComponent.displayName = HocUtil.displayName(Component);
  WrappedComponent.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    showModal: PropTypes.func.isRequired,
    navFrom: PropTypes.string,
    showSocialActionGate: PropTypes.func.isRequired,
    usageSetNavFrom: PropTypes.func.isRequired,
    usageTrackTapFlipItem: PropTypes.func.isRequired,
    usageTrackTapFlipSection: PropTypes.func.isRequired,
    profile: PropTypes.object,
    currentUserVerified: PropTypes.bool.isRequired,
    addPostOnboardingSocialAction: PropTypes.func.isRequired,
  };

  WrappedComponent.defaultProps = {
    navFrom: null,
    profile: null,
  };

  return connector(
    connectAuthentication,
    connectModal,
    connectUsageTrackFlip,
    connectUsageSetNavFrom,
    connectCurrentUser,
  )(withNavFrom(withSocialActionGate(WrappedComponent)));
}

export default withFlip;
