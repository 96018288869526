import { AppTheme } from 'Webapp/enums';

export const appTheme = ({
  app: { appTheme, appThemeOverride },
}: Flipboard.State) => {
  if (appTheme === AppTheme.DARK) {
    return appTheme;
  }
  return appThemeOverride || appTheme;
};

export const appThemeOverride = ({
  app: { appThemeOverride },
}: Flipboard.State) => appThemeOverride;

export const isAmp = ({ app: { isAmp } }: Flipboard.State) => isAmp || false;

export const isOnboarding = ({ app }: Flipboard.State) =>
  app?.routing?.query?.action?.indexOf('onboard') > -1;

export const getNglFeedConfigs = ({
  app: { nglFeedConfigs },
}: Flipboard.State) => nglFeedConfigs;

export const getAppLang = ({ app: { lang } }: Flipboard.State) => lang;

export const getLocale = ({ app: { locale } }: Flipboard.State) => locale;

export const isFromCrawler = ({ app: { isFromCrawler } }: Flipboard.State) =>
  isFromCrawler;
