import { Connector } from 'Utils/connector';

import { setNavAppStoreUrl, setAppUrl } from '../redux/actions/app';

const selectors = {
  appUrl: ({ app: { appUrl } }: Flipboard.State) => appUrl,
};

const actions = {
  setNavAppStoreUrl,
  setAppUrl,
};

export type ConnectAppUrlProps = Flipboard.ConnectorProps<
  typeof selectors,
  typeof actions
>;

const connectAppUrl: Connector = {
  selectors,
  actions,
};

export default connectAppUrl;
