import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { setIsGateDismissed } from '../redux/actions/app';
import {
  usageTrackMobileGatePickerDisplayed,
  usageTrackMobileGatePickerExit,
  usageTrackTapOpenInAppButton,
} from 'Webapp/shared/app/redux/actions/usage-actions';

// Utils
import {
  isArticleRoute,
  isSectionRoute,
  isVideoRoute,
} from '../redux/selectors/routing';

// Components
import connector from 'Utils/connector';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectAmp from 'Webapp/shared/app/connectors/connectAmp';
import connectRouting from 'Webapp/shared/app/connectors/connectRouting';

/**
 * Determines whether the mobile gate experiment is applicable.
 * See https://flipboard.atlassian.net/browse/FL-8607 for
 * full requirements.
 * @param {Boolean} isWebView - Whether we are rendering within a native app webview (iOS, Android)
 * @param {Boolean} isFromBriefing - Whether we are rendering from a briefing app link
 * @param {Boolean} isFromCrawler - Whether we are rendering for a crawler
 * @param {Boolean} isMobileGateSuppressed - Whether the gate is suppressed (by nogate=true param)
 * @param {String} path - The path being visited
 * @param {Boolean} loggedIn - Whether the user is logged in
 * @param {Boolean} mobile - Whether the browser viewport is mobile
 * @param {Boolean} isAmp - Whether rendering AMP or not
 * @param {Boolean} isDismissed - Whether the gate has been dismissed
 * @param {Boolean} isEmbeddable - Whether we are rendering within an embedabble layout
 * by the user
 * @return {Boolean}  - True if the experiment is applicable
 */
function shouldShowMobileGate(
  isWebView,
  isFromBriefing,
  isFromCrawler,
  isMobileGateSuppressed,
  routeApplicable,
  loggedIn,
  mobile,
  isAmp,
  isDismissed,
  isEmbeddable,
  isFromBranchInvite,
  magazineInvite,
) {
  return !!(
    !isWebView &&
    !isFromBriefing &&
    !isFromCrawler &&
    !isMobileGateSuppressed &&
    routeApplicable &&
    !loggedIn &&
    mobile &&
    !isAmp &&
    !isDismissed &&
    !isEmbeddable &&
    !isFromBranchInvite &&
    !magazineInvite?.token
  );
}

const mapStateToProps = (state, { isPhone, isAmp, isFromBranchInvite }) => {
  const {
    app: {
      isGateDismissed,
      isMobileGateSuppressed,
      isWebView,
      isFromCrawler,
      isFromBriefing,
      isEmbeddable,
      readyForMetrics,
    },
    magazineInvite,
    auth,
  } = state;

  const showGate = shouldShowMobileGate(
    isWebView,
    isFromBriefing,
    isFromCrawler,
    isMobileGateSuppressed,
    isSectionRoute(state) || (isArticleRoute(state) && !isVideoRoute(state)),
    auth.uid !== 0,
    isPhone,
    isAmp,
    isGateDismissed,
    isEmbeddable,
    isFromBranchInvite,
    magazineInvite,
  );

  return {
    readyForMetrics,
    shouldShowMobileGate: showGate,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      usageTrackMobileGatePickerDisplayed,
      usageTrackMobileGatePickerExit,
      usageTrackTapOpenInAppButton,
      dismissGate: () => dispatch(setIsGateDismissed(true)),
    },
    dispatch,
  );

const MobileGateContainer = (Component) =>
  connector(
    connectAmp,
    connectResponsive,
    connectRouting,
  )(connect(mapStateToProps, mapDispatchToProps)(Component));

export default MobileGateContainer;
