import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

import AcceptContributorInvite from 'Webapp/shared/app/modals/accept-contributor-invite';
import { AcceptMagazineInviteError } from 'Utils/errors';

// actions
import {
  showModal,
  dismissSpecificModal,
} from 'Webapp/shared/app/redux/actions/modal-actions';
import {
  onboardingDataSelector,
  setOnboardingData,
} from 'Webapp/shared/concepts/onboarding';

const modalAcceptMagazineInvite: OnboardingStep =
  (nextStep) => (dispatch, getState) => {
    const onboardingData = onboardingDataSelector(getState());
    if (!onboardingData?.magazineInviteSection) {
      throw new AcceptMagazineInviteError(
        'Accept Magazine Invite Flow is missing magazineInviteSection',
      );
    }
    const section = onboardingData.magazineInviteSection;

    const acceptMagazineInviteConsented =
      onboardingData?.acceptMagazineInviteConsented;
    if (acceptMagazineInviteConsented) {
      return nextStep();
    }
    return dispatch(
      showModal(AcceptContributorInvite, {
        section,
        invite: section.invite,
        onComplete: () => {
          // Probably we can replace this with a merge/update version of setOnboardingData
          dispatch(
            setOnboardingData({
              ...onboardingData,
              acceptMagazineInviteConsented: true,
            }),
          );
          dispatch(dismissSpecificModal(AcceptContributorInvite));
          nextStep();
        },
      }),
    );
  };

export default modalAcceptMagazineInvite;
