import { getTopics } from 'Utils/content/item-util';

const durationSize = (seconds) => {
  if (seconds >= 0 && seconds <= 60) {
    return 'xs';
  } else if (seconds >= 61 && seconds <= 180) {
    return 's';
  } else if (seconds >= 181 && seconds <= 420) {
    return 'm';
  } else if (seconds >= 421 && seconds <= 720) {
    return 'l';
  } else if (seconds >= 721 && seconds <= 1199) {
    return 'xl';
  } else if (seconds >= 1200) {
    return 'lf';
  }
  return 'unknown';
};

export default (item = {}, section) => ({
  video_duration: durationSize(item.duration),
  section_id: section && section.id,
  topics: getTopics(item).map((t) => t.topicTag),
  publisher_id: item.partnerID,
});
