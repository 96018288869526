import React from 'react';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';

import codeSplitLoad from 'Webapp/shared/utils/code-split-load.js';

import {
  MODAL_THEMES,
  MODAL_WINDOW_SIZES,
} from 'ComponentLibrary/modals/modal';

import ModalWrapper from 'Webapp/shared/app/modals/modal-wrapper';
import ModalControls from 'Webapp/shared/app/components/modal-controls';

import connector from 'Utils/connector';
import connectFeatureFlags, {
  ConnectFeatureFlagsProps,
} from 'Webapp/shared/app/connectors/connectFeatureFlags';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';

import withT from 'ComponentLibrary/hocs/withT';

const EmbedTools = codeSplitLoad('EmbedTools');
const StyledModalWrapper = styled(ModalWrapper)({
  padding: SPACING.LARGE,
  overflow: 'auto',
});

type EmbedToolsModalProps = {
  section?: Flipboard.Section;
} & ConnectFeatureFlagsProps &
  ConnectModalProps;

const EmbedToolsModal: React.FC<EmbedToolsModalProps> = ({
  section,
  dismissModal,
}) => (
  <StyledModalWrapper
    name="flip-compose"
    modalTheme={MODAL_THEMES.WINDOW}
    size={MODAL_WINDOW_SIZES.AUTO}
  >
    <EmbedTools section={section} />
    <ModalControls
      showCloseButton
      closeButtonSize={16}
      onDismiss={dismissModal}
      modalName="comments-verify-email"
    />
  </StyledModalWrapper>
);

export default connector(
  connectFeatureFlags,
  connectModal,
)(withT(EmbedToolsModal));
