import chunk from 'lodash/chunk';
import uniqBy from 'lodash/uniqBy';
import Config from 'Webapp/shared/config';

// Utils
import sentry from 'Utils/sentry';
import FlapUtil from 'Utils/content/flap-util';
import TopicUtil from 'Utils/content/topic';
import SectionUtil from 'Utils/content/section-util';
import { PromiseAll } from 'Utils/promise';
import {
  userStateDataSelector,
  userStateRevisionSelector,
  userStateFollowingIdsSelector,
  smartMagazineSelector,
} from 'Webapp/shared/app/redux/selectors/profile';
import { GA } from 'Utils/analytics';
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';
import getWindow from 'Utils/get-window';
import { InvalidUIDError, PutStateError } from 'Utils/errors';
import { isUIDValid } from 'Utils/is-uid-valid';
import { pick } from 'Utils/object-util';
import { set } from 'Utils/redux';
import { getSmartMagazineSection } from 'Utils/content/profile-util';
import { normalizeToArray } from 'Utils/normalize-to-array';

import { PROFILE_TYPES } from 'Webapp/action-types';
import { Services } from 'Webapp/enums';
import { setNotFound, setIsNewUser } from './app';
import {
  getSection,
  setSectionStale,
  getBasicSections,
} from './section-actions';
import { toastShowInfoAction, toastShowErrorAction } from './toast-actions';
import flapUpdateFeed from 'Utils/api/flap/endpoints/update-feed';

import {
  usageSetNavFrom,
  usageTrackSubscribeSection,
  usageTrackSignupActivated,
  usageTrackUnsubscribeSection,
} from 'Webapp/shared/app/redux/actions/usage-actions';

const { URLSearchParams } = getWindow();

export const fetchUserInfo = () =>
  async function (dispatch, getState, { flap }) {
    const {
      auth: { uid },
    } = getState();

    try {
      dispatch({ type: PROFILE_TYPES.GET_PROFILE_INFO });
      const params = { states: 'user', usessid: true, refreshFlipboard: true };
      if (!isUIDValid(uid)) {
        return;
      }
      const { data } = await flap.get(`/flipboard/userInfo/${uid}`, { params });
      if (
        FlapUtil.isAccessTokenExpired(data) ||
        data.success === false ||
        !data.userInfo
      ) {
        dispatch({ type: PROFILE_TYPES.GET_PROFILE_UNAUTHORIZED });
      } else {
        dispatch({
          type: PROFILE_TYPES.GET_PROFILE_INFO_SUCCESS,
          data: data.userInfo,
        });
        dispatch({
          type: PROFILE_TYPES.UPDATE_FOLLOWING_IDS,
        });
      }
    } catch (error) {
      if (error instanceof InvalidUIDError) {
        sentry.captureMessage(
          'InvalidUIDError in profile-actions fetchUserInfo',
        );
      }
      sentry.addBreadcrumb('fetchUserInfo');
      dispatch(setNotFound(true));
    }
  };

export const fetchProfileSmartMagazines = () =>
  async function (dispatch, getState, { flap }) {
    const {
      auth: { uid },
    } = getState();

    try {
      const params = { usessid: true };
      const { data } = await flap.get(`/flipboard/boards/${uid}`, { params });
      dispatch({
        type: PROFILE_TYPES.GET_PROFILE_SMART_MAGAZINES_SUCCESS,
        data: data.results,
      });
    } catch (error) {
      sentry.addBreadcrumb('fetchProfileSmartMagazines');
      dispatch(setNotFound(true));
    }
  };

export const fetchSmartMagazineCovers = (remoteids) =>
  async function (dispatch, getState, { flap }) {
    const {
      auth: { uid },
    } = getState();

    try {
      const params = {
        sections: remoteids.join(','),
        // Magazine covers are based on items in the stream. Limit to 3, in case the first few items don't have an image
        limit: 3,
      };
      const { data } = await flapUpdateFeed(flap, uid, params);
      const items = data.stream;
      const covers = FlapUtil.getSectionCoversFromStream(remoteids, items);

      dispatch({
        type: PROFILE_TYPES.GET_SMART_MAGAZINES_COVERS_SUCCESS,
        covers,
      });
    } catch (e) {
      sentry.captureException(e);
      dispatch({ type: PROFILE_TYPES.GET_SMART_MAGAZINES_COVERS_FAILED });
    }
  };

const fetchProfileCommunityGroups = () =>
  async function (dispatch, getState, { flap }) {
    const {
      auth: { uid },
    } = getState();

    try {
      const { success, errormessage, data } = await flap.get(
        `/community/member/${uid}`,
      );

      if (success === false) {
        return dispatch({
          type: PROFILE_TYPES.GET_PROFILE_COMMUNITY_GROUPS_FAILED,
          errormessage,
        });
      }

      dispatch({
        type: PROFILE_TYPES.GET_PROFILE_COMMUNITY_GROUPS_SUCCESS,
        data: data.communities,
      });
    } catch (_) {
      dispatch({ type: PROFILE_TYPES.GET_PROFILE_COMMUNITY_GROUPS_FAILED });
    }
  };

const fetchProfileStats = () =>
  async function (dispatch, getState, { flap }) {
    const {
      auth: { uid },
    } = getState();

    try {
      const params = {
        oid: `flipboard-_posts_:m:${uid}-0`,
        service: 'flipboard',
      };
      const { success, errormessage, data } = await flap.get(
        `/social/activity/${uid}`,
        { params },
      );

      if (success === false) {
        // TODO: Listen for GET_PROFILE_STATS_FAILED in reducer,render Toast
        return dispatch({
          type: PROFILE_TYPES.GET_PROFILE_STATS_FAILED,
          errormessage,
        });
      }

      dispatch({
        type: PROFILE_TYPES.GET_PROFILE_STATS_SUCCESS,
        data: data.items[0] || [],
      });
    } catch (_) {
      dispatch({ type: PROFILE_TYPES.GET_PROFILE_STATS_FAILED });
    }
  };

export const fetchProfileFollowers = (pageKey = null) =>
  async function (dispatch, getState, { flap }) {
    const {
      auth: { uid },
    } = getState();

    try {
      const params = {
        userid: uid,
        serviceUserid: uid,
        service: 'flipboard',
        limit: 30,
      };
      if (pageKey) {
        params.pageKey = pageKey;
      }
      dispatch({ type: PROFILE_TYPES.GET_PROFILE_FOLLOWERS });
      const { success, errormessage, data } = await flap.get(
        `/social/followers/${uid}`,
        { params },
      );

      if (success === false) {
        return dispatch({
          type: PROFILE_TYPES.GET_PROFILE_FOLLOWERS_FAILED,
          errormessage,
        });
      }

      dispatch({
        type: PROFILE_TYPES.GET_PROFILE_FOLLOWERS_SUCCESS,
        data: data.items,
        nextPageKey: data.pageKey || null,
        isFirstPage: !pageKey,
      });
    } catch (_) {
      dispatch({ type: PROFILE_TYPES.GET_PROFILE_FOLLOWERS_FAILED });
    }
  };

const FOLLOWING_SAMPLE_SIZE = 12;
const FOLLOWING_BATCH_CHUNK_SIZE = 24;
export const fetchFollowing =
  ({ sample } = { sample: false }) =>
  async (dispatch, getState, { flap }) => {
    dispatch(set(PROFILE_TYPES.GET_FOLLOWING, 'followingLoading', true));
    const {
      auth: { uid },
      profile: { followingIds },
    } = getState();
    if (!followingIds || followingIds.length === 0) {
      return null;
    }

    const idsChunks = sample
      ? [followingIds.slice(0, FOLLOWING_SAMPLE_SIZE)]
      : chunk(followingIds, FOLLOWING_BATCH_CHUNK_SIZE);

    const stream = await PromiseAll(
      idsChunks.map(async (chunk) => {
        const params = {
          sections: chunk.join(','),
        };
        try {
          const { data } = await flapUpdateFeed(flap, uid, params);
          if (data && data.stream) {
            return data.stream;
          }
        } catch (e) {
          sentry.captureException(e);
        }
        return null;
      }),
    );

    const actionType = sample
      ? PROFILE_TYPES.GET_FOLLOWING_SAMPLE_SUCCESS
      : PROFILE_TYPES.GET_FOLLOWING_SUCCESS;

    dispatch(
      set(
        actionType,
        'following',
        uniqBy(
          FlapUtil.getSectionMetadataItemsFromStream(stream)
            .map((s) => SectionUtil.projection(s.section))
            .filter((s) => !s.isSmartMagazine),
          'normalizedRemoteid',
        ),
      ),
    );
  };

const fetchFavorites = () =>
  async function (dispatch, getState, { flap }) {
    const {
      auth: { uid },
    } = getState();

    try {
      const params = { userid: uid, usessid: true };
      const { success, errormessage, data } = await flap.get(
        `/userstate/getCarouselFavorites/${uid}`,
        { params },
      );

      if (success === false) {
        // TODO: Listen for GET_FAVORITES_FAILED in reducer, render Toast (coming in settings PR)
        return dispatch({
          type: PROFILE_TYPES.GET_FAVORITES_FAILED,
          errormessage,
        });
      }

      dispatch({
        type: PROFILE_TYPES.GET_FAVORITES_SUCCESS,
        data: data.results,
        version: data.version,
      });
      return data;
    } catch (_) {
      dispatch({ type: PROFILE_TYPES.GET_FAVORITES_FAILED });
    }
  };

const getProfileSection = (remoteId, section = {}, options = {}) =>
  getSection(remoteId, {
    path: '/profile',
    pageKey: section.nextPageKey,
    previousRawItems: section.rawItems,
    ephemeral: false,
    ...options,
  });

export const getProfileFlips = (flipsForId, section) =>
  getProfileSection(FlapUtil.getRemoteidByUserid(flipsForId), section);

export const getProfileVideos = (videosForId, section) =>
  getProfileSection(FlapUtil.getProfileVideoRemoteid(videosForId), section);

export const getLikes = (uid, likesUid, section = {}) => {
  const path = '/profile/likes';
  const isOwnLikes = Number(uid) === Number(likesUid);
  const remoteid = isOwnLikes
    ? FlapUtil.profileLikesRemoteId()
    : FlapUtil.profileLikesRemoteId(likesUid);
  return getProfileSection(remoteid, section, {
    path,
    ephemeral: !isOwnLikes,
  });
};

export const addPostOnboardingFavorites = () => async (dispatch, getState) => {
  try {
    const { featureFlags, profile } = getState();
    if (!featureFlags.POST_ONBOARDING_FAVORITES) {
      return null;
    }
    const sectionsToAdd = profile.followingIds
      .filter(
        (remoteid) =>
          !Config.POST_ACTIVATION_AUTO_FOLLOW_REMOTE_IDS.includes(remoteid),
      )
      .slice(0, 3);
    for (const remoteid of sectionsToAdd) {
      // we don't do this in parallel because we need carouselVersion
      // to be in sync
      await dispatch(addToCarousel(remoteid));
    }
  } catch (e) {
    sentry.captureException(e);
  }
};

export const addToCarousel = (sectionId, title) =>
  async function (dispatch, getState, { flap }) {
    const {
      auth: { uid },
      profile: { carouselVersion },
    } = getState();

    try {
      const body = {
        title: title || null,
        sectionId,
        version: carouselVersion,
        userid: uid,
      };
      dispatch({
        type: PROFILE_TYPES.ADD_CAROUSEL_FAVORITE_PENDING,
      });
      const { data } = await flap.post(
        `/userstate/addCarouselFavorites/${uid}`,
        body,
      );
      dispatch({
        type: PROFILE_TYPES.ADD_CAROUSEL_FAVORITE_SUCCESS,
        version: data.version,
      });
      return dispatch(fetchFavorites());
    } catch (e) {
      dispatch({
        type: PROFILE_TYPES.ADD_CAROUSEL_FAVORITE_FAILURE,
      });
      throw e;
    }
  };

export const recordRecentlyPersonalizedTopic = (sectionId) =>
  set(
    PROFILE_TYPES.SET_RECENTLY_PERSONALIZED_TOPIC,
    'lastFavoritedSectionId',
    sectionId,
  );

export const clearRecentlyPersonaizedTopic = () =>
  set(
    PROFILE_TYPES.SET_RECENTLY_PERSONALIZED_TOPIC,
    'lastFavoritedSectionId',
    null,
  );

export const deleteFromCarousel = (sectionId) =>
  async function (dispatch, getState, { flap, t }) {
    const {
      auth: { uid },
      profile: { carouselVersion },
    } = getState();

    try {
      dispatch({
        type: PROFILE_TYPES.DELETE_CAROUSEL_FAVORITE_PENDING,
      });

      const { data } = await flap.post('/userstate/removeCarouselFavorites', {
        sectionId,
        userid: uid,
        version: carouselVersion,
      });

      // Add some guarding, in case Flap returns an unsuccessful deletion of carousel favorites.
      if (!data.success) {
        dispatch({
          type: PROFILE_TYPES.DELETE_CAROUSEL_FAVORITE_FAILURE,
        });
        sentry.captureMessage('Delete carousel favorite failed');
        throw new Error();
      }
      dispatch({
        type: PROFILE_TYPES.DELETE_CAROUSEL_FAVORITE_SUCCESS,
        sectionId,
        version: data.version,
      });
    } catch (error) {
      dispatch({ type: PROFILE_TYPES.DELETE_CAROUSEL_FAVORITE_FAILURE });
      dispatch(toastShowInfoAction(t('favorite_delete_failure')));
    }
  };

export const moveCarouselFavorite = (fromIndex, toIndex) =>
  async function (dispatch, getState, { flap }) {
    const {
      auth: { uid },
      profile: { carouselVersion },
    } = getState();

    try {
      dispatch({
        type: PROFILE_TYPES.MOVE_CAROUSEL_FAVORITE_PENDING,
        fromIndex,
        toIndex,
      });

      const { data } = await flap.post('/userstate/moveCarouselFavorite', {
        userid: uid,
        fromIndex,
        toIndex,
        version: carouselVersion,
      });

      if (!data.success) {
        dispatch({
          type: PROFILE_TYPES.MOVE_CAROUSEL_FAVORITE_FAILURE,
        });
        sentry.captureMessage('Move carousel favorite failed');
        throw new Error();
      }
      dispatch({
        type: PROFILE_TYPES.MOVE_CAROUSEL_FAVORITE_SUCCESS,
        version: data.version,
      });
    } catch (_) {
      dispatch({ type: PROFILE_TYPES.MOVE_CAROUSEL_FAVORITE_FAILURE });
    }
  };

const presentAsTOCSection = (s) =>
  pick(s, [
    'title',
    'sectionTitle',
    'remoteid',
    'service',
    'ssid',
    'authorDisplayName',
    'authorDescription',
    'userid',
    'fromUserId',
    'fromInvite',
  ]);

export const setFollowedTopics =
  (topics, navFrom, method) => async (dispatch, getState) =>
    dispatch(
      setFollowing(() => {
        const state = getState();
        const currentUserState = userStateDataSelector(state);
        const tocSections =
          (currentUserState && currentUserState.tocSections) || [];

        const newTOCSections = [
          ...tocSections.filter(
            (s) => s.remoteid.indexOf('flipboard/topic') === -1,
          ),
          ...topics
            .map(TopicUtil.decorateForFollowing)
            .map(presentAsTOCSection),
        ];
        return { newTOCSections, navFrom, viewedSection: null, method };
      }),
    );

export const setFollowing =
  (setFollowingFn) =>
  async (dispatch, getState, { t, flap }) => {
    const followSections = [];
    const unfollowSections = [];
    let navFrom, viewedSection, method;
    try {
      await dispatch(
        putState(async () => {
          const result = await setFollowingFn();
          const { newTOCSections } = result;
          navFrom = result.navFrom;
          viewedSection = result.viewedSection;
          method = result.method;
          const state = getState();
          const currentUserState = userStateDataSelector(state);

          const existingTOCSections = currentUserState?.tocSections || [];

          existingTOCSections.forEach((s) => {
            if (!SectionUtil.sectionsIncludesSection(newTOCSections, s)) {
              unfollowSections.push(s);
            }
          });
          newTOCSections.forEach((s) => {
            if (!SectionUtil.sectionsIncludesSection(existingTOCSections, s)) {
              followSections.push(s);
            }
          });

          if (unfollowSections.length === 0 && followSections.length === 0) {
            return;
          }
          const newState = Object.assign({}, currentUserState, {
            tocSections: newTOCSections,
          });
          if (unfollowSections.length > 0) {
            unfollowSections.forEach((s) => {
              dispatch({
                type: PROFILE_TYPES.UNFOLLOW_SECTION,
                remoteId: s.remoteid,
              });
            });
            dispatch(
              cleanupPersonalizedSections(
                unfollowSections.map((s) => s.remoteid),
              ),
            );
          }
          if (followSections.length > 0) {
            dispatch({
              type: PROFILE_TYPES.FOLLOW_SECTION,
              remoteIds: followSections.map((s) => s.remoteid),
            });
          }
          return newState;
        }),
      );

      let message;
      if (followSections.length > 0) {
        if (followSections.length === 1) {
          message = t('follow_section_success', {
            sectionTitle: followSections[0].title,
          });
        } else {
          message = t('follow_multiple_sections_success', {
            count: followSections.length,
          });
        }
        dispatch({ type: PROFILE_TYPES.FOLLOW_SECTION_SUCCESS });
        followSections.forEach((section) => {
          if (navFrom) {
            dispatch(
              usageSetNavFrom(USAGE_EVENT_NAMES.SECTION_SUBSCRIBE, navFrom),
            );
          }
          dispatch(usageTrackSubscribeSection(section, viewedSection, method));

          // if section is activitypub, call social/follow endpoint
          try {
            if (section.service === Services.ACTIVITYPUB) {
              flap.post('/social/follow', {
                service: section.service,
                userid: section.userid,
                serviceUserid: section.userid,
              });
            }
          } catch (e) {
            sentry.captureException(e);
          }
        });
      }
      if (unfollowSections.length > 0) {
        if (unfollowSections.length === 1) {
          message = t('unfollow_section_success', {
            sectionTitle: unfollowSections[0].title,
          });
        } else {
          message = t('unfollow_multiple_sections_success', {
            count: unfollowSections.length,
          });
        }

        dispatch({ type: PROFILE_TYPES.UNFOLLOW_SECTION_SUCCESS });
        unfollowSections.forEach((section) => {
          if (navFrom) {
            dispatch(
              usageSetNavFrom(USAGE_EVENT_NAMES.SECTION_UNSUBSCRIBE, navFrom),
            );
          }
          dispatch(
            usageTrackUnsubscribeSection(section, viewedSection, method),
          );

          // if section is activitypub, call social/unfollow endpoint
          try {
            if (section.service === Services.ACTIVITYPUB) {
              flap.post('/social/unfollow', {
                service: section.service,
                userid: section.userid,
                serviceUserid: section.userid,
              });
            }
          } catch (e) {
            sentry.captureException(e);
          }
        });
      }

      if (followSections.length > 0 && unfollowSections.length > 0) {
        message = t('updated_following_success');
      }

      if (message) {
        dispatch(toastShowInfoAction(message));
      }
    } catch (error) {
      let message;
      sentry.captureException(error);
      if (unfollowSections.length > 0 && followSections.length > 0) {
        message = t('updated_following_failure');
      } else {
        if (followSections.length > 0) {
          if (followSections.length === 1) {
            message = t('follow_section_failure', {
              sectionTitle: followSections[0].title,
            });
          } else {
            message = t('follow_multiple_sections_failure', {
              count: followSections.length,
            });
          }
        } else if (unfollowSections.length > 0) {
          if (unfollowSections.length === 1) {
            message = t('unfollow_section_failure', {
              sectionTitle: unfollowSections[0].title,
            });
          } else {
            message = t('unfollow_multiple_sections_failure', {
              count: unfollowSections.length,
            });
          }
        }
      }
      if (message) {
        dispatch(toastShowErrorAction(message));
      }
      dispatch(fetchUserInfo());
      return Promise.reject();
    }
  };

/**
 * Follows a section or an array of sections
 * @param {Array} sections        - Array of section objects (cast to Array if an object is supplied)
 * @param {String} navFrom        - (optional) usage navFrom value
 * @param {Object} viewedSection  - (optional) Projected section object corresponding
 * to the "viewed" section.  Used when following the author of a section.  In that case,
 * "section" would be the author and "viewedSection" would be the mag/storyboard being
 * viewed at the time.
 * @return {Promise}              - A promise that resolves as an object
 */
export const followSections =
  (sections, navFrom, viewedSection = null) =>
  (dispatch, getState) =>
    dispatch(
      setFollowing(() => {
        const state = getState();
        const currentState = userStateDataSelector(state);
        const tocSections = (currentState && currentState.tocSections) || [];
        const followedSections =
          normalizeToArray(sections).map(presentAsTOCSection);

        const newTOCSections = [...tocSections, ...followedSections];
        return { newTOCSections, navFrom, viewedSection };
      }),
    );

/**
 * Follow all post-activation "auto-follow" sections
 */
const followPostActivationSections = () =>
  async function (dispatch, getState) {
    const state = getState();

    // only follow if not already followed
    const followingIds = userStateFollowingIdsSelector(state);
    const remoteidsToFollow =
      Config.POST_ACTIVATION_AUTO_FOLLOW_REMOTE_IDS.filter(
        (remoteid) => !followingIds.includes(remoteid),
      );
    if (remoteidsToFollow.length === 0) {
      return null;
    }

    const sectionsToFollow = await dispatch(
      getBasicSections(remoteidsToFollow),
    );
    return dispatch(followSections(sectionsToFollow));
  };

/**
 * - Sets 'isNewUser' state to false, tracks activation in
 * - Follows a set of default sections
 * - Tracks activation in Usage and Google Analytics
 */
export const performPostActivationActions = () =>
  async function (dispatch) {
    dispatch(followPostActivationSections()).then(() =>
      dispatch(addPostOnboardingFavorites()),
    );
    dispatch(setIsNewUser(false));
    dispatch(usageTrackSignupActivated());
    GA.trackFirstLaunchActivated();
  };

/**
 * Fetches all data needed to display the Profile view in parallel
 * @param {Number} uid
 */
export const fetchFullProfile = (uid) => async (dispatch) => {
  dispatch({ type: PROFILE_TYPES.GET_PROFILE_PENDING });

  return await PromiseAll([
    // cause a full refresh of the user info so we get up-to-date verification status
    dispatch(fetchUserInfo()),
    dispatch(fetchProfileSmartMagazines()),
    dispatch(fetchProfileCommunityGroups()),
    dispatch(fetchProfileStats()),
    dispatch(fetchProfileFollowers()),
    dispatch(fetchFavorites()),
    dispatch(getProfileFlips(uid)),
  ]).then((responses) => {
    dispatch({ type: PROFILE_TYPES.GET_PROFILE_SUCCESS });
    return responses;
  });
};

export const createSmartMagazine = (topic, customizations) =>
  async function (dispatch, _getState, { flap, t }) {
    let smartMag;
    try {
      const body = {
        rootTopic: topic.remoteid,
        title: topic.title,
        usessid: true,
      };
      const params = new URLSearchParams();
      customizations.forEach((c) => params.append('addSection', c.remoteid));
      const {
        data: { results },
      } = await flap.post('/flipboard/createBoard', body, {
        params,
      });
      smartMag = results[0];

      if (!smartMag) {
        dispatch({ type: PROFILE_TYPES.CREATE_SMART_MAGAZINE_FAILED });
        throw new Error();
      }

      dispatch({ type: PROFILE_TYPES.CREATE_SMART_MAGAZINE_SUCCESS });
      dispatch(
        toastShowInfoAction(
          t('smart_magazine_create_success', {
            topicTitle: topic.title,
          }),
        ),
      );
    } catch (error) {
      return dispatch(toastShowErrorAction(t('smart_magazine_create_failure')));
    }

    dispatch(setSectionStale(topic));
    dispatch(fetchProfileSmartMagazines());
    dispatch(fetchUserInfo());

    return Promise.resolve(smartMag);
  };

export const addSmartMagazineToFavorites = (smartMag) =>
  async function (dispatch, getState) {
    try {
      const {
        profile: { favorites },
      } = getState();
      const isInCarouselFavorites = SectionUtil.isInCarouselFavorites(
        smartMag,
        favorites,
      );
      if (!isInCarouselFavorites) {
        await dispatch(addToCarousel(smartMag.remoteid, smartMag.title));
        dispatch(recordRecentlyPersonalizedTopic(smartMag.remoteid));
        dispatch({
          type: PROFILE_TYPES.ADD_SMART_MAGAZINE_CAROUSEL_SUCCESS,
          remoteid: smartMag.remoteid,
        });
      }
      return Promise.resolve(SectionUtil.projection(smartMag));
    } catch (error) {
      dispatch(
        toastShowErrorAction(
          `Failed to add ${smartMag.title} to favorites. Please try again later`,
        ),
      );
    }
  };

export const updateSmartMagazine = (topic, addSection, removeSection) =>
  async function (dispatch, _, { flap, t }) {
    try {
      const { boardId, version } = topic;
      const body = { boardId, version };
      const params = new URLSearchParams();
      addSection.forEach((s) => params.append('addSection', s));
      removeSection.forEach((s) => params.append('removeSection', s));
      const {
        data: { results },
      } = await flap.post('/flipboard/updateBoard', body, {
        params,
      });
      const smartMag = results[0];

      if (!smartMag) {
        throw new Error();
      }

      dispatch({ type: PROFILE_TYPES.UPDATE_SMART_MAGAZINE_SUCCESS, smartMag });
      dispatch(toastShowInfoAction(t('smart_magazine_update_success')));
      dispatch(setSectionStale(topic));
      dispatch(fetchProfileSmartMagazines());
      dispatch(fetchUserInfo());
    } catch (error) {
      dispatch({ type: PROFILE_TYPES.UPDATE_SMART_MAGAZINE_FAILED });
      dispatch(toastShowErrorAction(t('smart_magazine_update_failure')));
    }
  };

export const cleanupPersonalizedSections =
  (remoteIds) =>
  async (dispatch, getState, { flap }) => {
    const {
      auth: { uid },
    } = getState();

    const params = {
      sections: remoteIds.join(','),
    };
    const { data } = await flapUpdateFeed(flap, uid, params);

    if (data && data.stream) {
      const sections = FlapUtil.getSectionMetadataItemsFromStream(
        data.stream,
      ).map((m) => SectionUtil.getBasicProjectedSection(m.section));
      sections.forEach((s) => {
        dispatch(deleteFavorite(s));
        dispatch(deleteSmartMagazine(s));
      });
    }
  };

export const deleteFavorite = (section) =>
  async function (dispatch, getState) {
    const {
      profile: { favorites, smartMagazines },
    } = getState();
    const remoteIdInCarouselFavorites = SectionUtil.isInCarouselFavorites(
      section,
      favorites,
      smartMagazines,
    );
    if (remoteIdInCarouselFavorites) {
      await dispatch(deleteFromCarousel(remoteIdInCarouselFavorites));
    }
  };

export const deleteSmartMagazine = (section) =>
  async function (dispatch, getState, { flap, t }) {
    const {
      profile: { smartMagazines },
    } = getState();

    const smartMagazine = getSmartMagazineSection(section, smartMagazines);
    if (!smartMagazine) {
      return;
    }

    try {
      const { data } = await flap.post('/flipboard/deleteBoards', {
        boardId: smartMagazine.boardId,
      });
      dispatch({
        type: PROFILE_TYPES.DELETE_SMART_MAGAZINE_SUCCESS,
        boardId: smartMagazine.boardId,
      });
      dispatch(
        toastShowInfoAction(
          t('smart_magazine_delete_success', {
            smartMagazineTitle: smartMagazine.title,
          }),
        ),
      );
      dispatch(setSectionStale(section));
      dispatch(fetchUserInfo());
      return Promise.resolve(data);
    } catch (error) {
      sentry.captureException(error);

      dispatch(toastShowErrorAction(t('smart_magazine_delete_failure')));
    }
  };

/**
 * Unfollows a section
 * @param {Object} section        - Projected section object
 * @param {String} navFrom        - (optional) usage navFrom value
 * @param {Object} viewedSection  - (optional) Projected section object corresponding
 * to the "viewed" section.  Used when following the author of a section.  In that case,
 * "section" would be the author and "viewedSection" would be the mag/storyboard being
 * viewed at the time.
 * @return {Promise}              - A promise that resolves as an object
 */
export const unfollowSection =
  (section, navFrom, viewedSection = null) =>
  async (dispatch, getState) => {
    let smartMagazineSection;
    await dispatch(
      setFollowing(() => {
        const state = getState();
        const currentState = userStateDataSelector(state);
        const tocSections = currentState.tocSections || [];
        const {
          profile: { followingIds },
        } = state;

        /**
         * Find the corresponding remoteid stored in the favorites list.
         */
        smartMagazineSection = getSmartMagazineSection(
          section,
          smartMagazineSelector(state),
        );

        const remoteid = followingIds.find((id) => {
          const normalizedId = FlapUtil.normalizeRemoteid(id);
          if (
            FlapUtil.normalizeRemoteid(section.rootTopic?.remoteid) ===
              normalizedId ||
            FlapUtil.normalizeRemoteid(section.remoteid) === normalizedId ||
            FlapUtil.normalizeRemoteid(smartMagazineSection?.remoteid) ===
              normalizedId ||
            FlapUtil.normalizeRemoteid(section.ssid?.remoteidPlain) ===
              normalizedId ||
            FlapUtil.normalizeRemoteid(section?.sectionID) === normalizedId
          ) {
            return id;
          }
        });

        const newTOCSections = tocSections.filter(
          (s) =>
            !(
              s.remoteid.includes(remoteid) ||
              s.ssid?.remoteidPlain.includes(remoteid)
            ),
        );
        return { newTOCSections, navFrom, viewedSection };
      }),
    );
    if (smartMagazineSection) {
      await dispatch(deleteSmartMagazine(smartMagazineSection));
    }
  };

let putStateLock = false;
export const putState =
  (newStateFn) =>
  async (dispatch, getState, { flap }) => {
    try {
      if (putStateLock) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(dispatch(putState(newStateFn)));
          }, 10);
        });
      }
      putStateLock = true;
      const newState = await newStateFn();
      if (!newState) {
        putStateLock = false;
        return;
      }
      const revision = userStateRevisionSelector(getState());
      const { data } = await flap.post('/social/putState', {
        data: JSON.stringify(newState),
        revision,
        type: 'user',
      });
      if (!data?.success) {
        throw new PutStateError();
      }
      // update userInfo based on server's version
      // this covers TOC, revision, etc
      await dispatch(fetchUserInfo());
      putStateLock = false;
      return newState;
    } catch (error) {
      putStateLock = false;
      sentry.captureException(error);
      throw error;
    }
  };
