import React from 'react';
import styled from '@emotion/styled';
import Icon from './icon';

const StyledIcon = styled(Icon)({
  color: 'var(--color--icon)',
  path: {
    color: 'inherit',
    fill: 'currentColor',
    stroke: 'currentColor',
  },
  g: {
    color: 'inherit',
    stroke: 'currentColor',
  },
});
function RemoveIcon(props) {
  return (
    <StyledIcon
      name="remove"
      {...props}
      renderComponent={({ color }) => (
        <g
          fill="none"
          transform="translate(-3.000000, -3.000000)"
          stroke={color}
          className="icon-color"
        >
          <g transform="translate(3.000000, 3.000000)">
            <circle cx="9" cy="9" r="8.5" />
            <path
              d="M6.5,9 L11.5,9"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
          </g>
        </g>
      )}
    />
  );
}

export default RemoveIcon;
