import {
  SUBSCRIPTION_IMPLICIT_OPT_OUT_STATE,
  SUBSCRIPTION_IMPLICIT_SUBSCRIBE_STATE,
  SUBSCRIPTION_EXPLICIT_SUBSCRIBE_STATE,
  allGroup,
  allSubscription,
  everything,
  subscriptionMatches,
} from 'Webapp/shared/utils/email-settings';
import { query } from 'Webapp/shared/app/redux/selectors/routing';

const subscribed = (states, group, subscription) => {
  const foundSub = states.find(
    (state) => state.group === group.group && state.key === subscription.key,
  );
  // state defaults to SUBSCRIPTION_IMPLICIT_OPT_OUT_STATE
  const state =
    (foundSub && foundSub.state) || SUBSCRIPTION_IMPLICIT_OPT_OUT_STATE;
  if (state === SUBSCRIPTION_IMPLICIT_OPT_OUT_STATE) {
    return null;
  }
  return (
    state === SUBSCRIPTION_IMPLICIT_SUBSCRIBE_STATE ||
    state === SUBSCRIPTION_EXPLICIT_SUBSCRIBE_STATE
  );
};

const legacyPreselected = (preselect, subscription) =>
  subscription.legacy_preselect_value === preselect;

const groupIsSubscribed = (group, states) =>
  subscribed(states, group, allSubscription);

const groupIsPreselected = (group, state) => {
  const groupPreselect = query(state).group;

  return (groupPreselect && groupPreselect === group.group) || false;
};

const subscriptionIsPreselected = (subscription, state) => {
  const keyPreselect = query(state).key;

  return (keyPreselect && keyPreselect === subscription.key) || false;
};

const groupSubscriptions = (group, states, preselect, state) =>
  (group.subscriptions &&
    group.subscriptions.reduce((collect, current) => {
      const isSubscribed = subscribed(states, group, current);
      const isPreselected =
        legacyPreselected(preselect, current) ||
        subscriptionIsPreselected(current, state);
      const subscription = Object.assign({}, current, {
        isSubscribed,
        isPreselected,
      });
      return [...collect, subscription];
    }, [])) ||
  [];

export const emailGroups = ({ emailSettings: { groups } }) => groups;

export const emailGroupsLoaded = ({ emailSettings: { groupsLoaded } }) =>
  groupsLoaded;

export const emailSubscriptions = (state) => {
  const {
    emailSettings: { groups, states },
  } = state;

  let legacyPreselect = null;
  try {
    legacyPreselect = parseInt(query(state).preselect, 10);
  } catch {
    legacyPreselect = null;
  }

  const groupsIncludingEverything = groups && [everything, ...groups];

  let overrideGroupIsSubscribedState = null;
  let isGlobalAllStateExist = false;

  const decoratedSubscriptions =
    groupsIncludingEverything &&
    groupsIncludingEverything.map((group) => {
      let decorated = groupSubscriptions(group, states, legacyPreselect, state);
      const isPreselected =
        legacyPreselected(legacyPreselect, group) ||
        groupIsPreselected(group, state);

      const groupSubscribedState = groupIsSubscribed(group, states);

      if (groupSubscribedState && decorated) {
        // If groupAll is subscribed, set overrideGroupIsSubscribedState
        // flag to true to override
        // other group's isSubscribed state
        if (group.group === allGroup.group) {
          overrideGroupIsSubscribedState = true;
        }
        // If the group level is subscribed, set the subscription's (children) isSubscribed to null
        decorated = decorated.map((subscription) => ({
          ...subscription,
          isSubscribed: null,
        }));
      }

      // Set the flag when allGroup's state exist (true or false)
      if (group.group === allGroup.group && groupSubscribedState !== null) {
        isGlobalAllStateExist = true;
      }

      let decoratedGroup;
      if (group.group === allGroup.group) {
        decoratedGroup = Object.assign({}, group, {
          subscriptions: decorated,
          isSubscribed: groupSubscribedState,
          isPreselected,
        });
      } else {
        decoratedGroup = Object.assign({}, group, {
          subscriptions: decorated,
          // isSubscribed: groupSubscribedState || overrideGroupIsSubscribedState,
          isSubscribed: isGlobalAllStateExist
            ? overrideGroupIsSubscribedState
            : groupSubscribedState,
          isPreselected,
        });
      }

      return decoratedGroup;
    });

  return decoratedSubscriptions;
};

export const subscribedToAll = ({ emailSettings: { states } }) =>
  subscribed(states, allGroup, allSubscription);

export const preselectedGroup = (state) =>
  emailSubscriptions(state).find(
    (group) =>
      group.isPreselected ||
      !!group.subscriptions.find(
        (subscription) =>
          !subscriptionMatches(group, group, subscription, allSubscription) &&
          subscription.isPreselected,
      ),
  );

export const preselectedSubscription = (state) =>
  emailSubscriptions(state)
    .map((group) => group.subscriptions)
    .flat()
    .find((subscription) => subscription.isPreselected);

export const preselectUnsubscribeComplete = ({
  emailSettings: { preselectUnsubscribeComplete },
}) => preselectUnsubscribeComplete;

export const statesLoaded = ({ emailSettings: { statesLoaded } }) =>
  statesLoaded;

export const saveState = ({ emailSettings: { saveState } }) => saveState;

export const emailFromCode = ({ emailSettings: { emailFromCode } }) =>
  emailFromCode;
