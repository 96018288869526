import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'Webapp/shared/app/components/button';
import withT from 'ComponentLibrary/hocs/withT';
import ObjectUtil from 'Utils/object-util';
import { noOpFn } from 'Utils/no-op-fn';

import connector from 'Utils/connector';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';

class ResendVerificationEmailButton extends Component {
  constructor(props) {
    super(props);

    this.handleResendEmail = this.handleResendEmail.bind(this);
  }

  handleResendEmail() {
    const { resendConfirmation, onResendEmail } = this.props;

    resendConfirmation();
    onResendEmail();
  }

  render() {
    const { t } = this.props;

    const parentPropKeys = Object.keys(this.props).filter(
      (key) =>
        !Object.keys(ResendVerificationEmailButton.propTypes).includes(key),
    );
    const parentProps = ObjectUtil.pick(this.props, parentPropKeys);

    return (
      <Button {...parentProps} onClick={this.handleResendEmail}>
        {t('unverified_edu_resend_email')}
      </Button>
    );
  }
}

ResendVerificationEmailButton.propTypes = {
  t: PropTypes.func.isRequired,
  resendConfirmation: PropTypes.func.isRequired,
  onResendEmail: PropTypes.func,
};

ResendVerificationEmailButton.defaultProps = {
  onResendEmail: noOpFn,
};

export default connector(connectAuthentication)(
  withT(ResendVerificationEmailButton),
);
