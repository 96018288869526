import React from 'react';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { styleOnlyProps } from 'Style/style-helpers';

import getWindow from 'Utils/get-window';

import UnstyledButton from 'Webapp/shared/app/components/unstyled-button';
import CloseNewIcon from 'ComponentLibrary/icons/close-new';

import connector from 'Webapp/utils/connector';

type DialogPositionProps = {
  left?: string;
  top?: string;
  right?: string;
  bottom?: string;
  width?: string;
  height?: string;
  transform?: string;
};
type StyledPopupDialogProps = { positionProps: DialogPositionProps };
const StyledPopupDialog = styled(
  'div',
  styleOnlyProps('positionProps'),
)<StyledPopupDialogProps>(({ positionProps }) => ({
  position: 'fixed',
  ...positionProps,
}));

const CloseButtonWrapper = styled.div({
  position: 'absolute',
  top: SPACING.BASE,
  right: SPACING.BASE,
});

type PopupDialogProps = {
  className: string;
  positionProps: DialogPositionProps;
  showCloseButton?: boolean;
  onDismiss: () => void;
  onClickOutside?: () => void;
};

class PopupDialog extends React.Component<PopupDialogProps> {
  componentDidMount() {
    getWindow().addEventListener('mousedown', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    getWindow().removeEventListener('mousedown', this.handleClickOutside, true);
  }

  dialogRef = React.createRef<HTMLDivElement>();

  /**
   *
   * @param {*} target object in the dom.
   * @returns the z-index of the target object. If there is no specified z-index, reccursively
   * search upwards in the dom tree, till we find something with a specified z-index. An Element
   * has node type 1. ie: search upwards till we find z-index or the <html>
   */
  getZIndex(target) {
    const z = getWindow().getComputedStyle(target).getPropertyValue('z-index');

    // Climb the tree until we have a specified zIndex or run out of elements (node type 1)
    if (Number(z) && target.parentNode.nodeType === 1) {
      return this.getZIndex(target.parentNode);
    }
    return z;
  }

  handleClickOutside = (event: MouseEvent) => {
    if (!this.props.onClickOutside) {
      return;
    }
    const { target } = event;
    // const target = event.currentTarget;
    const clickedZIndex = this.getZIndex(target);
    const dialogZIndex = this.getZIndex(this.dialogRef.current);

    /**
     * Compare the closest (searching upwards in tree) specified zIndex of the click target
     * with the zIndex of the anchored dialog. Our anchored dialog should have a specified
     * zIndex. The other will either be "auto" or specified (in the case of a modal).
     * If we've clicked on an element that has a higher zIndex (ie: modal on top of anchored dialog),
     * disregard this as a click-outside. If it's lower, the anchored dialog should be visible,
     * and we can proceed with dismissal.
     */
    if (
      this.dialogRef?.current &&
      target instanceof Node &&
      !this.dialogRef.current.contains(target) &&
      (isNaN(clickedZIndex) || clickedZIndex <= dialogZIndex)
    ) {
      this.props.onClickOutside();
    }
  };
  getPositionProps = () => {
    const positionProps = this.props.positionProps;

    let translateX = '0';
    let translateY = '0';
    if (!positionProps.left && !positionProps.right) {
      translateX = '-50%';
      positionProps.left = '50%';
    }

    if (!positionProps.top && !positionProps.bottom) {
      translateY = '-50%';
      positionProps.top = '50%';
    }
    positionProps.transform = `translate(${translateX}, ${translateY})`;
    return positionProps;
  };
  render() {
    const { className, children, onDismiss, showCloseButton } = this.props;
    const positionProps = this.getPositionProps();

    return (
      <StyledPopupDialog
        className={className}
        positionProps={positionProps}
        ref={this.dialogRef}
      >
        {showCloseButton && (
          <CloseButtonWrapper>
            <UnstyledButton onClick={onDismiss} name="anchored-dialog-close">
              <CloseNewIcon size={24} />
            </UnstyledButton>
          </CloseButtonWrapper>
        )}
        {children}
      </StyledPopupDialog>
    );
  }
}

export default connector<PopupDialogProps>()(PopupDialog);
