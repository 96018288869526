import React from 'react';

function LoadingSpinner() {
  return (
    <div className="loading-spinner">
      <div className="loading-spinner__bounce loading-spinner__bounce--1" />
      <div className="loading-spinner__bounce loading-spinner__bounce--2" />
      <div className="loading-spinner__bounce loading-spinner__bounce--3" />
    </div>
  );
}

export default LoadingSpinner;
