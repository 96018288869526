import { CURATOR_PRO_STORYBOARD_TYPES } from 'Webapp/action-types';
import StoryboardSkeletonUtil from 'Utils/content/storyboard-skeleton-util';
import SectionUtil from 'Utils/content/section-util';
import FlapUtil from 'Utils/content/flap-util';
import { updateStoryboardItems } from 'Utils/content/storyboard-dragend-util';

const ALLOWED_SECTION_ITEM_TYPES = [
  'post',
  'section',
  'image',
  'status',
  'video',
  'audio',
];

interface CuratorProStoryboardReducerState {
  storyboard: null | Flipboard.Storyboard;
  items: Array<Flipboard.StoryboardItem>;
  isLoading: boolean;
  section: null | Flipboard.Section;
  sectionItems: Record<string, unknown>;
  prevItems: null | Array<Flipboard.StoryboardItem>;
}

const initialState: CuratorProStoryboardReducerState = {
  storyboard: null,
  items: [],
  isLoading: false,
  section: null,
  sectionItems: {},
  // When moving items in the UI, we must optimistically update the reducer.
  // If the server responds with a failed response, than we need to be able to revert changes
  prevItems: null,
};

function updateStoryboardUpdatedTime(flStoryboard) {
  return Object.assign({}, flStoryboard, {
    modified: new Date().getTime(),
  });
}

// This reducer maintains state for the active storyboard in the Pro Curator tool
export default function curatorProReducer(state = initialState, action) {
  switch (action.type) {
    case CURATOR_PRO_STORYBOARD_TYPES.GET_CURATOR_PRO_STORYBOARD: {
      return Object.assign({}, state, {
        isLoading: true,
        isLoadingSectionItems: false,
        storyboard: null,
        section: null,
        sectionItems: {},
        items: [],
      });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.UPDATE_CURATOR_PRO_STORYBOARD:
    case CURATOR_PRO_STORYBOARD_TYPES.DELETE_CURATOR_PRO_STORYBOARD:
    case CURATOR_PRO_STORYBOARD_TYPES.INSERT_CURATOR_PRO_STORYBOARD_ITEM:
    case CURATOR_PRO_STORYBOARD_TYPES.UPDATE_CURATOR_PRO_STORYBOARD_ITEM:
    case CURATOR_PRO_STORYBOARD_TYPES.DELETE_CURATOR_PRO_STORYBOARD_ITEM:
    case CURATOR_PRO_STORYBOARD_TYPES.CREATE_CURATOR_PRO_STORYBOARD_NESTED_ITEM:
    case CURATOR_PRO_STORYBOARD_TYPES.SET_CURATOR_PRO_SCHEDULE:
    case CURATOR_PRO_STORYBOARD_TYPES.DELETE_CURATOR_PRO_SCHEDULE: {
      return Object.assign({}, state, { isLoading: true });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.GET_CURATOR_PRO_STORYBOARD_SUCCESS: {
      const { flStoryboard, items, publishAt, isCampaign } = action;
      return Object.assign({}, state, {
        storyboard: StoryboardSkeletonUtil.curatorProProjection(
          flStoryboard,
          publishAt,
          isCampaign,
        ),
        items: items.map(StoryboardSkeletonUtil.storyboardChildProjection),
        isLoading: false,
      });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.SET_CURATOR_PRO_SCHEDULE_SUCCESS: {
      const { flStoryboard, publishAt } = action;
      return Object.assign({}, state, {
        storyboard: StoryboardSkeletonUtil.curatorProProjection(
          flStoryboard,
          publishAt,
        ),
        isLoading: false,
      });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.UPDATE_CURATOR_PRO_STORYBOARD_SUCCESS: {
      return Object.assign({}, state, {
        storyboard: StoryboardSkeletonUtil.curatorProProjection(
          action.flStoryboard,
        ),
        isLoading: false,
      });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.DELETE_CURATOR_PRO_STORYBOARD_SUCCESS: {
      return Object.assign({}, state, { storyboard: null, isLoading: false });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.INSERT_CURATOR_PRO_STORYBOARD_ITEM_SUCCESS: {
      return Object.assign({}, state, {
        items: [
          ...state.items,
          StoryboardSkeletonUtil.storyboardChildProjection(action.item),
        ],
        storyboard: updateStoryboardUpdatedTime(state.storyboard),
        isLoading: false,
      });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.UPDATE_CURATOR_PRO_STORYBOARD_ITEM_SUCCESS: {
      return Object.assign({}, state, {
        items: state.items.map((item) => {
          if (item.id !== action.item.id) {
            return item;
          }
          return StoryboardSkeletonUtil.storyboardChildProjection(action.item);
        }),
        storyboard: updateStoryboardUpdatedTime(state.storyboard),
        isLoading: false,
      });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.UPDATE_CURATOR_PRO_STORYBOARD_NESTED_ITEM_SUCCESS: {
      return Object.assign({}, state, {
        items: state.items.map((item) => {
          if (item.id !== action.groupId) {
            return item;
          }
          return Object.assign({}, item, {
            children: item.children.map((nestedItem) => {
              if (nestedItem.id !== action.item.id) {
                return nestedItem;
              }
              return StoryboardSkeletonUtil.storyboardChildProjection(
                action.item,
              );
            }),
          });
        }),
        storyboard: updateStoryboardUpdatedTime(state.storyboard),
        isLoading: false,
      });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.DELETE_CURATOR_PRO_STORYBOARD_ITEM_SUCCESS: {
      return Object.assign({}, state, {
        items: state.items
          .filter((i) => i.id !== action.itemId)
          .map((item) =>
            Object.assign({}, item, {
              children: (item.children || []).filter(
                (i) => i.id !== action.itemId,
              ),
            }),
          ),
        storyboard: updateStoryboardUpdatedTime(state.storyboard),
        isLoading: false,
      });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.CREATE_CURATOR_PRO_STORYBOARD_NESTED_ITEM_SUCCESS: {
      return Object.assign({}, state, {
        items: state.items.map((item) => {
          if (item.id !== action.groupId) {
            return item;
          }
          return Object.assign({}, item, {
            children: [
              ...(item.children || []),
              StoryboardSkeletonUtil.storyboardChildProjection(action.item),
            ],
          });
        }),
        storyboard: updateStoryboardUpdatedTime(state.storyboard),
        isLoading: false,
      });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.MOVE_CURATOR_PRO_STORYBOARD_ITEM: {
      const { sourceGroupId, targetGroupId, itemId, targetId, append } = action;
      const { items: storyboardItems, storyboard } = state;
      if (!storyboard) {
        return;
      }
      const updatedItems = updateStoryboardItems({
        storyboardItems,
        storyboardId: storyboard.id,
        sourceGroupId,
        targetGroupId,
        itemId,
        targetId,
        append,
      });

      if (!updatedItems) {
        return state;
      }

      return {
        ...state,
        items: updatedItems,
        prevItems: [...state.items],
        isLoading: true,
      };
    }
    case CURATOR_PRO_STORYBOARD_TYPES.MOVE_CURATOR_PRO_STORYBOARD_ITEM_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        prevItems: null,
        storyboard: updateStoryboardUpdatedTime(state.storyboard),
      });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.MOVE_CURATOR_PRO_STORYBOARD_ITEM_FAILED: {
      return Object.assign({}, state, {
        items: [...(state.prevItems || [])],
        prevItems: null,
        isLoading: false,
      });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.GET_CURATOR_PRO_STORYBOARD_FEED: {
      return Object.assign({}, state, {
        isLoadingSectionItems: true,
      });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.GET_CURATOR_PRO_STORYBOARD_FEED_SUCCESS: {
      const flatItems = FlapUtil.getFlatItems(
        action.items,
        ALLOWED_SECTION_ITEM_TYPES,
      );
      const itemsById = flatItems.reduce(
        (acc, item) =>
          Object.assign({}, acc, { [item.remoteServiceItemID]: item }),
        {},
      );
      return Object.assign({}, state, {
        section: SectionUtil.projection(action.section),
        sectionItems: itemsById,
        isLoadingSectionItems: false,
      });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.GET_CURATOR_PRO_STORYBOARD_FEED_FAILED: {
      return Object.assign({}, state, {
        isLoadingSectionItems: false,
      });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.GET_CURATOR_PRO_STORYBOARD_FEED_ITEM: {
      return Object.assign({}, state, {
        sectionItems: {
          ...state.sectionItems,
          [action.url]: { isLoading: true },
        },
      });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.GET_CURATOR_PRO_STORYBOARD_FEED_ITEM_SUCCESS: {
      const { section, items, url } = action;
      const flatItems = FlapUtil.getFlatItems(
        items,
        ALLOWED_SECTION_ITEM_TYPES,
      );
      const sectionProjection =
        section && SectionUtil.isValid(section)
          ? SectionUtil.projection(section)
          : null;
      let sectionItems;
      if (sectionProjection) {
        sectionItems = Object.assign({}, state.sectionItems, {
          [url]: sectionProjection,
        });
      } else if (flatItems.length === 1) {
        // If the single url we tried to fetch content for returns 1 item,
        // assume it is correct
        sectionItems = Object.assign({}, state.sectionItems, {
          [url]: flatItems[0],
        });
      } else {
        // If not, set loading on the item to false, so that the fallback can render correctly
        sectionItems = Object.assign({}, state.sectionItems, {
          [url]: { isLoading: false },
        });
      }

      return Object.assign({}, state, {
        sectionItems,
      });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.DELETE_CURATOR_PRO_SCHEDULE_SUCCESS: {
      return Object.assign({}, state, {
        storyboard: Object.assign({}, state.storyboard, {
          publishAt: null,
          isLoading: false,
        }),
      });
    }
    case CURATOR_PRO_STORYBOARD_TYPES.GET_CURATOR_PRO_STORYBOARD_FAILED:
    case CURATOR_PRO_STORYBOARD_TYPES.UPDATE_CURATOR_PRO_STORYBOARD_FAILED:
    case CURATOR_PRO_STORYBOARD_TYPES.DELETE_CURATOR_PRO_STORYBOARD_FAILED:
    case CURATOR_PRO_STORYBOARD_TYPES.INSERT_CURATOR_PRO_STORYBOARD_ITEM_FAILED:
    case CURATOR_PRO_STORYBOARD_TYPES.UPDATE_CURATOR_PRO_STORYBOARD_ITEM_FAILED:
    case CURATOR_PRO_STORYBOARD_TYPES.DELETE_CURATOR_PRO_STORYBOARD_ITEM_FAILED:
    case CURATOR_PRO_STORYBOARD_TYPES.CREATE_CURATOR_PRO_STORYBOARD_NESTED_ITEM_FAILED:
    case CURATOR_PRO_STORYBOARD_TYPES.SET_CURATOR_PRO_SCHEDULE_FAILED:
    case CURATOR_PRO_STORYBOARD_TYPES.DELETE_CURATOR_PRO_SCHEDULE_FAILED: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }
    default:
      return state;
  }
}
