import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { BODY_TYPES } from 'Style/typography';
import withT from 'ComponentLibrary/hocs/withT';
import replaceTags from 'Utils/i18n/replace-tags';
import Config from 'Config';
import WebLink from 'Webapp/shared/app/components/web-link';

const Disclaimer = styled.div(BODY_TYPES.HINT);

const TermsLink = (content) => (
  <WebLink href={`${Config.ABOUT_BASE_URL}/terms/`} openInNewWindow>
    {content}
  </WebLink>
);

const PrivacyLink = (content) => (
  <WebLink href={`${Config.ABOUT_BASE_URL}/privacy/`} openInNewWindow>
    {content}
  </WebLink>
);
function SignupDisclaimer({ isNewsletter, className, t }) {
  const descriptionString = isNewsletter
    ? 'inline_newsletter_signup_disclaimer'
    : 'signup_disclaimer';
  return (
    <Disclaimer className={className}>
      {replaceTags(t(descriptionString), {
        termsLink: TermsLink,
        privacyLink: PrivacyLink,
      })}
    </Disclaimer>
  );
}

SignupDisclaimer.propTypes = {
  className: PropTypes.string,
  isNewsletter: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

SignupDisclaimer.defaultProps = {
  isNewsletter: false,
};

export default withT(SignupDisclaimer);
