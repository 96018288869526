import {
  isSearchRoute,
  isArticleRoute,
  isHomeRoute,
  isSectionRoute,
} from './routing';
import { USAGE_NAV_FROMS } from 'Utils/analytics/usage';

export const getAdjustNavFromRoute = (state) => {
  if (isSearchRoute(state)) {
    return USAGE_NAV_FROMS.SEARCH_RESULTS;
  }
  if (isArticleRoute(state)) {
    return USAGE_NAV_FROMS.ARTICLE;
  }
  if (isHomeRoute(state)) {
    return USAGE_NAV_FROMS.HOME;
  }
  if (isSectionRoute(state)) {
    return USAGE_NAV_FROMS.FEED;
  }
};
