import { Connector } from 'Webapp/utils/connector';
import { isArticleRoute } from '../redux/selectors/routing';

const selectors = { isArticleRoute };

export type ConnectIsArticleRouteProps = Flipboard.ConnectorProps<
  typeof selectors,
  unknown
>;

const connectIsArticleRoute: Connector = {
  selectors,
};

export default connectIsArticleRoute;
