import { Connector } from 'Webapp/utils/connector';

import { toastInvalidInviteError } from 'Webapp/shared/app/redux/actions/section-actions';
import {
  usageTrackShowAcceptContributorInvite,
  usageTapAcceptContributorInvite,
} from 'Webapp/shared/app/redux/actions/usage-actions';
import {
  startAcceptMagazineFlow,
  startOldAcceptMagazineFlow,
} from 'Webapp/shared/concepts/onboarding';

import {
  getInviteMagazine,
  getInviteMagazinePendingStatus,
} from 'Webapp/shared/app/redux/selectors/invite-magazine';

import {
  hideMainNavCTAs,
  showMainNavCTAs,
} from 'Webapp/shared/app/redux/actions/app';

const selectors = {
  accessorySections: ({ accessorySections }) => accessorySections,
  magazineInvite: getInviteMagazine,
  isMagazineInvitePending: getInviteMagazinePendingStatus,
};

const actions = {
  hideMainNavCTAs,
  showMainNavCTAs,
  toastInvalidInviteError,
  startAcceptMagazineFlow,
  startOldAcceptMagazineFlow,
  usageTrackShowAcceptContributorInvite,
  usageTapAcceptContributorInvite,
};

export type ConnectAcceptMagazineInviteProps = Flipboard.ConnectorProps<
  typeof selectors,
  typeof actions
>;

const connectAcceptMagazineInvite: Connector = {
  selectors,
  actions,
};

export default connectAcceptMagazineInvite;
