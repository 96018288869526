import { CURATOR_PRO_MAGAZINE_TYPES } from 'Webapp/action-types';
import SectionUtil from 'Utils/content/section-util';
import { projection } from 'Utils/content/item-util';

interface CuratorProMagazineReducerState {
  magazine: null | Flipboard.Section;
  items: Array<Flipboard.Item>;
  pipes: Array<Flipboard.Pipe>;
  nextPageKey: null | Flipboard.NextPageKey;
  prevItems: null | Array<Flipboard.Item>;
  neverLoadMore: boolean;
  contributors: Array<Flipboard.Author>;
  isLoading: boolean;
}

const initialState: CuratorProMagazineReducerState = {
  magazine: null,
  items: [],
  pipes: [],
  nextPageKey: null,
  prevItems: null,
  neverLoadMore: false,
  contributors: [],
  isLoading: false,
};

export default function curatorProReducer(state = initialState, action) {
  switch (action.type) {
    case CURATOR_PRO_MAGAZINE_TYPES.GET_CURATOR_PRO_MAGAZINE: {
      return Object.assign({}, initialState, { isLoading: true });
    }
    case CURATOR_PRO_MAGAZINE_TYPES.UPDATE_CURATOR_PRO_MAGAZINE_COVER:
    case CURATOR_PRO_MAGAZINE_TYPES.REMOVE_CURATOR_PRO_MAGAZINE_CONTRIBUTOR:
    case CURATOR_PRO_MAGAZINE_TYPES.REMOVE_CURATOR_PRO_MAGAZINE_ITEM:
    case CURATOR_PRO_MAGAZINE_TYPES.GET_CURATOR_PRO_MAGAZINE_NEXT_ITEMS:
    case CURATOR_PRO_MAGAZINE_TYPES.FLIP_CURATOR_PRO_MAGAZINE_URL:
    case CURATOR_PRO_MAGAZINE_TYPES.DELETE_CURATOR_PRO_MAGAZINE:
    case CURATOR_PRO_MAGAZINE_TYPES.ADD_CURATOR_PRO_MAGAZINE_PIPE:
    case CURATOR_PRO_MAGAZINE_TYPES.DELETE_CURATOR_PRO_MAGAZINE_PIPE:
    case CURATOR_PRO_MAGAZINE_TYPES.CUSTOMIZE_CURATOR_PRO_MAGAZINE_ITEM:
    case CURATOR_PRO_MAGAZINE_TYPES.UPDATE_CURATOR_PRO_MAGAZINE: {
      return Object.assign({}, state, { isLoading: true });
    }
    case CURATOR_PRO_MAGAZINE_TYPES.GET_CURATOR_PRO_MAGAZINE_SUCCESS: {
      const items = action.items.map((item) => projection(item));
      return Object.assign({}, state, {
        magazine: SectionUtil.projection(action.section, items),
        items: items.filter((i) => !i.isSectionCover),
        pipes: action.pipes || null,
        contributors: action.contributors,
        nextPageKey: action.nextPageKey,
        neverLoadMore: action.neverLoadMore,
        isLoading: false,
      });
    }
    case CURATOR_PRO_MAGAZINE_TYPES.FLIP_CURATOR_PRO_MAGAZINE_URL_SUCCESS: {
      return Object.assign({}, state, {
        magazine: SectionUtil.projection(action.section),
        items: action.items
          .map((item) => projection(item))
          .filter((item) => !item.isSectionCover),
        nextPageKey: action.nextPageKey,
        neverLoadMore: action.neverLoadMore,
        isLoading: false,
      });
    }
    case CURATOR_PRO_MAGAZINE_TYPES.GET_CURATOR_PRO_MAGAZINE_NEXT_ITEMS_SUCCESS: {
      return Object.assign({}, state, {
        items: state.items.concat(
          action.items
            .map((item) => projection(item))
            .filter((item) => !item.isSectionCover),
        ),
        nextPageKey: action.nextPageKey,
        neverLoadMore: action.neverLoadMore,
        isLoading: false,
      });
    }
    case CURATOR_PRO_MAGAZINE_TYPES.UPDATE_CURATOR_PRO_MAGAZINE_SUCCESS: {
      const changes = Object.assign({}, action.changes);
      if (changes.magazineVisibility) {
        changes.isPrivate = changes.magazineVisibility === 'private';
      }
      return Object.assign({}, state, {
        magazine: Object.assign({}, state.magazine, changes),
        isLoading: false,
      });
    }
    case CURATOR_PRO_MAGAZINE_TYPES.DELETE_CURATOR_PRO_MAGAZINE_SUCCESS: {
      return Object.assign({}, initialState);
    }
    case CURATOR_PRO_MAGAZINE_TYPES.UPDATE_CURATOR_PRO_MAGAZINE_COVER_SUCCESS: {
      const item = state.items.find((item) => item.id === action.itemId);
      return Object.assign({}, state, {
        magazine: Object.assign({}, state.magazine, {
          magazineCoverItemId: action.itemId,
          coverImage: item ? item.image : null,
        }),
        isLoading: false,
      });
    }
    case CURATOR_PRO_MAGAZINE_TYPES.MOVE_CURATOR_PRO_MAGAZINE_ITEM: {
      const { moveId, position } = action;
      const moveIndex = state.items.findIndex((item) => item.oid === moveId);
      const updatedItems = [...state.items].filter(
        (item) => item.oid !== moveId,
      );
      updatedItems.splice(position, 0, state.items[moveIndex]);

      return Object.assign({}, state, {
        items: updatedItems,
        prevItems: [...state.items],
      });
    }
    case CURATOR_PRO_MAGAZINE_TYPES.MOVE_CURATOR_PRO_MAGAZINE_ITEM_SUCCESS: {
      return Object.assign({}, state, {
        prevItems: null,
        isLoading: false,
      });
    }
    case CURATOR_PRO_MAGAZINE_TYPES.MOVE_CURATOR_PRO_MAGAZINE_ITEM_FAILED: {
      return Object.assign({}, state, {
        items: [...(state.prevItems || [])],
        prevItems: null,
        isLoading: false,
      });
    }
    case CURATOR_PRO_MAGAZINE_TYPES.REMOVE_CURATOR_PRO_MAGAZINE_CONTRIBUTOR_SUCCESS: {
      return Object.assign({}, state, {
        contributors: state.contributors.filter(
          (c) => c.userid !== action.contributorId,
        ),
        isLoading: false,
      });
    }
    case CURATOR_PRO_MAGAZINE_TYPES.REMOVE_CURATOR_PRO_MAGAZINE_ITEM_SUCCESS: {
      return Object.assign({}, state, {
        items: state.items.filter((item) => item.oid !== action.oid),
        isLoading: false,
      });
    }
    case CURATOR_PRO_MAGAZINE_TYPES.ADD_CURATOR_PRO_MAGAZINE_PIPE_SUCCESS: {
      const newPipes = state.pipes.concat(action.pipe);
      return Object.assign({}, state, {
        pipes: newPipes,
        isLoading: false,
      });
    }
    case CURATOR_PRO_MAGAZINE_TYPES.DELETE_CURATOR_PRO_MAGAZINE_PIPE_SUCCESS: {
      const newPipes = state.pipes.filter((pipe) => pipe.id !== action.pipeId);
      return Object.assign({}, state, {
        pipes: newPipes,
        isLoading: false,
      });
    }
    case CURATOR_PRO_MAGAZINE_TYPES.CUSTOMIZE_CURATOR_PRO_MAGAZINE_ITEM_SUCCESS: {
      return Object.assign({}, state, {
        items: state.items.map((item) => {
          if (
            item.remoteServiceItemID ===
            (action.item && action.item.remoteServiceItemID)
          ) {
            return projection(action.item);
          }
          return item;
        }),
        isLoading: false,
      });
    }
    case CURATOR_PRO_MAGAZINE_TYPES.GET_CURATOR_PRO_MAGAZINE_FAILED:
    case CURATOR_PRO_MAGAZINE_TYPES.REMOVE_CURATOR_PRO_MAGAZINE_CONTRIBUTOR_FAILED:
    case CURATOR_PRO_MAGAZINE_TYPES.UPDATE_CURATOR_PRO_MAGAZINE_COVER_FAILED:
    case CURATOR_PRO_MAGAZINE_TYPES.REMOVE_CURATOR_PRO_MAGAZINE_ITEM_FAILED:
    case CURATOR_PRO_MAGAZINE_TYPES.GET_CURATOR_PRO_MAGAZINE_NEXT_ITEMS_FAILED:
    case CURATOR_PRO_MAGAZINE_TYPES.FLIP_CURATOR_PRO_MAGAZINE_URL_FAILED:
    case CURATOR_PRO_MAGAZINE_TYPES.DELETE_CURATOR_PRO_MAGAZINE_FAILED:
    case CURATOR_PRO_MAGAZINE_TYPES.ADD_CURATOR_PRO_MAGAZINE_PIPE_FAILED:
    case CURATOR_PRO_MAGAZINE_TYPES.DELETE_CURATOR_PRO_MAGAZINE_PIPE_FAILED:
    case CURATOR_PRO_MAGAZINE_TYPES.CUSTOMIZE_CURATOR_PRO_MAGAZINE_ITEM_FAILED:
    case CURATOR_PRO_MAGAZINE_TYPES.UPDATE_CURATOR_PRO_MAGAZINE_FAILED: {
      return Object.assign({}, state, { isLoading: false });
    }
    default:
      return state;
  }
}
