import getWindow from 'Utils/get-window';
import sentry from 'Utils/sentry';
import { GuardedHistoryMethodError } from 'Utils/errors';

let didNavigate = false;
const VISITED_EXTERNAL_URL_KEY = 'visitedExternalUrl';

const guardedHistoryMethod = (fn) => {
  // Sometimes history methods throw when they get odd pathnames
  // (encoded too many times or not enough, etc.)  There are bugs with
  // the react-router history object that we can't really work around
  // either.

  // https://github.com/ReactTraining/history/issues/505
  // https://github.com/ReactTraining/history/pull/656 The bulk of
  // problems appear to be fixed in history 5.0.0, but that version of
  // history does not work with react-router 5.2.0, only the 6+ branch
  // which is still experimental
  try {
    return fn();
  } catch (e) {
    sentry.captureException(new GuardedHistoryMethodError());
    sentry.captureException(e);
  }
  return null;
};

/** Determines whether the most recent react-router navigation
 * was a "POP," meaning the user clicked the back/forward
 * browser buttons.
 * @param {Object} history   - React-router history object
 * @return {Boolean}          - True if the user used the browser nav buttons
 */
export const didBacktrack = (history) => {
  // React-router history object always reports a "POP" action on first page load
  if (!didNavigate) {
    return false;
  }

  return history && history.action === 'POP';
};

/**
 * Set that the user navigated on the client
 */
export const setDidNavigate = () => {
  didNavigate = true;
};

export const resetDidNavigate = () => {
  didNavigate = false;
};

export const getPreviousUrl = (history) =>
  (didNavigate && getHistoryState(history, 'previousUrl')) || null;

const getHistoryState = (history, key) =>
  (history &&
    history.location &&
    history.location.state &&
    history.location.state[key]) ||
  null;

export const pushPathToHistory = (path, history) =>
  guardedHistoryMethod(() => {
    const state = {};
    const location = getWindow().location;
    const previousUrl = location && location.href;
    if (previousUrl) {
      state.previousUrl = previousUrl;
    }
    history.push(path, state);
  });

const getHistoryFullPath = (history) =>
  `${history.location.pathname}${history.location.search}`;

export const replaceHistoryState = (history, key, value) =>
  guardedHistoryMethod(() => {
    const currentPath = getHistoryFullPath(history);
    const currentState = history.location.state;
    const newState = Object.assign({}, currentState, {
      [key]: value,
    });
    history.replace(currentPath, newState);
  });

export const deleteHistoryState = (history, key) =>
  guardedHistoryMethod(() => {
    const currentPath = getHistoryFullPath(history);
    const newState = Object.assign({}, history.location.state);
    delete newState[key];
    history.replace(currentPath, newState);
  });

export const storeVisitedExternalUrl = (history, destinationUrl) => {
  replaceHistoryState(history, VISITED_EXTERNAL_URL_KEY, destinationUrl);
};

export const popVisitedExternalUrl = (history) =>
  guardedHistoryMethod(() => {
    const visitedExternalUrl = getHistoryState(
      history,
      VISITED_EXTERNAL_URL_KEY,
    );
    deleteHistoryState(history, VISITED_EXTERNAL_URL_KEY);
    return visitedExternalUrl;
  });
