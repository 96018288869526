import { AxiosResponse } from 'axios';
import { ItemMetricDetailType } from 'Webapp/enums';
import { currentUserUid } from 'Webapp/shared/app/redux/selectors/auth';

interface ItemMetricDetail {
  items: Array<Flipboard.FlapCommentaryItem>;
  nextPageKey: Flipboard.NextPageKey;
}

interface ItemMetricDetailParams {
  oid: Flipboard.SocialId;
  userid: Flipboard.UserId;
  pageKey?: Flipboard.NextPageKey;
  usessid: true;
}

export const fetchItemMetricDetail =
  ({
    item,
    itemMetricDetailType,
    pageKey,
  }: {
    item: Flipboard.Item;
    itemMetricDetailType: ItemMetricDetailType;
    pageKey: Flipboard.NextPageKey | null;
  }): Flipboard.Thunk<Promise<ItemMetricDetail>> =>
  async (_dispatch, getState, { flap }) => {
    const userid = currentUserUid(getState());
    const params: ItemMetricDetailParams = {
      oid: item.flipboardSocialId,
      userid,
      usessid: true,
    };
    if (pageKey) {
      params.pageKey = pageKey;
    }
    const typeKey = itemMetricDetailType.toLowerCase();
    const {
      data: { items },
    } = await flap.get<
      ItemMetricDetailParams,
      AxiosResponse<Flipboard.FlapSocialResponse<Flipboard.FlapSocialItem>>
    >(`/social/${itemMetricDetailType.toLowerCase()}`, {
      params,
    });
    const commentaryItem = items[0];
    return {
      items: commentaryItem.commentary,
      nextPageKey: commentaryItem[`${typeKey}PageKey`],
    };
  };
