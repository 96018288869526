import React from 'react';
import styled from '@emotion/styled';

import { BREAKPOINTS } from 'Style/breakpoints';
import { SPACING } from 'Style/spacing';
import { UI_TEXT_TYPES, BODY_TYPES } from 'Style/typography';
import { HiddenLabel } from 'Style/form.js';
import { styleObject } from 'Style/type-helpers';

import codeSplitLoad from 'Webapp/shared/utils/code-split-load';

import LoginButton from 'Webapp/shared/app/components/login-button';
import Input from 'Webapp/shared/app/components/base/input';
import Button, {
  StyleVariations,
  StyleModifiers,
  BUTTON_STYLES,
} from 'Webapp/shared/app/components/button';
import SignupDisclaimer from 'Webapp/shared/app/components/signup-disclaimer.js';
import LoadingSpinner from 'ComponentLibrary/icons/loading-spinner.js';

import withT from 'ComponentLibrary/hocs/withT.js';
import withFrictionlessSignup from 'Webapp/shared/app/hocs/withFrictionlessSignup.js';
import withReCaptcha from 'Webapp/shared/app/hocs/withReCaptcha.js';

import connector from 'Utils/connector';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';

const LoginModal = codeSplitLoad('LoginModal');

const FormWrapper = styled.div({
  padding: SPACING.LARGE,
});
const LoginMsg = styled.p(BODY_TYPES.SMALL_STANDARD);

export const MainForm = styled.form(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'fit-content',
    '> *': { width: '100%' },
  },
  styleObject(BREAKPOINTS.phone({ margin: `${SPACING.LARGE} 0 ` })),
);
const FormContents = styled.div({ display: 'grid', gap: SPACING.BASE });

const FormError = styled.span(UI_TEXT_TYPES.ERROR, { fontSize: '12px' });
export const FrictionlessSubmit = styled(Button)({});

const StyledLoginButton = styled(LoginButton)(
  {
    paddingLeft: SPACING.BASE,
  },
  styleObject(BUTTON_STYLES.RED_LINK),
);

type FrictionlessFormProps = {
  email: string;
  onEmailChange: () => void;
  onboardPending: boolean;
  frictionlessEnter: (frictionlessFlowType: string | null) => void;
  ReCaptchaUI: React.ReactNode;
  t: Flipboard.TFunction;
  onSubmit: (e: React.SyntheticEvent, frictionlessFlowType: string) => void;
  className?: string;
  frictionlessType: string;
  validateReCaptcha: (
    type: string,
    onValidated: () => void,
  ) => Promise<boolean>;
  showDisclaimer: boolean | false;
  showLogin: boolean | false;
  submitStringOverride: string;
} & ConnectModalProps;

interface FrictionlessFormState {
  emailInputError: string | null;
}

class FrictionlessForm extends React.Component<
  FrictionlessFormProps,
  FrictionlessFormState
> {
  static defaultProps = { submitStringOverride: 'create_account' };

  constructor(props: FrictionlessFormProps) {
    super(props);
    this.state = { emailInputError: null };
  }

  setEmailInputError = (e, msg) => {
    e.preventDefault();
    this.setState({ emailInputError: msg });
  };

  frictionlessFlowSubmit = async (e) => {
    const { validateReCaptcha, onSubmit, frictionlessType } = this.props;
    e.preventDefault();
    await validateReCaptcha(
      `frictionless_${frictionlessType.toLowerCase()}`,
      () => {
        onSubmit(e, frictionlessType);
      },
    );
  };

  handleLogin(e) {
    const { showModal } = this.props;
    e.preventDefault();
    showModal(LoginModal, {});
  }
  render() {
    const {
      email,
      onEmailChange,
      onboardPending,
      frictionlessEnter,
      frictionlessType,
      ReCaptchaUI,
      className,
      showDisclaimer,
      showLogin,
      submitStringOverride,
      t,
    } = this.props;
    const { emailInputError } = this.state;

    return (
      <FormWrapper className={className}>
        <MainForm onSubmit={this.frictionlessFlowSubmit}>
          <FormContents>
            <HiddenLabel>{t('email')}: </HiddenLabel>
            <Input
              type="email"
              name="email"
              placeholder={t('newsletters_email_placeholder')}
              value={email}
              onChange={onEmailChange}
              disabled={onboardPending}
              onInput={(e) => this.setEmailInputError(e, '')}
              onInvalid={(e) => this.setEmailInputError(e, t('invalid_email'))}
              onKeyUp={() => frictionlessEnter(frictionlessType)}
              required
            />
            {showDisclaimer && <SignupDisclaimer />}
            {showLogin && (
              <LoginMsg>
                {t('already_have_an_account')}
                <StyledLoginButton
                  name="main-nav-login"
                  styleModifier={[StyleModifiers.INLINE_LINK]}
                />
              </LoginMsg>
            )}
            {emailInputError && <FormError>{emailInputError}</FormError>}
          </FormContents>
          {onboardPending ? (
            <LoadingSpinner />
          ) : (
            <FrictionlessSubmit
              name="frictionless__form-submit"
              styleVariation={StyleVariations.PRIMARY}
              type="submit"
              disabled={onboardPending}
            >
              {t(submitStringOverride)}
            </FrictionlessSubmit>
          )}
        </MainForm>
        {ReCaptchaUI}
      </FormWrapper>
    );
  }
}

export default connector(connectModal)(
  withReCaptcha(withFrictionlessSignup(withT(FrictionlessForm))),
);
