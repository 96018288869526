/* eslint-disable react/prop-types */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ToastActions from '../redux/actions/toast-actions';
import getWindow from 'Utils/get-window';

function withToast(Component) {
  class WrappedComponent extends React.Component {
    componentWillUnmount() {
      getWindow().document.removeEventListener(
        'click',
        this.handleDismiss,
        false,
      );
    }

    handleDismiss = () => {
      if (this.props.toastMessage) {
        this.props.toastDismiss();
      }
    };

    render() {
      return <Component {...this.props} />;
    }
  }

  const mapStateToProps = ({ toast }) => ({
    toastMessage: toast.message || toast.props,
  });

  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(ToastActions, dispatch);

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent);
}

export default withToast;
