import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ICON_COLORS } from 'Style/colors';

import Icon from '../icon';

export const StyledShareIcon = styled(Icon)({
  color: ICON_COLORS.emphasis,
});

function ShareIcon({ size, ...props }) {
  return (
    <StyledShareIcon
      name="share"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      {...props}
      renderComponent={() => (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.33333 11.667C3.79357 11.667 4.16667 12.0401 4.16667 12.5003V13.5003C4.16667 14.2141 4.16732 14.6994 4.19796 15.0745C4.22781 15.4398 4.28192 15.6266 4.34832 15.757C4.50811 16.0706 4.76308 16.3255 5.07668 16.4853C5.20702 16.5517 5.39385 16.6058 5.7592 16.6357C6.13427 16.6663 6.61952 16.667 7.33333 16.667H12.6667C13.3805 16.667 13.8657 16.6663 14.2408 16.6357C14.6061 16.6058 14.793 16.5517 14.9233 16.4853C15.2369 16.3255 15.4919 16.0706 15.6517 15.757C15.7181 15.6266 15.7722 15.4398 15.802 15.0745C15.8327 14.6994 15.8333 14.2141 15.8333 13.5003V12.5003C15.8333 12.0401 16.2064 11.667 16.6667 11.667C17.1269 11.667 17.5 12.0401 17.5 12.5003V13.5348C17.5 14.2056 17.5 14.7592 17.4632 15.2102C17.4249 15.6786 17.3428 16.1092 17.1367 16.5136C16.8171 17.1408 16.3072 17.6508 15.68 17.9703C15.2755 18.1764 14.8449 18.2586 14.3765 18.2968C13.9255 18.3337 13.3719 18.3337 12.7011 18.3337H7.29892C6.62811 18.3337 6.07448 18.3337 5.62348 18.2968C5.15506 18.2586 4.72448 18.1764 4.32003 17.9703C3.69283 17.6508 3.18289 17.1408 2.86331 16.5136C2.65724 16.1092 2.5751 15.6786 2.53683 15.2102C2.49998 14.7592 2.49999 14.2056 2.5 13.5347C2.5 13.5233 2.5 13.5118 2.5 13.5003V12.5003C2.5 12.0401 2.8731 11.667 3.33333 11.667Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 1.66699C10.4602 1.66699 10.8333 2.04009 10.8333 2.50033V12.5003C10.8333 12.9606 10.4602 13.3337 10 13.3337C9.53976 13.3337 9.16667 12.9606 9.16667 12.5003V2.50033C9.16667 2.04009 9.53976 1.66699 10 1.66699Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.41075 1.91107C9.73618 1.58563 10.2638 1.58563 10.5893 1.91107L13.0893 4.41107C13.4147 4.73651 13.4147 5.26414 13.0893 5.58958C12.7638 5.91502 12.2362 5.91502 11.9107 5.58958L10 3.67884L8.08926 5.58958C7.76382 5.91502 7.23618 5.91502 6.91075 5.58958C6.58531 5.26414 6.58531 4.73651 6.91075 4.41107L9.41075 1.91107Z"
            fill="currentColor"
          />
        </>
      )}
    />
  );
}

ShareIcon.propTypes = {
  size: PropTypes.number,
};

ShareIcon.defaultProps = {
  size: 20,
};
export default ShareIcon;
