import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { noOpFn } from 'Utils/no-op-fn';

import {
  createSmartMagazine,
  updateSmartMagazine,
  addSmartMagazineToFavorites,
} from 'Webapp/shared/app/redux/actions/profile-actions';
import PersonalizeTopic from 'Webapp/shared/app/modals/personalize-topic';

import connector from 'Utils/connector';
import connectPersonalizedTopic from 'Webapp/shared/app/connectors/connectPersonalizedTopic';

const mapStateToProps = (state) => {
  const {
    profile: { favorites },
  } = state;

  return { favorites };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createSmartMagazine,
      updateSmartMagazine,
      usageTrackOnboardExit: noOpFn, // No-op as we don't care about the onboarding when using the personalize workflow.
      addSmartMagazineToFavorites,
    },
    dispatch,
  );
const PersonalizeContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PersonalizeTopic);

export default connector(connectPersonalizedTopic)(PersonalizeContainer);
