import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import AcceptLanguageParser from 'accept-language-parser';
import GlobalVars from 'Utils/global-vars';
import getWindow from 'Utils/get-window';

const availableLocales = [
  'ar',
  'de',
  'en',
  'es',
  'fr',
  'it',
  'ja',
  'ko',
  'nl',
  'pt-BR',
  'ru',
  'tr',
];

const COOKIE_NAME = 'i18n_preference';

const namespaces = ['webapp', 'auth', 'common'];

const Translations = {
  type: 'backend',
  read(language, namespace, callback) {
    if (GlobalVars.isServer()) {
      if (availableLocales.includes(language)) {
        try {
          const localeRequire = `require('./locales/${language}.json')`;
          const locale = eval(localeRequire);
          if (locale[namespace]) {
            return callback(null, locale[namespace]);
          }
        } catch (e) {
          return callback(e);
        }
      }
      return callback(new Error(`Not an available locale: ${language}`));
    }
    // ON THE CLIENT
    const { I18N_STRINGS } = getWindow();
    const locale = I18N_STRINGS && I18N_STRINGS[language];
    if (locale) {
      const languageNamespace = locale[namespace];
      if (languageNamespace) {
        callback(null, languageNamespace);
      } else {
        return callback(
          new Error(`Missing language/namespace: ${language} ${namespace}`),
        );
      }
    }
    return callback(new Error(`Missing language namespaces: ${language}`));
  },
};

export const createClientT = () => {
  const i18nextInstance = i18next.createInstance();
  i18nextInstance
    .use(
      new LanguageDetector(null, {
        lookupCookie: COOKIE_NAME,
        order: ['cookie', 'navigator'],
      }),
    )
    .use(Translations)
    .init({
      initImmediate: false, // badly named, makes this synchronous
      fallbackLng: {
        default: ['en'],
      },
      ns: namespaces,
      defaultNS: namespaces,
      // debug in the client in development mode, helps you spot
      // missing keys you need to add
      debug: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });
  return i18nextInstance.t.bind(i18nextInstance);
};

export const expressMiddleware = (req, _res, next) => {
  let language = req.cookies[COOKIE_NAME];
  if (!language) {
    const parsedLanguagePreferences = AcceptLanguageParser.parse(
      req.headers['accept-language'],
    );
    if (parsedLanguagePreferences.length > 0) {
      const { code, region } = parsedLanguagePreferences[0];
      language = region ? `${code}-${region}` : code;
    }
  }
  if (!language) {
    language = 'en-US';
  }
  const i18nextInstance = i18next.createInstance();
  i18nextInstance.use(Translations).init(
    {
      lng: language, // overrides detection. only provided by server
      fallbackLng: {
        default: ['en'],
      },
      ns: namespaces,
      defaultNS: namespaces,
      // debug in the client in development mode, helps you spot
      // missing keys you need to add
      debug: !GlobalVars.isServer() && process.env.NODE_ENV === 'development',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    },
    () => {
      req.i18n = i18nextInstance;
      req.i18nT = i18nextInstance.t.bind(i18nextInstance);
      next();
    },
  );
};

export const i18nStrings = (i18nInstance) =>
  i18nInstance.languages.reduce((acc, language) => {
    acc[language] = {};
    namespaces.forEach((namespace) => {
      acc[language][namespace] = i18nInstance.getResourceBundle(
        language,
        namespace,
      );
    });
    return acc;
  }, {});

export const createMockT =
  (...keys) =>
  (key, options) => {
    const foundKey = keys && keys.includes(key);
    if (!foundKey) {
      throw new Error(
        `mockT called with unknown key: ${key}, options: ${JSON.stringify(
          options,
        )}`,
      );
    }
    if (options !== undefined) {
      const replacements = Object.keys(options).map(
        (k) => `(${k}:${options[k]})`,
      );
      return `t:${key} - ${replacements.join('|')}`;
    }
    return `t:${key}`;
  };

export const guardedT = (t, key, options = {}, fallback = null) => {
  const value = t(key, options);
  return value === key ? fallback : value;
};
