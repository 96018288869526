import {
  getCookieValue,
  setCookieValue,
  deleteCookie,
} from 'Utils/client-cookie';
import { euc, duc } from 'Utils/url';
import { getTopics } from 'Utils/content/item-util';
import SectionUtil from 'Utils/content/section-util';
import getWindow from 'Utils/get-window';
import sentry from 'Utils/sentry';
import * as ContextualOnboardingUtil from './contextual-onboarding';

const MAX_RELATED_TOPICS_ONBOARDING = 3;
const MAX_RECENTLY_SAVED_ITEMS = 6;
export const ONBOARD_COOKIE = 'contextual_onboarding_items_V2';

export const getContextualSections = (item) => {
  if (!item) {
    return [];
  }

  const {
    remoteid,
    title,
    author,
    isTopic,
    isMagazine,
    isProfile,
    isStoryboard,
    isArticle,
    ssid,
    items,
  } = item;

  const contextualSections = [];

  /* Save an author section */
  if (author && (isMagazine || isStoryboard || isArticle)) {
    contextualSections.push({
      author: {
        authorUsername: author.authorUsername,
        authorDisplayName: author.authorDisplayName,
      },
      remoteid: `flipboard/username/${author.authorUsername}`,
      title: author.authorDisplayName,
      isProfile: true,
    });
  }

  /* Save the section itself */
  if (isTopic || isProfile || isMagazine) {
    contextualSections.push({
      author: author
        ? {
            authorUsername: author.authorUsername,
            authorDisplayName: author.authorDisplayName,
          }
        : {},
      remoteid: isMagazine ? ssid && ssid.remoteidPlain : remoteid,
      isTopic,
      isMagazine,
      isProfile,
      title,
      searchRelated: true,
    });
  }

  /* For storyboards and articles, save N relevant topic sections, as we already have them here.*/
  let relatedTopics = null;
  if (isStoryboard) {
    const coverItem = items.find((item) => item.type === 'sectionCover');
    relatedTopics = getTopics(coverItem);
  }
  if (isArticle) {
    relatedTopics = item.topics;
  }

  if (relatedTopics) {
    relatedTopics.slice(0, MAX_RELATED_TOPICS_ONBOARDING).forEach((topic) =>
      contextualSections.push({
        author: topic.author,
        remoteid: topic.remoteid,
        isTopic: true,
        title: topic.title,
        searchRelated: false,
      }),
    );
  }
  return contextualSections;
};

/**
 * Given a item, save relevant excerpts as contextual onboarding follow items.
 * @param {*} item
 */
export const saveContextualSectionExcerpts = (item) => {
  if (!item) {
    return null;
  }
  try {
    deleteCookie(ONBOARD_COOKIE);
    const sectionsToSave = getContextualSections(item);
    if (sectionsToSave) {
      ContextualOnboardingUtil.saveSectionsToCookie(sectionsToSave);
    }
  } catch (e) {
    sentry.captureException(e);
  }
};

/**
 * Add a section to a recently visited section cookie.
 * Cookie should store as most recent first.
 * @param {*} section
 */
export const saveSectionsToCookie = (sections) => {
  if (sections) {
    // Expire recently visited cookie in 1 hour
    const oneHourFromNow = new Date(Date.now() + 1000 * 60 * 60);

    const recentlyVisited = getRecentlyVisitedSections();

    sections.forEach((section) => {
      if (recentlyVisited.length >= MAX_RECENTLY_SAVED_ITEMS) {
        recentlyVisited.pop();
      }
      if (!SectionUtil.sectionsIncludesSection(recentlyVisited, section)) {
        recentlyVisited.unshift(section);
      }
    });

    setCookieValue(
      ONBOARD_COOKIE,
      getWindow().btoa(euc(JSON.stringify(recentlyVisited))),
      oneHourFromNow,
    );
  }
};

export const getRecentlyVisitedSections = () => {
  const cookieValue = getCookieValue(ONBOARD_COOKIE);
  if (cookieValue) {
    return JSON.parse(duc(getWindow().atob(cookieValue)));
  }
  return [];
};
