import { factory as flaxiosFactory } from 'Utils/flaxios';
import qs from 'Utils/qs';
import ApiHelpers from 'Utils/api/helpers';
import Config from 'Config';

const { FLAB_NAME } = Config;

/**
 * Axios instance to make Flab requests from web-u server to Flab
 */
const flab = flaxiosFactory({
  baseURL: Config.FLAB_URL,
  timeout: 15500,
});

// Set axios instance defaults
flab.defaults.headers.common.Host = Config.FLAB_URL.replace(
  /^(?:\w+:)?\/\//,
  '',
);
// Default query param serializer

const serialize = (params) => qs.stringify(params, { arrayFormat: 'repeat' });

flab.defaults.paramsSerializer = serialize;

// Default request body transformer
flab.defaults.transformRequest = [
  (data) => (typeof data !== 'object' ? data : serialize(data)),
];

/**
 * Response interceptor for Flab requests
 */
flab.interceptors.response.use(
  (response) => ApiHelpers.interceptResponseHandler(response, FLAB_NAME),
  (error) => ApiHelpers.interceptResponseErrorHandler(error, FLAB_NAME),
);

export default flab;
