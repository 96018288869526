import React from 'react';

import sentenceCase from 'Utils/content/sentence-case';

// Components
import {
  StyleVariations,
  StyleModifiers,
} from 'Webapp/shared/app/components/button';
import UserAddIcon from 'ComponentLibrary/icons/user-add';
import InviteContributors from 'Webapp/shared/app/modals/invite-contributors';

import DefaultItemActionButton from 'Webapp/shared/app/components/item-actions/default-item-action-button';

import { ItemActionButtonType } from 'Webapp/enums';

import connector from 'Webapp/utils/connector';
import connectFeatureFlags, {
  ConnectFeatureFlagsProps,
} from 'Webapp/shared/app/connectors/connectFeatureFlags';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';
import connectResponsive, {
  ConnectResponsiveProps,
} from 'Webapp/shared/app/connectors/connectResponsive';
import withT from 'ComponentLibrary/hocs/withT.js';

type InviteContributorButtonProps = {
  section: Flipboard.Section;
  isTooltipMenu: boolean;
  t: Flipboard.TFunction;
} & ConnectFeatureFlagsProps &
  ConnectModalProps &
  ConnectResponsiveProps;

const InviteContributorButton: React.FC<InviteContributorButtonProps> = ({
  section,
  featureFlags,
  showModal,
  isSmallScreen,
  isTooltipMenu,
  t,
}) => {
  const butttonVariation =
    !isTooltipMenu &&
    (isSmallScreen && featureFlags.NEW_MAGAZINE_COVER
      ? StyleVariations.PRIMARY_TEXT
      : StyleVariations.SECONDARY);

  return (
    <DefaultItemActionButton
      className="social-actions_invite-button"
      key={ItemActionButtonType.INVITE_CONTRIBUTOR}
      styleModifier={isTooltipMenu ? [StyleModifiers.BLOCK] : undefined}
      styleVariation={butttonVariation ? butttonVariation : undefined}
      onClick={() => showModal(InviteContributors, { magazine: section })}
      name="item-actions-invite-contributor"
    >
      {featureFlags.NEW_MAGAZINE_COVER && <UserAddIcon size={18} />}
      <span>{sentenceCase(t('invite_contributors'))}</span>
    </DefaultItemActionButton>
  );
};

export default connector<InviteContributorButtonProps>(
  connectModal,
  connectFeatureFlags,
  connectResponsive,
)(withT(InviteContributorButton));
