import FlapUtil from 'Utils/content/flap-util.js';
import { getSectionId } from 'Utils/content/flipboard-urls';

/* Find the section flagged as the primarySectionForRoute,
 * otherwise default to our previous logic of returning the first one in the array.
 **/
export const getPrimarySection = (state: Flipboard.State) => {
  const { entries } = state.sections;
  return (
    entries.find(
      (section: Flipboard.Section) => section.primarySectionForRoute,
    ) || entries[0]
  );
};

export const getPrimarySectionItems = (state: Flipboard.State) => {
  const primarySection = getPrimarySection(state);
  const { entries } = state.sections;
  const remoteId =
    primarySection?.rootTopic?.remoteid || primarySection?.remoteid;

  const currentSection = FlapUtil.sectionOrDefaults(
    FlapUtil.getSectionByRemoteId(remoteId, entries),
  );
  return currentSection.items;
};

export const getCurrentSection = ({
  app,
  sections,
}: Flipboard.State): Flipboard.Section => {
  const { params } = app.routing;
  if (app.routing.query && app.routing.query.id) {
    params.sid = app.routing.query.id;
  }
  const sectionId = getSectionId(params);
  return FlapUtil.sectionOrDefaults(
    FlapUtil.getSectionByRemoteId(sectionId, sections.entries),
  );
};

export const getInviteSection = (state: Flipboard.State) => {
  const { entries } = state.sections;
  return entries.find((section) => section.invite) || null;
};

export const getSectionEntries = (state: Flipboard.State) => {
  const { entries } = state.sections;
  return entries;
};

export const getSectionLoading = (state: Flipboard.State) => {
  const { loading } = state.sections;
  return loading;
};

export const getSectionTopicDescriptions = (state: Flipboard.State) => {
  const { topicDescriptions } = state.sections;
  return topicDescriptions;
};
