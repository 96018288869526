import { getCurrentArticle } from 'Webapp/shared/app/redux/selectors/article';

export default (_, getState) => {
  const article = getCurrentArticle(getState());

  if (article) {
    const { author, sourceDomain, excerpt } = article;
    const attribution = (author && author.authorDisplayName) || sourceDomain;
    if (attribution) {
      return `${attribution} - ${excerpt}`;
    }
    return excerpt;
  }
};
