import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';
import {
  showModal,
  dismissModal,
} from 'Webapp/shared/app/redux/actions/modal-actions';

import { onboardingDataSelector } from 'Webapp/shared/concepts/onboarding';
import { AcceptMagazineInviteError, InviteInvalidError } from 'Utils/errors';

// actions
import { acceptContributorInvite } from 'Webapp/shared/app/redux/actions/section-actions';
import { setInviteAcceptLoading } from 'Webapp/shared/concepts/magazine-invite';

import codeSplitLoad from 'Webapp/shared/utils/code-split-load';

const CompleteProfileCollaboratorModal = codeSplitLoad(
  'CompleteProfileCollaboratorModal',
);

const acceptMagazineInvite: OnboardingStep =
  (nextStep) => async (dispatch, getState) => {
    const onboardingData = onboardingDataSelector(getState());
    dispatch(setInviteAcceptLoading(true));
    if (!onboardingData?.magazineInviteSection) {
      dispatch(setInviteAcceptLoading(false));
      throw new AcceptMagazineInviteError(
        'Accept Magazine Invite Flow is missing magazineInviteSection',
      );
    }
    const section = onboardingData.magazineInviteSection;
    if (!section.remoteid || !section.invite) {
      dispatch(setInviteAcceptLoading(false));
      throw new AcceptMagazineInviteError(
        'Accept Magazine Invite Flow is missing invite or remoteid',
      );
    }

    // Extract data for invite (remoteid, section, invite)
    try {
      await dispatch(
        acceptContributorInvite(section.remoteid, section, section.invite),
      );
    } catch (e) {
      if (e instanceof InviteInvalidError) {
        return dispatch(
          showModal(CompleteProfileCollaboratorModal, {
            onComplete: () => {
              dispatch(dismissModal());
              dispatch(acceptMagazineInvite(nextStep));
            },
          }),
        );
      }
    }
    nextStep();
  };

export default acceptMagazineInvite;
