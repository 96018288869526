import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

import codeSplitLoad from 'Webapp/shared/utils/code-split-load';

import {
  showModal,
  dismissSpecificModal,
} from 'Webapp/shared/app/redux/actions/modal-actions';
const CreateMagazineModal = codeSplitLoad('CreateMagazineModal');

const createMagazineModal: OnboardingStep = (nextStep) => (dispatch) => {
  dispatch(
    showModal(CreateMagazineModal, {
      isPublisherOnboarding: true,
      onComplete: () => {
        dispatch(dismissSpecificModal(CreateMagazineModal));
        nextStep();
      },
    }),
  );
};

export default createMagazineModal;
