import React from 'react';

export const testSplitStringToChildren = (array = []) =>
  array.map((x, i) => <React.Fragment key={i}>{x}</React.Fragment>);

const splitStringToChildren = (string, regexp, transformFn = (x) => x) => {
  const matches = string.match(regexp);
  if (!matches) {
    return string;
  }
  const split = string.split(regexp);
  return split
    .filter((x) => x !== '')
    .map((chunk, i) => {
      const matchIndex = matches && matches.indexOf(chunk);
      let child = chunk;
      if (matchIndex > -1) {
        child = transformFn(chunk);
      }
      return <React.Fragment key={i}>{child}</React.Fragment>;
    });
};
export default splitStringToChildren;
