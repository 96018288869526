import React from 'react';

const cloneChildIfValid = (child, props) => {
  if (React.isValidElement(child)) {
    return React.cloneElement(child, props);
  }

  return child;
};

const childrenWithProps = (childrenToModify, props) => {
  if (Array.isArray(childrenToModify)) {
    return childrenToModify.map((child) => cloneChildIfValid(child, props));
  }
  return cloneChildIfValid(childrenToModify, props);
};

export default childrenWithProps;
