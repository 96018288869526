import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
// Utils
import { SPACING } from 'Style/spacing';
import sentenceCase from 'Utils/content/sentence-case';
import { USAGE_DISPLAY_STYLES } from 'Utils/analytics/usage';

import codeSplitLoad from 'Webapp/shared/utils/code-split-load.js';

// Components
import {
  StyleVariations,
  StyleModifiers,
} from 'Webapp/shared/app/components/button';
import GetAppButton from 'Webapp/shared/app/containers/get-app-button';
import SignupButton from 'Webapp/shared/app/containers/signup-button';
import LoginButton from '../login-button';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectRouting from 'Webapp/shared/app/connectors/connectRouting';
import connectFeatureFlags from 'Webapp/shared/app/connectors/connectFeatureFlags';

const SignupButtonCtaAnchoredDialog = codeSplitLoad(
  'SignupButtonCtaAnchoredDialog',
);

const StyledMainNavSectionaryLoginButton = styled.li({
  marginLeft: SPACING.LARGE,
});

class LoggedOutButtons extends Component {
  constructor(props) {
    super(props);

    this.signUpButtonElement = React.createRef();
  }

  render() {
    const {
      isLargeScreen,
      isDesktop,
      isFromBranchInvite,
      isNewslettersRoute,
      magazineInvite,
      featureFlags,
      t,
    } = this.props;
    const styleModifier = isLargeScreen ? [StyleModifiers.INVERSE] : [];

    return (
      <Fragment>
        {!isFromBranchInvite && !isNewslettersRoute && !isDesktop && (
          <li>
            <GetAppButton
              name="main-nav-get-app-cta"
              styleModifier={styleModifier}
            />
          </li>
        )}
        {isLargeScreen && !magazineInvite.token && (
          <li>
            <div
              className="anchored-dialog-menu__wrapper"
              ref={this.signUpButtonElement}
            >
              <SignupButton
                name="main-nav-signup-cta"
                styleVariation={StyleVariations.PRIMARY}
                displayStyle={USAGE_DISPLAY_STYLES.NAV_BAR}
                label={sentenceCase(t('sign_up'))}
              />
              {!featureFlags.TOPIC_PICKER_CTA && (
                <SignupButtonCtaAnchoredDialog
                  targetRef={this.signUpButtonElement}
                />
              )}
            </div>
          </li>
        )}
        {isLargeScreen && (
          <StyledMainNavSectionaryLoginButton>
            <LoginButton name="main-nav-login" />
          </StyledMainNavSectionaryLoginButton>
        )}
      </Fragment>
    );
  }
}

LoggedOutButtons.propTypes = {
  isLargeScreen: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  isFromBranchInvite: PropTypes.bool.isRequired,
  isNewslettersRoute: PropTypes.bool.isRequired,
  magazineInvite: PropTypes.shape({
    token: PropTypes.string,
  }).isRequired,
  featureFlags: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default connector(
  connectResponsive,
  connectRouting,
  connectFeatureFlags,
)(withT(LoggedOutButtons));
