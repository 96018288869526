import FlapUtil from './flap-util';
import SectionUtil from './section-util';

/* TODO: there are a bunch of projection/search
 * results in here that maybe don't make sense anymore and/or may not be used.
 */

/**
 * Returns a projection of a topic with useful properties used by components
 * @param  {Object} topic - Search topic result
 * @return {Object} - Projection of the search result topic category
 */
function topicProjection(topic) {
  return Object.assign({}, topic, {
    topicTag: FlapUtil.getTopicTagFromRemoteid(topic.remoteid),
  });
}

/**
 * Returns a projection of a search result with useful properties used by components
 * @param  {Object} result - Search result (contains searchResultItems)
 * @return {Object} - Projection of the search result item
 */
function searchResultProjection(result) {
  const { category } = result;

  let projected = Object.assign({}, result);

  // Decorate topic results
  if (category === 'topic') {
    projected = Object.assign({}, projected, {
      searchResultItems: result.searchResultItems.map((topic) =>
        topicProjection(topic),
      ),
    });
  }

  // Do section projections on all section results
  if (
    ['top_result', 'topic', 'magazine', 'profile', 'source'].includes(category)
  ) {
    projected = Object.assign({}, result, {
      searchResultItems: result.searchResultItems.map((section) =>
        SectionUtil.projection(section),
      ),
    });
  }

  return projected;
}

function topicsProjection(stream = []) {
  return (
    stream
      .find((i) => i.category === 'topic')
      ?.searchResultItems?.map((i) => SectionUtil.projection(i))
      .filter(SectionUtil.isTopic) || []
  );
}

/**
 * Returns a projection of search results with useful properties used by components
 * @param  {Array} searchResults Flipboard search results array
 * @return {Object} - Projected Flipboard search results
 */
function projection(searchResults) {
  return searchResults.map((result) => searchResultProjection(result));
}

/**
 * Returns the search result items for a specified result category
 * @param  {Array} searchResults Flipboard search results array
 * @param {String} category  The name of the result category to return
 * @return {Object} - Projected Flipboard search results
 */
function resultItems(searchResults, category) {
  const result =
    searchResults &&
    searchResults.find((result) => result.category === category);
  return (result && result.searchResultItems) || [];
}

export default {
  projection,
  topicsProjection,
  resultItems,
};
