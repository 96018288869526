import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

// Utils
import StoryboardRenderingUtil from 'Utils/content/storyboard-rendering-util';
import Attribution from 'Utils/content/attribution';
import { getOriginalItem } from 'Utils/content/item-util';
import { getImageObjectFromSection } from 'Utils/image-util';
import { noOpFn } from 'Utils/no-op-fn';

import connector from 'Utils/connector';
import connectIsArticleRoute from 'Webapp/shared/app/connectors/connectIsArticleRoute';
import connectModal from 'Webapp/shared/app/connectors/connectModal';

import ItemOverlay from '../item/card/item-overlay';
import Image from './image';
import Video from './video';
import Audio from './audio';
import ItemLink from '../item/item-link';
import ImageUnavailable from '../image-unavailable';
import ImageModal from 'Webapp/shared/app/modals/image';
import { THEMES as IMAGE_THEMES } from 'Webapp/shared/app/components/base/generic-image';

const StyledImageLink = styled(ItemLink)({ borderRadius: 'inherit' });
const StyledImage = styled(Image)({ borderRadius: 'inherit' });
const { sizes } = StoryboardRenderingUtil;
/**
 * Abstraction for Media Types
 */
function Media({
  className,
  item,
  section,
  shownInList,
  allowPlayableMedia,
  onError,
  useCrop,
  windowHeight,
  fallbackElementSize,
  imageTheme,
  previewSize,
  showWideVideos,
  isArticleRoute,
  link,
  showModal,
  ampClass,
  loadingMode,
  ampHeight,
  ampWidth,
}) {
  let itemToRender = item;

  // ImageUnavailable element for fallback image rendering, requires the author object
  const author = Attribution.getAuthor(item);

  const firstPartyVideoInList = itemToRender.isFirstPartyVideo && shownInList;

  const fallbackElement = (
    <ImageUnavailable
      author={author}
      fallbackElementSize={fallbackElementSize}
    />
  );

  const { isVideo, isAudio, isStatus, section: itemSection } = itemToRender;
  const videoItem = item.inlineVideoItem || item;

  if (isStatus) {
    itemToRender = getOriginalItem(item);
  }

  if (
    allowPlayableMedia &&
    isVideo &&
    (videoItem.isYoutube || videoItem.isVimeo || videoItem.isFirstPartyVideo)
  ) {
    return (
      <figure className={`${className} post__media`}>
        <Video
          link={firstPartyVideoInList}
          shownInList={shownInList}
          item={itemToRender}
          section={section}
          useCrop={useCrop}
          windowHeight={windowHeight}
          fallbackElement={fallbackElement}
          previewSize={previewSize}
          showWideVideos={showWideVideos}
        />
      </figure>
    );
  } else if (allowPlayableMedia && isAudio && item.isSoundcloud) {
    // Currently only supporting inline soundcloud players, others should go inside the link
    return (
      <figure className={`${className} post__media post__media--audio`}>
        <Audio
          item={item}
          fallbackElement={fallbackElement}
          useCrop={useCrop}
        />
      </figure>
    );
  }

  const imageToRender = getImageObjectFromSection(itemSection || itemToRender);

  const playIconSize = previewSize === sizes.SMALL ? 32 : 48;

  // render first topic that is not the current section's topic tag if present
  const displayTopic =
    isArticleRoute && shownInList && previewSize !== sizes.SMALL
      ? item.displayTopic
      : null;

  const showDuration = previewSize === sizes.SMALL ? showWideVideos : true;

  const handleMediaClick = () => {
    if (!link && item.isFlipComposeImage) {
      return (e) => {
        e.preventDefault();
        showModal(ImageModal, { item, section });
      };
    }

    return noOpFn;
  };

  const imageElement = (
    <StyledImage
      image={imageToRender}
      alt={itemToRender.title}
      onError={onError}
      windowHeight={windowHeight}
      fallbackElement={fallbackElement}
      crop={useCrop}
      variant={imageTheme}
      onClick={handleMediaClick()}
      ampClass={ampClass}
      loadingMode={loadingMode}
      ampWidth={ampWidth}
      ampHeight={ampHeight}
    />
  );

  let mediaElement = imageElement;

  if (link) {
    mediaElement = (
      <StyledImageLink
        item={itemToRender}
        section={section}
        shownInList={shownInList}
        className="post__media--image media-link"
      >
        {imageElement}
      </StyledImageLink>
    );
  } else if (item.isFlipComposeImage) {
    mediaElement = <div className="modal-link">{imageElement}</div>;
  }

  return (
    <div className={`${className} post__media`}>
      {mediaElement}
      <React.Fragment>
        <ItemOverlay
          size={playIconSize}
          border
          isCondensed={previewSize === sizes.SMALL}
          item={itemToRender}
          showDuration={showDuration}
          isMedia={!!firstPartyVideoInList}
          topicTag={displayTopic}
          shownInList={shownInList}
        />
      </React.Fragment>
    </div>
  );
}

Media.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
  section: PropTypes.object,
  shownInList: PropTypes.bool,
  allowPlayableMedia: PropTypes.bool,
  onError: PropTypes.func,
  useCrop: PropTypes.bool,
  windowHeight: PropTypes.number,
  imageTheme: PropTypes.oneOf(Object.keys(IMAGE_THEMES)),
  fallbackElementSize: PropTypes.oneOf(
    Object.values(ImageUnavailable.fallbackElementSizes),
  ),
  previewSize: PropTypes.oneOf(Object.values(sizes)),
  showWideVideos: PropTypes.bool,
  isArticleRoute: PropTypes.bool.isRequired,
  link: PropTypes.bool,
  showModal: PropTypes.func.isRequired,
  ampClass: PropTypes.string,
  loadingMode: PropTypes.string,
  ampHeight: PropTypes.number,
  ampWidth: PropTypes.number,
};

Media.defaultProps = {
  section: null,
  shownInList: true,
  allowPlayableMedia: true,
  onError: undefined,
  useCrop: true,
  windowHeight: null,
  fallbackElementSize: ImageUnavailable.fallbackElementSizes.CARD,
  previewSize: null,
  showWideVideos: false,
  link: true,
  ampClass: '',
};

export default connector(connectModal, connectIsArticleRoute)(Media);
