import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

import { GA } from 'Utils/analytics';

import { trackNewsletterSubscribe as gTagTrackNewsletterSubscribe } from 'Webapp/shared/utils/google-tag-manager';

import { FrictionlessFlowType } from 'Webapp/enums';

const trackNewsletterSubscriptions: OnboardingStep =
  (nextStep) => (_dispatch) => {
    gTagTrackNewsletterSubscribe();
    GA.trackFrictionlessSubscribeToNewsletter(FrictionlessFlowType.NEWSLETTER);
    nextStep();
  };

export default trackNewsletterSubscriptions;
