import React from 'react';
import { ItemMetricDetailType, ItemActionButtonType } from 'Webapp/enums';

import sentenceCase from 'Utils/content/sentence-case';

// Components
import LikeIcon from 'ComponentLibrary/icons/new_social/like';
import ItemMetricDetailButton from 'Webapp/shared/app/components/item-metric-detail-button';
import { getSocialActivityValue } from 'Webapp/shared/concepts/social-activity';
import DefaultItemActionButton from 'Webapp/shared/app/components/item-actions/default-item-action-button';
import {
  ItemButtonWithMetric,
  TOOLTIP_SOCIAL_BUTTON_STYLE_MODIFIER,
} from 'Webapp/shared/app/hocs/withItemActions.js';
import ItemMetric from 'Webapp/shared/app/components/item-actions/item-metric';
import DisabledWrapper from 'Webapp/shared/app/components/disabled-wrapper';

import withT from 'ComponentLibrary/hocs/withT';
import withLike from 'Webapp/shared/app/hocs/withLike';

import connector from 'Utils/connector';

import connectAuthentication, {
  ConnectAuthenticationProps,
} from 'Webapp/shared/app/connectors/connectAuthentication';
import connectCurrentUser, {
  ConnectCurrentUserProps,
} from 'Webapp/shared/app/connectors/connectCurrentUser';
import connectSocialActivity, {
  ConnectSocialActivityProps,
} from 'Webapp/shared/app/connectors/connectSocialActivity';
import connectFeatureFlags, {
  ConnectFeatureFlagsProps,
} from 'Webapp/shared/app/connectors/connectFeatureFlags';

type LikeButtonPassedProps = {
  item: Flipboard.Item;
  section?: Flipboard.Section;
  isTooltipMenu: boolean;
  size: number;
};

type LikeButtonProps = {
  showSocialMetrics: boolean;
  onLike: () => void;
  isLiked: boolean;
  t: Flipboard.TFunction;
} & LikeButtonPassedProps &
  ConnectSocialActivityProps &
  ConnectFeatureFlagsProps &
  ConnectAuthenticationProps &
  ConnectCurrentUserProps;

const LikeButton: React.FC<LikeButtonProps> = ({
  item,
  size,
  section,
  onLike,
  isUserStateLoading,
  isTooltipMenu,
  showSocialMetrics,
  socialActivity,
  isLiked,
  featureFlags,
  t,
}) => {
  const { canLike } = item;
  const showGlobalLikes = !section?.isMagazine;

  const likeCount = getSocialActivityValue(
    item,
    socialActivity,
    'likeCount',
    showGlobalLikes,
  );
  const disabled = !canLike || isUserStateLoading;
  return (
    <ItemButtonWithMetric key={ItemActionButtonType.LIKE}>
      <DisabledWrapper disabled={disabled}>
        <DefaultItemActionButton
          tooltip={t('like')}
          isTooltipMenu={isTooltipMenu}
          className="social-actions__button"
          styleModifier={
            isTooltipMenu ? TOOLTIP_SOCIAL_BUTTON_STYLE_MODIFIER : undefined
          }
          onClick={onLike}
          name="social-action-like"
          disabled={disabled}
          key={ItemActionButtonType.LIKE}
        >
          <LikeIcon size={size} isLiked={isLiked} />
          {isTooltipMenu && <span>{sentenceCase(t('like'))}</span>}
        </DefaultItemActionButton>
      </DisabledWrapper>
      <DisabledWrapper disabled={disabled}>
        {showSocialMetrics &&
          (featureFlags.ITEM_METRIC_DETAIL && likeCount && !showGlobalLikes ? (
            <ItemMetricDetailButton
              item={item}
              section={section}
              itemMetricDetailType={ItemMetricDetailType.LIKE}
            >
              <ItemMetric metricValue={likeCount} />
            </ItemMetricDetailButton>
          ) : (
            <ItemMetric metricValue={likeCount} />
          ))}
      </DisabledWrapper>
    </ItemButtonWithMetric>
  );
};

export default connector(
  connectAuthentication,
  connectCurrentUser,
  connectSocialActivity,
  connectFeatureFlags,
)(withLike(withT(LikeButton)));
