/* eslint-disable react/prefer-stateless-function */
// Allow creation of class component, so that refs can still be used on wrapped components

import React from 'react';
import { I18NContext } from 'ComponentLibrary/context';
import HocUtil from 'Utils/hoc-util';

function withT(Component) {
  class WrappedComponent extends React.Component {
    render() {
      return (
        <I18NContext.Consumer>
          {(t) => <Component {...this.props} t={t} />}
        </I18NContext.Consumer>
      );
    }
  }

  // Proxy the wrapped component displayName
  WrappedComponent.displayName = HocUtil.displayName(Component);

  return WrappedComponent;
}

export default withT;
