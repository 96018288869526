import React from 'react';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';

import NotificationListItem from 'Webapp/shared/app/components/notifications/notification-list-item';

import connector from 'Utils/connector';
import connectNotifications, {
  ConnectNotificationsProps,
} from 'Webapp/shared/app/connectors/connectNotifications';

const NotificationsListWrapper = styled.div({
  paddingRight: SPACING.LARGE,
});
const StyledList = styled.ul({
  display: 'grid',
  gap: SPACING.MEDIUM,
});

type NotificationsListProps = ConnectNotificationsProps;
const NotificationsList: React.FC<NotificationsListProps> = ({
  notifications,
}) => (
  <NotificationsListWrapper>
    <StyledList>
      {notifications.map((notification) => (
        <NotificationListItem
          key={notification.id}
          notification={notification}
        />
      ))}
    </StyledList>
  </NotificationsListWrapper>
);

export default connector<NotificationsListProps>(connectNotifications)(
  NotificationsList,
);
