// Utils
import sentry from 'Utils/sentry';
import SectionUtil from 'Utils/content/section-util.js';
import FlapUtil from 'Utils/content/flap-util.js';
import { PromiseAll } from 'Utils/promise';
import updateFeed from 'Utils/api/flap/endpoints/update-feed';

import {
  FlapItemType,
  FlapSectionFeedType,
  FlapSectionType,
} from 'Webapp/enums';

import {
  setAccessorySection,
  ACCESSORY_SECTIONS,
  accessorySectionMagazineId,
} from 'Webapp/shared/concepts/accessory-sections';

import {
  getBasicSections,
  getBasicSectionWithItems,
} from 'Webapp/shared/app/redux/actions/section-actions';
import { smartMagazineSelector } from 'Webapp/shared/app/redux/selectors/profile';

// Selectors
import { currentUserUid } from '../selectors/auth';

const RELATED_STORYBOARDS_LIMIT = 8;

export const fetchTopicCustomizations =
  (
    remoteId: Flipboard.SectionId,
  ): Flipboard.Thunk<Promise<Flipboard.SectionGroup[]>> =>
  async (_dispatch, _getState, { flap }) => {
    try {
      const params = { topicId: remoteId };
      const {
        data: { groups },
      } = await flap.get<Flipboard.FlapTopicCustomizationResponse>(
        '/flipboard/customizeBoard',
        { params },
      );
      if (groups) {
        return groups.map(
          (g) =>
            ({
              ...g,
              subsections: g.subsections
                .filter((s) => s.remoteid !== remoteId) // remove root topic
                .map((s) => {
                  const basicSection: null | Flipboard.BasicSection =
                    SectionUtil.getBasicProjectedSection(s);
                  return basicSection;
                })
                .filter((s) => s !== null),
            } as Flipboard.SectionGroup),
        );
      }
    } catch (error) {
      sentry.captureException(error as Error);
    }
    return [];
  };

const getMagazineContributors =
  (remoteId: Flipboard.SectionId): Flipboard.Thunk =>
  async (dispatch, _getState, { flap }) => {
    try {
      const params = { sectionid: remoteId };
      const { data } = await flap.get('/curator/magazineContributors', {
        params,
      });

      if (!data) {
        return;
      }
      const { contributors } = data;
      return dispatch(
        setAccessorySection({
          key: ACCESSORY_SECTIONS.MAGAZINE_CONTRIBUTORS,
          remoteId: accessorySectionMagazineId(remoteId),
          value: contributors,
        }),
      );
    } catch (error) {
      sentry.captureException(error as Error);
    }
  };

const getRecommendedMagazinesByTopic =
  (topics) =>
  async (dispatch, _getState, { flap }) => {
    const defaultReturnValue = [];
    try {
      const { data } = await flap.get(`/social/recommendedMagazines`, {
        params: { topics },
      });
      if (!data) {
        return defaultReturnValue;
      }

      const magazines = data.results;
      if (!magazines || magazines.length === 0) {
        return defaultReturnValue;
      }
      const remoteids = magazines.map((magazine) => magazine.remoteid);
      const sections =
        (await dispatch(getBasicSections(remoteids))) || defaultReturnValue;
      return sections.map((section) => {
        const projectedSection = SectionUtil.getBasicProjectedSection(section);

        /**
         * The response from the recommendedMagazines api includes a list of relevant topics.
         * We want to preserve those relationships in the section we return here.
         */
        const currentMagazine = magazines.find(
          (magazine) => magazine.remoteid === section.sectionID,
        );
        if (projectedSection) {
          projectedSection.topics =
            currentMagazine &&
            currentMagazine.sections &&
            currentMagazine.sections
              .filter((section) => section.type === 'topic')
              .map(SectionUtil.getBasicProjectedSection);
        }

        return projectedSection;
      });
    } catch (e) {
      return defaultReturnValue;
    }
  };
const getRecommendedMagazines =
  (remoteId): Flipboard.Thunk =>
  async (dispatch) => {
    // Related Magazines for a topic available via a flap api. Likewise, these are NOT rendered at this time. Do not preload.
    const topicTag = FlapUtil.getTopicTagFromRemoteid(remoteId);
    const recommendedMagazines = await dispatch(
      getRecommendedMagazinesByTopic(topicTag),
    );

    dispatch(
      setAccessorySection({
        key: ACCESSORY_SECTIONS.TOPIC_RECOMMENDED_MAGAZINES,
        remoteId,
        value: recommendedMagazines,
      }),
    );
  };

export const getSpecialVerticalTopicMagazines =
  (
    targetRemoteId: Flipboard.SectionId,
    remoteId: Flipboard.SectionId,
  ): Flipboard.Thunk =>
  async (dispatch, getState, { flap }) => {
    const uid = currentUserUid(getState());
    const {
      data: { stream },
    } = await updateFeed(flap, uid, {
      sections: remoteId,
      limit: 10, // needed to get magazine topics
    });

    const magazines = stream.filter(
      (x) =>
        x.type === FlapItemType.SECTION &&
        x.section?.type === FlapSectionType.LINK &&
        x.section?.feedType === FlapSectionFeedType.MAGAZINE,
    );
    const remoteids = magazines.reduce((acc, x) => {
      if (x.section?.remoteid) {
        acc.push(x.section.remoteid);
      }
      return acc;
    }, [] as Array<Flipboard.SectionId>);
    const sections = await dispatch(getBasicSections(remoteids));
    const recommendedMagazines = sections.map((section) => {
      const projectedSection = SectionUtil.getBasicProjectedSection(section);
      const currentMagazine = magazines.find(
        (magazine) => magazine.section?.remoteid === section.sectionID,
      );
      if (projectedSection) {
        projectedSection.topics = currentMagazine?.sectionLinks
          .filter(SectionUtil.isTopic)
          .map(SectionUtil.getBasicProjectedSection);
        return projectedSection;
      }
    });

    dispatch(
      setAccessorySection({
        key: ACCESSORY_SECTIONS.TOPIC_RECOMMENDED_MAGAZINES,
        remoteId: targetRemoteId,
        value: recommendedMagazines,
      }),
    );
  };

const getRelatedStoryboards =
  (
    targetRemoteId: Flipboard.SectionId,
    remoteId?: Flipboard.SectionId,
  ): Flipboard.Thunk =>
  async (dispatch) => {
    let relatedStoryboardRemoteId = remoteId;
    if (!remoteId) {
      const topicTag = FlapUtil.getTopicTagFromRemoteid(targetRemoteId);
      relatedStoryboardRemoteId =
        `flipboard/storyboards%2F${topicTag}` as Flipboard.SectionId;
    }

    const section = await dispatch(
      getBasicSectionWithItems(
        relatedStoryboardRemoteId,
        RELATED_STORYBOARDS_LIMIT,
      ),
    );

    const topicRelatedStoryboardSection = SectionUtil.getBasicProjectedSection(
      section,
    ) as Flipboard.BasicSection;

    dispatch(
      setAccessorySection({
        key: ACCESSORY_SECTIONS.TOPIC_STORYBOARDS,
        remoteId: targetRemoteId,
        value: [topicRelatedStoryboardSection],
      }),
    );
  };

export const fetchAccessorySections =
  (remoteId: Flipboard.SectionId): Flipboard.Thunk<Promise<unknown[]>> =>
  async (dispatch, getState, { flapStatic }) => {
    const promises: Array<Promise<void>> = [];
    const smartMagazines = smartMagazineSelector(getState());
    const smartMagazine = smartMagazines?.find((sm) =>
      FlapUtil.isRemoteIdSectionMatch(remoteId, sm),
    );
    const targetRemoteId = smartMagazine?.rootTopic?.remoteid || remoteId;

    if (FlapUtil.isMagazineSectionId(targetRemoteId)) {
      promises.push(dispatch(getMagazineContributors(targetRemoteId)));
    } else if (FlapUtil.isTopicSectionId(targetRemoteId)) {
      // ridiculous hack, we want to force relationships for certain topics for
      let topicRelatedMagazineAndStoryboardOverrides;
      try {
        const { data } = await flapStatic.get(
          '/topicRelatedMagazineAndStoryboardOverrides.json',
        );
        topicRelatedMagazineAndStoryboardOverrides = data;
      } catch (e) {
        topicRelatedMagazineAndStoryboardOverrides = {};
      }
      const normalizedId = FlapUtil.normalizeRemoteid(targetRemoteId);
      const {
        magazinesRemoteId: overrideRelatedMagazinesRemoteId,
        storyboardsRemoteId: overrideRelatedStoryboardsRemoteId,
      } = topicRelatedMagazineAndStoryboardOverrides[normalizedId] || {};

      if (
        overrideRelatedMagazinesRemoteId &&
        overrideRelatedStoryboardsRemoteId
      ) {
        promises.push(
          dispatch(
            getSpecialVerticalTopicMagazines(
              targetRemoteId,
              overrideRelatedMagazinesRemoteId,
            ),
          ),
        );
        promises.push(
          dispatch(
            getRelatedStoryboards(
              targetRemoteId,
              overrideRelatedStoryboardsRemoteId,
            ),
          ),
        );
      } else {
        promises.push(dispatch(getRecommendedMagazines(targetRemoteId)));
        promises.push(dispatch(getRelatedStoryboards(targetRemoteId)));
      }
    }
    return PromiseAll(promises);
  };
