import React from 'react';
import styled from '@emotion/styled';
import { componentSelector } from 'Style/type-helpers';
import { SPACING } from 'Style/spacing';
import {
  SURFACE_COLORS,
  TEXT_COLORS,
  DIVIDER_COLORS,
  ICON_COLORS,
} from 'Style/colors';
import { LAYERS } from 'Style/layout.js';

import Config from 'Config';

import replaceTags from 'Utils/i18n/replace-tags';

import PopupDialog from 'Webapp/shared/app/components/popup-dialog';
import { StyledCloseNewIcon } from 'ComponentLibrary/icons/close-new.js';
import WebLink from 'Webapp/shared/app/components/web-link';

import withT from 'ComponentLibrary/hocs/withT';

const FixedNotificationPopup = styled(PopupDialog)({
  zIndex: LAYERS.PERSISTENT_NOTIFICATION,
  display: 'flex',
  alignItems: 'center',
  gap: SPACING.LARGE,
  padding: SPACING.XLARGE,
  paddingRight: SPACING.BASE6X,
  color: TEXT_COLORS.primaryReverse,
  background: SURFACE_COLORS.primaryReverseWithEmphasis,
  boxShadow: 'var(--shadow--elevation-2)',
  border: `1px solid ${DIVIDER_COLORS.secondary}`,
  borderRadius: SPACING.BASE,
  cursor: 'auto',
  '.button--unstyled:hover .button--unstyled__children *': {
    color: ICON_COLORS.reverse,
  },
  [componentSelector(StyledCloseNewIcon)]: {
    color: ICON_COLORS.reverse,
  },
  a: {
    color: TEXT_COLORS.redLink,
    '&:hover': {
      color: TEXT_COLORS.redLinkHover,
    },
    '&:focus': {
      color: TEXT_COLORS.redLink,
      backgroundColor: 'inherit',
    },
  },
});
type PrivacyNoticePopupProps = {
  className?: string;
  onDismiss: () => void;
  children: React.ReactNode;
  t: Flipboard.TFunction;
};

const PrivacyNoticePopup: React.FC<PrivacyNoticePopupProps> = ({
  className,
  onDismiss,
  t,
}) => (
  <FixedNotificationPopup
    className={className}
    positionProps={{ bottom: SPACING.LARGE }}
    showCloseButton
    onDismiss={onDismiss}
  >
    <span>
      {replaceTags(t('privacy_change_notice'), {
        link(text: string) {
          return (
            <WebLink
              name="main-nav-privacy-policy"
              href={`${Config.ABOUT_BASE_URL}/privacy/`}
              openInNewWindow
            >
              {text}
            </WebLink>
          );
        },
      })}
    </span>
  </FixedNotificationPopup>
);

export default withT(PrivacyNoticePopup);
