import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ICON_COLORS } from 'Style/colors';
import Icon from './icon';
import withT from 'ComponentLibrary/hocs/withT';

export const StyledBellIcon = styled(Icon)((props) => ({
  color: props.color || ICON_COLORS.emphasis,
  fill: 'currentColor',
}));

function BellIcon({ size, t, ...props }) {
  return (
    <StyledBellIcon
      name={t('notifications')}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      {...props}
      renderComponent={() => (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.0001 1C10.1436 1 8.36309 1.7375 7.05033 3.05025C5.73758 4.36301 5.00008 6.14348 5.00008 8C5.00008 11.3527 4.28301 13.4346 3.62218 14.6461C3.29039 15.2544 2.9673 15.6535 2.74288 15.8904C2.6304 16.0091 2.54202 16.0878 2.4888 16.1322C2.46218 16.1544 2.44432 16.168 2.43663 16.1737L2.43377 16.1758C2.07528 16.4221 1.91664 16.8725 2.04299 17.2898C2.17068 17.7115 2.55941 18 3.00008 18H21.0001C21.4407 18 21.8295 17.7115 21.9572 17.2898C22.0835 16.8725 21.9249 16.4221 21.5664 16.1758L21.5635 16.1737C21.5558 16.168 21.538 16.1544 21.5114 16.1322C21.4581 16.0878 21.3698 16.0091 21.2573 15.8904C21.0329 15.6535 20.7098 15.2544 20.378 14.6461C19.7171 13.4346 19.0001 11.3527 19.0001 8C19.0001 6.14349 18.2626 4.36301 16.9498 3.05025C15.6371 1.7375 13.8566 1 12.0001 1ZM18.8508 16H5.1494C5.22549 15.8753 5.30188 15.7434 5.37797 15.6039C6.21715 14.0654 7.00008 11.6473 7.00008 8C7.00008 6.67392 7.52686 5.40215 8.46454 4.46447C9.40223 3.52678 10.674 3 12.0001 3C13.3262 3 14.5979 3.52678 15.5356 4.46447C16.4733 5.40215 17.0001 6.67392 17.0001 8C17.0001 11.6473 17.783 14.0654 18.6222 15.6039C18.6983 15.7434 18.7747 15.8753 18.8508 16Z"
            fill="currentColor"
          />
          <path
            d="M11.1347 20.4981C10.8576 20.0204 10.2457 19.8578 9.76792 20.1349C9.2902 20.412 9.12758 21.024 9.4047 21.5017C9.66841 21.9563 10.0469 22.3337 10.5024 22.596C10.9578 22.8583 11.4741 22.9964 11.9997 22.9964C12.5253 22.9964 13.0416 22.8583 13.497 22.596C13.9525 22.3337 14.331 21.9563 14.5947 21.5017C14.8718 21.024 14.7092 20.412 14.2315 20.1349C13.7537 19.8578 13.1418 20.0204 12.8647 20.4981C12.7768 20.6497 12.6506 20.7755 12.4988 20.8629C12.347 20.9504 12.1749 20.9964 11.9997 20.9964C11.8245 20.9964 11.6524 20.9504 11.5006 20.8629C11.3488 20.7755 11.2226 20.6497 11.1347 20.4981Z"
            fill="currentColor"
          />
        </>
      )}
    />
  );
}

BellIcon.propTypes = {
  size: PropTypes.number,
  t: PropTypes.func.isRequired,
};

BellIcon.defaultProps = {
  size: 24,
};

export default withT(BellIcon);
