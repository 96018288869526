import { PUBLISHER_TYPES } from 'Webapp/action-types';
import { RemoteStoreState } from 'Webapp/enums';

interface PublisherReducerState {
  remoteStoreState: RemoteStoreState;
  publisherInfo: null | Flipboard.PublisherInfo;
  pipes: Array<Flipboard.Pipe>;
  isLoading: boolean;
  loadingFailed: boolean;
}

const initialState: PublisherReducerState = {
  remoteStoreState: RemoteStoreState.INITIAL,
  publisherInfo: null,
  pipes: [],
  isLoading: false,
  loadingFailed: false,
};

export default function publisher(state = initialState, action) {
  switch (action.type) {
    case PUBLISHER_TYPES.CREATE_PUBLISHER_PENDING:
    case PUBLISHER_TYPES.GET_PUBLISHER_INFO_PENDING: {
      return Object.assign({}, state, {
        remoteStoreState: RemoteStoreState.LOADING,
        isLoading: true,
      });
    }
    case PUBLISHER_TYPES.CREATE_PUBLISHER_SUCCESS:
    case PUBLISHER_TYPES.UPDATE_PUBLISHER_STATUS_SUCCESS:
    case PUBLISHER_TYPES.GET_PUBLISHER_INFO_SUCCESS: {
      return Object.assign({}, state, {
        publisherInfo: action.publisher,
        remoteStoreState: RemoteStoreState.LOADED,
        isLoading: false,
      });
    }
    case PUBLISHER_TYPES.CREATE_PUBLISHER_FAILED:
    case PUBLISHER_TYPES.GET_PUBLISHER_INFO_FAILED: {
      return Object.assign({}, state, {
        remoteStoreState: RemoteStoreState.FAILED,
        isLoading: false,
      });
    }
    case PUBLISHER_TYPES.GET_PUBLISHER_PIPES_SUCCESS: {
      return Object.assign({}, state, {
        pipes: action.pipes,
        isLoading: false,
      });
    }
    case PUBLISHER_TYPES.GET_PUBLISHER_PIPES_PENDING: {
      return Object.assign({}, state, { pipes: [], isLoading: true });
    }
    case PUBLISHER_TYPES.GET_PUBLISHER_PIPES_FAILED: {
      return Object.assign({}, state, { pipes: [], isLoading: false });
    }
    case PUBLISHER_TYPES.CANCEL_PUBLISHER_APPLICATION_SUCCESS: {
      return Object.assign({}, state, { publisherInfo: null });
    }
    default:
      return state;
  }
}
