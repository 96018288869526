import connector from 'Utils/connector';
import {
  usageTrackSocialGateOverlayDisplayed,
  usageTrackSocialGateOverlayExit,
} from 'Webapp/shared/app/redux/actions/usage-actions';
import SocialActionGate from 'Webapp/shared/app/modals/social-action-gate';

export default connector({
  actions: {
    usageTrackSocialGateOverlayDisplayed,
    usageTrackSocialGateOverlayExit,
  },
})(SocialActionGate);
