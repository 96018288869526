import getWindow from 'Utils/get-window';
const KEYS = {
  UP: 38,
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39,
  ENTER: 13,
  ESCAPE: 27,
  TAB: 9,
  SPACE: 32,
  F: 70,
  I: 73,
  J: 74,
  K: 75,
  N: 78,
  P: 80,
};

class WindowKeyDown {
  constructor(onKeyDown) {
    this.onKeyDown = onKeyDown;
  }

  /**
   * Adds a listener for window key down keyboard events that calls a callback
   * @param {function} onResize A callback function to call on every scroll event
   */
  subscribe() {
    getWindow().addEventListener('keydown', this.onKeyDown);
  }

  /**
   * Removes a listener for window key down keyboard events
   * @param {function} onResize A callback function to remove with the listener
   */
  unsubscribe() {
    getWindow().removeEventListener('keydown', this.onKeyDown);
  }
}

export { KEYS };
export default WindowKeyDown;
