import React from 'react';
import PropTypes from 'prop-types';
import HocUtil from 'Utils/hoc-util';
import ShareModal from 'Webapp/shared/app/containers/modals/share';
import withNavFrom from '../hocs/withNavFrom';

import connector from 'Utils/connector';
import connectModal from 'Webapp/shared/app/connectors/connectModal';

import {
  usageTrackTapShareItem,
  usageTrackTapShareSection,
} from 'Webapp/shared/app/redux/actions/usage-actions';

function withShare(Component) {
  class WrappedComponent extends React.Component {
    constructor(props) {
      super(props);
      this.handleShare = this.handleShare.bind(this);
    }

    handleShare(shareModalProps) {
      const {
        showModal,
        navFrom,
        usageTrackTapShareItem,
        usageTrackTapShareSection,
      } = this.props;
      const { section, item, callback } = shareModalProps;
      const modalProps = Object.assign({}, shareModalProps, { navFrom });

      // Track share tap
      if (item) {
        usageTrackTapShareItem(section, item);
      } else {
        section && usageTrackTapShareSection(section);
      }

      showModal(ShareModal, modalProps);
      callback && callback();
    }

    render() {
      return <Component {...this.props} onShare={this.handleShare} />;
    }
  }

  // Proxy the wrapped component displayName
  WrappedComponent.displayName = HocUtil.displayName(Component);
  WrappedComponent.propTypes = {
    showModal: PropTypes.func.isRequired,
    navFrom: PropTypes.string,
    usageTrackTapShareItem: PropTypes.func.isRequired,
    usageTrackTapShareSection: PropTypes.func.isRequired,
  };

  WrappedComponent.defaultProps = {
    navFrom: null,
  };

  return connector(
    {
      actions: { usageTrackTapShareItem, usageTrackTapShareSection },
    },
    connectModal,
  )(withNavFrom(WrappedComponent));
}

export default withShare;
