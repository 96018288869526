import { ACCOUNT_EMAIL_SETTINGS_TYPES } from 'Webapp/action-types';
import {
  preselectedGroup,
  preselectedSubscription,
  emailSubscriptions,
  subscribedToAll,
  emailGroups,
} from '../../selectors/email-settings';
import sentry from 'Utils/sentry';

import {
  verificationCode,
  verificationSecret,
  langParam,
} from '../../selectors/routing';
import {
  isAuthenticated,
  currentUserUid,
} from 'Webapp/shared/app/redux/selectors/auth';
import { set } from 'Utils/redux';
import { showModal } from 'Webapp/shared/app/redux/actions/modal-actions';
import {
  toastShowErrorAction,
  toastShowInfoAction,
} from 'Webapp/shared/app/redux/actions/toast-actions';
import {
  SUBSCRIPTION_EXPLICIT_OPT_OUT_STATE,
  SUBSCRIPTION_EXPLICIT_SUBSCRIBE_STATE,
  allGroup,
  subscriptionMatches,
  allSubscription,
  findSubscription,
  getDiffSubscriptionsState,
} from 'Webapp/shared/utils/email-settings';

import NotificationModal from 'Webapp/shared/app/modals/notification-modal';

const langForRequest = (state) => langParam(state) || state.app.lang;

export const getEmailSettingsMapping = () =>
  async function (dispatch, getState, { flap }) {
    try {
      dispatch({ type: ACCOUNT_EMAIL_SETTINGS_TYPES.GET_EMAIL_CONFIG });

      const lang = langForRequest(getState());

      if (!lang) {
        dispatch({
          type: ACCOUNT_EMAIL_SETTINGS_TYPES.GET_EMAIL_CONFIG_FAILED,
        });
        return sentry.captureException(
          new Error('missing required email settings params'),
        );
      }

      const { data } = await flap.get(`/email/config`, {
        params: {
          lang,
        },
      });

      dispatch({
        type: ACCOUNT_EMAIL_SETTINGS_TYPES.GET_EMAIL_CONFIG_SUCCEEDED,
        payload: { groups: data },
      });
    } catch (error) {
      dispatch({ type: ACCOUNT_EMAIL_SETTINGS_TYPES.GET_EMAIL_CONFIG_FAILED });
      sentry.captureException(error);
    }
  };

const getEmailSettingsLoggedOut = () =>
  async function (dispatch, getState, { flap }) {
    try {
      dispatch({ type: ACCOUNT_EMAIL_SETTINGS_TYPES.GET_EMAIL_SETTINGS });
      const state = getState();
      const code = verificationCode(state);
      const s = verificationSecret(state);
      const lang = langForRequest(state);

      if (!code || !s) {
        dispatch({
          type: ACCOUNT_EMAIL_SETTINGS_TYPES.GET_EMAIL_SETTINGS_FAILED,
        });
        return sentry.captureException(
          new Error('missing required email settings params'),
        );
      }

      const { data } = await flap.get(`/email/states`, {
        params: {
          code,
          s,
          lang,
        },
      });
      const states = data?.states || [];
      const email = data?.email;

      if (email) {
        dispatch(
          set(
            ACCOUNT_EMAIL_SETTINGS_TYPES.SET_EMAIL_FROM_CODE,
            'emailFromCode',
            email,
          ),
        );
      }

      dispatch({
        type: ACCOUNT_EMAIL_SETTINGS_TYPES.GET_EMAIL_SETTINGS_SUCCEEDED,
        payload: { states },
      });
    } catch (error) {
      dispatch({
        type: ACCOUNT_EMAIL_SETTINGS_TYPES.GET_EMAIL_SETTINGS_FAILED,
      });
      sentry.captureException(error);
    }
  };

const getEmailSettingsLoggedIn = () =>
  async function (dispatch, _getState, { flap }) {
    try {
      dispatch({ type: ACCOUNT_EMAIL_SETTINGS_TYPES.GET_EMAIL_SETTINGS });

      const { data } = await flap.get(`/email/states`);
      const states = (data && data.states) || [];

      dispatch({
        type: ACCOUNT_EMAIL_SETTINGS_TYPES.GET_EMAIL_SETTINGS_SUCCEEDED,
        payload: { states },
      });
    } catch (error) {
      dispatch({
        type: ACCOUNT_EMAIL_SETTINGS_TYPES.GET_EMAIL_SETTINGS_FAILED,
      });
      sentry.captureException(error);
    }
  };

export const getEmailSettings = () =>
  function (dispatch, getState) {
    const state = getState();
    const authenticated = isAuthenticated(state);
    const code = verificationCode(state);
    const s = verificationSecret(state);

    // Even if we're authenticated, prefer the "code" and "s"
    // query params in case a logged-in user clicked
    // an unsubscribe link associated with another account
    if (authenticated && !(code && s)) {
      return dispatch(getEmailSettingsLoggedIn());
    }

    return dispatch(getEmailSettingsLoggedOut());
  };

const updateSubscriptions = (subscriptionChanges) =>
  async function (_dispatch, getState, { flap }) {
    const reduxState = getState();
    const code = verificationCode(reduxState);
    const s = verificationSecret(reduxState);
    const authenticated = isAuthenticated(reduxState);
    const uid = currentUserUid(reduxState);
    const body = subscriptionChanges.map((sc) => {
      const key = sc.subscription?.key || '*';
      const entry = {
        group: sc.group.group,
        key,
        state: sc.state,
      };
      if (uid !== 0) {
        entry.uid = uid.toString();
      }
      return entry;
    });

    const path =
      authenticated && !(code && s) && typeof uid !== 'undefined'
        ? `/email/states/${uid}`
        : '/email/states';

    const { data } = await flap.post(path, body, {
      params: {
        json: true,
        code,
        s,
      },
    });
    if (!data.success) {
      throw new Error('failed to update email settings');
    }
  };

const generateSubscriptionChangeOperation = (group, subscription = null) => ({
  group,
  subscription,
  state: SUBSCRIPTION_EXPLICIT_SUBSCRIBE_STATE,
});
const generateOptOutChangeOperation = (group, subscription = null) => ({
  group,
  subscription,
  state: SUBSCRIPTION_EXPLICIT_OPT_OUT_STATE,
});

export const subscribeToEmail = (group, subscription) =>
  updateSubscriptions([
    generateSubscriptionChangeOperation(group, subscription),
  ]);

export const optOutOfEmail = (group, subscription) =>
  updateSubscriptions([generateOptOutChangeOperation(group, subscription)]);

export const subscribeToAll = () => subscribeToEmail(allGroup, allSubscription);

export const optOutOfAll = () => optOutOfEmail(allGroup, allSubscription);

const setPreselectUnsubscribeComplete = () => (dispatch) =>
  dispatch(
    set(
      ACCOUNT_EMAIL_SETTINGS_TYPES.SET_PRESELECT_UNSUBSCRIBE_COMPLETE,
      'preselectUnsubscribeComplete',
      true,
    ),
  );

/**
 * Dispatch async action with a single payload to update the email settings
 * with targetGroup/targetSubscription to it opt-out
 * @param {Object} targetGroup - targeted group to opt-out or where the targetSubscription resides in the group
 * @param {Object} targetSubscription - targeted subscription to opt-out
 */
const optOutTargetedGroupAndSubcription = (
  targetGroup,
  targetSubscription = null,
) =>
  async function (dispatch, getState) {
    const state = getState();
    const groups = emailGroups(state);
    const nonTargetGroups = groups.filter(
      (g) => !subscriptionMatches(targetGroup, g),
    );

    const decoratedNonTargetGroups = nonTargetGroups.map((group) =>
      generateSubscriptionChangeOperation(group),
    );

    const nonTargetSubscriptions = targetGroup.subscriptions.filter(
      (s) =>
        !subscriptionMatches(targetGroup, targetGroup, targetSubscription, s),
    );
    const decoratedNonTargetSubscriptions = nonTargetSubscriptions.map(
      (subscription) =>
        generateSubscriptionChangeOperation(targetGroup, subscription),
    );
    const decoratedTargetSubscription = generateOptOutChangeOperation(
      targetGroup,
      targetSubscription,
    );

    const updatedSubscriptions = [
      ...decoratedNonTargetGroups,
      ...decoratedNonTargetSubscriptions,
      decoratedTargetSubscription,
    ];

    await dispatch(updateSubscriptions(updatedSubscriptions));
  };

export const unsubscribeFromPreselected =
  () =>
  async (dispatch, getState, { t }) => {
    const state = getState();
    const allSubscribed = subscribedToAll(state);
    const group = preselectedGroup(state);
    const subscription = preselectedSubscription(state);

    if (!group) {
      return null;
    }

    const subscriptionIsAll = subscriptionMatches(
      group,
      group,
      subscription,
      allSubscription,
    );

    const groupIsTarget =
      (group.isSubscribed || allSubscribed) && subscriptionIsAll;
    const subscriptionIsTarget =
      subscription &&
      !subscriptionIsAll &&
      (subscription.isSubscribed || group.isSubscribed || allSubscribed);
    const unsubscribeTarget = groupIsTarget ? group : subscription;

    const showSuccess = () =>
      dispatch(
        showModal(NotificationModal, {
          title: t('email_subscriptions_title'),
          message: t('unsubscribed_from_preselect', {
            name: unsubscribeTarget.name,
          }),
        }),
      );

    if (allSubscribed && groupIsTarget) {
      // if everything is subscribed and a group is the target,
      await dispatch(optOutTargetedGroupAndSubcription(group));
      showSuccess();
    } else if (allSubscribed && subscriptionIsTarget) {
      // If everything is subscribed and a subscription is the target:
      await dispatch(optOutTargetedGroupAndSubcription(group, subscription));
      showSuccess();
    } else if (subscriptionIsTarget && group.isSubscribed) {
      // If group is subscribed and a subscription is the target:
      await dispatch(optOutTargetedGroupAndSubcription(group, subscription));
      showSuccess();
    } else {
      if (groupIsTarget || subscriptionIsTarget) {
        await dispatch(
          optOutOfEmail(group, (subscriptionIsTarget && subscription) || null),
        );

        showSuccess();
      }
    }

    await dispatch(setPreselectUnsubscribeComplete());
    await dispatch(getEmailSettingsLoggedOut());
  };

export const savePendingSubscriptionGroups =
  (initialSubscriptionGroups, pendingSubscriptionGroups) =>
  async (dispatch, getState, { t }) => {
    try {
      dispatch({
        type: ACCOUNT_EMAIL_SETTINGS_TYPES.SAVE_EMAIL_SETTINGS_PENDING,
      });

      const currentSubscriptionGroups = emailSubscriptions(getState());

      // If we're subscribing to everything, just do that
      const currentAllGroup = findSubscription(
        currentSubscriptionGroups,
        allGroup,
      );
      const pendingAllGroup = findSubscription(
        pendingSubscriptionGroups,
        allGroup,
      );
      if (!currentAllGroup.isSubscribed && pendingAllGroup.isSubscribed) {
        dispatch(subscribeToEmail(pendingAllGroup));
        dispatch({
          type: ACCOUNT_EMAIL_SETTINGS_TYPES.SAVE_EMAIL_SETTINGS_SUCCEEDED,
        });
        return dispatch(toastShowInfoAction(t('email_subscriptions_updated')));
      }

      // Previously platform wanted to always submit each individual subscription's state
      // if the group is not subscribed.
      // However, they've requested to have only have the diff between the initial state
      // and the pending state
      const diffState = getDiffSubscriptionsState(
        initialSubscriptionGroups,
        pendingSubscriptionGroups,
      );

      const subscriptionChanges = [];

      diffState.forEach((collection) => {
        const { pendingGroup, pendingSubscriptions } = collection;
        if (pendingSubscriptions) {
          pendingSubscriptions.forEach((sub) => {
            subscriptionChanges.push(
              sub.isSubscribed
                ? generateSubscriptionChangeOperation(pendingGroup, sub)
                : generateOptOutChangeOperation(pendingGroup, sub),
            );
          });
        } else {
          subscriptionChanges.push(
            pendingGroup.isSubscribed
              ? generateSubscriptionChangeOperation(pendingGroup)
              : generateOptOutChangeOperation(pendingGroup),
          );
        }
      });

      await dispatch(updateSubscriptions(subscriptionChanges));

      dispatch({
        type: ACCOUNT_EMAIL_SETTINGS_TYPES.SAVE_EMAIL_SETTINGS_SUCCEEDED,
      });
      dispatch(toastShowInfoAction(t('email_subscriptions_updated')));
    } catch (error) {
      dispatch({
        type: ACCOUNT_EMAIL_SETTINGS_TYPES.SAVE_EMAIL_SETTINGS_FAILED,
      });
      dispatch(toastShowErrorAction(t('email_subscriptions_update_failed')));
      sentry.captureException(error);
    }
  };

export const subscribeToPendingSubscriptionGroups =
  (pendingSubscriptionGroups) => async (dispatch) => {
    try {
      dispatch({
        type: ACCOUNT_EMAIL_SETTINGS_TYPES.SAVE_EMAIL_SETTINGS_PENDING,
      });

      // If we're subscribing to everything, just do that
      const pendingAllGroup = findSubscription(
        pendingSubscriptionGroups,
        allGroup,
      );
      if (pendingAllGroup && pendingAllGroup.isSubscribed) {
        return dispatch(subscribeToEmail(pendingAllGroup));
      }

      const subscriptionChanges = [];
      pendingSubscriptionGroups.forEach((pendingGroup) => {
        let processSubscriptions = true;

        if (pendingGroup.isSubscribed) {
          subscriptionChanges.push(
            generateSubscriptionChangeOperation(pendingGroup),
          );
          if (pendingGroup.isSubscribed) {
            processSubscriptions = false;
          }
        }

        if (processSubscriptions) {
          pendingGroup.subscriptions.forEach((pendingSubscription) => {
            if (pendingSubscription.isSubscribed) {
              subscriptionChanges.push(
                generateSubscriptionChangeOperation(
                  pendingGroup,
                  pendingSubscription,
                ),
              );
            }
          });
        }
      });

      await dispatch(updateSubscriptions(subscriptionChanges));

      dispatch({
        type: ACCOUNT_EMAIL_SETTINGS_TYPES.SAVE_EMAIL_SETTINGS_SUCCEEDED,
      });
    } catch (error) {
      dispatch({
        type: ACCOUNT_EMAIL_SETTINGS_TYPES.SAVE_EMAIL_SETTINGS_FAILED,
      });
      sentry.captureException(error);
    }
  };

export const preSelectSubscription = (groupType, subscriptionKey) => ({
  type: ACCOUNT_EMAIL_SETTINGS_TYPES.SET_PRESELECT_SUBSCRIPTION,
  payload: { groupType, subscriptionKey },
});
