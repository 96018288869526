import { FLIP_MODE_TYPES } from 'Webapp/action-types';
import { setPayloadReducer } from 'Utils/redux';

interface FlipModeReducerState {
  enabled: boolean;
  targetRemoteId: null | Flipboard.SectionId;
}
const initialState: FlipModeReducerState = {
  enabled: false,
  targetRemoteId: null,
};

export default setPayloadReducer(FLIP_MODE_TYPES, initialState);
