import { styleObject } from 'Style/type-helpers';

export const truncate = (width: string, lineClamp: number) =>
  styleObject({
    width,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: lineClamp,
    overflow: 'hidden',
    overflowWrap: 'break-word',
  });
