import React from 'react';

import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';

const BulletChar = styled.span({
  margin: `0 ${SPACING.SMALL}`,
  fontSize: '10px',
});

function Bullet(props) {
  return <BulletChar {...props}>&bull;</BulletChar>;
}

Bullet.propTypes = {};

export default Bullet;
