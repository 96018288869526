import sentry from 'Utils/sentry';
import { BadPromiseAllArgument } from 'Utils/errors';
// we need to use any here until we have a better solution :-/

export const PromiseAll = <T>(promises: Array<T>): Promise<T[]> => {
  try {
    promises.forEach((p) => {
      if (p instanceof Promise === false) {
        throw new BadPromiseAllArgument();
      }
    });
  } catch (e) {
    sentry.captureException(e as Error);
  }
  return Promise.all(promises);
};
