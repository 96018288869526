import React from 'react';
import classNames from 'classnames';

// Components
import SearchIcon from 'ComponentLibrary/icons/search.js';
import ClearIcon from 'ComponentLibrary/icons/clear.js';

interface InputProps extends Partial<React.ComponentProps<'input'>> {
  icon?: InputIcons;
  inputClass?: string;
  onClear?: React.MouseEventHandler<HTMLDivElement>;
  hasValue?: boolean;
}

export enum InputIcons {
  SEARCH = 'SEARCH',
}

function renderInputIcon(icon) {
  switch (icon) {
    case InputIcons.SEARCH:
      return <SearchIcon className="input-field__icon" size={18} />;
    default:
      return null;
  }
}

const OMIT_PROPS = ['onClear', 'hasValue', 'inputClass', 'className'];

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const allowedProps = Object.entries(props).reduce(
    (filteredProps, [key, value]) => {
      if (!OMIT_PROPS.includes(key)) {
        filteredProps[key] = value;
      }
      return filteredProps;
    },
    {},
  );

  const classes = classNames(props.className, {
    'input-field': true,
    'input-field--with-icon': props.icon,
  });

  return (
    <div className={classes}>
      {renderInputIcon(props.icon)}
      <input ref={ref} className={props.inputClass} {...allowedProps} />
      {props.onClear && props.hasValue && (
        <div className="input-field__clear" onClick={props.onClear}>
          <ClearIcon />
        </div>
      )}
    </div>
  );
});

Input.defaultProps = {
  type: 'text',
  hasValue: false,
};

export default Input;
