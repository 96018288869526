import codeSplitLoad from 'Webapp/shared/utils/code-split-load';
import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

import { getAdjustNavFromRoute } from 'Webapp/shared/app/redux/selectors/nav-from';
import { loadContextualOnboardingSections } from 'Webapp/shared/app/redux/actions/section-actions';

import { showModal } from 'Webapp/shared/app/redux/actions/modal-actions';
const OnboardingTopicPicker = codeSplitLoad('OnboardingTopicPicker');

const onboardingTopicPicker: OnboardingStep =
  (nextStep) => async (dispatch, getState) => {
    const navFrom = getAdjustNavFromRoute(getState());
    await dispatch(loadContextualOnboardingSections());
    dispatch(
      showModal(OnboardingTopicPicker, {
        navFrom,
        onComplete: nextStep,
      }),
    );
  };

export default onboardingTopicPicker;
