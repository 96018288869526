import AddedPost from 'Webapp/shared/app/components/notifications/content/added-post';
import { FlapNotificationType } from 'Webapp/enums';
import { NotificationTextClickStrategy } from 'Webapp/shared/app/components/notifications/notification-text-click-strategies/index';

const strategy: NotificationTextClickStrategy = (notification) => {
  if (FlapNotificationType.ADDED_POST !== notification.notificationType) {
    return null;
  }
  return AddedPost;
};

export default strategy;
