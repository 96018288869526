import React from 'react';
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';
import styled from '@emotion/styled';
import { BREAKPOINTS } from 'Style/breakpoints';
import { SPACING } from 'Style/spacing';
import { DIVIDER_COLORS, SURFACE_COLORS, TEXT_COLORS } from 'Style/colors';
import { styleOnlyProps } from 'Style/style-helpers';
import { styleObject } from 'Style/type-helpers';

import AuthorAvatar from './attribution/author-avatar';
import PlusBulletIcon from 'ComponentLibrary/icons/plus-bullet';
import WebLink from './web-link';
import UnstyledButton from 'Webapp/shared/app/components/unstyled-button';
import MagazineContributorsModal from 'Webapp/shared/app/modals/magazine-contributors';

import connector from 'Webapp/utils/connector';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';

type StyledProfileStackProps = {
  isNewMagazineCover: boolean;
  isNewMagazine: boolean;
};

type IsNewMagazineProp = {
  isNewMagazine?: boolean;
};

export const ProfileStackList = styled(
  'ul',
  styleOnlyProps('isNewMagazine', 'isNewMagazineCover'),
)<StyledProfileStackProps>(({ isNewMagazineCover, isNewMagazine }) => {
  if (isNewMagazineCover) {
    return {
      marginLeft: '32px',
      marginRight: '32px',
      display: 'flex',
      flexDirection: 'row-reverse',
      minWidth: '0px',
    };
  }
  return {
    minWidth: '70px',
    display: 'inline-block',
    marginRight: isNewMagazine ? 0 : SPACING.MEDIUM,
    marginLeft: isNewMagazine ? '32px' : '20px',
  };
});

export const ProfileStackItem = styled(
  'li',
  styleOnlyProps('isNewMagazine'),
)<IsNewMagazineProp>(({ isNewMagazine }) => {
  const defaultStyled = {
    display: 'inline-flex',
    verticalAlign: 'middle',
  };
  const marginBottomValue = isNewMagazine ? '0px' : SPACING.BASE;
  const marginLeftValue = isNewMagazine ? '-32px' : '-20px';
  const styledObjectBreakingPoint = styleObject(
    BREAKPOINTS.tabletLandscapeUp({
      margin: `${SPACING.LARGE} 0 `,
      marginLeft: marginLeftValue,
      marginBottom: marginBottomValue,
    }),
  );
  return {
    ...defaultStyled,
    ...styledObjectBreakingPoint,
  };
});

type StyledUnDisplayedCountsProps = {
  avatarSize: number;
};

const UnDisplayedCounts = styled(
  UnstyledButton,
  styleOnlyProps('avatarSize'),
)<StyledUnDisplayedCountsProps>(({ avatarSize }) => ({
  borderRadius: '100%',
  height: `${avatarSize}px`,
  width: `${avatarSize}px`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '11px',
  color: TEXT_COLORS.overlay,
  backgroundColor: SURFACE_COLORS.primaryAlwaysDark,
  border: `2px solid ${DIVIDER_COLORS.primary}`,
}));

const StyledProfileAvatar = styled(
  AuthorAvatar,
  styleOnlyProps('isNewMagazine'),
)<IsNewMagazineProp>(
  {
    '.author-avatar__image': {
      border: `2px solid ${DIVIDER_COLORS.primary}`,
      'amp-img': {
        borderRadius: '50%',
      },
    },
  },
  (props) => !props.isNewMagazine && { marginRight: SPACING.BASE },
);

type ProfileStackPassedProps = {
  section: Flipboard.Section;
  profiles: Array<Flipboard.Author>;
  allProfiles: Array<Flipboard.Author>;
  avatarSize?: number;
  addContributorButtonName?: string;
  onClickAdd?: () => void | null;
  isNewMagazine: boolean;
  undisplayedCount: number;
  isNewMagazineCover: boolean;
};

type ProfileStackProps = ConnectModalProps & ProfileStackPassedProps;

const ProfileStack: React.FC<ProfileStackProps> = ({
  section,
  profiles,
  allProfiles,
  avatarSize = 32,
  addContributorButtonName = '',
  onClickAdd = null,
  isNewMagazine,
  undisplayedCount,
  isNewMagazineCover,
  showModal,
}) => {
  const showMagazineContributors = () => {
    section &&
      allProfiles &&
      showModal(MagazineContributorsModal, { magazine: section, allProfiles });
  };
  // Need to reverse since using flex's reverse-row will allow
  // the avatar to be stacked on correctly
  const userProfiles = isNewMagazineCover ? [...profiles].reverse() : profiles;
  return (
    <ProfileStackList
      isNewMagazine={isNewMagazine}
      isNewMagazineCover={isNewMagazineCover}
    >
      {undisplayedCount > 0 && isNewMagazineCover && (
        <ProfileStackItem>
          <UnDisplayedCounts
            name="show-magazine-contributors"
            onClick={showMagazineContributors}
            avatarSize={avatarSize}
          >
            {undisplayedCount}
          </UnDisplayedCounts>
        </ProfileStackItem>
      )}
      {userProfiles.map((p) => (
        <ProfileStackItem isNewMagazine={isNewMagazine} key={p.authorUsername}>
          <WebLink
            href={`/@${p.authorUsername}`}
            navFromEventName={USAGE_EVENT_NAMES.SECTION_ENTER}
          >
            <StyledProfileAvatar
              isNewMagazine={isNewMagazine}
              author={p}
              avatarSize={avatarSize}
            />
          </WebLink>
        </ProfileStackItem>
      ))}
      {onClickAdd && (
        <ProfileStackItem isNewMagazine={isNewMagazine}>
          <UnstyledButton name={addContributorButtonName} onClick={onClickAdd}>
            <PlusBulletIcon
              size={avatarSize}
              backgroundColor="#F7F7F7"
              color="#F52828"
            />
          </UnstyledButton>
        </ProfileStackItem>
      )}
    </ProfileStackList>
  );
};

export default connector<ProfileStackProps>(connectModal)(ProfileStack);
