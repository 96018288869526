import getCurrentSection from 'Utils/seo/shared/get-current-section';

export default ({ section }, getState) => {
  if (section) {
    const currentSection = getCurrentSection(getState());
    if (currentSection && currentSection.title) {
      return currentSection.title;
    }
  }
};
