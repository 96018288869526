import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';
import { performPostActivationActions } from 'Webapp/shared/app/redux/actions/profile-actions';
import { onboardingSuccessSelector } from 'Webapp/shared/concepts/onboarding';

const performPostActivationActionsStep: OnboardingStep =
  (nextStep) => async (dispatch, getState) => {
    const success = onboardingSuccessSelector(getState());
    if (success) {
      await dispatch(performPostActivationActions());
    }
    nextStep();
  };
export default performPostActivationActionsStep;
