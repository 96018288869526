import React from 'react';
import PropTypes from 'prop-types';
import Icon from './icon';

function TwitterIcon(props) {
  const derivedColor = props.useBrandColor ? '#1da1f2' : '#fff';
  return (
    <Icon
      name="twitter"
      size={30}
      viewBox="0 0 30 30"
      {...props}
      renderComponent={() => (
        <g data-name="twitter" transform="translate(3.0, -85.0)">
          <path
            d="M20.1557271,96.643397 C20.167153,96.8032883 20.167153,96.9632147 20.167153,97.1231059 C20.167153,102 16.4552361,107.619327 9.67093229,107.619327 C7.58081475,107.619327 5.63919779,107.013969 4.0059375,105.96325 C4.3029035,105.997492 4.58840853,106.008918 4.89680035,106.008918 C6.62139694,106.008918 8.20898907,105.426447 9.47676356,104.432787 C7.8549291,104.398509 6.49578316,103.336329 6.02750008,101.874386 C6.25594629,101.908629 6.48435734,101.93148 6.72422937,101.93148 C7.05543769,101.93148 7.38668117,101.885777 7.69503785,101.805866 C6.00468359,101.463197 4.73687395,99.9784374 4.73687395,98.1852859 L4.73687395,98.1396177 C5.22797352,98.4137321 5.79908904,98.5850492 6.40437656,98.6078657 C5.41071642,97.9454138 4.7597256,96.8147141 4.7597256,95.5355138 C4.7597256,94.8502455 4.94243335,94.2220712 5.262251,93.6738425 C7.07825419,95.9124254 9.80797188,97.3743335 12.8688627,97.5342599 C12.8117688,97.2601455 12.7774913,96.9746405 12.7774913,96.6891003 C12.7774913,94.6560768 14.4221774,93 16.4665916,93 C17.5287716,93 18.4881542,93.4454314 19.1620319,94.1649772 C19.9957656,94.005086 20.795257,93.6966941 21.5034121,93.2741144 C21.2292626,94.1307349 20.6467916,94.8502807 19.8815776,95.3071028 C20.6239751,95.2271923 21.3435208,95.0215626 22.0059375,94.7360576 C21.5034824,95.466994 20.8752729,96.1179848 20.1557271,96.643397 Z"
            id="Path"
            fill={derivedColor}
            fillRule="nonzero"
          />
        </g>
      )}
    />
  );
}

export default TwitterIcon;

TwitterIcon.propTypes = {
  useBrandColor: PropTypes.bool,
};

TwitterIcon.defaultProps = {
  useBrandColor: false,
};
