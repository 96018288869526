import GlobalVars from 'Utils/global-vars';
import getWindow from 'Utils/get-window';
import Locales from 'Utils/locales';
import FlapUtil from 'Utils/content/flap-util';
import DownloadAppBanner from '../../shared/app/containers/banners/download-app';

const CUSTOM_BANNER_IDS = {
  DOWNLOAD_APP: 'download-app',
};

const ALLOWED_PATHS = [
  '/',
  '/profile',
  '/:shortcut/:shortcutId',
  '/@:username/:slug',
];

// This array includes banners "hardcoded" into Webapp: banners that have their own custom banner components
const customBanners = [
  {
    id: CUSTOM_BANNER_IDS.DOWNLOAD_APP,
    activeIn: ['cover-stories'],
    isActiveOtherSections: (sectionObject) => sectionObject.isTopic,
    component: DownloadAppBanner,
    requiresAuthentication: true,
    shouldDisplay(userInfo) {
      const user = FlapUtil.getUserFromUserInfo(userInfo);
      if (!user) {
        return false;
      }
      const createTimestamp = user.createdTime ? user.createdTime * 1000 : 0;
      const newUserCutoffTimestamp = Date.now() - 1000 * 60 * 60 * 24 * 7; // 7 days
      return createTimestamp > newUserCutoffTimestamp;
    },
  },
];

/**
 * Returns viewed banners
 * @return {Array<String>} - Array of banner ids
 */
function getViewedBanners() {
  let banners;
  try {
    banners = JSON.parse(getWindow().localStorage.getItem('banners'));
  } catch (err) {
    banners = [];
  }
  return banners || [];
}

/**
 * Sets the dismissed banner into local storage
 * @param {String} bannerId
 */
function setDismissedBanner(bannerId) {
  const viewedBanners = getViewedBanners();
  viewedBanners.push(bannerId);
  getWindow().localStorage.setItem('banners', JSON.stringify(viewedBanners));
}

/**
 * Re-enables a previously dismissed banner
 * @param {String} bannerId - id of banner to re-enable
 */
function reEnableBanner(bannerId) {
  const viewedBanners = getViewedBanners().filter((b) => b !== bannerId);
  getWindow().localStorage.setItem('banners', JSON.stringify(viewedBanners));
}

/**
 * Checkes whether the banner has been dismissed
 * @param {String} bannerId
 * @return {Boolean} - whether the banner has been dismissed or not
 */
function isBannerDismissed(bannerId) {
  const viewedBanners = getViewedBanners();
  return viewedBanners.includes(bannerId);
}

/**
 * Checks whether the banner is active within the timeframe specified.
 * If no timeframe specified, banner should be active
 * @param {String} startDate - string date understood by the Date class
 * @param {String} endDate - string date understood by the Date class
 */
function isBannerActiveInTimespan(startDate, endDate) {
  if (!endDate) {
    return true;
  }
  const now = Date.now();
  const start = new Date(startDate).getTime();
  const end = new Date(endDate).getTime();
  return start <= now && now <= end;
}

/**
 * Checks whether the banner is active in the given section
 * @param {Object} banner
 * @param {Object} section - Flap section
 */
function isBannerActiveInSection(banner, section) {
  if (!section) {
    return false;
  }

  return (
    banner.activeIn.includes(section.title) ||
    banner.activeIn.includes(section.remoteid) ||
    (banner.isActiveOtherSections && banner.isActiveOtherSections(section)) ||
    (section.ssid &&
      section.ssid.remoteidPlain &&
      banner.activeIn.includes(section.ssid.remoteidPlain))
  );
}

function bannerMatchesLocales(locales) {
  if (!locales) {
    return true;
  }

  const clientLocale = Locales.getClientLocale();
  return locales.some((l) => clientLocale === l);
}

/**
 * Banners with `requiresAuthentication: true` should only be displayed for logged in users
 * @param {Object} banner - Dynconfig banner
 * @param {Object} userInfo - Flap user info
 */
function bannerMatchesAuth(banner, userInfo) {
  if (!banner.requiresAuthentication) {
    return true;
  }
  return userInfo ? !!userInfo.userid : false;
}

function getVisibleBanner(banners, section, userInfo, routing) {
  const isProduction = GlobalVars.deployEnvironment === 'production';
  const visibleBanner = banners.find(
    (banner) =>
      ALLOWED_PATHS.includes(routing.path) &&
      isBannerActiveInSection(banner, section) &&
      isBannerActiveInTimespan(banner.startDate, banner.endDate) &&
      !isBannerDismissed(banner.id) &&
      bannerMatchesLocales(banner.locales) &&
      bannerMatchesAuth(banner, userInfo) &&
      (banner.shouldDisplay ? banner.shouldDisplay(userInfo, section) : true) &&
      (isProduction ? !banner.beta : true),
  );

  return visibleBanner;
}

export {
  CUSTOM_BANNER_IDS,
  customBanners,
  getVisibleBanner,
  getViewedBanners,
  setDismissedBanner,
  isBannerDismissed,
  reEnableBanner,
};
