import React from 'react';
import styled from '@emotion/styled';
import { getReadableNumber } from 'Utils/numbers-formatter';

export const StyledItemMetric = styled.span({
  width: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
});

type ItemMetricProps = {
  metricValue?: number | null;
};
const ItemMetric: React.FC<ItemMetricProps> = ({ metricValue }) => {
  const displayValue = metricValue ? getReadableNumber(metricValue) : '';
  return <StyledItemMetric>{displayValue}</StyledItemMetric>;
};

export default ItemMetric;
