import SectionLink from 'Webapp/shared/app/components/section-link';
import NotificationThumb from 'Webapp/shared/app/components/notifications/notification-thumb';
import NotificationTime from 'Webapp/shared/app/components/notifications/notification-time';

/**
 * Generic Notification click strategy for anything where the first sectionLinks is a linkable
 * object.
 */
const LinkSection: React.FC<{ notification: Flipboard.Notification }> = ({
  notification,
  children,
}) => {
  const sectionLink = notification.sectionLinks?.[0];
  return (
    <>
      <SectionLink section={sectionLink} openInNewWindow={false}>
        {children}
        <NotificationTime notification={notification} />
      </SectionLink>
      <NotificationThumb notification={notification} />
    </>
  );
};

export default LinkSection;
