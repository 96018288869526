import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ICON_COLORS } from 'Style/colors';
import Icon from './icon';

export const StyledCreateFlipIcon = styled(Icon)({
  color: ICON_COLORS.emphasis,
});
function CreateFlipIcon({ ...props }) {
  return (
    <StyledCreateFlipIcon
      name="createflip"
      viewBox="0 0 24 24"
      {...props}
      renderComponent={() => (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.9999 2.99318C11.0088 3.54539 10.5683 4.00026 10.0161 4.00915C8.47644 4.03395 7.69367 4.12912 7.08658 4.38058C5.86144 4.88805 4.88807 5.86142 4.3806 7.08656C4.21112 7.49572 4.10839 7.99929 4.05477 8.78512C4.00054 9.57992 4 10.5885 4 12C4 13.4115 4.00054 14.42 4.05477 15.2148C4.10839 16.0007 4.21112 16.5042 4.3806 16.9134C4.88807 18.1385 5.86144 19.1119 7.08658 19.6194C7.49574 19.7889 7.99931 19.8916 8.78514 19.9452C9.57994 19.9994 10.5885 20 12 20C13.4115 20 14.4201 19.9994 15.2149 19.9452C16.0007 19.8916 16.5043 19.7889 16.9134 19.6194C18.1386 19.1119 19.1119 18.1385 19.6194 16.9134C19.8709 16.3063 19.966 15.5235 19.9908 13.9839C19.9997 13.4317 20.4546 12.9912 21.0068 13.0001C21.559 13.009 21.9995 13.4639 21.9906 14.0161C21.9659 15.5505 21.8797 16.6828 21.4672 17.6788C20.7567 19.394 19.394 20.7567 17.6788 21.4671C16.9853 21.7544 16.2387 21.88 15.351 21.9406C14.4801 22 13.4035 22 12.0369 22H11.9631C10.5965 22 9.51986 22 8.64899 21.9406C7.76134 21.88 7.01469 21.7544 6.32122 21.4671C4.60602 20.7567 3.2433 19.394 2.53284 17.6788C2.2456 16.9853 2.11997 16.2386 2.05941 15.351C1.99999 14.4801 2 13.4035 2 12.0368V11.9631C2 10.5965 1.99999 9.51984 2.05941 8.64897C2.11997 7.76132 2.2456 7.01467 2.53284 6.3212C3.2433 4.606 4.60602 3.24328 6.32122 2.53282C7.31719 2.12028 8.44945 2.03412 9.9839 2.00941C10.5361 2.00052 10.991 2.44096 10.9999 2.99318Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.8501 2C20.5527 2 20.2675 2.11814 20.0572 2.32843L10.7531 11.6326L10.2245 13.747L12.3389 13.2184L21.643 3.91421C21.8533 3.70392 21.9714 3.41871 21.9714 3.12132C21.9714 2.82393 21.8533 2.53872 21.643 2.32843C21.4327 2.11814 21.1475 2 20.8501 2ZM18.643 0.914214C19.2284 0.328852 20.0223 0 20.8501 0C21.6779 0 22.4719 0.328852 23.0572 0.914214C23.6426 1.49957 23.9714 2.29349 23.9714 3.12132C23.9714 3.94915 23.6426 4.74307 23.0572 5.32843L13.5572 14.8284C13.4291 14.9566 13.2685 15.0475 13.0927 15.0915L9.09265 16.0915C8.75188 16.1767 8.39139 16.0768 8.14301 15.8284C7.89463 15.58 7.79478 15.2196 7.87998 14.8788L8.87998 10.8788C8.92393 10.703 9.01485 10.5424 9.14301 10.4142L18.643 0.914214Z"
            fill="currentColor"
          />
        </>
      )}
    />
  );
}

CreateFlipIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

CreateFlipIcon.defaultProps = {
  className: '',
  size: 24,
};

export default CreateFlipIcon;
