import connector, { Connector } from 'Webapp/utils/connector';
import { usageTrackOnboardExit } from 'Webapp/shared/app/redux/actions/usage-actions';
import TopicCustomizations from 'Webapp/shared/app/components/topic-customization';
import { fetchTopicCustomizations } from 'Webapp/shared/app/redux/actions/related-section-actions';

const actions = {
  usageTrackOnboardExit,
  fetchTopicCustomizations,
};

export type TopicCustomizationsContainerProps = Flipboard.ConnectorProps<
  unknown,
  typeof actions
>;
const topicCustomizationsConnector: Connector = {
  actions,
};

const TopicCustomizationsContainer = connector(topicCustomizationsConnector)(
  TopicCustomizations,
);

export default TopicCustomizationsContainer;
