import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { loadableReady } from '@loadable/component';

import { sentryInit } from 'Utils/sentry/client';
import GlobalVars from 'Utils/global-vars';
import getWindow from 'Utils/get-window';

import { I18NContext } from 'ComponentLibrary/context';

import store from '../shared/app/redux/store';
import RootContainer from '../shared/app/route-containers/root';
import performanceReporting from './performance-reporting';
import { createClientT } from 'i18n';
import { registerServiceWorker } from './lib/service-worker';
import logger from 'Utils/logger';
import '../style/index.scss';

performanceReporting();
const t = createClientT();

// Set runtime configuration
const { SENTRY_CLIENT_DSN } = window.CLIENT_CONFIG;
sentryInit(SENTRY_CLIENT_DSN);

const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;

const render = (Component) =>
  loadableReady(() =>
    renderMethod(
      <Provider store={store}>
        <Router>
          <I18NContext.Provider value={t}>
            <Component />
          </I18NContext.Provider>
        </Router>
      </Provider>,
      document.getElementById('content'),
    ),
  );

// Helps debug hydration issues, always render in production,
// otherwise render if no disable-hydrate param
let shouldRender = true;
try {
  if (
    !GlobalVars.isProduction &&
    getWindow().location.search.match(/disable-hydrate/)
  ) {
    shouldRender = false;
  }
} catch (_e) {
  shouldRender = true;
} finally {
  if (shouldRender) {
    render(RootContainer);
  }
}

// Enable HMR (hot model reloading)
// https://webpack.js.org/guides/hot-module-replacement/
if (module.hot) {
  module.hot.accept('../shared/app/root', () => {
    render(RootContainer);
  });
}

// register the service worker
registerServiceWorker().catch((error) => {
  logger.error('error: ', error);
});
