import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
// Components
import AuthorAvatar from './attribution/author-avatar';
import FlipboardLogo from 'ComponentLibrary/logos/flipboard';

const Wrapper = styled.div({
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'var(--color--surface-secondary)',
  width: '100%',
  height: '100%',
});

const Background = styled.div({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Avatar = styled.div({
  display: 'flex',
  width: '100%',
});

const StyledAuthorAvatar = styled(AuthorAvatar)({
  filter: 'grayscale(100) opacity(60%)',
});

const StyledAuthorAvatarBg = styled(AuthorAvatar)({
  display: 'flex',
  width: '100%',

  '.author-avatar__image': {
    filter: 'grayscale(100) opacity(9%) blur(4px)',
    borderRadius: 'unset',
    width: '100%',
  },
});

function ImageUnavailable({ author, hideFallback, fallbackElementSize }) {
  const authorImage = (author && author.authorImage) || null;
  let bgAvatarSize = 570;
  let avatarSize = 180;
  let logoSize = 120;
  switch (fallbackElementSize) {
    case ImageUnavailable.fallbackElementSizes.CONDENSED:
      {
        bgAvatarSize = 200;
        avatarSize = 60;
        logoSize = 40;
      }
      break;
    case ImageUnavailable.fallbackElementSizes.ARTICLE: {
      bgAvatarSize = 870;
    }
  }
  return (
    // Note: AMP needs the fallback attribute
    <Wrapper
      fallback="fallback"
      className="media-container media-container-fallback"
    >
      {authorImage ? (
        <>
          <Background>
            <StyledAuthorAvatarBg author={author} avatarSize={bgAvatarSize} />
          </Background>
          <Avatar>
            <StyledAuthorAvatar author={author} avatarSize={avatarSize} />
          </Avatar>
        </>
      ) : (
        // Fallback for no author avatar
        <div>
          {!hideFallback && <FlipboardLogo size={logoSize} variant="gray" />}
        </div>
      )}
    </Wrapper>
  );
}

ImageUnavailable.fallbackElementSizes = {
  CONDENSED: 'condensed',
  CARD: 'card',
  ARTICLE: 'article',
};

ImageUnavailable.propTypes = {
  hideFallback: PropTypes.bool,
  author: PropTypes.object,
  fallbackElementSize: PropTypes.oneOf(
    Object.values(ImageUnavailable.fallbackElementSizes),
  ),
};

ImageUnavailable.defaultProps = {
  author: null,
  hideFallback: false,
  fallbackElementSize: ImageUnavailable.fallbackElementSizes.CARD,
};

export default ImageUnavailable;
