import React from 'react';
import styled from '@emotion/styled';
import { SURFACE_COLORS, DIVIDER_COLORS } from 'Style/colors';
import { SPACING } from 'Style/spacing';
import { LAYERS } from 'Style/layout';
import { componentSelector } from 'Style/type-helpers';
import { styleOnlyProps } from 'Style/style-helpers';

import { USAGE_DISPLAY_STYLES } from 'Utils/analytics/usage';
import TopicUtil from 'Utils/content/topic';
import SectionUtil from 'Utils/content/section-util';
import getWindow from 'Utils/get-window';
import { getContextualSections } from 'Webapp/shared/utils/contextual-onboarding.js';
import FlapUtil from 'Utils/content/flap-util';

import SignupButton from 'Webapp/shared/app/containers/signup-button';
import OnboardingSectionPicker, {
  SectionPickerHeader,
  SectionPickerContent,
} from 'Webapp/shared/app/components/onboarding-section-picker';

import Button, { StyleVariations } from 'Webapp/shared/app/components/button';
import PopupDialog from 'Webapp/shared/app/components/popup-dialog';
import connector from 'Webapp/utils/connector';
import connectContextualOnboarding, {
  ConnectContextualOnboardingProps,
} from 'Webapp/shared/app/connectors/connectContextualOnboarding';
import connectResponsive, {
  ConnectResponsiveProps,
} from 'Webapp/shared/app/connectors/connectResponsive';
import connectAuthentication, {
  ConnectAuthenticationProps,
} from 'Webapp/shared/app/connectors/connectAuthentication';

import withT from 'ComponentLibrary/hocs/withT';
import withNavFrom from 'Webapp/shared/app/hocs/withNavFrom';
import withSignupCta from 'Webapp/shared/app/hocs/withSignupCta';

import { OnboardingFlow } from 'Webapp/enums';

const SHOW_CTA_DELAY_MS = 2000;
const MAX_CONTEXTUAL_SECTIONS = 6;

type TopicPickerCTAPopupProps = { showAll: boolean };
const TopicPickerCTAPopup = styled(
  PopupDialog,
  styleOnlyProps('showAll'),
)<TopicPickerCTAPopupProps>(
  ({ showAll }) => {
    if (showAll) {
      return { height: '600px' };
    }
  },
  {
    zIndex: LAYERS.MODAL,
    display: 'grid',
    gap: SPACING.LARGE,
    padding: SPACING.BASE,
    width: '720px',
    background: SURFACE_COLORS.primary,
    boxShadow: 'var(--shadow--elevation-2)',
    border: `1px solid ${DIVIDER_COLORS.secondary}`,
    borderRadius: SPACING.BASE,
    cursor: 'auto',
    '.anchored-dialog__close': {
      position: 'absolute',
      top: SPACING.BASE,
      right: SPACING.BASE,
    },
    [componentSelector(SectionPickerHeader)]: {
      padding: SPACING.BASE,
      borderBottom: `1px solid ${DIVIDER_COLORS.secondary}`,
    },
    [componentSelector(SectionPickerContent)]: {
      margin: '0px',
      padding: '0px',
    },
  },
);
const PickerControls = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gap: SPACING.LARGE,
  justifyItems: 'end',
  padding: SPACING.LARGE,
});

type TopicPickerCTAProps = ConnectContextualOnboardingProps &
  ConnectResponsiveProps &
  ConnectAuthenticationProps & {
    className?: string;
    // from withNavFrom
    navFrom: string;
    // from withSignupCta
    shouldShowSignupCta: boolean;
    readyForMetrics: boolean;
    usageTrackTopicPickerCta: () => void;
    usageTrackTopicPickerCtaSeeMore: () => void;
    usageTrackTopicPickerCtaExit: () => void;
    // from withT
    t: Flipboard.TFunction;
  };

type TopicPickerArrayType = Array<
  Pick<Flipboard.BasicSection, 'title' | 'remoteid'>
>;
// type TopicPickerArrayType = Array<Flipboard.BasicSection>;
interface TopicPickerCTAState {
  showCta: boolean;
  showAll: boolean;
  editorialBoards: TopicPickerArrayType;
  selectedSections: TopicPickerArrayType;
  contextualSections: TopicPickerArrayType;
}
class TopicPickerCTA extends React.Component<
  TopicPickerCTAProps,
  TopicPickerCTAState
> {
  state: TopicPickerCTAState = {
    showCta: false,
    showAll: false,
    editorialBoards: [],
    selectedSections: [],
    contextualSections: [],
  };

  componentDidMount() {
    const {
      getEditorialBoards,
      editorialBoards,
      primarySection,
      currentArticle,
    } = this.props;
    if (!editorialBoards) {
      getEditorialBoards();
    }

    this.updateContextualSections(primarySection || currentArticle);

    if (this.props.readyForMetrics && this.props.shouldShowSignupCta) {
      this.startShowTimeout();
    }
  }
  componentDidUpdate(prevProps: TopicPickerCTAProps) {
    const {
      readyForMetrics,
      shouldShowSignupCta,
      primarySection,
      currentArticle,
    } = this.props;
    if (prevProps.primarySection !== primarySection) {
      this.updateContextualSections(primarySection);
    }
    if (prevProps.currentArticle !== currentArticle) {
      this.updateContextualSections(currentArticle);
    }

    // Transitioning to "ready for metrics"
    if (!prevProps.readyForMetrics && readyForMetrics && shouldShowSignupCta) {
      this.startShowTimeout();
    }

    // hide CTA if the app state dictates
    if (!shouldShowSignupCta && this.state.showCta) {
      this.setState({ showCta: false });
    }
  }

  timeout: number | null = null;
  pickerAnchorTarget = React.createRef<HTMLDivElement>();

  getUniqueSectionsToAdd = (
    sections: TopicPickerArrayType,
    addSections: TopicPickerArrayType,
  ) =>
    addSections.filter(
      (s) => !SectionUtil.sectionsIncludesSection(sections, s),
    );

  updateContextualSections = (
    sectionOrItem: Flipboard.Item | Flipboard.Section,
  ) => {
    const { contextualSections, selectedSections } = this.state;
    const newContextualSections = getContextualSections(
      sectionOrItem,
    ) as TopicPickerArrayType;
    const newSectionsToAdd = this.getUniqueSectionsToAdd(
      contextualSections,
      newContextualSections,
    );

    const updatedContextualSections = [
      ...newSectionsToAdd,
      ...contextualSections,
    ].slice(0, MAX_CONTEXTUAL_SECTIONS);
    const updatedSelectedSections = [...selectedSections, ...newSectionsToAdd];
    this.setState({
      selectedSections: updatedSelectedSections,
      contextualSections: updatedContextualSections,
    });
  };

  startShowTimeout = () => {
    this.timeout = getWindow().setTimeout(() => {
      this.props.usageTrackTopicPickerCta();
      this.setState({ showCta: true });
    }, SHOW_CTA_DELAY_MS);
  };

  onClickSeeMore = () => {
    this.props.usageTrackTopicPickerCtaSeeMore();
    this.setState({ showAll: true });
  };

  onClickExit = () => {
    this.props.usageTrackTopicPickerCtaExit();
    this.setState({ showCta: false });
  };

  onSave = () => {
    const { addPostOnboardingSocialAction, navFrom } = this.props;
    const { selectedSections } = this.state;
    if (!selectedSections) {
      return null;
    }
    this.setState({ showCta: false });
    const sectionsToFollow = selectedSections.map((section) => {
      if (SectionUtil.isTopic(section)) {
        return TopicUtil.decorateForFollowing(section);
      }
      return section;
    });
    addPostOnboardingSocialAction({
      follow: { sections: sectionsToFollow, navFrom },
    });
  };

  render() {
    const { editorialBoards, className, isAuthenticated, isLargeScreen, t } =
      this.props;
    const { showCta, showAll, selectedSections, contextualSections } =
      this.state;
    if (!showCta || !editorialBoards || isAuthenticated || !isLargeScreen) {
      return null;
    }
    const uniqueEditorialBoards = this.getUniqueSectionsToAdd(
      contextualSections,
      editorialBoards,
    );
    const pickerSections = [...contextualSections, ...uniqueEditorialBoards];
    const titleText = t('explore_more_on_flipboard');

    const selectedSectionTagsForUsage = selectedSections.map((s) =>
      FlapUtil.getTopicTagFromRemoteid(s.remoteid as Flipboard.SectionId),
    );
    const contextualSectionsForUsage = contextualSections.map((s) =>
      FlapUtil.getTopicTagFromRemoteid(s.remoteid as Flipboard.SectionId),
    );
    return (
      <>
        <TopicPickerCTAPopup
          className={className}
          positionProps={{ bottom: SPACING.LARGE }}
          showCloseButton
          onDismiss={this.onClickExit}
          showAll={showAll}
        >
          <OnboardingSectionPicker
            titleText={titleText}
            pickerSections={pickerSections}
            selectedSections={selectedSections}
            setSelectedSections={(sections: TopicPickerArrayType) =>
              this.setState({ selectedSections: sections })
            }
            truncateLines={!showAll && 3}
          />
          <PickerControls>
            {!showAll && (
              <Button
                name="topic-picker-showMore"
                styleVariation={StyleVariations.SECONDARY}
                onClick={this.onClickSeeMore}
              >
                {t('more')}
              </Button>
            )}

            <SignupButton
              name="topic-picker-cta-singup"
              label={t('save')}
              styleVariation={StyleVariations.PRIMARY}
              onClick={this.onSave}
              displayStyle={USAGE_DISPLAY_STYLES.TOPIC_PICKER_CTA}
              customUsageData={{
                contextual_sections: contextualSectionsForUsage,
                selected_sections: selectedSectionTagsForUsage,
              }}
              overrideOnboardingFlow={OnboardingFlow.STICKY_TOPIC_PICKER_CTA}
            />
          </PickerControls>
        </TopicPickerCTAPopup>
      </>
    );
  }
}

export default connector<TopicPickerCTAProps>(
  connectResponsive,
  connectAuthentication,
  connectContextualOnboarding,
)(withNavFrom(withSignupCta(withT(TopicPickerCTA))));
