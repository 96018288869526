import React from 'react';
import PropTypes from 'prop-types';
import AccountVerificationStatus from 'Webapp/shared/app/components/account-verification-status';

import connector from 'Utils/connector';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectRouting from 'Webapp/shared/app/connectors/connectRouting';
import connectVerificationStatus from 'Webapp/shared/app/connectors/connectVerificationStatus';
import withBreadcrumbs from 'Webapp/shared/app/hocs/withBreadcrumbs';
import styled from '@emotion/styled';
import { LAYOUT_SIZES } from 'Style/layout';
import { DIVIDER_COLORS } from 'Style/colors';
import isPropValid from '@emotion/is-prop-valid';

const shouldForwardProp = (prop) =>
  isPropValid(prop) && prop !== 'hasBreadcrumbs' && prop !== 'isHomeRoute';

const VerificationStatusWrapper = styled('div', { shouldForwardProp })(
  (props) => {
    const needsMarginTop = !!(
      !props?.props?.hasBreadcrumbs || props?.props?.isHomeRoute
    );
    return {
      marginTop: needsMarginTop ? `${LAYOUT_SIZES.MAIN_NAV_HEIGHT}` : '0',
      backgroundColor: `${DIVIDER_COLORS.secondary}`,
    };
  },
);

function BodyAccountVerificationStatus({
  isWideLargeScreen,
  hasBreadcrumbs,
  isHomeRoute,
  showAccountVerificationStatus,
}) {
  return (
    showAccountVerificationStatus &&
    !isWideLargeScreen && (
      <VerificationStatusWrapper props={{ hasBreadcrumbs, isHomeRoute }}>
        <AccountVerificationStatus />
      </VerificationStatusWrapper>
    )
  );
}

BodyAccountVerificationStatus.propTypes = {
  isWideLargeScreen: PropTypes.bool.isRequired,
  hasBreadcrumbs: PropTypes.bool.isRequired,
  isHomeRoute: PropTypes.bool.isRequired,
  showAccountVerificationStatus: PropTypes.bool.isRequired,
};

export default connector(
  connectVerificationStatus,
  connectRouting,
  connectResponsive,
)(withBreadcrumbs(BodyAccountVerificationStatus));
