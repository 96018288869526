import { isEqual, differenceWith } from 'lodash';

export const SUBSCRIPTION_IMPLICIT_OPT_OUT_STATE = '0';
export const SUBSCRIPTION_IMPLICIT_SUBSCRIBE_STATE = '1';
export const SUBSCRIPTION_EXPLICIT_OPT_OUT_STATE = '2';
export const SUBSCRIPTION_EXPLICIT_SUBSCRIBE_STATE = '3';

export const allGroup = { group: '*' };
export const allSubscription = { key: '*' };
export const everything = Object.assign(allGroup, {
  subscriptions: [allSubscription],
});

export const subscriptionMatches = (
  groupA,
  groupB,
  subscriptionA,
  subscriptionB,
) => {
  if ((subscriptionA && !subscriptionB) || (!subscriptionA && subscriptionB)) {
    return false;
  }
  const groupMatches = groupA.group === groupB.group;
  if (!subscriptionA && !subscriptionB) {
    return groupMatches;
  }
  return groupMatches && subscriptionA.key === subscriptionB.key;
};

export const findSubscription = (groups, searchGroup, searchSubscription) => {
  const foundGroup = groups.find((group) => group.group === searchGroup.group);

  if (!foundGroup) {
    return null;
  }

  if (!searchSubscription) {
    return foundGroup;
  }

  return (
    foundGroup.subscriptions.find(
      (subscription) => subscription.key === searchSubscription.key,
    ) || null
  );
};

export const groupsWithUpdatedIsSubscribed = (
  groups,
  searchGroup,
  searchSubscription,
  newIsSubscribed,
) =>
  groups.map((currentGroup) => {
    const newGroupIsSubscribed = subscriptionMatches(
      searchGroup,
      currentGroup,
      null,
      searchSubscription,
    )
      ? newIsSubscribed
      : currentGroup.isSubscribed;

    const newGroupSubscriptions = currentGroup.subscriptions.map(
      (currentSubscription) => {
        const newSubscriptionIsSubscribed = subscriptionMatches(
          searchGroup,
          currentGroup,
          searchSubscription,
          currentSubscription,
        )
          ? newIsSubscribed
          : currentSubscription.isSubscribed;

        return Object.assign({}, currentSubscription, {
          isSubscribed: newSubscriptionIsSubscribed,
        });
      },
    );

    return Object.assign({}, currentGroup, {
      isSubscribed: newGroupIsSubscribed,
      subscriptions: newGroupSubscriptions,
    });
  });

export const sectionHasNewsletterSignup = (section) => {
  const topicTagsWithNewsletters = [
    'climatechange',
    'food',
    'foodcooking',
    'news',
    'politics',
    'recipes',
    'technology',
    'photography',
  ];

  return section.isTopic && topicTagsWithNewsletters.includes(section.topicTag);
};

export const hardCodedGroups = {
  recs: {
    group: 'recs',
  },
};

export const groupForSection = (section) => {
  if (section.isTopic && section.topicTag === 'climatechange') {
    return hardCodedGroups.recs;
  }

  if (
    section.isTopic &&
    (section.topicTag === 'food' || section.topicTag === 'foodcooking')
  ) {
    return hardCodedGroups.recs;
  }

  if (section.isTopic && section.topicTag === 'news') {
    return hardCodedGroups.recs;
  }

  if (section.isTopic && section.topicTag === 'politics') {
    return hardCodedGroups.recs;
  }

  if (section.isTopic && section.topicTag === 'recipes') {
    return hardCodedGroups.recs;
  }

  if (section.isTopic && section.topicTag === 'technology') {
    return hardCodedGroups.recs;
  }

  if (section.isTopic && section.topicTag === 'photography') {
    return hardCodedGroups.recs;
  }

  return null;
};

export const hardCodedSubscriptions = {
  climateBriefing: {
    key: 'Climate',
  },
  food: {
    key: 'Food',
  },
  photoDesk: {
    key: 'PhotoDesk',
  },
  politicsRundown: {
    key: 'Polit',
  },
  recipes: {
    key: 'Recipes',
  },
  tech: {
    key: 'Tech',
  },
  photography: {
    key: 'Photography',
  },
};

export const subscriptionForSection = (section) => {
  if (section.isTopic && section.topicTag === 'climatechange') {
    return hardCodedSubscriptions.climateBriefing;
  }

  if (
    section.isTopic &&
    (section.topicTag === 'food' || section.topicTag === 'foodcooking')
  ) {
    return hardCodedSubscriptions.food;
  }

  if (section.isTopic && section.topicTag === 'news') {
    return hardCodedSubscriptions.photoDesk;
  }

  if (section.isTopic && section.topicTag === 'politics') {
    return hardCodedSubscriptions.politicsRundown;
  }

  if (section.isTopic && section.topicTag === 'recipes') {
    return hardCodedSubscriptions.recipes;
  }

  if (section.isTopic && section.topicTag === 'technology') {
    return hardCodedSubscriptions.tech;
  }

  if (section.isTopic && section.topicTag === 'photography') {
    return hardCodedSubscriptions.photography;
  }

  return null;
};

export const localizedGroupName = (t, group) =>
  t(`email_preference_group_name_${group.group}`, group.name);

export const localizedSubscriptionName = (t, subscription) =>
  t(
    `email_preference_subscription_name_${subscription.key}`,
    subscription.name,
  );

/**
 * Returns array of diff between initial and pending's state for each group/subscription.
 * @param {Object} initialSubscriptionGroups - initial state of subscription
 * @param {Object} pendingSubscriptionGroups - pending state of subscription
 * @typedef {Object} DiffState - diff state of subscription
 * @property {object} DiffState.pendingGroup - diff state of pending group
 * @property {Array<object>} DiffState.pendingSubscriptions - diff state of subscriptions in array
 * @returns {Array<DiffState>} -
 *   ex: [{ pendingGroup: groupA }, { pendingGroup: groupB. pendingSubscriptions: [ subscriptionA, subscriptionB ]}]
 */
export const getDiffSubscriptionsState = (
  initialSubscriptionGroups,
  pendingSubscriptionGroups,
) => {
  const difffSubscriptionsState = [];
  let isGlobalAllGroupStateNullified = false;

  // delta outputs array of the difference of both states
  // in group level
  const delta = differenceWith(
    pendingSubscriptionGroups,
    initialSubscriptionGroups,
    isEqual,
  );
  if (delta.length > 0) {
    delta.forEach((pendingGroup) => {
      // If the all-group level's isSubscribed is null
      // it means its state no longer exist --> skip it
      if (
        pendingGroup.isSubscribed === null &&
        pendingGroup.group === allGroup.group
      ) {
        isGlobalAllGroupStateNullified = true;
        return;
      }

      const initialGroup = initialSubscriptionGroups.find(
        (group) => group.name === pendingGroup.name,
      );

      // If the group level is currently false and previously it was
      // null -- handle here

      if (
        pendingGroup.isSubscribed === false &&
        initialGroup.isSubscribed === null
      ) {
        const subDelta = differenceWith(
          pendingGroup.subscriptions,
          initialGroup.subscriptions,
          isEqual,
        );

        const isAnySubscriptionsSubscribed = pendingGroup.subscriptions.some(
          (x) => x.isSubscribed == true,
        );

        if (isAnySubscriptionsSubscribed) {
          difffSubscriptionsState.push({
            pendingGroup,
            pendingSubscriptions:
              (subDelta.length && !isGlobalAllGroupStateNullified) ||
              isAnySubscriptionsSubscribed
                ? subDelta
                : null,
          });
        } else {
          difffSubscriptionsState.push({ pendingGroup });
        }
      } else if (
        // If the group is currently is false and previously was set to false
        // check its subscriptions directly and see if anyone of them is true first
        pendingGroup.isSubscribed === false &&
        initialGroup.isSubscribed === false
      ) {
        const isAnySubscriptionsSubscribed = pendingGroup.subscriptions.some(
          (x) => x.isSubscribed == true,
        );

        if (!isAnySubscriptionsSubscribed) {
          return;
        }

        const deltaSubscriptions = differenceWith(
          pendingGroup.subscriptions,
          initialGroup.subscriptions,
          isEqual,
        );

        // if there are no changes in subscriptions, omit it
        if (deltaSubscriptions.length <= 0) {
          return;
        }

        difffSubscriptionsState.push({
          pendingGroup,
          pendingSubscriptions: deltaSubscriptions,
        });
        // If group is currently subscribed and hasnt changed,
        // check to see if the groupAll was recently nullified
      } else if (
        pendingGroup.isSubscribed &&
        pendingGroup.isSubscribed === initialGroup.isSubscribed
      ) {
        // if groupAll is nullified, we'd want to send the current
        // state for pendingGroup
        if (isGlobalAllGroupStateNullified) {
          difffSubscriptionsState.push({ pendingGroup });
        } else {
          return;
        }
      } else {
        // If the group level is subscribed and previously it wasnt,
        // skip its children
        if (pendingGroup.isSubscribed && !initialGroup.isSubscribed) {
          difffSubscriptionsState.push({ pendingGroup });
        } else {
          // Otherwise, find all the differences in the subscriptions
          const deltaSubscriptionZ = differenceWith(
            pendingGroup.subscriptions,
            initialGroup.subscriptions,
            isEqual,
          );

          // Check to see if any of the pending subscriptions is subscribed true.
          // If not, we'll send the group level as state 2 and
          // ignore the subscriptions' state
          const isAnySubscriptionsSubscribed = pendingGroup.subscriptions.some(
            (x) => x.isSubscribed == true,
          );

          if (isAnySubscriptionsSubscribed) {
            difffSubscriptionsState.push({
              pendingGroup,
              pendingSubscriptions: deltaSubscriptionZ,
            });
          } else {
            difffSubscriptionsState.push({ pendingGroup });
          }
        }
      }
    });
  }
  const allGroupDelta = difffSubscriptionsState.filter(
    (x) => x.pendingGroup.group === allGroup.group,
  );
  let allGroupSubscriptionChild;
  if (allGroupDelta.length) {
    allGroupSubscriptionChild =
      allGroupDelta[0].pendingGroup.subscriptions[0].isSubscribed;
  }

  if (allGroupDelta.length && allGroupSubscriptionChild !== null) {
    return allGroupDelta;
  }

  return difffSubscriptionsState;
};
