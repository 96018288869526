import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import codeSplitLoad from 'Webapp/shared/utils/code-split-load';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { BREAKPOINTS } from 'Style/breakpoints';

import Config from '../../config';
import { getCookieValue, setCookieValue } from 'Utils/client-cookie';

// Components
import WebLink from './web-link';
import LoginButton from './login-button';
import UsnatApplies from './usnat-applies';
import GdprApplies from './gdpr-applies';
import DataProtectionLink from './data-protection-link';
import PrivacyNoticePopup from './privacy-notice-popup';
import TopicPickerCTA from 'Webapp/shared/app/components/signup-cta/topic-picker-cta';
import EmbedToolsModal from 'Webapp/shared/app/modals/embed-tools-modal';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectFeatureFlags from 'Webapp/shared/app/connectors/connectFeatureFlags';
import connectAcceptMagazineInvite from 'Webapp/shared/app/connectors/connectAcceptMagazineInvite';
import connectModal from 'Webapp/shared/app/connectors/connectModal';

const DoNotSellLink = codeSplitLoad('DoNotSellLink');

export const FOOTER_THEMES = {
  FULL: 'full',
  SPARSE: 'sparse',
};

const StyledFooter = styled.footer({
  padding: `${SPACING.BASE6X} ${SPACING.LARGE} ${SPACING.BASE8X}`,
  a: {
    padding: `${SPACING.MEDIUM}`,
    textDecoration: 'underline',
    textTransform: 'capitalize',
    '&:focus': {
      textDecoration: 'none',
    },
  },
  '.footer__links': {
    fontSize: '14px',
    textAlign: 'center',
    'li, a': {
      display: 'inline-block',
    },
  },
  '.footer__copyright': {
    display: 'block',
    margin: `${SPACING.LARGE} auto`,
    textAlign: 'center',
    fontSize: '12px',
  },
});
const StyledTopicPickerCTA = styled(TopicPickerCTA)(({ bottom }) => ({
  bottom: `calc(${bottom} + ${SPACING.LARGE})`,
  marginBottom: bottom ? SPACING.LARGE : 0,
}));
const StyledPrivacyNotice = styled(PrivacyNoticePopup)(
  ({ height }) => ({
    height,
  }),
  BREAKPOINTS.phone({ width: '100%' }),
);
const Footer = ({
  isTabletNarrow,
  isAuthenticated,
  footerTheme,
  featureFlags,
  magazineInvite,
  showModal,
  t,
}) => {
  const year = new Date().getFullYear();
  const isFullTheme = footerTheme === FOOTER_THEMES.FULL;
  const privacyNoticeHeight = isTabletNarrow
    ? SPACING.BASE12X
    : SPACING.BASE10X;
  const [privacyNoticeViewed, setPrivacyNoticeViewed] = useState(false);
  useEffect(
    () => setPrivacyNoticeViewed(getCookieValue('privacyNoticeViewed')),
    [],
  );

  const dismissPrivacyNotice = () => {
    setCookieValue('privacyNoticeViewed', true);
    setPrivacyNoticeViewed(true);
  };

  const showPrivacyNotice =
    featureFlags.PRIVACY_CHANGE_NOTICE && !privacyNoticeViewed;
  return (
    <>
      <StyledFooter className="footer">
        <ul className="footer__links">
          {!isAuthenticated && isFullTheme && (
            <li>
              <LoginButton name="footer-login" />
            </li>
          )}
          {isFullTheme && (
            <React.Fragment>
              {!isAuthenticated && (
                <li>
                  <WebLink
                    title={t('newsletters_sign_up_tooltip')}
                    href="/newsletters"
                  >
                    {t('newsletters_link')}
                  </WebLink>
                </li>
              )}
              <li>
                <WebLink
                  title={t('about_us_tooltip')}
                  href={Config.ABOUT_BASE_URL}
                  openInNewWindow
                >
                  {t('about_us')}
                </WebLink>
              </li>
              <li>
                <WebLink
                  title={t('blog_tooltip')}
                  href={`${Config.ABOUT_BASE_URL}/blog/`}
                  openInNewWindow
                >
                  {t('blog')}
                </WebLink>
              </li>
              {featureFlags.WIDGET_TOOL ? (
                <li>
                  <WebLink
                    title={t('tools_tooltip')}
                    onClick={() => {
                      showModal(EmbedToolsModal);
                    }}
                    openInNewWindow
                  >
                    🛠️
                  </WebLink>
                </li>
              ) : (
                <li>
                  <WebLink
                    title={t('tools_tooltip')}
                    href={`${Config.ABOUT_BASE_URL}/tools/`}
                    openInNewWindow
                  >
                    {t('tools')}
                  </WebLink>
                </li>
              )}
              <li>
                <WebLink
                  title={t('publishers_tooltip')}
                  href={`${Config.ABOUT_BASE_URL}/forpublishers/`}
                  openInNewWindow
                >
                  {t('publishers')}
                </WebLink>
              </li>
              <li>
                <WebLink
                  title={t('help_tooltip')}
                  href={`${Config.HELP_CENTER_URL}`}
                  openInNewWindow
                >
                  {t('help')}
                </WebLink>
              </li>
            </React.Fragment>
          )}
          <li>
            <WebLink
              title={t('terms_tooltip')}
              href={`${Config.ABOUT_BASE_URL}/terms/`}
              openInNewWindow
            >
              {t('terms')}
            </WebLink>
          </li>
          <li>
            <WebLink
              title={t('privacy_policy_tooltip')}
              href={`${Config.ABOUT_BASE_URL}/privacy/`}
              openInNewWindow
            >
              {t('privacy_policy')}
            </WebLink>
          </li>
          <UsnatApplies>
            <li>
              <DoNotSellLink />
            </li>
          </UsnatApplies>
          <GdprApplies>
            <li>
              <DataProtectionLink />
            </li>
          </GdprApplies>
          {isFullTheme && (
            <React.Fragment>
              <li>
                <WebLink title={t('sitemap_tooltip')} href="/sitemap">
                  {t('sitemap')}
                </WebLink>
              </li>
            </React.Fragment>
          )}
        </ul>
        <WebLink
          title={t('copyright_tooltip')}
          href={`${Config.ABOUT_BASE_URL}/copyright`}
          className="footer__copyright"
          openInNewWindow
        >
          &copy; {year} {t('flipboard')}
        </WebLink>
      </StyledFooter>
      {featureFlags.TOPIC_PICKER_CTA && !magazineInvite && (
        <StyledTopicPickerCTA
          bottom={showPrivacyNotice ? privacyNoticeHeight : 0}
        />
      )}
      {showPrivacyNotice && (
        <StyledPrivacyNotice
          height={privacyNoticeHeight}
          onDismiss={dismissPrivacyNotice}
        />
      )}
    </>
  );
};

Footer.propTypes = {
  isPhone: PropTypes.bool.isRequired,
  isTabletNarrow: PropTypes.bool.isRequired,
  footerTheme: PropTypes.oneOf(Object.values(FOOTER_THEMES)),
  isAuthenticated: PropTypes.bool.isRequired,
  featureFlags: PropTypes.object,
  magazineInvite: PropTypes.object,
  showModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

Footer.defaultProps = {
  footerTheme: FOOTER_THEMES.FULL,
};

export default connector(
  connectResponsive,
  connectAuthentication,
  connectFeatureFlags,
  connectAcceptMagazineInvite,
  connectModal,
)(withT(Footer));
