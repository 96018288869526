/* eslint-disable react/prefer-stateless-function */
// Allow creation of class component, so that refs can still be used on wrapped components

import React from 'react';
import { NavFromContext } from 'ComponentLibrary/context';
import HocUtil from 'Utils/hoc-util';

function withNavFrom(Component) {
  class WrappedComponent extends React.Component {
    render() {
      return (
        <NavFromContext.Consumer>
          {(navFrom) => <Component navFrom={navFrom} {...this.props} />}
        </NavFromContext.Consumer>
      );
    }
  }

  WrappedComponent.displayName = HocUtil.displayName(Component);
  return WrappedComponent;
}

export default withNavFrom;
