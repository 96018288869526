import React from 'react';
import PropTypes from 'prop-types';
import Icon from './icon';
import styled from '@emotion/styled';
import { ICON_COLORS } from 'Style/colors';

export const StyledUserAddIcon = styled(Icon)(() => ({
  color: ICON_COLORS.primary,
}));

const UserAddIcon = ({ ...props }) => (
  <StyledUserAddIcon
    name="user-add"
    viewBox="0 0 16 14"
    fill="none"
    {...props}
    renderComponent={() => (
      <g>
        <path
          d="M6 0.333496C4.15905 0.333496 2.66667 1.82588 2.66667 3.66683C2.66667 5.50778 4.15905 7.00016 6 7.00016C7.84095 7.00016 9.33333 5.50778 9.33333 3.66683C9.33333 1.82588 7.84095 0.333496 6 0.333496Z"
          fill="currentColor"
        />
        <path
          d="M3.33333 8.3335C2.44928 8.3335 1.60143 8.68469 0.976311 9.30981C0.351189 9.93493 0 10.7828 0 11.6668V13.0002C0 13.3684 0.298477 13.6668 0.666667 13.6668H11.3333C11.7015 13.6668 12 13.3684 12 13.0002V11.6668C12 10.7828 11.6488 9.93493 11.0237 9.30981C10.3986 8.68469 9.55072 8.3335 8.66667 8.3335H3.33333Z"
          fill="currentColor"
        />
        <path
          d="M13.3333 3.66683C13.7015 3.66683 14 3.96531 14 4.3335V5.66683H15.3333C15.7015 5.66683 16 5.96531 16 6.3335C16 6.70169 15.7015 7.00016 15.3333 7.00016H14V8.3335C14 8.70169 13.7015 9.00016 13.3333 9.00016C12.9651 9.00016 12.6667 8.70169 12.6667 8.3335V7.00016H11.3333C10.9651 7.00016 10.6667 6.70169 10.6667 6.3335C10.6667 5.96531 10.9651 5.66683 11.3333 5.66683H12.6667V4.3335C12.6667 3.96531 12.9651 3.66683 13.3333 3.66683Z"
          fill="currentColor"
        />
      </g>
    )}
  />
);

UserAddIcon.propTypes = {
  size: PropTypes.number,
};

UserAddIcon.defaultProps = {
  size: 16,
};

export default UserAddIcon;
