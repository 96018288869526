import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ICON_COLORS } from 'Style/colors';
import Icon from './icon';
import withT from 'ComponentLibrary/hocs/withT';

export const StyledSearchIcon = styled(Icon)((props) => ({
  color: props.color || ICON_COLORS.emphasis,
  fill: 'currentColor',
}));

function SearchIcon({ size, ...props }) {
  return (
    <StyledSearchIcon
      name="bell"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      {...props}
      renderComponent={() => (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5 2C5.80558 2 2 5.80558 2 10.5C2 15.1944 5.80558 19 10.5 19C12.3963 19 14.1476 18.379 15.5616 17.3293L19.1161 20.8839C19.6043 21.372 20.3957 21.372 20.8839 20.8839C21.372 20.3957 21.372 19.6043 20.8839 19.1161L17.3293 15.5616C18.379 14.1476 19 12.3963 19 10.5C19 5.80558 15.1944 2 10.5 2ZM4 10.5C4 6.91015 6.91015 4 10.5 4C14.0899 4 17 6.91015 17 10.5C17 14.0899 14.0899 17 10.5 17C6.91015 17 4 14.0899 4 10.5Z"
            fill="currentColor"
          />
        </>
      )}
    />
  );
}

SearchIcon.propTypes = {
  size: PropTypes.number,
  t: PropTypes.func.isRequired,
};

SearchIcon.defaultProps = {
  size: 24,
};

export default withT(SearchIcon);
