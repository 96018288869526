import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

import { onboardingDataSelector } from 'Webapp/shared/concepts/onboarding';
import { toastShowInfoAction } from 'Webapp/shared/app/redux/actions/toast-actions';

const showSuccessToastMessageInviteMagazine: OnboardingStep =
  (nextStep) =>
  async (dispatch, getState, { t }) => {
    const featureFlags = getState().featureFlags;
    const onboardingData = onboardingDataSelector(getState());
    if (onboardingData && onboardingData.magazineInviteSection) {
      dispatch(
        featureFlags.INVITE_MAGAZINE_CONTRIBUTOR
          ? toastShowInfoAction(t('youre_in_magazine'))
          : toastShowInfoAction(
              t('accept_contributor_invite_success', {
                inviteTitle: onboardingData.magazineInviteSection.title,
              }),
            ),
      );
    }
    nextStep();
  };

export default showSuccessToastMessageInviteMagazine;
