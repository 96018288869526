import { CONTENT_TYPES } from 'Webapp/action-types';
import { set } from 'Utils/redux';

export const getEditorialBoards = () =>
  async function getEditorialBoards(dispatch, _, { flapStatic }) {
    const { data } = await flapStatic.get('/editorialBoards.json');
    dispatch({
      type: CONTENT_TYPES.GET_EDITORIAL_BOARDS_SUCCEEDED,
      payload: data.boards,
    });
  };

export const fetchRecommendedBoards =
  () =>
  async (dispatch, _, { flap }) => {
    try {
      dispatch({ type: CONTENT_TYPES.GET_RECOMMENDED_BOARDS_PENDING });
      const { data } = await flap.get('/flipboard/recommendBoards');
      dispatch({
        type: CONTENT_TYPES.GET_RECOMMENDED_BOARDS_SUCCESS,
        data,
      });
    } catch (_) {
      dispatch({ type: CONTENT_TYPES.GET_RECOMMENDED_BOARDS_FAILED });
    }
  };

export const getContentGuide =
  () =>
  async (dispatch, getState, { flapStatic }) => {
    try {
      const {
        app: { lang, locale },
      } = getState();
      dispatch({ type: CONTENT_TYPES.GET_CONTENT_GUIDE });

      const { data } = await flapStatic.get('/contentGuide.json', {
        params: { lang, locale },
      });
      dispatch({
        type: CONTENT_TYPES.GET_CONTENT_GUIDE_SUCCEEDED,
        payload: { contentGuide: data },
      });
      return data;
    } catch (_) {
      dispatch({ type: CONTENT_TYPES.GET_CONTENT_GUIDE_FAILED });
    }
  };

export const setSelectedContentGuideSection = (remoteId) => ({
  type: CONTENT_TYPES.SET_SELECTED_CONTENT_GUIDE_SECTION,
  payload: { remoteId },
});

export const setContextualFollowSections = (contextualFollowSections) =>
  set(
    CONTENT_TYPES.SET_CONTEXTUAL_ONBOARDING_FOLLOW_SECTIONS,
    'contextualFollowSections',
    contextualFollowSections,
  );
