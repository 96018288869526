import FlapUtil from 'Utils/content/flap-util.js';
import Config from 'Config';

import {
  isAuthenticated,
  currentUserUid,
} from 'Webapp/shared/app/redux/selectors/auth';

// @TODO convert reducers/profile to ts
export function userInfoSelector({
  profile: { userInfo },
}: Flipboard.State): Flipboard.UserInfo {
  return userInfo as unknown as Flipboard.UserInfo;
}

function userStateSelector(state: Flipboard.State) {
  const userInfo = userInfoSelector(state);
  return (
    (userInfo &&
      userInfo.states &&
      userInfo.states.find((state) => state.type === 'user')) ||
    null
  );
}

export function userStateDataSelector(state: Flipboard.State) {
  const userState = userStateSelector(state);
  return (userState && userState.data) || null;
}

export function userStateFollowingIdsSelector(state: Flipboard.State) {
  const { profile } = state;
  return (profile && profile.followingIds) || [];
}

export function userStateRevisionSelector(state: Flipboard.State) {
  const userState = userStateSelector(state);
  return (userState && parseInt(userState.revision.toString(), 10)) || 0;
}

export function isUserStateLoadingSelector(state: Flipboard.State) {
  const { profile } = state;
  return profile.isUserStateLoading;
}

export const currentUser = (
  state: Flipboard.State,
): Flipboard.UserService | undefined => {
  const userInfo = userInfoSelector(state);
  return FlapUtil.getUserFromUserInfo(userInfo) || undefined;
};

export function smartMagazineSelector(
  state: Flipboard.State,
): Flipboard.Section[] {
  const { profile } = state;
  return profile.smartMagazines;
}

export function currentUserProfileSection(
  state: Flipboard.State,
): Flipboard.ProfileSection | null {
  if (!isAuthenticated(state)) {
    return null;
  }

  const uid = currentUserUid(state);
  const remoteid = FlapUtil.getRemoteidByUserid(uid);
  return (
    remoteid && FlapUtil.getSectionByRemoteId(remoteid, state.sections.entries)
  );
}

export function currentUserVerified(state: Flipboard.State) {
  return !!currentUser(state)?.confirmedEmail;
}

export function currentUserVerifiedPublisher(state: Flipboard.State) {
  return !!currentUser(state)?.profileSection?.verifiedType;
}

export function allUserMagazines(state: Flipboard.State) {
  const { profile } = state;
  return [...profile.magazines, ...profile.contributorMagazines];
}

export function storyboardCount(state) {
  const { profile } = state;
  return profile?.stats?.profileMetrics.find(
    (metric) => metric.type === 'packageCount',
  ).raw;
}

export const profileNeedsMoreFollowsForOnboarding = (
  state: Flipboard.State,
) => {
  const { profile } = state;
  return profile?.followingIds?.length < Config.MIN_REQUIRED_TOPIC_FOLLOWS;
};

export const isFavoritesFull = (state: Flipboard.State) => {
  const {
    profile: { favorites },
  } = state;
  const filteredFavorites = favorites.filter((f) => !f.dynamicFeed);

  return filteredFavorites.length >= 10;
};
