import React from 'react';
import styled from '@emotion/styled';

import { MODAL_THEMES } from 'ComponentLibrary/modals/modal.js';
import { ItemMetricDetailType } from 'Webapp/enums';
import { getSocialActivityValue } from 'Webapp/shared/concepts/social-activity';
import Button, { StyleVariations } from 'Webapp/shared/app/components/button';
import LoadingSpinner from 'ComponentLibrary/icons/loading-spinner.js';
import ItemMetricDetailLike from 'Webapp/shared/app/components/item-metric-detail-like';
import ItemMetricDetailFlip from 'Webapp/shared/app/components/item-metric-detail-flip';
import ItemCoreActionModal from 'Webapp/shared/app/modals/item-core-action-modal';

import { SPACING } from 'Style/spacing';

import { DIVIDER_COLORS, TEXT_COLORS } from 'Style/colors';
import { BODY_TYPES } from 'Style/typography';

import connector from 'Webapp/utils/connector';
import connectItemMetricDetail, {
  ConnectItemMetricDetailProps,
} from 'Webapp/shared/app/connectors/connectItemMetricDetail';
import connectSocialActivity, {
  ConnectSocialActivityProps,
} from 'Webapp/shared/app/connectors/connectSocialActivity';
import connectResponsive, {
  ConnectResponsiveProps,
} from 'Webapp/shared/app/connectors/connectResponsive';

import withT from 'ComponentLibrary/hocs/withT';

const LoadMoreWrapper = styled.div({
  display: 'grid',
  justifyItems: 'center',
  margin: SPACING.LARGE,
});

const LoadingWrapper = styled.div({ minHeight: '100px' });

const ItemMetricDetailListItem = styled('li')({
  padding: `${SPACING.MEDIUM} 0`,
  borderBottom: `1px solid ${DIVIDER_COLORS.primary}`,
  '&:last-child': {
    border: 'none',
  },
});

const ItemMetricDetailList = styled('ul')({
  margin: `0 ${SPACING.LARGE} ${SPACING.LARGE} ${SPACING.LARGE}`,
});

const ItemMetricDetailCount = styled('div')(BODY_TYPES.XSMALL_STANDARD, {
  margin: `${SPACING.BASE4X} ${SPACING.LARGE} 0 ${SPACING.LARGE}`,
  color: TEXT_COLORS.tertiary,
});

export interface ItemMetricDetailPassedProps {
  item: Flipboard.Item;
  section: Flipboard.Section;
  itemMetricDetailType: ItemMetricDetailType;
}

type ItemMetricDetailProps = ItemMetricDetailPassedProps &
  ConnectItemMetricDetailProps &
  ConnectSocialActivityProps &
  ConnectResponsiveProps & {
    t: Flipboard.TFunction;
  };

interface ItemMetricDetailState {
  items: Array<Flipboard.FlapCommentaryItem>;
  loading: boolean;
  nextPageKey: Flipboard.NextPageKey | null;
}

class ItemMetricDetail extends React.Component<
  ItemMetricDetailProps,
  ItemMetricDetailState
> {
  state: ItemMetricDetailState = {
    items: [],
    nextPageKey: null,
    loading: false,
  };

  componentDidMount() {
    this.fetchNextPage();
  }

  fetchNextPage = () => {
    const { fetchItemMetricDetail, itemMetricDetailType, item } = this.props;
    const pageKey = this.state.nextPageKey;
    this.setState({ loading: true, nextPageKey: null }, async () => {
      const { items, nextPageKey } = await fetchItemMetricDetail({
        item,
        itemMetricDetailType,
        pageKey,
      });
      this.setState({
        items: this.state.items.concat(items),
        nextPageKey,
        loading: false,
      });
    });
  };

  render() {
    const { item, section, itemMetricDetailType, socialActivity, isPhone, t } =
      this.props;
    const { nextPageKey, items, loading } = this.state;
    const ItemComponent =
      itemMetricDetailType === ItemMetricDetailType.LIKE
        ? ItemMetricDetailLike
        : ItemMetricDetailFlip;
    const getSocialCount = (key: 'likeCount' | 'shareCount') =>
      getSocialActivityValue(item, socialActivity, key) || 0;
    const detailCount =
      itemMetricDetailType === ItemMetricDetailType.LIKE
        ? t('like_count', {
            count: getSocialCount('likeCount'),
          })
        : t('flip_count', {
            count: getSocialCount('shareCount'),
          });
    return (
      <ItemCoreActionModal
        name="comments"
        item={item}
        section={section}
        modalTheme={isPhone ? MODAL_THEMES.FULLSCREEN : MODAL_THEMES.WINDOW}
      >
        <ItemMetricDetailCount>{detailCount}</ItemMetricDetailCount>
        {items.length > 0 && (
          <ItemMetricDetailList>
            {items.map((i) => (
              <ItemMetricDetailListItem key={`${i.type}-${i.authorUsername}`}>
                <ItemComponent item={i} />
              </ItemMetricDetailListItem>
            ))}
          </ItemMetricDetailList>
        )}
        <LoadMoreWrapper>
          {loading && (
            <LoadingWrapper>
              <LoadingSpinner />
            </LoadingWrapper>
          )}
          {nextPageKey && (
            <Button
              styleVariation={StyleVariations.SECONDARY}
              onClick={this.fetchNextPage}
            >
              {t('load_more')}
            </Button>
          )}
        </LoadMoreWrapper>
      </ItemCoreActionModal>
    );
  }
}

export default connector<ItemMetricDetailProps>(
  connectItemMetricDetail,
  connectSocialActivity,
  connectResponsive,
)(withT(ItemMetricDetail));
