import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

import { usageTrackPublisherSignupExit } from 'Webapp/shared/app/redux/actions/usage-actions';

const trackPublisherSignupExit: OnboardingStep = (nextStep) => (dispatch) => {
  dispatch(usageTrackPublisherSignupExit(true, 'flipboard'));
  nextStep();
};

export default trackPublisherSignupExit;
