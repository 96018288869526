import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Utils
import codeSplitLoad from 'Webapp/shared/utils/code-split-load';
import { setCookieValue } from 'Utils/client-cookie';
import getWindow from 'Utils/get-window';

// Components
import Button from 'Webapp/shared/app/components/button';

import connector from 'Utils/connector';

import connectModal from 'Webapp/shared/app/connectors/connectModal';

import withT from 'ComponentLibrary/hocs/withT';

const LoginModal = codeSplitLoad('LoginModal');

class LoginButton extends Component {
  handleClick = (e) => {
    if (e.ctrlKey) {
      return;
    }

    const { onClick, dismissAllModals, showModal } = this.props;

    const in15Mins = new Date(Date.now() + 1000 * 60 * 15);
    setCookieValue(
      'redirect_after_auth',
      getWindow().location.pathname + getWindow().location.search,
      in15Mins,
    );

    if (onClick) {
      onClick(e);
    }

    e.preventDefault();
    dismissAllModals();
    showModal(LoginModal);
  };

  render() {
    const { className, name, label, styleVariation, styleModifier, t } =
      this.props;

    return (
      <Button
        className={className}
        name={name}
        label={label || t('log_in')}
        styleVariation={styleVariation}
        styleModifier={styleModifier}
        href="/login"
        onClick={this.handleClick}
        tooltip={t('log_in_tooltip')}
      />
    );
  }
}

LoginButton.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  elementType: PropTypes.string,
  styleVariation: PropTypes.string,
  styleModifier: PropTypes.array,
  t: PropTypes.func,
  onClick: PropTypes.func,
  showModal: PropTypes.func.isRequired,
  dismissAllModals: PropTypes.func.isRequired,
};

LoginButton.defaultProps = {
  label: null,
  elementType: null,
  styleVariation: null,
  styleModifier: [],
};

export default connector(connectModal)(withT(LoginButton));
