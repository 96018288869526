import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Icon from './icon';

const StyledIcon = styled(Icon)({
  color: 'var(--color--icon)',
  g: {
    color: 'inherit',
    stroke: 'currentColor',
  },
});

function ReportIcon(props) {
  return (
    <StyledIcon
      name="report"
      size={props.size}
      viewBox="0 0 24 24"
      {...props}
      renderComponent={({ color }) => (
        <g fill="none" stroke={color}>
          <path
            d="M12 7.55556V12M12 16.4444H12.0111M22 12C22 13.3132 21.7413 14.6136 21.2388 15.8268C20.7363 17.0401 19.9997 18.1425 19.0711 19.0711C18.1425 19.9997 17.0401 20.7363 15.8268 21.2388C14.6136 21.7413 13.3132 22 12 22C10.6868 22 9.38642 21.7413 8.17317 21.2388C6.95991 20.7363 5.85752 19.9997 4.92893 19.0711C4.00035 18.1425 3.26375 17.0401 2.7612 15.8268C2.25866 14.6136 2 13.3132 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      )}
    />
  );
}

ReportIcon.propTypes = {
  size: PropTypes.number,
};

ReportIcon.defaultProps = {
  size: 18,
};

export default ReportIcon;
