import React from 'react';
import PropTypes from 'prop-types';
import Icon from './icon';

function PlusBulletIcon(props) {
  const { size, color, backgroundColor } = props;

  return (
    <Icon
      name="plus"
      viewBox="0 0 25 25"
      size={size}
      {...props}
      renderComponent={() => (
        <g id="Group-5">
          <circle id="Oval" fill={backgroundColor} cx="12" cy="12" r="12" />
          <g
            id="Group"
            transform="translate(6.000000, 6.000000)"
            stroke={color}
            strokeWidth="1.5"
          >
            <line
              x1="5.74667526"
              y1="0"
              x2="5.74667526"
              y2="11.4933505"
              id="Path-5"
            />
            <line
              x1="-9.09162994e-15"
              y1="5.74667526"
              x2="11.4933505"
              y2="5.74667526"
              id="Path-6"
            />
          </g>
        </g>
      )}
    />
  );
}

PlusBulletIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
};

PlusBulletIcon.defaultProps = {
  size: 25,
  color: '#F7F7F7',
  backgroundColor: '#F52828',
};

export default PlusBulletIcon;
