import { AxiosInstance } from 'axios';
import sentry from 'Utils/sentry';
import { USER_FEATURE_TYPES } from 'Webapp/action-types';
import { setPayloadReducer } from 'Webapp/utils/redux';

export enum USER_FEATURES {
  PACKAGE_MANAGEMENT = 'PACKAGE_MANAGEMENT',
  INTERNAL_PACKAGE_MANAGEMENT = 'INTERNAL_PACKAGE_MANAGEMENT',
  FAST_FLIP = 'FAST_FLIP',
}

type UserFeatures = Record<USER_FEATURES, boolean>;

const initialState = {
  PACKAGE_MANAGEMENT: false,
  INTERNAL_PACKAGE_MANAGEMENT: false,
  FAST_FLIP: false,
};

export const reducer = setPayloadReducer<
  typeof USER_FEATURE_TYPES,
  UserFeatures
>(USER_FEATURE_TYPES, initialState);

type APIFeatureKey =
  | 'packageManagement'
  | 'internalPackageManagement'
  | 'fastFlip';
enum MapAPIValue {
  packageManagement = USER_FEATURES.PACKAGE_MANAGEMENT,
  internalPackageManagement = USER_FEATURES.INTERNAL_PACKAGE_MANAGEMENT,
  fastFlip = USER_FEATURES.FAST_FLIP,
}
export const getUserFeaturesForUid = async function (
  uid: number,
  flap: AxiosInstance,
): Promise<UserFeatures> {
  const defaultFeaturesForUser = Object.keys(USER_FEATURES).reduce(
    (acc, key) => {
      acc[key as USER_FEATURES] = false;
      return acc;
    },
    {} as UserFeatures,
  );
  try {
    if (uid === 0) {
      return defaultFeaturesForUser;
    }
    const {
      data: { features },
    } = await flap.get<{
      features?: { [key in APIFeatureKey]: boolean };
    }>(`/flipboard/userFeatures/${uid}`);
    if (!features) {
      return defaultFeaturesForUser;
    }
    return Object.keys(features).reduce((acc, key) => {
      const apiKey: APIFeatureKey = key as APIFeatureKey;
      const mapped = MapAPIValue[apiKey] as unknown as USER_FEATURES;
      acc[mapped] = features[apiKey];
      return acc;
    }, {} as UserFeatures);
  } catch (e) {
    sentry.captureException(e as Error);
    return defaultFeaturesForUser;
  }
};

// actions
export const getFeatures =
  (uid = 0): Flipboard.Thunk =>
  async (dispatch, _, { flap }) => {
    try {
      const features = await getUserFeaturesForUid(uid, flap);
      dispatch({
        type: USER_FEATURE_TYPES.SET_USER_FEATURES,
        payload: features,
      });
    } catch (e) {
      sentry.captureException(e as Error);
    }
  };

// selectors
export const getActiveUserFeatureFlags = ({ userFeatures }: Flipboard.State) =>
  Object.keys(userFeatures).filter((key) => userFeatures[key as USER_FEATURES]);
