import connector, { Connector } from 'Webapp/utils/connector';
import { setRouting } from 'Webapp/shared/app/redux/actions/app';
import TopLevelRoute from 'Webapp/shared/app/components/top-level-route';

const actions = {
  setRouting,
};

export type TopLevelRouteContainerProps = Flipboard.ConnectorProps<
  unknown,
  typeof actions
>;

const topLevelRouteConnector: Connector = {
  actions,
};

const TopLevelRouteContainer = connector(topLevelRouteConnector)(TopLevelRoute);

export default TopLevelRouteContainer;
