import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

// Utils
import HocUtil from 'Utils/hoc-util';
import { getCurrentArticleRemoteId } from '../redux/selectors/article';
import { getSocialActivity } from 'Webapp/shared/concepts/social-activity';

// Components
import { SocialActionType } from 'Webapp/enums';
import withNavFrom from '../hocs/withNavFrom';
import withSocialActionGate from '../hocs/withSocialActionGate';

import connector from 'Utils/connector';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';

// Actions
import {
  likeItem,
  unlikeItem,
} from 'Webapp/shared/app/redux/actions/social-actions';
import { usageTrackTapLikeItem } from 'Webapp/shared/app/redux/actions/usage-actions';

function withLike(Component) {
  class WrappedComponent extends React.Component {
    constructor(props) {
      super(props);

      this.handleLike = this.handleLike.bind(this);
    }

    handleLike() {
      const {
        item,
        section,
        isAuthenticated,
        uid,
        likeItem,
        unlikeItem,
        navFrom,
        showSocialActionGate,
        usageTrackTapLikeItem,
        addPostOnboardingSocialAction,
        isLiked,
      } = this.props;
      if (isAuthenticated) {
        if (item) {
          usageTrackTapLikeItem(section, item);
          const likeFn = isLiked ? unlikeItem : likeItem;
          likeFn(item, uid, section, navFrom);
        }
      } else {
        addPostOnboardingSocialAction({
          like: { item, section, navFrom },
        });
        showSocialActionGate(SocialActionType.LIKE);
      }
    }

    render() {
      return <Component {...this.props} onLike={this.handleLike} />;
    }
  }

  // Proxy the wrapped component displayName
  WrappedComponent.displayName = HocUtil.displayName(Component);
  WrappedComponent.propTypes = {
    item: PropTypes.object,
    section: PropTypes.object,
    isAuthenticated: PropTypes.bool.isRequired,
    uid: PropTypes.number.isRequired,
    likeItem: PropTypes.func.isRequired,
    unlikeItem: PropTypes.func.isRequired,
    addPostOnboardingSocialAction: PropTypes.func.isRequired,
    articleRemoteId: PropTypes.string,
    navFrom: PropTypes.string,
    showSocialActionGate: PropTypes.func.isRequired,
    usageTrackTapLikeItem: PropTypes.func.isRequired,
    isLiked: PropTypes.bool.isRequired,
  };

  WrappedComponent.defaultProps = {
    section: null,
    articleRemoteId: null,
    navFrom: null,
  };

  const mapStateToProps = (state, { isAuthenticated, item }) => {
    const { socialActivity } = state;
    const itemSocialActivity = getSocialActivity(
      item?.flipboardSocialId,
      socialActivity,
    );
    return {
      articleRemoteId:
        (isAuthenticated && getCurrentArticleRemoteId(state)) || null,
      isLiked: itemSocialActivity.isLiked,
    };
  };

  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      { likeItem, unlikeItem, usageTrackTapLikeItem },
      dispatch,
    );

  return connector(connectAuthentication)(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(withSocialActionGate(withNavFrom(WrappedComponent))),
  );
}

export default withLike;
