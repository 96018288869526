import { NamedExoticComponent } from 'react';
import { MODAL_TYPES } from 'Webapp/action-types';

interface Modal {
  // we have to use NamedExoticComponent because we need displayName
  component: NamedExoticComponent;
  props?: Record<string, unknown>;
}

interface ModalReducerState {
  current: Array<Modal>;
  modalLoginComplete: boolean;
}

const initialState: ModalReducerState = {
  current: [],
  modalLoginComplete: false,
};

export default function modalReducer(
  state: ModalReducerState = initialState,
  action: { type: MODAL_TYPES; payload?: Modal },
) {
  switch (action.type) {
    case MODAL_TYPES.SHOW_MODAL: {
      return Object.assign({}, state, {
        current: [...state.current, action.payload],
      });
    }
    case MODAL_TYPES.DISMISS_MODAL: {
      return Object.assign({}, state, { current: state.current.slice(0, -1) });
    }
    case MODAL_TYPES.DISMISS_SPECIFIC_MODAL: {
      return Object.assign({}, state, {
        current: state.current.filter(
          (m) => m.component !== action.payload?.component,
        ),
      });
    }
    case MODAL_TYPES.DISMISS_ALL_MODALS: {
      return Object.assign({}, state, { current: [] });
    }
    case MODAL_TYPES.SET_MODAL_LOGIN_COMPLETE: {
      return Object.assign({}, state, { modalLoginComplete: true });
    }
    default:
      return state;
  }
}
