import React, { Component } from 'react';
import connector from 'Utils/connector';
import connectMute, {
  ConnectMuteProps,
} from 'Webapp/shared/app/connectors/connectMute';
import withT from 'ComponentLibrary/hocs/withT';

import { MuteItemType, Services } from 'Webapp/enums';

interface MuteSourceTagPassedProps {
  item: Flipboard.Item;
  type: MuteItemType;
}

type MuteSourceTagProps = ConnectMuteProps &
  MuteSourceTagPassedProps & { t: Flipboard.TFunction };

class MuteSourceTag extends Component<MuteSourceTagProps> {
  mute = async () => {
    const { item, type, muteSourceDomain, muteAuthor } = this.props;
    if (type === 'sourceDomain') {
      await muteSourceDomain(item.sourceDomain);
    }
    if (type === 'author') {
      const mutableAuthor: Flipboard.MutableAuthor = {
        username: item.author.authorUsername,
        authorDisplayName: item.author.authorUsername,
        userid: item.author.userid,
        service: Services.FLIPBOARD,
      };
      await muteAuthor(mutableAuthor);
    }
  };

  render() {
    const { item, type, t } = this.props;
    const label =
      type === 'sourceDomain'
        ? item.sourceDomain
        : item.author.authorDisplayName || item.author.authorUsername;
    return <span onClick={this.mute}>{t('mute_label', { label })}</span>;
  }
}

export default connector(connectMute)(withT(MuteSourceTag));
