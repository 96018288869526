import { ANALYTICS_TYPES } from 'Webapp/action-types';

interface AnalyticsHomeReducerState {
  allStoryboardMetrics: null | unknown;
  allMagazineMetrics: null | unknown;
  storyboardMetrics: Array<unknown>;
  magazineMetrics: Array<unknown>;
  isLoading: boolean;
}

const initialState: AnalyticsHomeReducerState = {
  allStoryboardMetrics: null,
  allMagazineMetrics: null,
  storyboardMetrics: [],
  magazineMetrics: [],
  isLoading: false,
};

export default function homeAnalyticsReducer(state = initialState, action) {
  switch (action.type) {
    case ANALYTICS_TYPES.GET_HOME_ANALYTICS_SUCCESS: {
      return Object.assign({}, state, {
        allStoryboardMetrics: action.allStoryboardMetrics,
        storyboardMetrics: action.storyboardMetrics,
        allMagazineMetrics: action.allMagazineMetrics,
        magazineMetrics: action.magazineMetrics,
        isLoading: false,
      });
    }
    case ANALYTICS_TYPES.GET_HOME_ANALYTICS_FAILED: {
      return Object.assign({}, state, { isLoading: false });
    }
    case ANALYTICS_TYPES.GET_HOME_ANALYTICS: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }
    default:
      return state;
  }
}
