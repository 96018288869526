import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withT from 'ComponentLibrary/hocs/withT';
import Input from 'Webapp/shared/app/components/base/input';
import Magazine from './magazine';

import connector from 'Utils/connector';
import connectModal from 'Webapp/shared/app/connectors/connectModal';
import connectCurrentUser from 'Webapp/shared/app/connectors/connectCurrentUser';
import connectFlipMode from 'Webapp/shared/app/connectors/connectFlipMode';

/**
 * Returns true if query exists in magazine title
 * @param {Object} magazine - Flipboard magazine
 * @param {String} query - Search term
 */
function queryMatchesMagTitle(magazine, query = '') {
  const title = (magazine.title && magazine.title.toLowerCase()) || '';
  return title.includes(query.toLowerCase());
}

class FlipModeMagazinePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: '',
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleClickMagazine = this.handleClickMagazine.bind(this);
  }

  handleSearchChange(e) {
    this.setState({ searchTerm: e.target.value });
  }

  handleClickMagazine(selectedMagazine) {
    this.props.setTargetRemoteId(selectedMagazine.remoteid);
    this.props.onSelect();
  }

  render() {
    const { allUserMagazines, t } = this.props;
    const { searchTerm } = this.state;

    const filteredMagazines = searchTerm
      ? allUserMagazines.filter((m) => queryMatchesMagTitle(m, searchTerm))
      : allUserMagazines;

    return (
      <div className="magazine-picker">
        <div className="magazine-picker__input-wrapper">
          <Input
            name="magazine-picker-magazine-search"
            placeholder={t('magazine_search_placeholder')}
            value={this.state.searchTerm}
            onChange={this.handleSearchChange}
          />
        </div>
        <ul className="magazine-picker__magazine-list">
          {filteredMagazines.map((magazine) => (
            <Magazine
              magazine={magazine}
              onClick={this.handleClickMagazine}
              key={magazine.remoteid}
            />
          ))}
        </ul>
      </div>
    );
  }
}

FlipModeMagazinePicker.propTypes = {
  t: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  allUserMagazines: PropTypes.array.isRequired,
  flipModeTargetMagazine: PropTypes.object,
  setTargetRemoteId: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

FlipModeMagazinePicker.defaultProps = { profile: null };

export default connector(
  connectFlipMode,
  connectCurrentUser,
  connectModal,
)(withT(FlipModeMagazinePicker));
