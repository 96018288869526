import React from 'react';

type PlayStoreProps = {
  className?: string;
  scale?: number;
};

const PlayStore: React.FC<PlayStoreProps> = ({ className, scale = 1 }) => (
  <svg
    className={className}
    version="1.1"
    id="artwork"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 135 40"
    height={45 * scale}
    width={135 * scale}
  >
    <g>
      <g>
        <path d="M130,40H5c-2.8,0-5-2.2-5-5V5c0-2.8,2.2-5,5-5h125c2.8,0,5,2.2,5,5v30C135,37.8,132.8,40,130,40z" />
      </g>
      <g>
        <g>
          <path
            fill="#a6a6a6"
            d="M130,0.8c2.3,0,4.2,1.9,4.2,4.2v30c0,2.3-1.9,4.2-4.2,4.2H5c-2.3,0-4.2-1.9-4.2-4.2V5c0-2.3,1.9-4.2,4.2-4.2
            H130 M130,0H5C2.2,0,0,2.3,0,5v30c0,2.8,2.2,5,5,5h125c2.8,0,5-2.2,5-5V5C135,2.3,132.8,0,130,0L130,0z"
          />
        </g>
      </g>
      <g>
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth="0.2"
          strokeMiterlimit="10"
          d="M47.4,10.2c0,0.8-0.2,1.5-0.7,2c-0.6,0.6-1.3,0.9-2.2,0.9c-0.9,0-1.6-0.3-2.2-0.9c-0.6-0.6-0.9-1.3-0.9-2.2
          s0.3-1.6,0.9-2.2c0.6-0.6,1.3-0.9,2.2-0.9c0.4,0,0.9,0.1,1.2,0.2c0.4,0.2,0.7,0.4,1,0.7l-0.6,0.6c-0.2-0.2-0.4-0.4-0.7-0.5
          c-0.3-0.1-0.6-0.2-0.9-0.2c-0.7,0-1.2,0.2-1.7,0.7c-0.4,0.5-0.7,1-0.7,1.7c0,0.7,0.2,1.3,0.7,1.7c0.5,0.5,1,0.7,1.7,0.7
          c0.6,0,1.1-0.2,1.5-0.5c0.4-0.3,0.6-0.8,0.7-1.4h-2.2V9.8h2.9C47.4,9.9,47.4,10.1,47.4,10.2z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth="0.2"
          strokeMiterlimit="10"
          d="M52,7v0.7h-2.7v1.9h2.5v0.7h-2.5v1.9H52V13h-3.5V7H52z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth="0.2"
          strokeMiterlimit="10"
          d="M55.3,7.7V13h-0.8V7.7h-1.7V7H57v0.7H55.3z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth="0.2"
          strokeMiterlimit="10"
          d="M60.7,13h-0.8V7h0.8V13z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth="0.2"
          strokeMiterlimit="10"
          d="M64.1,7.7V13h-0.8V7.7h-1.7V7h4.1v0.7H64.1z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth="0.2"
          strokeMiterlimit="10"
          d="M74.5,10c0,0.9-0.3,1.6-0.9,2.2c-0.6,0.6-1.3,0.9-2.2,0.9c-0.9,0-1.6-0.3-2.2-0.9c-0.6-0.6-0.9-1.3-0.9-2.2
          c0-0.9,0.3-1.6,0.9-2.2c0.6-0.6,1.3-0.9,2.2-0.9c0.9,0,1.6,0.3,2.2,0.9C74.2,8.4,74.5,9.1,74.5,10z M69.1,10
          c0,0.7,0.2,1.3,0.7,1.7c0.4,0.5,1,0.7,1.6,0.7c0.7,0,1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.7S73.5,8.7,73,8.3
          c-0.4-0.5-1-0.7-1.6-0.7c-0.7,0-1.2,0.2-1.6,0.7C69.3,8.7,69.1,9.3,69.1,10z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth="0.2"
          strokeMiterlimit="10"
          d="M76.3,13h-0.8V7h0.9l2.9,4.7h0l0-1.2V7h0.8v6h-0.8l-3.1-4.9h0l0,1.2V13z"
        />
      </g>
      <path
        fill="#ffffff"
        d="M68.1,21.8c-2.4,0-4.3,1.8-4.3,4.3c0,2.4,1.9,4.3,4.3,4.3c2.4,0,4.3-1.8,4.3-4.3
        C72.4,23.5,70.5,21.8,68.1,21.8z M68.1,28.6c-1.3,0-2.4-1.1-2.4-2.6c0-1.5,1.1-2.6,2.4-2.6s2.4,1,2.4,2.6
        C70.5,27.5,69.4,28.6,68.1,28.6z M58.8,21.8c-2.4,0-4.3,1.8-4.3,4.3c0,2.4,1.9,4.3,4.3,4.3c2.4,0,4.3-1.8,4.3-4.3
        C63.1,23.5,61.2,21.8,58.8,21.8z M58.8,28.6c-1.3,0-2.4-1.1-2.4-2.6c0-1.5,1.1-2.6,2.4-2.6c1.3,0,2.4,1,2.4,2.6
        C61.2,27.5,60.1,28.6,58.8,28.6z M47.7,23.1v1.8h4.3c-0.1,1-0.5,1.8-1,2.3c-0.6,0.6-1.6,1.3-3.3,1.3c-2.7,0-4.7-2.1-4.7-4.8
        c0-2.7,2.1-4.8,4.7-4.8c1.4,0,2.5,0.6,3.3,1.3l1.3-1.3c-1.1-1-2.5-1.8-4.5-1.8c-3.6,0-6.7,3-6.7,6.6c0,3.6,3.1,6.6,6.7,6.6
        c2,0,3.4-0.6,4.6-1.9c1.2-1.2,1.6-2.9,1.6-4.2c0-0.4,0-0.8-0.1-1.1H47.7z M93.1,24.5c-0.4-1-1.4-2.7-3.6-2.7c-2.2,0-4,1.7-4,4.3
        c0,2.4,1.8,4.3,4.2,4.3c1.9,0,3.1-1.2,3.5-1.9l-1.4-1c-0.5,0.7-1.1,1.2-2.1,1.2c-1,0-1.6-0.4-2.1-1.3l5.7-2.4L93.1,24.5z
         M87.3,25.9c0-1.6,1.3-2.5,2.2-2.5c0.7,0,1.4,0.4,1.6,0.9L87.3,25.9z M82.6,30h1.9V17.5h-1.9V30z M79.6,22.7L79.6,22.7
        c-0.5-0.5-1.3-1-2.3-1c-2.1,0-4.1,1.9-4.1,4.3c0,2.4,1.9,4.2,4.1,4.2c1,0,1.8-0.5,2.2-1h0.1v0.6c0,1.6-0.9,2.5-2.3,2.5
        c-1.1,0-1.9-0.8-2.1-1.5l-1.6,0.7c0.5,1.1,1.7,2.5,3.8,2.5c2.2,0,4-1.3,4-4.4V22h-1.8V22.7z M77.4,28.6c-1.3,0-2.4-1.1-2.4-2.6
        c0-1.5,1.1-2.6,2.4-2.6c1.3,0,2.3,1.1,2.3,2.6C79.7,27.5,78.7,28.6,77.4,28.6z M101.8,17.5h-4.5V30h1.9v-4.7h2.6
        c2.1,0,4.1-1.5,4.1-3.9C105.9,19,103.9,17.5,101.8,17.5z M101.9,23.5h-2.7v-4.3h2.7c1.4,0,2.2,1.2,2.2,2.1
        C104,22.4,103.2,23.5,101.9,23.5z M113.4,21.7c-1.4,0-2.8,0.6-3.3,1.9l1.7,0.7c0.4-0.7,1-0.9,1.7-0.9c1,0,1.9,0.6,2,1.6v0.1
        c-0.3-0.2-1.1-0.5-1.9-0.5c-1.8,0-3.6,1-3.6,2.8c0,1.7,1.5,2.8,3.1,2.8c1.3,0,1.9-0.6,2.4-1.2h0.1v1h1.8v-4.8
        C117.2,23,115.5,21.7,113.4,21.7z M113.2,28.6c-0.6,0-1.5-0.3-1.5-1.1c0-1,1.1-1.3,2-1.3c0.8,0,1.2,0.2,1.7,0.4
        C115.2,27.8,114.2,28.6,113.2,28.6z M123.7,22l-2.1,5.4h-0.1l-2.2-5.4h-2l3.3,7.6l-1.9,4.2h1.9l5.1-11.8H123.7z M106.9,30h1.9V17.5
        h-1.9V30z"
      />
      <g>
        <path
          fill="#ea4335"
          d="M20.7,19.4L10.1,30.7c0,0,0,0,0,0c0.3,1.2,1.4,2.1,2.8,2.1c0.5,0,1-0.1,1.5-0.4l0,0l12-6.9L20.7,19.4z"
        />
        <path
          fill="#fbbc04"
          d="M31.5,17.5L31.5,17.5l-5.2-3l-5.8,5.2l5.8,5.8l5.1-3c0.9-0.5,1.5-1.4,1.5-2.5C33,18.9,32.4,18,31.5,17.5z"
        />
        <path
          fill="#4285f4"
          d="M10.1,9.3C10,9.5,10,9.8,10,10v20c0,0.3,0,0.5,0.1,0.7l11-11L10.1,9.3z"
        />
        <path
          fill="#34a853"
          d="M20.8,20l5.5-5.5l-12-6.9c-0.4-0.3-0.9-0.4-1.5-0.4c-1.3,0-2.5,0.9-2.8,2.1c0,0,0,0,0,0L20.8,20z"
        />
      </g>
    </g>
  </svg>
);

export default PlayStore;
