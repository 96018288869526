import React from 'react';
import styled from '@emotion/styled';
import Icon from './icon';

const StyledIcon = styled(Icon)({
  color: 'var(--color--icon)',
  g: {
    color: 'inherit',
    stroke: 'currentColor',
  },
});
function MuteIcon(props) {
  return (
    <StyledIcon
      name="mute"
      size={18}
      viewBox="0 0 24 24"
      {...props}
      renderComponent={({ color }) => (
        <g fill="none" stroke={color}>
          <path
            d="M20.25 12C20.25 16.5563 16.5563 20.25 12 20.25C7.44365 20.25 3.75 16.5563 3.75 12C3.75 7.44365 7.44365 3.75 12 3.75C16.5563 3.75 20.25 7.44365 20.25 12Z"
            strokeWidth="1.5"
          />
          <line
            x1="6.47951"
            y1="17.0628"
            x2="17.4795"
            y2="6.46001"
            strokeWidth="1.5"
          />
        </g>
      )}
    />
  );
}

export default MuteIcon;
