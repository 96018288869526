import { USAGE_TYPES } from 'Webapp/action-types';
import { set, type } from 'Utils/redux';
import getWindow from 'Utils/get-window';
import { revision } from 'Version';
import ViewportTypes from 'Utils/viewport-types';
import GlobalVars from 'Utils/global-vars';
import FlabContent from 'Webapp/shared/content/flab';
import { didBacktrack, getPreviousUrl } from 'Utils/history';
import logger from 'Utils/logger';
import Config from 'Webapp/shared/config';
import { getCookieValue } from 'Utils/client-cookie';
import sentry from 'Utils/sentry';
import {
  USAGE_PROD_TYPES,
  HEARTBEAT_DURATION,
  USAGE_EVENT_NAMES,
  USAGE_NAV_FROMS,
  USAGE_REACHED_END_TYPES,
  USAGE_APP_ENTER_TYPES,
  USAGE_GENERAL_DISPLAY_TYPES,
  USAGE_SIGNUP_METHODS,
  usageActivationEventData,
  usageItemEventData,
  usageSectionEventData,
  magazineInviteContributorEventData,
  usageProfileMagazineCount,
  usageWidgetEventData,
  usageSectionIdForTracking,
  usageSsidForTracking,
  usageSectionAuthorEventData,
  usageCuratorProStoryboardPublishData,
  requestUsageSession,
  sendUsageEvent,
  RECAPTCHA_SCORE_COOKIE_NAME,
  getSubscribedKeys,
} from 'Utils/analytics/usage';

import {
  SendUsageEventTimedOutError,
  TrackSignupEnterError,
  TrackSignupExitError,
  TrackOnboardEnterError,
  TrackOnboardExitError,
  TrackSignupActivatedError,
  TrackSignupExitRedirectError,
  TrackPublisherSignupEnterError,
  TrackPublisherSignupExitError,
} from 'Utils/errors';
import { isHomeRoute } from 'Webapp/shared/app/redux/selectors/routing';

const getNavFromValue = (key, state) => {
  const {
    usage: { navFrom },
  } = state;

  return navFrom[key];
};

export const setFlurl = (flurl) =>
  set(USAGE_TYPES.USAGE_SET_FLURL, 'flurl', flurl);

export const usageSetMobileGateArticle = (article) =>
  set(USAGE_TYPES.USAGE_SET_MOBILE_GATE_ARTICLE, 'mobileGateArticle', article);

export const usageSetMobileGateSection = (section) =>
  set(USAGE_TYPES.USAGE_SET_MOBILE_GATE_SECTION, 'mobileGateSection', section);

const enqueueEvent = (event) => ({
  type: USAGE_TYPES.USAGE_ENQUEUE_EVENT,
  payload: { event },
});

// This is primarily for users who are verified via email confirmation and went through
// the frictionless flow (newsletters or newsletter-landing page)
const frictionlessConfirmationFlow = (state) => {
  const { profile, app } = state;

  if (isHomeRoute(state)) {
    return USAGE_SIGNUP_METHODS.HOME;
  }
  if (app.isFromInvitation) {
    return USAGE_SIGNUP_METHODS.INVITATION;
  }

  if (profile?.userInfo?.frictionless && app.isFromConfirmation) {
    return USAGE_SIGNUP_METHODS.EMAIL_CONFIRMATION;
  }
};

export const usageBootstrap =
  (getSession, setSession) => async (dispatch, getState) => {
    const {
      app: { isIntegration },
    } = getState();
    let session = await getSession();
    if (setSession && !isIntegration) {
      session = await requestUsageSession(session);
      await setSession(session);
    }
    dispatch(usageSetSession(session));
  };

const getProperties = (state) => {
  const {
    usage: { session, enableExperiments },
    app: { viewportType, locale, seoExperiments, experiments, isIntegration },
    auth: { uid },
  } = state;

  const props = {
    uid,
    appmode: ViewportTypes.getUsageAppMode(viewportType),
    locale,
    win_size:
      getWindow() &&
      `${getWindow().innerWidth || 0}x${getWindow().innerHeight || 0}`,
  };

  if (!isIntegration) {
    props.time = Math.round(Date.now());
    props.time_offset = -new Date().getTimezoneOffset();
    props.git_version = revision;
    props.app_version = Config.VERSION;
  }

  if (session) {
    props.unique_id = session.unique_id;
    props.session_id = session.session_id;
  }

  if (isIntegration || !enableExperiments) {
    return props;
  }
  const abTests = FlabContent.experimentsForTracking(experiments);
  if (abTests) {
    props.ab_tests = abTests;
  }
  if (seoExperiments) {
    props.variant = seoExperiments
      .map(({ experiment, group }) => `ab_seo_${experiment}_${group}`)
      .join(',');
  }
  return props;
};

/**
 * Emits a usage event
 * @param {Object} event - Usage event
 * @param {Object} customErrorType - Custom error type to emit if an exception occurs
 */
const emitUsageEvent =
  (event, customErrorType = null) =>
  async (dispatch, getState) => {
    const {
      usage: { flurl, referrer },
    } = getState();
    let data = event;
    const appendEventData = (key, value) => {
      if (value && data && data.event_data) {
        data = Object.assign({}, data, {
          event_data: {
            ...data.event_data,
            [key]: value,
          },
        });
      }
    };
    appendEventData('refer_url', referrer);
    appendEventData('flurl', flurl);
    const result = await sendUsageEvent(
      data,
      customErrorType,
      USAGE_PROD_TYPES.WEB,
    );
    if (
      GlobalVars.isDevelopment &&
      result &&
      result.status === 200 &&
      result.data &&
      result.data.result &&
      result.data.result === 1
    ) {
      dispatch({
        type: USAGE_TYPES.USAGE_DEVELOPMENT_EVENT_LOG,
        payload: data,
      });
    }
    return result;
  };

/**
 * Emits a usage event decorated with session properties
 * @param {Object} event - Usage event
 * @param {Object} customErrorType - Custom error type to emit if an exception occurs
 */
const emitUsageEventWithSession =
  (event, customErrorType = null) =>
  (dispatch, getState) => {
    const properties = getProperties(getState());
    const eventWithProps = Object.assign({}, event, { properties });
    return dispatch(emitUsageEvent(eventWithProps, customErrorType));
  };

export const sendUsageEventWithSession =
  (event, customErrorType = null) =>
  async (dispatch, getState) => {
    const {
      app: { isFromCrawler },
      usage: { trackingEnabled },
    } = getState();
    if (isFromCrawler) {
      if (GlobalVars.isDevelopment) {
        logger.info('Not sending usage event for crawler', event);
      }
      return;
    }

    // Tracking is enabled, emit immediately
    if (trackingEnabled || GlobalVars.isServer()) {
      return dispatch(emitUsageEventWithSession(event, customErrorType));
    }

    dispatch(enqueueEvent(event));
    // This can be a "fire and forget action", or you can choose to
    // await the returned promise if you want to be sure your event is
    // sent before continuing (useful for recording something before
    // redirecting, etc)
    try {
      await dispatch(waitOnUsageQueue);
    } catch (e) {
      if (e instanceof Error) {
        sentry.captureException(e);
      }
    }
    return;
  };

const waitOnUsageQueue = (_dispatch, getState) => {
  let enabledCheckCount = 0;
  const maxChecks = 500;
  return new Promise((resolve, reject) => {
    const interval = getWindow().setInterval(() => {
      const {
        usage: { trackingEnabled, eventQueue },
      } = getState();
      const eventSent = trackingEnabled && eventQueue.length === 0;
      if (eventSent || ++enabledCheckCount > maxChecks) {
        getWindow().clearInterval(interval);
        return eventSent
          ? resolve(true)
          : reject(new SendUsageEventTimedOutError());
      }
    }, 10);
  });
};

export const usageSetProdType = set(
  USAGE_TYPES.USAGE_SET_PROD_TYPE,
  'prodType',
);
export const usageSetReferrer = set(USAGE_TYPES.USAGE_SET_REFERRER, 'referrer');
export const usageEnableTracking = () => (dispatch, getState) => {
  dispatch(set(USAGE_TYPES.USAGE_ENABLE_TRACKING, 'trackingEnabled', true));
  const {
    usage: { eventQueue },
  } = getState();
  eventQueue.forEach((event) => dispatch(emitUsageEventWithSession(event)));
  dispatch(type(USAGE_TYPES.USAGE_EMPTY_QUEUE));
};

export const usageSetNavFrom = (eventName, value) => ({
  type: USAGE_TYPES.USAGE_SET_NAV_FROM,
  payload: { eventName, value },
});

const usageSetSession = set(USAGE_TYPES.USAGE_SET_SESSION, 'session');

// events
export const usageTrackAppEnter = (urlPath, type) => (dispatch) => {
  if (urlPath.match(/^\/video-player/)) {
    dispatch(usageSetProdType(USAGE_PROD_TYPES.SAMSUNG_DAILY));
  } else if (urlPath.match(/\/widget$/)) {
    dispatch(usageSetProdType(USAGE_PROD_TYPES.WIDGET));
  }

  const data = { url: urlPath };
  if (type) {
    data.type = type;
  }

  const event = {
    event_action: 'enter',
    event_category: 'app',
    event_data: data,
  };

  return dispatch(sendUsageEventWithSession(event));
};

/**
 * Resets the navFrom value for a given event
 * @param {String} eventName     - Name of the event to clear
 */
const usageClearNavFrom = (eventName) => (dispatch) => {
  // NOTE: Hack to make mobile content gate and social gates
  // work without clobbering each other's nav_from values
  if (eventName !== USAGE_EVENT_NAMES.DISPLAY_MOBILE_GATE) {
    return dispatch(usageSetNavFrom(eventName, null));
  }
};

/**
 * Tracks enters for items and sections.  Provides centralized
 * management of "isFirstPageLoad" variable so that actual
 * browser forward/back navigations can be distinguished from
 * first page loads.
 * @property {Object} event  - usage event object.  Either section/enter or item/enter
 * @param {Object} history    - React router history object
 */
const trackViewEnter =
  (enterEvent, history = null) =>
  (dispatch) => {
    const { event_data } = enterEvent;
    const navFromValue = event_data && event_data.nav_from;

    if (!navFromValue && didBacktrack(history)) {
      // Browser back/forward button navigation
      enterEvent.event_data.nav_from = USAGE_NAV_FROMS.BACKTRACKING;
    }

    // If the history object has a previousUrl value, override refer_url with that.
    // Intended to track client-side navigation behavior
    const previousUrl = getPreviousUrl(history);
    if (previousUrl) {
      enterEvent.event_data.refer_url = previousUrl;
    }

    return dispatch(sendUsageEventWithSession(enterEvent));
  };

const usageSetSectionHeartbeat = set(
  USAGE_TYPES.USAGE_SET_SECTION_HEARTBEAT,
  'sectionHeartbeat',
);

export const usageStartSectionHeartbeat = (section) => (dispatch) => {
  dispatch(
    usageSetSectionHeartbeat({
      section,
      start: new Date().getTime(),
      tap_count: 0,
      number_items: 0,
    }),
  );
  dispatch(usageScheduleSectionHeartbeat());
};

const usageSetSectionHeartbeatTimerId = set(
  USAGE_TYPES.USAGE_SET_SECTION_HEARTBEAT_TIMER_ID,
  'sectionHeartbeatTimerId',
);

const usageUpdateSectionHeartbeat = (key, value) => (dispatch, getState) => {
  const {
    usage: { sectionHeartbeat },
  } = getState();
  const updatedSectionHeartbeat = {
    ...sectionHeartbeat,
    [key]: value,
  };
  dispatch(
    set(
      USAGE_TYPES.USAGE_UPDATE_SECTION_HEARTBEAT,
      'sectionHeartbeat',
      updatedSectionHeartbeat,
    ),
  );
};

const usageIncrementSectionHeartbeatValue =
  (key) => () => (dispatch, getState) => {
    const {
      usage: { sectionHeartbeat },
    } = getState();
    if (!sectionHeartbeat) {
      return;
    }
    const newValue = sectionHeartbeat[key] + 1;
    dispatch(usageUpdateSectionHeartbeat(key, newValue));
  };

export const usageIncrementSectionHeartbeatItemsDisplayed =
  usageIncrementSectionHeartbeatValue('number_items');
export const usageIncrementSectionHeartbeatTapCount =
  usageIncrementSectionHeartbeatValue('tap_count');

const usageScheduleSectionHeartbeat = () => (dispatch, getState) => {
  const {
    usage: { sectionHeartbeat, sectionHeartbeatTimerId },
  } = getState();
  getWindow().clearTimeout(sectionHeartbeatTimerId);
  const timerId = getWindow().setTimeout(() => {
    if (dispatch(usageSendSectionHeartbeat(sectionHeartbeat))) {
      dispatch(usageStartSectionHeartbeat(sectionHeartbeat.section));
    } else {
      dispatch(usageScheduleSectionHeartbeat(sectionHeartbeat));
    }
  }, HEARTBEAT_DURATION);
  dispatch(usageSetSectionHeartbeatTimerId(timerId));
};

const usageSendSectionHeartbeat = (heartbeat) => (dispatch, getState) => {
  if (!heartbeat) {
    return false;
  }
  const { tap_count, number_items, section, start } = heartbeat;
  if (tap_count === 0 && number_items === 0) {
    return false;
  }
  const time_spent = new Date().getTime() - start;
  const event_data = usageSectionEventData(section, {
    tap_count,
    number_items,
    time_spent,
  });

  const navFromValue = getNavFromValue(
    USAGE_EVENT_NAMES.SECTION_HEARTBEAT,
    getState(),
  );
  if (navFromValue) {
    event_data.nav_from = navFromValue;
  }

  return dispatch(
    sendUsageEventWithSession({
      event_action: 'heartbeat',
      event_category: 'section',
      event_data,
    }),
  );
};

const usageUpdateSectionDisplayedItems = (value) =>
  set(
    USAGE_TYPES.USAGE_UPDATE_SECTION_DISPLAYED_ITEMS,
    'sectionDisplayedItems',
    value,
  );

const usageClearSectionDisplayedItems = () =>
  set(
    USAGE_TYPES.USAGE_UPDATE_SECTION_DISPLAYED_ITEMS,
    'sectionDisplayedItems',
    {},
  );

export const usageTrackDisplayItem = (item) => (dispatch, getState) => {
  const {
    usage: { sectionHeartbeat, navFromSection, sectionDisplayedItems },
  } = getState();
  if (sectionDisplayedItems[item.id]) {
    return;
  }
  dispatch(
    usageUpdateSectionDisplayedItems({
      ...sectionDisplayedItems,
      [item.id]: true,
    }),
  );
  return dispatch(
    sendUsageEventWithSession({
      event_category: 'section',
      event_action: 'display_item',
      event_data: {
        ...usageItemEventData(navFromSection, item),
        ssid: usageSsidForTracking(sectionHeartbeat?.section),
        section_id: usageSectionIdForTracking(sectionHeartbeat?.section),
      },
    }),
  );
};

export const usageTrackSectionEnter =
  (section, history, isOwnProfile = false) =>
  (dispatch, getState) => {
    if (!section) {
      return;
    }

    const data = isOwnProfile
      ? Object.assign(usageSectionEventData(section), {
          section_id: section.remoteid,
        })
      : usageSectionEventData(section);

    const navFromValue = getNavFromValue(
      USAGE_EVENT_NAMES.SECTION_ENTER,
      getState(),
    );
    if (navFromValue) {
      data.nav_from = navFromValue;
      dispatch(
        usageSetNavFrom(USAGE_EVENT_NAMES.SECTION_HEARTBEAT, navFromValue),
      );
      dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.SECTION_ENTER));
    }

    data.url = getWindow().document.location.href;

    const event = {
      event_action: 'enter',
      event_category: 'section',
      event_data: data,
    };

    const promise = dispatch(trackViewEnter(event, history));
    dispatch(usageClearSectionDisplayedItems());
    dispatch(usageStartSectionHeartbeat(section));
    return promise;
  };

export const usageTrackSectionExit = () => (dispatch, getState) => {
  const {
    usage: { sectionHeartbeat, sectionHeartbeatTimerId },
  } = getState();
  getWindow().clearTimeout(sectionHeartbeatTimerId);
  dispatch(usageSendSectionHeartbeat(sectionHeartbeat));
  dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.SECTION_HEARTBEAT));
  dispatch(usageSetSectionHeartbeat(null));
};

export const usageSetNavFromSection = (section) =>
  set(USAGE_TYPES.USAGE_SET_NAV_FROM_SECTION, 'navFromSection', section);

export const usageTrackItemEnter =
  (item, history = null) =>
  (dispatch, getState) => {
    if (!item) {
      return;
    }
    const {
      usage: { navFromSection },
    } = getState();

    // core required data
    const data = usageItemEventData(navFromSection, item);
    dispatch(usageSetNavFromSection(null));

    const navFromValue = getNavFromValue(
      USAGE_EVENT_NAMES.ITEM_ENTER,
      getState(),
    );
    if (navFromValue) {
      data.nav_from = navFromValue;
      dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.ITEM_ENTER));
    }

    const event = {
      event_action: 'enter',
      event_category: 'item',
      event_data: data,
    };

    dispatch(usageIncrementSectionHeartbeatTapCount());
    dispatch(trackViewEnter(event, history));
  };

/**
 * Tracks item shares
 * See https://flwiki.flipboard.com/display/DATA/Web+usage+event#Webusageevent-Event(CORE)-EnterItem(enter,item)
 * @param {Object} item            - Projected item that was shared
 * @param {Object} section         - Projected section the item is displayed within
 * @param {String} targetId        - ID of the target: facebook|twitter|email
 */
export const usageTrackItemShare =
  (item, section, targetId) => (dispatch, getState) => {
    if (!item) {
      return;
    }
    const validTargetIds = ['facebook', 'twitter', 'email'];

    const data = usageItemEventData(section, item);

    const navFromValue = getNavFromValue(
      USAGE_EVENT_NAMES.ITEM_SHARE,
      getState(),
    );
    if (navFromValue) {
      data.nav_from = navFromValue;
      dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.ITEM_SHARE));
    }

    if (targetId && validTargetIds.includes(targetId)) {
      data.target_id = targetId;
    }

    return dispatch(
      sendUsageEventWithSession({
        event_category: 'item',
        event_action: 'share',
        event_data: data,
      }),
    );
  };

export const usageTrackItemSocialCardView = (item, section) => (dispatch) =>
  dispatch(
    sendUsageEventWithSession({
      event_category: 'item',
      event_action: 'social_card_view',
      data: usageItemEventData(section, item),
    }),
  );

export const usageTrackItemComment = (item, section) => (dispatch) =>
  dispatch(
    sendUsageEventWithSession({
      event_category: 'item',
      event_action: 'comment',
      data: usageItemEventData(section, item),
    }),
  );

/**
 * Tracks item like taps
 * See https://flwiki.flipboard.com/display/DATA/Web+usage+event#Webusageevent-Event(CORE)-EnterItem(enter,item)
 * @param {Object} section         - Projected section the item is displayed within
 * @param {Object} item            - Projected item for which "like" was tapped
 */
export const usageTrackTapLikeItem = (section, item) => (dispatch) => {
  if (!item) {
    return;
  }
  const data = usageItemEventData(section, item);

  const event = {
    event_category: 'item',
    event_action: 'tap_like',
    event_data: data,
  };

  return dispatch(sendUsageEventWithSession(event));
};

/**
 * Tracks item likes
 * See https://flwiki.flipboard.com/display/DATA/Web+usage+event#Webusageevent-Event(CORE)-EnterItem(enter,item)
 * @param {Object} item            - Projected item that was liked
 * @param {Object} section         - Projected section the item is displayed within
 */
export const usageTrackItemLike = (item, section) => (dispatch, getState) => {
  if (!item) {
    return;
  }
  const data = usageItemEventData(section, item);

  const navFromValue = getNavFromValue(USAGE_EVENT_NAMES.ITEM_LIKE, getState());
  if (navFromValue) {
    data.nav_from = navFromValue;
    dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.ITEM_LIKE));
  }

  if (item.service) {
    data.target_id = item.service;
  }

  const event = {
    event_category: 'item',
    event_action: 'like',
    event_data: data,
  };

  return dispatch(sendUsageEventWithSession(event));
};

/**
 * Tracks item unlikes
 * See https://flwiki.flipboard.com/display/DATA/Web+usage+event#Webusageevent-Event(CORE)-EnterItem(enter,item)
 * @param {Object} item            - Projected item that was unliked
 * @param {Object} section         - Projected section the item is displayed within
 */
export const usageTrackItemUnlike = (item, section) => (dispatch, getState) => {
  if (!item) {
    return;
  }
  const data = usageItemEventData(section, item);
  const navFromValue = getNavFromValue(
    USAGE_EVENT_NAMES.ITEM_UNLIKE,
    getState(),
  );
  if (navFromValue) {
    data.nav_from = navFromValue;
    dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.ITEM_UNLIKE));
  }

  if (item.service) {
    data.target_id = item.service;
  }

  const event = {
    event_category: 'item',
    event_action: 'unlike',
    event_data: data,
  };

  return dispatch(sendUsageEventWithSession(event));
};

/**
 * Tracks the client-side app update when a user lands on webapp
 * with the action=onboard parameter and the app updates
 * to reflect the fact that they are authenticated.
 */
export const usageTrackOnboardAppStartAuthenticated = () =>
  usageTrackFirstLaunchEnter(
    'onboard_app_start_authenticated',
    TrackOnboardEnterError,
  );

export const usageTrackFirstLaunchEnter =
  (type, customErrorType = null) =>
  (dispatch, getState) => {
    const method = frictionlessConfirmationFlow(getState());
    dispatch(
      sendUsageEventWithSession(
        {
          event_category: 'firstlaunch',
          event_action: 'enter',
          event_data: usageActivationEventData({ type, method }),
        },
        customErrorType,
      ),
    );
  };

/**
 * Tracks a "general/tap_action" event.  Typically used for very specific UX interactions
 * like tapping a particular button.
 * @param {String} type           - The event_type, used to distinguish the specific UX interaction
 * @param {String} targetId       - (optional) The event_target_id, used to further differentiate UX interactions
 * @param {String} displayStyle   - (optional) The event_display_style, used to further differentiate UX interactions
 */
export const usageTrackTapAction =
  (type, targetId = null, displayStyle = null, customData = null) =>
  (dispatch) => {
    const data = Object.assign({}, customData, { type });
    if (targetId) {
      data.target_id = targetId;
    }
    if (displayStyle) {
      data.display_style = displayStyle;
    }

    const event = {
      event_category: 'general',
      event_action: 'tap_action',
      event_data: data,
    };

    return dispatch(sendUsageEventWithSession(event));
  };

/**
 *
 * @param {String} displayStyle  - one of grid|list
 * @param {String} sectionId
 * @param {String} url - URL of the section
 */
export const usageTrackCuratorProToggleItemsView = (
  displayStyle,
  sectionId,
  url,
) =>
  usageTrackTapAction('curator_pro_display_toggle', null, displayStyle, {
    section_id: sectionId,
    url,
  });

/**
 * Tracks Banner displays
 * @param {String} bannerId - usage id of the banner
 * @param {Object} history - History object
 */
export const usageTrackBannerEnter =
  (bannerId, history) => (dispatch, getState) => {
    const data = {
      type: bannerId,
    };

    const navFromValue = getNavFromValue(
      USAGE_EVENT_NAMES.BANNER_ENTER,
      getState(),
    );
    if (navFromValue) {
      data.nav_from = navFromValue;
      dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.BANNER_ENTER));
    }

    const event = {
      event_category: 'general',
      event_action: 'enter',
      event_data: data,
    };
    return dispatch(trackViewEnter(event, history));
  };

/**
 * Tracks Banner dismisses
 * @param {String} bannerId - usage id of the banner
 */
export const usageTrackBannerExit = (bannerId) => (dispatch) =>
  dispatch(
    sendUsageEventWithSession({
      event_category: 'general',
      event_action: 'exit',
      event_data: {
        type: bannerId,
      },
    }),
  );

/**
 * Tracks taps/clicks on Download App Banner App install buttons
 * @param {String} method - one of "qr_code", "ios_app_store", "google_play_store"
 */
export const usageTrackTapAppInstall = (method) => (dispatch) =>
  dispatch(
    sendUsageEventWithSession({
      event_category: 'general',
      event_action: 'tap_action',
      event_data: {
        type: 'app_install_banner',
        method,
      },
    }),
  );

export const usageTrackCuratorProStoryboardTapShare = () =>
  usageTrackTapAction('curator_pro_share_button');

/**
 * Tracks tapping the magazine create button
 * @param {Object} profile        - (optional) Projected profile of the currently logged-in user
 */
export const usageTrackTapMagazineCreate =
  (profile) => (dispatch, getState) => {
    const data = {};

    const navFromValue = getNavFromValue(
      USAGE_EVENT_NAMES.TAP_MAGAZINE_CREATE,
      getState(),
    );
    if (navFromValue) {
      data.nav_from = navFromValue;
      dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.TAP_MAGAZINE_CREATE));
    }

    if (profile) {
      data.number_items = usageProfileMagazineCount(profile);
    }

    const event = {
      event_category: 'magazine',
      event_action: 'tap_create',
      event_data: data,
    };

    return dispatch(sendUsageEventWithSession(event));
  };

/**
 * Tracks displaying UX components in general (dialogs, etc.)
 * @param {String} type     - The event_type property, used to differentiate displayed content
 */
const usageCreateTrackGeneralDisplay = (type) => () => (dispatch) =>
  dispatch(
    sendUsageEventWithSession({
      event_category: 'general',
      event_action: 'display',
      event_data: { type },
    }),
  );

/**
 * Sends usage event for displaying the "Open in App" button
 */
// see https://flwiki.flipboard.com/display/DATA/Web+usage+event#Webusageevent-Event(CORE)-Display%22OpeninApp%22Button(displayopen_in_app,general)-mobileonly
export const usageTrackDisplayOpenInAppButton =
  usageCreateTrackGeneralDisplay('open_in_app');

export const usageTrackCuratorProWhatIsItEdu = usageCreateTrackGeneralDisplay(
  'curator_pro_what_is_it_edu',
);

export const usageTrackCuratorProVerifiedAccountEditingViewEdu =
  usageCreateTrackGeneralDisplay(
    'curator_pro_verified_account_editing_view_hint',
  );

export const usageTrackCuratorProVerifiedAccountAccessNavBarEdu =
  usageCreateTrackGeneralDisplay(
    'curator_pro_verified_account_access_nav_bar_hint',
  );

export const usageTrackCuratorProVerifiedAccountAccessTileEdu =
  usageCreateTrackGeneralDisplay(
    'curator_pro_verified_account_access_tile_hint',
  );

export const usageTrackNavBarEdu = usageCreateTrackGeneralDisplay(
  USAGE_GENERAL_DISPLAY_TYPES.NAV_BAR_EDU_CTA,
);

export const usageTrackTopicPickerCta = usageCreateTrackGeneralDisplay(
  USAGE_GENERAL_DISPLAY_TYPES.TOPIC_PICKER_CTA,
);

export const usageTrackTopicPickerCtaSeeMore = () =>
  usageTrackTapAction(
    'see_more_button',
    null,
    USAGE_GENERAL_DISPLAY_TYPES.TOPIC_PICKER_CTA,
  );

export const usageTrackTopicPickerCtaExit = () =>
  usageTrackDialogCloseButtonTap(USAGE_GENERAL_DISPLAY_TYPES.TOPIC_PICKER_CTA);

/**
 * Sends usage event for tapping the "Open in App" button
 * @param {Object} section      - (optional) Projected section object that was being displayed
 */
export const usageTrackTapOpenInAppButton =
  (section) => (dispatch, getState) => {
    const data = {};

    // set data that may be missing and needs guarding
    if (section && section.remoteid) {
      data.section_id = section.remoteid;
    }

    const navFromValue = getNavFromValue(
      USAGE_EVENT_NAMES.OPEN_IN_APP,
      getState(),
    );
    if (navFromValue) {
      data.nav_from = navFromValue;
      dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.OPEN_IN_APP));
    }

    return dispatch(
      sendUsageEventWithSession({
        event_category: 'general',
        event_action: 'open_flipboard',
        event_data: data,
      }),
    );
  };

/**
 * Tracks displaying a widget
 * @param {Object} section  - Projected section object
 * @param {String} url      - URL determined by browser
 * @param {String} layout   - The layout of the widget (portrait, thumbnail, banner)
 */
export const usageTrackWidgetDisplay =
  (section, url, layout = null) =>
  (dispatch) =>
    dispatch(
      sendUsageEventWithSession({
        event_category: 'widget',
        event_action: 'display',
        event_data: usageWidgetEventData(section, url, layout),
      }),
    );

/**
 * Tracks clicking a widget
 * @param {Object} section  - Projected section object
 * @param {String} url      - URL determined by browser
 * @param {String} layout   - The layout of the widget (portrait, thumbnail, banner)
 */
export const usageTrackWidgetClick =
  (section, url, layout = null) =>
  (dispatch) =>
    dispatch(
      sendUsageEventWithSession({
        event_category: 'widget',
        event_action: 'click',
        event_data: usageWidgetEventData(section, url, layout),
      }),
    );

/**
 * Tracks reaching the end of a view (home, article preview, section).
 * "End" is currently defined on a case-by-case basis.  For example,
 * when the section view has infinite scroll, triggering a second
 * feed page load is considered reaching the "end.""
 * @param {String} type    - The type of view
 * @param {Object} data    - (optional) Additional event data
 * is rendering a section feed.
 */
export const usageTrackReachedEnd =
  (type, data = {}) =>
  (dispatch) => {
    data.type = type;
    return dispatch(
      sendUsageEventWithSession({
        event_category: 'general',
        event_action: 'end_of_feed',
        event_data: data,
      }),
    );
  };

/**
 * Tracks reaching the end of the logged-out home view
 */
export const usageTrackReachedLoggedOutHomeEnd = () =>
  usageTrackReachedEnd(USAGE_REACHED_END_TYPES.LOGGED_OUT_HOME);

/**
 * Tracks when a user clicks a link to the original publisher article.
 * Fired from ItemLink when NOT shown in a list (article preivew, for example).
 */
export const usageTrackPublisherSiteLinkClick = () =>
  usageTrackTapAction('publisher_link');

/**
 * Tracks flip taps with no item/section (such as nav bar pencil icon)
 * @param {Object} profile        - (optional) Projected profile of the currently logged-in user
 */
export const usageTrackTapFlip =
  (profile = null) =>
  (dispatch, getState) => {
    const data = {};

    const navFromValue = getNavFromValue(
      USAGE_EVENT_NAMES.TAP_FLIP,
      getState(),
    );
    if (navFromValue) {
      data.nav_from = navFromValue;
      dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.TAP_FLIP));
    }

    if (profile) {
      data.number_items = usageProfileMagazineCount(profile);
    }
    return dispatch(
      sendUsageEventWithSession({
        event_category: 'magazine',
        event_action: 'tap_flip',
        event_data: data,
      }),
    );
  };

/**
 * Fires a usage event for first launch exit
 * @param {String} type - one of the given FIRST_LAUNCH_EVENT_DATA_TYPES
 * @param {Boolean} success - Whether the exit was triggered by a successful login/signup or not
 * @param {String} service - Authentication service. Usually one of: "flipboard", "facebook", "twitter", "google"
 * @param {Integer} topicCount - (optional) The number of topics followed during onboarding
 * @param {Object} customErrorType - Custom error type to emit if an exception occurs
 */
export const usageTrackFirstLaunchExit =
  (type, success, service, topicCount = null, customErrorType = null) =>
  (dispatch, getState) => {
    const method = frictionlessConfirmationFlow(getState());
    const event_data = usageActivationEventData({
      type,
      success: success ? 1 : 0,
      method,
    });

    if (service) {
      event_data.target_id = service;
    }
    if (topicCount) {
      event_data.tap_count = topicCount;
    }

    return dispatch(
      sendUsageEventWithSession(
        {
          event_category: 'firstlaunch',
          event_action: 'exit',
          event_data,
        },
        customErrorType,
      ),
    );
  };

/**
 * Tracks exiting the onboarding process for a new user.
 * @param {String} enterType - The "type" property for the "firstlaunch" event.
 * One of APP_ENTER_TYPES
 * @param {Boolean} success - Whether there was a problem in the onboarding process
 * @param {Integer} topicCount - (optional) The number of topics followed during onboarding
 */
export const usageTrackOnboardExit = (enterType, success, topicCount = null) =>
  usageTrackFirstLaunchExit(
    enterType,
    success,
    null,
    topicCount,
    TrackOnboardExitError,
  );

/**
 * Sends usage avent for receiving search results
 * @param {String} searchTerm   - The user's search term
 * @param {Number} itemCount         - The number of items returned
 */
// see https://flwiki.flipboard.com/display/DATA/Web+usage+event#Webusageevent-ReceiveSearchResults(receive,search)
export const usageTrackReceivedSearchResults =
  (searchTerm, itemCount = 0) =>
  (dispatch) =>
    dispatch(
      sendUsageEventWithSession({
        event_category: 'search',
        event_action: 'receive',
        event_data: {
          search_term: searchTerm,
          number_items: itemCount,
          success: true,
        },
      }),
    );

export const usageTrackSectionTap = () => (dispatch) =>
  dispatch(usageIncrementSectionHeartbeatTapCount());

/**
 * Tracks a tapping the "Sign Up" button.  Can be differentiated based on the "displayStyle", which specifies
 * the UX context (nav bar CTA, footer CTA, nav bar buttons, etc)
 * @param {String} displayStyle   - (optional) The event_display_style, used to further differentiate UX interactions
 */
export const usageTrackSignupButtonTap = (displayStyle, customData = null) =>
  usageTrackTapAction('sign_up_button', null, displayStyle, customData);

/**
 * Tracks item flip taps
 * See https://flwiki.flipboard.com/display/DATA/Web+usage+event#Webusageevent-Event(CORE)-EnterItem(enter,item)
 * @param {Object} section         - Projected section the item is displayed within
 * @param {Object} item            - Projected item for which "flip" was tapped
 * @param {Object} profile        - (optional) Projected profile of the currently logged-in user
 */
export const usageTrackTapFlipItem =
  (section, item, profile = null) =>
  (dispatch, getState) => {
    if (!item) {
      return;
    }

    const data = usageItemEventData(section, item, {}, profile);

    const navFromValue = getNavFromValue(
      USAGE_EVENT_NAMES.TAP_FLIP,
      getState(),
    );
    if (navFromValue) {
      data.nav_from = navFromValue;
      dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.TAP_FLIP));
    }

    // Special-case "target_id" property for section item flips
    if (item.isSection && item.section) {
      data.target_id = usageSectionIdForTracking(item.section);
    }

    return dispatch(
      sendUsageEventWithSession({
        event_category: 'magazine',
        event_action: 'tap_flip',
        event_data: data,
      }),
    );
  };

/**
 * Tracks section flip taps
 * See https://flwiki.flipboard.com/display/DATA/Web+usage+event#Webusageevent-Event(CORE)-EnterItem(enter,item)
 * @param {Object} section        - Projected section for which "flip" was tapped
 * @param {Object} profile        - (optional) Projected profile of the currently logged-in user
 */
export const usageTrackTapFlipSection =
  (section, profile = null) =>
  (dispatch, getState) => {
    const data = usageSectionEventData(section, {}, profile);

    const navFromValue = getNavFromValue(
      USAGE_EVENT_NAMES.TAP_FLIP,
      getState(),
    );
    if (navFromValue) {
      data.nav_from = navFromValue;
      dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.TAP_FLIP));
    }

    return dispatch(
      sendUsageEventWithSession({
        event_category: 'magazine',
        event_action: 'tap_flip',
        event_data: data,
      }),
    );
  };

/**
 * Tracks reaching the end of an item view (article)
 * @param {Object} item    - A projected item object
 */
export const usageTrackReachedItemEnd = (item) =>
  usageTrackReachedEnd(
    USAGE_REACHED_END_TYPES.ITEM,
    usageItemEventData(null, item),
  );

/**
 * Tracks section follow taps
 * See https://flwiki.flipboard.com/display/DATA/Web+usage+event#Webusageevent-Event(CORE)-EnterItem(enter,item)
 * @param {Object} section            - Projected section for which "follow" was tapped
 */
export const usageTrackTapFollowSection = (section) => (dispatch, getState) => {
  const data = usageSectionEventData(section);

  const navFromValue = getNavFromValue(
    USAGE_EVENT_NAMES.TAP_SECTION_SUBSCRIBE,
    getState(),
  );
  if (navFromValue) {
    data.nav_from = navFromValue;
    dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.TAP_SECTION_SUBSCRIBE));
  }
  return dispatch(
    sendUsageEventWithSession({
      event_category: 'section',
      event_action: 'tap_follow',
      event_data: data,
    }),
  );
};

/**
 * Tracks a tapping the close button of a dialog.  Can be differentiated based on the "displayStyle", which specifies
 * the UX context (nav bar CTA, footer CTA, nav bar buttons, etc)
 * @param {String} displayStyle   - (optional) The event_display_style, used to further differentiate UX interactions
 */
export const usageTrackDialogCloseButtonTap = (displayStyle) =>
  usageTrackTapAction('dialog_close_button', null, displayStyle);

/**
 * Tracks section follow taps when following the author of a viewed section (typically shown in
 * the section view header)
 * See https://flwiki.flipboard.com/display/DATA/Web+usage+event#Webusageevent-Event(CORE)-EnterItem(enter,item)
 * @param {Object} section            - Projected section for the section being viewed
 * @param {Object} sectionAuthor      - Projected section object for the author being followed
 */
export const usageTrackTapFollowSectionAuthor =
  (section, sectionAuthor) => (dispatch, getState) => {
    const data = usageSectionAuthorEventData(section, sectionAuthor);

    const navFromValue = getNavFromValue(
      USAGE_EVENT_NAMES.TAP_SECTION_SUBSCRIBE,
      getState(),
    );
    if (navFromValue) {
      data.nav_from = navFromValue;
      dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.TAP_SECTION_SUBSCRIBE));
    }

    return dispatch(
      sendUsageEventWithSession({
        event_category: 'section',
        event_action: 'tap_follow',
        event_data: data,
      }),
    );
  };

/**
 * Tracks item share taps
 * See https://flwiki.flipboard.com/display/DATA/Web+usage+event#Webusageevent-Event(CORE)-EnterItem(enter,item)
 * @param {Object} section         - Projected section the item is displayed within
 * @param {Object} item            - Projected item for which "share" was tapped
 */
export const usageTrackTapShareItem = (section, item) => (dispatch) => {
  if (!item) {
    return;
  }
  return dispatch(
    sendUsageEventWithSession({
      event_category: 'item',
      event_action: 'tap_share',
      event_data: usageItemEventData(section, item),
    }),
  );
};

/**
 * Tracks section share taps
 * See https://flwiki.flipboard.com/display/DATA/Web+usage+event#Webusageevent-Event(CORE)-EnterItem(enter,item)
 * @param {Object} section        - Projected section for which "share" was tapped
 * @param {Object} profile        - (optional) Projected profile of the currently logged-in user
 */
export const usageTrackTapShareSection =
  (section, profile = null) =>
  (dispatch) =>
    dispatch(
      sendUsageEventWithSession({
        event_category: 'section',
        event_action: 'tap_share',
        event_data: usageSectionEventData(section, {}, profile),
      }),
    );

/**
 * Tracks copying contributor invites
 * @param {Object} section  - Projected section that was unfollowed (unsubscribed)
 */
export const usageTrackMagazineInviteContributor =
  (section) => (dispatch, getState) => {
    const data = usageSectionEventData(section);

    const navFromValue = getNavFromValue(
      USAGE_EVENT_NAMES.CURATOR_PRO_MAGAZINE_INVITE_CONTRIBUTOR,
      getState(),
    );
    if (navFromValue) {
      data.nav_from = navFromValue;
      dispatch(
        usageClearNavFrom(
          USAGE_EVENT_NAMES.CURATOR_PRO_MAGAZINE_INVITE_CONTRIBUTOR,
        ),
      );
    }

    return dispatch(
      sendUsageEventWithSession({
        event_category: 'general',
        event_action: 'invite_contributor',
        event_data: data,
      }),
    );
  };

export const usageTrackCuratorProStoryboardTapShareTarget = (targetId) =>
  usageTrackTapAction('published_storyboard_share', targetId);

/**
 * Tracks entering the onboarding process for a new user.
 */
export const usageTrackOnboardEnter = () =>
  usageTrackFirstLaunchEnter(
    USAGE_APP_ENTER_TYPES.CATEGORY_SELECTOR,
    TrackOnboardEnterError,
  );

export const usageTrackMobileGateDisplayed = (type) => (dispatch, getState) => {
  let data = {};
  const {
    usage: { mobileGateSection, mobileGateArticle },
  } = getState();
  if (mobileGateSection) {
    // showing the mobile gate for a section
    data = usageSectionEventData(mobileGateSection);
  } else if (mobileGateArticle) {
    // showing the mobile gate for an article
    data = usageItemEventData(null, mobileGateArticle);
  }
  data.type = type;

  const navFromValue = getNavFromValue(
    USAGE_EVENT_NAMES.DISPLAY_MOBILE_GATE,
    getState(),
  );
  if (navFromValue) {
    data.nav_from = navFromValue;
  }

  return dispatch(
    sendUsageEventWithSession({
      event_category: 'general',
      event_action: 'display',
      event_data: data,
    }),
  );
};

/**
 * Tracks exits of the mobile content gate
 * @param {String} type - usage event type
 * Can be mobile_gate_bottom_sheet,
 * mobile_gate_overlay,
 * mobile_gate_bottom_picker
 * @param {Object} method - Event method
 * Can be "open_in_app" or "dismiss"
 */
// see https://flipboard.atlassian.net/wiki/spaces/AE/pages/429392/Web+Fluency+Events
export const usageTrackMobileGateExit =
  (type, method) => (dispatch, getState) => {
    let data = {};
    const {
      usage: { mobileGateSection, mobileGateArticle },
    } = getState();
    if (mobileGateSection) {
      // showing the mobile gate for a section
      data = usageSectionEventData(mobileGateSection);
    } else if (mobileGateArticle) {
      // showing the mobile gate for an article
      data = usageItemEventData(null, mobileGateArticle);
    }
    data.type = type;
    data.method = method;

    const navFromValue = getNavFromValue(
      USAGE_EVENT_NAMES.DISPLAY_MOBILE_GATE,
      getState(),
    );
    if (navFromValue) {
      data.nav_from = navFromValue;
      dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.DISPLAY_MOBILE_GATE));
    }

    return dispatch(
      sendUsageEventWithSession({
        event_category: 'general',
        event_action: 'exit',
        event_data: data,
      }),
    );
  };

export const usageTrackMobileGatePickerDisplayed = () =>
  usageTrackMobileGateDisplayed('mobile_gate_bottom_picker');

export const usageTrackMobileGatePickerExit = (method) =>
  usageTrackMobileGateExit('mobile_gate_bottom_picker', method);

export const usageTrackMobileSocialGatePickerDisplayed = () =>
  usageTrackMobileGateDisplayed('mobile_social_gate_bottom_picker');

export const usageTrackMobileSocialGatePickerExit = (method) =>
  usageTrackMobileGateExit('mobile_social_gate_bottom_picker', method);

export const usageTrackSocialGateOverlayExit = (method, type) =>
  usageTrackMobileGateExit(type, method);

export const usageTrackSocialGateOverlayDisplayed = (type) =>
  usageTrackMobileGateDisplayed(type);

export const usageTrackCuratorProRemoveScheduleStoryboard =
  (sectionId, url, itemCount, tagCount) => (dispatch) =>
    dispatch(
      sendUsageEventWithSession({
        event_category: 'general',
        event_action: 'remove_scheduling',
        event_data: usageCuratorProStoryboardPublishData({
          section_id: sectionId,
          url,
          itemCount,
          tagCount,
        }),
      }),
    );

export const usageTrackCuratorProScheduleStoryboard =
  (
    sectionId,
    url,
    itemCount,
    tagCount,
    previousStoryboardCount,
    hasPrevSchedule,
  ) =>
  (dispatch) =>
    dispatch(
      sendUsageEventWithSession({
        event_category: 'general',
        event_action: hasPrevSchedule ? 'reschedule' : 'schedule',
        event_data: usageCuratorProStoryboardPublishData(
          sectionId,
          url,
          itemCount,
          tagCount,
          previousStoryboardCount,
        ),
      }),
    );

export const usageTrackCuratorProPublishStoryboard =
  (sectionId, url, itemCount, tagCount = 0, previousStoryboardCount) =>
  (dispatch) =>
    dispatch(
      sendUsageEventWithSession({
        event_category: 'general',
        event_action: 'publish',
        event_data: usageCuratorProStoryboardPublishData(
          sectionId,
          url,
          itemCount,
          tagCount,
          previousStoryboardCount,
        ),
      }),
    );

/**
 * Tracks item flips
 * See https://flwiki.flipboard.com/display/DATA/Web+usage+event#Webusageevent-Event(CORE)-EnterItem(enter,item)
 * @param {Object} item             - Projected item that was flipped
 * @param {Object} magazine         - Projected magazine section the item is being flipped into
 * @param {Object} profile          - (optional) Projected profile of the currently logged-in user
 */
export const usageTrackItemFlip = (item, magazine) => (dispatch, getState) => {
  if (!item) {
    return;
  }
  const profile = getState().profile || null;
  const data = usageItemEventData(magazine, item, {}, profile);

  // Special-case "target_id" property for section item flips
  if (item.isSection && item.section) {
    data.target_id = usageSectionIdForTracking(item.section);
  }

  const navFromValue = getNavFromValue(USAGE_EVENT_NAMES.ITEM_FLIP, getState());
  if (navFromValue) {
    data.nav_from = navFromValue;
    dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.ITEM_FLIP));
  }

  if (magazine.magazineTarget) {
    data.magazine_id = magazine.magazineTarget;
  }
  return dispatch(
    sendUsageEventWithSession({
      event_category: 'item',
      event_action: 'flip',
      event_data: data,
    }),
  );
};

/**
 * Tracks item flips
 * See https://flwiki.flipboard.com/display/DATA/Web+usage+event#Webusageevent-Event(CORE)-EnterItem(enter,item)
 * @param {Object} section          - Projected section that was flipped
 * @param {Object} magazine         - Projected magazine section the item is being flipped into
 * @param {Object} profile          - (optional) Projected profile of the currently logged-in user
 */
export const usageTrackSectionFlip =
  (section, magazine) => (dispatch, getState) => {
    if (!section) {
      return;
    }
    const profile = getState().profile || null;
    const data = usageSectionEventData(section, {}, profile);

    // "target_id" property for section flips
    data.target_id = usageSectionIdForTracking(section);

    const navFromValue = getNavFromValue(
      USAGE_EVENT_NAMES.SECTION_FLIP,
      getState(),
    );
    if (navFromValue) {
      data.nav_from = navFromValue;
      dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.SECTION_FLIP));
    }

    if (magazine.magazineTarget) {
      data.magazine_id = magazine.magazineTarget;
    }

    return dispatch(
      sendUsageEventWithSession({
        event_category: 'item',
        event_action: 'flip',
        event_data: data,
      }),
    );
  };

/**
 * Tracks URL flips
 * See https://flwiki.flipboard.com/display/DATA/Web+usage+event#Webusageevent-Event(CORE)-EnterItem(enter,item)
 * @param {Object} URL             - Projected item that was liked
 * @param {Object} magazine        - Projected magazine section the item is being flipped into
 * @param {Object} profile          - (optional) Projected profile of the currently logged-in user
 */
export const usageTrackUrlFlip = (url, magazine) => (dispatch, getState) => {
  const data = { url };
  const profile = getState().profile || null;

  const navFromValue = getNavFromValue(USAGE_EVENT_NAMES.URL_FLIP, getState());
  if (navFromValue) {
    data.nav_from = navFromValue;
    dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.URL_FLIP));
  }

  if (magazine.magazineTarget) {
    data.magazine_id = magazine.magazineTarget;
  }

  if (profile) {
    data.number_items = usageProfileMagazineCount(profile);
  }

  return dispatch(
    sendUsageEventWithSession({
      event_category: 'item',
      event_action: 'flip',
      event_data: data,
    }),
  );
};

/**
 * Tracks creation of magazines
 * @param {String} socialId - The "socialId" property for the new magazine
 * @param {String} magazineVisibility - The "magazineVisibility" property
 * for the new magazine
 * @param {Object} profile        - (optional) Projected profile of the currently logged-in user
 */
export const usageTrackMagazineCreate =
  (socialId, magazineVisibility, profile) => (dispatch, getState) => {
    const data = {
      magazine_id: socialId,
      type: magazineVisibility === 'public' ? 'public_mag' : 'private_mag',
    };

    const navFromValue = getNavFromValue(
      USAGE_EVENT_NAMES.MAGAZINE_CREATE,
      getState(),
    );
    if (navFromValue) {
      data.nav_from = navFromValue;
      dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.MAGAZINE_CREATE));
    }

    if (profile) {
      // We haven't updated the profile yet, add one to mag count
      data.number_items = usageProfileMagazineCount(profile) + 1;
    }

    return dispatch(
      sendUsageEventWithSession({
        event_category: 'magazine',
        event_action: 'create',
        event_data: data,
      }),
    );
  };

/**
 * Tracks section follows (subdscribes)
 * See https://wiki.service.flipboard.com/display/DATA/Web+usage+event#Webusageevent-Event(CORE)-SubscribeSection(subscribe,section)
 * @param {Object} section            - Projected section that was followed (subscribed)
 * @param {Object} viewedSection      - (optional) Projected section object corresponding
 * to the "viewed" section.  Used when following the author of a section.  In that case,
 * "section" would be the author and "viewedSection" would be the mag/storyboard  being
 * viewed at the time.
 * @param {String} method             - (optional) event data method
 */
export const usageTrackSubscribeSection =
  (section, viewedSection = null, method) =>
  (dispatch, getState) => {
    const data = viewedSection
      ? usageSectionAuthorEventData(viewedSection, section)
      : usageSectionEventData(section);

    const navFromValue = getNavFromValue(
      USAGE_EVENT_NAMES.SECTION_SUBSCRIBE,
      getState(),
    );
    if (navFromValue) {
      data.nav_from = navFromValue;
      dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.SECTION_SUBSCRIBE));
    }

    return dispatch(
      sendUsageEventWithSession({
        event_category: 'section',
        event_action: 'subscribe',
        event_data: { ...data, method },
      }),
    );
  };

/**
 * Tracks activation of a new user.  Triggered after a user completes a smart mag topic
 * picker or onboarding topic picker (or aborts it) when they have first logged in.
 * "First logged in" is determined by the query param "action=onboard"
 */
export const usageTrackSignupActivated = () => (dispatch, getState) => {
  const reCaptchaScore = getCookieValue(RECAPTCHA_SCORE_COOKIE_NAME);
  const method = frictionlessConfirmationFlow(getState());
  dispatch(
    sendUsageEventWithSession(
      {
        event_category: 'firstlaunch',
        event_action: 'activated',
        event_data: {
          ...usageActivationEventData({ method }),
          view_count: reCaptchaScore,
        },
      },
      TrackSignupActivatedError,
    ),
  );
};

/**
 * Tracks section unfollows (unsubdscribes)
 * See https://wiki.service.flipboard.com/display/DATA/Web+usage+event#Webusageevent-Event(CORE)-SubscribeSection(subscribe,section)
 * @param {Object} section            - Projected section that was unfollowed (unsubscribed)
 * @param {Object} viewedSection      - (optional) Projected section object corresponding
 * to the "viewed" section.  Used when following the author of a section.  In that case,
 * "section" would be the author and "viewedSection" would be the mag/storyboard  being
 * viewed at the time.
 * @param {String} method             - (optional) event data method
 */
export const usageTrackUnsubscribeSection =
  (section, viewedSection = null, method) =>
  (dispatch, getState) => {
    const data = viewedSection
      ? usageSectionAuthorEventData(viewedSection, section)
      : usageSectionEventData(section);

    const navFromValue = getNavFromValue(
      USAGE_EVENT_NAMES.SECTION_UNSUBSCRIBE,
      getState(),
    );
    if (navFromValue) {
      data.nav_from = navFromValue;
      dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.SECTION_UNSUBSCRIBE));
    }

    return dispatch(
      sendUsageEventWithSession({
        event_category: 'section',
        event_action: 'unsubscribe',
        event_data: { ...data, method },
      }),
    );
  };

export const usageTrackPersonalizeForYou = () => (dispatch) =>
  dispatch(
    sendUsageEventWithSession({
      event_category: 'magazine',
      event_action: 'edit',
      event_data: {
        type: 'personalize',
        section_id: 'auth/flipboard/coverstories',
      },
    }),
  );

export const usageTrackShowAcceptContributorInvite =
  (section, invite) => (dispatch) =>
    dispatch(
      sendUsageEventWithSession({
        event_category: 'general',
        event_action: 'display',
        event_data: magazineInviteContributorEventData(section, invite, {
          type: 'accept_contributor_invite',
        }),
      }),
    );

export const usageTapAcceptContributorInvite =
  (section, invite) => (dispatch) =>
    dispatch(
      sendUsageEventWithSession({
        event_category: 'general',
        event_action: 'tap_action',
        event_data: magazineInviteContributorEventData(section, invite, {
          type: 'accept_contributor_invite',
        }),
      }),
    );

/**
 * Tracks accepting contributor invites to private magazines
 * See https://wiki.service.flipboard.com/display/DATA/Web+usage+event#Webusageevent-Event(CORE)-SubscribeSection(subscribe,section)
 * @param {Object} section  - Projected section that was unfollowed (unsubscribed)
 * @param {Object} invite   - Contributor invite object
 */
export const usageTrackAcceptContributorInvite =
  (section, invite) => (dispatch) =>
    dispatch(
      sendUsageEventWithSession({
        event_category: 'general',
        event_action: 'accept_contributor_invite',
        event_data: magazineInviteContributorEventData(section, invite),
      }),
    );

/**
 * Tracks reaching the end of an item view (article)
 * @param {Object} item    - A projected item object
 */
export const usageTrackReachedSectionEnd = (section) =>
  usageTrackReachedEnd(
    USAGE_REACHED_END_TYPES.SECTION,
    usageSectionEventData(section),
  );

/**
 * Tracks enters for items and sections.  Provides centralized
 * management of "isFirstPageLoad" variable so that actual
 * browser forward/back navigations can be distinguished from
 * first page loads.
 * @property {Object} event  - usage event object.  Either section/enter or item/enter
 * @param {Object} history    - React router history object
 */
export const usageTrackViewEnter =
  (enterEvent, history = null) =>
  (dispatch) => {
    const { event_data } = enterEvent;
    const navFromValue = event_data && event_data.nav_from;

    if (!navFromValue && didBacktrack(history)) {
      // Browser back/forward button navigation
      enterEvent.event_data.nav_from = USAGE_NAV_FROMS.BACKTRACKING;
    }

    // If the history object has a previousUrl value, override refer_url with that.
    // Intended to track client-side navigation behavior
    const previousUrl = getPreviousUrl(history);
    if (previousUrl) {
      enterEvent.event_data.refer_url = previousUrl;
    }

    return dispatch(sendUsageEventWithSession(enterEvent));
  };

export const usageTrackAnalyticsEnter =
  (history, section) => (dispatch, getState) => {
    let type;
    let method;
    if (section) {
      type = 'analytics_package_page';
      if (section.isMagazine) {
        method = 'magazine';
      } else if (section.isStoryboard) {
        method = 'storyboard';
      }
    } else {
      type = 'analytics_home_page';
    }
    const data = {
      type,
      method,
      section_id: section && usageSectionIdForTracking(section),
    };

    const navFromValue = getNavFromValue(
      USAGE_EVENT_NAMES.ANALYTICS_ENTER,
      getState(),
    );
    if (navFromValue) {
      data.nav_from = navFromValue;
      dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.ANALYTICS_ENTER));
    }
    dispatch(
      usageTrackViewEnter(
        {
          event_category: 'general',
          event_action: 'enter',
          event_data: data,
        },
        history,
      ),
    );
  };

export const usageTrackCuratorProEnter =
  (section, history, viewMode) => (dispatch, getState) => {
    const data = usageSectionEventData(section);
    let method;
    if (section.isStoryboard) {
      method = 'storyboard';
    } else if (section.isMagazine) {
      method = 'magazine';
    }

    Object.assign(data, {
      type: 'curator_pro',
      method,
    });

    if (viewMode) {
      data.display_style = viewMode;
    }

    const event = {
      event_category: 'general',
      event_action: 'enter',
      event_data: data,
    };

    const navFromValue = getNavFromValue(
      USAGE_EVENT_NAMES.CURATOR_PRO_ENTER,
      getState(),
    );
    if (navFromValue) {
      event.event_data.nav_from = navFromValue;
      dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.CURATOR_PRO_ENTER));
    }
    return dispatch(usageTrackViewEnter(event, history));
  };

export const usageTrackOnboardServerRender = () => (dispatch) =>
  dispatch(
    sendUsageEventWithSession({
      event_category: 'firstlaunch',
      event_action: 'enter',
      event_data: { type: 'onboard_server_render' },
    }),
  );

export const usageTrackTopicSubtabSelected =
  ({ rootTopicId, section }) =>
  (dispatch) => {
    dispatch(
      sendUsageEventWithSession({
        event_category: 'section',
        event_action: 'enter',
        event_data: {
          nav_from: USAGE_NAV_FROMS.SUBTAB,
          method: 'home_carousel',
          target_id: rootTopicId,
          section_id: section.remoteid,
        },
      }),
    );
    dispatch(usageStartSectionHeartbeat(section));
  };

export const usageTrackFollowingEnter = () => (dispatch, getState) => {
  const event_data = {
    type: 'all',
  };
  const navFromValue = getNavFromValue(USAGE_EVENT_NAMES.TOC_ENTER, getState());
  if (navFromValue) {
    event_data.nav_from = navFromValue;
    dispatch(usageClearNavFrom(USAGE_EVENT_NAMES.TOC_ENTER));
  }
  dispatch(
    sendUsageEventWithSession({
      event_category: 'toc',
      event_action: 'enter',
      event_data,
    }),
  );
};

export const usageTrackSignupEnter = (
  enterType = USAGE_APP_ENTER_TYPES.SIGN_UP,
) => usageTrackFirstLaunchEnter(enterType, TrackSignupEnterError);

export const usageTrackSignupExit = (
  success,
  service,
  enterType = USAGE_APP_ENTER_TYPES.SIGN_UP,
) =>
  usageTrackFirstLaunchExit(
    enterType,
    success,
    service,
    null,
    TrackSignupExitError,
  );

/**
 * Newsletter and Email Landing page usage specification
 * https://flipboard.atlassian.net/wiki/spaces/DATA/pages/2132541490/Newsletter+and+Email+Landing+page+usage+specification
 *
 **/

export const usageTrackEnterNewsletterLandingPage =
  (subscriptionKey) => (dispatch) => {
    const event = {
      event_category: 'general',
      event_action: 'enter',
      event_data: {
        method: subscriptionKey,
        type: 'newsletter_landing_page',
      },
    };

    return dispatch(sendUsageEventWithSession(event));
  };

export const usageTrackNewsletterLandingPageSubmitClick =
  (subscriptionKey) => (dispatch) => {
    const event = {
      event_category: 'general',
      event_action: 'click',
      event_data: {
        method: subscriptionKey,
        type: 'newsletter_landing_page_submit_email',
      },
    };

    return dispatch(sendUsageEventWithSession(event));
  };

export const usageTrackNewsletterLandingPageSubmission =
  (subscriptionKey, success) => (dispatch) => {
    const event = {
      event_category: 'general',
      event_action: 'click',
      event_data: {
        method: subscriptionKey,
        type: 'newsletter_landing_page_submit_email',
        success: success ? 1 : 0,
      },
    };

    return dispatch(sendUsageEventWithSession(event));
  };

export const usageTrackEmailConfirmation = () => (dispatch, getState) => {
  const event = {
    event_category: 'general',
    event_action: 'enter',
    event_data: {
      type: 'email_confirmation',
    },
  };

  const navFromValue = getNavFromValue(
    USAGE_EVENT_NAMES.CONFIRMATION_EMAIL_ENTER,
    getState(),
  );

  if (navFromValue) {
    event.event_data.nav_from = navFromValue;
  }

  return dispatch(sendUsageEventWithSession(event));
};

export const usageTrackNewsletterEnter = () => (dispatch) =>
  dispatch(
    sendUsageEventWithSession({
      event_category: 'general',
      event_action: 'enter',
      event_data: {
        type: 'newsletters_sign_up_page',
      },
    }),
  );

export const usageTrackNewsletterSubmitClick =
  (pendingSubscriptionGroups) => (dispatch) => {
    const subscribedKeys = getSubscribedKeys(pendingSubscriptionGroups);
    return dispatch(
      sendUsageEventWithSession({
        event_category: 'general',
        event_action: 'click',
        event_data: {
          type: 'newsletters_sign_up_page_submit',
          newsletters: subscribedKeys,
        },
      }),
    );
  };
export const usageTrackNewsletterSubmit = (success) => (dispatch) =>
  dispatch(
    sendUsageEventWithSession({
      event_category: 'general',
      event_action: 'click',
      event_data: {
        type: 'newsletters_sign_up_page_submit',
        success: success ? 1 : 0,
      },
    }),
  );

export const usageTrackSettingsPreferenceEnter = () => (dispatch) =>
  dispatch(
    sendUsageEventWithSession({
      event_category: 'general',
      event_action: 'enter',
      event_data: {
        type: 'settings_page',
      },
    }),
  );

export const usageTrackSettingsPreferenceSubmit =
  ({ subscribed, unSubscribed }) =>
  (dispatch) =>
    dispatch(
      sendUsageEventWithSession({
        event_category: 'general',
        event_action: 'click',
        event_data: {
          type: 'settings_page-save',
          subscribed_newsletters: subscribed,
          unsubscribed_newsletters: unSubscribed,
        },
      }),
    );

export const usageTrackSignupExitRedirect = () =>
  emitUsageEventWithSession(
    {
      event_category: 'firstlaunch',
      event_action: 'enter',
      event_data: { type: 'signup_redirect' },
    },
    TrackSignupExitRedirectError,
  );

export const usageTrackLoginEnter = () =>
  usageTrackFirstLaunchEnter(USAGE_APP_ENTER_TYPES.SIGN_IN);

export const usageTrackLoginExit = (success, service) =>
  usageTrackFirstLaunchExit(USAGE_APP_ENTER_TYPES.SIGN_IN, success, service);

export const usageTrackRequestLoginLinkEnter = () =>
  usageTrackFirstLaunchEnter(USAGE_APP_ENTER_TYPES.REQUEST_LOGIN_LINK);

export const usageTrackRequestLoginLinkExit = (success) =>
  usageTrackFirstLaunchExit(
    USAGE_APP_ENTER_TYPES.REQUEST_LOGIN_LINK,
    success,
    'flipboard',
  );

export const usageTrackPublisherSignupEnter = () =>
  usageTrackFirstLaunchEnter(
    USAGE_APP_ENTER_TYPES.PUBLISHER_SIGNUP,
    TrackPublisherSignupEnterError,
  );

export const usageTrackPublisherSignupExit = (success, service) =>
  usageTrackFirstLaunchExit(
    USAGE_APP_ENTER_TYPES.PUBLISHER_SIGNUP,
    success,
    service,
    null,
    TrackPublisherSignupExitError,
  );

export const usageTrackMagazineSubscribe =
  (magazine) => (dispatch, getState) => {
    const data = usageSectionEventData(magazine);
    const navFromValue = getNavFromValue(
      USAGE_EVENT_NAMES.MAGAZINE_SUBSCRIBE,
      getState(),
    );
    if (navFromValue) {
      data.nav_from = navFromValue;
    }

    return dispatch(
      sendUsageEventWithSession({
        event_category: 'magazine',
        event_action: 'subscribe',
        event_data: data,
      }),
    );
  };

export const usageTrackMagazineUnsubscribe =
  (magazine) => (dispatch, getState) => {
    const data = usageSectionEventData(magazine);
    const navFromValue = getNavFromValue(
      USAGE_EVENT_NAMES.MAGAZINE_SUBSCRIBE,
      getState(),
    );
    if (navFromValue) {
      data.nav_from = navFromValue;
    }

    return dispatch(
      sendUsageEventWithSession({
        event_category: 'magazine',
        event_action: 'unsubscribe',
        event_data: data,
      }),
    );
  };
