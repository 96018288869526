import loadable from '@loadable/component';

// be very careful if trying to abstract anything here, the string
// that is being passed to `import` *must* be passed to import so that
// the babel plugin transformer works as expected
const loadables = {
  ArticleRouteContainer: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/article'),
  BrowserTools: /* #__LOADABLE__ */ () =>
    import('../app/components/embed-tool/browser-tools'),
  CampaignTile: /* #__LOADABLE__ */ () =>
    import('../app/components/campaign-tile'),
  Comments: /* #__LOADABLE__ */ () => import('../app/components/comments'),
  CreateMagazineModal: /* #__LOADABLE__ */ () =>
    import('../app/containers/create-magazine'),
  CroppedImage: /* #__LOADABLE__ */ () =>
    import('../app/components/base/cropped-image'),
  CuratorProMagazineRouteContainer: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/curator-pro/magazine'),
  CuratorProStoryboardRouteContainer: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/curator-pro/storyboard'),
  CuratorProCampaignRouteContainer: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/curator-pro/edit-campaign'),
  DevelopmentStuff: /* #__LOADABLE__ */ () =>
    import('../app/components/development-stuff'),
  DoNotSellLink: /* #__LOADABLE__ */ () =>
    import('../app/components/do-not-sell-link'),
  EmailSettingsView: /* #__LOADABLE__ */ () =>
    import('../app/views/account/email-settings'),
  EmbedTools: /* #__LOADABLE__ */ () =>
    import('../app/containers/embed-tool/embed-tools'),
  EmbedToolView: /* #__LOADABLE__ */ () => import('../app/views/embed-tool'),
  EmbeddableHtmlVideoPlayerView: /* #__LOADABLE__ */ () =>
    import('../app/views/embeddable-html-video-player'),
  ErrorView: /* #__LOADABLE__ */ () => import('../app/views/error'),
  FavoritesMenu: /* #__LOADABLE__ */ () =>
    import('../app/components/favorites-menu'),
  FavoritesMenuModal: /* #__LOADABLE__ */ () =>
    import('../app/modals/favorites-menu'),
  FirstPartyVideo: /* #__LOADABLE__ */ () =>
    import('../app/containers/first-party-video'),
  FlipComposeModal: /* #__LOADABLE__ */ () =>
    import('../app/modals/flip-compose-modal'),
  FollowingRouteContainer: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/following'),
  ForgotPasswordContainer: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/forgot-password'),
  ForgotUsernameContainer: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/forgot-username'),
  HomeAnalyticsRouteContainer: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/analytics/home'),
  HomeRouteContainer: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/home'),
  LoadFullProfile: /* #__LOADABLE__ */ () =>
    import('../app/containers/load-full-profile'),
  LoggedInButtons: /* #__LOADABLE__ */ () =>
    import('../app/containers/navigation/logged-in-buttons'),
  LoginModal: /* #__LOADABLE__ */ () =>
    import('../app/containers/modals/login'),
  LoginRouteContainer: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/login'),
  Logout: /* #__LOADABLE__ */ () => import('../app/views/logout'),
  LogoWidgetTool: /* #__LOADABLE__ */ () =>
    import('../app/components/embed-tool/logo-widget-tool'),
  MagazineAnalyticsRouteContainer: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/analytics/magazine'),
  MagazineAndStoryboardWidgetTool: /* #__LOADABLE__ */ () =>
    import('../app/components/embed-tool/magazine-and-storyboard-widget-tool'),
  MagazineContributors: /* #__LOADABLE__ */ () =>
    import('../app/components/magazine-contributors'),
  MagazineContributorBanner: /* #__LOADABLE__ */ () =>
    import('../app/views/section/magazine/magazine-contributor-banner'),
  MagazineInviteBanner: /* #__LOADABLE__ */ () =>
    import('../app/components/banners/magazine-invite-banner'),
  MobileGate: /* #__LOADABLE__ */ () => import('../app/modals/mobile-gate'),
  NavbarMoreMenu: /* #__LOADABLE__ */ () =>
    import('../app/components/navigation/navbar-more-menu'),
  NewslettersRouteContainer: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/newsletters'),
  NewslettersLandingRouteContainer: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/newsletters-landing'),
  OnboardingTopicPicker: /* #__LOADABLE__ */ () =>
    import('../app/containers/onboarding-topic-picker-modal'),
  PrivacySettings: /* #__LOADABLE__ */ () =>
    import('../app/views/privacy-settings'),
  ProfileHeader: /* #__LOADABLE__ */ () =>
    import('../app/components/profile-header'),
  ProfileMetricsSubNav: /* #__LOADABLE__ */ () =>
    import('../app/components/profile-metrics-sub-nav'),
  ProfileRouteContainer: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/profile'),
  PublisherHeading: /* #__LOADABLE__ */ () =>
    import('../app/containers/publisher-heading'),
  PublisherOnboardModal: /* #__LOADABLE__ */ () =>
    import('../app/containers/publisher-onboard'),
  PublisherSignupRouteContainer: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/publisher-signup'),
  RequestLoginLinkModal: /* #__LOADABLE__ */ () =>
    import('../app/modals/request-login-link'),
  RequestLoginLinkContainer: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/request-login-link'),
  ResetPasswordContainer: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/reset-password'),
  SearchModal: /* #__LOADABLE__ */ () => import('../app/modals/search'),
  SearchRouteContainer: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/search'),
  SectionRouteContainer: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/section'),
  SectionComponentDefault: /* #__LOADABLE__ */ () =>
    import('../app/containers/section/default'),
  SectionComponentMagazine: /* #__LOADABLE__ */ () =>
    import('../app/containers/section/magazine'),
  SectionComponentProfile: /* #__LOADABLE__ */ () =>
    import('../app/containers/section/profile'),
  SectionComponentTopic: /* #__LOADABLE__ */ () =>
    import('../app/containers/section/topic'),
  SectionComponentStoryboard: /* #__LOADABLE__ */ () =>
    import('../app/containers/section/storyboard'),
  SettingsRouteContainer: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/settings'),
  ShareLanding: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/shared-link-landing'),
  SignupRouteContainer: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/signup'),
  SignupModal: /* #__LOADABLE__ */ () => import('../app/modals/signup'),
  SitemapIndex: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/sitemap-index'),
  SitemapPage: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/sitemap-page'),
  SmartMagazineTile: /* #__LOADABLE__ */ () =>
    import('../app/components/smart-magazine-tile'),
  SortableList: /* #__LOADABLE__ */ () =>
    import('../../../../../shared/component_library/src/lists/sortable-list'),
  SortableListItem: /* #__LOADABLE__ */ () =>
    import(
      '../../../../../shared/component_library/src/lists/sortable-list-item'
    ),
  StoryboardAnalyticsRouteContainer: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/analytics/storyboard'),
  StoryboardHeader: /* #__LOADABLE__ */ () =>
    import('../app/themes/storyboard-layout/storyboard-header'),
  StoryboardLayout: /* #__LOADABLE__ */ () =>
    import('../app/containers/themes/storyboard-layout/storyboard-layout'),
  StoryboardPreviewFooter: /* #__LOADABLE__ */ () =>
    import('../app/containers/curator-pro/storyboard/preview-footer'),
  StoryboardTile: /* #__LOADABLE__ */ () =>
    import('../app/components/storyboard-tile'),
  ThirdPartyVideo: /* #__LOADABLE__ */ () =>
    import('../app/containers/third-party-video'),
  WidgetRouteContainer: /* #__LOADABLE__ */ () =>
    import('../app/route-containers/widgets/section'),
  SignupButtonCtaAnchoredDialog: /* #__LOADABLE__ */ () =>
    import('../app/components/signup-button-cta-anchored-dialog'),
  WelcomeModal: /* #__LOADABLE__ */ () => import('../app/modals/welcome'),
  CompleteProfileCollaboratorModal: /* #__LOADABLE__ */ () =>
    import('../app/modals/complete-profile-collaborator'),
  YoureInConfirmationModal: /* #__LOADABLE__ */ () =>
    import('../app/modals/youre-in-confirmation'),
};
export default (key) => loadable(loadables[key]);
