import React from 'react';
import { GA } from 'Utils/analytics';
import styled from '@emotion/styled';
import { styleOnlyProps } from 'Style/style-helpers';

const StyledClickableDiv = styled(
  'div',
  styleOnlyProps('disabled'),
)<{ disabled: boolean }>(({ disabled }) => ({
  cursor: disabled ? 'not-allowed' : 'pointer',
}));

interface ClickableDivProps {
  name: string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  stopPropagation?: false;
  disabled: boolean | false;
}
const ClickableDiv: React.FC<ClickableDivProps> = ({
  name,
  className,
  stopPropagation,
  onClick,
  children,
  disabled,
  ...props
}) => {
  const handleClick = (e) => {
    if (name) {
      GA.trackClickButton(name);
    }
    if (stopPropagation !== false) {
      e.stopPropagation();
    }
    if (onClick) {
      onClick(e);
    }
  };
  return (
    <StyledClickableDiv
      className={className}
      onClick={handleClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </StyledClickableDiv>
  );
};
export default ClickableDiv;
