import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

import {
  usageSetNavFrom,
  usageTrackEmailConfirmation,
} from 'Webapp/shared/app/redux/actions/usage-actions';
import { USAGE_NAV_FROMS, USAGE_EVENT_NAMES } from 'Utils/analytics/usage';

const trackFromEmailConfirmation: OnboardingStep = (nextStep) => (dispatch) => {
  dispatch(
    usageSetNavFrom(
      USAGE_EVENT_NAMES.CONFIRMATION_EMAIL_ENTER,
      USAGE_NAV_FROMS.EMAIL_CONFIRMATION,
    ),
  );
  dispatch(usageTrackEmailConfirmation());
  nextStep();
};

export default trackFromEmailConfirmation;
