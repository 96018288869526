import { factory as flaxiosFactory } from 'Utils/flaxios';
import getOrigin from 'Utils/get-origin';
import ApiHelpers from 'Utils/api/helpers';

const flavour = flaxiosFactory({
  baseURL: `${getOrigin()}/api/v2/flavour`,
  timeout: 30000,
  headers: { 'Content-Type': 'application/json' },
  'axios-retry': {
    retries: 0,
  },
});

/**
 * Request interceptor
 * Attaches a csrf token to the header of all requests
 */
flavour.interceptors.request.use((config) => {
  if (config.method.toLowerCase() === 'post') {
    config.headers['CSRF-Token'] = ApiHelpers.getCsrfTokenFromMetaElement();
  }
  return config;
});

export default flavour;
