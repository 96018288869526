const WEBAPP_PORT = process.env.WEBAPP_PORT || 3000;

enum NodeEnv {
  development = 'development',
  test = 'test',
  production = 'production',
}

enum DeployEnv {
  development = 'development',
  beta = 'beta',
  staging = 'staging',
  production = 'production',
  adam = 'adam',
  jeremy = 'jeremy',
  kenzo = 'kenzo',
}

enum FlapMode {
  gumby = 'gumby',
  production = 'production',
}

enum GatewayMode {
  beta = 'beta',
  production = 'production',
}

type DeployEnvMap<T> = { [key in DeployEnv]?: T };

const WEBAPP_URLS: DeployEnvMap<string> = {
  [DeployEnv.development]: `http://localhost:${WEBAPP_PORT}`,
  [DeployEnv.beta]: 'https://webu-beta.flipboard.com',
  [DeployEnv.staging]: 'https://webu-staging.flipboard.com',
  [DeployEnv.production]: 'https://flipboard.com',
  // developer deployments
  [DeployEnv.adam]: 'https://webu-adam.flipboard.com',
  [DeployEnv.jeremy]: 'https://webu-jeremy.flipboard.com',
  [DeployEnv.kenzo]: 'https://webu-kenzo.flipboard.com',
};

const deployEnvFlapModeOverride: DeployEnvMap<FlapMode> = {
  [DeployEnv.beta]: FlapMode.gumby,
  // you can control your dev env here for testing too:
  // [DeployEnv.adam]: FlapMode.gumby,
};

const flapMode =
  deployEnvFlapModeOverride[
    (process.env.DEPLOY_ENV as DeployEnv) ||
      (FlapMode.production as 'production')
  ] ||
  process.env.FLAP_MODE ||
  FlapMode.production;

const gatewayMode: GatewayMode =
  (process.env.GATEWAY_MODE as GatewayMode) || GatewayMode.production;

const useProductionFlap = flapMode === FlapMode.production;
const useProductionGateway = gatewayMode === GatewayMode.production;
const gatewayURL = useProductionGateway
  ? 'https://api2.flipboard.com'
  : 'https://api2-eng.flipboard.com';

const isReallyProduction =
  process.env.NODE_ENV === NodeEnv.production &&
  process.env.DEPLOY_ENV === DeployEnv.production;

const FLIPBOARD_URL =
  (process.env.DEPLOY_ENV &&
    WEBAPP_URLS[process.env.DEPLOY_ENV as DeployEnv]) ||
  WEBAPP_URLS.production ||
  'https://flipboard.com';

export default {
  ABOUT_BASE_URL: 'https://about.flipboard.com',
  FLIPBOARD_TOP_DOMAIN_URL: 'https://flipboard.com',
  FLIPBOARD_URL,
  LOGIN_URL: `${FLIPBOARD_URL}/login`,
  LOGOUT_URL: `${FLIPBOARD_URL}/logout`,
  SIGNUP_URL: `${FLIPBOARD_URL}/signup`,
  FLIPBOARD_ACCOUNTS_URL: 'https://accounts.flipboard.com',
  FLAP_URL: useProductionFlap
    ? 'http://fllb.service.flipboard.com:99/v1'
    : 'https://gumby.flipboard.com/v1',
  FLAP_INTERNAL_URL: useProductionFlap
    ? 'http://fllb.service.flipboard.com:99/int/v1'
    : 'http://gumby.service.flipboard.com/int/v1',
  FLAP_API_GW_URL: `${gatewayURL}${
    useProductionFlap ? '/flap/v1' : '/flap/gumby'
  }`,
  CONTENT_API_GW_URL: `${gatewayURL}/content/v1`,
  FLOUT_API_GW_URL: `${gatewayURL}/flout/v1`,
  FLAB_URL: 'http://fllb.service.flipboard.com:1012',
  FLAB_NAME: 'FLAB',
  // FLAP_STATIC_URL hits fbprod instead of a load balancer because it has a cacheing layer on the CDN
  FLAP_STATIC_URL: 'https://fbprod.flipboard.com',
  FLAP_NAME: useProductionFlap ? 'FLAP' : 'GUMBY',
  FLAP_VERSION: '4.2.7',
  FLAP_STATUS_ENDPOINT: 'http://status.flipboard.com',
  FLOWN_URL: 'https://flown.service.flipboard.com',
  // Flavour Box 4 is the only instance that can return audience size (endpoint used in webapp)
  FLAVOUR_INTERACTIVE_URL: 'http://fllb01-internal.service.flipboard.com:1036',
  FLAVOUR_NOTIFICATIONS_URL:
    'http://fllb01-internal.service.flipboard.com:1047',
  FLEA_URL: 'https://api.flipboard.com',
  ADS_URL: 'https://ad.flipboard.com/',
  PUBLISHER_SERVICE_URL: 'http://fllb-internal.service.flipboard.com:764',
  USAGE_URL: isReallyProduction
    ? 'https://ue.flipboard.com'
    : 'https://ue-test.flipboard.com',
  OAUTH2: {
    HYDRA_PUBLIC_URL: useProductionGateway
      ? 'https://as.flipboard.com'
      : 'https://as-eng.flipboard.com',
    HYDRA_PRIVATE_URL: useProductionGateway
      ? 'http://as-admin.service.flipboard.com'
      : 'http://as-eng-admin.service.flipboard.com',
    WEBU_HYDRA_CLIENT_ID: useProductionGateway
      ? 'webu-production'
      : 'webu-beta',
    HYDRA_REDIRECT_URL: useProductionGateway
      ? 'https://auth.flipboard.com/oauth2/token'
      : 'https://webu-auth-beta.flipboard.com/oauth2/token',
    HYDRA_RESPONSE_TYPE: 'code',
    HYDRA_REMEMBER: true,
    HYDRA_REMEMBER_FOR: 365 * 24 * 60 * 60,
    HYDRA_PERMISSIONS_SCOPE: [
      'package:read',
      'package:write',
      'offline',
      'openid',
    ].join('+'),
  },
  FACEBOOK_SDK_APP_ID: '105130332854716',
  GOOGLE_RECAPTCHA_PUBLIC_KEY: '6LcyJpAbAAAAAP0Jt0sy0VR_Zv5toJ0nmlciou2U',
  SIGNED_MEDIA_COOKIES_URL: 'https://w.ms.flipboard.com/c',
  GOOGLE_APP_ID: 'UA-39724124-2',
  GOOGLE_APP_NAME: 'web-universal',
  GOOGLE_APP_ID_WIDGET: 'UA-39724124-9',
  GOOGLE_APP_NAME_WIDGET: 'web-universal-widget',
  GOOGLE_RECAPTCHA_PUBLIC_KEY_V2: '6LchUlUbAAAAAILhjE_yxR5myPPFwJJu8Ct4Kbf6',
  GOOGLE_RECAPTCHA_SITE_VERIFY:
    'https://www.google.com/recaptcha/api/siteverify',
  VIDEO_AD_SHOW_PERCENTAGE: 60,
  VIDEO_AD_TAG_IU: '/21709104563/flipboard/flipboardtv',
  LOG_FORMAT:
    ':date[clf]   REQ | [jobid - :job-id] [remote-addr - :remote-addr] [remote-user - :remote-user] [method - :method] [url - :url] [host - :req[host]] [http-version - HTTP/:http-version] [status - :status] [content-length - :res[content-length]] [referrer - :referrer] [user-agent - :user-agent] [response-time - :response-time ms]',
  COMSCORE_CLIENT_ID: '20080817',
  APPLE_SDK_URL:
    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
  // https://developer.apple.com/account/resources/identifiers/list/serviceId
  // APPLE_SDK_CLIENT_ID: isReallyProduction
  //   ? 'com.flipboard.flipboard.prod' // https://developer.apple.com/account/resources/identifiers/serviceId/edit/TB86W9227F
  //   : 'com.flipboard.flipboard.beta', // https://developer.apple.com/account/resources/identifiers/serviceId/edit/Z62GG6D7D3
  APPLE_SDK_CLIENT_ID: 'com.flipboard.flipboard.prod',
  APPLE_SDK_SCOPE: 'name email',
  APPLE_SDK_REDIRECT_URI: `${FLIPBOARD_URL}/oauth/apple`,
  APPLE_SDK_OAUTH_STATE: 'esfweregrtml',
  FACEBOOK_SDK_URL: '//connect.facebook.net/en_US/sdk.js',
  FACEBOOK_SDK_VERSION: 'v3.2',
  FACEBOOK_APP_ID: '105130332854716',
  GOOGLE_SDK_URL: 'https://apis.google.com/js/platform.js',
  GOOGLE_SDK_APP_ID: '334069016917.apps.googleusercontent.com',
  ENABLE_PASSWORD_VALIDATION: true,
  PASSWORD_STRENGTH_MINIMUM_REQUIRED_LEVEL: 2,
  FLAP_OAUTH_HOST: 'https://fbprod.flipboard.com',
  BRANCH_API_URL: `https://api2.branch.io/v1`,
  BRANCH_KEY:
    process.env.NODE_ENV === NodeEnv.production
      ? 'key_live_eo7mZ6p5gwrrPiZDT1sYGbabAsg2SUCM'
      : 'key_test_ef9l80g4azrAJb6qT37IZnfaAvf2TLxW',
  CONFIRM_EMAIL_BACK_TO_FLIPBOARD: '/?action=confirmemail',
  MIN_REQUIRED_TOPIC_FOLLOWS: 3,
  BUGBOT_PROJECT_ID: '13570', // Jira FL project id
  BUGBOT_COMPONENT_ID: '14747', // Jira web component id
  BUGBOT_BASE_URL: 'https://bugbot.flipboard.com/rest',
  BUGBOT_FL_BACKEND_HOST: useProductionFlap
    ? 'https://fbprod.flipboard.com'
    : 'https://gumby.flipboard.com',
  COMMENT_MAX_LENGTH: 3000,
  SOURCEPOINT_ACCOUNT_ID: 1142,
  SOURCEPOINT_USNAT_PRIVACY_MANAGER_ID:
    process.env.NODE_ENV === NodeEnv.production ? 1031534 : 1068967,
  SOURCEPOINT_GDPR_PRIVACY_MANAGER_ID:
    process.env.NODE_ENV === NodeEnv.production ? 393130 : 1068966,
};
