import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';

const StyledSVG = styled.svg({
  marginLeft: SPACING.BASE,
  backgroundColor: 'var(--color--static-white)',
  borderRadius: '50%',
  border: '1px solid var(--color--static-white)',
});

function Amp({ size, color }) {
  return (
    <StyledSVG
      role="img"
      aria-labelledby="amp-logo-title"
      className="amp-logo"
      width={size}
      height={size}
      viewBox="0 0 60 60"
    >
      <title id="amp-logo-title">Amp logo</title>
      <g
        id="amp-logo-internal-site"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="AMP-Brand-Blue" fill={color}>
          <g id="Updated">
            <path
              d="M40.6737019,27.2526667 L27.9676923,48.196 L25.6657692,48.196 L27.9416106,34.5493333 L20.8936538,34.5593333 C20.8936538,34.5593333 20.8271875,34.561 20.7952163,34.561 C20.1608413,34.561 19.6459375,34.051 19.6459375,33.4226667 C19.6459375,33.1526667 19.900024,32.696 19.900024,32.696 L32.5639663,11.7768333 L34.9045913,11.786 L32.5715385,25.4543333 L39.6556731,25.446 C39.6556731,25.446 39.7322356,25.4443333 39.7684135,25.4443333 C40.4027885,25.4443333 40.916851,25.9535 40.916851,26.5826667 C40.916851,26.8376667 40.8167308,27.0618333 40.6728606,27.2518333 L40.6737019,27.2526667 Z M30.288125,0.000166666667 C13.5604808,0.000166666667 -0.000336538462,13.4318333 -0.000336538462,30.0001667 C-0.000336538462,46.5685 13.5604808,60.0001667 30.288125,60.0001667 C47.0166106,60.0001667 60.5765865,46.5685 60.5765865,30.0001667 C60.5765865,13.4318333 47.0166106,0.000166666667 30.288125,0.000166666667 L30.288125,0.000166666667 Z"
              id="Fill-7"
            />
          </g>
        </g>
      </g>
    </StyledSVG>
  );
}

Amp.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

Amp.defaultProps = {
  size: 60,
  color: '#0379C4',
};

export default Amp;
