import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ICON_COLORS } from 'Style/colors';
import { PinIconVariant } from 'Webapp/enums';

import Icon from './icon';

export const StyledPinIcon = styled(Icon)({
  color: ICON_COLORS.emphasis,
});

function PinIcon({ variant, ...props }) {
  return (
    <StyledPinIcon
      name="pin"
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      renderComponent={() => {
        switch (variant) {
          case PinIconVariant.FILLED:
            return (
              <path
                d="M8 10V4H7C6.44772 4 6 3.55228 6 3C6 2.44772 6.44772 2 7 2H17C17.5523 2 18 2.44772 18 3C18 3.55228 17.5523 4 17 4H16V10C16 11.6569 17.3431 13 19 13C19.5523 13 20 13.4477 20 14C20 14.5523 19.5523 15 19 15H13.0622V21.2298L12 23.0002L10.9378 21.2298V15H5C4.44772 15 4 14.5523 4 14C4 13.4477 4.44772 13 5 13C6.65685 13 8 11.6569 8 10Z"
                fill="currentColor"
              />
            );
          case PinIconVariant.PINNED:
            return (
              <>
                <path
                  d="M13.8748 12.8094C13.4825 12.2921 13.25 11.6491 13.25 10.9523V7.23808H10.75V10.9523C10.75 11.6491 10.5175 12.2921 10.1252 12.8094H13.8748Z"
                  fill="currentColor"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM8.875 7.23808H9.5V10.9523C9.5 11.978 8.66053 12.8094 7.625 12.8094C7.27982 12.8094 7 13.0866 7 13.4285C7 13.7704 7.27982 14.0475 7.625 14.0475H11.3361V17.904L12 19L12.6639 17.904V14.0475H16.375C16.7202 14.0475 17 13.7704 17 13.4285C17 13.0866 16.7202 12.8094 16.375 12.8094C15.3395 12.8094 14.5 11.978 14.5 10.9523V7.23808H15.125C15.4702 7.23808 15.75 6.96093 15.75 6.61904C15.75 6.27715 15.4702 6 15.125 6H8.875C8.52982 6 8.25 6.27715 8.25 6.61904C8.25 6.96093 8.52982 7.23808 8.875 7.23808Z"
                  fill="currentColor"
                />
              </>
            );
          default:
            return (
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 4H7C6.44772 4 6 3.55228 6 3C6 2.44772 6.44772 2 7 2H17C17.5523 2 18 2.44772 18 3C18 3.55228 17.5523 4 17 4H16V10C16 11.6569 17.3431 13 19 13C19.5523 13 20 13.4477 20 14C20 14.5523 19.5523 15 19 15H13.0622V21.2298L12 23.0002L10.9378 21.2298V15H5C4.44772 15 4 14.5523 4 14C4 13.4477 4.44772 13 5 13C6.65685 13 8 11.6569 8 10V4ZM14 10C14 11.1256 14.3719 12.1643 14.9996 13H9.00037C9.62806 12.1643 10 11.1256 10 10V4H14V10Z"
                fill="currentColor"
              />
            );
        }
      }}
    />
  );
}

PinIcon.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(PinIconVariant)),
};

PinIcon.defaultProps = {
  className: '',
  variant: PinIconVariant.DEFAULT,
};

export default PinIcon;
