import { Connector } from 'Webapp/utils/connector';
import {
  isHomeRoute,
  isAuthRoute,
  isNewslettersRoute,
} from 'Webapp/shared/app/redux/selectors/routing';
import { sharedLinkDataSelector } from 'Webapp/shared/concepts/shared-link';

const selectors = {
  routing: ({ app: { routing } }: Flipboard.State) => routing,
  isFromBranchInvite: (state: Flipboard.State) =>
    !!sharedLinkDataSelector(state),
  isHomeRoute,
  isAuthRoute,
  isNewslettersRoute,
  magazineInvite: ({ magazineInvite }: Flipboard.State) => magazineInvite,
};

export type ConnectRoutingProps = Flipboard.ConnectorProps<typeof selectors>;

const connectRouting: Connector = {
  selectors,
};

export default connectRouting;
