import React from 'react';
import PropTypes from 'prop-types';

import Button, { StyleVariations } from 'Webapp/shared/app/components/button';

// HOCs
import withT from 'ComponentLibrary/hocs/withT';
import withFollow from 'Webapp/shared/app/hocs/withFollow';
import withPersonalize from 'Webapp/shared/app/hocs/withPersonalize';

import connector from 'Utils/connector';
import connectUserState from 'Webapp/shared/app/connectors/connectUserState';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';

const FollowButton = ({
  namePrefix,
  className,
  styleVariation,
  followingStyleVariation,
  styleModifier,
  section,
  isFollowingSection,
  isUserStateLoading,
  handleFollow,
  handleUnfollow,
  onPersonalize,
  smartMagazineSection,
  followingIdsLoaded,
  isAuthenticated,
  t,
  label,
  disablePersonalization,
}) => {
  const isFollowing = isFollowingSection(section);
  const doFollow = () => {
    handleFollow({
      section,
      callback: () => {
        if (
          section.isTopic &&
          !smartMagazineSection &&
          !disablePersonalization
        ) {
          onPersonalize({ section });
        }
      },
    });
  };
  return isFollowing ? (
    <Button
      name={`${namePrefix}-unfollow`}
      className={className}
      label={label || t('unfollow')}
      styleVariation={followingStyleVariation || StyleVariations.UNSELECTED}
      styleModifier={styleModifier}
      disabled={isUserStateLoading || (isAuthenticated && !followingIdsLoaded)}
      onClick={() => handleUnfollow(section)}
    />
  ) : (
    <Button
      name={`${namePrefix}-follow`}
      className={className}
      label={label || t('follow')}
      styleVariation={styleVariation || StyleVariations.PRIMARY}
      styleModifier={styleModifier}
      disabled={isUserStateLoading || (isAuthenticated && !followingIdsLoaded)}
      onClick={doFollow}
    />
  );
};

FollowButton.propTypes = {
  namePrefix: PropTypes.string,
  className: PropTypes.string,
  styleVariation: PropTypes.oneOf(Object.values(StyleVariations)),
  followingStyleVariation: PropTypes.oneOf(Object.values(StyleVariations)),
  styleModifier: PropTypes.array,
  section: PropTypes.object.isRequired,
  smartMagazineSection: PropTypes.object,
  isFollowingSection: PropTypes.func.isRequired,
  isUserStateLoading: PropTypes.bool.isRequired,
  handleFollow: PropTypes.func.isRequired,
  handleUnfollow: PropTypes.func.isRequired,
  onPersonalize: PropTypes.func.isRequired,
  followingIdsLoaded: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  label: PropTypes.string,
  disablePersonalization: PropTypes.bool,
};
FollowButton.defaultProps = {
  namePrefix: '',
  className: '',
  disablePersonalization: false,
};

export default connector(
  connectUserState,
  connectAuthentication,
)(withFollow(withPersonalize(withT(FollowButton))));
