import { MAGAZINE_SUBSCRIPTION_TYPES } from 'Webapp/action-types';
import { set, setPayloadReducer } from 'Utils/redux';
import { currentUserUid } from 'Webapp/shared/app/redux/selectors/auth';
import FlapUtil from 'Utils/content/flap-util';
import {
  usageTrackMagazineSubscribe,
  usageTrackMagazineUnsubscribe,
} from 'Webapp/shared/app/redux/actions/usage-actions';

export interface CommentsReducerState {
  loaded: boolean;
  loading: boolean;
  subscriptions: Array<Flipboard.MagazineSubscription>;
}

const initialState: CommentsReducerState = {
  loaded: false,
  loading: false,
  subscriptions: [],
};

export const reducer = setPayloadReducer<
  typeof MAGAZINE_SUBSCRIPTION_TYPES,
  CommentsReducerState
>(MAGAZINE_SUBSCRIPTION_TYPES, initialState);

// actions
export const setMagazineSubscriptionsLoaded = (value: boolean) =>
  set(
    MAGAZINE_SUBSCRIPTION_TYPES.SET_MAGAZINE_SUBSCRIPTIONS_LOADED,
    'loaded',
    value,
  );

export const setMagazineSubscriptionsLoading = (value: boolean) =>
  set(
    MAGAZINE_SUBSCRIPTION_TYPES.SET_MAGAZINE_SUBSCRIPTIONS_LOADING,
    'loading',
    value,
  );

export const setMagazineSubscriptions = (
  subscriptions: Array<Flipboard.MagazineSubscription>,
) =>
  set(
    MAGAZINE_SUBSCRIPTION_TYPES.SET_MAGAZINE_SUBSCRIPTIONS,
    'subscriptions',
    subscriptions,
  );

export const loadMagazineSubscriptions =
  (): Flipboard.Thunk =>
  async (dispatch, getState, { flap }) => {
    try {
      dispatch(setMagazineSubscriptionsLoading(true));
      const uid = currentUserUid(getState());
      const {
        data: {
          result: { results },
        },
      } = await flap.get<Flipboard.FlapMagazineSubscriptionsResponse>(
        `/bellnotifications/subscriptions/${uid}`,
      );

      dispatch(
        setMagazineSubscriptions(
          results.map(({ remoteid, title }) => ({
            remoteid: FlapUtil.normalizeRemoteid(
              remoteid,
            ) as Flipboard.SectionId,
            title,
          })),
        ),
      );
      dispatch(setMagazineSubscriptionsLoaded(true));
    } finally {
      dispatch(setMagazineSubscriptionsLoading(false));
    }
  };

export const toggleMagazineSubscription =
  (magazine: Flipboard.Section): Flipboard.Thunk =>
  async (dispatch, getState, { flap }) => {
    try {
      dispatch(setMagazineSubscriptionsLoading(true));
      const uid = currentUserUid(getState());
      const subscriptions = getMagazineSubscriptionsSelector(getState());
      const isSubscribed = getIsSubscribed(subscriptions, magazine);
      const method = isSubscribed ? 'delete' : 'post';
      await flap[method](
        `/bellnotifications/status/${uid}?rawSections=${magazineSubscriptionId(
          magazine,
        )}`,
      );
      const trackUsageFn = isSubscribed
        ? usageTrackMagazineUnsubscribe
        : usageTrackMagazineSubscribe;
      await dispatch(trackUsageFn(magazine));
    } finally {
      dispatch(setMagazineSubscriptionsLoading(false));
    }
  };

// selectors
export const getMagazineSubscriptionsSelector = (state: Flipboard.State) =>
  state.magazineSubscriptions.subscriptions;
export const getMagazineSubscriptionsLoadingSelector = (
  state: Flipboard.State,
) => state.magazineSubscriptions.loading;
export const getMagazineSubscriptionsLoadedSelector = (
  state: Flipboard.State,
) => state.magazineSubscriptions.loaded;

// helpers
const magazineSubscriptionId = (magazine: Flipboard.Section) =>
  FlapUtil.normalizeRemoteid(
    magazine.ssid?.remoteidPlain || '',
  ) as Flipboard.SectionId;

export const getIsSubscribed = (
  subscriptions: CommentsReducerState['subscriptions'],
  magazine: Flipboard.Section,
) =>
  !!subscriptions.find((i) => i.remoteid === magazineSubscriptionId(magazine));
