import { TEXT_COLORS, COLORS, ALERTS } from './colors';
import { overflowWordBreak } from './definitions';

import { styleObject, nestedSelector } from 'Style/type-helpers';

const FONT_TIEMPOS = "'Tiempos', Georgia, serif";
const FONT_FAKT_FLIPBOARD = "'Fakt', AvenirNext-Medium, 'Segoe UI', sans-serif";
const FONT_FAKT_FLIPBOARD_CONDENSED =
  "'FaktCondensed', AvenirNextCondensed-Medium,'Segoe UI', sans-serif";
export const FONTS = Object.freeze({
  FONT_TIEMPOS,
  FONT_FAKT_FLIPBOARD,
  FONT_FAKT_FLIPBOARD_CONDENSED,
});

export const FONT_WEIGHTS = Object.freeze({
  NORMAL: 400,
  MEDIUM: 500,
  SEMIBOLD: 600,
  BOLD: 700,
  BLACK: 800,
});

export const CONTENT_BODY = Object.freeze(
  styleObject({
    fontFamily: FONT_FAKT_FLIPBOARD,
    fontWeight: FONT_WEIGHTS.NORMAL,
    color: TEXT_COLORS.primary,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.1px',
  }),
);

const brandHeading = styleObject({
  fontFamily: FONT_TIEMPOS,
  fontWeight: FONT_WEIGHTS.MEDIUM,
  fontSize: '30px',
  lineHeight: '36px',
  color: TEXT_COLORS.primary,
});

const body = styleObject({
  fontFamily: FONT_FAKT_FLIPBOARD,
  letterSpacing: '-0.1px',
  color: TEXT_COLORS.secondary,
});

const bodyStandardBase = styleObject({
  ...body,
  fontWeight: FONT_WEIGHTS.MEDIUM,
  fontSize: '14px',
  lineHeight: '20px',
});

/**
 * Formerly .ui-body--*
 */
export const BODY_TYPES = Object.freeze({
  XSMALL_STANDARD: styleObject({
    ...body,
    fontWeight: FONT_WEIGHTS.MEDIUM,
    fontSize: '13px',
    lineHeight: '16px',
  }),
  SMALL_STANDARD: bodyStandardBase,
  SMALL_EMPHASIS_1: styleObject({
    ...bodyStandardBase,
    color: TEXT_COLORS.primary,
  }),
  SMALL_EMPHASIS_2: styleObject({
    ...bodyStandardBase,
    color: TEXT_COLORS.primary,
    fontWeight: FONT_WEIGHTS.BOLD,
  }),
  XSMALL_EMPHASIS: styleObject({
    ...bodyStandardBase,
    fontSize: '9px',
    lineHeight: '12px',
    color: TEXT_COLORS.primary,
    fontWeight: FONT_WEIGHTS.BOLD,
  }),
  LARGE_STANDARD: styleObject({
    ...body,
    fontWeight: FONT_WEIGHTS.NORMAL,
    fontSize: '16px',
    lineHeight: '24px',
  }),
  BRAND_HEADING_MEDIUM: brandHeading,
  HINT: styleObject({
    ...body,
    fontSize: '12px',
    lineHeight: '140%',
    fontWeight: FONT_WEIGHTS.MEDIUM,
    color: TEXT_COLORS.tertiary,
  }),
  MEDIUM: styleObject({
    ...bodyStandardBase,
    fontSize: '18px',
    lineHeight: '28px',
  }),
});

/**
 * Formerly .ui-subheading--*
 */
const subHeading = styleObject({
  fontFamily: FONT_FAKT_FLIPBOARD,
  fontWeight: FONT_WEIGHTS.SEMIBOLD,
  letterSpacing: '-0.1px',
  color: TEXT_COLORS.primary,
});

export const UI_SUBHEADING_TYPES = Object.freeze({
  LARGE_STANDARD: styleObject({
    ...subHeading,
    fontSize: '16px',
    lineHeight: '24px',
  }),
  SMALL: styleObject({
    ...subHeading,
    fontSize: '12px',
    lineHeight: '16px',
  }),
});

/**
 * Formerly .ui-heading--*
 */
const UI_HEADING = styleObject({
  fontFamily: FONT_FAKT_FLIPBOARD_CONDENSED,
  fontWeight: FONT_WEIGHTS.BLACK,
  textTransform: 'uppercase',
  color: 'var(--color--text-primary)',
});
const UI_HEADING_SMALL_BASE = styleObject({
  ...UI_HEADING,
  fontSize: '16px',
  lineHeight: '20px',
});

export const UI_HEADING_TYPES = Object.freeze({
  XLARGE: styleObject({
    ...UI_HEADING,
    fontSize: '28px',
    lineHeight: '32px',
  }),
  LARGE: styleObject({
    ...UI_HEADING,
    fontSize: '24px',
    lineHeight: '28px',
  }),
  MEDIUM: styleObject({
    ...UI_HEADING,
    fontSize: '22px',
    lineHeight: '24px',
  }),
  SMALL_STANDARD: UI_HEADING_SMALL_BASE,
  SMALL_DIMMED: styleObject({
    ...UI_HEADING_SMALL_BASE,
    color: 'var(--color--text-secondary)',
  }),
});

const UI_TEXT = styleObject({
  ...overflowWordBreak,
  fontFamily: FONT_FAKT_FLIPBOARD,
});
const UI_TEXT_SUPPORTING_BASE = styleObject({
  ...UI_TEXT,
  fontSize: '14px',
  lineHeight: '1.2',
  fontWeight: FONT_WEIGHTS.MEDIUM,
  textTransform: 'none',
  color: 'var(--color--text-secondary)',
});

const UI_TEXT_TITLE_BASE = styleObject({
  ...overflowWordBreak,
  fontFamily: FONT_FAKT_FLIPBOARD_CONDENSED,
  fontWeight: FONT_WEIGHTS.BLACK,
  fontSize: '22px',
  lineHeight: '24px',
  textTransform: 'uppercase',
  color: TEXT_COLORS.primary,
  ...nestedSelector('a a:hover', {
    color: 'inherit',
  }),
});

/**
 * Formerly .ui-text--*
 */
export const UI_TEXT_TYPES = {
  BODY: styleObject({
    ...UI_TEXT,
    fontWeight: FONT_WEIGHTS.MEDIUM,
    fontSize: '16px',
    ...nestedSelector('a', {
      color: 'inherit',
    }),
  }),
  BODY_2: styleObject({
    ...UI_TEXT,
    fontWeight: FONT_WEIGHTS.MEDIUM,
    fontSize: '14px',
  }),
  SUPPORTING: UI_TEXT_SUPPORTING_BASE,
  SUPPORTING_EMPHASIS: styleObject({
    ...UI_TEXT_SUPPORTING_BASE,
    fontWeight: FONT_WEIGHTS.SEMIBOLD,
  }),
  SUPPORTING_DIM: styleObject({
    ...UI_TEXT_SUPPORTING_BASE,
    color: TEXT_COLORS.dim,
  }),
  SUPPORTING_LARGE: styleObject({
    ...UI_TEXT_SUPPORTING_BASE,
    fontSize: '16px',
    color: TEXT_COLORS.tertiary,
  }),
  SUPPORTING_ALT: styleObject({
    ...UI_TEXT_SUPPORTING_BASE,
    color: TEXT_COLORS.tertiary,
  }),
  TITLE: styleObject(UI_TEXT_TITLE_BASE),
  TITLE_SMALL: styleObject({
    ...UI_TEXT_TITLE_BASE,
    fontSize: '16px',
    lineHeight: '20px',
  }),
  TITLE_LARGE: styleObject({
    ...UI_TEXT_TITLE_BASE,
    fontSize: '40px',
    lineHeight: '42px',
  }),
  PAGE_TITLE: styleObject({
    ...UI_TEXT_TITLE_BASE,
    fontSize: '30px',
    lineHeight: '32px',
  }),
  ERROR: styleObject({
    fontFamily: FONT_FAKT_FLIPBOARD,
    fontWeight: FONT_WEIGHTS.MEDIUM,
    fontSize: '14px',
    color: ALERTS.error,
  }),
};

/**
 * Formerly .content-heading--*
 */
const CONTENT_HEADING = styleObject({
  fontFamily: FONT_FAKT_FLIPBOARD_CONDENSED,
  fontWeight: FONT_WEIGHTS.BLACK,
  textTransform: 'uppercase',
  color: TEXT_COLORS.primary,
  ...nestedSelector('a', { color: 'inherit' }),
});

export const CONTENT_HEADING_TYPES = {
  LARGE: styleObject({
    ...CONTENT_HEADING,
    fontSize: '30px',
    lineHeight: '32px',
  }),
  MEDIUM: styleObject({
    ...CONTENT_HEADING,
    fontSize: '28px',
    lineHeight: '30px',
  }),
  SMALL: styleObject({
    ...CONTENT_HEADING,
    fontSize: '24px',
    lineHeight: '28px',
  }),
};

/**
 * Formerly .content-story-heading--*
 */
const CONTENT_STORY_HEADING = styleObject({
  fontFamily: FONT_TIEMPOS,
  fontWeight: FONT_WEIGHTS.MEDIUM,
  color: 'var(--color--text-primary)',
  letterSpacing: '-0.3px',
  ...nestedSelector('a', { color: 'inherit' }),
});

const CONTENT_STORY_HEADING_LARGE_BASE = styleObject({
  ...CONTENT_STORY_HEADING,
  fontSize: '24px',
  lineHeight: '28px',
});
const CONTENT_STORY_HEADING_MEDIUM_BASE = styleObject({
  ...CONTENT_STORY_HEADING,
  fontSize: '18px',
  lineHeight: '24px',
});
const CONTENT_STORY_HEADING_SMALL_BASE = styleObject({
  ...CONTENT_STORY_HEADING,
  fontSize: '16px',
  lineHeight: '20px',
});

export const CONTENT_STORY_HEADING_TYPES = {
  LARGE: CONTENT_STORY_HEADING_LARGE_BASE,
  MEDIUM: CONTENT_STORY_HEADING_MEDIUM_BASE,
  SMALL: CONTENT_STORY_HEADING_SMALL_BASE,
  XSMALL: styleObject({
    ...CONTENT_STORY_HEADING,
    fontSize: '14px',
    lineHeight: '20px',
  }),
  LARGE_OVERLAY: styleObject({
    ...CONTENT_STORY_HEADING_LARGE_BASE,
    fontWeight: FONT_WEIGHTS.SEMIBOLD,
    color: TEXT_COLORS.overlay,
  }),
  MEDIUM_OVERLAY: {
    ...CONTENT_STORY_HEADING_MEDIUM_BASE,
    fontWeight: FONT_WEIGHTS.SEMIBOLD,
    color: TEXT_COLORS.overlay,
  },
  SMALL_OVERLAY: styleObject({
    ...CONTENT_STORY_HEADING_SMALL_BASE,
    fontWeight: FONT_WEIGHTS.SEMIBOLD,
    color: TEXT_COLORS.overlay,
  }),
};

const LINK_BASE = {
  cursor: 'pointer',
  textDecoration: 'none',
  color: TEXT_COLORS.secondary,
  transition: 'all $animation--duration $animation--timing',
  '&:hover, &:focus': {
    color: TEXT_COLORS.primary,
  },
  '&:active': {
    color: TEXT_COLORS.secondary,
  },
};
/**
 * Formerly .link--*
 */
export const LINK = {
  BASE: LINK_BASE,
  UNDERLINE: {
    textDecoration: 'underline',
    '&:hover, &:focus': {
      textDecoration: 'none',
    },
    '&:active': {
      textDecoration: 'underline',
    },
  },
  ALT: {
    color: TEXT_COLORS.overlay,
    textDecoration: 'underline',
    '&:hover': {
      color: TEXT_COLORS.secondaryReverse,
    },
  },
  LINK_EMPHASIS: {
    ...LINK_BASE,
    color: COLORS.red,
    '&:hover, &:focus': {
      color: COLORS.red,
      filter: 'brightness(0.9)', // darken(color(red), 10%)
    },
    '&:active': {
      color: COLORS.red,
      filter: 'brightness(0.85)', // darken(color(red), 15%)
    },
  },
};

const ARTICLE_TEXT_TITLE_BASE = {
  ...overflowWordBreak,
  fontFamily: FONT_TIEMPOS,
  fontWeight: FONT_WEIGHTS.MEDIUM,
  color: TEXT_COLORS.primary,
  a: { color: 'inherit' },
};

export const ARTICLE_TEXT_TITLE = {
  SMALL: { ...ARTICLE_TEXT_TITLE_BASE, fontSize: '16px', lineHeight: '1.1' },
  LARGE: { ...ARTICLE_TEXT_TITLE_BASE, fontSize: '32px', lineHeight: '40px' },
};

// Common max width for readability
// This is a rough pixel value determined by UX best practices.
// The perfect value, according to the masters, restricts the character count to 50-75 per line and is therefore based on font-size, font-weight, leading, and line-height.
export const MAX_WIDTH_READABILITY = '560px';

export const STATUS_POST_TITLE = {
  fontFamily: FONT_TIEMPOS,
  fontWeight: FONT_WEIGHTS.NORMAL,
  fontSize: '20px',
};

export const NO_TEXT_TRANSFORM = styleObject({
  textTransform: 'none',
});

export const PILL_TAG = styleObject({
  fontFamily: FONT_FAKT_FLIPBOARD_CONDENSED,
  fontWeight: FONT_WEIGHTS.SEMIBOLD,
  fontSize: '14px',
  textTransform: 'uppercase',
});

export const CARD_OVERLAY_TAG = styleObject({
  fontFamily: FONT_FAKT_FLIPBOARD_CONDENSED,
  fontWeight: FONT_WEIGHTS.SEMIBOLD,
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '0.5px',
  textTransform: 'uppercase',
});

export const UNSET_TEXT = styleObject({
  fontFamily: 'unset',
  fontSize: 'unset',
  fontStyle: 'unset',
  fontWeight: 'unset',
  textTransform: 'unset',
});
