import { factory as flaxiosFactory } from 'Utils/flaxios';

// Utils
import sentry from 'Utils/sentry';
import FlapUtil from 'Utils/content/flap-util';
import getOrigin from 'Utils/get-origin';
import ApiHelpers from 'Utils/api/helpers';
import getWindow from 'Utils/get-window';
import { setCookieValue } from 'Utils/client-cookie';

const isOauth2AuthenticationProblem = (response) => {
  if (!response || !response.data || !response.data.error) {
    return false;
  }
  const { code, status, reason } = response.data.error;
  if (code === 403 && status === 'Forbidden' && reason.match(/access token/i)) {
    return true;
  }
  return false;
};

/**
 * Creates a flaxios instance for making requests to Flap from clients,
 * proxied through webapp's server
 */
const flapClientFactory = (serviceName, basePath, options = {}) => {
  /**
   * Axios instance to make Flap requests from web-u client to web-u server
   */
  const flap = flaxiosFactory({
    baseURL: getOrigin() + basePath,
    // Do not timeout (this is the axios default, but leaving in to
    // document our reasoning).  On a slow 3g connection it can take a
    // long time to finish requests when other resources (images /
    // stlyes / etc) are also being loaded.  Currently, timeouts result
    // in "not found" errors that are inaccurate.  We can introduce a
    // timeout if we need to, but should improve the error messaging
    // first.
    timeout: 30000,
    headers: { 'Content-Type': 'application/json' },
    ...options,
  });

  /**
   * Request interceptor
   * Attaches a csrf token to the header of all requests
   */
  flap.interceptors.request.use((config) => {
    if (config.method.toLowerCase() === 'post') {
      config.headers['CSRF-Token'] = ApiHelpers.getCsrfTokenFromMetaElement();
    }
    return config;
  });

  /**
   * Handles default and Flap errors
   */
  flap.interceptors.response.use(
    (response) => {
      const { data } = response;
      const statusCode = FlapUtil.getResponseStatusCode(response);
      if (statusCode < 200 || statusCode > 299) {
        return Promise.reject(data);
      }
      return response;
    },
    (error) => {
      sentry.addBreadcrumb(
        `[${serviceName} Client] Failed Response Interceptor`,
      );
      const { response, code } = error;
      if (code === 'ECONNABORTED') {
        return Promise.reject(
          new Error(`[${serviceName} Client] Request timed out`),
        );
      }

      if (!response) {
        return Promise.reject(new Error(`[${serviceName} Client] No response`));
      }

      const statusCode = FlapUtil.getResponseStatusCode(response);
      if (statusCode !== 404) {
        sentry.captureException(error);
      }
      // 403 means that an oauth2_token was present but invalid. Redirect to token endpoint, to either
      // try to refresh token, or clear cookies if refresh token is invalid
      if (isOauth2AuthenticationProblem(response)) {
        setCookieValue(
          'redirect_after_auth',
          getWindow().location.pathname + getWindow().location.search,
        );
        getWindow().location = '/logout';
        return;
      }
      // TODO: This should probably be the error object, but changing this is probably not safe at this time.
      return Promise.reject(error.response);
    },
  );

  return flap;
};

export default flapClientFactory;
