import React from 'react';
import styled from '@emotion/styled';
import { TEXT_COLORS } from 'Style/colors';

import DateUtil from 'Utils/date-util';

import connector from 'Utils/connector';
import connectLanguage, {
  ConnectLanguageProps,
} from 'Webapp/shared/app/connectors/connectLanguage';

import withT from 'ComponentLibrary/hocs/withT';

const StyledNotificationTime = styled.time({ color: TEXT_COLORS.tertiary });

type NotificationTimeProps = ConnectLanguageProps & {
  notification: Flipboard.Notification;
  t: Flipboard.TFunction;
};

const NotificationTime: React.FC<NotificationTimeProps> = ({
  notification,
  lang,
  t,
}) => (
  <StyledNotificationTime>
    {DateUtil.friendlyFormat(notification.dateCreated, t, lang)}
  </StyledNotificationTime>
);

export default connector(connectLanguage)(withT(NotificationTime));
