/**
 * Returns a human readable number
 * @param {Number} number
 * @returns - Number string like 2.1M, 34k.
 */
export const getReadableNumber = (number) => {
  if (number > 999999) {
    return `${(number / 1000000).toFixed(1)}M`;
  } else if (number > 999) {
    return `${Math.floor(number / 1000)}k`;
  }

  return number;
};

/**
 * Rounds a number to a specific number o decimal places
 * Eg. 0.2, 10, 0.23, but never 0.40
 * @param {Number} number - number that represents a ratio
 * @param {Number} decimalPlace - max number of decimal places
 * @returns {String}
 */
export const round = (number = 0, decimalPlaces = 0) =>
  parseFloat(number.toFixed(decimalPlaces));

/**
 * Converts a number to percentage string
 * Eg. 0.2%, 5%, 100%, 10000%
 * @param {Number} number - number that represents a ratio
 * @param {Number} decimalPlace - max number of decimal places
 * @returns {String} readable % number string
 */
export const getPercentage = (number, decimalPlaces = 0) => {
  if (!number || typeof number !== 'number') {
    return '0%';
  }
  return `${round(number * 100, decimalPlaces)}%`;
};
