import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ICON_COLORS } from 'Style/colors';
import Icon from './icon';

export const StyledLockIcon = styled(Icon)({
  color: ICON_COLORS.overlay,
  path: { fill: 'currentColor' },
});

function LockIcon({ size, ...props }) {
  return (
    <StyledLockIcon
      name="lock"
      viewBox="0 0 17 18"
      size={size}
      {...props}
      renderComponent={() => (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.83333 4.83317C3.83333 2.53198 5.69881 0.666504 8 0.666504C10.3012 0.666504 12.1667 2.53198 12.1667 4.83317V7.33317H13C14.3807 7.33317 15.5 8.45246 15.5 9.83317V14.8332C15.5 16.2139 14.3807 17.3332 13 17.3332H3C1.61929 17.3332 0.5 16.2139 0.5 14.8332V9.83317C0.5 8.45246 1.61929 7.33317 3 7.33317H3.83333V4.83317ZM5.5 7.33317H10.5V4.83317C10.5 3.45246 9.38071 2.33317 8 2.33317C6.61929 2.33317 5.5 3.45246 5.5 4.83317V7.33317Z"
        />
      )}
    />
  );
}

LockIcon.propTypes = {
  size: PropTypes.number,
};

LockIcon.defaultProps = {
  size: 18,
};

export default LockIcon;
