import { Connector } from 'Webapp/utils/connector';
import { loadPreviousAndSaveCurrentVisitedItems } from '../redux/actions/section-actions';

import { isSectionRoute } from 'Webapp/shared/app/redux/selectors/routing';
import { getCurrentArticle } from 'Webapp/shared/app/redux/selectors/article';
import { getEditorialBoards } from 'Webapp/shared/app/redux/actions/content-actions';
import { addPostOnboardingSocialAction } from 'Webapp/shared/app/redux/actions/social-actions';
import { getPrimarySection } from 'Webapp/shared/app/redux/selectors/section';

const selectors = {
  contextualFollowSections: ({ content: { contextualFollowSections } }) =>
    contextualFollowSections,
  editorialBoards: ({ content }) => content.editorialBoards,
  primarySection: (state) =>
    (isSectionRoute(state) && getPrimarySection(state)) || null,
  currentArticle: getCurrentArticle,
};

const actions = {
  loadPreviousAndSaveCurrentVisitedItems,
  getEditorialBoards,
  addPostOnboardingSocialAction,
};

export type ConnectContextualOnboardingProps = Flipboard.ConnectorProps<
  typeof selectors,
  typeof actions
>;

const connectContextualOnboarding: Connector = {
  selectors,
  actions,
};

export default connectContextualOnboarding;
