import React from 'react';
import { OnboardingFlow } from 'Webapp/enums';
// Utils
import { setCookieValue } from 'Utils/client-cookie';
import getWindow from 'Utils/get-window';

// Components
import Button, {
  CombinedProps as ButtonProps,
} from 'Webapp/shared/app/components/button';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';
import connectAdjustNavFromRoute, {
  ConnectAdjustNavFromRouteProps,
} from 'Webapp/shared/app/connectors/connectAdjustNavFromRoute';
import connectOnboardingFlow, {
  ConnectOnboardingFlowProps,
} from 'Webapp/shared/app/connectors/connectOnboardingFlow';

const ALLOWED_BUTTON_PROPS = [
  'styleVariation',
  'styleModifier',
  'name',
  'className',
];
type ForwardableButtonProps = Pick<
  ButtonProps,
  'styleVariation' | 'styleModifier' | 'name' | 'className'
>;

export type SignupButtonPassedProps = ForwardableButtonProps & {
  overrideOnboardingFlow: OnboardingFlow;
  label: string | null;
  displayStyle: string;
  onClick?: (e: React.MouseEvent) => void;
  customUsageData?: object;
  continueFlow?: boolean;
};

export type SignupButtonProps = ConnectModalProps &
  ConnectAdjustNavFromRouteProps &
  ConnectOnboardingFlowProps &
  SignupButtonPassedProps & {
    // from withT
    t: (key: string) => string;
    // fromContainer
    usageTrackSignupButtonTap: (
      displayStyle: string,
      customUsageData?: object,
    ) => void;
  };

const SignupButton: React.FC<SignupButtonProps> = ({
  overrideOnboardingFlow,
  continueFlow,
  label,
  displayStyle,
  onClick,
  usageTrackSignupButtonTap,
  customUsageData,
  continueOnboardingFlow,
  startOnboardingFlow,
  t,
  ...otherButtonProps
}) => {
  const handleClick = (e: React.MouseEvent) => {
    if (e.ctrlKey) {
      return;
    }

    usageTrackSignupButtonTap(displayStyle, customUsageData);
    const in15Mins = new Date(Date.now() + 1000 * 60 * 15);
    setCookieValue(
      'redirect_after_auth',
      getWindow().location.pathname + getWindow().location.search,
      in15Mins,
    );

    if (onClick) {
      onClick(e);
    }

    e.preventDefault();
    if (continueFlow) {
      continueOnboardingFlow(overrideOnboardingFlow);
    } else {
      startOnboardingFlow(overrideOnboardingFlow);
    }
  };

  const forwardProps: ForwardableButtonProps = Object.fromEntries(
    Object.entries(otherButtonProps).filter(([key]) =>
      ALLOWED_BUTTON_PROPS.includes(key),
    ),
  );
  return (
    <Button
      href="/signup"
      {...forwardProps}
      label={label || t('sign_up')}
      onClick={handleClick}
      tooltip={t('signup_tooltip')}
    />
  );
};

SignupButton.defaultProps = {
  label: null,
  overrideOnboardingFlow: OnboardingFlow.SIGNUP_BUTTON,
};

const ConnectedSignupButton = connector<SignupButtonPassedProps>(
  connectModal,
  connectOnboardingFlow,
  connectAdjustNavFromRoute,
)(withT(SignupButton));

export default ConnectedSignupButton;
