import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { UI_HEADING_TYPES, BODY_TYPES } from 'Style/typography';
import { MODAL } from 'Style/modal';

// HOCs
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectModal from 'Webapp/shared/app/connectors/connectModal';

// Utils
import replaceTags from 'Utils/i18n/replace-tags';

// Components
import ModalWrapper from 'Webapp/shared/app/modals/modal-wrapper';
import { MODAL_THEMES, MODAL_MODIFIERS } from 'ComponentLibrary/modals/modal';
import Button, { StyleVariations } from 'Webapp/shared/app/components/button';
import ModalControls from 'Webapp/shared/app/components/modal-controls';
import SectionTag, {
  STYLE_VARIATIONS as TOPIC_TAG_STYLE_VARIATIONS,
} from 'Webapp/shared/app/components/section-tag';

const Content = styled.div(MODAL.content);
const Controls = styled(ModalControls)({
  borderTop: '0px',
  padding: `${SPACING.LARGE} ${SPACING.BASE4X} ${SPACING.BASE4X}`,
});

const Title = styled.h1(UI_HEADING_TYPES.MEDIUM, MODAL.title);
const Description = styled.p(BODY_TYPES.LARGE_STANDARD, {
  marginBottom: SPACING.XLARGE,
});
const SubmitButton = styled(Button)({ marginLeft: SPACING.BASE });

export const AddFavoriteConfirmation = ({
  topic,
  dismissModal,
  onSubmit,
  isMagazineInviteFlow,
  t,
}) => {
  const handleSubmit = () => {
    onSubmit();
    dismissModal();
  };
  const topicTagComponent = (
    <SectionTag
      section={topic}
      styleVariation={TOPIC_TAG_STYLE_VARIATIONS.COLOR_ONLY}
      noLink
    />
  );

  const titleModalText = isMagazineInviteFlow
    ? t('add_to_favorites')
    : replaceTags(
        t('add_topic_to_favorites', {
          topicTitle: topic.title,
        }),
        {
          topicTag: () => topicTagComponent,
        },
      );

  const descriptionModalText = isMagazineInviteFlow
    ? t('add_to_favorites_home_carousel_description')
    : t('add_to_favorites_description', {
        topicTitle: topic.title,
      });

  const submitButtonText = isMagazineInviteFlow
    ? t('add')
    : t('add_to_favorites');
  return (
    <ModalWrapper
      name="add-favorite-confirmation-modal"
      modalTheme={MODAL_THEMES.WINDOW}
      modifier={MODAL_MODIFIERS.HAS_NO_HEADER}
    >
      <Content>
        <Title>
          <span>{titleModalText}</span>
        </Title>
        <Description>{descriptionModalText}</Description>
      </Content>
      <Controls fixedControls>
        <Button
          name="topic-customization-cancel"
          styleVariation={StyleVariations.SECONDARY}
          onClick={() => {
            dismissModal();
          }}
        >
          {t('not_now')}
        </Button>
        <SubmitButton
          name="topic-customization-done"
          styleVariation={StyleVariations.PRIMARY}
          onClick={handleSubmit}
        >
          {submitButtonText}
        </SubmitButton>
      </Controls>
    </ModalWrapper>
  );
};

AddFavoriteConfirmation.propTypes = {
  topic: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dismissModal: PropTypes.func.isRequired,
  isMagazineInviteFlow: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

AddFavoriteConfirmation.defaultProps = {};

export default connector(connectModal)(withT(AddFavoriteConfirmation));
