import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Config from 'Config';

// Utils
import { AppTheme } from 'Webapp/enums';
import sentenceCase from 'Utils/content/sentence-case';
import getWindow from 'Utils/get-window';
import { USAGE_NAV_FROMS, USAGE_EVENT_NAMES } from 'Utils/analytics/usage';

import {
  CUSTOM_BANNER_IDS,
  isBannerDismissed,
  reEnableBanner,
} from '../../../../client/lib/banners';

// Components
import CheckmarkIcon from 'ComponentLibrary/icons/checkmark';
import WebLink from '../web-link';
import UnstyledButton from 'Webapp/shared/app/components/unstyled-button';
import NavigationProfileAvatar from '../../containers/navbar/profile-avatar';

// HOCs
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectTheme from 'Webapp/shared/app/connectors/connectTheme';
import connectUsageSetNavFrom from 'Webapp/shared/app/connectors/connectUsageSetNavFrom';

function toggleAppTheme(theme, setAppThemeOverride) {
  const newTheme = theme === AppTheme.DARK ? AppTheme.DEFAULT : AppTheme.DARK;
  setAppThemeOverride(newTheme);
  getWindow().localStorage.setItem('appThemeOverride', newTheme);
}

class NavbarProfile extends Component {
  constructor(props) {
    super(props);

    this.state = { isMenuOpen: false };
    this.handleClickProfileMenu = this.handleClickProfileMenu.bind(this);
    this.handleClickDownloadApp = this.handleClickDownloadApp.bind(this);
  }

  handleClickProfileMenu() {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  }

  handleClickDownloadApp() {
    const { usageSetNavFrom, refreshBanners } = this.props;
    usageSetNavFrom(USAGE_EVENT_NAMES.BANNER_ENTER, USAGE_NAV_FROMS.NAV_BAR);
    reEnableBanner(CUSTOM_BANNER_IDS.DOWNLOAD_APP);
    refreshBanners();
    this.handleClickProfileMenu();
  }

  render() {
    const { profileSection, appThemeOverride, setAppThemeOverride, t } =
      this.props;

    return (
      <React.Fragment>
        <NavigationProfileAvatar
          profileSection={profileSection}
          onProfileMenuClick={this.handleClickProfileMenu}
        />
        {this.state.isMenuOpen && [
          <ul
            key="profile-dropdown"
            className="profile-dropdown main-nav__dropdown"
          >
            <li className="profile-dropdown__item">
              <WebLink
                name="main-nav-profile"
                href="/profile"
                className="profile-dropdown__link"
                navFromEventName={USAGE_EVENT_NAMES.SECTION_ENTER}
              >
                {sentenceCase(t('profile'))}
              </WebLink>
            </li>
            <li className="profile-dropdown__item">
              <WebLink
                name="main-nav-analytics"
                href="/analytics"
                className="profile-dropdown__link"
                navFromEventName={USAGE_EVENT_NAMES.ANALYTICS_ENTER}
              >
                {sentenceCase(t('analytics'))}
              </WebLink>
            </li>
            <li className="profile-dropdown__item">
              <WebLink
                name="main-nav-settings"
                href="/settings"
                className="profile-dropdown__link"
              >
                {sentenceCase(t('settings'))}
              </WebLink>
            </li>
            <li className="profile-dropdown__item">
              <WebLink
                name="profile-dropdown-toggle-theme"
                className="profile-dropdown__link"
                onClick={(e) => {
                  e.preventDefault();
                  toggleAppTheme(appThemeOverride, setAppThemeOverride);
                  this.handleClickProfileMenu();
                }}
              >
                {appThemeOverride === AppTheme.DARK && (
                  <CheckmarkIcon
                    className="profile-dropdown__link-icon"
                    color="#E12828"
                  />
                )}
                {sentenceCase(t('dark_mode'))}
              </WebLink>
            </li>
            <li className="profile-dropdown__item">
              <WebLink
                name="main-nav-privacy-policy"
                href={`${Config.ABOUT_BASE_URL}/privacy/`}
                className="profile-dropdown__link"
                onClick={this.handleClickProfileMenu}
                openInNewWindow
              >
                {sentenceCase(t('privacy_policy'))}
              </WebLink>
            </li>
            <li className="profile-dropdown__item">
              <WebLink
                name="data-protection-settings"
                href="/donotsellmyinfo"
                className="profile-dropdown__link"
              >
                {t('privacy_settings_title')}
              </WebLink>
            </li>

            {isBannerDismissed(CUSTOM_BANNER_IDS.DOWNLOAD_APP) && (
              <li className="profile-dropdown__item">
                <UnstyledButton
                  name="main-nav-download-app"
                  className="profile-dropdown__link"
                  onClick={this.handleClickDownloadApp}
                >
                  {sentenceCase(t('download_app'))}
                </UnstyledButton>
              </li>
            )}
            <li className="profile-dropdown__item">
              <WebLink
                name="main-help"
                href={`${Config.ABOUT_BASE_URL}/help-center/`}
                className="profile-dropdown__link"
              >
                {sentenceCase(t('help'))}
              </WebLink>
            </li>
            <li className="profile-dropdown__item">
              <WebLink
                name="main-nav-logout"
                href="/logout"
                className="profile-dropdown__link"
              >
                {sentenceCase(t('log_out'))}
              </WebLink>
            </li>
          </ul>,
          <div
            key="offclick"
            className="offclick"
            onClick={this.handleClickProfileMenu}
          />,
        ]}
      </React.Fragment>
    );
  }
}

NavbarProfile.propTypes = {
  profileSection: PropTypes.object,
  setAppThemeOverride: PropTypes.func,
  appThemeOverride: PropTypes.string,
  usageSetNavFrom: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  refreshBanners: PropTypes.func.isRequired,
};

NavbarProfile.defaultProps = {
  profileSection: null,
};

export default connector(
  connectTheme,
  connectUsageSetNavFrom,
)(withT(NavbarProfile));
