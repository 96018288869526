import React from 'react';
import PropTypes from 'prop-types';
import Config from '../../config';
import { CTAType } from 'Webapp/enums';

// Utils
import PropValidator from 'Utils/react-prop-validator';
import sentenceCase from 'Utils/content/sentence-case';
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';
import { GA } from 'Utils/analytics';

// Components
import Button, {
  StyleVariations,
  StyleModifiers,
} from 'Webapp/shared/app/components/button';

import withNavFrom from '../hocs/withNavFrom';
import withAdjustUrl from '../hocs/withAdjustUrl';
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectNavStoreAppUrl from 'Webapp/shared/app/connectors/connectNavAppStoreUrl';
import connectUsageSetNavFrom from 'Webapp/shared/app/connectors/connectUsageSetNavFrom';
import connectAmp from 'Webapp/shared/app/connectors/connectAmp';

function GetAppButton({
  name,
  appStoreUrl,
  navAppStoreUrl,
  isAmp,
  navFrom,
  styleVariation,
  styleModifier,
  getAdjustUrl,
  onClick,
  usageSetNavFrom,
  usageTrackTapOpenInAppButton,
  t,
}) {
  // Always use config value if AMP.
  // Default to the nav app store URL but allow
  // overriding for other scenarios
  const adjustBaseUrl = isAmp
    ? Config.APP_STORE_URL_FOR_NAV_AMP
    : appStoreUrl || navAppStoreUrl;
  const adjustUrl = getAdjustUrl(adjustBaseUrl, navFrom);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    usageSetNavFrom(USAGE_EVENT_NAMES.OPEN_IN_APP, navFrom);
    usageTrackTapOpenInAppButton();
    GA.trackClickButton(CTAType.DOWNLOAD_APP);
  };

  return (
    <Button
      name={name}
      styleVariation={styleVariation}
      styleModifier={styleModifier}
      href={adjustUrl}
      onClick={handleClick}
      tooltip={t('open_in_app_tooltip')}
    >
      {sentenceCase(t('open_in_app'))}
    </Button>
  );
}

GetAppButton.propTypes = {
  name: PropTypes.string.isRequired,
  isAmp: PropTypes.bool,
  appStoreUrl: PropTypes.string,
  navAppStoreUrl: PropTypes.string.isRequired,
  navFrom: PropTypes.string,
  styleVariation: PropTypes.oneOf(Object.values(StyleVariations)),
  styleModifier: PropTypes.arrayOf(PropValidator.memberOf(StyleModifiers)),
  getAdjustUrl: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  usageSetNavFrom: PropTypes.func.isRequired,
  usageTrackTapOpenInAppButton: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

GetAppButton.defaultProps = {
  navFrom: null,
  styleVariation: StyleVariations.PRIMARY,
  styleModifier: [StyleModifiers.NONE],
  appStoreUrl: null,
  onClick: null,
};

export default connector(
  connectUsageSetNavFrom,
  connectAmp,
  connectNavStoreAppUrl,
)(withT(withNavFrom(withAdjustUrl(GetAppButton))));
