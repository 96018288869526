import {
  currentUser,
  currentUserVerified,
  currentUserVerifiedPublisher,
  currentUserProfileSection,
  allUserMagazines,
  userStateDataSelector as currentUserData,
  isUserStateLoadingSelector as isUserStateLoading,
  isFavoritesFull,
} from 'Webapp/shared/app/redux/selectors/profile';

import {
  fetchUserInfo,
  addToCarousel,
} from 'Webapp/shared/app/redux/actions/profile-actions';
import { Connector } from 'Webapp/utils/connector';

const selectors = {
  currentUser,
  currentUserVerified,
  currentUserVerifiedPublisher,
  currentUserProfileSection,
  allUserMagazines,
  currentUserData,
  profile: ({ profile }: Flipboard.State) => profile,
  publisher: ({ publisher: { publisherInfo } }: Flipboard.State) =>
    publisherInfo,
  isFavoritesFull,
  isUserStateLoading,
};

const actions = {
  fetchUserInfo,
  addToCarousel,
};

export type ConnectCurrentUserProps = Flipboard.ConnectorProps<
  typeof selectors
>;

const connectCurrentUser: Connector = {
  selectors,
  actions,
};

export default connectCurrentUser;
