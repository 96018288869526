import React, { useEffect } from 'react';
import getWindow from 'Utils/get-window';
import logger from 'Utils/logger';

// interface Props {}
interface ClickOutsideComponentProps {
  handleClickOutside: (e: MouseEvent) => void;
}

const withClickOutside =
  <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
  (props) => {
    useEffect(() => {
      getWindow().addEventListener('click', doHandleClickOutside, true);

      return () => {
        window.removeEventListener('click', doHandleClickOutside, true);
      };
    }, []);

    const wrapperRef = React.useRef<HTMLDivElement>(null);
    const componentRef = React.useRef<HTMLElement & ClickOutsideComponentProps>(
      null,
    );

    const doHandleClickOutside = (e) => {
      if (!wrapperRef) {
        return;
      }
      const { current } = wrapperRef;
      if (current && !current.contains(e.target)) {
        const currentComponentRef = componentRef && componentRef.current;
        if (!currentComponentRef?.handleClickOutside) {
          return logger.warn(
            `Components using withClickOutside should implement a handleClickOutside function. See the Component: ${Component.name}`,
          );
        }
        currentComponentRef.handleClickOutside(e);
      }
    };

    return (
      <div ref={wrapperRef}>
        <Component {...props} ref={componentRef} />
      </div>
    );
  };

export default withClickOutside;
