import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getSectionPath } from 'Utils/content/flipboard-urls';
import replaceTags from 'Utils/i18n/replace-tags';

// Components
import WebLink from '../web-link';
import withT from 'ComponentLibrary/hocs/withT';

const ToastTemplateFlipped = ({ magazine, t }) => {
  const message = t('flipped_into_magazine_success2', {
    magazineTitle: magazine.title,
  });

  return replaceTags(message, {
    magazineLink: function MagazineLink(contents) {
      return (
        <WebLink
          className="toast-message__link"
          href={getSectionPath(magazine)}
        >
          {contents}
        </WebLink>
      );
    },
  });
};

ToastTemplateFlipped.propTypes = {
  magazine: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withT(ToastTemplateFlipped);
