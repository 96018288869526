import Config from 'Config';

import flaxios from 'Utils/flaxios';
import getWindow from 'Utils/get-window';

export default () => {
  const { COMSCORE } = getWindow() || {};
  COMSCORE &&
    typeof COMSCORE.beacon === 'function' &&
    COMSCORE.beacon({
      c1: '2',
      c2: Config.COMSCORE_CLIENT_ID,
    });
  // fire and forget, required dummy response for comscore
  // pageview_candidate
  flaxios.get(`/comscore_pageview`, {
    params: {
      u: new Date().getTime(),
    },
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });
};
