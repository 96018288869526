import { stripParams, euc } from 'Utils/url';
import {
  isSupportedType,
  isSidebar,
  isMagazine,
  isGroup,
  getImage,
  projection,
} from 'Utils/content/item-util';
import SectionUtil from 'Utils/content/section-util';
import NglFeedConfigUtil from 'Utils/ngl/ngl-feed-config-util';
import { langLocale } from 'Webapp/server/lib/lang-locale';
import { arrayFlatten } from 'Utils/array-flatten';

import { FlapResponseCodes } from 'Webapp/enums';

export const REMOTEID_USER_PREFIX = 'flipboard/user';
const REMOTEID_USERNAME_PREFIX = 'flipboard/username/';

const LIKES_SECTION_REMOTEID = 'auth/flipboard/curator%2Flikes';

const getTranslationFromMap = (map, key, t) => map[key] && t(map[key]);

const getDefaultErrorMessage = (key, t) =>
  getTranslationFromMap(
    {
      [FlapResponseCodes.GENERIC_ERROR]: 'default_error_message_generic_error',
      [FlapResponseCodes.MULTIPLE_ACCOUNTS_FOR_EMAIL_ADDRESS]:
        'default_error_message_multiple_accounts_for_email_address',
      [FlapResponseCodes.INVALID_CREDENTIALS]:
        'default_error_message_invalid_credentials',
      [FlapResponseCodes.NO_ASSOCIATED_ACCOUNT]:
        'default_error_message_no_associated_account',
      [FlapResponseCodes.NON_EXISTANT_ACCOUNT]:
        'default_error_message_non_existant_account',
    },
    key,
    t,
  );

const getForgotPasswordErrorMessage = (key, t) =>
  getTranslationFromMap(
    {
      [FlapResponseCodes.MULTIPLE_ACCOUNTS_FOR_EMAIL_ADDRESS]:
        'forgot_password_error_message_multiple_accounts_for_email_address',
      [FlapResponseCodes.INVALID_CREDENTIALS]:
        'forgot_password_error_message_invalid_credentials',
      [FlapResponseCodes.NON_EXISTANT_ACCOUNT]:
        'forgot_password_error_message_non_existant_account',
    },
    key,
    t,
  );

const STORYBOARD_SECTION_ID_REGEXP = /^sid\/[a-z0-9]{16}\//i;
const MAGAZINE_SECTION_ID_REGEXP = /^sid\/[a-z0-9]{9}\//i;
const TOPIC_SECTION_ID_REGEXP = /^flipboard\/topic\//i;

/**
 * Returns the first item of type "metadata" in a stream
 * @param {Array} stream - An Array of streamed items
 * @return {Object} - The metadata item or an empty object
 */
function metadata(stream) {
  return stream.find((item) => item.type === 'metadata') || {};
}

/**
 * Returns an array of section items (type "metadata" and contains a section)
 * @param {Array} stream - An array of streamed items
 * @returns {Array} An array of sections
 */
function sectionMetadataItems(stream) {
  return (
    stream.filter((item) => item.type === 'metadata' && item.section) || []
  );
}

/**
 * Finds the metrics property on the first group of the first
 * sidebar in a stream; Optionally matches for the first with a matching sidebarId.
 * @param {Array} stream - An Array of streamed items
 * @param {String} secionId - Optionally a sectionId for which to filter metrics.
 * @return {Object} - A metrics object or null
 */
function metrics(stream, sectionId = null) {
  const sidebar =
    stream.find(
      (item) =>
        item.type === 'sidebar' &&
        item.sidebarType === 'sidebar' &&
        (sectionId ? item.sectionID === sectionId : true),
    ) || {};

  const magazinesGroup =
    sidebar &&
    sidebar.groups &&
    sidebar.groups.find((g) => g.usageType === 'magazines');

  return (magazinesGroup && magazinesGroup.metrics) || null;
}

/**
 * Process a raw FLAP updateFeed stream and return a raw section object
 * @param {Array} stream                - Array of raw FLAP update feed response items
 * @param {Object} nglFeedConfigs       - NGL feed configs object
 * @return {Object}                     - A raw section object with any necessary
 * low-level conversions applied (NGL promotion, etc)
 */
function getSectionFromStream(stream, nglFeedConfigs = []) {
  const metadataItem = metadata(stream);
  const { section } = metadataItem;

  // Return early if no section property found
  if (!section) {
    return null;
  }

  // Pull up high-level properties as needed
  section.sectionID = metadataItem.sectionID;
  section.invite = metadataItem.invite;

  // Decorate the section object with metrics from the stream
  section.metrics = metrics(stream);

  // Section is NGL-promoted
  if (NglFeedConfigUtil.sectionIsNgl(nglFeedConfigs, section)) {
    const nglFeedConfig = NglFeedConfigUtil.getConfig(nglFeedConfigs, section);
    return NglFeedConfigUtil.convertSectionToStoryboard(
      section,
      nglFeedConfig || {},
    );
  }

  return section;
}

/**
 * Returns an array of the "section" items from a stream of metaDataItems. Combines any corresponding
 * metrics items if found.
 * @param {Array} stream - An Array of streamed items
 * @returns
 */
function getBasicSectionsFromStream(stream) {
  return sectionMetadataItems(stream).map((metadataItem) => ({
    ...metadataItem.section,
    sectionID: metadataItem.sectionID,
    metrics: metrics(stream, metadataItem.sectionID),
  }));
}

const nextPageKeyFromStream = (stream) => {
  if (!stream || !Array.isArray(stream) || stream.length === 0) {
    return null;
  }
  // items with groupType: topicImages have ids
  // that can not be used for pagination
  const filtered = stream.filter(
    (item) => isSupportedType(item) && item?.groupType !== 'topicImages',
  );

  if (filtered.length === 0) {
    return null;
  }
  const lastItem = filtered.slice(-1)[0];
  if (!lastItem) {
    return null;
  }
  return lastItem.nextPageKey || lastItem.id || null;
};

/**
 * Looks through an updateFeed stream for raw items.  Uses
 * previousRawItems param to support paging
 * @param {Array} stream - An Array of streamed items
 * @param {Array} previousRawItems - The previously-fetched raw items
 * @return {Array} - Array of all raw items currently loaded for section
 */
function sectionRawItemsFromStream(stream, previousRawItems = null) {
  let rawItems = stream.filter((item) => isSupportedType(item));
  // receving an additional page of items
  if (previousRawItems !== null) {
    rawItems = previousRawItems.concat(rawItems);
  }

  // remove duplicates from raw items
  // TODO: Figure out why we are getting duplicates
  const rawItemObject = rawItems.reduce((collect, current) => {
    if (collect[current.id]) {
      return collect;
    }
    collect[current.id] = current;
    return collect;
  }, {});

  return Object.values(rawItemObject);
}

/**
 * Processes an array of raw items, applying NGL conversion if
 * needed.
 * @param {Array} rawItems - An Array of raw items
 * @param {Object} section  - A raw section object
 * @param {Object} nglFeedConfigs       - NGL feed configs object
 * @return {Array} - Array of raw items, converted for NGL if needed
 */
function nglProcessedRawItems(
  rawItems = [],
  section = null,
  nglFeedConfigs = [],
) {
  let processedRawItems = rawItems;
  // Section is NGL-promoted, convert items to groups
  if (NglFeedConfigUtil.sectionIsNgl(nglFeedConfigs, section)) {
    processedRawItems = NglFeedConfigUtil.getSectionStoryboardtems(
      nglFeedConfigs,
      section,
      processedRawItems,
    );
    // Section is native storyboard, ensure all top-level items are groups
  }

  // Convert section item's sections to storyboards as necessary for NGL
  // as directed in the NGLFeedConfig
  // TODO: Remove when NGL is fed by FLAP
  processedRawItems = NglFeedConfigUtil.convertSectionItemsToNgl(
    nglFeedConfigs,
    processedRawItems,
  );

  return processedRawItems;
}

/**
 * Returns a profile likes remoteid optionally scoped to a uid
 * @param {String} uid - a user uid
 * @return {String} - profile likes remoteid
 */
function profileLikesRemoteId(uid) {
  return uid ? `${LIKES_SECTION_REMOTEID}/${uid}` : LIKES_SECTION_REMOTEID;
}

/**
 * Returns the first item with an "EOS" property and type "metadata"
 * @param {Array} stream  - An Array of streamed items
 * @return {Object} - The EOS metadata for the stream, or null
 */
function sectionEosMetadata(stream) {
  return stream.find((item) => item.EOS && item.type === 'metadata');
}

/**
 * Returns whether or not the stream has a "noItemStatus" state
 * @param {Array} stream  - An Array of streamed items
 * @return {Boolean} - True if the stream has a "noItemStatus" state
 */
function noItemStatus(stream) {
  const eosMetadata = sectionEosMetadata(stream);
  const section = getSectionFromStream(stream);
  return !!(
    eosMetadata &&
    eosMetadata.noItemStatus &&
    eosMetadata.noItemsText &&
    !section?.invite
  );
}

function isAccessTokenExpired(responseData) {
  if (!responseData) {
    return false;
  }
  if (responseData.errorcode === FlapResponseCodes.EXPIRED_TOKEN) {
    return true;
  }
  if (
    responseData.stream &&
    responseData.stream[0] &&
    responseData.stream[0].action === 'resetUser'
  ) {
    return true;
  }
  if (responseData.action === 'resetUser') {
    return true;
  }
  return false;
}

/**
 * Returns whether or not the stream has a "neverLoadMore" state
 * @param {Array} stream  - An Array of streamed items
 * @return {Boolean} - True if the stream has a "neverLoadMore" state
 */
function neverLoadMore(stream) {
  const eosMetadata = sectionEosMetadata(stream);
  return !!(eosMetadata && eosMetadata.neverLoadMore) || false;
}

/**
 * Looks through an updateFeed stream for magazines in profiles
 * @param {Array}  stream - An Array of streamed items
 * @return {Array}        - Array of magazine items
 */
function getMagazinesFromStream(stream) {
  const sidebars = stream.filter((item) => isSidebar(item));
  return sidebars.reduce((acc, sidebar) => {
    const items = sidebar.items || [];
    const magazines = items.filter((item) => isMagazine(item));
    return acc.concat(magazines);
  }, []);
}

/**
 * Looks through an updateFeed stream for "See More" items for a given
 * remoteid
 * @param {Array}  stream   - An Array of streamed items
 * @param {String} remoteid - Remoteid of See More section
 * @return {Object}         - "See More" section
 */
function getRelatedArticlesSectionFromStream(stream, remoteid) {
  return stream.find((i) => isGroup(i) && i.sectionID === remoteid) || null;
}

/**
 * Gets topic tag from remoteid
 * @param  {String} remoteid - Flipboard remoteid
 * @return {String} topic tag for a topic
 */
function getTopicTagFromRemoteid(remoteid) {
  return normalizeRemoteid(remoteid).replace('flipboard/topic/', '');
}

/**
 * Taken an access_token generated by Flap, and returns { uid, udid, tuuid }
 * @param {String} token - Access token generated by Flap in the format: "2050227062|e3699151-dc3d-4192-a650-f5b28a1b54db|1565628803150.0a7aba07-6504-41b6-bb68-4dc719f523b2"
 * @return {Object} - { uid, udid, tuuid }
 */
function getIdsFromToken(token = '') {
  const ids = decodeURIComponent(token).split('|');
  if (ids.length < 3) {
    return { id: 0 };
  }
  return { id: ids[0], udid: ids[1], tuuid: ids[2] };
}

/**
 * Builds an access token
 * @param {String} uid
 * @param {String} udid
 * @param {String} tuuid
 */
function generateAccessToken(uid, udid, tuuid) {
  return `${uid}|${udid}|${tuuid}`;
}

function getRemoteidByUserid(userid) {
  return `${REMOTEID_USER_PREFIX}%2F${userid}`;
}

function getUseridByRemoteid(remoteid) {
  return remoteid.replace(`${REMOTEID_USER_PREFIX}%2F`, '');
}

function getProfileVideoRemoteid(userid) {
  return `flipboard/list%2Fvideos%2F${userid}`;
}

/**
 * Builds a remoteid for similar storyboards for a given item
 * @param {Object} item - Item Projection
 * @param {Object} relatedArticlesSection - see more section link
 * @returns {String} section remote id
 */
function getRelatedStoryboardsRemoteId(item, relatedArticlesSection = null) {
  if (!item) {
    return null;
  }
  if (item.isTopic || item.rootTopic) {
    const topicTag = item.rootTopic?.remoteid
      ? FlapUtil.getTopicTagFromRemoteid(item.rootTopic.remoteid)
      : item.topicTag;
    return `flipboard/storyboards%2F${topicTag}`;
  }

  const remoteIdPlain =
    relatedArticlesSection &&
    relatedArticlesSection.ssid &&
    relatedArticlesSection.ssid.remoteidPlain;
  if (remoteIdPlain) {
    return remoteIdPlain.replace('similarArticles', 'similarStoryboards');
  }
  const additionalUsageURL = item.additionalUsage && item.additionalUsage.url;
  const sourceURL = item.sourceURL;
  if (additionalUsageURL || sourceURL) {
    return `flipboard/similarStoryboards%2F${euc(
      additionalUsageURL ? additionalUsageURL : sourceURL,
    )}`;
  }
  return null;
}

/**
 * returns a new object of data, derived from other objects and arrays
 * @param  {Object} stats - Contains nested profileMetrics array, containing values for flips, likes, and followers
 * @param  {Object} magazines - Contains nested magazines array
 * @param  {Array}  following - Array of following objects
 * @param  {Array}  smartMagazines - Smart magazines
 * @param  {Array}  communityGroups - Community group magazines
 * @return {Object}
 */
function getUserStats({
  stats,
  contributorMagazines,
  smartMagazines,
  communityGroups,
}) {
  if (!stats && !contributorMagazines && !smartMagazines && !communityGroups) {
    return null;
  }

  let userStats = {};
  const profileMetrics = stats && stats.profileMetrics;
  if (profileMetrics) {
    userStats = SectionUtil.getSectionMetrics({
      metrics: stats.profileMetrics,
    });
  }
  if (contributorMagazines) {
    userStats.groupMagazines = contributorMagazines.length;
  }
  if (smartMagazines) {
    userStats.smartMagazines = smartMagazines.length;
  }
  if (communityGroups) {
    userStats.communityGroups = communityGroups.length;
  }

  return userStats;
}

/**
 * Returns the first image found in a stream of Flap items. Returns early if found
 * @param {Array<Object>} items - Flap items
 * @returns {Object} Flap item map structure
 */
function getImageFromStream(items) {
  for (const item of items) {
    let image = getImage(item);
    if (image) {
      return image;
    }
    if (item.items) {
      image = getImageFromStream(item.items);
      if (image) {
        return image;
      }
    }
  }
  return null;
}

/**
 * Return Flap image Map structures for the given remoteids in the provided stream of items
 * @param {Array<String>} remoteids - Array of remoteids
 * @param {Array<Object>} items - Array of Flap updateFeed items
 * @returns {Object<String, Object>} - Object keyed by remoteids, containing Flap Image Map structures
 */
function getSectionCoversFromStream(remoteids, items) {
  return remoteids.reduce((acc, id) => {
    const currentItems = items.filter((i) => i.sectionID === id);
    const image = getImageFromStream(currentItems);
    acc[id] = image;
    return acc;
  }, {});
}

/**
 * Looks into successful Flap responses for custom response codes, falling back to
 * default response status codes.
 * @param {Object} response - axios response
 * @returns {Number} Interger status code of the response
 */
function getResponseStatusCode(response) {
  const { data, status } = response;
  let flapCode = data && (data.errorcode || data.code);
  flapCode = Number.isInteger(flapCode) ? flapCode : null;
  return flapCode || status;
}

/**
 * Normalizes a given Flap remoteid
 * @param {String} remoteid
 * @returns {String} Normalized remoteid
 */
const normalizeRemoteid = (remoteid) =>
  remoteid && decodeURIComponent(remoteid.replace('auth/', ''));

/**
 * Finds the index of a "likes" section is an array of projected sections
 * @param {Array} sections - Redux Reducer sections
 * @returns {Object} Redux reducer section, including projection
 */
const getLikesSectionIndex = (sections) =>
  sections.findIndex((s) => s.isLikesSection);

/**
 * Finds the "likes" section is an array of projected sections
 * @param {Array} sections - Redux Reducer sections
 * @returns {Object} Redux reducer section, including projection
 */
const getLikesSection = (sections) =>
  sections[getLikesSectionIndex(sections)] || null;

/**
 * Returns true if the remoteid matches the section
 * @param {String} remoteid - Flap section remoteid
 * @param {Object} section - Redux Reducer section
 * @returns {Boolean} whether or not the remoteid matches the section
 */
const isRemoteIdSectionMatch = (remoteid, section) => {
  if (!remoteid || !section) {
    return false;
  }
  const normalizedRemoteId = normalizeRemoteid(remoteid);
  if (!normalizedRemoteId) {
    return false;
  }
  if (
    normalizeRemoteid(section.remoteid) === normalizedRemoteId ||
    normalizeRemoteid(section.sectionID) === normalizedRemoteId ||
    normalizeRemoteid(section.ssid?.remoteidPlain) === normalizedRemoteId ||
    normalizeRemoteid(section.rootTopic?.remoteid) === normalizedRemoteId ||
    normalizeRemoteid(section.requestedRemoteId) === normalizedRemoteId
  ) {
    return true;
  }
  if (normalizedRemoteId.includes(REMOTEID_USERNAME_PREFIX)) {
    const username = normalizedRemoteId.replace(REMOTEID_USERNAME_PREFIX, '');
    return section.authorUsername === username && section.isProfile;
  }
  return false;
};

/**
 * Finds the index of a section in an array of Redux Reducer sections by remoteid.
 * Note that sometimes sections may have more than 1 remoteid, thus we cannot do a standard Array.findIndex
 * @param {String} remoteid - Flap section remoteid
 * @param {Array} sections - Redux Reducer sections
 * @returns {Number} Index at which the section exists, -1 otherwise
 */
function getSectionIndexByRemoteId(remoteid, sections) {
  if (remoteid === LIKES_SECTION_REMOTEID) {
    return getLikesSectionIndex(sections);
  }

  return sections.findIndex((section) =>
    isRemoteIdSectionMatch(remoteid, section),
  );
}

/**
 * Finds a section in an array of Redux Reducer sections by remoteid.
 * Note that sometimes sections may have more than 1 remoteid, thus we cannot do a standard Array.find
 * @param {String} remoteid - Flap section remoteid
 * @param {Array} sections - Redux Reducer sections
 * @returns {Object} Redux reducer section, including projection
 */
function getSectionByRemoteId(remoteid, sections) {
  const index = getSectionIndexByRemoteId(remoteid, sections);
  if (index === -1) {
    return null;
  }
  return sections[index] || null;
}

function getUserStoryboardsSection(username, sections) {
  return sections.find((section) => {
    if (!SectionUtil.isUserStoryboards(section)) {
      return null;
    }
    const { authorUsername } = section;
    return (
      authorUsername && authorUsername.toLowerCase() === username.toLowerCase()
    );
  });
}

/**
 * Returns the remoteid for the feed of storyboards for a given uid
 * @param {Number} uid - uid for the feed of storyboards
 * @return {String} Remoteid for a user's storyboards feed
 */
function getUserStoryboardSectionId(uid) {
  return `flipboard/list/package/${uid}`;
}

/**
 * Updates a section in an array of Redux reducer sections by remoteid.
 * This does not mutate the original data
 * @param {Object} newSection - New section to be updated
 * @param {Array} sections - Redux Reducer sections
 * @returns {Object} Redux reducer section, including projection
 */
function updateSectionByRemoteId(section, sections) {
  const index = getSectionIndexByRemoteId(section.remoteid, sections);
  if (index === -1) {
    return [section, ...sections];
  }

  return sections.map((s, i) => {
    if (index === i) {
      if (!section.metrics || Object.keys(section.metrics).length === 0) {
        Object.assign(section, { metrics: s.metrics });
      }
      return section;
    }
    return s;
  });
}

/**
 * Gets default parameters from request, to send to Flap
 * @param {Object} req - Express.js Request
 * @returns {Object} - Key/val pairs of request parameters
 */
function getDefaultFlapRequestParams(req) {
  return {
    jobid: req.jobid.withUpstreamSegment(),
    ...langLocale(req),
  };
}

/**
 * Return message based on error object or default
 * @param {Object} error - flap error response
 * @param {String} defaultMessage - Default error message if none found
 * @returns {String} error message
 */
const errorMessage = (error, t, defaultMessage) => {
  if (
    (!!error.msg && error.errorcode) === FlapUtil.responseCodes.UNVERIFIED_EMAIL
  ) {
    return error.msg;
  }
  return defaultMessage || t('fallback_error_message');
};

const getErrorCodeMessage = (getErrorMessageFn) => (errorCode, t) => {
  if (!errorCode) {
    return t('fallback_error_message');
  }
  return (
    getErrorMessageFn(errorCode.toString(), t) || t('fallback_error_message')
  );
};

const getFlapErrorCodeMessage = getErrorCodeMessage(getDefaultErrorMessage);
const getFlapForgotPasswordErrorCodeMessage = getErrorCodeMessage(
  getForgotPasswordErrorMessage,
);

function getUserFromUserInfo(userInfo) {
  if (!userInfo || !userInfo.myServices || !userInfo.myServices.length) {
    return null;
  }

  const service = userInfo.myServices.find((s) => s.service === 'flipboard');
  if (!service) {
    return null;
  }
  return service;
}

function getUserContentOptions(userInfo) {
  if (!userInfo?.states) {
    return null;
  }
  const userInfoData = userInfo.states.find((x) => x.type === 'user')?.data;
  if (userInfoData) {
    const { mutedAuthors, mutedSourceDomains } = userInfoData;
    return { mutedAuthors, mutedSourceDomains };
  }
}

const SMART_MAGAZINE_SUPPORTED_LANGUAGES = ['en', 'fr', 'de'];
/**
 * Checks if a given browser Language supports Smart Magazines
 * @param {String} lang - Language
 * @return {Boolean}
 */
function isSmartMagazineSupported(lang) {
  return SMART_MAGAZINE_SUPPORTED_LANGUAGES.includes(lang);
}

/**
 * Returns a section if provided. If no section, return a dummy section
 * object with sensible default properties.
 * @param {Object} section - Projected Flipboard section
 * @returns {Object}  The section or a dummy section object with defaults
 */
function sectionOrDefaults(section) {
  return (
    section || {
      items: [],
      rawItems: null,
      nextPageKey: null,
      neverLoadMore: true,
      magazines: [],
      followers: [],
      followersNextPageKey: null,
      followersLoading: false,
      stale: false,
      placeholder: true,
    }
  );
}

/**
 * Builds a Flap remoteid for a given URL
 * @param {String} url
 * @returns {String}
 */
function getResolveUrlRemoteid(url) {
  return `resolve/flipboard/url${euc(`/${url}`)}`;
}

/**
 * Builds a Flap remoteid for a given item in a magazine, for use in updateFeed
 * @param {Object} item - Item Projection
 * @returns {String}
 */
function getMagazineItemRemoteid(item) {
  return `auth/flipboard/curator/magazineitem/${item.remoteServiceItemID}`;
}

/**
 * Flattens an Array of items and filters out specified item types
 * @param {Array<Object>} items
 * @param {Array<String> | undefined} allowedTypes
 * @returns {Array<Object>}
 */
function getFlatItems(items = [], allowedTypes) {
  return items.reduce((acc, item) => {
    if (item.type === 'metadata' && item.section) {
      const projected = projection(item.section);
      return [...acc, projected];
    } else if (item.type === 'group') {
      return [...acc, ...getFlatItems(item.items, allowedTypes)];
    }
    if (!allowedTypes || allowedTypes.includes(item.type)) {
      const projected = projection(item);
      return [...acc, projected];
    }
    return acc;
  }, []);
}

function getRemoteServiceItemId(item) {
  if (!item.id) {
    return null;
  }
  return item.id.replace(/^flipboard:/, '');
}

/**
 * Returns titles for each item, keyed by URL
 * @param {Array} items
 * @return {Object<String, String>} - Object with keys (urls) and values (titles)
 */
function getTitlesByUrl(items = []) {
  return items.reduce((acc, item) => {
    const canonicalUrl = stripParams(item.sourceURL);
    return Object.assign({}, acc, { [canonicalUrl]: item.title });
  }, {});
}

/**
 * Returns is first page group for stream
 * @param {Array} stream
 * @returns {Boolean} whether or not the stream is a first page with group
 */
function isFirstPageGroup(pageKey, stream) {
  if (pageKey) {
    return false;
  }
  return !!stream.find((i) => i.type === 'group');
}

/**
 * Returns is storyboard sectionid
 * @param {String} sectionId
 * @returns {Boolean} whether or not the section id is for a storyboard
 */
function isStoryboardSectionId(sectionId) {
  return STORYBOARD_SECTION_ID_REGEXP.test(sectionId);
}

/**
 * Returns is first page group for stream
 * @param {String} sectionId
 * @returns {Boolean} whether or not the section id is for a topic
 */
function isTopicSectionId(sectionId) {
  return TOPIC_SECTION_ID_REGEXP.test(normalizeRemoteid(sectionId));
}

function isMagazineSectionId(sectionId) {
  return MAGAZINE_SECTION_ID_REGEXP.test(sectionId);
}

/**
 * Returns language from an Array of items
 * Note that this assumes all items have the same language, for practical purposes
 * @param {Array} items - Array of Flipboard section items
 * @return {String} One of 'en', 'fr', 'de', null
 */
function getItemsLang(items) {
  let lang;
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (!item.language) {
      continue;
    }
    const languages = item.language.split(',');
    lang = languages.find((l) => ['en', 'fr', 'de'].includes(l));
    if (lang) {
      return lang;
    }
  }
  return null;
}

/**
 *
 * @param {Array} commentary - Raw commentary response data from social/activity endpoint
 * @param {String} uid  - UID of current user
 * @return {Array} - An array of OIDs representing liked items
 */
function likedItemUids(commentary, uid) {
  const itemsWithCommentary = commentary.filter(
    (item) => item.commentary && item.commentary.length > 0,
  );

  // Find all items that have type like and the userid of the current user
  const userLikedItems = itemsWithCommentary.filter((item) =>
    item.commentary.some(
      (commentaryItem) =>
        commentaryItem.type.includes('like') &&
        commentaryItem.userid.includes(uid),
    ),
  );
  return userLikedItems.map((item) => item.id);
}

/**
 * Searches the @param {*} stream of flap items and
 * @returns the subSections found in a SectionTabs sidebar item.
 */
function getSubTopicsFromStream(stream) {
  const subSections = stream.find(
    (item) =>
      item.type === 'sidebar' &&
      item.groupId &&
      item.groupId.indexOf('SectionTabs') === 0,
  );
  return subSections ? subSections.items : [];
}

const getSectionMetadataItemsFromStream = (stream) =>
  arrayFlatten(stream.filter((x) => x !== null)).filter(
    (x) => x.type === 'metadata' && !x.noItemStatus && !x.EOS,
  );

export const FlapUtil = {
  errorMessage,
  generateAccessToken,
  getIdsFromToken,
  isRemoteIdSectionMatch,
  metadata,
  sectionMetadataItems,
  metrics,
  sectionRawItemsFromStream,
  nglProcessedRawItems,
  sectionEosMetadata,
  noItemStatus,
  neverLoadMore,
  getMagazinesFromStream,
  getRemoteidByUserid,
  getUseridByRemoteid,
  getProfileVideoRemoteid,
  getResponseStatusCode,
  getRelatedArticlesSectionFromStream,
  getTopicTagFromRemoteid,
  getUserFromUserInfo,
  getUserContentOptions,
  getUserStats,
  getImageFromStream,
  getSectionCoversFromStream,
  profileLikesRemoteId,
  responseCodes: FlapResponseCodes,
  nextPageKeyFromStream,
  normalizeRemoteid,
  getSectionByRemoteId,
  getUserStoryboardSectionId,
  getUserStoryboardsSection,
  getSectionIndexByRemoteId,
  updateSectionByRemoteId,
  getLikesSection,
  isAccessTokenExpired,
  isSmartMagazineSupported,
  isFirstPageGroup,
  isStoryboardSectionId,
  isTopicSectionId,
  isMagazineSectionId,
  getDefaultFlapRequestParams,
  getFlapErrorCodeMessage,
  getFlapForgotPasswordErrorCodeMessage,
  getSectionFromStream,
  getBasicSectionsFromStream,
  getResolveUrlRemoteid,
  getMagazineItemRemoteid,
  getRemoteServiceItemId,
  getFlatItems,
  getTitlesByUrl,
  sectionOrDefaults,
  getItemsLang,
  getRelatedStoryboardsRemoteId,
  likedItemUids,
  getSubTopicsFromStream,
  getSectionMetadataItemsFromStream,
};

export default FlapUtil;
