import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { TEXT_COLORS } from 'Style/colors';
import { BODY_TYPES } from 'Style/typography';

// Utils
import codeSplitLoad from 'Webapp/shared/utils/code-split-load';
import { GA } from 'Utils/analytics';
import { USAGE_NAV_FROMS, USAGE_EVENT_NAMES } from 'Utils/analytics/usage';

// Components
import { NavFromContext } from 'ComponentLibrary/context';
import FollowingIcon from 'ComponentLibrary/icons/following-new';
import CreateFlipIcon from 'ComponentLibrary/icons/create-flip';
import FlipIcon from 'ComponentLibrary/icons/flip';
import UnstyledButton from 'Webapp/shared/app/components/unstyled-button';
import Notifications from 'Webapp/shared/app/components/notifications';
import NavbarProfile from './navbar-profile';
import WebLink from 'Webapp/shared/app/components/web-link';

// HOCs
import withNavFrom from '../../hocs/withNavFrom';
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectCurrentUser from 'Webapp/shared/app/connectors/connectCurrentUser';
import connectUsageSetNavFrom from 'Webapp/shared/app/connectors/connectUsageSetNavFrom';
import connectModal from 'Webapp/shared/app/connectors/connectModal';
import connectTheme from 'Webapp/shared/app/connectors/connectTheme';
import connectFlipMode from 'Webapp/shared/app/connectors/connectFlipMode';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectFeatureFlags from 'Webapp/shared/app/connectors/connectFeatureFlags';
const FlipComposeModal = codeSplitLoad('FlipComposeModal');

export const StyledNavBarButton = styled(UnstyledButton)(
  BODY_TYPES.XSMALL_EMPHASIS,
  {
    textTransform: 'uppercase',
    '.button--unstyled__children': {
      display: 'flex',
      gap: SPACING.SMALL,
      color: TEXT_COLORS.tertiary,
    },
  },
);

const StyledWebLink = styled(WebLink)({
  display: 'flex',
  gap: SPACING.SMALL,
  color: TEXT_COLORS.tertiary,
});

function LoggedInButtons({
  profileSection,
  showModal,
  navFrom,
  profile,
  setAppThemeOverride,
  refreshBanners,
  usageSetNavFrom,
  usageTrackTapFlip,
  hasFlipModeFeature,
  setFlipModeEnabled,
  isFlipModeEnabled,
  isLargeScreen,
  isPhone,
  featureFlags,
  t,
}) {
  const handleFlipClick = () => {
    GA.trackClickButton('flip compose - open');
    usageSetNavFrom(USAGE_EVENT_NAMES.TAP_FLIP, navFrom);
    usageTrackTapFlip(profile);
    showModal(FlipComposeModal, { navFrom });
  };

  const handleClickFlipMode = () => {
    setFlipModeEnabled(!isFlipModeEnabled);
  };

  return (
    <NavFromContext.Provider value={USAGE_NAV_FROMS.NAV_BAR}>
      <li>
        <StyledNavBarButton
          name="navbar-flip-compose"
          onClick={handleFlipClick}
          stopPropagation={false}
        >
          <CreateFlipIcon size={22} toolTip={t('flip_compose')} />
          {!isPhone && <span>{t('create_a_flip')}</span>}
        </StyledNavBarButton>
      </li>
      {hasFlipModeFeature && isLargeScreen && (
        <li>
          <StyledNavBarButton
            name="navbar-flip-mode"
            onClick={handleClickFlipMode}
            className={classNames({
              'social-actions__button--flip-mode': isFlipModeEnabled,
            })}
          >
            <FlipIcon />
          </StyledNavBarButton>
        </li>
      )}
      <li>
        <StyledWebLink
          href="/following"
          navFromEventName={USAGE_EVENT_NAMES.TOC_ENTER}
        >
          <FollowingIcon />
        </StyledWebLink>
      </li>
      {featureFlags.NOTIFICATIONS && (
        <li>
          <Notifications />
        </li>
      )}
      <li>
        <NavbarProfile
          profileSection={profileSection}
          setAppThemeOverride={setAppThemeOverride}
          refreshBanners={refreshBanners}
        />
      </li>
    </NavFromContext.Provider>
  );
}

LoggedInButtons.propTypes = {
  profileSection: PropTypes.object,
  showModal: PropTypes.func.isRequired,
  navFrom: PropTypes.string,
  profile: PropTypes.object,
  setAppThemeOverride: PropTypes.func.isRequired,
  refreshBanners: PropTypes.func.isRequired,
  usageSetNavFrom: PropTypes.func.isRequired,
  usageTrackTapFlip: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  hasFlipModeFeature: PropTypes.bool.isRequired,
  isFlipModeEnabled: PropTypes.bool.isRequired,
  setFlipModeEnabled: PropTypes.func.isRequired,
  isLargeScreen: PropTypes.bool.isRequired,
  isPhone: PropTypes.bool.isRequired,
  featureFlags: PropTypes.shape({
    NOTIFICATIONS: PropTypes.bool.isRequired,
  }).isRequired,
};

LoggedInButtons.defaultProps = {
  profileSection: null,
  navFrom: null,
  profile: null,
};

export default connector(
  connectUsageSetNavFrom,
  connectModal,
  connectTheme,
  connectCurrentUser,
  connectFlipMode,
  connectResponsive,
  connectFeatureFlags,
)(withT(withNavFrom(LoggedInButtons)));
