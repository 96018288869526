import React from 'react';
import styled from '@emotion/styled';
import ProfileAvatar from 'Webapp/shared/app/components/profile-avatar.js';
import { SPACING } from 'Style/spacing';
import { BODY_TYPES } from 'Style/typography';
import { TEXT_COLORS } from 'Style/colors';

import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';
import { getAuthorUrl } from 'Utils/content/flipboard-urls';

import WebLink from 'Webapp/shared/app/components/web-link';

const StyledWebLink = styled(WebLink)({
  display: 'flex',
  flexDirection: 'row',
  gap: SPACING.BASE,
});

const StyledSectionLinkItems = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
});

const ProfileName = styled.h5(BODY_TYPES.SMALL_STANDARD, {
  color: TEXT_COLORS.primary,
});
const ProfileUserName = styled.h5(BODY_TYPES.XSMALL_STANDARD, {
  color: TEXT_COLORS.tertiary,
});

interface ItemProfileListItem {
  profile: Flipboard.Section;
}

const ItemProfileListItem: React.FC<ItemProfileListItem> = ({ profile }) => {
  const profileUrl = getAuthorUrl(profile);
  return (
    <StyledWebLink
      href={profileUrl}
      navFromEventName={USAGE_EVENT_NAMES.SECTION_ENTER}
      openInNewWindow
    >
      <ProfileAvatar
        image={profile.authorImage || profile.image}
        avatarSize={40}
      />
      <StyledSectionLinkItems>
        <ProfileName>{profile.authorDisplayName}</ProfileName>
        <ProfileUserName>{`@${profile.authorUsername}`}</ProfileUserName>
      </StyledSectionLinkItems>
    </StyledWebLink>
  );
};

export default ItemProfileListItem;
