import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { BREAKPOINTS } from 'Style/breakpoints';
import { SPACING } from 'Style/spacing';
import {
  BODY_TYPES,
  UI_SUBHEADING_TYPES,
  UI_TEXT_TYPES,
} from 'Style/typography';
import { TEXT_COLORS, DIVIDER_COLORS } from 'Style/colors';
import { styleOnlyProps } from 'Style/style-helpers';

// Utils
import { getAuthorUrl } from 'Utils/content/flipboard-urls';
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';

// Components
import WebLink from './web-link';
import ProfileStack from './profile-stack';
import AuthorAvatar from './attribution/author-avatar';
import MagazineContributorsDescription from './magazine-contributors-description';
import CuratorAttribution from 'Webapp/shared/app/components/attribution/curator';
const AvatarWrapper = styled(
  'div',
  styleOnlyProps('singleProfile', 'multipleProfiles'),
)(
  ({ singleProfile }) =>
    singleProfile && {
      display: 'flex',
      alignItems: 'center',
      '.author-avatar': {
        marginRight: SPACING.MEDIUM,
      },
    },
  ({ multipleProfiles }) =>
    multipleProfiles && { display: 'flex', alignItems: 'center' },
);
const contributorsFontStyle = function (props) {
  if (props.isNewMagazineCover) {
    return { ...UI_SUBHEADING_TYPES.SMALL, color: TEXT_COLORS.overlay };
  } else if (props.isNewMagazine) {
    return {
      ...BODY_TYPES.LARGE_STANDARD,
      color: TEXT_COLORS.overlay,
    };
  }
  return UI_TEXT_TYPES.SUPPORTING;
};

const StyledMagazineContributors = styled(
  'p',
  styleOnlyProps('isNewMagazine', 'isNewMagazineCover'),
)(contributorsFontStyle, {
  verticalAlign: 'middle',
  ...BREAKPOINTS.tabletLandscapeUp({
    display: 'inline-block',
    marginBottom: 0,
  }),
});

const StyledAuthor = styled(WebLink)({
  ...UI_SUBHEADING_TYPES.SMALL,
  color: TEXT_COLORS.overlay,
});

const StyledAuthorAvatar = styled(AuthorAvatar)({
  borderRadius: '100%',
  '.author-avatar__image': {
    border: `1.5px solid ${DIVIDER_COLORS.primary}`,
  },
});
function MagazineContributors({
  profiles,
  section,
  maxDisplayCount,
  className,
  onClickAdd,
  avatarSize,
  isNewMagazine,
  isNewMagazineCover,
}) {
  const entireProfiles = profiles;
  const displayProfiles = profiles.slice(0, maxDisplayCount);
  const undisplayedCount = profiles.length - displayProfiles.length;

  const isAvatarStack = profiles.length === 1 && !onClickAdd;

  const renderAuthor = (author) => {
    if (!author) {
      return null;
    }

    const authorUrl = getAuthorUrl(section);

    return authorUrl ? (
      <WebLink
        className="media-link"
        href={authorUrl}
        navFromEventName={USAGE_EVENT_NAMES.SECTION_ENTER}
      >
        <StyledAuthorAvatar author={author} avatarSize={avatarSize} />
      </WebLink>
    ) : (
      <StyledAuthorAvatar author={author} avatarSize={avatarSize} />
    );
  };

  if (isAvatarStack) {
    if (!isNewMagazineCover) {
      return (
        <CuratorAttribution
          className={className}
          item={section}
          avatarSize={avatarSize}
        />
      );
    }
    const author = profiles[0];
    return (
      <AvatarWrapper className={className}>
        {renderAuthor(author)}
        <StyledAuthor
          href={`/@${author.authorUsername}`}
          name="magazine-owner-description-profile-link"
          navFromEventName={USAGE_EVENT_NAMES.SECTION_ENTER}
        >
          {section.authorDisplayName}
        </StyledAuthor>
      </AvatarWrapper>
    );
  }
  return (
    <AvatarWrapper className={className}>
      <ProfileStack
        isNewMagazine={isNewMagazine}
        profiles={displayProfiles}
        allProfiles={entireProfiles}
        addContributorButtonName="magazine-contributors-add-contributor"
        onClickAdd={onClickAdd}
        avatarSize={avatarSize}
        undisplayedCount={undisplayedCount}
        isNewMagazineCover={isNewMagazineCover}
        maxDisplayCount={maxDisplayCount}
        section={section}
      />
      <StyledMagazineContributors
        isNewMagazineCover={isNewMagazineCover}
        isNewMagazine={isNewMagazine}
      >
        <MagazineContributorsDescription
          isNewMagazine={isNewMagazine}
          profiles={displayProfiles}
          undisplayedCount={undisplayedCount}
          isNewMagazineCover={isNewMagazineCover}
          section={section}
          entireProfiles={entireProfiles}
        />
      </StyledMagazineContributors>
    </AvatarWrapper>
  );
}

MagazineContributors.propTypes = {
  profiles: PropTypes.array.isRequired,
  section: PropTypes.object.isRequired,
  onClickAdd: PropTypes.func,
  maxDisplayCount: PropTypes.number,
  className: PropTypes.string,
  avatarSize: PropTypes.number,
  isNewMagazine: PropTypes.bool,
  isNewMagazineCover: PropTypes.bool,
  author: PropTypes.object,
};

MagazineContributors.defaultProps = {
  maxDisplayCount: 5,
  onClickAdd: null,
  className: '',
  avatarSize: 35,
  isNewMagazine: false,
};

export default MagazineContributors;
