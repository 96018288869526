import { CONTENT_TYPES } from 'Webapp/action-types';
import { merger } from 'Utils/redux';

interface ContentReducerState {
  editorialBoards: null | Array<Flipboard.EditorialBoard>;
  loading: boolean;
  contentGuide: null | Flipboard.ContentGuide;
  selectedContentGuideRemoteId: null | Flipboard.SectionId;
  contextualFollowSections: Array<Flipboard.Section>;
}

export const initialState: ContentReducerState = {
  editorialBoards: null,
  loading: false,
  contentGuide: null,
  selectedContentGuideRemoteId: null,
  contextualFollowSections: [],
};

export default function contentReducer(state = initialState, action) {
  const merge = merger(state);
  switch (action.type) {
    case CONTENT_TYPES.GET_EDITORIAL_BOARDS:
      return Object.assign({}, initialState, { loading: true });
    case CONTENT_TYPES.GET_EDITORIAL_BOARDS_SUCCEEDED: {
      const boards = action.payload || [];
      return Object.assign({}, state, {
        loading: false,
        editorialBoards: boards,
      });
    }
    case CONTENT_TYPES.GET_CONTENT_GUIDE_SUCCEEDED: {
      const { contentGuide } = action.payload;
      return Object.assign({}, state, { contentGuide });
    }
    case CONTENT_TYPES.SET_SELECTED_CONTENT_GUIDE_SECTION: {
      const { remoteId } = action.payload;
      return Object.assign({}, state, {
        selectedContentGuideRemoteId: remoteId,
      });
    }
    case CONTENT_TYPES.SET_CONTEXTUAL_ONBOARDING_FOLLOW_SECTIONS: {
      return merge(action.payload);
    }
    default:
      return state;
  }
}
