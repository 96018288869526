import getWindow from 'Utils/get-window';
import { AppTheme } from 'Webapp/enums';
/**
 * Sets browser theme by attaching class to body element
 * @param {String} theme  - One of AppTheme
 */
export function setBrowserTheme(theme) {
  if (!getWindow()) {
    return;
  }
  const { body } = getWindow().document;
  if (theme === AppTheme.DARK) {
    return body.classList.add('theme--dark');
  }
  return body.classList.remove('theme--dark');
}
