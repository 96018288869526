import curry from 'lodash/curry';
import { AnyAction } from 'redux';
import { ActionTypeEnum } from 'Webapp/action-types';

export const set = curry<
  ValueOf<ActionTypeEnum>,
  string,
  unknown,
  { type: ValueOf<ActionTypeEnum>; payload: { [key: string]: unknown } }
>((type: ValueOf<ActionTypeEnum>, key: string, value: unknown) => ({
  type,
  payload: { [key]: value },
}));

export const type = (t: ValueOf<ActionTypeEnum>) => ({ type: t });

export const merger =
  <T>(a: T) =>
  (b: Partial<T>) => ({
    ...a,
    ...b,
  });

export const setPayloadReducer =
  <T, S>(types: T, initialState: S, resetType?: keyof T) =>
  (state: S = initialState, action: AnyAction) => {
    if (resetType && action.type === resetType) {
      return initialState;
    }
    if (Object.values(types).indexOf(action.type) > -1) {
      return merger(state)(action.payload);
    }
    return state;
  };
