export enum ItemType {
  SECTION_COVER = 'sectionCover',
  STATUS = 'status',
}

export enum FlapItemCommentaryType {
  SHARE = 'share',
  LIKE = 'like',
  COMMENT = 'comment',
}

export enum FlapNotificationType {
  ACCEPTED_INVITE = 'acceptedYourInvite',
  ADDED_POST = 'addedPost',
  COMMENT = 'comment',
  COMMENT_BY_PEOPLE_YOU_FOLLOW = 'commentByPeopleYouFollow',
  COMMENT_MENTION = 'commentMention',
  COMMENT_SAME_POST = 'commentSamePost',
  FOLLOW = 'follow',
  FOLLOW_YOU = 'followYou',
  LIKE = 'like',
  MENTION = 'mention',
  MILESTONE = 'milestone',
  NOTIFICATION = 'notification',
  REFLIP = 'retweet',
  STATS = 'stats',
}

export enum RelatedSectionType {
  TOPICS = 'TOPICS',
  AUTHOR = 'AUTHOR',
  ARTICLES = 'ARTICLES',
  STORYBOARDS = 'STORYBOARDS',
  FLIPBOARD_TV = 'FLIPBOARD_TV',
  PROFILE_MAGAZINES = 'PROFILE_MAGAZINES',
  PROFILE_STORYBOARDS = 'PROFILE_STORYBOARDS',
}

export enum FlapItemType {
  AUTHOR = 'metadata',
  METADATA = 'metadata',
  NOTIFICATION = 'notification',
  SECTION = 'section',
  STATUS = 'status',
  USER_METADATA = 'userMetadata',
  VIDEO = 'video',
}

export enum FlapSectionType {
  LINK = 'link',
  SECTION = 'section',
}

export enum FlapSectionFeedType {
  MAGAZINE = 'magazine',
  PROFILE = 'profile',
}

export enum FlapSectionLinkType {
  AUTHOR = 'author',
  TEXT_LINK = 'textLink',
  MAGAZINE = 'magazine',
}

export enum FlapLinkType {
  USER = 'user',
}

export enum FlapSearchType {
  PROFILE = 'profile',
}

export enum FlapProfileType {
  AUTHOR = 'author',
}

export enum LoadState {
  NONE = 'NONE',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum Services {
  FLIPBOARD = 'flipboard',
  ACTIVITYPUB = 'activity',
}

export enum FrictionlessFlowType {
  HOMEPAGE = 'HOMEPAGE',
  NEWSLETTER = 'NEWSLETTER',
  NEWSLETTERS = 'NEWSLETTERS',
  TOPIC = 'TOPIC',
  INVITE = 'INVITE',
  MAIN_SIGNUP = 'MAIN_SIGNUP',
  ACTION_GATE = 'ACTION_GATE',
}

export enum SocialActionType {
  LIKE = 'LIKE',
  FLIP = 'FLIP',
  ACCEPT_INVITE = 'ACCEPT_INVITE',
  FOLLOW = 'FOLLOW',
  FOLLOW_TOPIC = 'FOLLOW_TOPIC',
  FAVORITE = 'FAVORITE',
  PERSONALIZE = 'PERSONALIZE',
  COMMENTS = 'COMMENTS',
}

export enum PinIconVariant {
  FILLED = 'FILLED',
  PINNED = 'PINNED',
  DEFAULT = 'DEFAULT',
}

export enum ItemActionButtonType {
  FOLLOW = 'FOLLOW',
  EDIT_MAGAZINE = 'EDIT_MAGAZINE',
  PERSONALIZE_TEXT = 'PERSONALIZE_TEXT',
  PERSONALIZE = 'PERSONALIZE',
  SHARE_TEXT = 'SHARE_TEXT',
  SHARE = 'SHARE',
  FLIP = 'FLIP',
  LIKE = 'LIKE',
  MUTE = 'MUTE',
  ACCEPT_INVITE = 'ACCEPT_INVITE',
  INVITE_CONTRIBUTOR = 'INVITE_CONTRIBUTOR',
  ADD_FAVORITE = 'ADD_FAVORITE',
  COMMENTS = 'COMMENTS',
  LESS_LIKE = 'LESS_LIKE',
  REPORT = 'REPORT',
  REMOVE_FROM_MAGAZINE = 'REMOVE_FROM_MAGAZINE',
  LEAVE_MAGAZINE = 'LEAVE_MAGAZINE',
  CREATE_STORY = 'CREATE_STORY',
  PIN_FLIP = 'PIN_FLIP',
}

export enum ItemMetricDetailType {
  LIKE = 'likes',
  FLIP = 'shares',
}

export enum CTAType {
  DOWNLOAD_APP = 'download_app_cta',
}

export enum SIGNUP_TYPES {
  STANDARD = 'standard',
  FRICTIONLESS = 'frictionless',
}

export enum SubscribeType {
  TOPIC_PAGE = 'topic-page',
  NEWSLETTERS_PAGE = 'newsletters-page',
  NEWSLETTER_PAGE = 'newsletter-page',
}

export enum FlapResponseCodes {
  GENERIC_ERROR = 1100,
  MULTIPLE_ACCOUNTS_FOR_EMAIL_ADDRESS = 1101,
  INVALID_CREDENTIALS = 1102,
  NO_ASSOCIATED_ACCOUNT = 1105,
  NON_EXISTANT_ACCOUNT = 1107,
  INVALID_ACCOUNT = 1107,
  EXPIRED_TOKEN = 1110,
  UNVERIFIED_EMAIL = 1109,
  INVITE_VALIDATION_ERROR = 1118,
}

export enum AppTheme {
  DEFAULT = 'default',
  DARK = 'dark',
}

export enum NotificationGroupType {
  SOCIAL = 'social',
  CONTENT = 'content',
}

export enum ContentSectionsType {
  SIDEBAR = 'SIDEBAR',
  CONDENSED_0 = 'CONDENSED_0',
  CONDENSED_1 = 'CONDENSED_1',
  ITEM_CARD_1 = 'ITEM_CARD_1',
  ITEM_CARD_2 = 'ITEM_CARD_2',
}

export enum ImageLoadingMode {
  LAZY = 'lazy',
  EAGER = 'eager',
}

export enum SaveState {
  NONE = 0,
  PENDING,
  SUCCEEDED,
  FAILED,
}

export enum CuratorProItemType {
  ARTICLE = 'article',
  MAGAZINE = 'magazine',
  STORYBOARD = 'storyboard',
  TOPIC = 'topic',
  PROFILE = 'profile',
  FEED = 'feed',
  TWEET = 'tweet',
  STATUS = 'status',
  VIDEO = 'video',
  AUDIO = 'audio',
  IMAGE = 'image',
}

export enum CuratorProSectionType {
  MAGAZINE = 'magazine',
  STORYBOARD = 'storyboard',
}

export enum SettingsTab {
  PROFILE = 'PROFILE',
  EMAIL = 'EMAIL',
  CONTENTS = 'CONTENTS',
}

export enum OnboardingState {
  INCOMPLETE = 'incomplete',
  REJECTED = 'rejected',
  PENDING = 'pending',
  PROCESSING = 'processing',
  LIVE = 'live',
}

export enum StoryboardType {
  STORYBOARD = 'STORYBOARD',
  CAMNPAIGN = 'CAMNPAIGN',
}

export enum MagazineMetricType {
  VIEWERS = 'viewers',
  FOLLOWERS = 'followers',
  STORIES = 'stories',
}

export enum ArticleContentSectionType {
  SIDEBAR = 'SIDEBAR',
  CONDENSED_0 = 'CONDENSED_0',
  CONDENSED_1 = 'CONDENSED_1',
  ITEM_CARD_1 = 'ITEM_CARD_1',
  ITEM_CARD_2 = 'ITEM_CARD_2',
}

export enum RemoteStoreState {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  FAILED = 'FAILED',
}

export enum MuteItemType {
  SOURCE_DOMAIN = 'sourceDomain',
  AUTHOR = 'author',
}

export enum PillSize {
  SMALL = 'SMALL',
  LARGE = 'LARGE',
}

export enum OnboardingFlow {
  SIGNUP_BUTTON = 'SIGNUP_BUTTON',
  SIGNUP_LANDING = 'SIGNUP_LANDING',
  SIGNUP_NEWSLETTER = 'SIGNUP_NEWSLETTER',
  STICKY_TOPIC_PICKER_CTA = 'STICKY_TOPIC_PICKER_CTA',
  POST_VERIFICATION = 'POST_VERIFICATION',
  PUBLISHER_LANDING = 'PUBLISHER_LANDING',
  ACCEPT_MAGAZINE_INVITE_UNAUTHENTICATED_OLD = 'ACCEPT_MAGAZINE_INVITE_UNAUTHENTICATED_OLD',
  ACCEPT_MAGAZINE_INVITE_UNAUTHENTICATED_NEW = 'ACCEPT_MAGAZINE_INVITE_UNAUTHENTICATED_NEW',
  ACCEPT_MAGAZINE_INVITE_SIGNUP_OLD = 'ACCEPT_MAGAZINE_INVITE_SIGNUP_OLD',
  ACCEPT_MAGAZINE_INVITE_SIGNUP_NEW = 'ACCEPT_MAGAZINE_INVITE_SIGNUP_NEW',
  ACCEPT_MAGAZINE_INVITE_AUTHENTICATED_OLD = 'ACCEPT_MAGAZINE_INVITE_AUTHENTICATED_OLD',
  ACCEPT_MAGAZINE_INVITE_AUTHENTICATED_NEW = 'ACCEPT_MAGAZINE_INVITE_AUTHENTICATED_NEW',
  BECOME_PUBLISHER = 'BECOME_PUBLISHER',
}

export enum FlipModalStyle {
  DEFAULT = 'DEFAULT',
  OLD = 'OLD',
  SINGLE_PANE = 'SINGLE_PANE',
}
export enum WidgetLayout {
  THUMBNAIL = 'Thumbnail',
  PORTRAIT = 'Portrait',
  BANNER = 'Banner',
}

export enum EmbedToolModes {
  MAGAZINE_STORYBOARD = 'MAGAZINE_STORYBOARD',
  FOLLOW = 'FOLLOW',
  SHARE = 'SHARE',
  BROWSER = 'BROWSER',
}

export enum TOAST_STYLES {
  INFO = 'info',
  ERROR = 'error',
}
