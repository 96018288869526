import { factory as flaxiosFactory } from 'Utils/flaxios';
import qs from 'Utils/qs';
import ApiHelpers from 'Utils/api/helpers';
import Config from 'Config';

const { FLAP_NAME } = Config;

export const contentFactory = () => {
  const content = flaxiosFactory({
    baseURL: Config.CONTENT_API_GW_URL,
    timeout: 16500,
  });

  // Default query param serializer
  content.defaults.paramsSerializer = (params) =>
    qs.stringify(params, { arrayFormat: 'repeat' });

  /**
   * Request interceptor for Flap requests made from the server
   * @param {Object} config - axios config
   * @returns {Object} config - modified axios config
   */
  content.interceptors.request.use((config) => {
    if (config.url === '/health') {
      return config;
    }

    // Set start time of request
    config.metadata = { startTime: new Date() };

    // Add default params and access token ids
    config.params = Object.assign(
      {},
      ApiHelpers.FLAP_DEFAULT_PARAMS,
      config.params,
    );

    ApiHelpers.interceptApiGwRequest(config);
    return config;
  });

  /**
   * Response interceptor for Flap requests
   */
  content.interceptors.response.use(
    (response) => ApiHelpers.interceptResponseHandler(response, FLAP_NAME),
    (error) => ApiHelpers.interceptResponseErrorHandler(error, FLAP_NAME),
  );

  return content;
};

export default contentFactory();
