import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { isExternalUrl } from 'Utils/content/flipboard-urls';
import { usageItemEnterEventName } from 'Utils/analytics/usage';

// Components
import WebLink from '../web-link';
import Button, { StyleVariations } from 'Webapp/shared/app/components/button';
import withHistory from 'Webapp/shared/app/hocs/withHistory';

import connector from 'Utils/connector';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectItemLinkUsage from 'Webapp/shared/app/connectors/connectItemLinkUsage';

export const elementTypes = {
  LINK: 'link',
  BUTTON: 'button',
};

function ItemLink({
  elementType,
  item,
  section,
  shownInList,
  isAuthenticated,
  className,
  dataTestId,
  children,
  onClick,
  history,
  buttonName,
  buttonLabel,
  title,
  usageSetNavFromSection,
  usageTrackItemEnter,
  usageTrackPublisherSiteLinkClick,
  disableAnchorLink,
  forceInternal,
}) {
  // Determine usage nav from event name for item links
  const itemNavFromEventName = usageItemEnterEventName(item);

  const shownInStoryboard = section && section.isStoryboard;
  const useFlipboardUrl =
    forceInternal ||
    item.isFlipboardInternalContent ||
    (shownInList && !isAuthenticated && !shownInStoryboard);
  const linkUrl = useFlipboardUrl
    ? item.internalURL
    : item.openURL || item.sourceURL || item.internalURL; // Fall back to internalURL if we get bad data that is missing a sourceURL

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (section) {
      usageSetNavFromSection(section);
    }

    const externalUrl = isExternalUrl(linkUrl);

    // If the item link is external and the item is shown in a list
    // (not the article preview page), emit a usage "item enter"
    // event.  We prevent emitting the event on article preview pages
    // because it has already been fired for opening the preview
    // page itself.
    if (externalUrl && shownInList) {
      usageTrackItemEnter(item, history);
    }

    // If the item link is external and the item is shown on
    // an article preview page, emit a usage "general/tap_action"
    // event.  This is to help us understand the rate at which
    // logged-out users proceed onto publisher URLs vs. engaging
    // with Flipboard content and/or beginning the signup process.
    if (externalUrl && !shownInList) {
      usageTrackPublisherSiteLinkClick();
    }
  };

  if (elementType === elementTypes.LINK) {
    return (
      <WebLink
        className={className}
        href={linkUrl}
        data-test-id={dataTestId}
        navFromEventName={itemNavFromEventName}
        onClick={handleClick}
        title={title}
        disableAnchorLink={disableAnchorLink}
      >
        {children}
      </WebLink>
    );
  }

  return (
    <Button
      href={linkUrl}
      name={buttonName}
      label={buttonLabel}
      styleVariation={StyleVariations.SECONDARY}
      navFromEventName={itemNavFromEventName}
      onClick={handleClick}
    />
  );
}

ItemLink.propTypes = {
  elementType: PropTypes.oneOf(Object.values(elementTypes)),
  item: PropTypes.object.isRequired,
  section: PropTypes.object,
  shownInList: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  usageSetNavFromSection: PropTypes.func.isRequired,
  usageTrackItemEnter: PropTypes.func.isRequired,
  usageTrackPublisherSiteLinkClick: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  buttonName: PropTypes.string,
  buttonLabel: PropTypes.string,
  title: PropTypes.string,
  disableAnchorLink: PropTypes.bool,
  forceInternal: PropTypes.bool,
};

ItemLink.defaultProps = {
  elementType: elementTypes.LINK,
  section: null,
  shownInList: true,
  className: '',
  dataTestId: null,
  children: null,
  onClick: null,
  buttonName: null,
  buttonLabel: null,
  title: '',
  disableAnchorLink: false,
  forceInternal: false,
};

export default connector(
  connectAuthentication,
  connectItemLinkUsage,
)(withHistory(ItemLink));
