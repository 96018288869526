import React from 'react';
import styled from '@emotion/styled';

import { TEXT_COLORS, SURFACE_COLORS } from 'Style/colors';
import { SPACING } from 'Style/spacing';
import { UI_SUBHEADING_TYPES, FONT_WEIGHTS } from 'Style/typography';
import ProfileAvatar from 'Webapp/shared/app/components/profile-avatar.js';

import connector from 'Utils/connector';
import connectFeatureFlags, {
  ConnectFeatureFlagsProps,
} from 'Webapp/shared/app/connectors/connectFeatureFlags';

export const MENTION_AVATAR_SIZE = 20;

export const StyledAtMentionTextOnly = styled.span({
  fontWeight: FONT_WEIGHTS.BOLD,
  textDecoration: 'underline',
  textUnderlineOffset: SPACING.SMALL,
  '&:before': {
    content: '"@"',
  },
});
export const StyledAtMention = styled.span(UI_SUBHEADING_TYPES.SMALL, {
  display: 'inline-flex',
  verticalAlign: 'bottom',
  paddingRight: SPACING.BASE,
  marginRight: SPACING.SMALL,
  background: SURFACE_COLORS.tertiary,
  color: TEXT_COLORS.secondary,
  borderRadius: SPACING.MEDIUM,
  '.author-avatar__image': {
    margin: `${SPACING.XSMALL} ${SPACING.SMALL} ${SPACING.XSMALL} ${SPACING.XSMALL}`,
  },
  span: {
    color: TEXT_COLORS.primary,
  },
  source: { display: 'contents' },
});

const MentionContents = styled.div({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  alignItems: 'center',
  '> *': {
    display: 'inline-block',
    verticalAlign: 'bottom',
  },
});
const MentionName = styled.span({
  /**
   * HACK ALERT! This is a hack to center the text vertically. inline-block allows us
   * to have the mentions appear inline, be positioned "in line", but not screw up the keyboard navigation
   * within Draft-JS. line hight will force everything to be centred "vertically" within the atmention, as normal
   * alignment doesn't work with inline-block.
   */
  lineHeight: '22px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

type AtMentionProps = {
  className?: string;
  name: string;
  username: string;
  avatar?: string;
  children: React.ReactNode;
} & ConnectFeatureFlagsProps;
const AtMention: React.FC<AtMentionProps> = ({
  className,
  name,
  avatar,
  username,
  children,
  featureFlags,
}) => {
  if (featureFlags.PILL_REMOVAL) {
    return (
      <StyledAtMentionTextOnly className={className}>
        {username}
      </StyledAtMentionTextOnly>
    );
  }
  return (
    <StyledAtMention className={className}>
      <MentionContents>
        <ProfileAvatar
          name={name}
          avatarSize={MENTION_AVATAR_SIZE}
          image={avatar}
        />
        <MentionName>{children}</MentionName>
      </MentionContents>
    </StyledAtMention>
  );
};

export default connector<AtMentionProps>(connectFeatureFlags)(AtMention);
