import { TOAST_TYPES } from 'Webapp/action-types';

const allowSimpleMessage = (props) => {
  if (typeof props === 'string') {
    return {
      message: props,
    };
  }
  return props;
};

export const toastShowInfoAction = (props) => ({
  type: TOAST_TYPES.SHOW_INFO,
  props: allowSimpleMessage(props),
});

export const toastShowErrorAction = (props) => ({
  type: TOAST_TYPES.SHOW_ERROR,
  props: allowSimpleMessage(props),
});

export const toastGenericError =
  () =>
  (dispatch, _, { t }) => {
    dispatch(toastShowErrorAction(t('fallback_error_message')));
  };

export const toastDismiss = () => ({
  type: TOAST_TYPES.DISMISS,
});
