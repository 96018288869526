import { AxiosResponse } from 'axios';

// Utils
import FlapUtil from 'Utils/content/flap-util';
import { getSectionId } from 'Utils/content/flipboard-urls';
import SectionUtil from 'Utils/content/section-util';
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';

import { TOAST_TEMPLATES } from '../../components/toast-templates';

import { currentUserUid } from 'Webapp/shared/app/redux/selectors/auth';

// Types
import { MAGAZINE_TYPES } from 'Webapp/action-types';

// Actions
import { toastShowInfoAction, toastShowErrorAction } from './toast-actions';
import { dismissModal } from './modal-actions';
import { fetchUserInfo } from './profile-actions';
import {
  usageSetNavFrom,
  usageTrackItemFlip,
  usageTrackSectionFlip,
  usageTrackUrlFlip,
  usageTrackMagazineCreate,
} from 'Webapp/shared/app/redux/actions/usage-actions';
import { MissingFlipTargetError } from 'Webapp/utils/errors';
import {
  getSection,
  shortenSection,
} from 'Webapp/shared/app/redux/actions/section-actions';
import { MAX_UPDATE_FEED_LIMIT } from 'Utils/api/flap/index.js';

import { updateItemSocialActivity } from 'Webapp/shared/concepts/social-activity';

type FlipParams = Omit<Flipboard.FlapShareWithCommentRequestBody, 'target'> & {
  target: Flipboard.Section;
};
const flip =
  (
    flipParams: FlipParams,
    magazine: Flipboard.Section,
    mentionLinks?: Array<Flipboard.MentionLink>,
  ): Flipboard.Thunk =>
  async (dispatch, getState, { flap, t }) => {
    const uid = currentUserUid(getState());
    try {
      dispatch({ type: MAGAZINE_TYPES.MAGAZINE_FLIP_PENDING });
      const target =
        flipParams.target.magazineTarget || flipParams.target.joinTarget;
      if (!target) {
        throw new MissingFlipTargetError();
      }
      const body = { ...flipParams, target };

      const { data } = await flap.post<
        Flipboard.FlapShareWithCommentRequestBody,
        AxiosResponse<Flipboard.FlapShareWithCommentResponse>
      >('/social/shareWithComment', body, {
        params: {
          userid: uid,
          magazineUserid: magazine?.author?.userid,
          service: 'flipboard',
          ...(mentionLinks ? { link: mentionLinks } : undefined),
        },
      });
      dispatch({
        type: MAGAZINE_TYPES.MAGAZINE_FLIP_SUCCESS,
        data: data.userInfo,
      });
      dispatch(
        toastShowInfoAction({
          template: TOAST_TEMPLATES.FLIPPED,
          magazine,
        }),
      );
      dispatch(maybeRefreshMagazine(magazine.remoteid));
    } catch (_) {
      dispatch({ type: MAGAZINE_TYPES.MAGAZINE_FLIP_FAILED });
      dispatch(
        toastShowErrorAction(
          t('flipped_into_magazine_failure', {
            magazineTitle: magazine.title,
          }),
        ),
      );
    }
  };

export const flipItem =
  (
    item: Flipboard.Item,
    magazine: Flipboard.Section,
    caption: string,
    navFrom: string | null = null,
    mentionLinks?: Array<Flipboard.MentionLink>,
  ): Flipboard.Thunk =>
  async (dispatch) => {
    const body = {
      url: item.sourceURL,
      target: magazine,
      oid: item.id,
      text: caption,
    };
    await dispatch(flip(body, magazine, mentionLinks));
    if (navFrom) {
      dispatch(usageSetNavFrom(USAGE_EVENT_NAMES.ITEM_FLIP, navFrom));
    }
    dispatch(usageTrackItemFlip(item, magazine));
    dispatch(updateItemSocialActivity(item));
  };

export const flipSection =
  (
    section: Flipboard.Section,
    magazine: Flipboard.Section,
    caption: string,
    navFrom: string,
    mentionLinks?: Array<Flipboard.MentionLink>,
  ): Flipboard.Thunk =>
  async (dispatch) => {
    if (navFrom) {
      dispatch(usageSetNavFrom(USAGE_EVENT_NAMES.SECTION_FLIP, navFrom));
    }
    const sectionUrl = await dispatch(shortenSection(section));
    const body = {
      url: sectionUrl,
      target: magazine,
      sectionId: getSectionId(section) as Flipboard.SectionId,
      text: caption,
    };
    await dispatch(flip(body, magazine, mentionLinks));
    if (navFrom) {
      dispatch(usageSetNavFrom(USAGE_EVENT_NAMES.SECTION_FLIP, navFrom));
    }
    dispatch(usageTrackSectionFlip(section, magazine));
  };

export const flipUrl =
  (
    url: string,
    magazine: Flipboard.Section,
    caption: string,
    navFrom: string | null = null,
    mentionLinks?: Array<Flipboard.MentionLink>,
  ): Flipboard.Thunk =>
  async (dispatch) => {
    const body: FlipParams = {
      url,
      target: magazine,
      text: caption,
    };
    await dispatch(flip(body, magazine, mentionLinks));
    if (navFrom) {
      dispatch(usageSetNavFrom(USAGE_EVENT_NAMES.URL_FLIP, navFrom));
    }
    dispatch(usageTrackUrlFlip(url, magazine));
  };

interface FlapCreateMagazineBody {
  title: string;
  description: string;
  magazineVisibility: Flipboard.FlapMagazine['magazineVisibility'];
}
export const createMagazine =
  (
    title: string,
    description: string,
    isPublic: boolean,
    profile: Record<string, unknown>,
  ): Flipboard.Thunk =>
  async (dispatch, _, { flap, t }) => {
    try {
      dispatch({ type: MAGAZINE_TYPES.CREATE_MAGAZINE_PENDING });

      const body: FlapCreateMagazineBody = {
        title,
        description,
        magazineVisibility: isPublic ? 'public' : 'private',
      };
      const params = { usessid: true };
      const { data } = await flap.post<
        FlapCreateMagazineBody,
        AxiosResponse<Flipboard.FlapCreateMagazineResponse>
      >('/curator/createMagazine', body, {
        params,
      });
      if (data.magazine) {
        const { socialId, magazineVisibility } = data.magazine;
        dispatch(
          usageTrackMagazineCreate(socialId, magazineVisibility, profile),
        );
        const magazine = SectionUtil.projection(data.magazine);
        dispatch({
          type: MAGAZINE_TYPES.CREATE_MAGAZINE_SUCCESS,
          magazine,
        });
        dispatch(toastShowInfoAction(t('magazine_create_success')));
        return magazine;
      }

      // No magazine returned in response
      const error = new Error('No magazine in response');
      dispatch({ type: MAGAZINE_TYPES.CREATE_MAGAZINE_FAILED, error });
      dispatch(
        toastShowErrorAction(
          FlapUtil.errorMessage(error, t, t('magazine_create_failure')),
        ),
      );
    } catch (error) {
      dispatch({ type: MAGAZINE_TYPES.CREATE_MAGAZINE_FAILED, error });
      dispatch(
        toastShowErrorAction(
          FlapUtil.errorMessage(error, t, t('magazine_create_failure')),
        ),
      );
    }
  };

export const resetMagazineSaveState = () => ({
  type: MAGAZINE_TYPES.MAGAZINE_SAVE_STATE_RESET,
});

export const editMagazine =
  (
    target: Flipboard.MagazineTarget,
    data: Record<string, string>,
  ): Flipboard.Thunk =>
  async (dispatch, _, { flap, t }) => {
    try {
      dispatch({ type: MAGAZINE_TYPES.EDIT_MAGAZINE_PENDING });

      const validKeys = [
        'title',
        'description',
        'coverItemId',
        'imageURL',
        'magazineVisibility',
        'magazineIsProfile',
        'magazineContributorsCanInviteOthers',
      ];

      // This endpoint requires keys/values passed as URL params with each additional pairs passed with repeated "key" and "value" keys
      // Arrays of keys/values are not allowed.
      const params = new URLSearchParams();
      params.append('target', target);

      for (const k in data) {
        // Prevent invalid keys from being passed
        if (validKeys.indexOf(k) !== -1) {
          params.append('key', k);
          params.append('value', data[k]);
        }
      }

      await flap.post(
        '/curator/editMagazine',
        {},
        {
          params,
        },
      );
      dispatch({
        type: MAGAZINE_TYPES.EDIT_MAGAZINE_SUCCESS,
      });
      dispatch(fetchUserInfo());
      dispatch(toastShowInfoAction(t('magazine_save_success')));
      dispatch(dismissModal());
    } catch (error) {
      dispatch({ type: MAGAZINE_TYPES.EDIT_MAGAZINE_FAILED, error });
      dispatch(toastShowErrorAction(t('magazine_save_failure')));
    }
  };

export const deleteMagazine =
  (target: Flipboard.MagazineTarget): Flipboard.Thunk =>
  async (dispatch, _, { flap, t }) => {
    try {
      dispatch({ type: MAGAZINE_TYPES.DELETE_MAGAZINE_PENDING });
      await flap.post('/curator/destroyMagazine', { target });
      dispatch({
        type: MAGAZINE_TYPES.DELETE_MAGAZINE_SUCCESS,
      });
      dispatch(fetchUserInfo());
      dispatch(toastShowInfoAction(t('magazine_delete_success')));
      dispatch(dismissModal());
    } catch (error) {
      dispatch({ type: MAGAZINE_TYPES.DELETE_MAGAZINE_FAILED, error });
      dispatch(toastShowErrorAction(t('magazine_delete_failure')));
    }
  };

export const createMagazinePipe =
  (
    target: Flipboard.MagazineTarget,
    source: string,
  ): Flipboard.Thunk<Promise<Flipboard.Pipe>> =>
  async (dispatch, _, { publisher }) => {
    dispatch({ type: MAGAZINE_TYPES.CREATE_MAGAZINE_PIPE });
    const params = { target, source };
    try {
      const { data } = await publisher.post<
        Record<string, unknown>,
        AxiosResponse<Flipboard.FlapResponse<{ item: Array<Flipboard.Pipe> }>>
      >(
        '/int/pipes/create',
        {},
        {
          params,
        },
      );
      if (!data.success) {
        dispatch({ type: MAGAZINE_TYPES.CREATE_MAGAZINE_PIPE_FAILED });
        throw new Error();
      }
      const pipe = data.item && data.item[0];
      if (!pipe) {
        throw new Error();
      }
      dispatch({ type: MAGAZINE_TYPES.CREATE_MAGAZINE_PIPE_SUCCESS, pipe });
      return pipe;
    } catch (error) {
      dispatch({ type: MAGAZINE_TYPES.CREATE_MAGAZINE_PIPE_FAILED });
      throw error;
    }
  };

export const changePinnedMagazineItem =
  (
    magazine: Flipboard.Section,
    itemId: Flipboard.ItemId | false,
    successTKey: string,
    failureTKey: string,
  ): Flipboard.Thunk =>
  async (dispatch, getState, { flap, t }) => {
    const target = magazine.magazineTarget;
    if (target) {
      const uid = currentUserUid(getState());
      const { data } = await flap.get<Flipboard.FlapResponse>(
        `/curator/editMagazine/${uid}`,
        {
          params: {
            userid: uid,
            target,
            key: 'magazinePinnedItem',
            value: itemId || '',
          },
        },
      );
      if (data.success) {
        await dispatch(maybeRefreshMagazine(magazine.remoteid));
        dispatch(toastShowInfoAction(t(successTKey)));
      } else {
        dispatch(toastShowErrorAction(t(failureTKey)));
      }
    }
  };

export const pinMagazineItem =
  (magazine: Flipboard.Section, itemId: Flipboard.ItemId): Flipboard.Thunk =>
  (dispatch) =>
    dispatch(
      changePinnedMagazineItem(
        magazine,
        itemId,
        'magazine_item_pin_success',
        'magazine_item_pin_failure',
      ),
    );

export const unpinMagazineItem =
  (magazine: Flipboard.Section): Flipboard.Thunk =>
  (dispatch) =>
    dispatch(
      changePinnedMagazineItem(
        magazine,
        false,
        'magazine_item_unpin_success',
        'magazine_item_unpin_failure',
      ),
    );

/**
 * Removes an item from a magazine
 * @param {Object} item     - Flipboard item object
 * @param {Object} section  - Flipboard section object
 * @return {Promise}        - A promise that resolves as an object
 */
export const removeItemFromMagazine =
  (item: Flipboard.Item, section: Flipboard.Section): Flipboard.Thunk =>
  async (dispatch, _, { flap, t }) => {
    const params = {
      oid: item.id,
      service: 'flipboard',
      target: item.magazineTarget,
    };
    dispatch({ type: MAGAZINE_TYPES.REMOVE_ITEM_FROM_MAGAZINE_PENDING });

    try {
      const response = await flap.post<
        unknown,
        AxiosResponse<Flipboard.FlapResult>
      >('/social/destroy', {}, { params });
      if (response && response.data && response.data.code == 200) {
        dispatch({
          type: MAGAZINE_TYPES.REMOVE_ITEM_FROM_MAGAZINE_SUCCESS,
          payload: {
            oid: item.id,
            target: item.magazineTarget,
          },
        });
        dispatch(toastShowInfoAction(t('remove_item_from_magazine_success')));

        dispatch(maybeRefreshMagazine(section.remoteid));
        dispatch(dismissModal());
      }
    } catch (error) {
      dispatch({
        type: MAGAZINE_TYPES.REMOVE_ITEM_FROM_MAGAZINE_FAILED,
        error,
      });
      dispatch(toastShowInfoAction(t('remove_item_from_magazine_failure')));
      dispatch(dismissModal());
    }
  };

export const maybeRefreshMagazine =
  (remoteId: Flipboard.SectionId): Flipboard.Thunk =>
  (dispatch, getState) => {
    const { sections } = getState();
    const magazine = FlapUtil.getSectionByRemoteId(remoteId, sections.entries);
    // if the magazine is loaded and in sections entries
    if (magazine) {
      // we refresh it
      dispatch(
        getSection(magazine.remoteid, {
          limit: MAX_UPDATE_FEED_LIMIT,
          primarySectionForRoute: true,
        }),
      );
    }
  };
