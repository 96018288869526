import GlobalVars from 'Utils/global-vars';
import FlapStaticClient from './client';

/**
 * Wrapper for Flap Static axios instances.
 * To avoid any confusion in a isomorphic application, just import
 * this file as your Flap axios instance, and it should work on servers and clients
 * import Flap from 'Utils/api/flap-static'
 */

const Flap = GlobalVars.isServer()
  ? eval(`require('./server')`).default // hide from webpack so not bundled
  : FlapStaticClient;
export default Flap;
