import Comment from 'Webapp/shared/app/components/notifications/content/comment';
import { NotificationTextClickStrategy } from 'Webapp/shared/app/components/notifications/notification-text-click-strategies/index';
import { FlapNotificationType } from 'Webapp/enums';

const strategy: NotificationTextClickStrategy = (notification) => {
  if (
    ![
      FlapNotificationType.COMMENT,
      FlapNotificationType.COMMENT_MENTION,
      FlapNotificationType.COMMENT_SAME_POST,
      FlapNotificationType.COMMENT_BY_PEOPLE_YOU_FOLLOW,
    ].includes(notification.notificationType)
  ) {
    return null;
  }

  const item = notification?.referredByItems?.[0];
  if (!item) {
    return null;
  }

  return Comment;
};

export default strategy;
