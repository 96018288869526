import { report as datadogReport } from 'Utils/browser-datadog';
import getWindow from 'Utils/get-window';
import reportVitals from 'Utils/report-vitals';
import normalizedPathname from 'Utils/normalized-pathname';
import isCrawler from 'Utils/is-crawler';

export default () => {
  const { navigator } = getWindow();

  if (
    navigator &&
    navigator.userAgent &&
    !isCrawler({ get: () => navigator.userAgent })
  ) {
    const start = new Date();
    const pathname = normalizedPathname(getWindow().location.href);

    const tags = {
      pathname,
    };

    reportVitals(tags);
    getWindow().addEventListener('load', () => {
      datadogReport(
        'histogram',
        'load',
        new Date().getTime() - start.getTime(),
        tags,
      );
    });
  }
};
