import React from 'react';
import PropTypes from 'prop-types';
import {
  getBestImageSrc,
  THEMES,
} from 'Webapp/shared/app/components/base/generic-image';
import { upgradeToHTTPS } from 'Utils/image-util';

const AmpImage = ({
  alt,
  ampHeight,
  ampLayout,
  ampNoLoading,
  ampWidth,
  className,
  fallbackElement,
  height,
  image,
  variant,
  width,
  ampStyle,
  ampClass,
}) => (
  <div className={className} style={ampStyle}>
    <amp-img
      class={ampClass}
      layout={ampLayout}
      src={upgradeToHTTPS(getBestImageSrc(variant, image))}
      alt={alt}
      width={ampWidth || width}
      height={ampHeight || height}
      noloading={ampNoLoading ? 'noloading' : undefined}
    >
      {fallbackElement}
    </amp-img>
  </div>
);

AmpImage.propTypes = {
  alt: PropTypes.string,
  ampHeight: PropTypes.number,
  ampLayout: PropTypes.string,
  ampNoLoading: PropTypes.bool,
  ampWidth: PropTypes.number,
  ampStyle: PropTypes.object,
  className: PropTypes.string,
  fallbackElement: PropTypes.element,
  height: PropTypes.number,
  image: PropTypes.object,
  width: PropTypes.number,
  variant: PropTypes.oneOf(Object.keys(THEMES)),
  ampClass: PropTypes.string,
};

AmpImage.defaultProps = {
  ampLayout: 'responsive',
  ampNoLoading: false,
  ampStyle: {},
  ampClass: '',
  variant: THEMES.medium,
};

export default AmpImage;
