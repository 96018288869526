import { StyledComponent } from '@emotion/styled';
import {
  CSSObject,
  CSSPropertiesWithMultiValues,
  CSSPseudos,
} from '@emotion/serialize/types';

export interface StyleObject extends CSSPropertiesWithMultiValues, CSSPseudos {}

export const styleObject = (obj: StyleObject): CSSObject => obj as CSSObject;

export const componentSelector = (
  arg: StyledComponent<Record<string, unknown>>,
): string => arg as unknown as string;

export const nestedSelector = (selector: string, obj: StyleObject): CSSObject =>
  ({ [selector]: obj } as CSSObject);
