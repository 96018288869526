import {
  UpdateFeedNullUserError,
  MissingSectionsParamError,
} from 'Utils/errors';
import sentry from 'Utils/sentry';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import isValueless from 'Utils/is-valueless';
import { MAX_UPDATE_FEED_LIMIT } from 'Utils/api/flap/index.js';

const updateFeed = (
  flaxiosInstance: Flipboard.APIs['flap'] | Flipboard.APIs['flapInternal'],
  uid: Flipboard.UserId,
  params: Flipboard.FlapUpdateFeedParams,
  config?: AxiosRequestConfig,
) => {
  if (isValueless(uid)) {
    throw new UpdateFeedNullUserError();
  }
  if (Array.isArray(params.sections) || isValueless(params.sections)) {
    throw new MissingSectionsParamError();
  }
  if (params.limit && params.limit > MAX_UPDATE_FEED_LIMIT) {
    sentry.captureMessage('udpate-feed limit MAX_UPDATE_FEED_LIMIT exceeded');
    params.limit = MAX_UPDATE_FEED_LIMIT;
  }
  return flaxiosInstance.get<unknown, AxiosResponse<Flipboard.FlapStream>>(
    `/users/updateFeed/${uid}`,
    {
      params: Object.assign(
        {
          nostream: true,
          wantsMetadata: true,
          usessid: true,
          limit: 0,
        },
        params,
      ),
      ...config,
    },
  );
};

export default updateFeed;
