import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import GlobalVars from 'Utils/global-vars';

const retry = function (instance: AxiosInstance) {
  axiosRetry(instance, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: (error) => {
      const { response } = error;
      // flap is sure things aren't fine
      if (
        response?.status === 200 &&
        (response?.data as { success: boolean })?.success === false
      ) {
        return false;
      }
      return axiosRetry.isNetworkOrIdempotentRequestError(error);
    },
  });
};

const defaultOptions = {
  timeout: 10000,
};

export const factory = (
  axiosOptions: AxiosRequestConfig,
  allowRetries = true,
) => {
  const instance = axios.create(
    Object.assign({}, defaultOptions, axiosOptions),
  );

  if (GlobalVars.isServer()) {
    // do not do this on the client, eval to skip bundling
    const CurlDebugInterceptor = eval(
      'require("../server/lib/curl-debug-interceptor")',
    );
    CurlDebugInterceptor.apply(instance);
  }

  if (allowRetries) {
    retry(instance);
  }
  return instance;
};

retry(axios);
export default axios;
