import React from 'react';
import PropTypes from 'prop-types';
import AccountVerificationStatus from 'Webapp/shared/app/components/account-verification-status';

import connector from 'Utils/connector';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectVerificationStatus from 'Webapp/shared/app/connectors/connectVerificationStatus';

function NavbarAccountVerificationStatus({
  showAccountVerificationStatus,
  isWideLargeScreen,
}) {
  return (
    (showAccountVerificationStatus && isWideLargeScreen && (
      <AccountVerificationStatus />
    )) ||
    null
  );
}

NavbarAccountVerificationStatus.propTypes = {
  showAccountVerificationStatus: PropTypes.bool.isRequired,
  isWideLargeScreen: PropTypes.bool.isRequired,
};

export default connector(
  connectVerificationStatus,
  connectResponsive,
)(NavbarAccountVerificationStatus);
