import React from 'react';
import PropTypes from 'prop-types';

// Components
import CloseIcon from 'ComponentLibrary/icons/close';
import Button, { StyleVariations } from 'Webapp/shared/app/components/button';
import UnstyledButton from 'Webapp/shared/app/components/unstyled-button';
import BannerImage from './banner-image';

import connector from 'Utils/connector';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';

function GenericBanner({ banner, isPhone, onClose }) {
  if (isPhone) {
    return null;
  }
  return (
    <div className="banner">
      <div className="banner__content">
        <h1 className="ui-text--page-title banner__title">{banner.title}</h1>
        <p className="ui-text--body banner__subtitle">{banner.subtitle}</p>
        <div>
          <Button
            name="banner-cta"
            label={banner.cta}
            styleVariation={StyleVariations.PRIMARY}
            href={banner.link}
          />
        </div>
      </div>
      <div className="banner__image-wrapper">
        <BannerImage
          smallImage={banner.smallImage}
          mediumImage={banner.mediumImage}
          largeImage={banner.largeImage}
        />
        <UnstyledButton onClick={() => onClose(banner.id)} name="banner-close">
          <CloseIcon color="#fff" />
        </UnstyledButton>
      </div>
    </div>
  );
}

GenericBanner.propTypes = {
  isPhone: PropTypes.bool.isRequired,
  banner: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default connector(connectResponsive)(GenericBanner);
