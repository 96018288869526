import { combineReducers } from 'redux';
import homeAnalytics from './analytics/home';
import app from './app';
import articles from './articles';
import auth from './auth';
import content from './content';
import magazine from './magazine';
import modal from './modal';
import storyboards from './storyboards';
import curatorProMagazine from './curator-pro/magazine';
import curatorProStoryboard from './curator-pro/storyboard';
import publisher from './publisher';
import profile from './profile';
import recommendedBoards from './recommended-boards';
import search from './search';
import searchNav from './search-nav';
import sections from './sections';
import settings from './settings';
import profileAnalytics from './analytics/profile';
import storyboardAnalytics from './analytics/storyboard';
import magazineAnalytics from './analytics/magazine';
import toast from './toast';
import emailSettings from './account/email-settings';
import usage from './usage';
import flipMode from './flip-mode';
import blocking from './blocking';
import newsLettersLanding from './newsletters-landing';

import { reducer as sitemap } from 'Webapp/shared/concepts/sitemap';
import { reducer as featureFlags } from 'Webapp/shared/concepts/feature-flags';
import { reducer as campaigns } from 'Webapp/shared/concepts/campaigns';
import { reducer as accessorySections } from 'Webapp/shared/concepts/accessory-sections';
import { reducer as sharedLinkData } from 'Webapp/shared/concepts/shared-link';
import { reducer as socialActivity } from 'Webapp/shared/concepts/social-activity';
import { reducer as comments } from 'Webapp/shared/concepts/comments';
import { reducer as userFeatures } from 'Webapp/shared/concepts/user-features';
import { reducer as magazineInvite } from 'Webapp/shared/concepts/magazine-invite';
import { reducer as notifications } from 'Webapp/shared/concepts/notifications';
import { reducer as onboarding } from 'Webapp/shared/concepts/onboarding';
import { reducer as magazineSubscriptions } from 'Webapp/shared/concepts/magazine-subscriptions';

const reducers = combineReducers({
  usage,
  app,
  articles,
  auth,
  content,
  magazine,
  modal,
  storyboards,
  curatorProMagazine,
  curatorProStoryboard,
  homeAnalytics,
  recommendedBoards,
  search,
  searchNav,
  sections,
  publisher,
  profile,
  settings,
  profileAnalytics,
  storyboardAnalytics,
  magazineAnalytics,
  toast,
  emailSettings,
  flipMode,
  blocking,
  newsLettersLanding,
  sitemap,
  campaigns,
  featureFlags,
  accessorySections,
  sharedLinkData,
  socialActivity,
  comments,
  userFeatures,
  magazineInvite,
  notifications,
  onboarding,
  magazineSubscriptions,
});

export default reducers;
