export default {
  /**
   * Creates a debounce function
   * @param  {Function} func - function to be debounced
   * @param  {Number} interval - Milliseconds for debouncing
   * @return {Function} - function that supports debouncing.  This
   * function returns a cancel() function when called
   */
  create(func, interval) {
    let timeout;

    const cancelFn = (t) => () => {
      clearTimeout(t);
    };

    return (...args) => {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const context = this;
      const later = () => {
        timeout = null;
        func.apply(context, args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, interval);

      // return a cancel function
      return cancelFn(timeout);
    };
  },
};
