import getWindow from 'Utils/get-window';

/**
 * As of iOS 13, iPad OS now thinks it's a real desktop experience and identifies as a real MAC. As such, any behaviour that requires us to know
 * if a devices is an iPad will break. The MobileDetect Library does not work. https://github.com/hgoebl/mobile-detect.js/issues/105
 * This "hack" needs to be done client side. iPadOS supports 5 touch points.
 */
export const isIPadDetectHack = () => {
  const window = getWindow();
  const navigator = window && window.navigator;
  const isIPad =
    navigator &&
    navigator.userAgent &&
    navigator.userAgent.match(/Macintosh/) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints >= 5;

  return isIPad ? true : false;
};
