import { JSXElementConstructor } from 'react';
import { MODAL_TYPES } from 'Webapp/action-types';

export const showModal = <P = unknown>(
  component: JSXElementConstructor<Record<string, unknown>>,
  props: P = {} as P,
) => ({
  type: MODAL_TYPES.SHOW_MODAL,
  payload: {
    component,
    props,
  },
});

export const getModalComponent = (
  component: JSXElementConstructor<Record<string, unknown>>,
  state: Flipboard.State,
) => {
  const {
    modal: { current },
  } = state;
  const foundModal = current.find((m) => m.component === component);
  return foundModal?.component || null;
};
export const isModalShown =
  (
    component: JSXElementConstructor<Record<string, unknown>>,
  ): Flipboard.Thunk<boolean> =>
  (_dispatch, getState) => {
    const shownModalComponent = getModalComponent(component, getState());
    return !!shownModalComponent;
  };

export const dismissSpecificModal =
  (
    component: JSXElementConstructor<Record<string, unknown>>,
  ): Flipboard.Thunk =>
  (dispatch, getState) => {
    const shownModalComponent = getModalComponent(component, getState());
    dispatch({
      type: MODAL_TYPES.DISMISS_SPECIFIC_MODAL,
      payload: {
        component: shownModalComponent,
      },
    });
  };

export const dismissModal = () => ({ type: MODAL_TYPES.DISMISS_MODAL });

export const dismissAllModals = () => ({
  type: MODAL_TYPES.DISMISS_ALL_MODALS,
});

export const setModalLoginComplete = () => ({
  type: MODAL_TYPES.SET_MODAL_LOGIN_COMPLETE,
});
