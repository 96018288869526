import { Breadcrumbs } from 'Utils/content/structured-data';
import { euc } from 'Utils/url';
import { getProfilePath, getArticlePath } from 'Utils/content/flipboard-urls';

export default ({ item, section }) => {
  if (!item) {
    return null;
  }
  const breadcrumbs = new Breadcrumbs();

  if (section) {
    if (section.isTopic) {
      breadcrumbs.add(`#${section.title}`, section.canonicalPath);
    }

    if (section.isMagazine) {
      if (section.authorUsername) {
        breadcrumbs.add(
          `@${euc(section.authorUsername)}`,
          getProfilePath(section),
        );
      }

      breadcrumbs.add(section.title, section.canonicalPath);
    }

    if (section.isProfile) {
      breadcrumbs.add(`@${euc(section.authorUsername)}`, section.canonicalPath);
    }
  }

  breadcrumbs.add(item.sanitizedTitle, getArticlePath(item, section));
  return breadcrumbs;
};
