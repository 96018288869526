import trackOnboardAppStartAuthenticated from 'Webapp/shared/app/components/onboarding-flows/steps/track-onboard-app-start-authenticated';
import onboardingTopicPicker from 'Webapp/shared/app/components/onboarding-flows/steps/onboarding-topic-picker';
import performPostActivationActions from 'Webapp/shared/app/components/onboarding-flows/steps/perform-post-activation-actions';

import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

const steps: Array<OnboardingStep> = [
  trackOnboardAppStartAuthenticated,
  onboardingTopicPicker,
  performPostActivationActions,
];

export default steps;
