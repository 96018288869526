import { Breadcrumbs } from 'Utils/content/structured-data';
import { euc } from 'Utils/url';
import { getProfilePath } from 'Utils/content/flipboard-urls';

const profileRoot = 'https://flipboard.com/profile';

export default ({ section }) => {
  if (!section || !section.isProfile) {
    return null;
  }
  const breadcrumbs = new Breadcrumbs();
  breadcrumbs.add('profile', profileRoot);
  breadcrumbs.add(`@${euc(section.authorUsername)}`, getProfilePath(section));
  return breadcrumbs;
};
