import React from 'react';
import PropTypes from 'prop-types';

// Utils
import getWindow from 'Utils/get-window';

// Components
import WaitFor from 'ComponentLibrary/utility/wait-for';

import connector from 'Utils/connector';
import connectFeatureFlags from 'Webapp/shared/app/connectors/connectFeatureFlags';

function waitFor() {
  // wait for SourcePoint to load
  return !!getWindow().__gpp;
}
class UsnatApplies extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      usnatApplies: false,
    };
  }

  getUsnatApplies = () => {
    const { FORCE_USNAT_APPLIES } = this.props.featureFlags;

    getWindow().__gpp('addEventListener', (e) => {
      const applicableSections = e?.pingData?.applicableSections || [];
      const usnatApplies = applicableSections.length > 0 ? true : false;
      this.setState({
        usnatApplies: FORCE_USNAT_APPLIES === true ? true : usnatApplies,
      });
    });
  };

  render() {
    return (
      <React.Fragment>
        <WaitFor predicate={waitFor} onWaitComplete={this.getUsnatApplies} />
        {typeof this.props.children === 'function'
          ? this.props.children(this.state.usnatApplies)
          : null}
        {typeof this.props.children !== 'function' && this.state.usnatApplies
          ? this.props.children
          : null}
      </React.Fragment>
    );
  }
}

UsnatApplies.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  featureFlags: PropTypes.shape({
    FORCE_USNAT_APPLIES: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connector(connectFeatureFlags)(UsnatApplies);
