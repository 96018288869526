import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Icon from './icon';
import withT from 'ComponentLibrary/hocs/withT';

const StyledIcon = styled(Icon)({
  color: 'var(--color--icon)',
  g: {
    color: 'inherit',
    path: {
      color: 'inherit',
      fill: 'currentColor',
      stroke: 'currentColor',
    },
  },
});

function LessLikeThisIcon(props) {
  return (
    <StyledIcon
      role="img"
      name="less-like"
      aria-labelledby="less-like-this-icon-title"
      size={18}
      viewBox="0 0 24 24"
      {...props}
      renderComponent={({ color }) => (
        <g>
          <path
            strokeWidth="1"
            clipRule="evenodd"
            fill={color}
            fillRule="evenodd"
            strokeLinecap="square"
            d="m 10.783203,16.570312 -1.027344,1.09961 2.613282,2.894531 7.384765,-8.162109 0.0039,-0.0059 C 20.575415,11.46296 21.125,10.26271 21.125,8.9609375 c 0,-0.9734388 -0.277165,-1.886866 -0.748047,-2.671875 L 19.292969,7.4511719 C 19.503708,7.9149895 19.625,8.4252556 19.625,8.9609375 c 0,0.874307 -0.379691,1.7434315 -0.996094,2.4472655 l -6.259765,6.919922 z"
          />
          <path
            strokeWidth="1"
            clipRule="evenodd"
            fill={color}
            fillRule="evenodd"
            strokeLinecap="square"
            d="M 19.419922,5.1152344 C 18.504682,4.2722672 17.300247,3.75 15.990234,3.75 14.511175,3.75 13.29055,4.5227926 12.375,5.5566406 11.450885,4.5200906 10.218215,3.75 8.740234,3.75 5.945717,3.75 3.625,6.0851656 3.625,8.9316406 c 0,1.3017824 0.545588,2.5264684 1.373047,3.4667974 l 0.002,0.0039 3.746094,4.148437 1.027344,-1.099609 -3.650391,-4.042969 v -0.002 C 5.510373,10.70942 5.125,9.8053226 5.125,8.9316406 5.125,6.9012396 6.785477,5.25 8.740234,5.25 c 1.238911,0 2.36214,0.6980191 3,1.7207031 l 0.638672,1.0234375 0.634766,-1.0234375 C 13.65166,5.9432541 14.747194,5.25 15.990234,5.25 c 0.905353,0 1.752061,0.3710652 2.402344,0.9667969 z"
          />
          <path
            fill="none"
            stroke={color}
            strokeWidth="1"
            strokeLinecap="square"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeDasharray="none"
            strokeOpacity="1"
            d="M 20.386,3 5.375,19.083"
          />
        </g>
      )}
    />
  );
}

LessLikeThisIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  toolTip: PropTypes.string,
  t: PropTypes.func.isRequired,
};

LessLikeThisIcon.defaultProps = {
  size: 18,
  className: '',
};

export default withT(LessLikeThisIcon);
