import React from 'react';
import styled from '@emotion/styled';
import { styleOnlyProps } from 'Style/style-helpers';

import SectionUtil from 'Utils/content/section-util';
import { smallestUrl } from 'Utils/image-util';

import Image from 'Webapp/shared/app/components/base/image.js';
import { isSectionLinkMagazine } from 'Webapp/predicates';
import MagazineThumbnail from 'Webapp/shared/app/components/magazine-thumbnail';
import SectionLink from 'Webapp/shared/app/components/section-link';
import ItemLink from 'Webapp/shared/app/components/item/item-link.js';

import connector from 'Utils/connector';
import connectNotifications, {
  ConnectNotificationsProps,
} from 'Webapp/shared/app/connectors/connectNotifications';

const IMAGE_SIZE = 40;

type StyledThumbnailWrapperProps = {
  imgHeight: number;
  imgWidth: number;
};
const StyledThumbnailWrapper = styled(
  'div',
  styleOnlyProps('imgHeight', 'imgWidth'),
)<StyledThumbnailWrapperProps>(({ imgHeight, imgWidth }) => ({
  overflow: 'hidden',
  height: `${imgHeight}px`,
  width: `${imgWidth}px`,
}));

type NotificationThumbProps = ConnectNotificationsProps & {
  notification: Flipboard.Notification;
  isItemLink?: boolean;
  disableLink?: boolean;
};

const NotificationThumb: React.FC<NotificationThumbProps> = ({
  notification,
  isItemLink,
  disableLink,
}) => {
  if (notification.image) {
    const item = notification?.referredByItems?.[0];
    const thumbnail = (
      <StyledThumbnailWrapper imgHeight={IMAGE_SIZE} imgWidth={IMAGE_SIZE}>
        <Image
          image={notification.image}
          urlHelper={smallestUrl}
          alt={notification.text}
          ampLayout="fixed"
          ampWidth={IMAGE_SIZE}
          ampHeight={IMAGE_SIZE}
          ampStyle={{
            width: IMAGE_SIZE,
            height: IMAGE_SIZE,
          }}
          crop
        />
      </StyledThumbnailWrapper>
    );
    return item ? (
      <ItemLink item={item} shownInList forceInternal>
        {thumbnail}
      </ItemLink>
    ) : (
      thumbnail
    );
  }
  const magazine = notification.sectionLinks?.find(isSectionLinkMagazine);
  const item = notification?.referredByItems?.[0];
  if (magazine) {
    const MagazineThumbnailComponent = (
      <MagazineThumbnail
        name={magazine.title}
        width={IMAGE_SIZE}
        height={IMAGE_SIZE}
        image={magazine.image}
      />
    );
    if (isItemLink && item) {
      return disableLink ? (
        MagazineThumbnailComponent
      ) : (
        <ItemLink item={item} shownInList forceInternal>
          {MagazineThumbnailComponent}
        </ItemLink>
      );
    }
    return disableLink ? (
      MagazineThumbnailComponent
    ) : (
      <SectionLink section={SectionUtil.projection(magazine)}>
        {MagazineThumbnailComponent}
      </SectionLink>
    );
  }
  return <span />;
};

export default connector<NotificationThumbProps>(connectNotifications)(
  NotificationThumb,
);
