import React from 'react';
import PropTypes from 'prop-types';
import sentry from 'Utils/sentry';

import connector from 'Utils/connector';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';

const StructuredData = ({ isAuthenticated, getData, ...props }) => {
  try {
    if (isAuthenticated) {
      return null;
    }
    const data = getData(props);
    if (!data) {
      return null;
    }
    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `${JSON.stringify(data)}`,
        }}
      />
    );
  } catch (e) {
    sentry.captureException(e);
    return null;
  }
};

StructuredData.propTypes = {
  getData: PropTypes.func.isRequired,
};

export default connector(connectAuthentication)(StructuredData);
