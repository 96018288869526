import { uniqBy } from 'lodash';
import {
  SECTIONS_TYPES,
  SETTINGS_TYPES,
  MUTING_TYPES,
  MAGAZINE_TYPES,
} from 'Webapp/action-types';
import SectionUtil from 'Utils/content/section-util';
import { projection } from 'Utils/content/item-util';
import FlapUtil from 'Utils/content/flap-util';
import { merger } from 'Utils/redux';

interface SectionsReducerState {
  entries: Array<Flipboard.Section>;
  topicDescriptions: Array<Flipboard.TopicDescription>;
  loading: Record<Flipboard.SectionId, boolean>;
  sectionCovers: Record<Flipboard.SectionId, Flipboard.Image>;
}

const initialState: SectionsReducerState = {
  entries: [],
  topicDescriptions: [],
  loading: {},
  sectionCovers: {},
};

/**
 * Adds a section to the entries object, returning a new
 * state instance.  If the section is a topic, magazine, or storyboard (bundle) it is added to an array
 * of remoteids used to limit the total number of retained section.
 * @param {Object} state                    - The current reducer state
 * @param {String} requestedRemoteId        - The originally requested remoteId of the section
 * @param {Object} contents                 - The projected contents of the section
 * @param {Array}  items                    - The NGL projected items in the section
 * @param {Array}  rawItems                 - The un-projected items in the section
 * @param {Array}  magazineProjections      - The projected magazines in the section
 * @param {Array}  followerProjections      - The projected followers for the section
 * @param {String} followersNextPageKey      - The key of the next FLAP followers page request
 * @param {String} nextPageKey              - The key for the next FLAP item page request
 * @param {Boolean} neverLoadMore           - Whether to allow more item page loads
 * @param {Boolean} ephemeral               - True if something will automatically reload it when needed.
 * @param {Boolean} preferMagazineContext
 * @returns {Object}                        - A new copy of the reducer state
 * with the sectinosByRemoteId object containing the supplied section, and limited
 * to retainedSectionLimit number of non-profile sections
 */
function retainSection(
  state: SectionsReducerState,
  requestedRemoteId: Flipboard.SectionId,
  sectionProjection: Flipboard.Section,
  items: Array<Flipboard.Item>,
  rawItems: Array<Flipboard.FlapItem>,
  magazineProjections?: Array<Flipboard.Section>,
  followerProjections?: Array<Flipboard.Section>,
  followersNextPageKey?: Flipboard.NextPageKey,
  nextPageKey?: Flipboard.NextPageKey,
  neverLoadMore?: boolean,
  ephemeral?: boolean,
  preferMagazineContext?: boolean,
  excludeContext?: boolean,
  primarySectionForRoute?: boolean,
  subSections?: Array<Flipboard.Section>,
) {
  const { entries } = state;

  const section = {
    ...sectionProjection,
    requestedRemoteId,
    ephemeral,
    accessedAt: new Date().getTime(),
    rawItems,
    primarySectionForRoute,
    subSections: sectionProjection.subSections || subSections,
  };

  if (typeof magazineProjections !== 'undefined') {
    section.magazineProjections = magazineProjections;
  }
  if (typeof followerProjections !== 'undefined') {
    section.followerProjections = followerProjections;
  }
  if (typeof followersNextPageKey !== 'undefined') {
    section.followersNextPageKey = followersNextPageKey;
  }
  if (typeof nextPageKey !== 'undefined') {
    section.nextPageKey = nextPageKey;
  }
  if (typeof neverLoadMore !== 'undefined') {
    section.neverLoadMore = neverLoadMore;
  }
  section.items = items.map((i) =>
    projection(i, section, preferMagazineContext, excludeContext),
  );

  return Object.assign({}, state, {
    entries: FlapUtil.updateSectionByRemoteId(section, entries),
  });
}

/**
 * Updates the "loading" state for a section using a
 * non-normalized remoteid
 * @param {Object} loading - Current loading state
 * @param {String} remoteId - Remoteid of the section
 * @param {Boolean} value   - New loading status for the section
 * @return {Object} Updated loading state
 */
function getUpdatedLoading(loading, remoteId, value) {
  const normalizedRemoteId = FlapUtil.normalizeRemoteid(remoteId);
  if (value) {
    return Object.assign({}, loading, {
      [normalizedRemoteId]: value,
    });
  }

  return Object.keys(loading)
    .filter((k) => k !== normalizedRemoteId)
    .reduce((acc, k) => {
      acc[k] = loading[k];
      return acc;
    }, {});
}

export default function sectionsReducer(state = initialState, action) {
  const merge = merger(state);
  switch (action.type) {
    case SECTIONS_TYPES.GET_SECTION: {
      const { remoteId } = action.payload;
      const updatedLoading = getUpdatedLoading(state.loading, remoteId, true);
      return merge({ loading: updatedLoading });
    }
    case SECTIONS_TYPES.GET_SECTION_SUCCEEDED: {
      const {
        remoteId,
        requestedRemoteId,
        metadata,
        items,
        rawItems,
        magazines,
        followers,
        followersNextPageKey,
        nextPageKey,
        neverLoadMore,
        isLikesSection,
        ephemeral,
        preferMagazineContext,
        excludeContext,
        primarySectionForRoute,
        subSections,
        rootTopic,
      } = action.payload;
      // project followers
      const followerProjections =
        followers &&
        followers.map((follower) => SectionUtil.projection(follower));

      let sectionProjection, magazineProjections;
      const loadedSection = FlapUtil.getSectionByRemoteId(
        remoteId,
        state.entries,
      );
      if (!loadedSection || loadedSection.stale) {
        // section from response
        const { section } = metadata;
        // project section contents
        sectionProjection =
          section &&
          SectionUtil.projection(section, items, {
            isLikesSection,
            rootTopic,
          });
        // project magazines
        magazineProjections =
          magazines && magazines.map((mag) => SectionUtil.projection(mag));
      } else {
        // section contents and magazines from store
        // const section = state.entries[remoteId];
        sectionProjection = loadedSection;
        magazineProjections = loadedSection.magazineProjections;
      }

      const updatedLoading = getUpdatedLoading(
        state.loading,
        requestedRemoteId,
        false,
      );

      return Object.assign(
        retainSection(
          state,
          requestedRemoteId,
          sectionProjection,
          items,
          rawItems,
          magazineProjections,
          followerProjections,
          followersNextPageKey,
          nextPageKey,
          neverLoadMore,
          ephemeral,
          preferMagazineContext,
          excludeContext,
          primarySectionForRoute,
          subSections,
        ),
        {
          loading: updatedLoading,
        },
      );
    }

    case SECTIONS_TYPES.GET_SECTION_FOLLOWERS: {
      const { remoteId } = action.payload;
      const section = FlapUtil.getSectionByRemoteId(remoteId, state.entries);
      if (!section) {
        return state;
      }

      const updatedSection = Object.assign({}, section, {
        followersLoading: true,
      });
      return merge({
        entries: FlapUtil.updateSectionByRemoteId(
          updatedSection,
          state.entries,
        ),
      });
    }

    case SECTIONS_TYPES.GET_SECTION_FOLLOWERS_SUCCEEDED: {
      const { remoteId, followers, isFirstPage, followersNextPageKey } =
        action.payload;
      const section = FlapUtil.getSectionByRemoteId(remoteId, state.entries);
      if (!section) {
        return state;
      }

      // project followers
      const newFollowerProjections =
        followers &&
        followers.map((follower) => SectionUtil.projection(follower));
      const updatedFollowerProjections = isFirstPage
        ? newFollowerProjections
        : section.followerProjections.concat(newFollowerProjections);

      const updatedSection = Object.assign({}, section, {
        followerProjections: updatedFollowerProjections,
        followersNextPageKey,
        followersLoading: false,
      });
      return merge({
        entries: FlapUtil.updateSectionByRemoteId(
          updatedSection,
          state.entries,
        ),
      });
    }

    case SECTIONS_TYPES.GET_SECTION_FOLLOWERS_FAILED: {
      const { remoteId } = action.payload;
      const section = FlapUtil.getSectionByRemoteId(remoteId, state.entries);
      if (!section) {
        return state;
      }

      const updatedSection = Object.assign({}, section, {
        followersLoading: false,
      });
      return merge({
        entries: FlapUtil.updateSectionByRemoteId(
          updatedSection,
          state.entries,
        ),
      });
    }

    case SECTIONS_TYPES.GET_SMART_MAGAZINE_SUCCEEDED: {
      const { requestedRemoteId, metadata, items, rawItems } = action.payload;
      const itemProjections = items.map((item) => projection(item));
      const { section } = metadata;
      const sectionProjection =
        section && SectionUtil.projection(section, itemProjections);

      return retainSection(
        state,
        requestedRemoteId,
        sectionProjection,
        itemProjections,
        rawItems,
      );
    }
    case SECTIONS_TYPES.GET_SECTION_FAILED: {
      const { remoteId } = action.payload;
      const updatedLoading = getUpdatedLoading(state.loading, remoteId, false);
      return merge({
        loading: updatedLoading,
      });
    }
    case SECTIONS_TYPES.GET_TOPIC_DESCRIPTIONS:
      return Object.assign({}, initialState, { loading: true });
    case SECTIONS_TYPES.GET_TOPIC_DESCRIPTIONS_SUCCEEDED: {
      const topics = action.payload || [];
      return merge({
        topicDescriptions: topics,
      });
    }
    case SETTINGS_TYPES.SAVE_SETTINGS_SUCCESS: {
      const userInfo = action.payload;
      const user = FlapUtil.getUserFromUserInfo(userInfo);
      const profileSection = user && user.profileSection;
      if (!profileSection) {
        return state;
      }
      let section = FlapUtil.getSectionByRemoteId(
        profileSection.remoteid,
        state.entries,
      );

      if (!section) {
        return state;
      }
      section = Object.assign(
        {},
        section,
        SectionUtil.projection(profileSection),
      );

      return merge({
        entries: FlapUtil.updateSectionByRemoteId(section, state.entries),
      });
    }
    case MAGAZINE_TYPES.REMOVE_ITEM_FROM_MAGAZINE_SUCCESS: {
      const { oid, target } = action.payload;

      // Update the section with item removed from magazine
      const updatedSections = state.entries.map((section) => {
        if (section.magazineTarget !== target) {
          return section;
        }
        const modifiedSectionItems = section.items.filter(
          (item) => item.id !== oid,
        );
        return Object.assign({}, section, { items: modifiedSectionItems });
      });
      return merge({
        entries: updatedSections,
      });
    }
    case SECTIONS_TYPES.PURGE_SECTION: {
      return merge({
        entries: state.entries.filter(
          (section) => section.remoteid !== action.remoteId,
        ),
      });
    }
    case SECTIONS_TYPES.SET_SECTION_STALE: {
      const {
        payload: { remoteId },
      } = action;

      const section = FlapUtil.getSectionByRemoteId(remoteId, state.entries);
      if (!section) {
        return state;
      }

      return merge({
        entries: FlapUtil.updateSectionByRemoteId(
          {
            ...section,
            stale: true,
          },
          state.entries,
        ),
      });
    }
    case SECTIONS_TYPES.SET_ACCESSED_AT: {
      return merge({
        entries: state.entries.map((section) => {
          if (section.remoteid === action.remoteid) {
            return Object.assign({
              ...section,
              accessedAt: new Date().getTime(),
            });
          }
          return section;
        }),
      });
    }
    case SECTIONS_TYPES.GET_SECTION_RELATED_SECTION_SUCCEEDED: {
      const {
        payload: { remoteId, relatedSectionType, sectionId, index },
      } = action;

      const section = FlapUtil.getSectionByRemoteId(remoteId, state.entries);
      if (!section) {
        return state;
      }
      let updatedRelatedSections = section.relatedSections || {};
      let updatedRelatedSectionList = (
        updatedRelatedSections[relatedSectionType] || []
      ).slice();

      if (!updatedRelatedSectionList.includes(sectionId)) {
        if (index != null) {
          // If we receive an index, but it is greater than the size/position we have processed,
          // expand the array and insert.
          if (updatedRelatedSectionList.length <= index) {
            updatedRelatedSectionList = [
              ...updatedRelatedSectionList,
              ...Array(
                Math.max(index + 1 - updatedRelatedSectionList.length, 0),
              ).fill(null),
            ];
          }
          updatedRelatedSectionList[index] = sectionId;
        } else {
          updatedRelatedSectionList.push(sectionId);
        }
        updatedRelatedSections = {
          ...updatedRelatedSections,
          [relatedSectionType]: updatedRelatedSectionList,
        };
        return merge({
          entries: FlapUtil.updateSectionByRemoteId(
            {
              ...section,
              relatedSections: updatedRelatedSections,
            },
            state.entries,
          ),
        });
      }
      return state;
    }
    case SECTIONS_TYPES.SET_TOPICS_FOR_SECTION: {
      const {
        payload: { remoteId, topics },
      } = action;
      const section = FlapUtil.getSectionByRemoteId(remoteId, state.entries);

      const updatedSection = Object.assign({}, section, {
        topics,
      });
      return merge({
        entries: FlapUtil.updateSectionByRemoteId(
          updatedSection,
          state.entries,
        ),
      });
    }
    case SECTIONS_TYPES.SET_RECOMMENDED_MAGAZINES_FOR_SECTION: {
      const {
        payload: { remoteId, recommendedMagazines },
      } = action;
      const section = FlapUtil.getSectionByRemoteId(remoteId, state.entries);

      const updatedSection = Object.assign({}, section, {
        recommendedMagazines: uniqBy(recommendedMagazines, 'remoteid'),
      });

      return merge({
        entries: FlapUtil.updateSectionByRemoteId(
          updatedSection,
          state.entries,
        ),
      });
    }
    case SECTIONS_TYPES.SET_SECTION_EPHEMERAL: {
      const { remoteId, isEphemeral } = action.payload;
      const section = FlapUtil.getSectionByRemoteId(remoteId, state.entries);

      if (!section) {
        return state;
      }
      const updatedSection = Object.assign({}, section, {
        ephemeral: isEphemeral,
      });
      return merge({
        entries: FlapUtil.updateSectionByRemoteId(
          updatedSection,
          state.entries,
        ),
      });
    }
    case SECTIONS_TYPES.GET_SECTION_COVERS_SUCCESS: {
      return merge({
        sectionCovers: {
          ...state.sectionCovers,
          ...action.sectionCovers,
        },
      });
    }

    case MUTING_TYPES.UPDATE_MUTE_ITEM_SUCCEEDED: {
      const { mutedItemID, sectionRemoteid } = action.payload;
      const { entries } = state;

      const updatedSections = entries.map((section) => {
        if (section.remoteid !== sectionRemoteid) {
          return section;
        }

        const modifiedSection = Object.assign({}, section);
        const previousMutedItems = modifiedSection.mutedItems || [];

        if (previousMutedItems.includes(mutedItemID)) {
          modifiedSection.mutedItems = modifiedSection.mutedItems?.filter(
            (mutedItem) => mutedItem !== mutedItemID,
          );
        } else {
          modifiedSection.mutedItems = [...previousMutedItems, mutedItemID];
        }

        return modifiedSection;
      });

      return merge({
        entries: updatedSections,
      });
    }

    case MUTING_TYPES.MUTE_AUTHOR_SUCCEEDED: {
      const {
        payload: { author },
      } = action;
      const updatedSections = state.entries.map((entry) => ({
        ...entry,
        items: entry.items.map((item) => ({
          ...item,
          recentlyMuted:
            item.author?.userid === author.userid ? true : item.recentlyMuted,
        })),
      }));
      return merge({ entries: updatedSections });
    }

    case MUTING_TYPES.UNMUTE_AUTHOR_SUCCEEDED: {
      const {
        payload: { author },
      } = action;
      const updatedSections = state.entries.map((entry) => ({
        ...entry,
        items: entry.items.map((item) => ({
          ...item,
          recentlyMuted:
            item.author?.userid === author.userid ? false : item.recentlyMuted,
        })),
      }));
      return merge({ entries: updatedSections });
    }

    case MUTING_TYPES.MUTE_SOURCE_DOMAIN_SUCCEEDED: {
      const {
        payload: { domain },
      } = action;
      const updatedSections = state.entries.map((entry) => ({
        ...entry,
        items: entry.items.map((item) => ({
          ...item,
          recentlyMuted:
            item.sourceDomain === domain ? true : item.recentlyMuted,
        })),
      }));
      return merge({ entries: updatedSections });
    }

    case MUTING_TYPES.UNMUTE_SOURCE_DOMAIN_SUCCEEDED: {
      const {
        payload: { domain },
      } = action;
      const updatedSections = state.entries.map((entry) => ({
        ...entry,
        items: entry.items.map((item) => ({
          ...item,
          recentlyMuted:
            item.sourceDomain === domain ? false : item.recentlyMuted,
        })),
      }));
      return merge({ entries: updatedSections });
    }

    case MUTING_TYPES.UPDATE_MUTED_CONTENTS: {
      // only keep non-ephemeral sections, all other sections should be
      // refreshed
      const updatedSections = state.entries.filter(
        (entry) => entry.ephemeral !== true,
      );
      return merge({
        entries: updatedSections,
      });
    }
    case SECTIONS_TYPES.ACCEPT_CONTRIBUTOR_INVITE_SUCCESS: {
      const { remoteId } = action.payload;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { invite: _invite, ...updatedSection } =
        FlapUtil.getSectionByRemoteId(remoteId, state.entries);

      return merge({
        entries: FlapUtil.updateSectionByRemoteId(
          updatedSection,
          state.entries,
        ),
      });
    }
    case SECTIONS_TYPES.SET_METRICS: {
      const { remoteid, metrics } = action.payload;
      const { ...updatedSection } = FlapUtil.getSectionByRemoteId(
        remoteid,
        state.entries,
      );
      updatedSection.metrics = metrics;
      return merge({
        entries: FlapUtil.updateSectionByRemoteId(
          updatedSection,
          state.entries,
        ),
      });
    }
    default:
      return state;
  }
}
