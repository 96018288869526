import React from 'react';
import PropTypes from 'prop-types';
import Config from '../../config';

// Utils
import getWindow from 'Utils/get-window';
import ObjectUtil from 'Utils/object-util';

// Components
import WebLink from './web-link';
import withT from 'ComponentLibrary/hocs/withT';

const DNS_LINK_INCLUDE_PROPS = ['href', 'name', 'className', 'onClick'];

function DataProtectionLink(props) {
  const { auto, label, onClick, t } = props;

  const showPrivacyManager = (e) => {
    if (e) {
      e.preventDefault();
    }

    // Show SourcePoint privacy management UI
    const window = getWindow();
    window._sp_?.gdpr.loadPrivacyManagerModal(
      Config.SOURCEPOINT_GDPR_PRIVACY_MANAGER_ID,
    );
  };
  const handleClick = onClick
    ? (e) => {
        showPrivacyManager(e);
        onClick(e);
      }
    : showPrivacyManager;

  const linkProps = ObjectUtil.pick(
    Object.assign({}, props, { onClick: handleClick }),
    DNS_LINK_INCLUDE_PROPS,
  );

  if (auto) {
    showPrivacyManager();
  }
  return <WebLink {...linkProps}>{label || t('data_protection')}</WebLink>;
}

DataProtectionLink.propTypes = {
  auto: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  t: PropTypes.func.isRequired,
};

DataProtectionLink.defaultProps = {
  auto: false,
  onClick: null,
};

export default withT(DataProtectionLink);
