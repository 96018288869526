import LinkSection from 'Webapp/shared/app/components/notifications/content/link-section';
import { NotificationTextClickStrategy } from 'Webapp/shared/app/components/notifications/notification-text-click-strategies/index';
import { FlapNotificationType } from 'Webapp/enums';

const useLinkSection =
  (type: FlapNotificationType): NotificationTextClickStrategy =>
  (notification) => {
    if (notification.notificationType !== type) {
      return null;
    }
    const section = notification.sectionLinks?.[0];
    if (!section) {
      return null;
    }

    return LinkSection;
  };

export default useLinkSection;
