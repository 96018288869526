export const COLORS = Object.freeze({
  red: '#f52828',
  facebook: '#4267b2',
  twitter: '#1da1f2',
  staticWhite: '#fff',
  staticBlack: '#000',
  fadeEffect: 'var(--color-fade-effect)',
  themeReverse: 'var(--color--theme-reverse)',
  createMagTile: 'var(--color--create-mag-tile)',
});

export const ALERTS = Object.freeze({
  error: 'var(--color--alert-error)',
  warning: 'var(--color--alert-warning)',
  success: 'var(--color--alert-success)',
});

export const TEXT_COLORS = Object.freeze({
  primary: 'var(--color--text-primary)',
  secondary: 'var(--color--text-secondary)',
  secondaryDark: 'var(--color--text-secondary-dark)',
  tertiary: 'var(--color--text-tertiary)',
  dim: 'var(--color--text-dim)',
  primaryReverse: 'var(--color--text-primary-reverse)',
  secondaryReverse: 'var(--color--text-secondary-reverse)',
  overlay: 'var(--color--text-overlay)',
  overlaySecondary: 'var(--color--text-overlay-secondary)',
  redLink: 'var(--color--text-red-link)',
  redLinkHover: 'var(--color--text-red-link-hover)',
});

/**
 * Generates a style object for default colors, including focus/hover styles. To be applied
 * to a focusable element (ie: button, link, etc).
 * @param isOverlay: Sets the default colour to overlay (white) instead of primary (themed)
 * @param setFocusColor, allows turning off setting the focus styles for more complex cases.
 * @returns
 */
export const DefaultTextColorStyle = (
  isOverlay: boolean,
  setFocusColor = true,
) => {
  const color = isOverlay ? TEXT_COLORS.overlay : TEXT_COLORS.primary;
  const focusStyle = setFocusColor
    ? {
        '&:focus': {
          color,
          '> *': { color },
        },
      }
    : {};

  const hoverStyle = {
    '&:hover': {
      color,
      '> *': { color },
    },
  };

  return {
    color,
    ...focusStyle,
    ...hoverStyle,
    a: {
      ...focusStyle,
      ...hoverStyle,
    },
  };
};

export const SURFACE_COLORS = Object.freeze({
  primary: 'var(--color--surface-primary)',
  secondary: 'var(--color--surface-secondary)',
  secondaryDarken: 'var(--color--surface-secondary-darken)',
  tertiary: 'var(--color--surface-tertiary)',
  tertiaryDarken: 'var(--color--surface-tertiary-darken)',
  primaryReverse: 'var(--color--surface-primary-reverse)',
  primaryAlwaysDark: 'var(--color--surface-primary-always-dark)',
  primaryReverseWithEmphasis:
    'var(--color--surface-primary-reverse-with-emphasis)',
  newStoryboardDefaultCover:
    'var(--color--surface-new-storyboard-default-cover)',
  quaternary: 'var( --color--surface-quaternary)',
  defaultStoryboardCard: 'var(--color--ngl-card)',
  defaultStandalonePostPrimary: 'var(--color--surface-standalone-post-primary)',
  defaultStandalonePostSecondary:
    'var(--color--surface-standalone-post-secondary)',
  standalonePostCommentPreviewBubble:
    'var(--color--surface-standalone-post-comment-preview-bubble-base)',
  flipImageCommentPreviewBubble:
    'var(--color--surface-flip-image-comment-preview-bubble-base)',
});

export const DIVIDER_COLORS = Object.freeze({
  primary: 'var(--color--divider-primary)',
  secondary: 'var(--color--divider-secondary)',
  tertiary: 'var(--color--divider-tertiary)',
  newStoryboard: 'var(--color--divider-new-storyboard)',
  emphasis: 'var(--color--divider-emphasis)',
});

export const ICON_COLORS = Object.freeze({
  base: 'var(--color--icon)',
  reverse: 'var(--color--icon-reverse)',
  emphasis: 'var(--color--icon-emphasis)',
  emphasisDark: 'var(--color--icon-emphasis-dark)',
  emphasisBackground: 'var(--color--icon-emphasis-background)',
  overlay: 'var(--color--icon-overlay)',
  caret: 'var(--color--icon-caret)',
});
