import {
  FlapItemCommentaryType,
  FlapItemType,
  FlapLinkType,
  FlapSectionLinkType,
} from 'Webapp/enums';

export const isFlapCommentaryLike = (
  i: Flipboard.FlapCommentaryItem,
): i is Flipboard.FlapCommentaryLike => i.type === FlapItemCommentaryType.LIKE;

export const isSectionLinkAuthor = (
  i: Flipboard.FlapSectionLink,
): i is Flipboard.FlapAuthorSectionLink =>
  i.type === FlapSectionLinkType.AUTHOR;

export const isSectionLinkProfileMention = (
  i: Flipboard.FlapSectionLink,
): i is Flipboard.FlapProfileSectionLink =>
  i.type === FlapSectionLinkType.TEXT_LINK &&
  i.referringTextLoc &&
  (i.linkType === FlapLinkType.USER || i.feedType === FlapLinkType.USER);

export const isSectionLinkMagazine = (
  i: Flipboard.FlapSectionLink,
): i is Flipboard.FlapMagazineSectionLink =>
  i.type === FlapSectionLinkType.MAGAZINE;

export const isFlapNotificationItem = (
  i: Flipboard.FlapItem,
): i is Flipboard.FlapNotificationItem => i.type === FlapItemType.NOTIFICATION;

export const isProfileSection = (
  section?: Flipboard.Section,
): section is Flipboard.ProfileSection => {
  if (section) {
    return 'mastodonProfile' in section || 'apEnabled' in section;
  }
  return false;
};
