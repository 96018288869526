import { Connector } from 'Utils/connector';
import {
  isModalShown,
  showModal,
  dismissModal,
  dismissSpecificModal,
  dismissAllModals,
  setModalLoginComplete,
} from 'Webapp/shared/app/redux/actions/modal-actions';

const selectors = {
  googleAdVisibility: ({ app }: Flipboard.State) => (app?.isAmp ? 1 : 0),
  currentModals: ({ modal }: Flipboard.State) => modal.current,
  modalLoginComplete: ({ modal: { modalLoginComplete } }) => modalLoginComplete,
};

const actions = {
  isModalShown,
  dismissModal,
  dismissSpecificModal,
  dismissAllModals,
  setModalLoginComplete,
};

export type ConnectModalProps = Flipboard.ConnectorProps<
  typeof selectors,
  typeof actions
> & {
  showModal: typeof showModal;
};

const connectModal: Connector = {
  selectors,
  actions: {
    ...actions,
    showModal,
  },
};
export default connectModal;
