import { Breadcrumbs } from 'Utils/content/structured-data';
import { euc } from 'Utils/url';
import { getProfilePath, getArticlePath } from 'Utils/content/flipboard-urls';

const videoRoot = 'https://flipboard.com/section/thanks%2FeditorsPicks';

export default ({ section, item }) => {
  if (!item || !item.isFirstPartyVideo) {
    return null;
  }
  const breadcrumbs = new Breadcrumbs();

  // TODO: Determine if we should omit this when we have a proper section
  // instead of the pseudo-section "Editor's Picks"
  if (section) {
    if (section.isTopic) {
      breadcrumbs.add(`#${section.title}`, section.canonicalPath);
    } else if (section.isMagazine) {
      if (section.authorUsername) {
        breadcrumbs.add(
          `@${euc(section.authorUsername)}`,
          getProfilePath(section),
        );
      }
      breadcrumbs.add(section.title, section.canonicalPath);
    } else if (section.isProfile) {
      breadcrumbs.add(`@${euc(section.authorUsername)}`, section.canonicalPath);
    } else {
      // The section is not a standard "topic," "magazine," "profile," etc.
      breadcrumbs.add('video', videoRoot);

      if (section.authorUsername) {
        breadcrumbs.add(
          `@${euc(section.authorUsername)}`,
          getProfilePath(section),
        );
      }
    }
  }

  breadcrumbs.add(item.sanitizedTitle, getArticlePath(item, section));
  return breadcrumbs;
};
