import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getDimensionsFromRatio } from 'Utils/image-util';
import { AppTheme } from 'Webapp/enums';

// Components
import AmpLogo from './amp';
import withT from 'ComponentLibrary/hocs/withT';

// Aspect ratio
const RATIO = 508 / 100;

function renderLogo(w, h, toolTip, className, t, appTheme) {
  const { width, height } = getDimensionsFromRatio(RATIO, w, h);
  const fontColor = appTheme === AppTheme.DARK ? '#fff' : '#000';
  return (
    <svg
      key="logo"
      role="img"
      aria-labelledby="logo-landscape-title logo-landscape-desc"
      className={`logo--landscape ${className}`}
      width={width}
      height={height}
      viewBox="0 0 508 100"
    >
      <title id="logo-landscape-title">{toolTip}</title>
      <desc id="logo-landscape-desc">
        {t('flipboard_logo_landscape_description')}
      </desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group">
          <rect
            id="Rectangle"
            fill="#FFFFFF"
            x="3"
            y="4"
            width="93"
            height="93"
          />
          <g id="Horizontal-Logo_DIGITAL_RED_100px" fillRule="nonzero">
            <polygon
              id="Shape"
              fill={fontColor}
              points="129.95 20 164.78 20 164.78 31.56 143.72 31.56 143.72 45.31 161.18 45.31 161.18 56.21 143.71 56.21 143.71 80 129.94 80"
            />
            <polygon
              id="Shape"
              fill={fontColor}
              points="169.63 20 183.4 20 183.4 68.44 203.07 68.44 203.07 80 169.63 80"
            />
            <polygon
              id="Shape"
              fill={fontColor}
              points="208.24 20 222 20 222 80 208.24 80"
            />
            <path
              d="M228.83,20 L246,20 C260.83,20 269.11,27.7 269.11,40.41 C269.11,53.52 260.83,61.56 246,61.56 L242.6,61.56 L242.6,80 L228.83,80 L228.83,20 Z M242.6,30.9 L242.6,50.57 L245.3,50.57 C251.94,50.57 255.3,47.29 255.3,40.65 C255.3,34.26 251.94,30.9 245.3,30.9 L242.6,30.9 Z"
              id="Shape"
              fill={fontColor}
            />
            <path
              d="M273.92,20 L293.67,20 C307.2,20 313.75,25.82 313.75,35.65 C313.75,41.31 310.97,46.39 304,48.11 C311.53,49.43 315.53,55.24 315.53,62.21 C315.53,72.46 308.32,80 294.8,80 L273.9,80 L273.92,20 Z M287.69,30.9 L287.69,43.9 L291.53,43.9 C297.27,43.9 299.89,41.44 299.89,37.1 C299.89,33.25 297.43,30.87 291.78,30.87 L287.69,30.9 Z M287.69,54.26 L287.69,69.11 L292.85,69.11 C298.85,69.11 301.78,66.32 301.78,61.48 C301.78,56.81 298.51,54.27 292.93,54.27 L287.69,54.26 Z"
              id="Shape"
              fill={fontColor}
            />
            <path
              d="M342.29,19 C356.71,19 365,29.59 365,50 C365,70.41 356.72,81 342.29,81 C327.86,81 319.59,70.43 319.59,50 C319.59,29.57 327.86,19 342.29,19 Z M342.29,69.17 C347.78,69.17 351.22,63.51 351.22,49.99 C351.22,36.47 347.78,30.81 342.29,30.81 C336.8,30.81 333.43,36.46 333.43,49.99 C333.43,63.52 336.79,69.18 342.29,69.18 L342.29,69.17 Z"
              id="Shape"
              fill={fontColor}
            />
            <path
              d="M380.38,20 L397.84,20 L412.92,80 L398.53,80 L396,68 L381.37,68 L378.74,80 L364.89,80 L380.38,20 Z M388.66,33 L383.66,56.94 L393.91,56.94 L389,33 L388.66,33 Z"
              id="Shape"
              fill={fontColor}
            />
            <path
              d="M416.46,20 L433.67,20 C448.67,20 456.67,26.31 456.67,39 C456.67,47.2 452.98,53.27 446.1,56.38 L458.72,80 L443.83,80 L433.59,58.68 L430.23,58.68 L430.23,80 L416.46,80 L416.46,20 Z M430.23,30.9 L430.23,48 L433.67,48 C439.98,48 442.85,45 442.85,39.15 C442.85,33.57 439.98,30.87 433.67,30.87 L430.23,30.9 Z"
              id="Shape"
              fill={fontColor}
            />
            <path
              d="M463.84,20 L481.14,20 C498.93,20 507.2,32.54 507.2,50 C507.2,67.21 498.92,80 481.14,80 L463.84,80 L463.84,20 Z M477.61,31.56 L477.61,68.44 L480.89,68.44 C489.89,68.44 493.1,59.83 493.1,50 C493.1,39.59 489.91,31.56 480.89,31.56 L477.61,31.56 Z"
              id="Shape"
              fill={fontColor}
            />
            <path
              d="M0,0 L0,100 L100,100 L100,0 L0,0 Z M80,40 L60,40 L60,60 L40,60 L40,80 L20,80 L20,20 L80,20 L80,40 Z"
              id="Shape"
              fill="#F52828"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

function FlipboardLandscape({
  width,
  height,
  showAmpBadge,
  toolTip,
  className,
  t,
  appTheme,
}) {
  if (showAmpBadge) {
    return [
      renderLogo(width, height, toolTip, className, t, appTheme),
      <AmpLogo key="amplogo" size={20} />,
    ];
  }
  return renderLogo(width, height, toolTip, className, t, appTheme);
}

FlipboardLandscape.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  showAmpBadge: PropTypes.bool,
  className: PropTypes.string,
  toolTip: PropTypes.string,
  t: PropTypes.func.isRequired,
  appTheme: PropTypes.oneOf(Object.values(AppTheme)),
};

FlipboardLandscape.defaultProps = {
  width: null,
  height: null,
  showAmpBadge: false,
  className: '',
  toolTip: 'Flipboard',
  appTheme: AppTheme.DEFAULT,
};

export default withT(FlipboardLandscape);
