import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

// Utils
import sentry from 'Utils/sentry';
import HocUtil from 'Utils/hoc-util';
import { EMAIL_REGEXP } from 'Utils/reg-exp';
import { UnhandledFrictionlessFlowError } from 'Utils/errors';
// Actions
import { frictionlessSignup } from 'Webapp/shared/app/redux/actions/auth-actions';
import { setTriggerInviteOnboarding } from 'Webapp/shared/app/redux/actions/app';
import { usageTrackSignupEnter } from 'Webapp/shared/app/redux/actions/usage-actions';
import {
  FrictionlessFlowType,
  SocialActionType,
  OnboardingFlow,
} from 'Webapp/enums';

import {
  getFrictionlessFlowUsageType,
  onboardingPendingSelector,
} from 'Webapp/shared/concepts/onboarding';

// Components

import withT from 'ComponentLibrary/hocs/withT';
import withToast from 'Webapp/shared/app/hocs/withToast';
import withHistory from 'Webapp/shared/app/hocs/withHistory';

import connector from 'Utils/connector';
import connectModal from 'Webapp/shared/app/connectors/connectModal';
import connectEmailSettings from 'Webapp/shared/app/connectors/connectEmailSettings';
import connectContextualOnboarding from 'Webapp/shared/app/connectors/connectContextualOnboarding';
import connectRouting from 'Webapp/shared/app/connectors/connectRouting';
import connectOnboardingFlow from 'Webapp/shared/app/connectors/connectOnboardingFlow';

function withFrictionlessSignup(Component) {
  class WrappedComponent extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        email: '',
        pendingSubscriptionGroups: this.props.emailGroupsLoaded
          ? this.props.emailGroups
          : null,
        frictionlessEnterEventFired: false,
        emailInputError: null,
      };
    }

    frictionlessEnter = (frictionlessFlowType = null) => {
      const { usageTrackSignupEnter, socialGateActionType } = this.props;

      const appEnterType = getFrictionlessFlowUsageType(
        frictionlessFlowType,
        socialGateActionType,
      );

      const { frictionlessEnterEventFired } = this.state;
      if (!frictionlessEnterEventFired) {
        usageTrackSignupEnter(appEnterType);
        this.setState({ frictionlessEnterEventFired: true });
      }
    };

    handleEmailChange = (e) => {
      this.setState({ email: e.target.value });
    };

    handlePendingSubscriptionGroupsChange = (
      updatedPendingSubscriptionGroups,
    ) => {
      this.setState({
        pendingSubscriptionGroups: updatedPendingSubscriptionGroups,
      });
    };

    isFormValid = () => EMAIL_REGEXP.test(this.state.email);

    setCustomError = (e, msg) => {
      e.preventDefault();
      this.setState({ emailInputError: msg });
    };

    handleSubmit = async (e, frictionlessFlowType) => {
      e?.preventDefault();

      const { startOnboardingFlow } = this.props;

      switch (frictionlessFlowType) {
        case FrictionlessFlowType.NEWSLETTER:
        case FrictionlessFlowType.NEWSLETTERS:
        case FrictionlessFlowType.TOPIC: {
          startOnboardingFlow(OnboardingFlow.SIGNUP_NEWSLETTER, {
            email: this.state.email,
            pendingSubscriptionGroups: this.state.pendingSubscriptionGroups,
            frictionlessFlowType,
          });
          break;
        }
        default:
          sentry.captureException(
            new UnhandledFrictionlessFlowError(frictionlessFlowType),
          );
      }
    };

    render() {
      return (
        <Component
          {...this.props}
          email={this.state.email}
          pendingSubscriptionGroups={this.state.pendingSubscriptionGroups}
          onEmailChange={this.handleEmailChange}
          onPendingSubscriptionGroupsChange={
            this.handlePendingSubscriptionGroupsChange
          }
          onSubmit={this.handleSubmit}
          isSubmittable={!this.props.onboardPending && this.isFormValid()}
          frictionlessEnter={this.frictionlessEnter}
          onboardPending={this.props.onboardPending}
        />
      );
    }
  }

  // Proxy the wrapped component displayName
  WrappedComponent.displayName = HocUtil.displayName(Component);

  WrappedComponent.propTypes = {
    frictionlessSignup: PropTypes.func.isRequired,
    setTriggerInviteOnboarding: PropTypes.func.isRequired,
    subscribeToPendingSubscriptionGroups: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    toastShowErrorAction: PropTypes.func.isRequired,
    emailGroupsLoaded: PropTypes.bool.isRequired,
    emailGroups: PropTypes.array,
    usageTrackSignupEnter: PropTypes.func.isRequired,
    socialGateActionType: PropTypes.oneOf(Object.values(SocialActionType)),
    routing: PropTypes.object,
    history: PropTypes.object,
    startOnboardingFlow: PropTypes.func,
    onboardPending: PropTypes.bool,
  };

  WrappedComponent.defaultProps = {
    navFrom: null,
  };

  const mapStateToProps = (state) => {
    const {
      profile: { followingIds, followingIdsLoaded },
    } = state;
    const onboardPending = onboardingPendingSelector(state);
    return {
      followingIds,
      followingIdsLoaded,
      onboardPending,
    };
  };

  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        usageTrackSignupEnter,
        frictionlessSignup,
        setTriggerInviteOnboarding,
      },
      dispatch,
    );

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    connector(
      connectEmailSettings,
      connectModal,
      connectContextualOnboarding,
      connectRouting,
      connectOnboardingFlow,
    )(withHistory(withToast(withT(WrappedComponent)))),
  );
}

export default withFrictionlessSignup;
