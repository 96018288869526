import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
// Utils
import StoryboardRenderingUtil from 'Utils/content/storyboard-rendering-util';

// Libs
import { imageUrl, largestUrl } from 'Utils/image-util';
import videoAdOptionsForItem from 'Utils/video-ad-options-for-item';
import videoTrackerOptionsForItem from 'Utils/video-tracker-options-for-item';

// Components
import Image from '../base/image';
import ItemOverlay from '../item/card/item-overlay';

import connector from 'Utils/connector';
import connectAmp from 'Webapp/shared/app/connectors/connectAmp';
import HTMLVideo from './html-video';
import ItemLink from '../item/item-link';

const { sizes } = StoryboardRenderingUtil;

const StyledImageFrame = styled.div({
  borderRadius: 'inherit',
});

const StyledImageLink = styled(ItemLink)({
  borderRadius: 'inherit',
});

const StyledImage = styled(Image)({
  borderRadius: 'inherit',
});
class Video extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playingVideo: false,
    };

    this.handlePlayInline = this.handlePlayInline.bind(this);
  }

  handlePlayInline() {
    this.setState({
      playingVideo: true,
    });
  }

  render() {
    const {
      item,
      ampWidth,
      ampHeight,
      ampLayout,
      useCrop,
      windowHeight,
      isAmp,
      fallbackElement,
      shownInList,
      section,
      link,
      previewSize,
      showWideVideos,
    } = this.props;
    const { image, isFirstPartyVideo } = item;
    const videoItem = item.inlineVideoItem || item;

    const { videoID, videoSourceURL } = videoItem;
    const imageURL = imageUrl(image);

    // AMP rendering
    if (isAmp) {
      if (videoID) {
        const ampVideoProps = {
          layout: ampLayout,
          width: ampWidth,
          height: ampHeight,
          'data-videoid': videoID,
        };
        return (
          <div className="video-container">
            {videoItem.isYoutube && <amp-youtube {...ampVideoProps} />}
            {videoItem.isVimeo && <amp-vimeo {...ampVideoProps} />}
            {!videoItem.isYoutube &&
              !videoItem.isVimeo &&
              videoItem.isHtml5 &&
              videoSourceURL && (
                <amp-video
                  // NOTE: Setting prop controls as true below renders as `controls="true"`
                  // in Amp, which is considered as invalid. Setting it to undefined renders
                  // it as <amp-video controls /> which is the desired format.
                  // More info here: https://github.com/facebook/react/issues/9230
                  controls=""
                  width={ampWidth}
                  height={ampHeight}
                  layout={ampLayout}
                  poster={imageURL}
                >
                  <source key={videoSourceURL} src={videoSourceURL} />
                </amp-video>
              )}
          </div>
        );
      }
      return (
        <Image
          image={image}
          alt={item.title}
          ampLayout={ampLayout}
          ampWidth={ampWidth}
          ampHeight={ampHeight}
        />
      );
    }

    if (isFirstPartyVideo && !shownInList) {
      return (
        <HTMLVideo
          autoPlay
          track
          prerollAds={item.adPreRollOk}
          className="media-container"
          videoAdOptions={videoAdOptionsForItem(item, section)}
          videoTrackerOptions={videoTrackerOptionsForItem(item, section)}
          poster={largestUrl(item.posterImage)}
          source={item.videoSiteURL}
          mimeType={item.mimeType}
        />
      );
    }

    if (this.state.playingVideo) {
      if (videoItem.isYoutube) {
        return (
          <div className="media-container responsive-iframe">
            <iframe
              src={`https://www.youtube.com/embed/${videoID}?autoplay=1`}
              frameBorder="0"
              allowFullScreen
            />
          </div>
        );
      }
      if (videoItem.isVimeo) {
        return (
          <div className="media-container responsive-iframe">
            <iframe
              src={`https://player.vimeo.com/video/${videoID}?autoplay=1&portrait=0`}
              frameBorder="0"
              webkitallowfullscreen="webkitallowfullscreen"
              mozallowfullscreen="mozallowfullscreen"
              allowFullScreen
            />
          </div>
        );
      }
    }

    // Static image
    const imageElement = (
      <StyledImage
        image={image}
        ampHeight={ampHeight}
        alt={item.title}
        windowHeight={windowHeight}
        fallbackElement={fallbackElement}
        crop={useCrop}
      />
    );

    if (!videoID && (videoItem.isYoutube || videoItem.isVimeo)) {
      return imageElement;
    }

    const showDuration = previewSize === sizes.SMALL ? showWideVideos : true;
    const playIconSize = previewSize === sizes.SMALL ? 32 : 48;
    return link ? (
      <StyledImageFrame className="image-frame">
        <StyledImageLink
          item={videoItem}
          section={section}
          shownInList={shownInList}
        >
          {imageElement}
          <ItemOverlay
            size={playIconSize}
            border
            item={videoItem}
            showDuration={showDuration}
            shownInList={shownInList}
          />
        </StyledImageLink>
      </StyledImageFrame>
    ) : (
      <StyledImageFrame className="image-frame" onClick={this.handlePlayInline}>
        {imageElement}
        <ItemOverlay
          size={playIconSize}
          border
          item={videoItem}
          showDuration={showDuration}
          shownInList={shownInList}
        />
      </StyledImageFrame>
    );
  }
}

Video.propTypes = {
  item: PropTypes.object.isRequired,
  section: PropTypes.object,
  ampLayout: PropTypes.string,
  ampWidth: PropTypes.number,
  ampHeight: PropTypes.number,
  useCrop: PropTypes.bool,
  isAmp: PropTypes.bool,
  windowHeight: PropTypes.number,
  fallbackElement: PropTypes.element,
  shownInList: PropTypes.bool.isRequired,
  link: PropTypes.bool,
  previewSize: PropTypes.oneOf(Object.values(sizes)),
  showWideVideos: PropTypes.bool,
};

Video.defaultProps = {
  ampLayout: 'responsive',
  ampWidth: 480,
  ampHeight: 270,
  useCrop: true,
  isAmp: false,
  windowHeight: null,
  fallbackElement: null,
  previewSize: null,
  showWideVideos: false,
};

export default connector(connectAmp)(Video);
