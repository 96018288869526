import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { UI_TEXT_TYPES } from 'Style/typography';

import getWindow from 'Utils/get-window';
import sentry from 'Utils/sentry';

import withT from 'ComponentLibrary/hocs/withT';

const ReCaptchaError = styled.span(UI_TEXT_TYPES.ERROR, {
  fontSize: '12px',
  marginBottom: SPACING.BASE,
});

class Recaptcha extends Component {
  constructor(props) {
    super(props);
    this.state = {
      failed: false,
      widget: null,
    };

    this.isRecaptchaLoaded = this.isRecaptchaLoaded.bind(this);
    this.renderGrecaptcha = this.renderGrecaptcha.bind(this);
    this.callback = this.callback.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidMount() {
    const { isRecaptchaOn } = this.props;
    if (isRecaptchaOn) {
      this.renderGrecaptcha();
    }
  }

  componentDidUpdate(prevProps) {
    const { isRecaptchaOn, failedV3Validation } = this.props;
    if (isRecaptchaOn && !prevProps.isRecaptchaOn) {
      this.renderGrecaptcha();
    }

    if (failedV3Validation && !prevProps.failedV3Validation) {
      this.reset();
    }
  }

  isRecaptchaLoaded() {
    const window = getWindow();
    return (
      typeof window !== 'undefined' &&
      typeof window.grecaptcha !== 'undefined' &&
      typeof window.grecaptcha.render === 'function'
    );
  }

  reset() {
    getWindow().grecaptcha.reset(this.state.widget);
  }

  renderGrecaptcha() {
    const { elementID, size, sitekey } = this.props;
    if (this.isRecaptchaLoaded()) {
      this.setState({
        widget: getWindow().grecaptcha.render(elementID, {
          sitekey,
          size,
          callback: this.callback,
        }),
      });
    }
  }

  async callback(token) {
    try {
      if (this.props.verifyCallback) {
        await this.props.verifyCallback(token);
        this.setState({ failed: false });
      }
    } catch (e) {
      this.reset();
      this.setState({ failed: true });
      sentry.captureException(e);
    }
  }

  render() {
    const { elementID, className, sitekey, size, themeColor, t } = this.props;

    return (
      <React.Fragment>
        {this.state.failed && (
          <ReCaptchaError>{t('recaptcha_validation_error')}</ReCaptchaError>
        )}
        <br />
        <div
          id={elementID}
          className={className}
          data-sitekey={sitekey}
          data-size={size}
          data-theme={themeColor}
        />
      </React.Fragment>
    );
  }
}

Recaptcha.propTypes = {
  sitekey: PropTypes.string.isRequired,
  elementID: PropTypes.string.isRequired,
  className: PropTypes.string,
  verifyCallback: PropTypes.func.isRequired,
  size: PropTypes.string.isRequired,
  isRecaptchaOn: PropTypes.bool.isRequired,
  failedV3Validation: PropTypes.bool.isRequired,
  themeColor: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

Recaptcha.defaultProps = {
  size: 'normal',
};

export default withT(Recaptcha);
