import { factory as flaxiosFactory } from 'Utils/flaxios';
import ApiHelpers from 'Utils/api//helpers';
import Config from 'Config';
const SERVICE_NAME = 'PUBLISHER';

/**
 * Axios instance to make Flap requests from web-u server to Flap
 */
const Publisher = flaxiosFactory({
  baseURL: Config.PUBLISHER_SERVICE_URL,
  timeout: 30000,
});

/**
 * Response interceptor for Publisher requests
 */
Publisher.interceptors.response.use(
  (response) => ApiHelpers.interceptResponseHandler(response, SERVICE_NAME),
  (error) => ApiHelpers.interceptResponseErrorHandler(error, SERVICE_NAME),
);

export default Publisher;
