import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ICON_COLORS } from 'Style/colors';
import Icon from './icon';
import withT from 'ComponentLibrary/hocs/withT';

export const StyledFollowingIcon = styled(Icon)((props) => ({
  color: props.color || ICON_COLORS.emphasis,
  fill: 'currentColor',
}));

function FollowingIcon({ size, ...props }) {
  return (
    <StyledFollowingIcon
      name="following"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      {...props}
      renderComponent={() => (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.83855 2H6.16146C5.63433 1.99998 5.17954 1.99997 4.80497 2.03057C4.40963 2.06287 4.01641 2.13419 3.63803 2.32698C3.07354 2.6146 2.6146 3.07354 2.32698 3.63803C2.13419 4.01641 2.06287 4.40963 2.03057 4.80497C1.99997 5.17954 1.99998 5.63429 2 6.16142V6.83855C1.99998 7.36568 1.99997 7.82047 2.03057 8.19503C2.06287 8.59038 2.13419 8.98359 2.32698 9.36197C2.6146 9.92646 3.07354 10.3854 3.63803 10.673C4.01641 10.8658 4.40963 10.9371 4.80497 10.9694C5.17955 11 5.63432 11 6.16148 11H6.83852C7.36568 11 7.82045 11 8.19503 10.9694C8.59038 10.9371 8.98359 10.8658 9.36197 10.673C9.92646 10.3854 10.3854 9.92646 10.673 9.36197C10.8658 8.98359 10.9371 8.59038 10.9694 8.19503C11 7.82045 11 7.36568 11 6.83852V6.16148C11 5.63432 11 5.17955 10.9694 4.80497C10.9371 4.40963 10.8658 4.01641 10.673 3.63803C10.3854 3.07354 9.92646 2.6146 9.36197 2.32698C8.98359 2.13419 8.59038 2.06287 8.19503 2.03057C7.82047 1.99997 7.36568 1.99998 6.83855 2ZM4.54601 4.109C4.59546 4.0838 4.69618 4.04613 4.96784 4.02393C5.25118 4.00078 5.62345 4 6.2 4H6.8C7.37656 4 7.74883 4.00078 8.03217 4.02393C8.30383 4.04613 8.40455 4.0838 8.45399 4.109C8.64216 4.20487 8.79514 4.35785 8.89101 4.54601C8.9162 4.59546 8.95388 4.69618 8.97608 4.96784C8.99923 5.25118 9 5.62345 9 6.2V6.8C9 7.37656 8.99923 7.74883 8.97608 8.03217C8.95388 8.30383 8.9162 8.40455 8.89101 8.45399C8.79514 8.64216 8.64216 8.79514 8.45399 8.89101C8.40455 8.9162 8.30383 8.95388 8.03217 8.97608C7.74883 8.99923 7.37656 9 6.8 9H6.2C5.62345 9 5.25118 8.99923 4.96784 8.97608C4.69618 8.95388 4.59546 8.9162 4.54601 8.89101C4.35785 8.79514 4.20487 8.64216 4.109 8.45399C4.0838 8.40455 4.04613 8.30383 4.02393 8.03217C4.00078 7.74883 4 7.37656 4 6.8V6.2C4 5.62345 4.00078 5.25118 4.02393 4.96784C4.04613 4.69618 4.0838 4.59546 4.109 4.54601C4.20487 4.35785 4.35785 4.20487 4.54601 4.109Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.8385 2H17.1615C16.6343 1.99998 16.1795 1.99997 15.805 2.03057C15.4096 2.06287 15.0164 2.13419 14.638 2.32698C14.0735 2.6146 13.6146 3.07354 13.327 3.63803C13.1342 4.01641 13.0629 4.40963 13.0306 4.80497C13 5.17955 13 5.63431 13 6.16146V6.83855C13 7.36569 13 7.82046 13.0306 8.19503C13.0629 8.59038 13.1342 8.98359 13.327 9.36197C13.6146 9.92646 14.0735 10.3854 14.638 10.673C15.0164 10.8658 15.4096 10.9371 15.805 10.9694C16.1796 11 16.6343 11 17.1615 11H17.8385C18.3657 11 18.8205 11 19.195 10.9694C19.5904 10.9371 19.9836 10.8658 20.362 10.673C20.9265 10.3854 21.3854 9.92646 21.673 9.36197C21.8658 8.98359 21.9371 8.59038 21.9694 8.19503C22 7.82049 22 7.36578 22 6.83869V6.16148C22 5.63439 22 5.17951 21.9694 4.80497C21.9371 4.40963 21.8658 4.01641 21.673 3.63803C21.3854 3.07354 20.9265 2.6146 20.362 2.32698C19.9836 2.13419 19.5904 2.06287 19.195 2.03057C18.8205 1.99997 18.3657 1.99998 17.8385 2ZM15.546 4.109C15.5955 4.0838 15.6962 4.04613 15.9678 4.02393C16.2512 4.00078 16.6234 4 17.2 4H17.8C18.3766 4 18.7488 4.00078 19.0322 4.02393C19.3038 4.04613 19.4045 4.0838 19.454 4.109C19.6422 4.20487 19.7951 4.35785 19.891 4.54601C19.9162 4.59546 19.9539 4.69618 19.9761 4.96784C19.9992 5.25118 20 5.62345 20 6.2V6.8C20 7.37656 19.9992 7.74883 19.9761 8.03217C19.9539 8.30383 19.9162 8.40455 19.891 8.45399C19.7951 8.64216 19.6422 8.79514 19.454 8.89101C19.4045 8.9162 19.3038 8.95388 19.0322 8.97608C18.7488 8.99923 18.3766 9 17.8 9H17.2C16.6234 9 16.2512 8.99923 15.9678 8.97608C15.6962 8.95388 15.5955 8.9162 15.546 8.89101C15.3579 8.79514 15.2049 8.64216 15.109 8.45399C15.0838 8.40455 15.0461 8.30383 15.0239 8.03217C15.0008 7.74883 15 7.37656 15 6.8V6.2C15 5.62345 15.0008 5.25118 15.0239 4.96784C15.0461 4.69618 15.0838 4.59546 15.109 4.54601C15.2049 4.35785 15.3579 4.20487 15.546 4.109Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.1615 13H17.8385C18.3657 13 18.8205 13 19.195 13.0306C19.5904 13.0629 19.9836 13.1342 20.362 13.327C20.9265 13.6146 21.3854 14.0735 21.673 14.638C21.8658 15.0164 21.9371 15.4096 21.9694 15.805C22 16.1795 22 16.6342 22 17.1613V17.8385C22 18.3656 22 18.8205 21.9694 19.195C21.9371 19.5904 21.8658 19.9836 21.673 20.362C21.3854 20.9265 20.9265 21.3854 20.362 21.673C19.9836 21.8658 19.5904 21.9371 19.195 21.9694C18.8205 22 18.3658 22 17.8387 22H17.1615C16.6344 22 16.1795 22 15.805 21.9694C15.4096 21.9371 15.0164 21.8658 14.638 21.673C14.0735 21.3854 13.6146 20.9265 13.327 20.362C13.1342 19.9836 13.0629 19.5904 13.0306 19.195C13 18.8205 13 18.3657 13 17.8385V17.1615C13 16.6343 13 16.1795 13.0306 15.805C13.0629 15.4096 13.1342 15.0164 13.327 14.638C13.6146 14.0735 14.0735 13.6146 14.638 13.327C15.0164 13.1342 15.4096 13.0629 15.805 13.0306C16.1795 13 16.6343 13 17.1615 13ZM15.9678 15.0239C15.6962 15.0461 15.5955 15.0838 15.546 15.109C15.3579 15.2049 15.2049 15.3579 15.109 15.546C15.0838 15.5955 15.0461 15.6962 15.0239 15.9678C15.0008 16.2512 15 16.6234 15 17.2V17.8C15 18.3766 15.0008 18.7488 15.0239 19.0322C15.0461 19.3038 15.0838 19.4045 15.109 19.454C15.2049 19.6422 15.3579 19.7951 15.546 19.891C15.5955 19.9162 15.6962 19.9539 15.9678 19.9761C16.2512 19.9992 16.6234 20 17.2 20H17.8C18.3766 20 18.7488 19.9992 19.0322 19.9761C19.3038 19.9539 19.4045 19.9162 19.454 19.891C19.6422 19.7951 19.7951 19.6422 19.891 19.454C19.9162 19.4045 19.9539 19.3038 19.9761 19.0322C19.9992 18.7488 20 18.3766 20 17.8V17.2C20 16.6234 19.9992 16.2512 19.9761 15.9678C19.9539 15.6962 19.9162 15.5955 19.891 15.546C19.7951 15.3579 19.6422 15.2049 19.454 15.109C19.4045 15.0838 19.3038 15.0461 19.0322 15.0239C18.7488 15.0008 18.3766 15 17.8 15H17.2C16.6234 15 16.2512 15.0008 15.9678 15.0239Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.83855 13H6.16146C5.63431 13 5.17955 13 4.80497 13.0306C4.40963 13.0629 4.01641 13.1342 3.63803 13.327C3.07354 13.6146 2.6146 14.0735 2.32698 14.638C2.13419 15.0164 2.06287 15.4096 2.03057 15.805C1.99997 16.1795 1.99998 16.6343 2 17.1614V17.8385C1.99998 18.3657 1.99997 18.8205 2.03057 19.195C2.06287 19.5904 2.13419 19.9836 2.32698 20.362C2.6146 20.9265 3.07354 21.3854 3.63803 21.673C4.01641 21.8658 4.40963 21.9371 4.80497 21.9694C5.17952 22 5.63425 22 6.16136 22H6.83852C7.36563 22 7.82048 22 8.19503 21.9694C8.59038 21.9371 8.98359 21.8658 9.36197 21.673C9.92646 21.3854 10.3854 20.9265 10.673 20.362C10.8658 19.9836 10.9371 19.5904 10.9694 19.195C11 18.8205 11 18.3657 11 17.8385V17.1615C11 16.6343 11 16.1796 10.9694 15.805C10.9371 15.4096 10.8658 15.0164 10.673 14.638C10.3854 14.0735 9.92646 13.6146 9.36197 13.327C8.98359 13.1342 8.59038 13.0629 8.19503 13.0306C7.82046 13 7.3657 13 6.83855 13ZM4.54601 15.109C4.59546 15.0838 4.69618 15.0461 4.96784 15.0239C5.25118 15.0008 5.62345 15 6.2 15H6.8C7.37656 15 7.74883 15.0008 8.03217 15.0239C8.30383 15.0461 8.40455 15.0838 8.45399 15.109C8.64216 15.2049 8.79514 15.3579 8.89101 15.546C8.9162 15.5955 8.95388 15.6962 8.97608 15.9678C8.99923 16.2512 9 16.6234 9 17.2V17.8C9 18.3766 8.99923 18.7488 8.97608 19.0322C8.95388 19.3038 8.9162 19.4045 8.89101 19.454C8.79514 19.6422 8.64216 19.7951 8.45399 19.891C8.40455 19.9162 8.30383 19.9539 8.03217 19.9761C7.74883 19.9992 7.37656 20 6.8 20H6.2C5.62345 20 5.25118 19.9992 4.96784 19.9761C4.69618 19.9539 4.59546 19.9162 4.54601 19.891C4.35785 19.7951 4.20487 19.6422 4.109 19.454C4.0838 19.4045 4.04613 19.3038 4.02393 19.0322C4.00078 18.7488 4 18.3766 4 17.8V17.2C4 16.6234 4.00078 16.2512 4.02393 15.9678C4.04613 15.6962 4.0838 15.5955 4.109 15.546C4.20487 15.3579 4.35785 15.2049 4.54601 15.109Z"
            fill="currentColor"
          />
        </>
      )}
    />
  );
}

FollowingIcon.propTypes = {
  size: PropTypes.number,
  t: PropTypes.func.isRequired,
};

FollowingIcon.defaultProps = {
  size: 24,
};

export default withT(FollowingIcon);
