import modalAcceptMagazineInvite from 'Webapp/shared/app/components/onboarding-flows/steps/modal-accept-magazine-invite';
import acceptMagazineInvite from 'Webapp/shared/app/components/onboarding-flows/steps/accept-magazine-invite';
import followMagazine from 'Webapp/shared/app/components/onboarding-flows/steps/follow-magazine';
import showSuccessToastMessageInviteMagazine from 'Webapp/shared/app/components/onboarding-flows/steps/show-success-toast-message-invite-magazine';
import trackAcceptContributorInvite from 'Webapp/shared/app/components/onboarding-flows/steps/track-accept-contributor-invite';
import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

const steps: Array<OnboardingStep> = [
  modalAcceptMagazineInvite,
  acceptMagazineInvite,
  trackAcceptContributorInvite,
  followMagazine,
  showSuccessToastMessageInviteMagazine,
];

export default steps;
