import trackPublisherSignupExit from 'Webapp/shared/app/components/onboarding-flows/steps/track-publisher-signup-exit';
import publisherConfirmEmailModal from 'Webapp/shared/app/components/onboarding-flows/steps/publisher-confirm-email-modal';

import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

const steps: Array<OnboardingStep> = [
  trackPublisherSignupExit,
  publisherConfirmEmailModal,
];

export default steps;
