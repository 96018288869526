import { ARTICLES_TYPES, SECTIONS_TYPES } from 'Webapp/action-types';
import {
  getRelatedArticlesSection,
  isArticle,
  getTopicsToLoad,
  isStandalonePost,
} from 'Utils/content/item-util';
import FlapUtil from 'Utils/content/flap-util';
import Attribution from 'Utils/content/attribution';
import { getSectionId } from 'Utils/content/flipboard-urls';
import SectionUtil from 'Utils/content/section-util';
import sentry from 'Utils/sentry';
import { RelatedSectionType } from 'Webapp/enums';

import { getSection, getStoryboardsSection } from './section-actions';

export const RELATED_STORYBOARDS_LIMIT = 8;
const AUTHOR_SECTION_ITEM_LIMIT = 6;
const RELATED_ARTICLES_LIMIT = 4;
const RELATED_TOPIC_ITEM_LIMIT = 6;
const RELATED_TV_LIMIT = 9;

/**
 *
 * @param {String} remoteId - The remoteid of the primary section
 * @param {Object} item - Item for which the related Section will be linked.
 * @param {String} relatedSectionType - Section Type Key for the related section
 * @param {String} sectionId - Section id of the related section
 * @param {Number} limit - number of items in the related section to fetch
 * @param {Object} optionalParams - Additional optional params to include. See below.
 * @returns
 */
export const loadRelatedSection =
  (remoteId, item, relatedSectionType, sectionId, limit, optionalParams = {}) =>
  async (dispatch) => {
    if (remoteId === sectionId) {
      return;
    }
    /**
     * @param {Boolean} optionalParams.extractItemsFromGroup - expect items to be in an item group and extract it
     * @param {Boolean} optionalParams.preferMagazineContext - use magazine context for section items instead of section
     * @param {Number} optionalParams.index - Optional include an index so we can preserve an order for related sections.
     */
    const params = Object.assign(
      {
        extractItemsFromGroup: false,
        preferMagazineContext: false,
        index:
          null /* Allow specifying index so we can preserve the order if needed */,
      },
      optionalParams,
    );

    await dispatch(
      getSection(sectionId, {
        limit,
        extractItemsFromGroup: params.extractItemsFromGroup,
        preferMagazineContext: params.preferMagazineContext,
        loadCommentary: true,
      }),
    );
    const actionType =
      item && (isArticle(item) || isStandalonePost(item))
        ? ARTICLES_TYPES.GET_ARTICLE_RELATED_SECTION_SUCCEEDED
        : SECTIONS_TYPES.GET_SECTION_RELATED_SECTION_SUCCEEDED;
    return await dispatch({
      type: actionType,
      payload: {
        remoteId,
        relatedSectionType,
        sectionId,
        index: params.index,
      },
    });
  };

export const loadRelatedArticlesSection =
  (remoteId, item) => (dispatch, _getState) => {
    // Fetch for related articles
    const relatedArticlesSection = getRelatedArticlesSection(item);

    // No seeMore section id found
    if (!relatedArticlesSection) {
      return null;
    }

    const relatedArticlesSectionId = SectionUtil.sectionIdForUpdateFeedRequest(
      relatedArticlesSection,
    );

    return dispatch(
      loadRelatedSection(
        remoteId,
        item,
        RelatedSectionType.ARTICLES,
        relatedArticlesSectionId,
        RELATED_ARTICLES_LIMIT,
        {
          extractItemsFromGroup: true,
          preferMagazineContext: true,
        },
      ),
    );
  };

export const getAuthorSection = (remoteId, item) => (dispatch) => {
  // Get the author section so we can render posts and magazines
  const author = Attribution.getAuthor(item);
  const authorSectionId = author && author.remoteid;

  if (!authorSectionId) {
    return Promise.resolve();
  }
  return dispatch(
    loadRelatedSection(
      remoteId,
      item,
      RelatedSectionType.AUTHOR,
      authorSectionId,
      AUTHOR_SECTION_ITEM_LIMIT,
    ),
  );
};

export const getRelatedStoryboards = (remoteId, item) => (dispatch) => {
  const relatedArticlesSection = item.isArticle
    ? getRelatedArticlesSection(item)
    : null;

  if (item.isArticle && !relatedArticlesSection) {
    return null;
  }

  const relatedStoryboardsSectionId = FlapUtil.getRelatedStoryboardsRemoteId(
    item,
    relatedArticlesSection,
  );
  if (!relatedStoryboardsSectionId) {
    return null;
  }

  return dispatch(
    loadRelatedSection(
      remoteId,
      item,
      RelatedSectionType.STORYBOARDS,
      relatedStoryboardsSectionId,
      RELATED_STORYBOARDS_LIMIT,
    ),
  );
};

export const getRelatedTopics =
  (remoteId, item, topics = null) =>
  (dispatch) => {
    const topicsToLoad = topics || getTopicsToLoad(item);

    const topicLoadPromises = [];
    if (topicsToLoad) {
      topicsToLoad.forEach((topic, index) => {
        if (topic && topic.remoteid) {
          topicLoadPromises.push(
            dispatch(
              loadRelatedSection(
                remoteId,
                item,
                RelatedSectionType.TOPICS,
                topic.remoteid,
                RELATED_TOPIC_ITEM_LIMIT,
                { index },
              ),
            ),
          );
        }
      });
    }
    return topicLoadPromises;
  };

export const geRelatedFlipboardTVSection = (remoteId, item) => (dispatch) =>
  dispatch(
    loadRelatedSection(
      remoteId,
      item,
      RelatedSectionType.FLIPBOARD_TV,
      'sid/3dh8cdp0z/flipboard_tv',
      RELATED_TV_LIMIT,
    ),
  );

export const getProfileMagazinesSection =
  (remoteId, item, author) => (dispatch) => {
    if (!author || !author.authorUsername) {
      return;
    }
    const authorSectionId = getSectionId({ username: author.authorUsername });

    return dispatch(
      loadRelatedSection(
        remoteId,
        item,
        RelatedSectionType.PROFILE_MAGAZINES,
        authorSectionId,
        0,
      ),
    );
  };

export const getRelatedProfileStoryboardsSection =
  (remoteId, item, path) => async (dispatch) => {
    await dispatch(getStoryboardsSection(item.authorUsername, path));
    const userStoryboardSectionId = FlapUtil.getUserStoryboardSectionId(
      item.userid,
    );

    return dispatch({
      type: SECTIONS_TYPES.GET_SECTION_RELATED_SECTION_SUCCEEDED,
      payload: {
        remoteId,
        relatedSectionType: RelatedSectionType.PROFILE_STORYBOARDS,
        sectionId: userStoryboardSectionId,
      },
    });
  };

export const getShortUrl =
  (item) =>
  async (_dispatch, _getState, { flap }) => {
    const { sourceURL: url, id: oid } = item;
    try {
      const { data } = await flap.post('/social/shortenURL', { url, oid });
      if (data) {
        return data.result;
      }
      return null;
    } catch (error) {
      sentry.captureMessage(`Error shortening URL`);
      sentry.captureException(error);
    }
  };

/**
 * Flags an item as inappropriate
 * @param {Object} item     - Flipboard item object
 * @param {Object} section  - Flipboard section object
 * @return {Promise}        - A promise that resolves as an object
 */
export const flagItem =
  (section, item) =>
  async (_dispatch, _getState, { flap }) => {
    const params = {
      oid: item && item.id,
      url: item && item.sourceURL,
      type: 'inappropriate',
      section: section.ssid?.remoteidPlain || section.remoteid,
    };
    try {
      const { data } = await flap.post('/social/flagItem', {}, { params });
      return data;
    } catch (error) {
      sentry.captureMessage(`Error flagging item`);
      sentry.captureException(error);
    }
  };
