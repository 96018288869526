import { useSelector } from 'react-redux';
import { ACCESSORY_SECTIONS_TYPES, SECTIONS_TYPES } from 'Webapp/action-types';
import { merger } from 'Utils/redux';
import FlapUtil from 'Utils/content/flap-util.js';

export enum ACCESSORY_SECTIONS {
  REFERRING_USER = 'REFERRING_USER',
  MAGAZINE_CONTRIBUTORS = 'MAGAZINE_CONTRIBUTORS',
  TOPIC_RECOMMENDED_MAGAZINES = 'TOPIC_RECOMMENDED_MAGAZINES',
  TOPIC_STORYBOARDS = 'TOPIC_STORYBOARDS',
}

type SectionAccessorySections = {
  [key in ACCESSORY_SECTIONS]: Array<Flipboard.Section>;
};

interface AccessorySectionReducerState {
  [sectionId: Flipboard.SectionId]: SectionAccessorySections;
}

const initialState: AccessorySectionReducerState = {};

export const reducer = (
  state: AccessorySectionReducerState = initialState,
  action: {
    type: keyof ACCESSORY_SECTIONS_TYPES;
    remoteId: Flipboard.SectionId;
    key?: ACCESSORY_SECTIONS;
    value?: Flipboard.Section;
  },
): AccessorySectionReducerState => {
  const merge = merger(state);
  switch (action.type) {
    case ACCESSORY_SECTIONS_TYPES.SET: {
      const { remoteId, key, value } = action;
      if (!key || !value) {
        return state;
      }
      const normalizedRemoteId = FlapUtil.normalizeRemoteid(
        remoteId,
      ) as Flipboard.SectionId;
      const accessorySections = state[normalizedRemoteId];
      return merge({
        [normalizedRemoteId]: {
          ...accessorySections,
          [key]: value,
        },
      });
    }
    case SECTIONS_TYPES.PURGE_SECTION: {
      const normalizedRemoteId = FlapUtil.normalizeRemoteid(
        action.remoteId,
      ) as Flipboard.SectionId;
      if (state[normalizedRemoteId]) {
        const clone = Object.assign({}, state);
        delete clone[normalizedRemoteId];
        return clone;
      }
      break;
    }
  }
  return state;
};

// helpers
const getSectionAccessorySections =
  (key: ACCESSORY_SECTIONS) =>
  (
    remoteId: Flipboard.SectionId,
    accessorySections: AccessorySectionReducerState,
  ) => {
    const sectionId = FlapUtil.normalizeRemoteid(
      remoteId,
    ) as Flipboard.SectionId;
    const sectionsForSectionId = accessorySections[
      sectionId
    ] as SectionAccessorySections;
    return sectionsForSectionId ? sectionsForSectionId[key] : [];
  };

export const getContributorAccessorySections = (
  remoteid: Flipboard.SectionId,
  accessorySections: AccessorySectionReducerState,
) =>
  getSectionAccessorySections(ACCESSORY_SECTIONS.MAGAZINE_CONTRIBUTORS)(
    accessorySectionMagazineId(remoteid),
    accessorySections,
  );

// remove y / z variation in remote id
// sid/5p05obmez/peterheld => sid/5p05obme/peterheld
// sid/5p05obmey/peterheld => sid/5p05obme/peterheld
// we want to store and retrieve in a way that works for either
export const accessorySectionMagazineId = (remoteid: Flipboard.SectionId) =>
  remoteid.replace(/sid\/(.+)[yz]\//, 'sid/$1/') as Flipboard.SectionId;

// actions
export const setAccessorySection =
  ({
    remoteId,
    key,
    value,
  }: {
    remoteId: Flipboard.SectionId;
    key: ACCESSORY_SECTIONS;
    value: Array<Flipboard.Section | Flipboard.BasicSection>;
  }): Flipboard.Thunk =>
  (dispatch) =>
    dispatch({ type: ACCESSORY_SECTIONS_TYPES.SET, remoteId, key, value });

// hook compatible selector
export const getAccessorySections = (
  key: ACCESSORY_SECTIONS,
  remoteId: Flipboard.SectionId,
) =>
  useSelector(
    ({ accessorySections }: Flipboard.State) =>
      accessorySections[
        FlapUtil.normalizeRemoteid(remoteId) as Flipboard.SectionId
      ]?.[key] || [],
  );
