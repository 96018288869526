import ObjectUtil from 'Utils/object-util';
import logger from 'Utils/logger';
import Flab from 'Utils/api/flab';
import sentry from 'Utils/sentry';
import GlobalVars from 'Utils/global-vars';
import { PromiseAll } from 'Utils/promise';

const { pick } = ObjectUtil;

export const factory = (
  // activeUidExperiments are for tracking logged-in user intractions only
  // because only logged-in sessions have uid values
  // REMINDER: Set this value as ACTIVE_UID_EXPERIMENTS in the config file for your app
  activeUidExperiments = [],
  // activeUniqueIdExperiments are for tracking logged-out users or
  // those who follow the signup flow (logged-out -> logged-in) because
  // only unique_id is available for the logged-out interactions
  // REMINDER: Set this value as ACTIVE_UNIQUE_ID_EXPERIMENTS in the config file for your app
  activeUniqueIdExperiments = [],
) => {
  const FLAB_PATH = '/get_all_cells';

  let clientExperimentRequestPromise = null;

  /**
   * Generates a string representation of the experiments object that can
   * be used to decorate usage and Google Analytics events
   * @param {Object} experiments     - A map of experiments from FLAB
   * @return {String}                - A comma-separated string containing the
   * experiments in the following format: "<experimentId>_<cellId>"
   */
  function experimentsForTracking(experiments) {
    const keys = experiments && Object.keys(experiments);
    return (
      (keys &&
        keys.length > 0 &&
        keys
          .sort()
          .map(
            (experimentId) =>
              `${experimentId}_${experiments[experimentId].group}`,
          )
          .join(',')) ||
      null
    );
  }

  /**
   * Requests the experiments associated with the uid of the logged-in user
   * (if present) and with the unique_id provided by the ue.flipboard.com/setcookie
   * endpoint
   * @param {String} uid        - ID (user_id/uid) for logged-in FLAB experiments
   * @param {String} uniqueId   - ID (unique_id) for logged-out FLAB experiments
   * @param {Object} overrides  - Object of experiment overrides from query params
   * @return {Object}           - The experiments object
   */
  function requestExperiments(
    uid = null,
    uniqueId = null,
    overrides = {},
    resetExperiments = false,
  ) {
    // Prevent repeated client experiment requests
    if (!GlobalVars.isServer() && clientExperimentRequestPromise) {
      if (resetExperiments) {
        clientExperimentRequestPromise = null;
      } else {
        return clientExperimentRequestPromise;
      }
    }

    const requestById = async function (id) {
      const params = { id };
      try {
        const response = await Flab.get(FLAB_PATH, { params });
        const experimentMap =
          response && response.data && response.data.experiment_map;
        if (!experimentMap) {
          const errorMessage = 'No experiments found in response.';
          const error = new Error(errorMessage);
          sentry.captureMessage(errorMessage, {
            experimentsResponse: response,
          });
          throw error;
        }

        return experimentMap || {};
      } catch (error) {
        logger.error(`Error requesting experiments: ${error.message}`);
        // Report error to Sentry
        sentry.captureException(new Error('Request experiments failed'), {
          experimentsRequestPath: FLAB_PATH,
          experimentsRequestParmas: params,
        });
        return {};
      }
    };

    const experimentPromises = [];

    if (Number(uid)) {
      experimentPromises.push(
        requestById(uid).then((uidExperiments) => {
          // non-client experiments are always based on UID, so we
          // get them from the UID-based FLAB response
          const nonClientExperiments = Object.keys(uidExperiments).reduce(
            (collect, current) => {
              const experiment = uidExperiments[current];
              if (!experiment.client_test) {
                collect[current] = experiment;
              }
              return collect;
            },
            {},
          );
          const activeUidClientExperiments = pick(
            uidExperiments,
            activeUidExperiments.map(String),
          );
          return Promise.resolve(
            Object.assign({}, nonClientExperiments, activeUidClientExperiments),
          );
        }),
      );
    }

    if (uniqueId) {
      experimentPromises.push(
        requestById(uniqueId).then((uniqueIdExperiments) =>
          Promise.resolve(
            pick(uniqueIdExperiments, activeUniqueIdExperiments.map(String)),
          ),
        ),
      );
    }

    clientExperimentRequestPromise = PromiseAll(experimentPromises).then(
      (experimentResults) => {
        const merged = Object.assign({}, ...experimentResults, overrides);
        return Promise.resolve(merged);
      },
    );

    return clientExperimentRequestPromise;
  }

  /**
   * Requests the experiments associated with a UID as well
   * as the unique_id value found in request cookies.  Intended
   * to be used on the server.
   * @param {Object} cookies - The cookies object from the Express request
   * @param {*} overrides    - Object of experiment overrides from query params
   * @return {Object}        - The experiments object
   */

  function getServerSideExperiments(cookies, overrides) {
    const uid = getFlabUidFromCookies(cookies);
    const uniqueId = getFlabUniqueIdFromCookies(cookies);
    return requestExperiments(uid, uniqueId, overrides);
  }

  /**
   * Returns the group for an experiment
   * @param {Object} experiments      - A map of experiments from FLAB
   * @param {Number} id               - The ID of the experiment to query
   * @return {String}                 - The "group" for the experiment
   */
  function getExperimentGroup(experiments, id) {
    if (!experiments) {
      return null;
    }
    if (typeof experiments[id] === 'undefined') {
      return null;
    }
    const group = experiments[id] && experiments[id].group;
    return parseInt(group, 10);
  }

  /**
   * Returns an experiment override object given a request query object
   * @param {Object} query            - The query object from a request
   * @return {Object}                 - An experiment override object or null
   */
  function overridesFromQuery(query) {
    if (!query) {
      return null;
    }
    const { experimentId, experimentGroup } = query;
    if (!(experimentId && experimentGroup)) {
      return null;
    }
    const overrides = {};
    overrides[experimentId] = {
      group: experimentGroup,
    };
    return overrides;
  }

  /** Returns the unique_id to use for requesting FLAB experiments.
   * Looks for a "ue_session" cookie and returns the
   * "unique_id" property if present.  Returns null if not found.
   * @param {Object} cookies          - Cookies object
   * @return {String}                 - The "unique_id" for the session
   */
  function getFlabUniqueIdFromCookies(cookies) {
    if (!cookies) {
      return null;
    }

    try {
      const { ue_session } = cookies;
      if (ue_session) {
        const session = ue_session && JSON.parse(ue_session);
        return session.unique_id || null;
      }
    } catch (e) {
      // matches Unexpected end of JSON,
      // and Unexpected token x in JSON, etc
      if (e.message.match(/JSON/)) {
        sentry.captureMessage('Failure to parse ue_session cookie!', {
          ue_session: cookies.ue_session,
        });
      } else {
        sentry.captureException(e);
      }
    }

    return null;
  }

  /** Returns the user_id to use for requesting FLAB experiments.  If
   * a "userid" cookie is available, returns it  Returns null if not found.
   * @param {Object} cookies          - Cookies object
   * @return {String}                 - The "user_id" for the logged-in user
   */
  function getFlabUidFromCookies(cookies) {
    if (!cookies) {
      return null;
    }
    return cookies.userid || null;
  }

  return {
    getExperimentGroup,
    experimentsForTracking,
    overridesFromQuery,
    getOverridesParams,
    getFlabUniqueIdFromCookies,
    getFlabUidFromCookies,
    requestExperiments,
    getServerSideExperiments,
  };
};

export const getOverridesParams = (experiments = {}) => {
  const overrideExperimentId = experiments && Object.keys(experiments)[0];
  if (!overrideExperimentId) {
    return null;
  }
  return {
    experimentId: overrideExperimentId,
    experimentGroup:
      experiments[overrideExperimentId].group &&
      experiments[overrideExperimentId].group.toString(),
  };
};
