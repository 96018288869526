import { connect } from 'react-redux';
import { AnyAction, ActionCreator, bindActionCreators } from 'redux';

// connector is an abstraction around redux connect that let us be a
// little clearer about what we are trying to do.  Connector takes two
// types of prop mapping objects (selectors, actions, more details
// below) and then returns a function you can pass a component to to
// hook it all up, like the following:
//
// connector({
//   // each of the below is optional, but there should be at least one
//   selectors,
//   actions,
// })(MyComponent)
//
// you can also pass many "connectors" (sets of selectors / actions), like so:
//
// connector(
//  // where each arg is a "connector", i.e. contains selectors / actions / helpers
//  connectCurrentUser,
//  connectRouting,
//  connectMySpecialThing,
// )(MyComponent)
//
// SELECTORS
//
// selectors are simple, just receive state, return value
// expamples:
//
// selectors: {
//   uid: (state) => state.auth.uid,
//   pageNotFound: ({ notFound }) => notFound,
// }
//
// ACTIONS
//
// any valid redux action
//
// actions: {
//   getThing: ({ type: TYPES.GET_THING }),
//   getThingWithThingy: (thingy) => ({ type: TYPES.GET_THING, thingy }),
//   moreComplicated: (arg) => (dispatch, getState, { flap }) => {...},
// }

export interface Connector<Props = Record<string, unknown>> {
  selectors?: {
    [key in keyof Props]?: (state: Flipboard.State) => unknown;
  };
  actions?: {
    [key in keyof Props]?: ActionCreator<unknown> | AnyAction;
  };
}

const connector =
  <Props>(...connectors: Array<Connector<Props>>) =>
  (Component: React.JSXElementConstructor<Props>) => {
    const { selectors, actions } = connectors.reduce(
      (finalReducer, { selectors, actions }) => ({
        selectors: { ...finalReducer.selectors, ...selectors },
        actions: { ...finalReducer.actions, ...actions },
      }),
      { selectors: {} as Connector['selectors'], actions: {} },
    );
    const selectorKeys = selectors ? Object.keys(selectors) : [];
    return connect(
      (state: Flipboard.State) =>
        selectorKeys.reduce((acc, key) => {
          if (selectors) {
            const selector = selectors[key];
            if (selector) {
              Object.assign(acc, { [key]: selector(state) });
            }
          }
          return acc;
        }, {}),
      (dispatch) => bindActionCreators(actions, dispatch),
      undefined,
      { forwardRef: true },
    )(Component);
  };

export default connector;
