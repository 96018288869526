const isServer = (): boolean => {
  if (typeof process === undefined) {
    return false;
  }
  return !!process.env.IS_SERVER;
};

export default {
  isServer,
  // populated by webpack EnvironmentPlugin or from node
  environment: process.env.NODE_ENV || 'production',
  deployEnvironment: process.env.DEPLOY_ENV || 'production',
  // handy stuff
  isDevelopment: process.env.NODE_ENV === 'development',
  isDeployed: process.env.NODE_ENV === 'production',
  isTest: process.env.NODE_ENV === 'test',
  isBeta:
    process.env.NODE_ENV === 'production' && process.env.DEPLOY_ENV === 'beta',
  isStaging:
    process.env.NODE_ENV === 'production' &&
    process.env.DEPLOY_ENV === 'staging',
  isProduction:
    process.env.NODE_ENV === 'production' &&
    process.env.DEPLOY_ENV === 'production',
  isIntegration:
    process.env.DEPLOY_ENV !== 'production' && process.env.IS_INTEGRATION,
  isReplay: process.env.DEPLOY_ENV !== 'production' && process.env.REPLAY,
  isDeveloperDeploy:
    process.env.NODE_ENV === 'production' &&
    process.env.DEPLOY_ENV !== 'production' &&
    process.env.DEPLOY_ENV !== 'staging',
};
