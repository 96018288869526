import { Breadcrumbs } from 'Utils/content/structured-data';

export default ({ section }) => {
  if (!section || !(section.isTopic || section.isSmartMagazine)) {
    return null;
  }

  // TODO: revisit this for new topic page?
  // home /topic / science
  // home / #Science
  const breadcrumbs = new Breadcrumbs();
  // breadcrumbs.add(`#${section.title}`, section.sourceURL);
  breadcrumbs.add(section.title, section.sourceURL);
  return breadcrumbs;
};
