import modalSignup from 'Webapp/shared/app/components/onboarding-flows/steps/modal-signup';

import acceptMagazineInvite from 'Webapp/shared/app/components/onboarding-flows/steps/accept-magazine-invite';
import followMagazine from 'Webapp/shared/app/components/onboarding-flows/steps/follow-magazine';

import showSuccessToastMessageInviteMagazine from 'Webapp/shared/app/components/onboarding-flows/steps/show-success-toast-message-invite-magazine';
import trackAcceptContributorInvite from 'Webapp/shared/app/components/onboarding-flows/steps/track-accept-contributor-invite';
import performPostActivationActions from 'Webapp/shared/app/components/onboarding-flows/steps/perform-post-activation-actions';
import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

const steps: Array<OnboardingStep> = [
  modalSignup,
  acceptMagazineInvite,
  trackAcceptContributorInvite,
  followMagazine,
  performPostActivationActions,
  showSuccessToastMessageInviteMagazine,
];

export default steps;
