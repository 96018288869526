export const PACKAGE_TYPE = 'package';

/**
 * Finds a storyboard item by searching the storyboard and its sections for an item whose
 * id is itemId.
 */
export const findItem = (storyboardItems, itemId) =>
  storyboardItems.reduce((res, item) => {
    if (res !== undefined) {
      return res;
    }

    if (item.id === itemId) {
      return item;
    }

    return item.children?.find((c) => c.id === itemId);
  }, undefined);

/**
 * Returns true if there is no destinationIndex (the target was a section)
 * or if the drag and drop is within one section and a lower index item was
 * moved after a higher indexed item.
 */
export const shouldAppend = ({
  sourceIndex,
  sourceContainerId,
  destinationContainer,
  destinationIndex,
  targetId,
}) => {
  let append = false;

  if (
    destinationIndex == undefined ||
    targetId == undefined ||
    (sourceContainerId === destinationContainer.id &&
      sourceIndex < destinationIndex)
  ) {
    // true if there's not destinationIndex (drop was on a section or item), or if the
    // item moved from a higher position to a lower position among its parent's children
    append = true;
  }

  return append;
};

/**
 * Finds the id of the item that a dragged item has been dropped on.
 *
 * @param {String} sourceItem            The storyboard item that has just been dropped.
 * @param {Number} destinationIndex      The index in the storyboard or one of its sections that
 *                                       the item was dropped at.
 * @param {String} destinationId         The 'parentId' of the item that the dragged item was dropped on.
 *                                       Could be the storyboard, a section, or an individual item
 * @param {Object} destinationContainer  The container the item has been dropped into, either the storyboard
 *                                       or one of its sections.
 * @return {String|undefined}
 */
export const getTargetItemId = ({
  sourceItem,
  destinationIndex,
  destinationId,
  destinationContainer,
}) => {
  let targetItemId;
  let index;

  if (destinationIndex == undefined) {
    // no destination.index, drop target was a section or item,
    // not storyboard or section body
    if (
      destinationContainer.id === destinationId &&
      sourceItem.type !== PACKAGE_TYPE
    ) {
      // drop target was a section and drop source was not a section,
      // so append after the target section's last child index
      index = (destinationContainer.children?.length ?? 1) - 1;
      targetItemId = destinationContainer.children?.[index]?.id;
    } else {
      // drop target was an item, so parent id is the item's id
      targetItemId = destinationId;
    }
  } else {
    // the drop target was the storyboard body or a section body
    index = destinationIndex;
    targetItemId = destinationContainer.children?.[index]?.id;
  }

  return targetItemId;
};

/**
 * Returns the child (article, etc.) of storyboard or one of its sections that has just
 * been dropped at the end of the drag.
 */
export const getDroppedItem = ({ index, parentId }, storyboard) => {
  const { id: storyboardId, children: items } = storyboard;

  // the dropped item is a child of the storyboard root, return the item
  if (parentId === storyboardId) {
    return items[index];
  }

  // the dropped item is a child of a section, find the section...
  const section = items.find((item) => item.id === parentId);

  // ...then return the child at index
  return section.children[index];
};

/**
 * Returns the container an item is being dropped into. Either the storyboard or a section
 * of the storyboard.
 */
export const getDestinationContainer = (
  sourceItem,
  destination,
  storyboard,
) => {
  const { id: storyboardId, children: items } = storyboard;

  // drop target is the storyboard root
  if (destination.parentId === storyboardId) {
    return storyboard;
  }

  const dropTarget = items.reduce((res, item) => {
    if (res !== undefined) {
      return res;
    }

    // the drop target is a package or redireced from an individual item
    // to the storyboard itself
    if (item.id === destination.parentId) {
      return item.type === PACKAGE_TYPE ? item : storyboard;
    }

    // the drop target might be a section or will be redirected from a section's
    // individual item child to the section
    return item.children?.find((c) => c.id === destination.parentId)
      ? item
      : undefined;
  }, undefined);

  if (dropTarget?.type === PACKAGE_TYPE && sourceItem.type === PACKAGE_TYPE) {
    return storyboard;
  }

  return dropTarget;
};

/**
 * Handles drop of an item from the storyboard root (not in a section) to the storyboard root.
 */
export const fromRootToRoot = ({
  storyboardItems,
  itemId,
  targetId,
  append = false,
}) => {
  const item = storyboardItems.find((c) => c.id === itemId);
  const updatedItems = storyboardItems.filter((i) => i.id !== itemId);
  let targetIndex = updatedItems.findIndex((c) => c.id === targetId);

  if (targetIndex < 0) {
    // this can happen when a bug internal to atlaskit/tree returns NaN for destination.index
    // this only seems to happen when dropping a child of the root storyboard partially on top
    // of the last child of the storyboard, so add the dropped item on the end.
    targetIndex = storyboardItems.length;
  } else {
    targetIndex += append ? 1 : 0;
  }

  updatedItems.splice(targetIndex, 0, item);

  return updatedItems;
};

/**
 * Handles the drop of an item from a storyboard section to the storyboard root.
 */
export const fromSectionToRoot = ({
  storyboardItems,
  itemId,
  targetId,
  sourceGroupId,
  append,
}) => {
  const sourceSection = storyboardItems.find((c) => c.id === sourceGroupId);

  if (!sourceSection) {
    return;
  }

  let targetIndex = storyboardItems.findIndex((c) => c.id === targetId);

  if (targetIndex >= 0) {
    targetIndex += append ? 1 : 0;
  } else {
    // if the target wasn't found, the item was dropped in last position, so splice it on the end.
    targetIndex = storyboardItems.length;
  }

  const [item, itemIndex] = sourceSection.children.reduce(
    (r, c, i) => (c.id === itemId ? [c, i] : r),
    [],
  );

  const updatedItems = storyboardItems.map((currentItem) => {
    if (currentItem === sourceSection) {
      const children1 = currentItem.children?.slice(0, itemIndex) ?? [];
      const children2 = currentItem.children?.slice(itemIndex + 1) ?? [];
      return {
        ...currentItem,
        children: [...children1, ...children2],
      };
    }
    return currentItem;
  });

  updatedItems.splice(targetIndex, 0, item);

  return updatedItems;
};

/**
 * Handles the drop of an item from the root of a storyboard into a section
 * (on a child item or within the section body).
 */
export const fromRootIntoSection = ({
  storyboardItems,
  itemId,
  targetId,
  targetGroupId,
  append,
}) => {
  const item = findItem(storyboardItems, itemId);
  const targetGroup = storyboardItems.find((i) => i.id === targetGroupId);

  if (!targetGroup) {
    return;
  }

  let targetIndex = targetGroup.children.findIndex((c) => c.id === targetId);
  targetIndex += append ? 1 : 0;

  return storyboardItems.reduce((newItems, currentItem) => {
    switch (true) {
      case currentItem.id === targetGroupId: {
        // this is the target section, add the item to its children
        const children1 = currentItem.children?.slice(0, targetIndex) ?? [];
        const children2 = currentItem.children?.slice(targetIndex) ?? [];

        newItems.push({
          ...currentItem,
          children: [...children1, item, ...children2],
        });
        break;
      }
      case currentItem.id === itemId:
        // is the item being moved, ignore to exclude since it's now in the section
        break;
      default:
        // add all other items
        newItems.push(currentItem);
    }

    return newItems;
  }, []);
};

/**
 * Handles the drop of an item from the root of a storyboard onto a section
 * (onto the section controls, open or closed).
 */
export const fromRootOntoSection = ({
  storyboardItems,
  itemId,
  targetGroupId,
}) => {
  const item = findItem(storyboardItems, itemId);

  if (!item) {
    return;
  }

  return storyboardItems.reduce((newItems, currentItem) => {
    switch (true) {
      case currentItem.id === itemId:
        // this is the item being moved, remove it by skipping it
        return newItems;
      case currentItem.id === targetGroupId: {
        // this is the target section/group/package, add item to it's children
        const children = Array.isArray(currentItem.children)
          ? currentItem.children
          : [];
        newItems.push({
          ...currentItem,
          children: [...children, item],
        });
        return newItems;
      }
      default:
        // any other items gets added as is
        newItems.push(currentItem);
        return newItems;
    }
  }, []);
};

/**
 * From one section into another.
 */
export const fromSectionIntoSection = ({
  storyboardItems,
  itemId,
  sourceGroupId,
  targetId,
  targetGroupId,
  append,
}) => {
  const targetGroup = storyboardItems.find((i) => i.id === targetGroupId);

  if (!targetGroup) {
    return;
  }

  const sourceGroup = storyboardItems.find((i) => i.id === sourceGroupId);

  if (!sourceGroup) {
    return;
  }

  const item = sourceGroup.children.find((c) => c.id === itemId);
  const sourceChildren = sourceGroup.children.filter((i) => i.id !== itemId);
  let targetIndex = targetGroup.children.findIndex((c) => c.id === targetId);
  targetIndex += append ? 1 : 0;
  const targetChildren1 = targetGroup.children?.slice(0, targetIndex) || [];
  const targetChildren2 = targetGroup.children?.slice(targetIndex) || [];
  const targetChildren = [...targetChildren1, item, ...targetChildren2];

  return storyboardItems.map((i) => {
    switch (i.id) {
      case sourceGroupId:
        // this is the section/group the item is moving from, remove item from its children
        return { ...i, children: sourceChildren };
      case targetGroupId:
        // this ithe secton/group the item is moving to, add item to its children
        return { ...i, children: targetChildren };
      default:
        return i;
    }
  });
};

/**
 * Handles drop from one section onto a section (the actual section item, forms/controls, is the target)
 */
export const fromSectionOntoSection = ({
  storyboardItems,
  itemId,
  sourceGroupId,
  targetGroupId,
}) => {
  const sourceSection = storyboardItems.find((c) => c.id === sourceGroupId);

  if (!sourceSection) {
    return;
  }

  const item = findItem(storyboardItems, itemId);

  return storyboardItems.reduce((newItems, currentItem) => {
    switch (true) {
      case sourceSection.id === currentItem.id:
        // this is the parent section of the moved item, remove the item from its children
        newItems.push({
          ...currentItem,
          children: currentItem.children?.filter((c) => c.id !== itemId) ?? [],
        });
        return newItems;
      case currentItem.id === targetGroupId: {
        // this is the section/package, add the item to its children
        const children = Array.isArray(currentItem.children)
          ? currentItem.children
          : [];

        newItems.push({
          ...currentItem,
          children: [...children, item],
        });
        return newItems;
      }
      default:
        // any other items get added as is
        newItems.push(currentItem);
        return newItems;
    }
  }, []);
};

/**
 * Child item is moved within it's parent section
 */
export const fromSectionToSameSection = ({
  storyboardItems,
  itemId,
  targetId,
  targetGroupId,
  append = false,
}) => {
  const targetGroup = storyboardItems.find((i) => i.id === targetGroupId);

  if (!targetGroup) {
    return;
  }

  const item = targetGroup.children.find((c) => c.id === itemId);
  const children = targetGroup.children.filter((i) => i.id !== itemId);
  let targetIndex = children.findIndex((c) => c.id === targetId);

  targetIndex += append ? 1 : 0;
  children.splice(targetIndex, 0, item);

  return storyboardItems.map((i) => {
    if (!i.children || i.id !== targetGroupId) {
      return i;
    }
    return { ...i, children };
  });
};

/**
 * Accepts a storyboard id, the contents of a storyboard, and arguments to determine the source
 * item, source container, destination container, and destination position for the dropped item.
 * Returns a copy of storyboardItems mutated according to these parameters.
 *
 * @param options                  arguments
 * @param options.storyboardItems  {Object[]}  Array of storyboard contents. Items include individual articles
 *                                     and sections.
 * @param options.storyboardId     {String}    The id of the storyboard whose contents are being rearranged.
 * @param options.sourceGroupId    {String}    The id of the container (either the storyboard or a section) the
 *                                     dropped item was a child of at drag start
 * @param options.targetGroupId    {String}    The id of the container (either the storyboard or a section) the
 *                                      item is being dropped into.
 * @param options.itemId           {String}    The id of the item (article, tweet, section, etc) being dragged
 *                                     and dropped
 * @param options.targetId         {String}    The id of the target container's child item that the dropped item
 *                                     will appear before or after.
 * @param options.append           {Boolean}   In some cases determines if the dropped item will appear before or
 *                                     after the item who's id matches targetId.
 */
export const updateStoryboardItems = ({
  storyboardItems,
  storyboardId,
  sourceGroupId,
  targetGroupId,
  itemId,
  targetId,
  append = false,
}) => {
  let updatedItems;

  if (storyboardId === targetGroupId) {
    // the target container is the storyboard, so this is a top level drop
    if (sourceGroupId === targetGroupId) {
      // moving from the root container to the root container
      updatedItems = fromRootToRoot({
        storyboardItems,
        itemId,
        targetId,
        append,
      });
    } else {
      // moving from a section/package to root
      updatedItems = fromSectionToRoot({
        storyboardItems,
        itemId,
        targetId,
        sourceGroupId,
        append,
      });
    }
  } else {
    // this drop is into one of the sections, or on the section itself
    if (!targetId) {
      // drop is on the section itself
      if (storyboardId === sourceGroupId) {
        // the item is coming from the main list
        updatedItems = fromRootOntoSection({
          storyboardItems,
          itemId,
          targetGroupId,
        });
      } else if (targetGroupId !== sourceGroupId) {
        // only set updated items if it's been dropped from a different section
        updatedItems = fromSectionOntoSection({
          storyboardItems,
          itemId,
          sourceGroupId,
          targetGroupId,
        });
      }
    } else if (sourceGroupId === targetGroupId) {
      // drop from section into same section
      updatedItems = fromSectionToSameSection({
        storyboardItems,
        itemId,
        targetId,
        targetGroupId,
        append,
      });
    } else if (storyboardId === sourceGroupId) {
      // drop from the root into a section
      updatedItems = fromRootIntoSection({
        storyboardItems,
        itemId,
        targetId,
        targetGroupId,
        append,
      });
    } else {
      // drop from different section onto section
      updatedItems = fromSectionIntoSection({
        storyboardItems,
        itemId,
        sourceGroupId,
        targetId,
        targetGroupId,
        append,
      });
    }
  }

  return updatedItems;
};
