import flapClientFactory from 'Utils/api/flap-client-factory';

/**
 * Axios instance to make Flap Content requests for from web-u client to web-u server
 */
const content = flapClientFactory('FLAP Content', '/api/v2/analytics', {
  // indefinite, some stats endpoints take forever and people are ok
  // with waiting?
  timeout: 0,
});
export default content;
