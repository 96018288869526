import GlobalVars from 'Utils/global-vars';
import FloutServer from './server';
import FloutClient from './client';

/**
 * Wrapper for Flout axios instances.
 * To avoid any confusion in a isomorphic application, just import
 * this file as your Flout axios instance, and it should work on servers and clients
 * import Flout from 'Utils/api/flout'
 */
const Flout = GlobalVars.isServer() ? FloutServer : FloutClient;
export default Flout;
