const sentenceCase = (string) => {
  // NOTE: Sentence casing was being done in the CSS,
  // but found that Firefox has a well known, long standing bug with this approach.
  // See more here: https://flipboard.atlassian.net/browse/FL-8721
  if (!string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
export default sentenceCase;
