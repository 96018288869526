import getWindow from 'Utils/get-window';
import { URL_REGEXP } from 'Utils/reg-exp';
import { getAuthorUrl } from './flipboard-urls';
import {
  isGroup,
  isStoryboard,
  getOriginalItem,
  isFlipComposeImage,
} from 'Utils/content/item-util';

// TODO: Bring over web/app/client/src/social from web repo
const social = {
  format: () => ({ isValidContentDomainURLs: [] }),
};

const TITLE_PUNCTUATION_REGEX = /["'‘’★ :]/g;

// TODO: Move to a separate util
function stripURLs(text, replacement = '\u2026') {
  if (!text) {
    return null;
  }
  return text.replace(
    URL_REGEXP,
    (match, _p1, _p2, _p3, _p4, _p5, offset, original) => {
      if (original.length - offset - match.length === 0) {
        return '';
      }
      return replacement;
    },
  );
}

function titlePrefixMatch(a, b) {
  if (!a || !b) {
    return null;
  }
  const modifiedA = a
    .replace(TITLE_PUNCTUATION_REGEX, '')
    .toLowerCase()
    .substr(0, 20);
  const modifiedB = a
    .replace(TITLE_PUNCTUATION_REGEX, '')
    .toLowerCase()
    .substr(0, 20);
  return modifiedA.indexOf(modifiedB) === 0;
}

// TODO: Revisit this. If this function is still needed, find out how this should work
// and write tests.
function isValidContentDomain(item, source) {
  // Only validate media types
  if (!['image', 'video', 'audio'].includes(item.type)) {
    return true;
  }
  const socialFormat = social.format(source.service);
  // Get content domain
  const anchor = getWindow().document.createElement('a');
  anchor.href = item.sourceURL;
  const urls = socialFormat.isValidContentDomainURLs;
  return urls.some((cd) => anchor.hostname.substr(-1 * cd.length) === cd);
}

const Attribution = {
  /**
   * returns the first section link of a specified type
   * @param  {Object} item - Flipboard Item
   * @param  {String} type - Type of section link
   * @return {Object}      - Section link object
   */
  getFirstSectionLinkByType: (item, type) => {
    if (!item || !item.sectionLinks) {
      return null;
    }
    // Note: group item sectionLinks type attribute is not the same as other items.
    //       Instead need to look at the feedType
    return (
      item.sectionLinks.find(
        (l) => l.type === type || (isGroup(item) && l.feedType === type),
      ) || null
    );
  },

  // TODO: Use?
  /**
   * Checks for duplicate sectionLinks
   * @param  {Array}  a - sectionLinks A
   * @param  {Array}  b - sectionLinks B
   * @return {Boolean}  - true if ANY sectionLink in a matches any sectionLink in b by remoteid.
   *                      false  otherwise.
   */
  hasDuplicateSectionLinks: (a, b) => {
    if (!a || !b) {
      return false;
    }
    const aTable = a.reduce((acc, link) => {
      acc[link.remoteid] = true;
      return acc;
    }, {});
    return b.some((link) => aTable[link.remoteid]);
  },

  // TODO: Use?
  /**
   * Checks if an Item is a Source.  Source items are those that
   * are type === 'status' and service !== 'flipboard'
   * @param  {Object}  item - Flipboard Item
   * @return {Boolean}
   */
  isSourceItem: (item) =>
    item.type === 'status' && item.service !== 'flipboard',

  // TODO: Use?
  /**
   * Recursively looks for a source item
   * @param  {Object} item - Flipboard Item
   * @return {Object}      - Flipboard item that is a Source
   */
  getSourceItem: (item) => {
    if (item.referredByItems) {
      const referredByItem = item.referredByItems.find((i) =>
        Attribution.getSourceItem(i),
      );
      if (referredByItem) {
        return referredByItem;
      }
    }

    if (Attribution.isSourceItem(item)) {
      return item;
    }
    if (item.original && Attribution.isSourceItem(item.original)) {
      return item.original;
    }
    return null;
  },

  // TODO: Use?
  getThirdPartySource: (item) => {
    const thirdPartySource = Attribution.getSourceItem(item);
    if (!thirdPartySource) {
      return null;
    }
    // Only get 3rd party source if one of the following is true:
    // - the content is "owned" by a service (same domain)
    // - 3rd party source is the same as primarySource
    const validDomain = !isValidContentDomain(item, thirdPartySource);
    if (
      validDomain &&
      thirdPartySource !== Attribution.getOriginalItemAuthor(item)
    ) {
      return null;
    }
    return thirdPartySource;
  },

  // TODO: Use?
  getPrimaryAttributionModel: (item) => {
    const originalSource = Attribution.getOriginalItemAuthor(item);
    const fromInfo = Attribution.getFromInfo(item);

    let commentary;
    if (!fromInfo && originalSource) {
      commentary = Attribution.getItemSourceCommentary(item, originalSource);
    }

    return {
      item,
      source: originalSource,
      from: fromInfo,
      commentary,
    };
  },

  // TODO: Use?
  getThirdPartyAttributionModel: (item) => {
    const thirdPartySource = Attribution.getThirdPartySource(item);
    const commentary = thirdPartySource ? thirdPartySource.text : null;
    return {
      item,
      source: thirdPartySource,
      from: null,
      commentary,
    };
  },

  /** (convenience) Returns the author of the original item
   * associated with an item object
   * @param {Object} item  - A Flipboard item object
   * @return {Object}      - The author of the originally flipped
   *                         item object
   */
  getOriginalItemAuthor: (item) => {
    const originalItem = getOriginalItem(item);
    return originalItem && Attribution.getAuthor(originalItem);
  },

  /**
   * Returns the first referredByItems child with type === status.
   * Returns the supplied item if it is a "status" item or if it has
   * no referredByItems of type "status."
   * @param {Object} - An item object
   * @return {Object} - An associated flip object
   */
  getFlipItem: (item) => {
    // RSS items and some other items have no referredByItems property,
    // return the root item for status update items.
    if (item.type === 'status') {
      return item;
    }

    if (item.referredByItems) {
      const statusItem = item.referredByItems.find((i) => i.type === 'status');

      if (statusItem) {
        return statusItem;
      }
    }

    return item;
  },

  /**
   * Returns the author for an item
   * @param {Object} item  - An item
   * @return {Object}      - An associated author object or null
   */
  getAuthor: (item) => {
    if (!item) {
      return null;
    }
    let author = Attribution.getFirstSectionLinkByType(item, 'author');
    if (author) {
      return author;
    }
    if (isFlipComposeImage(item) && item.referredByItems) {
      const referredByItem = item.referredByItems?.find(
        (i) => i.type === 'status',
      );
      author = Attribution.getFirstSectionLinkByType(referredByItem, 'author');
      if (author) {
        return author;
      }
    }

    if (isStoryboard(item) && item?.section?.author) {
      return item.section.author;
    }

    return null;
  },

  /**
   * Returns the author name, derived from the author object
   * @param {Object} author - author object
   * @return {String}
   */
  getAuthorName: (author) => {
    if (!author) {
      return null;
    }
    return (
      author.avatarText || author.authorDisplayName || author.authorUsername
    );
    // Note: Author will often have a title attribute on it, but it's usually not defined, unless it's a topic section.
    //       We don't want to make this title attribute available because then non profile sections will render the author avatar using the title.
  },

  /**
   * Returns the first magazine associated with an item or its "flip"
   * @param {Object} item - An item
   * @return {Object}     - A magazine object or null
   */
  getMagazine: (item) => {
    if (item === undefined || item === null) {
      return null;
    }

    const flip = Attribution.getFlipItem(item);
    const magazine =
      flip && Attribution.getFirstSectionLinkByType(flip, 'magazine');
    if (magazine && magazine.hidden !== true && !magazine.isProfile) {
      return magazine;
    }

    if (flip.originalFlip !== undefined) {
      const originalFlipMag = Attribution.getFirstSectionLinkByType(
        flip.originalFlip,
        'magazine',
      );
      if (
        originalFlipMag &&
        originalFlipMag.hidden !== true &&
        !originalFlipMag.isProfile
      ) {
        return originalFlipMag;
      }
    }

    return null;
  },

  getFeedTitle: (item, t) => {
    const { feedTitle, service } = item;
    if (typeof feedTitle === 'undefined' || feedTitle === null) {
      return null;
    }
    return service === 'flipboard'
      ? null
      : t('feed_title_via_rss', { feedTitle });
  },

  getFromInfo: (item) => {
    let fromInfo;

    if (item.franchise && item.activity) {
      fromInfo = item.activity;
    } else if (item.franchise) {
      fromInfo = {
        text: item.franchise.title,
        sectionLinks: item.franchise.sectionLinks,
      };
    } else {
      fromInfo = item.reason;
    }

    return fromInfo;
  },

  getItemSourceCommentary: (item, source) => {
    let commentary;

    if (source.service === 'flipboard') {
      if (source.text) {
        commentary = source;
      }
    } else if (item.type === 'status') {
      const originalItem = source.original || source;
      const commentaryText = stripURLs(originalItem.text);
      const contentText = stripURLs(originalItem.title || item.text);
      if (commentaryText && !titlePrefixMatch(commentaryText, contentText)) {
        commentary = Object.assign({}, originalItem, { text: commentaryText });
      }
    }

    if (commentary && commentary.text) {
      commentary.text = decodeURIComponent(commentary.text);
    }

    return commentary;
  },

  /**
   * Returns the byline for the item
   * @param  {Object} item      - Flipboard item
   * @param  {Function} i18n t  - translation function
   * @return {String}           - The author name, plus author display name if different
   */
  getByline: (item, t) => {
    const itemAuthorDisplayName = item.authorDisplayName;
    const author = Attribution.getOriginalItemAuthor(item);

    // no author section link
    if (author === null) {
      return itemAuthorDisplayName !== undefined ? itemAuthorDisplayName : null;
    }

    const { referringText, authorDisplayName, title, username, service } =
      author;
    const byline = referringText || authorDisplayName || title || username;

    // no required author props
    if (byline === undefined) {
      return null;
    }

    if (service === 'twitter') {
      return t('author_on_twitter', {
        authorDisplayName: byline,
      });
    }

    // authorDisplayName is defined on the item
    if (itemAuthorDisplayName !== undefined) {
      const repeated = itemAuthorDisplayName.indexOf(byline) > -1;
      return repeated ? byline : `${byline} \u00b7 ${itemAuthorDisplayName}`;
    }

    return byline;
  },

  /**
   * Returns an the text for the source attribution
   * @param  {Object} item           - Flipboard item
   * @param  {Function} i18n t       - translation function
   * @param  {bool} isCondensedItem  - item if condensed
   * @return {String}                - The text for the source attribution
   */
  getSourceAttributionText: (item, t, isCondensedItem) => {
    const { hostDisplayName, service, sourceDomain, section, partnerID } = item;

    // YouTube and SoundCloud
    if (item.isYoutube || item.isSoundcloud) {
      return Attribution.getMediaAttribution(item, t);
    }

    // display name
    if (hostDisplayName !== undefined) {
      return hostDisplayName;
    }

    // original source (for status and flipComposeImage items only)
    const originalSource = Attribution.getOriginalItemAuthor(item);

    if (item.isStatus) {
      if (originalSource !== null) {
        return Attribution.getByline(item, t);
      }
    }

    // Authored section items (magazines and storyboards)
    if (item.isMagazine || item.isStoryboard) {
      const bylineAuthor = item.sectionItemAuthor || item.author;
      const authorDisplayName =
        Attribution.getAuthorName(bylineAuthor) || item?.authorDisplayName;

      // Magazine byline
      if (item.isMagazine && isCondensedItem) {
        return t('by_author', {
          authorDisplayName,
        });
      }

      // TODO: Magazine byline "Magazine by" text will be removed when new gateway magazine is released
      if (item.isMagazine) {
        return authorDisplayName
          ? t('magazine_by_author', {
              authorDisplayName,
            })
          : '';
      }

      // Storyboard byline
      if (item.isStoryboard) {
        return t('by_author', {
          authorDisplayName,
        });
      }
    }

    // Topic byline
    if (item.isTopic || (section && section.isTopic)) {
      return t('a_flipboard_topic');
    }

    // Profile byline
    if (item.isProfile || (section && section.isProfile)) {
      return t('a_profile_on_flipboard');
    }

    // feed title
    const feedTitle = Attribution.getFeedTitle(item, t);
    if (feedTitle && service !== 'flipboard') {
      return feedTitle;
    }

    const prefix = item.author
      ? item.author.authorDisplayName || item.author.title
      : sourceDomain;

    const authorDisplayName = item.isFlipComposeImage
      ? originalSource?.authorDisplayName
      : item.authorDisplayName;
    if (authorDisplayName !== undefined) {
      // NOTE: Gross special-casing for Amazon brandmag partner items
      // prefix and author display name UNLESS Amazon partner
      if (typeof prefix === 'string' && partnerID !== 'brandmag-amazon') {
        const prefixContainsDisplayName =
          prefix.indexOf(authorDisplayName) > -1;
        const displayNameContainsPrefix =
          authorDisplayName.indexOf(prefix) > -1;
        return prefixContainsDisplayName || displayNameContainsPrefix
          ? prefix
          : `${prefix} - ${authorDisplayName}`;
      }

      // author display name
      return authorDisplayName;
    }

    // prefix
    if (prefix !== undefined) {
      return prefix;
    }

    // no attribution found
    return null;
  },

  /** Returns the URL for the source attribution
   * @param {Object} item             - Flipboard item
   * @return {String}                 - The URL for the source attribution
   */
  getSourceAttributionUrl: (item) => {
    const { type, sourceURL, internalURL, sectionItemAuthor, author } = item;

    // audio item
    if (type === 'audio') {
      return item.sourceURL;
    }

    // Topic
    if (item.isTopic && sourceURL) {
      return sourceURL;
    }

    // Return the URL of the original item author
    if (author && author.authorUsername) {
      return getAuthorUrl(author);
    }

    // Return the URL of the section author for magazines and Storyboards
    // Other sections should move on to the sourceURL
    if (
      sectionItemAuthor &&
      sectionItemAuthor.authorUsername &&
      (item.isMagazine || item.isStoryboard)
    ) {
      return getAuthorUrl(sectionItemAuthor);
    }

    // return sourceURL if present
    if (sourceURL) {
      return sourceURL;
    }

    if (internalURL) {
      return internalURL;
    }

    return null;
  },

  /**
   * Determines whether a section matches an item magazine, for the purposes
   * of deciding whether to show a magazine attribution.  If we're in
   * the same magazine (section), it doesn't make sense to show the
   * magazine attribution.
   * @param  {Object} section   - Flipboard section being rendered
   * @param  {Object} magazine  - The magazine for the item
   * @return {Boolean}          - true if they are the same
   */
  sectionMatchesMagazine: (section, magazine) => {
    // no section specified
    if (section === null) {
      return false;
    }

    const sectionRemoteId = section && section.remoteid;
    if (sectionRemoteId === magazine.remoteid) {
      return true;
    }

    const sectionRemoteIdToShare = section && section.remoteidToShare;
    if (sectionRemoteIdToShare === magazine.remoteid) {
      return true;
    }

    return false;
  },

  // TODO: test and document
  getSoundcloudAttribution: (item, t) => {
    const attribution = item.artist || item.postedBy;
    return typeof attribution !== 'undefined'
      ? t('attribution_on_soundcloud', { attribution })
      : 'soundcloud.com';
  },

  // TODO: test and document
  getYoutubeAttribution: (item, t) => {
    const attribution = item.artist || item.postedBy || item.authorDisplayName;
    return typeof attribution !== 'undefined'
      ? t('attribution_on_youtube', { attribution })
      : 'YouTube';
  },

  // TODO: test and document
  getMediaAttribution: (item, t) => {
    if (item.isSoundcloud) {
      return Attribution.getSoundcloudAttribution(item, t);
    }
    if (item.isYoutube) {
      return Attribution.getYoutubeAttribution(item, t);
    }
    return null;
  },

  /**
   * Gets the flipper for the item
   * @param {Object} item     - Flipboard item
   * @returns {Object}        - Flipboard author who flipped the item or null
   */
  getFlipper: (item) => {
    const flipItem = Attribution.getFlipItem(item);
    return flipItem && Attribution.getAuthor(flipItem);
  },
};

export default Attribution;
