import { CONTENT_TYPES } from 'Webapp/action-types';

interface RecommendedBoardsReducerState {
  isLoading: boolean;
  forYou: Array<Flipboard.RecommendedForYou>;
  editorial: Array<Flipboard.EditorialBoard>;
}

const initialState: RecommendedBoardsReducerState = {
  isLoading: false,
  forYou: [],
  editorial: [],
};

export default function recommendedBoardsReducer(state = initialState, action) {
  switch (action.type) {
    case CONTENT_TYPES.GET_RECOMMENDED_BOARDS_PENDING: {
      return Object.assign({}, state, { isLoading: true });
    }
    case CONTENT_TYPES.GET_RECOMMENDED_BOARDS_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        forYou: action.data.recommendedResults || [],
        editorial: action.data.editorialResults || [],
      });
    }
    case CONTENT_TYPES.GET_RECOMMENDED_BOARDS_FAILED: {
      return Object.assign({}, state, { isLoading: false });
    }
    default:
      return state;
  }
}
