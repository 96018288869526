import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ICON_COLORS } from 'Style/colors';

import Icon from './icon';

export const StyledFlipIcon = styled(Icon)({
  color: ICON_COLORS.emphasis,
  path: {
    stroke: 'currentColor',
  },
});
function FlipIcon({ size, ...props }) {
  return (
    <StyledFlipIcon
      name="flip"
      height={size}
      width={size}
      viewBox="0 0 24 24"
      {...props}
      renderComponent={() => (
        <>
          <path
            d="M12 4V20"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4 12H20"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )}
    />
  );
}

FlipIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

FlipIcon.defaultProps = {
  className: '',
  size: 24,
};

export default FlipIcon;
