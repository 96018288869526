export class CustomError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = this.constructor.name;
  }
}
export class LoginFailureError extends CustomError {}
export class UpdateFeedNullUserError extends CustomError {}
export class RequestTimeoutError extends CustomError {}
export class NoResponseError extends CustomError {}
export class HydraLoginRedirectError extends CustomError {}
export class HydraGetTokenError extends CustomError {}
export class AcceptLoginRequestArgumentError extends CustomError {}
export class ConsentContextError extends CustomError {}
export class ConfirmEmailError extends CustomError {}
export class SetOauth2TokenCookieError extends CustomError {}
export class DatadogProxyMethodArgumentError extends CustomError {}
export class DatadogProxyNameArgumentError extends CustomError {}
export class DatadogProxyTagArgumentError extends CustomError {}
export class HydraInvalidToken extends CustomError {}
export class SendUsageEventTimedOutError extends CustomError {}
export class VideoTrackerError extends CustomError {}
export class InvalidUIDError extends CustomError {}
export class ImageUploadError extends CustomError {}
export class ArticleCanonicalNoSourceURLKeyError extends CustomError {}
export class ArticleCanonicalSourceURLKeyFormatError extends CustomError {}
export class InsufficientPrivilegesError extends CustomError {}
export class GuardedHistoryMethodError extends CustomError {}
export class UnknownAPIError extends CustomError {}
export class SEOExperimentBadUrl extends CustomError {}
export class SEOExperimentConfigurationId extends CustomError {}
export class SEOExperimentConfigurationGroups extends CustomError {}
export class SEOExperimentConfigurationGroupId extends CustomError {}
export class SEOExperimentConfigurationGroupRange extends CustomError {}
export class SEOExperimentConfigurationGroupCoverage extends CustomError {}
export class TrackSignupExitRedirectError extends CustomError {}
export class TrackSignupEnterError extends CustomError {}
export class TrackSignupExitError extends CustomError {}
export class TrackPublisherSignupEnterError extends CustomError {}
export class TrackPublisherSignupExitError extends CustomError {}
export class TrackOnboardEnterError extends CustomError {}
export class TrackOnboardExitError extends CustomError {}
export class TrackSignupActivatedError extends CustomError {}
export class MissingSectionsParamError extends CustomError {}
export class CuratorProStoryboardFeedLoadFailure extends CustomError {}
export class ResendConfirmationEmailError extends CustomError {}
export class PutStateError extends CustomError {}
export class ReCaptchaValidationError extends CustomError {}
export class ReCaptchaSignupError extends CustomError {}
export class MissingRequiredEnvironmentVariable extends CustomError {}
export class UpstreamHealthCheckFailure extends CustomError {}
export class BadPromiseAllArgument extends CustomError {}
export class DecommissionedLoginError extends CustomError {}
export class PopulateAuthenticationError extends CustomError {}
export class SSOCallbackError extends CustomError {}
export class PublisherCreateError extends CustomError {}
export class PublisherCreateMissingUserError extends CustomError {}
export class CommentAreYouSureError extends CustomError {}
export class FetchSharedLinkDataError extends CustomError {}
export class BadSharedLinkDataError extends CustomError {}
export class BranchFetchSharedLinkDataError extends CustomError {}
export class BranchExtractSharedDataLinkError extends CustomError {}
export class BranchParseError extends CustomError {}
export class BranchDeepLinkCreateError extends CustomError {}
export class UrlPreviewError extends CustomError {}
export class MissingFlipTargetError extends CustomError {}
export class UploadImageError extends CustomError {}
export class InviteInvalidError extends CustomError {}
export class SaveSettingsError extends CustomError {}
export class UnrecognizedNotificationError extends CustomError {}
export class FrictionlessSignupError extends CustomError {}
export class AcceptMagazineInviteError extends CustomError {}
export class UnhandledFrictionlessFlowError extends CustomError {}
