import React, { Component } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import withBreadcrumbs from '../hocs/withBreadcrumbs';
import { SURFACE_COLORS } from 'Style/colors';

import connector from 'Utils/connector';
import connectRouting from 'Webapp/shared/app/connectors/connectRouting';

// Utils
import {
  CUSTOM_BANNER_IDS,
  getVisibleBanner,
  setDismissedBanner,
} from 'Webapp/client/lib/banners';

// Components
import GenericBanner from './banners/generic-banner';

const StyledAppBanneWrapper = styled.div((props) => ({
  background: props.isMagazineInviteBanner
    ? SURFACE_COLORS.secondary
    : 'inherit',
  position: props.isMagazineInviteBanner ? 'relative' : 'inherit',
}));

class Banner extends Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose(bannerId) {
    setDismissedBanner(bannerId);
    this.forceUpdate();
  }

  render() {
    const { banners, section, userInfo, routing } = this.props;
    const visibleBanner = getVisibleBanner(banners, section, userInfo, routing);
    if (!visibleBanner) {
      return null;
    }

    const CustomBanner = visibleBanner.component;
    const isMagazineInviteBanner =
      visibleBanner.id === CUSTOM_BANNER_IDS.MAGAZINE_INVITE;

    return (
      <StyledAppBanneWrapper
        isMagazineInviteBanner={isMagazineInviteBanner}
        className="app__banner-wrapper"
      >
        {CustomBanner ? (
          <CustomBanner onClose={this.handleClose} />
        ) : (
          <GenericBanner banner={visibleBanner} onClose={this.handleClose} />
        )}
      </StyledAppBanneWrapper>
    );
  }
}

Banner.propTypes = {
  banners: PropTypes.array.isRequired,
  section: PropTypes.object,
  userInfo: PropTypes.object,
  routing: PropTypes.object.isRequired,
  hasBreadcrumbs: PropTypes.bool.isRequired,
};

Banner.defaultProps = {
  section: null,
  userInfo: null,
  hasBreadcrumbs: false,
};

export default connector(connectRouting)(withBreadcrumbs(Banner));
