import { Connector } from 'Utils/connector';
import { fetchItemMetricDetail } from 'Webapp/shared/app/redux/actions/item-metric-detail-actions';

const actions = {
  fetchItemMetricDetail,
};

export type ConnectItemMetricDetailProps = Flipboard.ConnectorProps<
  unknown,
  typeof actions
>;

const connectItemMetricDetail: Connector = {
  actions,
};

export default connectItemMetricDetail;
