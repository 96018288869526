import { Breadcrumbs } from 'Utils/content/structured-data';
import { euc } from 'Utils/url';
import { getProfilePath } from 'Utils/content/flipboard-urls';

const storyboardRoot =
  'https://flipboard.com/@storyboards/us-storyboards-jl2f9vsjz';

export default ({ section }) => {
  if (!section || !section.isStoryboard) {
    return null;
  }
  const breadcrumbs = new Breadcrumbs();
  breadcrumbs.add('storyboard', storyboardRoot);
  if (section.authorUsername) {
    breadcrumbs.add(`@${euc(section.authorUsername)}`, getProfilePath(section));
  }
  breadcrumbs.add(section.title, section.sourceURL);
  return breadcrumbs;
};
