/* eslint-disable react/prefer-stateless-function */
// Allow creation of class component, so that refs can still be used on wrapped components
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { UI_SUBHEADING_TYPES, FONT_WEIGHTS } from 'Style/typography';
import { TEXT_COLORS } from 'Style/colors';
import { styleOnlyProps } from 'Style/style-helpers';

import { ItemActionButtonType } from 'Webapp/enums';

// Utils
import HocUtil from 'Utils/hoc-util';
import { getEditorUrl } from 'Utils/content/flipboard-urls';
import SectionUtil from 'Utils/content/section-util';
import sentenceCase from 'Utils/content/sentence-case';
import { noOpFn } from 'Utils/no-op-fn';

// Components
import Button, {
  StyleModifiers,
  StyleVariations,
} from 'Webapp/shared/app/components/button';
import DefaultItemActionButton from 'Webapp/shared/app/components/item-actions/default-item-action-button';
import FlipButton from 'Webapp/shared/app/components/item-actions/flip-button';
import LikeButton from 'Webapp/shared/app/components/item-actions/like-button';
import FollowButton from 'Webapp/shared/app/components/follow-button';
import ShareIcon from 'ComponentLibrary/icons/new_social/share';
import MuteIcon from 'ComponentLibrary/icons/mute';
import PersonalizeIcon from 'ComponentLibrary/icons/personalize';
import FavoriteButton from 'Webapp/shared/app/containers/section-header/favorite-button';
import ConfirmDialog from 'Webapp/shared/app/modals/confirm-dialog';
import ItemCommentsButton from 'Webapp/shared/app/components/item-comments-button';
import MuteSourceTag from 'Webapp/shared/app/components/item/mute-source-tag';
import RemoveIcon from 'ComponentLibrary/icons/remove';
import ReportIcon from 'ComponentLibrary/icons/report';
import CaretIcon, { CARET_DIRECTIONS } from 'ComponentLibrary/icons/caret';
import LessLikeThisIcon from 'ComponentLibrary/icons/less-like-this';
import RelatedTopicTag from 'Webapp/shared/app/components/item/related-topic-tag';
import PinFlipButton from 'Webapp/shared/app/components/flip/pinned-flip-buttons';
import InviteContributorButton from 'Webapp/shared/app/components/invite-contributor-button';
import CreateStoryButton from 'Webapp/shared/app/components/flip/create-story-button';
import MagazineInviteAcceptButton from 'Webapp/shared/app/components/magazine-invite-accept-button';

import { getContributorAccessorySections } from 'Webapp/shared/concepts/accessory-sections';

import withT from 'ComponentLibrary/hocs/withT';
import withToast from 'Webapp/shared/app/hocs/withToast';
import withShare from '../hocs/withShare';
import withPersonalize from '../hocs/withPersonalize';
import withReport from 'Webapp/shared/app/hocs/withReport';
import withRemoveFromMagazine from 'Webapp/shared/app/hocs/withRemoveFromMagazine';
import withHistory from 'Webapp/shared/app/hocs/withHistory';

import connector from 'Utils/connector';
import connectUserState from 'Webapp/shared/app/connectors/connectUserState';
import connectModal from 'Webapp/shared/app/connectors/connectModal';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectUsageSetNavFrom from 'Webapp/shared/app/connectors/connectUsageSetNavFrom';
import connectCurrentUser from 'Webapp/shared/app/connectors/connectCurrentUser';
import connectSocialActivity from 'Webapp/shared/app/connectors/connectSocialActivity';
import connectGetSection from 'Webapp/shared/app/connectors/connectGetSection';
import connectMagazineActions from 'Webapp/shared/app/connectors/connectMagazineActions';
import connectFeatureFlags from 'Webapp/shared/app/connectors/connectFeatureFlags';
import connectAcceptMagazineInvite from 'Webapp/shared/app/connectors/connectAcceptMagazineInvite';

export const BUTTON_SETS = Object.freeze({
  NEW_ITEM_CARD: [
    ItemActionButtonType.LIKE,
    ItemActionButtonType.COMMENTS,
    ItemActionButtonType.SHARE,
    ItemActionButtonType.FLIP,
  ],
  DEFAULT_SOCIAL: [
    ItemActionButtonType.LIKE,
    ItemActionButtonType.COMMENTS,
    ItemActionButtonType.FLIP,
    ItemActionButtonType.SHARE,
  ],
  DEFAULT_ACTION_SHEET: [
    ItemActionButtonType.LESS_LIKE,
    ItemActionButtonType.MUTE,
    ItemActionButtonType.REPORT,
    ItemActionButtonType.REMOVE_FROM_MAGAZINE,
    ItemActionButtonType.PIN_FLIP,
  ],
  MAGAZINE: [
    ItemActionButtonType.FOLLOW,
    ItemActionButtonType.EDIT_MAGAZINE,
    ItemActionButtonType.SHARE,
    ItemActionButtonType.FLIP,
  ],
  DEFAULT_TOPIC: [ItemActionButtonType.SHARE, ItemActionButtonType.FLIP],
  INLINE_STORYBOARD: [
    ItemActionButtonType.SHARE_TEXT,
    ItemActionButtonType.FLIP,
    ItemActionButtonType.LIKE,
  ],
  INLINE_STORYBOARD_PHONE: [ItemActionButtonType.SHARE_TEXT],
  FLIP_SHARE_LIKE: [
    ItemActionButtonType.FLIP,
    ItemActionButtonType.SHARE,
    ItemActionButtonType.LIKE,
  ],
  STORYBOARD_POST: [
    ItemActionButtonType.FLIP,
    ItemActionButtonType.SHARE,
    ItemActionButtonType.LIKE,
    ItemActionButtonType.REPORT,
  ],
});
export const TOOLTIP_SOCIAL_BUTTON_STYLE_MODIFIER = [
  StyleModifiers.BLOCK,
  StyleModifiers.WITH_BADGE,
];

export const MeatballButton = styled(DefaultItemActionButton)({
  gap: '0px',
  padding: `${SPACING.BASE} ${SPACING.MEDIUM}`,
});
const StyledFollowButton = styled(
  FollowButton,
  styleOnlyProps('isTooltipMenu'),
)((props) => {
  if (props.isTooltipMenu) {
    return {};
  }
  return {
    margin: `0px ${SPACING.MEDIUM}`,
  };
});
const LessLikeThisSpan = styled.span({
  paddingRight: SPACING.BASE,
});
const StyledItemTopicsContainer = styled.ul((props) => ({
  overflow: 'hidden',
  height: props?.isLessLikeOpen ? 'fit-content' : '0',
  display: props?.isLessLikeOpen ? 'block' : 'none',
  paddingBottom: SPACING.BASE,
}));

export const ItemButtonWithMetric = styled.div(UI_SUBHEADING_TYPES.SMALL, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: FONT_WEIGHTS.BOLD,
});

const CORE_ACTIONS_DEFAULT_ICON_SIZE = 24;

function withItemActions(Component) {
  class WrappedComponent extends React.Component {
    iconSize = CORE_ACTIONS_DEFAULT_ICON_SIZE;

    renderFollowButton = () => {
      const { section, featureFlags } = this.props;
      const renderWhiteOverStyleVariationButton =
        featureFlags.NEW_MAGAZINE_COVER && section?.isMagazine;

      return (
        <StyledFollowButton
          key={ItemActionButtonType.FOLLOW}
          namePrefix="social-actions"
          section={section}
          styleVariation={
            renderWhiteOverStyleVariationButton
              ? StyleVariations.WHITE_OVERLAY
              : null
          }
        />
      );
    };

    renderEditButton = () => {
      const { section, isTooltipMenu, t } = this.props;
      return (
        <DefaultItemActionButton
          isTooltipMenu={isTooltipMenu}
          key={ItemActionButtonType.EDIT_MAGAZINE}
          name="social-actions_edit-magazine"
          className="social-actions_edit-magazine-button"
          label={t('edit')}
          styleVariation={StyleVariations.WHITE_OVERLAY}
          href={getEditorUrl(section)}
        />
      );
    };
    renderPersonalizeButton = (isIcon = true) => {
      const {
        section,
        item,
        smartMagazineSection,
        onPersonalize,
        isUserStateLoading,
        isTooltipMenu,
        t,
      } = this.props;
      const isSmartMagazine = !item && !!smartMagazineSection;
      const buttonStyleModifiers = [
        StyleModifiers.BLOCK,
        StyleModifiers.ALIGN_LEFT,
      ];
      return (
        <DefaultItemActionButton
          isTooltipMenu={isTooltipMenu}
          key={ItemActionButtonType.PERSONALIZE}
          name="social-action-personalize"
          onClick={() => onPersonalize({ section })}
          disabled={isUserStateLoading}
          label={isIcon ? null : t('personalize')}
          className={isIcon ? 'social-actions__button' : ''}
          styleModifier={isIcon ? undefined : buttonStyleModifiers}
        >
          {isIcon && (
            <PersonalizeIcon
              size={this.iconSize}
              isPersonalized={isSmartMagazine}
            />
          )}
        </DefaultItemActionButton>
      );
    };
    renderShareButton = (isIcon = true) => {
      const { section, item, onShare, isUserStateLoading, isTooltipMenu, t } =
        this.props;
      const hideIcon = isTooltipMenu && !item && section?.isTopic;
      const styleModifier = [];
      if (isTooltipMenu) {
        styleModifier.push(StyleModifiers.BLOCK);
        if (hideIcon) {
          styleModifier.push(StyleModifiers.ALIGN_LEFT);
        } else {
          styleModifier.push(StyleModifiers.WITH_BADGE);
        }
      }

      return isIcon ? (
        <DefaultItemActionButton
          tooltip={t('share')}
          isTooltipMenu={isTooltipMenu}
          key={ItemActionButtonType.SHARE}
          className="social-actions__button"
          onClick={() => onShare({ section, item })}
          name="social-action-share"
          styleModifier={styleModifier}
          disabled={isUserStateLoading}
        >
          {!hideIcon && <ShareIcon size={this.iconSize} />}
          {isTooltipMenu && <span>{sentenceCase(t('share'))}</span>}
          {/* There is no share Count. We get a "shareCount, but it's actually flipCount. Don't be fooled." If that changes, add them here. */}
        </DefaultItemActionButton>
      ) : (
        <DefaultItemActionButton
          tooltip={t('share')}
          isTooltipMenu={isTooltipMenu}
          key={ItemActionButtonType.SHARE}
          name="social-action-share"
          label={t('share')}
          styleVariation={StyleVariations.PRIMARY}
          onClick={() => onShare({ section, item })}
        />
      );
    };

    renderFlipButton = () => {
      const { item, section, isTooltipMenu, showSocialMetrics } = this.props;

      return (
        <FlipButton
          key={ItemActionButtonType.FLIP}
          item={item}
          section={section}
          isTooltipMenu={isTooltipMenu}
          showSocialMetrics={showSocialMetrics}
          size={this.iconSize}
        />
      );
    };

    renderLikeButton = () => {
      const { item, section, isTooltipMenu, showSocialMetrics } = this.props;

      return (
        <LikeButton
          key={ItemActionButtonType.LIKE}
          item={item}
          section={section}
          size={this.iconSize}
          isTooltipMenu={isTooltipMenu}
          showSocialMetrics={showSocialMetrics}
        />
      );
    };

    renderCommentButton = () => {
      const { item, section, t } = this.props;
      return (
        <ItemCommentsButton
          tooltip={t('comment')}
          key={ItemActionButtonType.COMMENTS}
          size={this.iconSize}
          item={item}
          section={section}
        />
      );
    };

    renderMuteButton = (type) => () => {
      const { item, isTooltipMenu } = this.props;
      return (
        <MeatballButton
          key={ItemActionButtonType.MUTE}
          isTooltipMenu={isTooltipMenu}
          styleModifier={[StyleModifiers.WITH_BADGE]}
          name="item-actions-remove"
        >
          <MuteIcon color={TEXT_COLORS.primary} />
          <MuteSourceTag type={type} item={item} />
        </MeatballButton>
      );
    };

    renderAcceptInviteButton = () => {
      const { section, isTooltipMenu } = this.props;

      return (
        <MagazineInviteAcceptButton
          key={ItemActionButtonType.ACCEPT_INVITE}
          isTooltipMenu={isTooltipMenu}
          styleVariation={
            isTooltipMenu ? undefined : StyleVariations.WHITE_OVERLAY
          }
          styleModifier={
            isTooltipMenu ? TOOLTIP_SOCIAL_BUTTON_STYLE_MODIFIER : undefined
          }
          section={section}
        />
      );
    };

    renderLeaveMagazineButton = (magazineTarget, userid, section) => {
      const {
        showModal,
        removeContributor,
        purgeSection,
        isTooltipMenu,
        toastShowInfoAction,
        t,
        history,
      } = this.props;
      const handleLeavingMagazine = async () => {
        await removeContributor(magazineTarget, userid);
        toastShowInfoAction(t('leave_magazine_success'));
        history.push('/');
        // in case they backtrack or whatever we want to reload things
        purgeSection(section.remoteid);
      };

      return (
        <DefaultItemActionButton
          key={ItemActionButtonType.LEAVE_MAGAZINE}
          styleVariation={StyleVariations.NONE}
          onClick={() => {
            showModal(ConfirmDialog, {
              name: 'remove-self-contributor',
              confirmLabel: t('remove'),
              message: t('confirm_remove_contributor', {
                magazineTitle: section.title,
              }),
              onConfirm: () => handleLeavingMagazine(),
            });
          }}
          className="social-actions_leave-magazine-button"
          label={t('leave_magazine')}
          styleModifier={
            isTooltipMenu ? TOOLTIP_SOCIAL_BUTTON_STYLE_MODIFIER : undefined
          }
        />
      );
    };

    renderFavoriteButton = () => {
      const { section, isTooltipMenu } = this.props;
      return (
        <FavoriteButton
          key={ItemActionButtonType.ADD_FAVORITE}
          section={section}
          styleVariation={StyleVariations.NONE}
          styleModifier={
            isTooltipMenu ? TOOLTIP_SOCIAL_BUTTON_STYLE_MODIFIER : undefined
          }
        />
      );
    };
    renderInviteContributorButton = () => {
      const { section, isTooltipMenu } = this.props;
      return (
        <InviteContributorButton
          section={section}
          isTooltipMenu={isTooltipMenu}
          key={ItemActionButtonType.INVITE_CONTRIBUTOR}
        />
      );
    };

    renderLessLikeButton = () => {
      const { item, section, t } = this.props;
      const [isLessLikeOpen, setIsLessLikeOpen] = useState(false);
      const renderItemTopics = () => {
        {
          return item.topics.map((topic) => (
            <li key={`${ItemActionButtonType.LESS_LIKE}_${topic.title}`}>
              <Button
                styleModifier={[StyleModifiers.BLOCK]}
                name="item-actions-less-like"
              >
                <RelatedTopicTag topic={topic} section={section} />
              </Button>
            </li>
          ));
        }
      };
      return (
        <React.Fragment>
          <MeatballButton
            key={ItemActionButtonType.LESS_LIKE}
            styleModifier={[StyleModifiers.WITH_BADGE]}
            onClick={() => {
              setIsLessLikeOpen(!isLessLikeOpen);
            }}
            name="item-actions-less-like"
            color={TEXT_COLORS.primary}
          >
            <LessLikeThisIcon color={TEXT_COLORS.primary} />
            <LessLikeThisSpan>{t('show_less_like_this')}</LessLikeThisSpan>
            <CaretIcon
              direction={
                isLessLikeOpen ? CARET_DIRECTIONS.down : CARET_DIRECTIONS.right
              }
              color={TEXT_COLORS.primary}
              size={12}
            />
          </MeatballButton>
          <StyledItemTopicsContainer
            key={`${ItemActionButtonType.LESS_LIKE}_topics`}
            isLessLikeOpen={isLessLikeOpen}
          >
            {renderItemTopics()}
          </StyledItemTopicsContainer>
        </React.Fragment>
      );
    };

    renderReportButton = () => {
      const { item, section, dismiss, onReport, t } = this.props;
      return (
        <MeatballButton
          key={ItemActionButtonType.REPORT}
          styleModifier={[StyleModifiers.BLOCK, StyleModifiers.WITH_BADGE]}
          onClick={() => onReport({ item, section, callback: dismiss })}
          name="item-actions-report"
        >
          <ReportIcon
            color={TEXT_COLORS.primary}
            size={section?.isStoryboard ? this.iconSize : 18}
          />
          <span>{sentenceCase(t('report'))}</span>
        </MeatballButton>
      );
    };

    renderRemoveFromMagazineButton = () => {
      const { item, section, onRemoveItemFromMagazine, dismiss, t } =
        this.props;
      return (
        <MeatballButton
          key={ItemActionButtonType.REMOVE_FROM_MAGAZINE}
          styleModifier={[StyleModifiers.BLOCK, StyleModifiers.WITH_BADGE]}
          onClick={() =>
            onRemoveItemFromMagazine({ item, section, callback: dismiss })
          }
          name="item-actions-remove"
        >
          <RemoveIcon />
          <span>{sentenceCase(t('remove'))}</span>
        </MeatballButton>
      );
    };

    renderCreateStory = () => {
      const { section } = this.props;
      return (
        <CreateStoryButton
          section={section}
          key={ItemActionButtonType.CREATE_STORY}
        />
      );
    };

    renderPinFlipButton = (pin) => {
      const { item, section, t } = this.props;
      return (
        <PinFlipButton
          tooltip={t('pin')}
          key={ItemActionButtonType.PIN_FLIP}
          item={item}
          section={section}
          pin={pin}
        />
      );
    };

    getButton(buttonType) {
      const {
        uid,
        isAuthenticated,
        item,
        section,
        smartMagazineSection,
        isAnalytics,
        accessorySections,
      } = this.props;

      const isMagazineOwner =
        section && uid && SectionUtil.isMagOwner(section, uid);

      // Only currently checking this when we are looking at the primary section
      const contributors =
        section &&
        getContributorAccessorySections(section.remoteid, accessorySections);
      const isMagazineContributor =
        section?.primarySectionForRoute &&
        SectionUtil.isMagContributor(section, contributors, uid);
      const isSmartMagazine = !item && !!smartMagazineSection;

      switch (buttonType) {
        case ItemActionButtonType.FOLLOW:
          if (!isMagazineOwner) {
            return this.renderFollowButton;
          }
          break;
        case ItemActionButtonType.EDIT_MAGAZINE:
          if (!item && isMagazineOwner) {
            return this.renderEditButton;
          }
          break;
        case ItemActionButtonType.PERSONALIZE:
          if (!item && (section?.isTopic || section?.isSmartMagazine)) {
            return () => this.renderPersonalizeButton(true);
          }
          break;
        case ItemActionButtonType.PERSONALIZE_TEXT:
          if (!item && (section?.isTopic || section?.isSmartMagazine)) {
            return () => this.renderPersonalizeButton(false);
          }
          break;
        case ItemActionButtonType.SHARE:
          if (!isSmartMagazine) {
            return () => this.renderShareButton(true);
          }
          break;
        case ItemActionButtonType.SHARE_TEXT:
          if (!isSmartMagazine) {
            return () => this.renderShareButton(false);
          }
          break;
        case ItemActionButtonType.FLIP:
          if (!isSmartMagazine && !isAnalytics) {
            return this.renderFlipButton;
          }
          break;
        case ItemActionButtonType.LIKE:
          if (item && !isAnalytics) {
            return this.renderLikeButton;
          }
          break;
        case ItemActionButtonType.COMMENTS:
          if (item && !isAnalytics) {
            return this.renderCommentButton;
          }
          break;
        case ItemActionButtonType.MUTE:
          if (
            item?.sourceDomain &&
            !isAnalytics &&
            section?.isUserPreferenceable
          ) {
            if (item.sourceDomain === 'flipboard.com') {
              return this.renderMuteButton('author');
            }
            return this.renderMuteButton('sourceDomain');
          }
          break;
        case ItemActionButtonType.ACCEPT_INVITE: {
          const invite = SectionUtil.getInvite(section, uid);
          if (invite && !isMagazineContributor) {
            return () => this.renderAcceptInviteButton();
          }
          break;
        }
        case ItemActionButtonType.LEAVE_MAGAZINE: {
          const {
            section,
            profile: { contributorMagazines },
          } = this.props;
          if (isMagazineContributor) {
            const magazine = contributorMagazines.find((x) =>
              SectionUtil.mightBeSameSection(x, section),
            );
            if (magazine) {
              return () =>
                this.renderLeaveMagazineButton(
                  magazine.magazineTarget,
                  this.props.profile.userInfo.userid,
                  section,
                );
            }
          }
          break;
        }
        case ItemActionButtonType.ADD_FAVORITE:
          if (
            section?.isMagazine ||
            section?.isTopic ||
            section?.isSmartMagazine
          ) {
            return this.renderFavoriteButton;
          }
          break;
        case ItemActionButtonType.INVITE_CONTRIBUTOR:
          if (isMagazineOwner) {
            return this.renderInviteContributorButton;
          }
          break;
        case ItemActionButtonType.LESS_LIKE:
          if (
            isAuthenticated &&
            !!item?.topics.length &&
            section?.isUserPreferenceable
          ) {
            return this.renderLessLikeButton;
          }
          break;
        case ItemActionButtonType.REPORT:
          if (isAuthenticated && item) {
            return this.renderReportButton;
          }
          break;
        case ItemActionButtonType.REMOVE_FROM_MAGAZINE:
          if (item.canDeleteFromMagazine) {
            return this.renderRemoveFromMagazineButton;
          }
          break;
        case ItemActionButtonType.CREATE_STORY:
          if (isMagazineOwner || isMagazineContributor) {
            return this.renderCreateStory;
          }
          break;
        case ItemActionButtonType.PIN_FLIP:
          if (isMagazineOwner || isMagazineContributor) {
            return () => this.renderPinFlipButton(!item.pinned);
          }
          break;
        default:
          return null;
      }
    }
    generateItemActionButtons = () => {
      const { buttonSet, isTooltipMenu } = this.props;
      const buttons = [];
      const DefaultItemActionButtonSet = isTooltipMenu
        ? BUTTON_SETS.DEFAULT_ACTION_SHEET
        : BUTTON_SETS.DEFAULT_SOCIAL;

      (buttonSet || DefaultItemActionButtonSet).forEach((bt) => {
        const button = this.getButton(bt);
        if (button) {
          buttons.push(button);
        }
      });
      return buttons;
    };

    render() {
      return (
        <Component
          {...this.props}
          generateItemActionButtons={this.generateItemActionButtons}
        />
      );
    }
  }

  // Proxy the wrapped component displayName
  WrappedComponent.displayName = HocUtil.displayName(Component);
  WrappedComponent.propTypes = {
    isAuthenticated: PropTypes.bool,
    uid: PropTypes.number,
    item: PropTypes.object,
    section: PropTypes.object,
    smartMagazineSection: PropTypes.object,
    buttonSet: PropTypes.array,
    showFollowButton: PropTypes.bool,
    onPersonalize: PropTypes.func.isRequired,
    onShare: PropTypes.func.isRequired,
    isAnalytics: PropTypes.bool,
    isUserStateLoading: PropTypes.bool,
    isTooltipMenu: PropTypes.bool,
    showModal: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    toastDismiss: PropTypes.func.isRequired,
    toastShowInfoAction: PropTypes.func.isRequired,
    usageSetNavFrom: PropTypes.func.isRequired,
    profile: PropTypes.object,
    onRemoveItemFromMagazine: PropTypes.func,
    onReport: PropTypes.func,
    dismiss: PropTypes.func,
    showSocialMetrics: PropTypes.bool,
    removeContributor: PropTypes.func.isRequired,
    purgeSection: PropTypes.func.isRequired,
    getSection: PropTypes.func.isRequired,
    fetchUserInfo: PropTypes.func.isRequired,
    accessorySections: PropTypes.object.isRequired,
    socialActivity: PropTypes.object.isRequired,
    featureFlags: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  WrappedComponent.defaultProps = {
    section: null,
    hideSocialButtons: false,
    isAnalytics: false,
    showFollowButton: false,
    isTooltipMenu: false,
    profile: null,
    dismiss: noOpFn,
    showSocialMetrics: false,
  };

  return connector(
    connectUserState,
    connectModal,
    connectAuthentication,
    connectUsageSetNavFrom,
    connectCurrentUser,
    connectSocialActivity,
    connectGetSection,
    connectMagazineActions,
    connectFeatureFlags,
    connectAcceptMagazineInvite,
  )(
    withReport(
      withRemoveFromMagazine(
        withToast(
          withPersonalize(withShare(withHistory(withT(WrappedComponent)))),
        ),
      ),
    ),
  );
}

export default withItemActions;
