import { factory as flaxiosFactory } from 'Utils/flaxios';
import qs from 'Utils/qs';
import ApiHelpers from 'Utils/api/helpers';
import Config from 'Config';

const API_NAME = 'Flout';

export const floutFactory = () => {
  const flout = flaxiosFactory({
    baseURL: Config.FLOUT_API_GW_URL,
    timeout: 30000,
  });

  // Default query param serializer
  flout.defaults.paramsSerializer = (params) => {
    // NOTE: Hack around to get around special characters in remoteids
    // We need to prevent double encoding, as request goes from web client to web server, then to
    // Flout. Example of remoteid format: flipboard/package%2FXprRYSHxQOi1-Uf_aSjrqw%3Ac%3A75085216
    if (params.packageSectionID) {
      params.packageSectionID = params.packageSectionID.replace(
        'flipboard%2F',
        'flipboard/',
      );
    }
    return qs.stringify(params, { arrayFormat: 'comma' });
  };

  /**
   * Request interceptor for Flout requests made from the server
   * @param {Object} config - axios config
   * @returns {Object} config - modified axios config
   */
  flout.interceptors.request.use((config) => {
    // Set start time of request
    config.metadata = { startTime: new Date() };

    ApiHelpers.interceptApiGwRequest(config);
    return config;
  });

  /**
   * Response interceptor for Flap requests
   */
  flout.interceptors.response.use(
    (response) => ApiHelpers.interceptResponseHandler(response, API_NAME),
    (error) => ApiHelpers.interceptResponseErrorHandler(error, API_NAME),
  );

  return flout;
};

export default floutFactory();
