// IMPORTANT! update this if you add an enum.  If you don't you will
// probably end up with type errors
export type ActionTypeEnum =
  | ACCESSORY_SECTIONS_TYPES
  | ACCOUNT_EMAIL_SETTINGS_TYPES
  | APP_TYPES
  | ANALYTICS_TYPES
  | ARTICLES_TYPES
  | AUTH_TYPES
  | BLOCKING_TYPES
  | CONTENT_TYPES
  | CURATOR_PRO_MAGAZINE_TYPES
  | CURATOR_PRO_STORYBOARD_TYPES
  | FEATURE_FLAG_TYPES
  | FLIP_MODE_TYPES
  | MAGAZINE_INVITE_TYPES
  | MAGAZINE_SUBSCRIPTION_TYPES
  | MAGAZINE_TYPES
  | MODAL_TYPES
  | MUTING_TYPES
  | NEWSLETTERS_LANDING_TYPES
  | NOTIFICATION_TYPES
  | PROFILE_TYPES
  | PUBLISHER_TYPES
  | QUICK_ACCESS_TYPES
  | SEARCH_NAV_TYPES
  | SEARCH_TYPES
  | SECTIONS_TYPES
  | SETTINGS_TYPES
  | SHARED_LINK_TYPES
  | SITEMAP_TYPES
  | SOCIAL_ACTIVITY_TYPES
  | TOAST_TYPES
  | USAGE_TYPES;

export enum ACCESSORY_SECTIONS_TYPES {
  SET = 'ACCESSORY_SECTIONS_SET',
}

export enum ACCOUNT_EMAIL_SETTINGS_TYPES {
  GET_EMAIL_CONFIG = 'GET_EMAIL_CONFIG',
  GET_EMAIL_CONFIG_SUCCEEDED = 'GET_EMAIL_CONFIG_SUCCEEDED',
  GET_EMAIL_CONFIG_FAILED = 'GET_EMAIL_CONFIG_FAILED',
  GET_EMAIL_SETTINGS = 'GET_EMAIL_SETTINGS',
  GET_EMAIL_SETTINGS_SUCCEEDED = 'GET_EMAIL_SETTINGS_SUCCEEDED',
  GET_EMAIL_SETTINGS_FAILED = 'GET_EMAIL_SETTINGS_FAILED',
  SAVE_EMAIL_SETTINGS_PENDING = 'SAVE_EMAIL_SETTINGS_PENDING',
  SAVE_EMAIL_SETTINGS_SUCCEEDED = 'SAVE_EMAIL_SETTINGS_SUCCEEDED',
  SAVE_EMAIL_SETTINGS_FAILED = 'SAVE_EMAIL_SETTINGS_FAILED',
  RESET_EMAIL_SETTINGS_SAVE_STATE = 'RESET_EMAIL_SETTINGS_SAVE_STATE',
  SET_PRESELECT_UNSUBSCRIBE_COMPLETE = 'SET_PRESELECT_UNSUBSCRIBE_COMPLETE',
  SET_EMAIL_FROM_CODE = 'SET_EMAIL_FROM_CODE',
  SET_PRESELECT_SUBSCRIPTION = 'SET_PRESELECT_SUBSCRIPTION',
}

export enum APP_TYPES {
  CLEAR_APP_ERRORS = 'CLEAR_APP_ERRORS',
  GET_EXPERIMENTS = 'GET_EXPERIMENTS',
  GET_EXPERIMENTS_FAILED = 'GET_EXPERIMENTS_FAILED',
  GET_EXPERIMENTS_SUCCEEDED = 'GET_EXPERIMENTS_SUCCEEDED',
  GET_USER_FEATURES_FAILED = 'GET_USER_FEATURES_FAILED',
  GET_USER_FEATURES_SUCCEEDED = 'GET_USER_FEATURES_SUCCEEDED',
  REFRESH_APP_BANNERS = 'REFRESH_APP_BANNERS',
  RESET_BREADCRUMBS = 'RESET_BREADCRUMBS',
  SET_ACTIVATION_REFERRER = 'SET_ACTIVATION_REFERRER',
  SET_ADJUST_DEEP_LINK = 'SET_ADJUST_DEEP_LINK',
  SET_ADJUST_NAV_FROM = 'SET_ADJUST_NAV_FROM',
  SET_ADJUST_SECTION_ID = 'SET_ADJUST_SECTION_ID',
  SET_APP_BANNERS = 'SET_APP_BANNERS',
  SET_APP_DEVICE_TYPE = 'SET_APP_DEVICE_TYPE',
  SET_APP_ENABLE_AMP_STORIES = 'SET_APP_ENABLE_AMP_STORIES',
  SET_APP_IS_AMP = 'SET_APP_IS_AMP',
  SET_APP_IS_RSS = 'SET_APP_IS_RSS',
  SET_APP_LOCALE = 'SET_APP_LOCALE',
  SET_APP_META_DESCRIPTION = 'SET_APP_META_DESCRIPTION',
  SET_APP_NOT_FOUND = 'SET_APP_NOT_FOUND',
  SET_APP_PAGE_CANONICAL_PATH = 'SET_APP_PAGE_CANONICAL_PATH',
  SET_APP_SOCIAL_GRAPH_DESCRIPTION = 'SET_APP_SOCIAL_GRAPH_DESCRIPTION',
  SET_APP_SOCIAL_GRAPH_TITLE = 'SET_APP_SOCIAL_GRAPH_TITLE',
  SET_APP_THEME = 'SET_APP_THEME',
  SET_APP_THEME_OVERRIDE = 'SET_APP_THEME_OVERRIDE',
  SET_APP_TITLE = 'SET_APP_TITLE',
  SET_APP_URL = 'SET_APP_URL',
  SET_APP_VIEWPORT_TYPE = 'SET_APP_VIEWPORT_TYPE',
  SET_BREADCRUMBS = 'SET_BREADCRUMBS',
  SET_DID_NAVIGATE = 'SET_DID_NAVIGATE',
  SET_DID_SCROLL_TO_BOTTOM = 'SET_DID_SCROLL_TO_BOTTOM',
  SET_EXPERIMENT_OVERRIDES = 'SET_EXPERIMENT_OVERRIDES',
  SET_FEATURE_FLAG_AB_TEST_SETUP_COMPLETE = 'SET_FEATURE_FLAG_AB_TEST_SETUP_COMPLETE',
  SET_FORCED_TRACE_LOG = 'SET_FORCED_TRACE_LOG',
  SET_FRICTIONLESS_TRIGGER_ONBOARDING = 'SET_FRICTIONLESS_TRIGGER_ONBOARDING',
  SET_GOOGLE_AD_VISIBILITY = 'SET_GOOGLE_AD_VISIBILITY',
  SET_HIDE_MAIN_NAV_CTAS = 'SET_HIDE_MAIN_NAV_CTAS',
  SET_HOME_TILE_SECTIONS = 'SET_HOME_TILE_SECTIONS',
  SET_IS_CHROMELESS = 'SET_IS_CHROMELESS',
  SET_IS_EMBEDDABLE = 'SET_IS_EMBEDDABLE',
  SET_IS_FROM_BRIEFING = 'SET_IS_FROM_BRIEFING',
  SET_IS_FROM_CONFIRMATION = 'SET_IS_FROM_CONFIRMATION',
  SET_IS_FROM_CRAWLER = 'SET_IS_FROM_CRAWLER',
  SET_IS_FROM_INVITATION = 'SET_IS_FROM_INVITATION',
  SET_IS_GATE_DISMISSED = 'SET_IS_GATE_DISMISSED',
  SET_IS_INTEGRATION = 'SET_IS_INTEGRATION',
  SET_IS_IOS = 'SET_IS_IOS',
  SET_IS_NEW_USER = 'SET_IS_NEW_USER',
  SET_IS_WEBVIEW = 'SET_IS_WEBVIEW',
  SET_JOBID = 'SET_JOBID',
  SET_NAV_APP_STORE_URL = 'SET_NAV_APP_STORE_URL',
  SET_NAV_FROM_OVERRIDE = 'SET_NAV_FROM_OVERRIDE',
  SET_NGL_FEED_CONFIGS = 'SET_NGL_FEED_CONFIGS',
  SET_NO_EXPERIMENTS = 'SET_NO_EXPERIMENTS',
  SET_REFERRER = 'SET_REFERRER',
  SET_ROUTING = 'SET_ROUTING',
  SET_SEO_EXPERIMENTS = 'SET_SEO_EXPERIMENTS',
  SET_SERVER_COMPONENT = 'SET_SERVER_COMPONENT',
  SET_SERVICE_WORKER_REGISTERED = 'SET_SERVICE_WORKER_REGISTERED',
  SET_SHOWN_VIDEO_AD = 'SET_SHOWN_VIDEO_AD',
  SET_SUPPRESS_MOBILE_GATE = 'SET_SUPPRESS_MOBILE_GATE',
  SET_TRIGGER_INVITE_ONBOARDING = 'SET_TRIGGER_INVITE_ONBOARDING',
  SET_UTM_PARAMS = 'SET_UTM_PARAMS',
  SET_VIDEO_ADS_ENABLED = 'SET_VIDEO_ADS_ENABLED',
  SET_MASTODON_VERIFICATION_URL = 'SET_MASTODON_VERIFICATION_URL',
  SET_ACTIVITY_PUB_ALTERNATE = 'SET_ACTIVITY_PUB_ALTERNATE',
}

export enum ANALYTICS_TYPES {
  GET_PROFILE_STORYBOARD_ANALYTICS = 'GET_PROFILE_STORYBOARD_ANALYTICS',
  GET_PROFILE_STORYBOARD_ANALYTICS_SUCCEESS = 'GET_PROFILE_STORYBOARD_ANALYTICS_SUCCEESS',
  GET_PROFILE_STORYBOARD_ANALYTICS_FAILED = 'GET_PROFILE_STORYBOARD_ANALYTICS_FAILED',
  GET_HOME_ANALYTICS = 'GET_HOME_ANALYTICS',
  GET_HOME_ANALYTICS_SUCCESS = 'GET_HOME_ANALYTICS_SUCCESS',
  GET_HOME_ANALYTICS_FAILED = 'GET_HOME_ANALYTICS_FAILED',
  GET_STORYBOARD_ANALYTICS = 'GET_STORYBOARD_ANALYTICS',
  GET_STORYBOARD_ANALYTICS_SUCCESS = 'GET_STORYBOARD_ANALYTICS_SUCCESS',
  GET_STORYBOARD_ANALYTICS_FAILED = 'GET_STORYBOARD_ANALYTICS_FAILED',
  CLEAR_STORYBOARD_ANALYTICS = 'CLEAR_STORYBOARD_ANALYTICS',
  GET_MAGAZINE_ANALYTICS = 'GET_MAGAZINE_ANALYTICS',
  GET_MAGAZINE_ANALYTICS_SUCCESS = 'GET_MAGAZINE_ANALYTICS_SUCCESS',
  GET_MAGAZINE_ANALYTICS_FAILED = 'GET_MAGAZINE_ANALYTICS_FAILED',
  CLEAR_MAGAZINE_ANALYTICS = 'CLEAR_MAGAZINE_ANALYTICS',
}

export enum ARTICLES_TYPES {
  GET_ARTICLE = 'GET_ARTICLE',
  GET_ARTICLE_SUCCEEDED = 'GET_ARTICLE_SUCCEEDED',
  GET_ARTICLE_FAILED = 'GET_ARTICLE_FAILED',
  GET_ARTICLE_RELATED_SECTION_SUCCEEDED = 'GET_ARTICLE_RELATED_SECTION_SUCCEEDED',
  GET_ARTICLE_LIKES_SUCCEEDED = 'GET_ARTICLE_LIKES_SUCCEEDED',
  SET_VIDEO_ARTICLE_ID_HACK = 'SET_VIDEO_ARTICLE_ID_HACK',
}

export enum AUTH_TYPES {
  GET_AUTH_TOKEN = 'GET_AUTH_TOKEN',
  FRICTIONLESS_SET_UID = 'FRICTIONLESS_SET_UID',
  AUTH_FORM_CHANGE = 'AUTH_FORM_CHANGE',
  LOGIN_PENDING = 'LOGIN_PENDING',
  SIGNUP_PENDING = 'SIGNUP_PENDING',
  SIGNUP_FAILURE = 'SIGNUP_FAILURE',
  LOGIN_FAILURE = 'LOGIN_FAILURE',
  CONNECT_WITH_SSO_PENDING = 'CONNECT_WITH_SSO_PENDING',
  CONNECT_WITH_SSO_FAILURE = 'CONNECT_WITH_SSO_FAILURE',
  GET_PASSWORD_STRENGTH_PENDING = 'GET_PASSWORD_STRENGTH_PENDING',
  GET_PASSWORD_STRENGTH_SUCCESS = 'GET_PASSWORD_STRENGTH_SUCCESS',
  RESET_AUTH_FORM = 'RESET_AUTH_FORM',
  SET_SIGNUP_COMPLETED = 'SET_SIGNUP_COMPLETED',
  SET_AUTH_FORM_LOADING = 'SET_AUTH_FORM_LOADING',
  LOGOUT = 'LOGOUT',
}

export enum CAMPAIGN_TYPES {
  GET_CAMPAIGNS = 'CAMPAIGN_GET_CAMPAIGNS',
  GET_CAMPAIGNS_SUCCESS = 'CAMPAIGN_ GET_CAMPAIGNS_SUCCESS',
  GET_CAMPAIGNS_FAILED = 'CAMPAIGN_ GET_CAMPAIGNS_FAILED',
}

export enum COMMENT_TYPES {
  GET_COMMENTS = 'GET_COMMENTS',
  GET_COMMENTS_SUCCEEDED = 'GET_COMMENTS_SUCCEEDED',
  GET_COMMENTS_FAILED = 'GET_COMMENTS_FAILED',
  REMOVE_COMMENT = 'REMOVE_COMMENT',
  RESET_COMMENTS = 'RESET_COMMENTS',
  APPEND_NEW_COMMENT = 'APPEND_NEW_COMMENT',
  NEW_COMMENT_BUMP_COMMENT_COUNT = 'NEW_COMMENT_BUMP_COMMENT_COUNT',
  REMOVE_BLOCKED_USER_COMMENTS = 'REMOVE_BLOCKED_USER_COMMENTS',
}

export enum BLOCKING_TYPES {
  GET_BLOCKED_PROFILES_PENDING = 'GET_BLOCKED_PROFILES_PENDING',
  GET_BLOCKED_PROFILES_SUCCESS = 'GET_BLOCKED_PROFILES_SUCCESS',
  GET_BLOCKED_PROFILES_FAILED = 'GET_BLOCKED_PROFILES_FAILED',
}

export enum CONTENT_TYPES {
  GET_EDITORIAL_BOARDS = 'GET_EDITORIAL_BOARDS',
  GET_EDITORIAL_BOARDS_SUCCEEDED = 'GET_EDITORIAL_BOARDS_SUCCEEDED',
  GET_RECOMMENDED_BOARDS_PENDING = 'GET_RECOMMENDED_BOARDS_PENDING',
  GET_RECOMMENDED_BOARDS_SUCCESS = 'GET_RECOMMENDED_BOARDS_SUCCESS',
  GET_RECOMMENDED_BOARDS_FAILED = 'GET_RECOMMENDED_BOARDS_FAILED',
  GET_CONTENT_GUIDE = 'GET_CONTENT_GUIDE',
  GET_CONTENT_GUIDE_SUCCEEDED = 'GET_CONTENT_GUIDE_SUCCEEDED',
  GET_CONTENT_GUIDE_FAILED = 'GET_CONTENT_GUIDE_FAILED',
  SET_SELECTED_CONTENT_GUIDE_SECTION = 'SET_SELECTED_CONTENT_GUIDE_SECTION',
  SET_CONTEXTUAL_ONBOARDING_FOLLOW_SECTIONS = 'SET_CONTEXTUAL_ONBOARDING_FOLLOW_SECTIONS',
}

export enum CURATOR_PRO_MAGAZINE_TYPES {
  GET_CURATOR_PRO_MAGAZINE = 'GET_CURATOR_PRO_MAGAZINE',
  GET_CURATOR_PRO_MAGAZINE_SUCCESS = 'GET_CURATOR_PRO_MAGAZINE_SUCCESS',
  GET_CURATOR_PRO_MAGAZINE_FAILED = 'GET_CURATOR_PRO_MAGAZINE_FAILED',
  UPDATE_CURATOR_PRO_MAGAZINE = 'UPDATE_CURATOR_PRO_MAGAZINE',
  UPDATE_CURATOR_PRO_MAGAZINE_SUCCESS = 'UPDATE_CURATOR_PRO_MAGAZINE_SUCCESS',
  UPDATE_CURATOR_PRO_MAGAZINE_FAILED = 'UPDATE_CURATOR_PRO_MAGAZINE_FAILED',
  UPDATE_CURATOR_PRO_MAGAZINE_COVER = 'UPDATE_CURATOR_PRO_MAGAZINE_COVER',
  UPDATE_CURATOR_PRO_MAGAZINE_COVER_SUCCESS = 'UPDATE_CURATOR_PRO_MAGAZINE_COVER_SUCCESS',
  UPDATE_CURATOR_PRO_MAGAZINE_COVER_FAILED = 'UPDATE_CURATOR_PRO_MAGAZINE_COVER_FAILED',
  REMOVE_CURATOR_PRO_MAGAZINE_CONTRIBUTOR = 'REMOVE_CURATOR_PRO_MAGAZINE_CONTRIBUTOR',
  REMOVE_CURATOR_PRO_MAGAZINE_CONTRIBUTOR_SUCCESS = 'REMOVE_CURATOR_PRO_MAGAZINE_CONTRIBUTOR_SUCCESS',
  REMOVE_CURATOR_PRO_MAGAZINE_CONTRIBUTOR_FAILED = 'REMOVE_CURATOR_PRO_MAGAZINE_CONTRIBUTOR_FAILED',
  MOVE_CURATOR_PRO_MAGAZINE_ITEM = 'MOVE_CURATOR_PRO_MAGAZINE_ITEM',
  MOVE_CURATOR_PRO_MAGAZINE_ITEM_SUCCESS = 'MOVE_CURATOR_PRO_MAGAZINE_ITEM_SUCCESS',
  MOVE_CURATOR_PRO_MAGAZINE_ITEM_FAILED = 'MOVE_CURATOR_PRO_MAGAZINE_ITEM_FAILED',
  REMOVE_CURATOR_PRO_MAGAZINE_ITEM = 'REMOVE_CURATOR_PRO_MAGAZINE_ITEM',
  REMOVE_CURATOR_PRO_MAGAZINE_ITEM_SUCCESS = 'REMOVE_CURATOR_PRO_MAGAZINE_ITEM_SUCCESS',
  REMOVE_CURATOR_PRO_MAGAZINE_ITEM_FAILED = 'REMOVE_CURATOR_PRO_MAGAZINE_ITEM_FAILED',
  GET_CURATOR_PRO_MAGAZINE_NEXT_ITEMS = 'GET_CURATOR_PRO_MAGAZINE_NEXT_ITEMS',
  GET_CURATOR_PRO_MAGAZINE_NEXT_ITEMS_SUCCESS = 'GET_CURATOR_PRO_MAGAZINE_NEXT_ITEMS_SUCCESS',
  GET_CURATOR_PRO_MAGAZINE_NEXT_ITEMS_FAILED = 'GET_CURATOR_PRO_MAGAZINE_NEXT_ITEMS_FAILED',
  CUSTOMIZE_CURATOR_PRO_MAGAZINE_ITEM = 'CUSTOMIZE_CURATOR_PRO_MAGAZINE_ITEM',
  CUSTOMIZE_CURATOR_PRO_MAGAZINE_ITEM_SUCCESS = 'CUSTOMIZE_CURATOR_PRO_MAGAZINE_ITEM_SUCCESS',
  CUSTOMIZE_CURATOR_PRO_MAGAZINE_ITEM_FAILED = 'CUSTOMIZE_CURATOR_PRO_MAGAZINE_ITEM_FAILED',
  FLIP_CURATOR_PRO_MAGAZINE_URL = 'FLIP_CURATOR_PRO_MAGAZINE_URL',
  FLIP_CURATOR_PRO_MAGAZINE_URL_SUCCESS = 'FLIP_CURATOR_PRO_MAGAZINE_URL_SUCCESS',
  FLIP_CURATOR_PRO_MAGAZINE_URL_FAILED = 'FLIP_CURATOR_PRO_MAGAZINE_URL_FAILED',
  DELETE_CURATOR_PRO_MAGAZINE = 'DELETE_CURATOR_PRO_MAGAZINE',
  DELETE_CURATOR_PRO_MAGAZINE_SUCCESS = 'DELETE_CURATOR_PRO_MAGAZINE_SUCCESS',
  DELETE_CURATOR_PRO_MAGAZINE_FAILED = 'DELETE_CURATOR_PRO_MAGAZINE_FAILED',
  ADD_CURATOR_PRO_MAGAZINE_PIPE = 'ADD_CURATOR_PRO_MAGAZINE_PIPE',
  ADD_CURATOR_PRO_MAGAZINE_PIPE_SUCCESS = 'ADD_CURATOR_PRO_MAGAZINE_PIPE_SUCCESS',
  ADD_CURATOR_PRO_MAGAZINE_PIPE_FAILED = 'ADD_CURATOR_PRO_MAGAZINE_PIPE_FAILED',
  DELETE_CURATOR_PRO_MAGAZINE_PIPE = 'DELETE_CURATOR_PRO_MAGAZINE_PIPE',
  DELETE_CURATOR_PRO_MAGAZINE_PIPE_SUCCESS = 'DELETE_CURATOR_PRO_MAGAZINE_PIPE_SUCCESS',
  DELETE_CURATOR_PRO_MAGAZINE_PIPE_FAILED = 'DELETE_CURATOR_PRO_MAGAZINE_PIPE_FAILED',
}

export enum CURATOR_PRO_STORYBOARD_TYPES {
  GET_CURATOR_PRO_STORYBOARDS = 'GET_CURATOR_PRO_STORYBOARDS',
  GET_CURATOR_PRO_STORYBOARDS_SUCCESS = 'GET_CURATOR_PRO_STORYBOARDS_SUCCESS',
  GET_CURATOR_PRO_STORYBOARDS_FAILED = 'GET_CURATOR_PRO_STORYBOARDS_FAILED',
  GET_CURATOR_PRO_DRAFT_STORYBOARDS = 'GET_CURATOR_PRO_DRAFT_STORYBOARDS',
  GET_CURATOR_PRO_DRAFT_STORYBOARDS_SUCCESS = 'GET_CURATOR_PRO_DRAFT_STORYBOARDS_SUCCESS',
  GET_CURATOR_PRO_DRAFT_STORYBOARDS_FAILED = 'GET_CURATOR_PRO_DRAFT_STORYBOARDS_FAILED',
  GET_CURATOR_PRO_STORYBOARD = 'GET_CURATOR_PRO_STORYBOARD',
  GET_CURATOR_PRO_STORYBOARD_SUCCESS = 'GET_CURATOR_PRO_STORYBOARD_SUCCESS',
  GET_CURATOR_PRO_STORYBOARD_FAILED = 'GET_CURATOR_PRO_STORYBOARD_FAILED',
  CREATE_CURATOR_PRO_STORYBOARD = 'CREATE_CURATOR_PRO_STORYBOARD',
  CREATE_CURATOR_PRO_STORYBOARD_SUCCESS = 'CREATE_CURATOR_PRO_STORYBOARD_SUCCESS',
  CREATE_CURATOR_PRO_STORYBOARD_FAILED = 'CREATE_CURATOR_PRO_STORYBOARD_FAILED',
  UPDATE_CURATOR_PRO_STORYBOARD = 'UPDATE_CURATOR_PRO_STORYBOARD',
  UPDATE_CURATOR_PRO_STORYBOARD_SUCCESS = 'UPDATE_CURATOR_PRO_STORYBOARD_SUCCESS',
  UPDATE_CURATOR_PRO_STORYBOARD_FAILED = 'UPDATE_CURATOR_PRO_STORYBOARD_FAILED',
  DELETE_CURATOR_PRO_STORYBOARD = 'DELETE_CURATOR_PRO_STORYBOARD',
  DELETE_CURATOR_PRO_STORYBOARD_SUCCESS = 'DELETE_CURATOR_PRO_STORYBOARD_SUCCESS',
  DELETE_CURATOR_PRO_STORYBOARD_FAILED = 'DELETE_CURATOR_PRO_STORYBOARD_FAILED',
  PUBLISH_CURATOR_PRO_STORYBOARD = 'PUBLISH_CURATOR_PRO_STORYBOARD',
  PUBLISH_CURATOR_PRO_STORYBOARD_SUCCESS = 'PUBLISH_CURATOR_PRO_STORYBOARD_SUCCESS',
  PUBLISH_CURATOR_PRO_STORYBOARD_FAILED = 'PUBLISH_CURATOR_PRO_STORYBOARD_FAILED',
  CLONE_CURATOR_PRO_STORYBOARD = 'CLONE_CURATOR_PRO_STORYBOARD',
  CLONE_CURATOR_PRO_STORYBOARD_SUCCESS = 'CLONE_CURATOR_PRO_STORYBOARD_SUCCESS',
  CLONE_CURATOR_PRO_STORYBOARD_FAILED = 'CLONE_CURATOR_PRO_STORYBOARD_FAILED',
  INSERT_CURATOR_PRO_STORYBOARD_ITEM = 'INSERT_CURATOR_PRO_STORYBOARD_ITEM',
  INSERT_CURATOR_PRO_STORYBOARD_ITEM_SUCCESS = 'INSERT_CURATOR_PRO_STORYBOARD_ITEM_SUCCESS',
  INSERT_CURATOR_PRO_STORYBOARD_ITEM_FAILED = 'INSERT_CURATOR_PRO_STORYBOARD_ITEM_FAILED',
  UPDATE_CURATOR_PRO_STORYBOARD_ITEM = 'UPDATE_CURATOR_PRO_STORYBOARD_ITEM',
  UPDATE_CURATOR_PRO_STORYBOARD_ITEM_SUCCESS = 'UPDATE_CURATOR_PRO_STORYBOARD_ITEM_SUCCESS',
  UPDATE_CURATOR_PRO_STORYBOARD_NESTED_ITEM_SUCCESS = 'UPDATE_CURATOR_PRO_STORYBOARD_NESTED_ITEM_SUCCESS',
  UPDATE_CURATOR_PRO_STORYBOARD_ITEM_FAILED = 'UPDATE_CURATOR_PRO_STORYBOARD_ITEM_FAILED',
  DELETE_CURATOR_PRO_STORYBOARD_ITEM = 'DELETE_CURATOR_PRO_STORYBOARD_ITEM',
  DELETE_CURATOR_PRO_STORYBOARD_ITEM_SUCCESS = 'DELETE_CURATOR_PRO_STORYBOARD_ITEM_SUCCESS',
  DELETE_CURATOR_PRO_STORYBOARD_ITEM_FAILED = 'DELETE_CURATOR_PRO_STORYBOARD_ITEM_FAILED',
  CREATE_CURATOR_PRO_STORYBOARD_NESTED_ITEM = 'CREATE_CURATOR_PRO_STORYBOARD_NESTED_ITEM',
  CREATE_CURATOR_PRO_STORYBOARD_NESTED_ITEM_SUCCESS = 'CREATE_CURATOR_PRO_STORYBOARD_NESTED_ITEM_SUCCESS',
  CREATE_CURATOR_PRO_STORYBOARD_NESTED_ITEM_FAILED = 'CREATE_CURATOR_PRO_STORYBOARD_NESTED_ITEM_FAILED',
  MOVE_CURATOR_PRO_STORYBOARD_ITEM = 'MOVE_CURATOR_PRO_STORYBOARD_ITEM',
  MOVE_CURATOR_PRO_STORYBOARD_ITEM_SUCCESS = 'MOVE_CURATOR_PRO_STORYBOARD_ITEM_SUCCESS',
  MOVE_CURATOR_PRO_STORYBOARD_ITEM_FAILED = 'MOVE_CURATOR_PRO_STORYBOARD_ITEM_FAILED',
  GET_CURATOR_PRO_STORYBOARD_FEED = 'GET_CURATOR_PRO_STORYBOARD_FEED',
  GET_CURATOR_PRO_STORYBOARD_FEED_SUCCESS = 'GET_CURATOR_PRO_STORYBOARD_FEED_SUCCESS',
  GET_CURATOR_PRO_STORYBOARD_FEED_FAILED = 'GET_CURATOR_PRO_STORYBOARD_FEED_FAILED',
  GET_CURATOR_PRO_STORYBOARD_FEED_ITEM = 'GET_CURATOR_PRO_STORYBOARD_FEED_ITEM',
  GET_CURATOR_PRO_STORYBOARD_FEED_ITEM_SUCCESS = 'GET_CURATOR_PRO_STORYBOARD_FEED_ITEM_SUCCESS',
  SET_CURATOR_PRO_SCHEDULE = 'SET_CURATOR_PRO_SCHEDULE',
  SET_CURATOR_PRO_SCHEDULE_SUCCESS = 'SET_CURATOR_PRO_SCHEDULE_SUCCESS',
  SET_CURATOR_PRO_SCHEDULE_FAILED = 'SET_CURATOR_PRO_SCHEDULE_FAILED',
  DELETE_CURATOR_PRO_SCHEDULE = 'DELETE_CURATOR_PRO_SCHEDULE',
  DELETE_CURATOR_PRO_SCHEDULE_SUCCESS = 'DELETE_CURATOR_PRO_SCHEDULE_SUCCESS',
  DELETE_CURATOR_PRO_SCHEDULE_FAILED = 'DELETE_CURATOR_PRO_SCHEDULE_FAILED',
}

export enum FEATURE_FLAG_TYPES {
  SET = 'SET_FEATURE_FLAGS',
}

export enum FLIP_MODE_TYPES {
  SET_ENABLED = 'SET_ENABLED',
  SET_TARGET_REMOTE_ID = 'SET_TARGET_REMOTE_ID',
}

export enum MAGAZINE_TYPES {
  MAGAZINE_FLIP_PENDING = 'MAGAZINE_FLIP_PENDING',
  MAGAZINE_FLIP_SUCCESS = 'MAGAZINE_FLIP_SUCCESS',
  MAGAZINE_FLIP_FAILED = 'MAGAZINE_FLIP_FAILED',
  CREATE_MAGAZINE_PENDING = 'CREATE_MAGAZINE_PENDING',
  CREATE_MAGAZINE_SUCCESS = 'CREATE_MAGAZINE_SUCCESS',
  CREATE_MAGAZINE_FAILED = 'CREATE_MAGAZINE_FAILED',
  MAGAZINE_SAVE_STATE_RESET = 'MAGAZINE_SAVE_STATE_RESET',
  EDIT_MAGAZINE_PENDING = 'EDIT_MAGAZINE_PENDING',
  EDIT_MAGAZINE_SUCCESS = 'EDIT_MAGAZINE_SUCCESS',
  EDIT_MAGAZINE_FAILED = 'EDIT_MAGAZINE_FAILED',
  DELETE_MAGAZINE_PENDING = 'DELETE_MAGAZINE_PENDING',
  DELETE_MAGAZINE_SUCCESS = 'DELETE_MAGAZINE_SUCCESS',
  DELETE_MAGAZINE_FAILED = 'DELETE_MAGAZINE_FAILED',
  CREATE_MAGAZINE_PIPE = 'CREATE_MAGAZINE_PIPE',
  CREATE_MAGAZINE_PIPE_SUCCESS = 'CREATE_MAGAZINE_PIPE_SUCCESS',
  CREATE_MAGAZINE_PIPE_FAILED = 'CREATE_MAGAZINE_PIPE_FAILED',
  REMOVE_ITEM_FROM_MAGAZINE_PENDING = 'REMOVE_ITEM_FROM_MAGAZINE_PENDING',
  REMOVE_ITEM_FROM_MAGAZINE_SUCCESS = 'REMOVE_ITEM_FROM_MAGAZINE_SUCCESS',
  REMOVE_ITEM_FROM_MAGAZINE_FAILED = 'REMOVE_ITEM_FROM_MAGAZINE_FAILED',
}

export enum MAGAZINE_INVITE_TYPES {
  SET_MAGAZINE_INVITE_TOKEN = 'SET_MAGAZINE_INVITE_TOKEN',
  SET_MAGAZINE_INVITE_SECTION = 'SET_MAGAZINE_INVITE_SECTION',
  SET_INVITE_VALIDATION_ERRORS = 'SET_INVITE_VALIDATION_ERRORS',
  INVITE_MAGAZINE_ACCEPT_LOADING = 'INVITE_MAGAZINE_ACCEPT_LOADING',
}

export enum MAGAZINE_SUBSCRIPTION_TYPES {
  SET_MAGAZINE_SUBSCRIPTIONS_LOADED = 'SET_MAGAZINE_SUBSCRIPTIONS_LOADED',
  SET_MAGAZINE_SUBSCRIPTIONS_LOADING = 'SET_MAGAZINE_SUBSCRIPTIONS_LOADING',
  SET_MAGAZINE_SUBSCRIPTIONS = 'SET_MAGAZINE_SUBSCRIPTIONS',
}

export enum MODAL_TYPES {
  SHOW_MODAL = 'SHOW_MODAL',
  DISMISS_MODAL = 'DISMISS_MODAL',
  DISMISS_SPECIFIC_MODAL = 'DISMISS_SPECIFIC_MODAL',
  DISMISS_ALL_MODALS = 'DISMISS_ALL_MODALS',
  SET_MODAL_LOGIN_COMPLETE = 'SET_MODAL_LOGIN_COMPLETE',
}

export enum MUTING_TYPES {
  MUTE_AUTHOR_SUCCEEDED = 'MUTE_AUTHOR_SUCCEEDED',
  UNMUTE_AUTHOR_SUCCEEDED = 'UNMUTE_AUTHOR_SUCCEEDED',
  MUTE_SOURCE_DOMAIN_SUCCEEDED = 'MUTE_SOURCE_DOMAIN_SUCCEEDED',
  UNMUTE_SOURCE_DOMAIN_SUCCEEDED = 'UNMUTE_SOURCE_DOMAIN_SUCCEEDED',
  UPDATE_MUTE_ITEM = 'UPDATE_MUTE_ITEM',
  UPDATE_MUTE_ITEM_FAILED = 'UPDATE_MUTE_ITEM_FAILED',
  UPDATE_MUTE_ITEM_SUCCEEDED = 'UPDATE_MUTE_ITEM_SUCCEEDED',
  UPDATE_MUTED_CONTENTS = 'UPDATE_MUTED_CONTENTS',
  LESS_LIKE_ITEM = 'LESS_LIKE_ITEM',
  LESS_LIKE_ITEM_SUCCEEDED = 'LESS_LIKE_ITEM_SUCCEEDED',
  LESS_LIKE_ITEM_FAILED = 'LESS_LIKE_ITEM_FAILED',
}

export enum NEWSLETTERS_LANDING_TYPES {
  GET_NEWSLETTERS_SIGNUP_CONFIGURATION_SUCCESS = 'GET_NEWSLETTERS_SIGNUP_CONFIGURATION_SUCCESS',
}

export enum NOTIFICATION_TYPES {
  GET_NOTIFICATIONS = 'GET_NOTIFICATIONS',
  GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS',
  SET_NOTIFICATIONS_ENTRIES = 'SET_NOTIFICATIONS_ENTRIES',
  SET_NOTIFICATIONS_UNREAD_COUNT = 'SET_NOTIFICATIONS_UNREAD_COUNT',
  SET_NOTIFICATIONS_READ = 'SET_NOTIFICATIONS_READ',
  SET_NOTIFICATIONS_GROUP = 'SET_NOTIFICATIONS_GROUP',
}

export enum PROFILE_TYPES {
  GET_PROFILE_PENDING = 'GET_PROFILE_PENDING',
  GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS',
  GET_PROFILE_INFO = 'GET_PROFILE_INFO',
  GET_PROFILE_INFO_SUCCESS = 'GET_PROFILE_INFO_SUCCESS',
  UPDATE_FOLLOWING_IDS = 'UPDATE_FOLLOWING_IDS',
  GET_PROFILE_UNAUTHORIZED = 'GET_PROFILE_UNAUTHORIZED',
  GET_PROFILE_SMART_MAGAZINES_SUCCESS = 'GET_PROFILE_SMART_MAGAZINES_SUCCESS',
  GET_PROFILE_COMMUNITY_GROUPS_SUCCESS = 'GET_PROFILE_COMMUNITY_GROUPS_SUCCESS',
  GET_PROFILE_COMMUNITY_GROUPS_FAILED = 'GET_PROFILE_COMMUNITY_GROUPS_FAILED',
  GET_PROFILE_FOLLOWERS = 'GET_PROFILE_FOLLOWERS',
  GET_PROFILE_FOLLOWERS_SUCCESS = 'GET_PROFILE_FOLLOWERS_SUCCESS',
  GET_PROFILE_FOLLOWERS_FAILED = 'GET_PROFILE_FOLLOWERS_FAILED',
  GET_PROFILE_STATS_SUCCESS = 'GET_PROFILE_STATS_SUCCESS',
  GET_PROFILE_STATS_FAILED = 'GET_PROFILE_STATS_FAILED',
  GET_FAVORITES_SUCCESS = 'GET_FAVORITES_SUCCESS',
  GET_FAVORITES_FAILED = 'GET_FAVORITES_FAILED',
  CREATE_SMART_MAGAZINE_SUCCESS = 'CREATE_SMART_MAGAZINE_SUCCESS',
  CREATE_SMART_MAGAZINE_FAILED = 'CREATE_SMART_MAGAZINE_FAILED',
  UPDATE_SMART_MAGAZINE_SUCCESS = 'UPDATE_SMART_MAGAZINE_SUCCESS',
  UPDATE_SMART_MAGAZINE_FAILED = 'UPDATE_SMART_MAGAZINE_FAILED',
  DELETE_SMART_MAGAZINE_SUCCESS = 'DELETE_SMART_MAGAZINE_SUCCESS',
  DELETE_SMART_MAGAZINE_FAILED = 'DELETE_SMART_MAGAZINE_FAILED',
  GET_SMART_MAGAZINES_COVERS_SUCCESS = 'GET_SMART_MAGAZINES_COVERS_SUCCESS',
  GET_SMART_MAGAZINES_COVERS_FAILED = 'GET_SMART_MAGAZINES_COVERS_FAILED',
  ADD_CAROUSEL_FAVORITE_PENDING = 'ADD_CAROUSEL_FAVORITE_PENDING',
  ADD_CAROUSEL_FAVORITE_SUCCESS = 'ADD_CAROUSEL_FAVORITE_SUCCESS',
  ADD_CAROUSEL_FAVORITE_FAILURE = 'ADD_CAROUSEL_FAVORITE_FAILURE',
  DELETE_CAROUSEL_FAVORITE_PENDING = 'DELETE_CAROUSEL_FAVORITE_PENDING',
  DELETE_CAROUSEL_FAVORITE_FAILURE = 'DELETE_CAROUSEL_FAVORITE_FAILURE',
  DELETE_CAROUSEL_FAVORITE_SUCCESS = 'DELETE_CAROUSEL_FAVORITE_SUCCESS',
  ADD_SMART_MAGAZINE_CAROUSEL_SUCCESS = 'ADD_SMART_MAGAZINE_CAROUSEL_SUCCESS',
  FOLLOW_SECTION = 'FOLLOW_SECTION',
  UNFOLLOW_SECTION = 'UNFOLLOW_SECTION',
  FOLLOW_SECTION_SUCCESS = 'FOLLOW_SECTION_SUCCESS',
  UNFOLLOW_SECTION_SUCCESS = 'UNFOLLOW_SECTION_SUCCESS',
  MOVE_CAROUSEL_FAVORITE_PENDING = 'MOVE_CAROUSEL_FAVORITE_PENDING',
  MOVE_CAROUSEL_FAVORITE_FAILURE = 'MOVE_CAROUSEL_FAVORITE_FAILURE',
  MOVE_CAROUSEL_FAVORITE_SUCCESS = 'MOVE_CAROUSEL_FAVORITE_SUCCESS',
  GET_FOLLOWING = 'GET_FOLLOWING',
  GET_FOLLOWING_SUCCESS = 'GET_FOLLOWING_SUCCESS',
  GET_FOLLOWING_SAMPLE_SUCCESS = 'GET_FOLLOWING_SAMPLE_SUCCESS',
  SET_RECENTLY_PERSONALIZED_TOPIC = 'SET_RECENTLY_PERSONALIZED_TOPIC',
  RESET_AUTH_FORM = 'RESET_AUTH_FORM',
}

export enum PUBLISHER_TYPES {
  GET_PUBLISHER_INFO_PENDING = 'GET_PUBLISHER_INFO_PENDING',
  GET_PUBLISHER_INFO_SUCCESS = 'GET_PUBLISHER_INFO_SUCCESS',
  GET_PUBLISHER_INFO_FAILED = 'GET_PUBLISHER_INFO_FAILED',
  GET_PUBLISHER_PIPES_PENDING = 'GET_PUBLISHER_PIPES_PENDING',
  GET_PUBLISHER_PIPES_SUCCESS = 'GET_PUBLISHER_PIPES_SUCCESS',
  GET_PUBLISHER_PIPES_FAILED = 'GET_PUBLISHER_PIPES_FAILED',
  UPDATE_PUBLISHER_STATUS_PENDING = 'UPDATE_PUBLISHER_STATUS_PENDING',
  UPDATE_PUBLISHER_STATUS_SUCCESS = 'UPDATE_PUBLISHER_STATUS_SUCCESS',
  UPDATE_PUBLISHER_STATUS_FAILED = 'UPDATE_PUBLISHER_STATUS_FAILED',
  UPDATE_PUBLISHER_PAYWALL = 'UPDATE_PUBLISHER_PAYWALL',
  UPDATE_PUBLISHER_PAYWALL_SUCCESS = 'UPDATE_PUBLISHER_PAYWALL_SUCCESS',
  UPDATE_PUBLISHER_PAYWALL_FAILED = 'UPDATE_PUBLISHER_PAYWALL_FAILED',
  CANCEL_PUBLISHER_APPLICATION_PENDING = 'CANCEL_PUBLISHER_APPLICATION_PENDING',
  CANCEL_PUBLISHER_APPLICATION_SUCCESS = 'CANCEL_PUBLISHER_APPLICATION_SUCCESS',
  CANCEL_PUBLISHER_APPLICATION_FAILED = 'CANCEL_PUBLISHER_APPLICATION_FAILED',
  CREATE_PUBLISHER_PENDING = 'CREATE_PUBLISHER_PENDING',
  CREATE_PUBLISHER_SUCCESS = 'CREATE_PUBLISHER_SUCCESS',
  CREATE_PUBLISHER_FAILED = 'CREATE_PUBLISHER_FAILED',
}

export enum QUICK_ACCESS_TYPES {
  GET_LOGGED_OUT_FEED = 'GET_LOGGED_OUT_FEED',
  GET_LOGGED_OUT_FEED_SUCCEEDED = 'GET_LOGGED_OUT_FEED_SUCCEEDED',
  GET_LOGGED_OUT_FEED_FAILED = 'GET_LOGGED_OUT_FEED_FAILED',
  SET_GROUP_PREFERENCES = 'SET_GROUP_PREFERENCES',
  GROUP_PREFERENCES_LOADED = 'GROUP_PREFERENCES_LOADED',
}

export enum SEARCH_NAV_TYPES {
  SEARCH_NAV = 'SEARCH_NAV',
  SEARCH_NAV_SUCCEEDED = 'SEARCH_NAV_SUCCEEDED',
  CLEAR_SEARCH_NAV = 'CLEAR_SEARCH_NAV',
}

export enum SEARCH_TYPES {
  SEARCH = 'SEARCH',
  SEARCH_SUCCEEDED = 'SEARCH_SUCCEEDED',
  CLEAR_SEARCH = 'CLEAR_SEARCH',
}

export enum SECTIONS_TYPES {
  GET_SECTION = 'GET_SECTION',
  GET_SECTION_ABORT = 'GET_SECTION_ABORT',
  GET_SECTION_SUCCEEDED = 'GET_SECTION_SUCCEEDED',
  GET_SECTION_FAILED = 'GET_SECTION_FAILED',
  GET_MULTIPLE_SECTIONS = 'GET_MULTIPLE_SECTIONS',
  GET_MULTIPLE_SECTIONS_SUCCEEDED = 'GET_MULTIPLE_SECTIONS_SUCCEEDED',
  GET_MULTIPLE_SECTIONS_FAILED = 'GET_MULTIPLE_SECTIONS_FAILED',
  GET_SECTION_COVERS_SUCCESS = 'GET_SECTION_COVERS_SUCCESS',
  GET_SECTION_COVERS_FAILED = 'GET_SECTION_COVERS_FAILED',
  GET_SECTION_UNAUTHORIZED = 'GET_SECTION_UNAUTHORIZED',
  GET_SECTION_FOLLOWERS = 'GET_SECTION_FOLLOWERS',
  GET_SECTION_FOLLOWERS_SUCCEEDED = 'GET_SECTION_FOLLOWERS_SUCCEEDED',
  GET_SECTION_FOLLOWERS_FAILED = 'GET_SECTION_FOLLOWERS_FAILED',
  GET_SECTION_RELATED_SECTION_SUCCEEDED = 'GET_SECTION_RELATED_SECTION_SUCCEEDED',
  GET_TOPIC_DESCRIPTIONS = 'GET_TOPIC_DESCRIPTIONS',
  GET_TOPIC_DESCRIPTIONS_SUCCEEDED = 'GET_TOPIC_DESCRIPTIONS_SUCCEEDED',
  GET_TOPIC_DESCRIPTIONS_FAILED = 'GET_TOPIC_DESCRIPTIONS_FAILED',
  GET_SMART_MAGAZINE_SUCCEEDED = 'GET_SMART_MAGAZINE_SUCCEEDED',
  GET_SMART_MAGAZINE_FAILED = 'GET_SMART_MAGAZINE_FAILED',
  GET_SECTION_LIKES_SUCCEEDED = 'GET_SECTION_LIKES_SUCCEEDED',
  ACCEPT_CONTRIBUTOR_INVITE_SUCCESS = 'ACCEPT_CONTRIBUTOR_INVITE_SUCCESS',
  ACCEPT_CONTRIBUTOR_INVITE_FAILED = 'ACCEPT_CONTRIBUTOR_INVITE_FAILED',
  PURGE_SECTION = 'PURGE_SECTION',
  SET_SECTION_STALE = 'SET_SECTION_STALE',
  SET_ACCESSED_AT = 'SET_ACCESSED_AT',
  SET_TOPICS_FOR_SECTION = 'SET_TOPICS_FOR_SECTION',
  SET_RECOMMENDED_MAGAZINES_FOR_SECTION = 'SET_RECOMMENDED_MAGAZINES_FOR_SECTION',
  SET_SECTION_EPHEMERAL = 'SET_SECTION_EPHEMERAL',
  SET_METRICS = 'SET_METRICS',
}

export enum SETTINGS_TYPES {
  SAVE_SETTINGS_PENDING = 'SAVE_SETTINGS_PENDING',
  SAVE_SETTINGS_SUCCESS = 'SAVE_SETTINGS_SUCCESS',
  SAVE_SETTINGS_FAILED = 'SAVE_SETTINGS_FAILED',
  RESET_SETTINGS_SAVE_STATE = 'RESET_SETTINGS_SAVE_STATE',
}

export enum SITEMAP_TYPES {
  LOADING = 'SITEMAP_LOADING',
  INDEX_LOADED = 'SITEMAP_INDEX_LOADED',
  GET_INDEX_FAILURE = 'SITEMAP_GET_INDEX_FAILURE',
  GET_INDEX_SUCCESS = 'SITEMAP_GET_INDEX_SUCCESS',
  GET_PAGE_FAILURE = 'SITEMAP_GET_PAGE_FAILURE',
  GET_PAGE_SUCCESS = 'SITEMAP_GET_PAGE_SUCCESS',
}

export enum SOCIAL_ACTIVITY_TYPES {
  SET_SOCIAL_ACTIVITY = 'SET_SOCIAL_ACTIVITY',
  RESET_SOCIAL_ACTIVITY = 'RESET_SOCIAL_ACTIVITY',
  LIKE_ITEM = 'LIKE_ITEM',
  LIKE_ITEM_PENDING = 'LIKE_ITEM_PENDING',
  LIKE_ITEM_SUCCEEDED = 'LIKE_ITEM_SUCCEEDED',
  LIKE_ITEM_FAILED = 'LIKE_ITEM_FAILED',
  UNLIKE_ITEM = 'UNLIKE_ITEM',
  UNLIKE_ITEM_PENDING = 'UNLIKE_ITEM_PENDING',
  UNLIKE_ITEM_SUCCEEDED = 'UNLIKE_ITEM_SUCCEEDED',
  UNLIKE_ITEM_FAILED = 'UNLIKE_ITEM_FAILED',
  ADD_POST_ONBOARDING_SOCIAL_ACTION = 'ADD_POST_ONBOARDING_SOCIAL_ACTION',
}

export enum TOAST_TYPES {
  SHOW_INFO = 'SHOW_INFO',
  SHOW_ERROR = 'SHOW_ERROR',
  DISMISS = 'DISMISS',
}

export enum USAGE_TYPES {
  USAGE_ENQUEUE_EVENT = 'USAGE_ENQUEUE_EVENT',
  USAGE_SET_PROD_TYPE = 'USAGE_SET_PROD_TYPE',
  USAGE_SET_SESSION = 'USAGE_SET_SESSION',
  USAGE_SET_REFERRER = 'USAGE_SET_REFERRER',
  USAGE_SET_NAV_FROM = 'USAGE_SET_NAV_FROM',
  USAGE_ENABLE_TRACKING = 'USAGE_ENABLE_TRACKING',
  USAGE_SET_FLURL = 'USAGE_SET_FLURL',
  USAGE_EMPTY_QUEUE = 'USAGE_EMPTY_QUEUE',
  USAGE_SET_SECTION_HEARTBEAT_TIMER_ID = 'USAGE_SET_SECTION_HEARTBEAT_TIMER_ID',
  USAGE_SET_SECTION_HEARTBEAT = 'USAGE_SET_SECTION_HEARTBEAT',
  USAGE_UPDATE_SECTION_HEARTBEAT = 'USAGE_UPDATE_SECTION_HEARTBEAT',
  USAGE_SET_NAV_FROM_SECTION = 'USAGE_SET_NAV_FROM_SECTION',
  USAGE_SET_MOBILE_GATE_ARTICLE = 'USAGE_SET_MOBILE_GATE_ARTICLE',
  USAGE_SET_MOBILE_GATE_SECTION = 'USAGE_SET_MOBILE_GATE_SECTION',
  USAGE_DEVELOPMENT_EVENT_LOG = 'USAGE_DEVELOPMENT_EVENT_LOG',
  USAGE_SET_IS_INTEGRATION = 'USAGE_SET_IS_INTEGRATION',
  USAGE_UPDATE_SECTION_DISPLAYED_ITEMS = 'USAGE_UPDATE_SECTION_DISPLAYED_ITEMS',
}

export enum USER_FEATURE_TYPES {
  SET_USER_FEATURES = 'SET_USER_FEATURES',
}

export enum SHARED_LINK_TYPES {
  SET_SHARED_LINK_DATA = 'SET_SHARED_LINK_DATA',
}

export enum ONBOARDING_TYPES {
  RESET_ONBOARDING = 'RESET_ONBOARDING',
  SET_ONBOARDING_FLOW = 'SET_ONBOARDING_FLOW',
  SET_CURRENT_ONBOARDING_STEP = 'SET_CURRENT_ONBOARDING_STEP',
  SET_ONBOARDING_DATA = 'SET_ONBOARDING_DATA',
  SET_ONBOARDING_SUCCESS = 'SET_ONBOARDING_SUCCESS',
  SET_ONBOARDING_PENDING = 'SET_ONBOARDING_PENDING',
}
