import { styleObject } from 'Style/type-helpers';

export const buttonActiveStateShadow = styleObject({
  boxShadow: 'inset -1px 2px 1px rgba(var(--color--static-black), 0.2)',
});

export const overflowWordBreak = styleObject({
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
});

export const disabledState = styleObject({
  opacity: '0.3',
  cursor: 'not-allowed',
  // keeps: active;
  pointerEvents: 'none',
});
