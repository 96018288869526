import { Breadcrumbs } from 'Utils/content/structured-data';
import { getMagazineAnalyticsUrl } from 'Utils/content/flipboard-urls';

const analyticsRoot = '/analytics';

export default ({ analyticsMagazine }) => {
  if (!analyticsMagazine || !analyticsMagazine.isMagazine) {
    return null;
  }

  const breadcrumbs = new Breadcrumbs();
  breadcrumbs.add('Analytics', analyticsRoot);

  const username =
    analyticsMagazine.author && analyticsMagazine.author.authorUsername;
  const id = analyticsMagazine.remoteid;
  if (username && id) {
    breadcrumbs.add(
      analyticsMagazine.title,
      getMagazineAnalyticsUrl(username, id),
    );
  }

  return breadcrumbs;
};
