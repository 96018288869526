/* eslint-env node */
import { factory as flaxiosFactory } from 'Utils/flaxios';
import qs from 'Utils/qs';
import { safeURL } from 'Utils/url';
import Config from 'Config';
import ApiHelpers from 'Utils/api/helpers';

const API_SERVICE = 'FLAVOUR';

const baseURL = (host) => `${host}/api`;

export const flavourFactory = () => {
  const flavour = flaxiosFactory(
    {
      baseURL: baseURL(Config.FLAVOUR_INTERACTIVE_URL),
      timeout: 30000,
      headers: {
        'Content-Type': 'application/json',
      },
    },
    false,
  );

  flavour.defaults.paramsSerializer = (params) =>
    qs.stringify(params, { arrayFormat: 'repeat' });

  flavour.interceptors.request.use((config) => {
    config.metadata = { startTime: new Date() };
    // for whatever reason, audience only available on notifications
    if (config.url.indexOf('/v1/audience') === 0) {
      config.baseURL = baseURL(Config.FLAVOUR_NOTIFICATIONS_URL);
      config.timeout = 30000; // Flavour audience request can sometimes be slow
    }
    config.headers.Host = safeURL(config.baseURL)?.host;
    return config;
  });

  flavour.interceptors.response.use(
    // there is an XSS error in flavour 404 pages,
    // don't proxy back 404 page output
    // see: http://fllb01-internal.service.flipboard.com:1036/api/v2/section/topics?section_id=flipboard/topic%252Fkenosha%27%22()%26%25%3Ctrz%3E%3CScRiPt%20%3Ealert(document.cookie)%3C/ScRiPt%3E
    (response) => response,
    (error) => {
      if (
        error?.response?.status === 404 &&
        typeof error?.response?.data === 'string'
      ) {
        error.response.data = '';
      }
      return error;
    },
  );

  flavour.interceptors.response.use(
    (response) => ApiHelpers.interceptResponseHandler(response, API_SERVICE),
    (error) => ApiHelpers.interceptResponseErrorHandler(error, API_SERVICE),
  );

  return flavour;
};

export default flavourFactory();
