import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  HashLink as RouterLink,
  NavHashLink as NavRouterLink,
} from 'react-router-hash-link';
import Config from 'Config';

// Libs
import { GA } from 'Utils/analytics';
import { isAbsoluteUrl, getPathAndSearch } from 'Utils/url';
import { isExternalUrl } from 'Utils/content/flipboard-urls';
import getWindow from 'Utils/get-window';
import { storeVisitedExternalUrl } from 'Utils/history';
import ObjectUtil from 'Utils/object-util';

// Components
import { NavFromContext } from 'ComponentLibrary/context';

const Link = React.forwardRef(
  (
    {
      history,
      activeClassName,
      activeStyle,
      openInNewWindow,
      buttonName,
      hash,
      staticContext: _staticContext, // props from hocs that are unused
      navFromEventName: _navFromEventName,
      match: _match,
      location: _location,
      noSameUrlLinks,
      isAmp,
      gaTarget,
      disableAnchorLink,
      stopPropagation,
      ...linkProps // everything else, props for links
    },
    ref,
  ) => {
    // React Router Link does not like absolute links
    const absoluteUrl = isAbsoluteUrl(linkProps.href);
    const shouldOpenNewWindow =
      openInNewWindow !== undefined
        ? openInNewWindow
        : isExternalUrl(linkProps.href);
    const linkType = GA.linkType(absoluteUrl, shouldOpenNewWindow);

    // Add data-vars for AMP Analytics support
    linkProps.className = linkProps.className
      ? `${linkProps.className} ${linkType}`
      : linkType;
    linkProps['data-vars-anchor-link'] = linkProps.href;
    // If Link is a button, add properties needed by AMP Analytics
    if (buttonName) {
      linkProps['data-vars-button-name'] = buttonName;
      linkProps['data-ga-target'] = 'button';
    }

    const url = linkProps.href;

    // If href points to where we currently are, don't render an anchor
    if (
      noSameUrlLinks &&
      url.replace(Config.FLIPBOARD_URL, '') === _location.pathname
    ) {
      const SPAN_INCLUDE_PROPS = ['className', 'children'];
      const spanProps = ObjectUtil.pick(linkProps, SPAN_INCLUDE_PROPS);
      return <span {...spanProps} />;
    }

    return (
      <NavFromContext.Consumer>
        {(navFrom) => {
          // Create a handler for link clicks, for analytics

          const handleClick = async (e) => {
            if (disableAnchorLink) {
              e.preventDefault();
            }
            if (stopPropagation) {
              e.stopPropagation();
            }
            if (absoluteUrl && !shouldOpenNewWindow) {
              // Modify the history so we can detect when a user
              // navigates to Auth and then uses the browser "back"
              // button to return
              storeVisitedExternalUrl(history, url);
            }

            GA.trackClickLink(linkType, url, gaTarget, navFrom);
            if (linkProps.onClick) {
              // ensure async onclick is handled before redirecting
              const shouldRedirect = absoluteUrl && !shouldOpenNewWindow;
              if (shouldRedirect) {
                e.preventDefault();
              }
              await linkProps.onClick(e);
              if (shouldRedirect) {
                getWindow().location = url;
              }
            }
          };

          if (shouldOpenNewWindow && !isAmp) {
            const rel = 'noopener nofollow';
            Object.assign(linkProps, {
              target: '_blank',
              rel,
            });
          }

          if (shouldOpenNewWindow || absoluteUrl) {
            return <a {...linkProps} onClick={handleClick} role="link" />;
          }

          const to = getPathAndSearch(linkProps.href);

          if (hash) {
            to.hash = hash;
          }

          // Decorate router history state for tracking navigation
          const routerToState = {};
          const location = getWindow().location;
          const previousUrl = location && location.href;
          if (previousUrl) {
            routerToState.previousUrl = previousUrl;
          }
          to.state = routerToState;

          const routerLinkProps = Object.assign({}, linkProps, {
            to,
            smooth: true,
          });

          if (activeClassName) {
            routerLinkProps.activeClassName = activeClassName;
          }
          if (activeStyle) {
            routerLinkProps.style = activeStyle;
          }

          // Use NavLink if activeClassName is specified.
          // This allows for styling if the link points to the current page
          let LinkComponent;
          if (routerLinkProps.activeClassName || routerLinkProps.activeStyle) {
            LinkComponent = NavRouterLink;
            routerLinkProps.exact = true;
          } else {
            LinkComponent = RouterLink;
          }

          return (
            <LinkComponent
              {...routerLinkProps}
              innerRef={ref}
              onClick={handleClick}
              role="link"
            />
          );
        }}
      </NavFromContext.Consumer>
    );
  },
);

Link.defaultProps = {
  href: null,
  name: null,
  buttonName: null,
  className: '',
  activeClassName: undefined,
  onClick: null,
  noSameUrlLinks: false,
  isAmp: false,
  gaTarget: null,
  stopPropagation: false,
};

Link.propTypes = {
  href: PropTypes.string,
  name: PropTypes.string,
  buttonName: PropTypes.string,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  activeStyle: PropTypes.object,
  hash: PropTypes.string,
  onClick: PropTypes.func,
  openInNewWindow: PropTypes.bool,
  history: PropTypes.object.isRequired,
  staticContext: PropTypes.object,
  navFromEventName: PropTypes.string,
  match: PropTypes.object,
  location: PropTypes.object,
  noSameUrlLinks: PropTypes.bool,
  isAmp: PropTypes.bool,
  gaTarget: PropTypes.string,
  disableAnchorLink: PropTypes.bool,
  stopPropagation: PropTypes.bool,
};

export default withRouter(Link);
