import React from 'react';
import styled from '@emotion/styled';
import { UI_SUBHEADING_TYPES, UNSET_TEXT } from 'Style/typography';
import { SURFACE_COLORS, DefaultTextColorStyle } from 'Style/colors';
import { SPACING } from 'Style/spacing';
import { truncate } from 'Style/truncate';
import { styleOnlyProps, getPixelNumberValue } from 'Style/style-helpers';

// Utils
import Attribution from 'Utils/content/attribution';
import { getOriginalItem, getOriginalItemOnly } from 'Utils/content/item-util';
import getWindow from 'Utils/get-window';
// Components
import Button, { StyleModifiers } from 'Webapp/shared/app/components/button';
import ItemCommentsButton from 'Webapp/shared/app/components/item-comments-button';
import CommentText from 'Webapp/shared/app/components/comment-text';

import connector from 'Utils/connector';
import connectFeatureFlags, {
  ConnectFeatureFlagsProps,
} from 'Webapp/shared/app/connectors/connectFeatureFlags';
import withT from 'ComponentLibrary/hocs/withT';

type StyledExpandingTextProps = {
  isExpanded?: boolean;
  isNote?: boolean;
  isOverlay: boolean;
  backgroundColor?: string;
};
const styleProps = styleOnlyProps(
  'isExpanded',
  'isNote',
  'backgroundColor',
  'isOverlay',
);

export const MoreButtonWrapper = styled(
  'div',
  styleProps,
)<StyledExpandingTextProps>(
  {
    position: 'sticky',
    bottom: SPACING.SMALL,
    right: '0',
    backgroundColor: 'inherit',
    marginLeft: 'auto',
    paddingLeft: SPACING.BASE6X,
    background: `linear-gradient(90deg, transparent 0%, ${SURFACE_COLORS.secondary} 35.21%)`,
  },
  ({ isExpanded }) => {
    if (isExpanded) {
      return {
        paddingLeft: SPACING.BASE6X,
      };
    }
  },
  ({ backgroundColor }) => {
    if (backgroundColor) {
      return {
        background: `linear-gradient(90deg, transparent 0%, ${backgroundColor} 35.21%)`,
      };
    }
  },
);

const StyledExpandingText = styled(
  'div',
  styleProps,
)<StyledExpandingTextProps>(({ isExpanded }) => ({
  position: 'relative',
  backgroundColor: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: isExpanded ? SPACING.XLARGE : '0px',
}));

type TextProps = { numLines: number | null };
const TextStyle = ({ numLines }) => {
  const truncateStyle = numLines && truncate('100%', numLines);
  return { ...truncateStyle, lineHeight: '1.6' };
};
const Text = styled('p', styleOnlyProps('numLines'))<TextProps>(TextStyle);
const TextTitle = styled('h1', styleOnlyProps('numLines'))<TextProps>(
  TextStyle,
  UNSET_TEXT,
);

export const DefaultMoreButton = styled(
  Button,
  styleProps,
)<StyledExpandingTextProps>(({ backgroundColor, isOverlay }) => {
  if (backgroundColor) {
    return {
      ...DefaultTextColorStyle(isOverlay),
      '&:focus': {
        backgroundColor,
        ...DefaultTextColorStyle(isOverlay),
      },
    };
  }
});

export const CommentMoreButton = styled(
  'div',
  styleProps,
)<StyledExpandingTextProps>(
  UI_SUBHEADING_TYPES.SMALL,
  {
    margin: '0',
    padding: '0',
    marginLeft: 'auto',
  },
  ({ backgroundColor, isOverlay }) => {
    if (backgroundColor) {
      return {
        ...DefaultTextColorStyle(isOverlay, true),
        '&:focus': {
          backgroundColor,
        },
      };
    }
  },
);

type ItemCardCaptionProps = {
  className: string;
  item: Flipboard.Item;
  numLines: number;
  children: React.ReactNode;
  isItemCaption: boolean;
  hideMoreButton: boolean;
  expandBehaviour: boolean;
  startExpanded: boolean;
  shownInList: boolean;
  isOverlay: boolean;
  overrideBackgroundColor?: string;
  t: Flipboard.TFunction;
} & ConnectFeatureFlagsProps;

type ItemCardCaptionState = {
  isExpanded: boolean;
  shouldTruncate: boolean;
};

class ItemFlipNote extends React.Component<
  ItemCardCaptionProps,
  ItemCardCaptionState
> {
  static defaultProps = {
    hideMoreButton: false,
    shownInList: true,
    numLines: 4,
  };
  state = {
    shouldTruncate: false,
    isExpanded: this.props.startExpanded || false,
  };

  componentDidMount() {
    this.setShouldTruncate();
  }
  componentDidUpdate(prevProps: Readonly<ItemCardCaptionProps>) {
    if (prevProps.startExpanded !== this.props.startExpanded) {
      this.setState(
        { isExpanded: this.props.startExpanded },
        this.setShouldTruncate,
      );
    }
  }

  textRef: React.RefObject<HTMLParagraphElement> = React.createRef();

  toggleExpanded = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  setShouldTruncate = () => {
    const textElement = this.textRef.current;
    if (textElement) {
      const computed = getWindow().getComputedStyle(textElement);
      const lineHeight = getPixelNumberValue(
        computed.getPropertyValue('line-height'),
      );

      // Round up to the nearest pixel width as we don't need to deal with decimals.
      // (Scroll height doesn't return decimals, so this is more accurate).
      const calculatedMaxCollapsedHeight = Math.round(
        lineHeight * this.props.numLines,
      );
      this.setState({
        shouldTruncate: textElement.scrollHeight > calculatedMaxCollapsedHeight,
      });
    }
  };

  getItemFlipNote = () => {
    const { isItemCaption, item } = this.props;
    let displayText: string;
    let sectionLinks: Array<Flipboard.FlapSectionLink>;

    if (isItemCaption) {
      displayText = item.caption;
      const flipItem = Attribution.getFlipItem(item);
      sectionLinks = flipItem.sectionLinks;
    } else if (item.isFlipComposeImage) {
      displayText = item.text;
      const originalItem = getOriginalItemOnly(item);
      if (originalItem) {
        sectionLinks = originalItem?.sectionLinks;
      } else {
        const flipItem = Attribution.getFlipItem(item);
        sectionLinks = flipItem.sectionLinks;
      }
    } else {
      displayText = item.text;
      const originalItem = getOriginalItem(item);
      sectionLinks = originalItem?.sectionLinks;
    }

    return (
      !!displayText && (
        <CommentText sectionLinks={sectionLinks}>{displayText}</CommentText>
      )
    );
  };

  renderMoreButton = () => {
    const {
      isOverlay,
      overrideBackgroundColor,
      item,
      featureFlags,
      hideMoreButton,
      expandBehaviour,
      isItemCaption,
      t,
    } = this.props;
    const { isExpanded, shouldTruncate } = this.state;
    const shouldShowMoreButton = !hideMoreButton && shouldTruncate;
    if (!shouldShowMoreButton) {
      return null;
    }

    const expandLabel = isExpanded ? 'less' : 'more';

    let backgroundColor = overrideBackgroundColor;
    const { isNote } = item;
    if (isNote && featureFlags.STANDALONE_POST) {
      backgroundColor = isItemCaption
        ? SURFACE_COLORS.defaultStandalonePostSecondary
        : SURFACE_COLORS.defaultStandalonePostPrimary;
    }
    return (
      <MoreButtonWrapper
        isExpanded={isExpanded}
        backgroundColor={backgroundColor}
        isOverlay={false}
      >
        {featureFlags.COMMENTS && !expandBehaviour ? (
          <ItemCommentsButton
            styleModifier={[StyleModifiers.INLINE_LINK]}
            key="comments"
            item={item}
            expandCommentsHeader
          >
            <CommentMoreButton
              backgroundColor={backgroundColor}
              isNote={isNote}
              isOverlay={isOverlay}
            >
              {t('more')}
            </CommentMoreButton>
          </ItemCommentsButton>
        ) : (
          <DefaultMoreButton
            name="more-button"
            styleModifier={[StyleModifiers.INLINE_LINK]}
            onClick={this.toggleExpanded}
            backgroundColor={backgroundColor}
            isOverlay={isOverlay}
          >
            {t(expandLabel)}
          </DefaultMoreButton>
        )}
      </MoreButtonWrapper>
    );
  };
  render() {
    const { className, numLines, shownInList } = this.props;
    const { isExpanded } = this.state;

    const showLines = isExpanded ? null : numLines;

    const text = this.getItemFlipNote();
    if (!text) {
      return null;
    }
    const TextComponent = shownInList ? Text : TextTitle;

    return (
      <>
        <StyledExpandingText
          className={className}
          isExpanded={isExpanded}
          isOverlay={false}
        >
          <TextComponent numLines={showLines} ref={this.textRef}>
            {text}
          </TextComponent>
          {this.renderMoreButton()}
        </StyledExpandingText>
      </>
    );
  }
}

export default connector(connectFeatureFlags)(withT(ItemFlipNote));
