import { connect } from 'react-redux';

import { isIPadDetectHack } from 'Utils/client';

function withIsIos(Component) {
  const mapStateToProps = ({ app: { isIos } }) => ({
    isIos: isIos || isIPadDetectHack(),
  });

  return connect(mapStateToProps)(Component);
}

export default withIsIos;
