import { connect } from 'react-redux';
import {
  addToCarousel,
  deleteFromCarousel,
} from '../../redux/actions/profile-actions';
import FavoriteButton from '../../components/section-header/favorite-button';
import SectionUtil from 'Utils/content/section-util';

const mapStateToProps = (
  {
    profile: { isLoading, favoritesUpdating, favorites, smartMagazines },
    auth,
  },
  { section },
) => {
  const remoteIdInCarouselFavorites = SectionUtil.isInCarouselFavorites(
    section,
    favorites,
    smartMagazines,
  );
  const filteredFavorites = favorites.filter((f) => !f.dynamicFeed);
  return {
    uid: auth.uid,
    loading: isLoading || favoritesUpdating,
    favoritesFull: filteredFavorites.length >= 10,
    remoteIdInCarouselFavorites,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addFavorite: (section) =>
    dispatch(addToCarousel(section.remoteid, section.title)),
  removeFavorite: (remoteIdInCarouselFavorites) =>
    dispatch(deleteFromCarousel(remoteIdInCarouselFavorites)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    addFavorite: () => dispatchProps.addFavorite(ownProps.section),
    removeFavorite: () =>
      dispatchProps.removeFavorite(stateProps.remoteIdInCarouselFavorites),
  }),
)(FavoriteButton);
