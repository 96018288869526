import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { UI_HEADING_TYPES, BODY_TYPES } from 'Style/typography';
import { SPACING } from 'Style/spacing';

// Components
import ModalWrapper from './modal-wrapper';
import { MODAL_THEMES } from 'ComponentLibrary/modals/modal';
import ModalControls from 'Webapp/shared/app/components/modal-controls';

import connector from 'Utils/connector';
import connectModal from 'Webapp/shared/app/connectors/connectModal';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';

const ModalDiv = styled.div({ padding: `${SPACING.BASE8X} ${SPACING.BASE4X}` });
const ModalTitle = styled.h1({
  ...UI_HEADING_TYPES.LARGE,
  marginBottom: SPACING.LARGE,
  textAlign: 'center',
});
const ModalMessage = styled.div({
  ...BODY_TYPES.LARGE_STANDARD,
  textAlign: 'center',
});

function NotificationModal({ title, message, dismissModal, isPhone }) {
  const modalTheme = isPhone ? MODAL_THEMES.FULLSCREEN : MODAL_THEMES.WINDOW;

  return (
    <ModalWrapper name="notification-modal" modalTheme={modalTheme}>
      <ModalDiv>
        <ModalTitle>{title}</ModalTitle>
        <ModalMessage>{message}</ModalMessage>
        <ModalControls
          modalName="notification-modal"
          showCloseButton
          closeButtonSize={16}
          onDismiss={dismissModal}
        />
      </ModalDiv>
    </ModalWrapper>
  );
}

NotificationModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  isPhone: PropTypes.bool.isRequired,
  dismissModal: PropTypes.func.isRequired,
};

export default connector(connectResponsive, connectModal)(NotificationModal);
