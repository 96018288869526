import React from 'react';
import PropTypes from 'prop-types';
import Icon from './icon';

function FacebookIcon(props) {
  const derivedColor = props.useBrandColor ? '#4267b2' : '#fff';
  return (
    <Icon
      name="facebook"
      viewBox="0 0 30 30"
      size={30}
      {...props}
      renderComponent={() => (
        <path
          d="M17.1357031,15.5625 L17.5941117,12.4858984 L14.7279298,12.4858984 L14.7279298,10.4893945 C14.7279298,9.64769531 15.1283119,8.82724609 16.4119849,8.82724609 L17.715,8.82724609 L17.715,6.20785156 C17.715,6.20785156 16.5325509,6 15.4020031,6 C13.041618,6 11.4987605,7.47355469 11.4987605,10.1410937 L11.4987605,12.4858984 L8.875,12.4858984 L8.875,15.5625 L11.4987605,15.5625 L11.4987605,23 L14.7279298,23 L14.7279298,15.5625 L17.1357031,15.5625 Z"
          id="Path"
          fill={derivedColor}
          fillRule="nonzero"
        />
      )}
    />
  );
}

export default FacebookIcon;

FacebookIcon.propTypes = {
  useBrandColor: PropTypes.bool,
};

FacebookIcon.defaultProps = {
  useBrandColor: false,
};
