import { SPACING } from 'Style/spacing';

export const MODAL = {
  content: {
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    padding: SPACING.LARGE,
  },
  title: {
    marginBottom: SPACING.MEDIUM,
  },
};
