import uuid from 'uuid/v4';
import { euc } from 'Utils/url';
import FlapUtil from 'Utils/content/flap-util';
import { getCurrentDomainFlipboardUrl } from 'Utils/content/flipboard-urls';

const TWEET_ID_PREFIX = 'twitter-';
const TWITTER_DOMAIN_REGEX = /^https?:\/\/twitter.com\/[^/]+\/status\//;
const MIN_CHILDREN_TO_PUBLISH = 3;

/**
 * Takes a storyboard id and converts it into a section id
 * Eg.
 * flipboard:bhswwa55SzKk2HdHCtdu5w:c:75085216 to
 * flipboard/package%2Fbhswwa55SzKk2HdHCtdu5w%3Ac%3A75085216
 * @param {*} id
 */
function getSectionIdFromPackageId(id) {
  return encodeURIComponent(id).replace('flipboard%3A', 'flipboard/package%2F');
}

function getPackageIdFromSectionId(id) {
  return decodeURIComponent(id).replace('flipboard/package/', 'flipboard:');
}

/**
 * Looks for the customizations object in an item
 * @param {Object} item - Storyboard Item
 * @returns {Object}
 */
function getCustomizations(item) {
  const details = item ? item.details || item.customizer || {} : {};
  return details && details.customizations;
}

/**
 * Looks for title in an item's customizations object
 * These titles are specifically added by the storyboard creator
 * @param {Object} item - Storyboard Item
 * @returns {String}
 */
function getCustomizedTitle(item) {
  const customizations = getCustomizations(item);
  return (customizations && customizations.title) || item.title;
}

/**
 * Looks for image in an item's customizations object
 * These images are specifically added by the storyboard creator
 * @param {Object} item - Storyboard Item
 * @returns {String}
 */
function getCustomizedImage(item) {
  const customizations = getCustomizations(item);
  return (customizations && customizations.image) || null;
}

function getCustomizedClickUrl(item) {
  const customizations = getCustomizations(item);
  return (customizations && customizations.click) || null;
}

/**
 * Looks for an item's description in it's customizations object
 * @param {Object} item - Storyboard Item
 * @returns {String}
 */
function getCustomizedText(item) {
  const customizations = getCustomizations(item);
  return (customizations && customizations.text) || null;
}

/**
 * Looks for an item's excerptText in it's customizations object
 * @param {Object} item - Storyboard Item
 * @returns {String}
 */
function getCustomizedExcerptText(item) {
  const customizations = getCustomizations(item);
  return (customizations && customizations.excerptText) || null;
}

/**
 * Looks for a cover image url in an item's customizations object
 * @param {Object} item - Storyboard Item
 * @returns {String}
 */
function getCustomizedCoverUrl(item) {
  const customizations = getCustomizations(item);
  return (customizations && customizations.image) || null;
}

function getCustomizedBackground(item) {
  const customizations = getCustomizations(item);
  return (
    (customizations &&
      customizations.itemRenderHints &&
      customizations.itemRenderHints.prefersNeutralBackgroundColor) ||
    null
  );
}

/**
 * Looks for the type of an item, in it's customizations object
 * @param {Object} item - Storyboard Item
 * @returns {String}
 */
function getCustomizedType(item) {
  const customizations = getCustomizations(item);
  return (
    (customizations &&
      customizations.itemRenderHints &&
      customizations.itemRenderHints.type) ||
    null
  );
}

/**
 * Checks if a storyboard item should be numbered
 * @param {Object} item - Storyboard Item
 * @returns {Boolean}
 */
function getIsNumbered(item) {
  const customizations = getCustomizations(item);
  return (
    (customizations &&
      customizations.itemRenderHints &&
      customizations.itemRenderHints.numbered) ||
    null
  );
}

/**
 * Finds the display size of a storyboard item
 * @param {Object} item - Storyboard Item
 * @returns {Boolean}
 */
function getDisplaySize(item) {
  const customizations = getCustomizations(item);
  return (
    (customizations &&
      customizations.itemRenderHints &&
      customizations.itemRenderHints.size) ||
    null
  );
}

/**
 * Looks for a display count in an item's customizations object
 * @param {Object} item - Storyboard item
 * @return {Number} display count of item
 */
function getDisplayCount(item) {
  const customizations = getCustomizations(item);
  return (
    (customizations &&
      customizations.franchise &&
      customizations.franchise.itemDisplayCount) ||
    null
  );
}

/**
 * Looks for preferred layout in an item's customizations object
 * @param {Object} item - Storyboard item
 * @return {String} optional preferred layout style
 */
function getPreferredLayout(item) {
  const customizations = getCustomizations(item);
  return (
    (customizations &&
      customizations.franchise &&
      customizations.franchise.preferredLayoutStyle) ||
    null
  );
}

/**
 * Looks for the default title for the item. These titles are extracted and populated by Flipboard.
 * @param {*} item
 */
function getDefaultTitle(item) {
  const { attachments } = item;
  let title = null;
  if (attachments && attachments.length) {
    title = attachments[0].details && attachments[0].details.title;
  }
  return title;
}

/**
 * Looks for text in a Storyboard item
 * @param {Object} item - Flipboard Storyboard skeleton item
 * @return {String} text of item
 */
function getText(item) {
  const { text } = item;
  if (text) {
    return text;
  }
  return getCustomizedText(item);
}

/**
 * Returns canonical item type
 * @param {String} type
 */
function getItemType(item) {
  if (isTweet(item)) {
    return 'tweet';
  }
  if (item.type === 'post') {
    return 'article';
  }
  return item.type;
}

/**
 * Looks for the url that an item references
 * @param {Object} item - Flipboard Storyboard skeleton item
 * @return {String} url of item target
 */
function getUrl(item) {
  // NOTE: Once fully moved to new Storyboard API, do not need to look for attachment urls
  if (item.url) {
    return item.url;
  }
  const { attachment, attachments } = item;
  if (attachment && attachment.url) {
    return attachment.url;
  }
  if (!attachments || attachments.length === 0) {
    return;
  }
  return attachments[0].url || item.url;
}

/**
 * Returns the URL for the storyboard type section
 * @param {Object} flStoryboard    - Projected section
 * @return {String}             - The Storyboard URL
 */
function getStoryboardUrl(flStoryboard) {
  return (
    (flStoryboard.remoteid && `/section?id=${euc(flStoryboard.remoteid)}`) ||
    null
  );
}

/**
 * Computes the display type of an item, based on specific properties
 * @param {Object} item - Flipboard Storyboard skeleton item
 * @return {String} display type of item
 */
function getDisplayType(item) {
  if (getIsNumbered(item)) {
    return 'numbered';
  }
  return getCustomizedType(item);
}

/**
 * Determines if an item is a tweet
 * @param {Object} item - Flipboard Storyboard skeleton item
 * @return {Boolean}
 */
function isTweet(item) {
  const { type, id, source } = item;
  if (type !== 'post') {
    return false;
  }
  return !!(
    (id && id.indexOf(TWEET_ID_PREFIX) === 0) ||
    (source && TWITTER_DOMAIN_REGEX.test(source))
  );
}

/**
 * Determines if an item is a section gateway
 * @param {Object} item - Flipboard Storyboard skeleton item
 * @return {Boolean}
 */
function isSectionGateway(item) {
  return item.type === 'section';
}

/**
 * Determines if an item is an exploded section
 * @param {Object} item - Flipboard Storyboard skeleton item
 * @return {Boolean}
 */
function isExplodedSection(item) {
  const { type, section } = item;
  return !!(type === 'package' && section && getDisplayCount(item) > 0);
}

function hasLessThanMinChildren(storyboardSkeleton) {
  const countOfChildren = storyboardSkeleton.children
    ? storyboardSkeleton.children.reduce((a, c) => {
        let currCount = 1;
        if (c.type === 'package') {
          currCount = c.children ? c.children.length : 0;
        }
        return a + currCount;
      }, 0)
    : 0;
  return countOfChildren < MIN_CHILDREN_TO_PUBLISH;
}

function hasEmptySections(storyboardSkeleton) {
  const result = storyboardSkeleton.children
    .filter((c) => c.type === 'package')
    .some((c) => !c.children || c.children.length <= 0);
  return result;
}

/**
 * Determines if a storyboard skeleton is valid or not
 * @param {*} storyboardSkeleton
 * @param {Object} item - Flipboard Storyboard skeleton
 * @return {Boolean}
 */
function isValid(storyboardSkeleton) {
  if (
    !storyboardSkeleton.title ||
    !storyboardSkeleton.description ||
    !storyboardSkeleton.coverUrl ||
    !storyboardSkeleton.children ||
    !storyboardSkeleton.children.length ||
    hasLessThanMinChildren(storyboardSkeleton) ||
    hasEmptySections(storyboardSkeleton)
  ) {
    return false;
  }

  return true;
}

/**
 * Returns an error message
// TODO: Translate errors when strings are determined
 * @param {*} storyboardSkeleton
 */
function getError(storyboardSkeleton) {
  if (!storyboardSkeleton.title) {
    return 'Missing title';
  }
  if (!storyboardSkeleton.description) {
    return 'Missing description';
  }
  if (!storyboardSkeleton.coverUrl) {
    return 'Missing cover image';
  }
  if (!storyboardSkeleton.children || !storyboardSkeleton.children.length) {
    return 'No content was added';
  }
  if (hasLessThanMinChildren(storyboardSkeleton)) {
    return 'Storyboards must contain at least three items to publish';
  }

  if (hasEmptySections(storyboardSkeleton)) {
    return 'One or more sections are empty';
  }
  return null;
}

/**
 * Converts a storyboard child projection into a Flap storyboard child structure
 * @param {Object} projection - Storyboard child projection
 * @returns {Object} Storyboard child following Flap's expected data structure
 */
function getFlapStoryboardChildFromProjection(childProjection) {
  const {
    id,
    title,
    type,
    text,
    image,
    children,
    url,
    displaySize,
    displayType,
  } = childProjection;
  const storyboardChild = {
    type: childProjection.type,
    details: {
      customizations: {
        itemRenderHints: {},
      },
    },
  };

  if (title) {
    storyboardChild.details.customizations.title = title;
  }

  if (text) {
    if (type === 'status' || type === 'tweet') {
      storyboardChild.text = text;
    } else {
      storyboardChild.details.customizations.text = text;
    }
  }

  if (displayType === 'numbered') {
    storyboardChild.details.customizations.itemRenderHints.numbered = true;
  } else if (displayType) {
    storyboardChild.details.customizations.itemRenderHints.type = displayType;
  }

  if (displaySize) {
    storyboardChild.details.customizations.itemRenderHints.size = displaySize;
  }

  if (image) {
    storyboardChild.details.customizations.image = image;
  }

  if (url) {
    Object.assign(storyboardChild, {
      attachments: [{ url }],
    });
  }

  if (type === 'package') {
    Object.assign(storyboardChild, {
      children: children
        ? children.map(getFlapStoryboardChildFromProjection)
        : [],
    });
    storyboardChild.details.customizations.franchise = {
      itemDisplayCount: children ? children.length : 0,
    };
  } else if (type === 'section') {
    Object.assign(storyboardChild, {
      section: childProjection.section,
    });
  } else if (type === 'tweet') {
    Object.assign(storyboardChild, {
      type: 'post',
      details: { customizations: { skipAttachment: true } },
    });
  }

  if (id) {
    Object.assign(storyboardChild, {
      id,
      remoteid: childProjection.remoteid,
      timestamp: childProjection.timestamp,
    });
  }

  return storyboardChild;
}

/**
 * Converts a storyboard  projection into a Flap storyboard  structure
 * @param {Object} projection - Storyboard projection
 * @returns {Object} Storyboard following Flap's expected data structure
 */
function getFlapStoryboardFromProjection(projection) {
  const flStoryboard = {
    type: projection.type,
    description: projection.description,
    details: {
      customizations: {
        title: projection.title,
        image: projection.coverUrl,
        cover: true,
        tags: projection.tags || [],
      },
    },
    children: (projection.children || []).map((c) =>
      getFlapStoryboardChildFromProjection(c),
    ),
  };

  // If storyboard  already exists, add identifying properties
  if (projection.id) {
    Object.assign(flStoryboard, {
      id: projection.id,
      remoteid: projection.remoteid,
      timestamp: projection.timestamp,
    });
  }
  return flStoryboard;
}

function generateNewStoryboard(title, description) {
  return {
    type: 'package',
    description,
    details: {
      customizations: { title },
    },
  };
}

/**
 * Determines if an item is a "root item" based on a wrapping section around it
 * @param {Object} item - Storyboard skeleton item projection
 */

function isRootItem(item) {
  return (
    item.type !== 'package' ||
    (!item.title && !item.text && item.children && item.children.length === 1)
  );
}

function getStoryboardArticleUrls(storyboardItems = []) {
  return storyboardItems.reduce((acc, child) => {
    if (!child.children && child.url) {
      return acc.concat(child.url);
    } else if (!child.children) {
      return acc;
    }
    const childItems = child.children
      .filter((item) => item.url)
      .map((item) => item.url);
    return acc.concat(childItems);
  }, []);
}

/**
 * Takes an array of storyboard root level items and returns a flat array including nested items
 * @param {Array} items - Storyboard items
 * @return {Array} - flat array of storyboard items
 */
function getFlatItems(items = []) {
  return items.reduce((acc, item) => {
    if (item.children) {
      return [...acc, ...item.children];
    }
    return [...acc, item];
  }, []);
}

/**
 * Looks for an extracted item, based on a storyboard item definition.
 * Note that sometimes we have the actual extracted content for a given item,
 * so we can match by id. Sometimes we have an extraction based on an URL.
 * Sometimes the extraction is a Flap item, sometimes a section.
 * @param {Object} extractedItems - Map keyed by id/remoteid, depending on cases
 * @param {Object} storyboardItem - Storyboard item definition
 * @returns {Object} extracted item
 */
function getExtractedItemFromStoryboardItem(
  storyboardItem,
  extractedItems = {},
) {
  if (!storyboardItem) {
    return null;
  }
  const id = FlapUtil.getRemoteServiceItemId(storyboardItem);
  const { url } = storyboardItem;

  const urlItem = extractedItems[url];
  // If item is a section, return the section instead
  if (urlItem) {
    return urlItem.section || urlItem;
  }

  if (storyboardItem.type === 'tweet' || storyboardItem.type === 'reply') {
    return extractedItems[id.replace('twitter:', '')];
  }

  const sectionItem =
    (storyboardItem.section && extractedItems[storyboardItem.section]) ||
    (id && extractedItems[id]);
  return sectionItem && (sectionItem.section || sectionItem);
}

function getFlapStoryboardFromProCuratorProjection(projection) {
  const flStoryboard = {
    id: projection.id,
    title: projection.title,
    description: projection.description,
    discommend: !projection.recommend,
    series: projection.series,
    details: {
      customizations: {
        itemRenderHints: {},
        tags: projection.tags,
      },
    },
  };
  if (projection.coverUrl) {
    flStoryboard.details.customizations.image = projection.coverUrl;
    flStoryboard.details.customizations.cover = true;
  }

  if (projection.prefersNeutralBackgroundColor) {
    flStoryboard.details.customizations.itemRenderHints.prefersNeutralBackgroundColor = true;
  }

  if (projection.sourceDomainSelected) {
    flStoryboard.details.customizations.imageAttribution =
      projection.sourceDomainSelected;
  }
  return flStoryboard;
}

function getFlapStoryboardItemFromProCuratorProjection(itemProjection) {
  const {
    displayType,
    displaySize,
    text,
    excerptText,
    type,
    image,
    title,
    originalTitle,
    url,
    clickUrl,
    preferredLayout,
  } = itemProjection;
  const item = {
    type,
    details: {
      customizations: {
        itemRenderHints: {},
      },
    },
  };
  const trimmedTitle = title ? title.trim() : '';
  const trimmedText = text ? text.trim() : '';

  if (type === 'package') {
    item.title = trimmedTitle;
    if (preferredLayout) {
      item.details.customizations.franchise = {
        preferredLayoutStyle: preferredLayout,
      };
    }
  } else {
    if (originalTitle) {
      item.title = originalTitle;
    }
    if (trimmedTitle && originalTitle !== trimmedTitle) {
      item.details.customizations.title = trimmedTitle;
    }
  }

  if (trimmedText) {
    item.details.customizations.text = trimmedText;
  }
  if (excerptText) {
    item.details.customizations.excerptText = excerptText;
  }
  if (clickUrl) {
    item.details.customizations.click = clickUrl;
  }

  if (url) {
    item.url = url;
  }
  if (displayType === 'numbered') {
    item.details.customizations.itemRenderHints.numbered = true;
  } else if (displayType) {
    item.details.customizations.itemRenderHints.type = displayType;
  }

  if (displaySize) {
    item.details.customizations.itemRenderHints.size = displaySize;
  }

  if (image) {
    item.details.customizations.image = image;
  }

  return item;
}

function storyboardChildProjection(child) {
  const { id, title, section, timestamp, children } = child;

  const projected = {
    type: getItemType(child),
    clientId: uuid(),
    text: getText(child),
    excerptText: getCustomizedExcerptText(child),
    title: getCustomizedTitle(child),
    clickUrl: getCustomizedClickUrl(child),
    originalTitle: title,
    image: getCustomizedImage(child),
    preferredLayout: getPreferredLayout(child),
  };

  if (id) {
    projected.id = id;
  }
  if (projected.type === 'package') {
    Object.assign(projected, {
      displayType: getDisplayType(child),
      displaySize: getDisplaySize(child),
      displayCount: getDisplayCount(child),
      children: children && children.map(storyboardChildProjection),
    });
  } else if (projected.type === 'section') {
    Object.assign(projected, { section, url: getUrl(child) });
  } else if (projected.type === 'tweet') {
    Object.assign(projected, { isTweet: true, timestamp });
  } else {
    Object.assign(projected, { url: getUrl(child) });
  }

  return projected;
}

function projection(flStoryboard) {
  const { id, remoteid, description, timestamp, type } = flStoryboard;

  const projected = {
    id,
    remoteid,
    type,
    title: getCustomizedTitle(flStoryboard),
    description,
    coverUrl: getCustomizedCoverUrl(flStoryboard),
    timestamp,
    isStoryboard: true,
    tags: getCustomizations(flStoryboard).tags || [],
    children:
      flStoryboard.children &&
      flStoryboard.children.map(storyboardChildProjection),
    canonicalPath: getStoryboardUrl(flStoryboard),
    rawStoryboard: flStoryboard,
  };

  return projected;
}

function curatorProProjection(flStoryboard, publishAt, isCampaign = false) {
  const {
    id,
    type,
    timestamp,
    modified,
    title,
    description,
    section,
    author,
    source,
    draft,
    discommend,
    series,
  } = flStoryboard;
  const customizations = getCustomizations(flStoryboard);
  return {
    id,
    type,
    timestamp,
    modified,
    title,
    isStoryboard: !isCampaign,
    isCampaign,
    isDraft: !!draft,
    prefersNeutralBackgroundColor: getCustomizedBackground(flStoryboard),
    recommend: !discommend,
    tags: (customizations && customizations.tags) || [],
    description,
    remoteid: section,
    coverUrl: getCustomizedCoverUrl(flStoryboard),
    canonicalPath: getCurrentDomainFlipboardUrl(source, true),
    author,
    publishAt,
    series,
    rawStoryboard: flStoryboard,
  };
}

// structure storyboard items in a way that is passable to the Tree component from @atlaskit/tree
function treeProjection(skeleton) {
  const rootId = skeleton.id;
  const treeSource = {
    rootId,
    items: {
      [rootId]: {
        id: rootId,
        children: [],
        hasChildren: true,
        isExpanded: true,
        isChildrenLoading: true,
      },
    },
  };

  return skeleton.children.reduce((tree, item) => {
    tree.items[rootId].children.push(item.id);

    if (isRootItem(item)) {
      tree.items[item.id] = {
        id: item.id,
        children: [],
        hasChildren: false,
        data: item,
      };
    } else {
      tree.items[item.id] = {
        id: item.id,
        children: [],
        hasChildren: true,
        data: item,
        isExpanded: false,
      };

      item.children?.forEach?.((child) => {
        tree.items[item.id].children.push(child.id);
        tree.items[child.id] = {
          id: child.id,
          children: [],
          hasChildren: false,
          data: child,
        };
      });
    }

    return tree;
  }, treeSource);
}

export default {
  getCustomizations,
  getCustomizedTitle,
  getCustomizedText,
  getCustomizedCoverUrl,
  getCustomizedType,
  getCustomizedImage,
  getCustomizedClickUrl,
  getIsNumbered,
  getDisplaySize,
  getDisplayCount,
  getPreferredLayout,
  getDefaultTitle,
  getText,
  getItemType,
  getUrl,
  getDisplayType,
  getExtractedItemFromStoryboardItem,
  getFlatItems,
  isTweet,
  isSectionGateway,
  isExplodedSection,
  isValid,
  isRootItem,
  projection,
  storyboardChildProjection,
  curatorProProjection,
  treeProjection,
  getFlapStoryboardFromProjection,
  getFlapStoryboardChildFromProjection,
  getFlapStoryboardFromProCuratorProjection,
  getFlapStoryboardItemFromProCuratorProjection,
  generateNewStoryboard,
  getError,
  getStoryboardArticleUrls,
  getSectionIdFromPackageId,
  getPackageIdFromSectionId,
};
