import Locales from 'Utils/locales';
/**
 * Returns the franchise by remoteid
 * @param {Object} contentGuide   - The content guide object
 * @param {String} remoteid       - Remoteid of the franchise
 * @return {Object}               - The franchise object or null
 */
function getFranchise(contentGuide, remoteid) {
  if (!contentGuide) {
    return null;
  }

  const { sections } = contentGuide;
  if (typeof sections === 'undefined') {
    return null;
  }

  const franchise = sections.find(
    (franchise) => franchise.remoteid === remoteid,
  );
  return typeof franchise !== 'undefined' ? franchise : null;
}

/**
 * Converts a section from a franchise into a "section" item.
 * That is, an item of type "section" with a "section" property
 */
function convertSectionToSectionItem(section) {
  if (!section) {
    return null;
  }

  const decoratedSection = Object.assign({}, section, {
    isFromContentGuide: true,
  });

  return {
    type: 'section',
    id: section.remoteid,
    author: section.author,
    brick: section.brick,
    title: section.title,
    description: section.description,
    section: decoratedSection,
    subhead: section.subhead,
  };
}

function getHomeSections(
  contentGuide,
  defaultSections,
  titleSortOrder = [],
  { lang, locale },
) {
  if (!contentGuide) {
    return null;
  }

  const filteredDefaultSections = defaultSections.filter(
    (s) => titleSortOrder.indexOf(s.title) >= 0,
  );
  let sections = null;
  const isEnUs =
    Locales.isLangMatch(lang, 'en') && Locales.isLocaleMatch(locale, 'en_US');

  if (isEnUs) {
    sections = filteredDefaultSections;
  } else {
    sections = contentGuide.sections.slice(0, 7);
  }

  const filteredSections = sections.filter(
    (s) => !s.locale || Locales.isLocaleMatch(s.locale, locale),
  );
  // For some reason, sorting based on the titleSortOrder
  // is resulting in the opposite order on the server vs.
  // the client for content guide content (as opposed to default sections)
  return isEnUs
    ? filteredSections.sort((a, b) => {
        const aIndex = titleSortOrder.indexOf(a.title);
        if (aIndex === -1) {
          return 1;
        }
        const bIndex = titleSortOrder.indexOf(b.title);
        return aIndex < bIndex ? -1 : 1;
      })
    : filteredSections;
}

function getNonCarouselSections(contentGuide) {
  return contentGuide
    ? contentGuide.sections.filter((section) => !section.showCarousel)
    : [];
}

export default {
  getFranchise,
  getHomeSections,
  convertSectionToSectionItem,
  getNonCarouselSections,
};
