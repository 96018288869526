import qs from 'Utils/qs';

const SENSITIVE_KEYS = [
  'newPassword',
  'password',
  'pword',
  'tuuid',
  'access_token',
  'secret',
];

const filterSensitiveObj = (obj = {}, sensitiveKeys) => {
  try {
    return Object.keys(obj).reduce((acc, k) => {
      if (sensitiveKeys.includes(k)) {
        acc[k] = '__FILTERED__';
      } else {
        acc[k] = obj[k];
      }
      return acc;
    }, {});
  } catch (_) {
    return {};
  }
};

const filterSensitiveString = (string, sensitiveKeys) =>
  qs.stringify(filterSensitiveObj(qs.parse(string), sensitiveKeys)) || '';

export default (urlOrObj, sensitiveKeys = SENSITIVE_KEYS) => {
  if (typeof urlOrObj === 'string') {
    return filterSensitiveString(urlOrObj, sensitiveKeys);
  }
  return filterSensitiveObj(urlOrObj, sensitiveKeys);
};
