import React from 'react';
import styled from '@emotion/styled';
import { styleOnlyProps } from 'Style/style-helpers';

// Utils
import { smallestUrl } from 'Utils/image-util';

// Components
import Image from './base/image';
import withT from 'ComponentLibrary/hocs/withT';

type StyledThumbnailWrapperProps = {
  imgHeight: number;
  imgWidth: number;
};
const StyledThumbnailWrapper = styled(
  'div',
  styleOnlyProps('imgHeight', 'imgWidth'),
)<StyledThumbnailWrapperProps>(({ imgHeight, imgWidth }) => ({
  overflow: 'hidden',
  height: `${imgHeight}px`,
  width: `${imgWidth}px`,
}));

type MagazineThumbnailProps = {
  image: Flipboard.Image;
  name: string;
  size: number;
  width?: number;
  height?: number;
  className?: string;
  imageClass?: string;
  fallbackElement: React.ReactNode | false;
  t: Flipboard.TFunction;
};

const MagazineThumbnail: React.FC<MagazineThumbnailProps> = ({
  image,
  name,
  size,
  height,
  width,
  className,
  imageClass,
  fallbackElement,
  t,
}) => {
  if (!image && !fallbackElement) {
    return null;
  }
  const alt = name ? `${t('magazine')} - ${name}` : t('magazine');

  const imgHeight = height || size;
  const imgWidth = width || size;
  const otherProps: Record<string, unknown> =
    height && width ? { height, width } : { size };
  if (fallbackElement) {
    otherProps.fallbackElement = fallbackElement;
  }

  return (
    <StyledThumbnailWrapper
      className={className}
      imgHeight={imgHeight}
      imgWidth={imgWidth}
    >
      <Image
        className={imageClass}
        image={image}
        urlHelper={smallestUrl}
        alt={alt}
        ampLayout="fixed"
        ampWidth={imgWidth}
        ampHeight={imgHeight}
        ampStyle={{
          width: imgWidth,
          height: imgHeight,
        }}
        {...otherProps}
        crop
      />
    </StyledThumbnailWrapper>
  );
};

MagazineThumbnail.defaultProps = {
  size: 35,
};

export default withT(MagazineThumbnail);
