import getCurrentSection from 'Utils/seo/shared/get-current-section';

export default ({ section }, getState, { t }) => {
  if (section) {
    const currentSection = getCurrentSection(getState());
    if (currentSection && currentSection.isProfile) {
      const description =
        currentSection.description || currentSection.authorDescription;
      const trimmedDescription = description && description.trim();

      if (trimmedDescription && trimmedDescription.length > 70) {
        return trimmedDescription;
      }

      if (trimmedDescription) {
        const needsPeriod = trimmedDescription.slice(-1) !== '.';
        const genericDescriptionKey =
          trimmedDescription && trimmedDescription.length < 30
            ? 'profile_seo_description_generic_no_mags_long'
            : 'profile_seo_description_generic_no_mags_short';

        return `${trimmedDescription}${needsPeriod ? '.' : ''} ${t(
          genericDescriptionKey,
          {
            profileName: currentSection.authorUsername,
          },
        )}`;
      }

      const magazineNames = currentSection.magazineProjections
        .slice(0, 3)
        .map((m) => m.title)
        .join(', ');

      return t('profile_seo_description_generic', {
        profileName: currentSection.authorUsername,
        magazineNames,
      });
    }
  }
};
