export const currentUserUid = ({ auth: { uid } }: Flipboard.State) =>
  uid || (0 as Flipboard.UserId);

export const isAuthenticated = (state: Flipboard.State) =>
  currentUserUid(state) !== 0;

export const authForm = ({ auth: { authForm } }: Flipboard.State) => authForm;

export const signupCompleted = ({
  auth: { signupCompleted },
}: Flipboard.State) => signupCompleted;
