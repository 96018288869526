import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from '@emotion/styled';

// Components
import Image, { THEMES as ImageThemes } from './base/image';
import AuthorAvatarDefault from './attribution/author-avatar-default';
import withT from 'ComponentLibrary/hocs/withT';

const StyledImage = styled(Image)({
  display: 'block',
  objectFit: 'cover',
  borderRadius: '50%',
  border: '0.75px solid rgba(0, 0, 0, 0.15)',
});

function ProfileAvatar({ className, image, name, avatarSize, imageClass, t }) {
  const fallback = (
    <AuthorAvatarDefault avatarSize={avatarSize} imageClass={imageClass} />
  );
  if (!image && !name) {
    return fallback;
  }
  const alt = name ? `${t('avatar')} - ${name}` : t('avatar');

  const classes = classNames(className, imageClass, {
    'author-avatar__image': true,
  });

  const usableImage =
    typeof image === 'string' ||
    (image && typeof image === 'object' && Object.keys(image).length > 0);

  return usableImage ? (
    <StyledImage
      className={classes}
      image={image}
      variant={ImageThemes.small}
      alt={alt}
      ampLayout="fixed"
      ampWidth={avatarSize}
      ampHeight={avatarSize}
      ampStyle={{
        width: avatarSize,
        height: avatarSize,
      }}
      size={avatarSize}
      fallbackElement={fallback}
    />
  ) : (
    fallback
  );
}

ProfileAvatar.propTypes = {
  className: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  avatarSize: PropTypes.number,
  imageClass: PropTypes.string,
  t: PropTypes.func.isRequired,
};

ProfileAvatar.defaultProps = {
  imageUrl: null,
  name: null,
  avatarSize: 35,
  imageClass: null,
};

export default withT(ProfileAvatar);
