import { MAGAZINE_TYPES } from 'Webapp/action-types';
import { SaveState } from 'Webapp/enums';

interface MagazineReducerState {
  saveState: SaveState;
  flipState: SaveState;
}

const initialState: MagazineReducerState = {
  saveState: SaveState.NONE,
  flipState: SaveState.NONE,
};

export default function magazineReducer(state = initialState, action) {
  switch (action.type) {
    case MAGAZINE_TYPES.CREATE_MAGAZINE_PENDING: {
      return Object.assign({}, state, {
        saveState: SaveState.PENDING,
      });
    }
    case MAGAZINE_TYPES.CREATE_MAGAZINE_SUCCESS: {
      return Object.assign({}, state, {
        saveState: SaveState.SUCCEEDED,
      });
    }
    case MAGAZINE_TYPES.CREATE_MAGAZINE_FAILED: {
      return Object.assign({}, state, {
        saveState: SaveState.FAILED,
      });
    }
    case MAGAZINE_TYPES.MAGAZINE_SAVE_STATE_RESET: {
      return Object.assign({}, state, {
        saveState: SaveState.NONE,
      });
    }
    case MAGAZINE_TYPES.MAGAZINE_FLIP_PENDING: {
      return Object.assign({}, state, {
        flipState: SaveState.PENDING,
      });
    }
    case MAGAZINE_TYPES.MAGAZINE_FLIP_SUCCESS: {
      return Object.assign({}, state, {
        flipState: SaveState.SUCCEEDED,
      });
    }
    case MAGAZINE_TYPES.MAGAZINE_FLIP_FAILED: {
      return Object.assign({}, state, {
        flipState: SaveState.FAILED,
      });
    }
    default:
      return state;
  }
}
