import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

// Utils
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';
import qs from 'Utils/qs';
import { getTopicPath } from 'Utils/content/flipboard-urls';

// Components
import WebLink from './web-link';
import withNavFrom from '../hocs/withNavFrom';
import withHistory from 'Webapp/shared/app/hocs/withHistory';

import connector from 'Utils/connector';
import connectSectionLinkUsage from 'Webapp/shared/app/connectors/connectSectionLinkUsage';

const StyledWebLink = styled(WebLink)({
  wordBreak: 'break-word',
});

function SectionLink({
  item,
  section,
  navFromEventName,
  navFrom,
  className,
  dataTestId,
  children,
  onClick,
  openInNewWindow,
  utmParams,
  fromParam,
  history,
  usageSetNavFrom,
  usageTrackItemEnter,
}) {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    // For SectionTiles used in item feeds (not profile sections)
    if (item) {
      if (navFrom) {
        usageSetNavFrom(USAGE_EVENT_NAMES.ITEM_ENTER, navFrom);
      }
      usageTrackItemEnter(item, history);
    }
  };

  const canonicalPath =
    section.isSmartMagazine && section.rootTopic
      ? getTopicPath(section.rootTopic)
      : section.canonicalPath;
  let queryParams = fromParam ? { from: fromParam } : {};
  // Add UTM params if provided
  if (utmParams) {
    queryParams = Object.assign({}, queryParams, utmParams);
  }
  const href =
    Object.keys(queryParams).length > 0
      ? `${canonicalPath}?${qs.stringify(queryParams)}`
      : canonicalPath;

  return (
    <StyledWebLink
      className={className}
      href={href}
      data-test-id={dataTestId}
      navFromEventName={navFromEventName || USAGE_EVENT_NAMES.SECTION_ENTER}
      onClick={handleClick}
      openInNewWindow={openInNewWindow}
    >
      {children}
    </StyledWebLink>
  );
}

SectionLink.propTypes = {
  item: PropTypes.object,
  section: PropTypes.object.isRequired,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  navFromEventName: PropTypes.string,
  navFrom: PropTypes.string,
  openInNewWindow: PropTypes.bool,
  utmParams: PropTypes.object,
  fromParam: PropTypes.string,
  history: PropTypes.object,
  usageSetNavFrom: PropTypes.func.isRequired,
  usageTrackItemEnter: PropTypes.func.isRequired,
};

SectionLink.defaultProps = {
  item: null,
  section: null,
  className: '',
  dataTestId: null,
  children: null,
  onClick: null,
  navFromEventName: null,
  navFrom: null,
  openInNewWindow: false,
  utmParams: null,
  fromParam: null,
  history: null,
};

export default connector(connectSectionLinkUsage)(
  withHistory(withNavFrom(SectionLink)),
);
