import sortBy from 'lodash/sortBy';
import { ANALYTICS_TYPES } from 'Webapp/action-types';
import SectionUtil from 'Utils/content/section-util';
import { projection } from 'Utils/content/item-util';

interface AnalyticsMagazineReducerState {
  sectionMetrics: null | unknown;
  dimensionsMetrics: null | unknown;
  itemMetrics: null | unknown;
  isLoading: boolean;
  section: null | Flipboard.Section;
  sectionCover: null | unknown;
}

const initialState: AnalyticsMagazineReducerState = {
  sectionMetrics: null,
  dimensionsMetrics: null,
  itemMetrics: null,
  isLoading: true,
  section: null,
  sectionCover: null,
};

export default function magazineAnalyticsReducer(state = initialState, action) {
  switch (action.type) {
    case ANALYTICS_TYPES.GET_MAGAZINE_ANALYTICS_SUCCESS: {
      // NOTE: Do not .map(projection) to prevent .map 2nd param (index) from being
      // passed to projection function. Although projection() does not take a 2nd param, similar
      // scenarios have caused issues
      const items = action.sectionItems
        ? action.sectionItems.map((item) => projection(item))
        : [];
      const sectionCover = items.find((i) => i.isSectionCover);
      return Object.assign({}, state, {
        sectionMetrics: action.sectionMetrics && action.sectionMetrics.metrics,
        dimensionsMetrics:
          action.dimensionsMetrics && action.dimensionsMetrics.metrics,
        itemMetrics:
          action.itemMetrics && sortBy(action.itemMetrics, ['position']),
        section: SectionUtil.projection(action.section),
        sectionCover,
        isLoading: false,
      });
    }
    case ANALYTICS_TYPES.GET_MAGAZINE_ANALYTICS_FAILED: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }
    case ANALYTICS_TYPES.GET_MAGAZINE_ANALYTICS: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }
    case ANALYTICS_TYPES.CLEAR_MAGAZINE_ANALYTICS: {
      return Object.assign({}, initialState);
    }
    default:
      return state;
  }
}
