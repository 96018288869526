import React from 'react';
import signupButtonOnboardingSteps from 'Webapp/shared/app/components/onboarding-flows/signup-button-onboarding-steps';
import signupLandingOnboardingSteps from 'Webapp/shared/app/components/onboarding-flows/signup-landing-onboarding-steps';
import newsletterFrictionlessSteps from 'Webapp/shared/app/components/onboarding-flows/newsletter-frictionless-steps';
import stickyTopicPickerCtaSteps from 'Webapp/shared/app/components/onboarding-flows/sticky-topic-picker-cta-steps';
import postVerificationSteps from 'Webapp/shared/app/components/onboarding-flows/post-verification-steps';
import signupPublisherOnboardingSteps from 'Webapp/shared/app/components/onboarding-flows/signup-publisher-steps';
import acceptMagazineInviteUnauthenticatedOldSteps from 'Webapp/shared/app/components/onboarding-flows/accept-magazine-invite-unauthenticated-old';
import acceptMagazineInviteUnauthenticatedSteps from 'Webapp/shared/app/components/onboarding-flows/accept-magazine-invite-unauthenticated';
import acceptMagazineInviteAuthenticatedOldSteps from 'Webapp/shared/app/components/onboarding-flows/accept-magazine-invite-authenticated-old';
import acceptMagazineInviteAuthenticatedSteps from 'Webapp/shared/app/components/onboarding-flows/accept-magazine-invite-authenticated';
import acceptMagazineInviteSignupOldSteps from 'Webapp/shared/app/components/onboarding-flows/accept-magazine-invite-signup-old';
import acceptMagazineInviteSignupSteps from 'Webapp/shared/app/components/onboarding-flows/accept-magazine-invite-signup';
import becomePublisherSteps from 'Webapp/shared/app/components/onboarding-flows/become-publisher-steps';
import { OnboardingFlow } from 'Webapp/enums';

import connector from 'Utils/connector';
import connectOnboardingFlow, {
  ConnectOnboardingFlowProps,
} from 'Webapp/shared/app/connectors/connectOnboardingFlow';

type OnboardingProps = ConnectOnboardingFlowProps;

export type OnboardingStep = (nextStep: () => void) => Flipboard.Thunk;

const onboardingFlowsSteps = {
  [OnboardingFlow.SIGNUP_BUTTON]: signupButtonOnboardingSteps,
  [OnboardingFlow.SIGNUP_LANDING]: signupLandingOnboardingSteps,
  [OnboardingFlow.SIGNUP_NEWSLETTER]: newsletterFrictionlessSteps,
  [OnboardingFlow.STICKY_TOPIC_PICKER_CTA]: stickyTopicPickerCtaSteps,
  [OnboardingFlow.POST_VERIFICATION]: postVerificationSteps,
  [OnboardingFlow.PUBLISHER_LANDING]: signupPublisherOnboardingSteps,
  [OnboardingFlow.ACCEPT_MAGAZINE_INVITE_UNAUTHENTICATED_OLD]:
    acceptMagazineInviteUnauthenticatedOldSteps,
  [OnboardingFlow.ACCEPT_MAGAZINE_INVITE_UNAUTHENTICATED_NEW]:
    acceptMagazineInviteUnauthenticatedSteps,
  [OnboardingFlow.ACCEPT_MAGAZINE_INVITE_SIGNUP_OLD]:
    acceptMagazineInviteSignupOldSteps,
  [OnboardingFlow.ACCEPT_MAGAZINE_INVITE_SIGNUP_NEW]:
    acceptMagazineInviteSignupSteps,
  [OnboardingFlow.ACCEPT_MAGAZINE_INVITE_AUTHENTICATED_OLD]:
    acceptMagazineInviteAuthenticatedOldSteps,
  [OnboardingFlow.ACCEPT_MAGAZINE_INVITE_AUTHENTICATED_NEW]:
    acceptMagazineInviteAuthenticatedSteps,
  [OnboardingFlow.BECOME_PUBLISHER]: becomePublisherSteps,
};

interface OnboardingState {
  steps: Array<OnboardingStep>;
}

class Onboarding extends React.Component<OnboardingProps, OnboardingState> {
  state: OnboardingState = {
    steps: [],
  };

  componentDidUpdate(prevProps: OnboardingProps) {
    const { onboardingFlow, currentOnboardingStep } = this.props;
    if (!prevProps.onboardingFlow && onboardingFlow) {
      this.setSteps(this.getStepsForOnboardingFlow(onboardingFlow));
    }
    if (
      // resetting
      (prevProps.currentOnboardingStep !== null &&
        currentOnboardingStep === null) ||
      // going from unset to set currentOnboardingStep (including zero)
      (prevProps.currentOnboardingStep === null &&
        currentOnboardingStep !== null) ||
      // incrementing currentOnboardingStep
      (prevProps.currentOnboardingStep !== null &&
        currentOnboardingStep !== null &&
        prevProps.currentOnboardingStep < currentOnboardingStep)
    ) {
      this.executeCurrentStep();
    }
  }

  getStepsForOnboardingFlow = (onboardingFlow: OnboardingFlow) =>
    onboardingFlowsSteps[onboardingFlow] || [];

  executeCurrentStep = () => {
    const { currentOnboardingStep, nextOnboardingStep, runOnboardingFlowStep } =
      this.props;

    const step =
      currentOnboardingStep !== null && this.state.steps[currentOnboardingStep];
    if (step) {
      runOnboardingFlowStep(step(nextOnboardingStep));
    }
  };

  setSteps = (steps: OnboardingState['steps']) => {
    this.setState({ steps }, () => {
      this.props.setCurrentOnboardingStep(0);
    });
  };

  render() {
    return null;
  }
}

export default connector<OnboardingProps>(connectOnboardingFlow)(Onboarding);
