import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

import FrictionlessSignupSuccessModal from 'Webapp/shared/app/modals/frictionless-signup-success';

// actions
import {
  showModal,
  dismissSpecificModal,
} from 'Webapp/shared/app/redux/actions/modal-actions';
import { onboardingSuccessSelector } from 'Webapp/shared/concepts/onboarding';

const modalFrictionlessSignupSuccess: OnboardingStep =
  (nextStep) => (dispatch, getState) => {
    const success = onboardingSuccessSelector(getState());
    success
      ? dispatch(
          showModal(FrictionlessSignupSuccessModal, {
            onComplete: () => {
              dispatch(dismissSpecificModal(FrictionlessSignupSuccessModal));
              nextStep();
            },
          }),
        )
      : nextStep();
  };

export default modalFrictionlessSignupSuccess;
