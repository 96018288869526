// all the same as meta description, reuse
import article from 'Utils/seo/meta-description/article';
import topic from 'Utils/seo/meta-description/topic';
import profile from 'Utils/seo/meta-description/profile';
import home from 'Utils/seo/meta-description/home';
import signup from 'Utils/seo/meta-description/signup';
import login from 'Utils/seo/meta-description/login';
import newsletter from 'Utils/seo/meta-description/newsletter';
import newslettersLanding from 'Utils/seo/meta-description/newsletters-landing';

import magazine from './magazine';
import storyboard from './storyboard';
import video from './video';

export default [
  login,
  signup,
  newsletter,
  newslettersLanding,
  video,
  article,
  topic,
  profile,
  magazine,
  storyboard,
  home,
];
