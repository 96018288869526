import { factory as flaxiosFactory } from 'Utils/flaxios';
import sentry from 'Utils/sentry';

// Utils
import getOrigin from 'Utils/get-origin';

/**
 * Axios instance to make Flab requests from web-u client to web-u server
 */
const flab = flaxiosFactory({
  baseURL: `${getOrigin()}/api/v2/ab`,
  // Do not timeout (this is the axios default, but leaving in to
  // document our reasoning).  On a slow 3g connection it can take a
  // long time to finish requests when other resources (images /
  // stlyes / etc) are also being loaded.  Currently, timeouts result
  // in "not found" errors that are inaccurate.  We can introduce a
  // timeout if we need to, but should improve the error messaging
  // first.
  timeout: 16000,
  headers: { 'Content-Type': 'application/json' },
});

/**
 * Handles default and Flab errors
 */
flab.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response, code } = error;
    sentry.addBreadcrumb('[FLAB Client] Failed Response Interceptor');
    if (code === 'ECONNABORTED') {
      return Promise.reject(new Error('[FLAB Client] Request timed out'));
    }
    if (!response) {
      return Promise.reject(new Error('[FLAB Client] No response'));
    }

    if (code !== 404) {
      sentry.captureException(error);
    }
    return Promise.reject(error.response);
  },
);

export default flab;
