import styled from '@emotion/styled';

import { SPACING } from 'Style/spacing';
import { BREAKPOINTS } from 'Style/breakpoints';
import { UI_TEXT_TYPES } from 'Style/typography';
import { styleObject } from 'Style/type-helpers';

export const Title = styled.h1(UI_TEXT_TYPES.TITLE, {
  marginBottom: SPACING.BASE,
});

export const PageTitle = styled.h1(UI_TEXT_TYPES.PAGE_TITLE, {
  marginBottom: SPACING.BASE,
});

export const TitleWrapper = styled.p(UI_TEXT_TYPES.BODY);

export const Wrapper = styled.section(
  {
    margin: '160px auto',
    textAlign: 'center',
  },
  styleObject(
    BREAKPOINTS.tabletPortraitUp({
      margin: '220px auto',
    }),
  ),
);
