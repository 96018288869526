import sharedRoutes, { RouteTypes } from 'Webapp/shared/routes';

import { getCurrentArticle } from './article';

const isRouteType =
  (checkType) =>
  ({
    app: {
      routing: { type },
    },
  }) =>
    type === checkType;

export const isAuthRoute = isRouteType(RouteTypes.AUTH);
export const isSectionRoute = isRouteType(RouteTypes.SECTION);
export const isArticleRoute = isRouteType(RouteTypes.ARTICLE);
export const isHomeRoute = isRouteType(RouteTypes.HOME);
export const isNewslettersRoute = isRouteType(RouteTypes.NEWSLETTERS);
export const isSearchRoute = isRouteType(RouteTypes.SEARCH);
export const isProfileRoute = (state) =>
  state.app.routing.path === '/@:username' &&
  isRouteType(RouteTypes.SECTION)(state);

export const isSectionSlugRoute = ({
  app: {
    routing: { path },
  },
}) =>
  sharedRoutes
    .filter((r) => r.sectionSlugRoute)
    .map((r) => r.path)
    .includes(path);

const isPath =
  (checkPath) =>
  ({
    app: {
      routing: { path },
    },
  }) =>
    path === checkPath;

export const isUserStoryboardsRoute = isPath('/@:username/storyboards');
export const isOwnStoryboardsRoute = isPath('/profile/storyboards');
export const isVideoRoute = (state) => {
  if (!isArticleRoute(state)) {
    return false;
  }

  const currentArticle = state && getCurrentArticle(state);

  return (
    currentArticle &&
    (currentArticle.isVideo || currentArticle.isFirstPartyVideo)
  );
};
export const isEmailSettingsRoute = isPath('/account/emailSettings');

export const query = ({
  app: {
    routing: { query },
  },
}) => query;

export const verificationCode = (state) => query(state).code;

export const verificationSecret = (state) => query(state).s;

export const langParam = (state) => query(state).lang;

export const params = ({
  app: {
    routing: { params },
  },
}) => params || {};

export const paramSelector = (state) => params(state);
export const urlSelector = ({
  app: {
    routing: { url },
  },
}) => url;
