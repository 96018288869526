import fromUnixTime from 'date-fns/fromUnixTime';

import Config from 'Webapp/shared/config';
import { euc } from 'Utils/url';
import StoryboardRenderingUtil from 'Utils/content/storyboard-rendering-util';
import GlobalVars from 'Utils/global-vars';
import { getSectionPath } from 'Utils/content/flipboard-urls';
import FlapUtil from 'Utils/content/flap-util';
import isIntegerMatch from 'Utils/is-integer-match';
import {
  getImage,
  isSectionCover,
  isSection,
  firstImageItem,
  isGroup,
  isItemMuted,
  projection as itemProjection,
} from 'Utils/content/item-util';
import { usageSectionIdForTracking } from 'Utils/analytics/usage';
import sentry from 'Utils/sentry';
import { getSmartMagazineSection } from 'Utils/content/profile-util';
import isValueless from 'Utils/is-valueless';
import { Services, FlapSectionFeedType, FlapProfileType } from 'Webapp/enums';

/**
 * Returns the image object for the "brick" of the section.
 * This is what is shown in tiled lists of sections.
 * @param  {Object} section - Flipboard section
 * @return {Object}
 */
function brickImage(section) {
  return (section && section.brick) || null;
}

/**
 * Returns the image object from the metadata the section.
 * This is what is shown in the background of section titles
 * @param  {Object} section - Flipboard section
 * @param  {Array<rawItems>} rawItems - array of raw items
 * @return {Object}
 */
function image(section, rawItems = []) {
  if (typeof section.image !== 'undefined') {
    return section.image;
  }

  if (typeof section.imageURL !== 'undefined') {
    return {
      imageURL: section.imageURL,
    };
  }

  const itemWithImage = rawItems.find((i) => {
    // if item is a group, check the contained items
    if (isGroup(i)) {
      return i.items.find((groupItem) => getImage(groupItem));
    }
    return getImage(i);
  });
  if (itemWithImage) {
    if (isGroup(itemWithImage)) {
      const groupItemWithImage = itemWithImage.items.find((i) => getImage(i));
      return getImage(groupItemWithImage);
    }
    return getImage(itemWithImage);
  }
  return null;
}

/**
 * Returns an author object with appropriate props from the metadata of the section.
 * @param  {Object} section - Flipboard section
 * @param {Object} coverItem  - Optional Flipboard storyboard cover item
 * @return {Object} An author object
 */
function getAuthor(section, coverItem = null) {
  if (coverItem) {
    const sectionLinks =
      (Array.isArray(coverItem?.sectionLinks) && coverItem.sectionLinks) || [];
    return sectionLinks.find((link) => link.type === 'author') || null;
  }

  if (!section) {
    return null;
  }
  if (section.author) {
    return section.author;
  }
  if (
    !section.authorDisplayName &&
    !section.title &&
    !section.authorUsername &&
    !section.username &&
    !section.avatarText
  ) {
    return null;
  }

  const author = {
    authorDisplayName: section.authorDisplayName,
    authorDescription: section.authorDescription,
    title: section.title,
    authorUsername: section.authorUsername,
    username: section.username,
  };
  if (section.authorImage) {
    author.authorImage = section.authorImage;
  }
  if (isProfile(section)) {
    author.avatarText = getAvatarText(section);
    if (!section.authorImage) {
      author.authorImage = section.image;
    }
  }
  return author;
}

/**
 * Returns the author URL, or builds it if it's not available
 * @param {Object} section - Flipboard section
 * @return {String} URL of the author
 */
function getAuthorUrl(section) {
  const { authorURL, authorUsername } = section;
  if (authorURL) {
    return authorURL;
  }
  if (isTwitter(section) && authorUsername) {
    return `https://twitter.com/${authorUsername}`;
  }
  return null;
}

/**
 * Returns avatar text with the value of possible props from the metadata of the section.
 * @param  {Object} section - Flipboard section
 * @return {String} The avatar text
 */
function getAvatarText(section) {
  if (!section) {
    return null;
  }
  return (
    section.authorDisplayName ||
    section.title ||
    section.authorUsername ||
    section.username ||
    null
  );
}

function getMetricsText(section) {
  const { metrics } = section;
  if (
    !metrics ||
    !Array.isArray(metrics) ||
    metrics.length === 0 ||
    typeof metrics[0] !== 'object'
  ) {
    return;
  }
  const { value, displayName } = metrics[0];
  if (!value || !displayName) {
    return;
  }
  return `${value} ${displayName}`;
}

/**
 * Returns the text from the metadata the section.
 * @param  {Object} section - Flipboard section
 * @return {Object} The text of the section
 */
function text(section) {
  return (section && section.text) || null;
}

/**
 * Returns whether a section is a profile
 * @param {Object} section - Flipboard section to test
 * @return {Boolean}       - True if section is a profile
 */
function isProfile(section) {
  const { service, feedType, type, remoteid } = section;

  if (service !== Services.FLIPBOARD) {
    return false;
  }

  if (feedType === FlapSectionFeedType.PROFILE) {
    return true;
  }
  if (type === FlapProfileType.AUTHOR) {
    return true;
  }
  if (/^flipboard\/(user|username)%2F/.test(remoteid || '')) {
    return true;
  }

  return false;
}

/**
 * Returns whether a section is a list of a user's storyboards
 * @param {Object} section  - Flipboard section to test
 * @return {Boolean}        - True if the section represents a list of a user's
 * storyboards.
 */
function isUserStoryboards(section) {
  const remoteidPlain = section && section.ssid && section.ssid.remoteidPlain;
  if (!remoteidPlain) {
    return false;
  }

  return !!(
    section.service === 'flipboard' &&
    section.userid &&
    remoteidPlain.match(`flipboard/list%2Fpackage%2F${section.userid}`)
  );
}

/**
 * Returns whether a section is a contributor
 * @param {Object} section - Flipboard section to test
 * @return {Boolean}       - True if section is a contributor
 */
function isContributor(section) {
  const { service, type } = section;

  if (service !== 'flipboard') {
    return false;
  }

  if (type === 'contributor') {
    return true;
  }

  return false;
}

/**
 * Returns whether a section is a topic
 * @param {Object} section - Flipboard section to test
 * @return {Boolean}       - True if section is a topic
 */
function isTopic(section) {
  const { remoteid, topicTag, topic, feedType } = section;

  if (feedType === 'topic') {
    return true;
  }
  if (/^(auth\/)*flipboard\/topic%2F(.*)/.test(remoteid || '')) {
    return true;
  }
  if (topicTag) {
    return true;
  }
  if (topic !== undefined) {
    return true;
  }

  return false;
}

function isPersonalizeSearchTrigger(section) {
  return !!section.remoteid.match(/flipboard\/search/);
}

/**
 * Returns whether a section is a magazine
 * @param {Object} section - Flipboard section to test
 * @return {Boolean}       - True if section is a magazine
 */
function isMagazine(section) {
  const { feedType } = section;
  if (feedType === 'magazine') {
    return true;
  }
  return false;
}

/**
 * Returns whether a section is a board
 * @param {Object} section - Flipboard section to test
 * @return {Boolean}       - True if section is a board
 */
function isSmartMagazine(section) {
  const { feedType } = section;
  if (feedType === 'board') {
    return true;
  }
  return false;
}

/**
 * Returns whether a section is a service
 * @param {Object} section - Flipboard section to test
 * @return {Boolean}       - True if section is a service
 */
function isService(section) {
  const { service, remoteid } = section;
  if (service === undefined) {
    return false;
  }
  if (remoteid === `auth/${service}`) {
    return true;
  }
  if (remoteid === service) {
    return true;
  }
  if (service && service !== Services.FLIPBOARD) {
    return true;
  }
  return false;
}

/**
 * Returns whether a section is a Twitter section
 * @param {Object} section - Flipboard section to test
 * @return {Boolean}       - True if section is from Twitter
 */
function isTwitter(section) {
  const { service } = section;
  return service === 'twitter';
}

/**
 * Returns whether a section is a Flipboard section
 * @param {Object} section - Flipboard section to test
 * @return {Boolean}       - True if section is a from Flipboard
 */
function isFlipboard(section) {
  const { service } = section;
  return service === 'flipboard';
}

/**
 * Returns whether a section is empty, taking multiple
 * factors into account
 * @param {Object} section  - Flipboard section to test
 * @param {Array} items     - An array of items to check
 * @return {Boolean}        - True if the section has items
 */
function hasItems(section, items = []) {
  if (!section) {
    return false;
  }
  // never false for uncached, invited sections
  if (!section.cached && section.invite) {
    return true;
  }

  return items.length > 0;
}

/**
 *
 * @param {Object} section - Flipboard section
 * @returns {Boolean} True if section has storyboards, false if not
 */
function hasStoryboards(section) {
  const metrics = getSectionMetrics(section);
  return !!metrics.packageCount;
}

/**
 *
 * @param {Object} section - Flipboard section
 * @returns {Boolean} True if section has storyboards, false if not
 */
function hasVideos(section) {
  const metrics = getSectionMetrics(section);
  return !!metrics.videos;
}

const isItemRenderable = (section, item, currentUserData) => {
  if (
    isUserPreferenceable(section) &&
    isItemMuted(item, currentUserData) &&
    !item.recentlyMuted
  ) {
    return false;
  }
  return (
    (item.isValid &&
      (item.isPost ||
        item.isMedia ||
        item.isGroup ||
        item.isStatus ||
        item.isImage ||
        item.isVideo ||
        item.isAudio ||
        (item.isSection &&
          section.normalizedRemoteid !== item.section?.normalizedRemoteid))) ||
    item.isActivity ||
    item.isClientSideFeedItem
  );
};

/**
 * Returns valid items to render for a section, depending
 * on the type of section.
 * @param {Object} section   - Flipboard section
 * @param {Array} items      - An array of items to filter
 * @return {Array}           - The items to render
 */
function itemsToRender(section, items = [], currentUserData) {
  return items.filter((item) =>
    isItemRenderable(section, item, currentUserData),
  );
}

/** Returns whether a section is a storyboard
 * @param {Object} section - Flipboard section to test
 * @return {Boolean}       - True if section is a storyboard
 */
function isStoryboard(section) {
  if (!section) {
    return false;
  }

  const { feedType } = section;
  return feedType === 'bundle';
}

/** Returns whether a section is a community group
 * @param {Object} section  - Flipboard section
 * @return {Boolean}        - True if section is a community group
 */
function isCommunityGroup(section) {
  const { feedType } = section;
  return feedType === 'community';
}

/**
 * Returns if section should not be indexed
 * Set to "noindex" for:
 *   private section
 *   third party service (like Twitter)
 * @param {Object}  section   - Projected Flipboard section
 * @return {Boolean}          - True if section should not be indexed
 */
function isNoIndex(section) {
  if (section.isPrivate) {
    return true;
  }
  if (section.isTwitter) {
    return true;
  }
  return section.service !== 'flipboard';
}

/**
 * Returns the image to be used in social share rendering for a section.
 * The URL for that image is embedded in HTML meta tags.
 * @param {Object} section - Flipboard section
 * @return {Object}        - The image object for rendering in social shares
 */
function socialImage(section, items = []) {
  if (isTopic(section)) {
    // long story, see this Jira:
    // https://flipboard.atlassian.net/browse/FL-26926
    return {
      mediumURL: `https://flip.it/tile/${euc(
        section.normalizedRemoteid,
      )}/mediumURL`,
    };
  }

  // Use the author image if section is a profile
  if (isProfile(section) && section.authorImage) {
    return section.authorImage;
  }

  // Use the section image if section is a community group
  if (isCommunityGroup(section) && image(section)) {
    return image(section);
  }

  const item = firstImageItem(items);
  const firstImageItemImage = item && getImage(item);

  // Return cover item image if present
  const coverItem = items.find((item) => isSectionCover(item));
  if (typeof coverItem !== 'undefined') {
    // If the header is suppressed with render hints, return null
    if (StoryboardRenderingUtil.isHiddenHeaderImage(coverItem) && item) {
      return firstImageItemImage;
    }

    // If there is an image on the cover item, return that
    if (getImage(coverItem)) {
      return getImage(coverItem);
    }
  }

  // Return the section image if present
  if (section.image) {
    return section.image;
  }

  // Return first contained item image
  if (firstImageItem) {
    return firstImageItemImage;
  }

  return null;
}

/**
 * Generates a Flipboard section url for use by native apps
 * @param {Object} section     - A Flipboard section (can be raw)
 * @returns {String}           - A native app URL
 */
function appUrl(section) {
  const title = isProfile(section) ? section.authorDisplayName : section.title;
  const encodedTitle = euc(title);
  const remoteId = usageSectionIdForTracking(section);

  return `flipboard://showSection/${remoteId}?title=${encodedTitle}`;
}

/**
 * Returns the description for a section.
 * @param {Object} section     - Flipboard section
 * @return {String}            - The description
 */
function description(section) {
  // Use the author description for profile sections
  if (isProfile(section) && section.authorDescription) {
    return section.authorDescription;
  }

  return section.description;
}

function customizedTopicText(path, key, topicDescriptions = []) {
  const customTopicTexts = topicDescriptions.find(
    (topic) => topic.url === path,
  );
  if (customTopicTexts !== undefined && customTopicTexts[key]) {
    return customTopicTexts[key];
  }

  return null;
}

/**
 * Retruns whether a section is "recommended" based on some other Flipboard context
 * @param {Object} section     - Flipboard section
 * @return {Boolean}            - True if the section is a "recommended" section, such as
 * those for article "see more."
 */
function isRecommended(section) {
  const reasonText = section && section.reason && section.reason.text;
  if (!reasonText) {
    return false;
  }

  return reasonText.match(/recommended by Flipboard/gi) !== null;
}

/**
 * Returns whether a section is private
 * @param {Object} section - Flipboard section to test
 * @return {Boolean}       - True if visibility is private
 */
function isPrivate(section) {
  return section.magazineVisibility === 'private';
}

/**
 * Returns whether the section is own profile, own section or co-contributor of a section
 * @param {Object} section - Flipboard section
 * @param {Number} uid - Current logged in user's uid
 * @returns {Boolean}
 */
function isOwner(section, uid) {
  if (!uid) {
    return false;
  }
  if (
    section.userid === uid ||
    section.isMember ||
    (section.author &&
      section.author.id &&
      isIntegerMatch(section.author.id.replace('flipboard:', ''), uid))
  ) {
    return true;
  }
  return false;
}

/**
 * Returns whether a section is associated with a verified publisher
 * @param {Object} section - Flipboard section to test
 * @return {Boolean}       - True if verified type is publisher
 */
function isVerifiedPublisher(section) {
  return !!section.verifiedType;
}

/**
 * Checks if the provided userid matches the magazine userid
 * @param {Object} section - Flipboard section projection
 * @param {Number|String} userid - Userid to compare magazine ownership with
 */
function isMagOwner(section, userid) {
  // Skip check if no section provided, or section is not a magazine
  if (!section || !section.isMagazine) {
    return false;
  }
  if (!section.userid || !userid) {
    return false;
  }
  // Abstract equality check here, in case either value being compared is a string or number
  return section.userid == userid;
}

function isMagContributor(section, contributors, userid) {
  // Skip check if no section provided, or section is not a magazine
  if (!section || !section.isMagazine || !userid) {
    return false;
  }
  // Abstract equality check here, in case either value being compared is a string or number
  return !!contributors?.some((c) => c.userid == userid);
}

function isForYou(section) {
  return !!section?.ssid?.remoteidPlain?.includes(
    Config.COVER_STORIES_REMOTE_ID,
  );
}

function isUserPreferenceable(section) {
  return isSmartMagazine(section) || isForYou(section) || isTopic(section);
}

/**
 * Returns the section title
 * @param {Object} section - Flipboard section to test
 * @return {String}        - The title
 */
function getSectionTitle(section) {
  const { title, sectionTitle, feedType, authorUsername } = section;
  return (
    title ||
    sectionTitle ||
    (feedType === 'profile' && authorUsername && `@${authorUsername}`) ||
    null
  );
}

function getDecoratedSectionTitle(section) {
  return section.isTopic ? `#${section.title}` : section.title;
}
/**
 * Returns an array of sub sections for a smart magazine
 * @param {Object} section - Flipboard section to test
 * @return {Array}         - Subsections array
 */
function getSmartMagSubSections(section) {
  if (!isSmartMagazine(section)) {
    return null;
  }
  if (!section.subsections) {
    return null;
  }
  return section.subsections.map(getBasicProjectedSection);
}

/**
 * Returns a new object of data derived from the metrics property of a section
 * @param {Object} section - Flipboard section
 * @return {Object}    - The useful parts of the section metrics
 */
function getSectionMetrics(section) {
  if (!section) {
    return null;
  }
  const { metrics } = section;
  if (!metrics) {
    return {};
  }

  // Return metrics if the type is Object (we assume that metrics have already been projected);
  if (!Array.isArray(metrics) && typeof metrics === 'object') {
    return metrics;
  }

  return metrics.reduce(
    (acc, metric) => Object.assign({}, acc, { [metric.type]: metric.raw }),
    {},
  );
}

function getMetricsCountValue(section, key) {
  if (!section) {
    return null;
  }
  const metrics = getSectionMetrics(section);
  if (metrics[key]) {
    try {
      return parseInt(metrics[key], 10);
    } catch (e) {
      sentry.captureException(e);
    }
  }
  return null;
}

/**
 * Implements the "compare" function param for Array.sort.  Uses
 * section.title.
 * @param {Object} sectionA   - The projected "a" section for comparison
 * @param {Object} sectionB   - The projected "b" section for comparison
 */
function compareByTitle(sectionA, sectionB) {
  if (sectionA.title === null) {
    return 1;
  }
  if (sectionB.title === null) {
    return -1;
  }

  return sectionA.title.localeCompare(sectionB.title);
}

/**
 * Test for section validity.  Some sections are invalid in that when
 * we try to retrieve them from FLAP, they fail.  This can be predicted
 * in some cases based on the props of the raw section.  Typical use case
 * is filtering out "invalid" sections returned as items in a feed or user info
 * @param {Object} section     - Flipboard section
 * @return {Boolean}            - True if valid
 */
function isValid(section) {
  if (isService(section) && section.remoteid === 'twitter') {
    return false;
  } // The path rendered for this ('/service/twitter') returns nothing
  if (isProfile(section)) {
    return !!getSectionTitle(section);
  }
  if (isUserStoryboards(section)) {
    return true;
  }

  return !!section.feedType;
}

/**
 * Checks if a given section is in the carousel favorites. As the remoteid stored
 * may be a different one then checked returns the stored remoteid as a truthy value.
 * @param {Object} section - Flipboard section
 * @param {Array} favorites - List of sections in the carousel favorites for a user
 */
function isInCarouselFavorites(section, favorites = [], smartMagazines = []) {
  const rootTopic = section.isSmartMagazine && section.rootTopic;
  const smartMagazine = getSmartMagazineSection(section, smartMagazines);

  const foundFavorite = favorites.find((f) => {
    const favRemoteId = f.normalizedRemoteid;
    return (
      favRemoteId &&
      (section.normalizedRemoteid === favRemoteId ||
        FlapUtil.normalizeRemoteid(rootTopic?.remoteid) === favRemoteId ||
        smartMagazine?.normalizedRemoteid === favRemoteId)
    );
  });

  if (foundFavorite) {
    return foundFavorite.remoteid;
  }
  return null;
}

/**
 * Returns a potential magazine contributor invite in a section
 * @param {Object} section - Flipboard section
 * @param {Number} currentUserUid - Optional: if provided, checks for an invite that was not sent by the current user
 */
function getInvite(section, currentUserUid) {
  if (!section) {
    return null;
  }
  const { invite } = section;
  if (!invite) {
    return null;
  }
  if (!currentUserUid) {
    return invite;
  }
  return isIntegerMatch(invite.userid, currentUserUid) ? null : invite;
}

function mightBeSameSection(left, right) {
  if (!left || !right) {
    return false;
  }
  return (
    FlapUtil.isRemoteIdSectionMatch(left?.remoteid, right) ||
    FlapUtil.isRemoteIdSectionMatch(left?.ssid?.remoteidPlain, right) ||
    FlapUtil.isRemoteIdSectionMatch(left?.rootTopic?.remoteid, right)
  );
}

/**
 * Checks if a given section matches any in the given array.
 * @param {Array<Section>} sections - Array of Flipboard section projections
 * @param {Object} section - Flipboard section projection
 */
function sectionsIncludesSection(sections, section) {
  return sections.some((s) => mightBeSameSection(s, section));
}

/**
 * Gets the section's cover image
 * @param {Array} rawItems - Array of raw items
 * @return {Object}     - Image object or null
 */
function getCoverImage(rawItems = []) {
  if (!rawItems) {
    return null;
  }
  if (rawItems.length === 0) {
    return null;
  }
  const coverItem = rawItems.find((item) => isSectionCover(item));
  if (coverItem) {
    return getImage(coverItem) || null;
  }
  return getImage(rawItems.find((i) => isSection(i))?.section) || null;
}

function getStoryboardDefinitionId(section) {
  if (!isStoryboard(section)) {
    return null;
  }
  const { ssid } = section;
  if (!ssid || !ssid.remoteidPlain) {
    return null;
  }
  return decodeURIComponent(
    ssid.remoteidPlain.replace(/^auth\/flipboard\/package%2F/, 'flipboard:'),
  );
}

function getFormattedDate(unixTimestamp) {
  return unixTimestamp ? fromUnixTime(unixTimestamp).toISOString() : null;
}

function getDatePublished(section) {
  return getFormattedDate(
    (section && (section.dateCreated || section.magazineDateCreated / 1000)) ||
      new Date().getTime() / 1000,
  );
}

function getDateModified(section) {
  return (
    getFormattedDate(
      section &&
        (section.lastModifiedDate || section.magazineDateLastPosted / 1000),
    ) || getDatePublished(section)
  );
}

function getRootTopic(section, smartMagazines) {
  if (section.rootTopic) {
    return section.rootTopic;
  }
  const rootTopic =
    isSmartMagazine(section) &&
    smartMagazines &&
    smartMagazines.find((smartMag) => smartMag.remoteid == section.remoteid)
      ?.rootTopic;

  return rootTopic || null;
}

/**
 * Returns the item cover from section
 * @param {Object} section  -
 * @return {Object}
 */
function getSectionCoverItem(section) {
  if (!section || !section.items) {
    return null;
  }
  const sectionCover = section.items.find((item) => item.isSectionCover);
  return sectionCover ? sectionCover : null;
}

/**
 * Returns whether or not a section is loading based on the
 * "loading" object in the sections reducer state.
 * @param {String} requestedRemoteId  - The originally requested remoteId of the section
 * @param {Object} loading  - Object containing loading status of sections by remoteid
 * @return {Boolean}        - True if the section is loading
 */
function isSectionLoading(requestedRemoteId, loadingState) {
  return !!loadingState[FlapUtil.normalizeRemoteid(requestedRemoteId)];
}

const getMetric = (section, metric) =>
  section?.metrics?.profileMetrics?.find((m) => m.type === metric);

const getInviteMessage = (section) => section?.inviteMessage;

function getUserid(section) {
  const { userid } = section;

  if (!isNaN(userid)) {
    return parseInt(userid, 10);
  }

  return userid;
}

/** Returns a projection of a section with only the
 * useful properties and derived properties for existing
 * React components.
 * @param {Object} section - Flipboard section
 * @param  {Array<rawItems>} rawItems - array of raw items
 * @return {Object}        - The projection of the section
 */
function projection(section, rawItems = [], options = {}) {
  if (typeof section === 'undefined' || section === null) {
    return null;
  }
  const { isLikesSection, rootTopic } = options;
  const {
    items = [],
    remoteid,
    sectionID,
    boardId,
    remoteidToShare,
    ssid,
    service,
    type,
    feedType,
    linkType,
    sourceURL,
    hidden,
    referringText,
    referringTextLoc,
    authorDisplayName,
    authorDescription,
    authorImage,
    authorUsername,
    username,
    topicTag,
    invite,
    partnerId,
    tileImage,
    sponsoredAuthor,
    brick,
    magazineTarget,
    magazineCoverItemId,
    joinTarget,
    nglHideHeaderBrackets,
    nglHeaderDescription,
    isNglPromoted,
    isFromContentGuide,
    subhead,
    version,
    dynamicFeed,
    canShare,
    isMember,
    subSections,
    companionBoardRemoteid,
    exchange,
    mastodonProfile,
    apEnabled,
    magazineFederatedURI,
    magazineFederatedName,
  } = section;

  let projected = Object.assign(
    {},
    {
      // pass-through:
      remoteid,
      boardId,
      sectionID,
      remoteidToShare,
      ssid,
      service,
      type,
      linkType,
      feedType,
      hidden,
      sourceURL,
      referringText,
      referringTextLoc,
      authorDisplayName,
      authorDescription,
      authorImage,
      authorUsername,
      username,
      magazineFederatedName,
      userid: getUserid(section),
      topicTag:
        topicTag ||
        (isTopic(section) && FlapUtil.getTopicTagFromRemoteid(remoteid)),
      invite,
      partnerId,
      cached: !section.private,
      tileImage,
      sponsoredAuthor,
      brick,
      magazineTarget,
      magazineCoverItemId,
      joinTarget,
      nglHideHeaderBrackets,
      nglHeaderDescription,
      isNglPromoted,
      isFromContentGuide,
      subhead,
      version,
      dynamicFeed,
      canShare,
      isMember,
      subSections,
      companionBoardRemoteid,
      exchange,
      mastodonProfile,
      apEnabled,
      magazineFederatedURI,
      // derived:
      normalizedRemoteid: FlapUtil.normalizeRemoteid(section.remoteid),
      title: getSectionTitle(section),
      image: image(section, rawItems),
      isTopic: isTopic(section),
      isMagazine: isMagazine(section),
      isSmartMagazine: isSmartMagazine(section),
      isStoryboard: isStoryboard(section),
      isProfile: isProfile(section),
      isContributor: isContributor(section),
      isService: isService(section),
      isTwitter: isTwitter(section),
      isFlipboard: isFlipboard(section),
      isCommunityGroup: isCommunityGroup(section),
      isNoIndex: isNoIndex(section),
      isRecommended: isRecommended(section),
      isPrivate: isPrivate(section),
      isLikesSection: isLikesSection || false,
      isVerifiedPublisher: isVerifiedPublisher(section),
      isVideoSection: !!section.videoIcon,
      isSeries: !!section.magazineIsSeries,
      isForYou: isForYou(section),
      isUserPreferenceable: isUserPreferenceable(section),
      author: getAuthor(section),
      authorURL: getAuthorUrl(section),
      items: items.map((item) => itemProjection(item)),
      description: description(section),
      appUrl: appUrl(section),
      avatarText: getAvatarText(section),
      metricsText: getMetricsText(section),
      metrics: getSectionMetrics(section),
      subsections: getSmartMagSubSections(section),
      isValid: isValid(section),
      coverImage: getCoverImage(rawItems) || section.image,
      storyboardDefinitionId: getStoryboardDefinitionId(section),
      datePublished: getDatePublished(section),
      dateModified: getDateModified(section),
      rootTopic: getBasicProjectedSection(section.rootTopic || rootTopic),
      stale: false,
      topics: [], // This should be subsequently populated if/when needed.
      mutedItems: [],
      inviteMessage: getInviteMessage(section),
    },
  );

  // NOTE FlipboardUrls.getSectionPath expects a projected section
  projected = Object.assign({}, projected, {
    canonicalPath: getSectionPath(projected),
  });

  if (GlobalVars.environment === 'development') {
    projected.rawSection = section;
  }

  return projected;
}

/**
 * There are a number of APIs that return section-like components (flab, flavour).
 * Takes in one of these section-like components and standardizes on a minimal set of parameters
 * to display links to said sections.
 * @param {*} section: The object coming from the various APIs.
 * @returns a Basic projected section that should be the minimal required to create a full featured
 * link to the actual section.
 */
export const getBasicProjectedSection = (section) => {
  if (isValueless(section)) {
    return null;
  }

  const {
    title,
    type,
    linkType,
    feedType,
    remoteid,
    sectionID,
    image,
    author,
    authorDisplayName,
    authorUsername,
    authorDescription,
    authorImage,
    sections,
    reason,
    isProfile,
    isMagazine,
    isTopic,
    isStoryboard,
    isSection,
    canonicalPath,
    metrics,
    companionBoardRemoteid,
    normalizedRemoteid,
    service,
    items,
  } = projection(section);
  const projectedSection = {
    title,
    type,
    feedType,
    linkType,
    remoteid,
    sectionID,
    image,
    author,
    authorDisplayName,
    authorUsername,
    authorDescription,
    authorImage,
    sections,
    reason,
    isProfile,
    isMagazine,
    isTopic,
    isStoryboard,
    isSection,
    canonicalPath,
    metrics,
    companionBoardRemoteid,
    normalizedRemoteid,
    service,
    items,
  };

  return projectedSection;
};

const sectionIdForUpdateFeedRequest = (section) =>
  section?.ssid?.remoteidPlain || section?.remoteid;

const getTopicSectionId = (section) => {
  if (section.isSmartMagazine) {
    return section.rootTopic?.normalizedRemoteid;
  }
  return section.normalizedRemoteid;
};

export default {
  appUrl,
  brickImage,
  customizedTopicText,
  description,
  getAuthor,
  getAuthorUrl,
  getAvatarText,
  hasItems,
  hasStoryboards,
  hasVideos,
  image,
  isCommunityGroup,
  isMagazine,
  isSmartMagazine,
  isNoIndex,
  isStoryboard,
  isProfile,
  isUserStoryboards,
  isContributor,
  isService,
  isTwitter,
  isFlipboard,
  isTopic,
  isPersonalizeSearchTrigger,
  isRecommended,
  isPrivate,
  isVerifiedPublisher,
  isValid,
  isOwner,
  isInCarouselFavorites,
  isMagOwner,
  isMagContributor,
  isItemRenderable,
  itemsToRender,
  projection,
  socialImage,
  text,
  getSectionTitle,
  getDecoratedSectionTitle,
  getSmartMagSubSections,
  getSectionMetrics,
  getMetricsCountValue,
  getInvite,
  compareByTitle,
  mightBeSameSection,
  sectionsIncludesSection,
  getCoverImage,
  getStoryboardDefinitionId,
  isSectionLoading,
  getDateModified,
  getDatePublished,
  getBasicProjectedSection,
  getRootTopic,
  getMetric,
  sectionIdForUpdateFeedRequest,
  isForYou,
  isUserPreferenceable,
  getSectionCoverItem,
  getTopicSectionId,
  getUserid,
};
