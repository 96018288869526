import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

import { GA } from 'Utils/analytics';

import { usageTrackSignupExit } from 'Webapp/shared/app/redux/actions/usage-actions';
import {
  onboardingDataSelector,
  onboardingSuccessSelector,
  getFrictionlessFlowUsageType,
} from 'Webapp/shared/concepts/onboarding';

const trackFrictionlessComplete: OnboardingStep =
  (nextStep) => (dispatch, getState) => {
    const onboardingData = onboardingDataSelector(getState());
    const success = onboardingSuccessSelector(getState());

    const frictionlessFlowType = onboardingData?.frictionlessFlowType;
    const appEnterType =
      frictionlessFlowType &&
      getFrictionlessFlowUsageType(frictionlessFlowType);
    if (success && !!frictionlessFlowType) {
      dispatch(usageTrackSignupExit(success, null, appEnterType));
      GA.trackFrictionlessSignupExit(success);
    }
    nextStep();
  };

export default trackFrictionlessComplete;
