import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Utils

// Components
import AnchoredDialog from 'ComponentLibrary/utility/anchored-dialog';

import withClickOutside from 'Webapp/shared/app/hocs/withClickOutside';

const UNALLOWED_ANCHORED_DIALOG_PROPS = [
  'children',
  'className',
  'fluencyName',
];

class EduDialog extends Component {
  constructor(props) {
    super(props);

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    if (this.props.onMount) {
      this.props.onMount();
    }
  }

  handleClickOutside() {
    this.props.onDismiss();
  }

  render() {
    const dialogProps = Object.assign({}, this.props);
    UNALLOWED_ANCHORED_DIALOG_PROPS.forEach((p) => delete dialogProps[p]);
    return (
      <AnchoredDialog
        className={this.props.className}
        styleVariation={AnchoredDialog.styleVariations.EDU}
        {...dialogProps}
      >
        {this.props.children}
      </AnchoredDialog>
    );
  }
}

EduDialog.propTypes = {
  className: PropTypes.string,
  onDismiss: PropTypes.func.isRequired,
  onMount: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

EduDialog.defaultProps = {
  className: '',
  fluencyName: null,
};

export default withClickOutside(EduDialog);
