/** Gets a nested property within obj whose path is defined by path
 * @param {Array} path - An array of increasing depth keys representing
 *                       the position of a nested property
 * @param {Object} obj - The object from which to retrieve the property
 */
function getFromPath(path, obj) {
  if (obj == null) {
    return null;
  }
  return path.reduce((accum, key) => {
    if (accum === null) {
      return null;
    }
    return accum[key] || null;
  }, obj);
}

/** Sets a nested property within obj whose path is defined by path
 * @param {Array} path - An array of increasing depth keys representing
 *                       the position of a nested property
 * @param {Object} obj - The object on which to set the property
 * @param {Any} value  - The new value for the specified property
 */
function setPath(path, obj, value) {
  if (!(path.length > 0)) {
    return false;
  }
  const parent = getFromPath(path.slice(0, -1), obj);
  const key = path[path.length - 1];
  parent[key] = value;
  return true;
}

/** Returns a property value associated with the first matched rule
 * @param {Array} rules - An array of rules that define which nested
 *                        object keys to use to retrieve a property
 * @param {Object} obj  - The object from which to retrieve the property
 */
function getFromRules(rules, obj) {
  const rule = rules.find(({ test }) => test(obj));
  if (rule === undefined) {
    return null;
  }
  return rule.get(obj);
}

/** Creates a set function that will set the property associated with the first matched rule
 * @param {Array} rules   - An array of rules that define which nested
 *                          keys to use to set a property
 * @param {Object} object - The object on which to set the property
 * @param {Any} value     - The new value for the specified property
 */
function setFromRules(rules, obj, value) {
  const rule = rules.find(({ test }) => test(obj));
  return rule === undefined ? null : rule.set(obj, value);
}

/** Creates a rule
 * @param {String} pathStr - A string representation of nested object key path
 *                           for example: section.mainItem.image
 * @param {Array} additionaltests    - An array of additional tests to be
 *                                     performed, in order, before applying
 *                                     the rule to an object
 * @return {Object}        - A rule object with the following properties:
 *                           path: path string
 *                           test: a function to determine whether to apply
 *                                 the rule to the object
 *                           get: a function to return the value at the path
 *                           set: a function to set the value at the path
 */
function createRule(pathStr, ...additionalTests) {
  const path = pathStr.split('.');
  return {
    path: pathStr,
    test(item) {
      const value = getFromPath(path, item);
      const exists = value != null;
      return additionalTests.reduce((passed, aTest) => {
        if (aTest) {
          return passed && aTest(item, value);
        }
        return passed;
      }, exists);
    },
    get: getFromPath.bind(null, path),
    set: setPath.bind(null, path),
  };
}

/** Returns new object with only specified keys
 * @param {Object} original     - Original object
 * @param {Array}  keys         - Keys to filter with
 * @return {Object}             - New object containing properties specified by keys
 * pairs corresponding to keys param.
 */
export const pick = (object = {}, keys = []) =>
  Object.keys(object).reduce((acc, key) => {
    if (keys.indexOf(key) > -1) {
      acc[key] = object[key];
    }
    return acc;
  }, {});

export const flatNotate = (object = {}, separator = '.', parentKeys = []) =>
  Object.keys(object).reduce((acc, key) => {
    const value = object[key];
    const newParentKeys = [...parentKeys, key];
    const completeKey = newParentKeys.join(separator);
    if (value && Object.getPrototypeOf(value) === Object.prototype) {
      Object.assign(acc, flatNotate(value, separator, newParentKeys));
    } else {
      acc[completeKey] = value;
    }
    return acc;
  }, {});

export default {
  getFromRules,
  getFromPath,
  setFromRules,
  createRule,
  pick,
  flatNotate,
};
