import styled from '@emotion/styled';
import { NotificationContentComponent } from 'Webapp/shared/app/components/notifications/notification-text-click-strategies/index';

import SectionUtil from 'Utils/content/section-util';
import sentenceCase from 'Utils/content/sentence-case';

import Button, { BUTTON_STYLES } from 'Webapp/shared/app/components/button';
import ItemLink from 'Webapp/shared/app/components/item/item-link.js';
import NotificationThumb from 'Webapp/shared/app/components/notifications/notification-thumb';
import NotificationTime from 'Webapp/shared/app/components/notifications/notification-time';

import { styleObject } from 'Style/type-helpers';
import { SPACING } from 'Style/spacing';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Webapp/utils/connector';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';
import CommentsModal, {
  CommentsModalPassedProps,
} from 'Webapp/shared/app/modals/comments';

const StyledItemLink = styled(ItemLink)({
  display: 'contents',
});
const CommentWrapper = styled.div({
  display: 'grid',
  gridTemplateRows: '1fr auto',
  gap: SPACING.SMALL,
});
const CommentMetaWrapper = styled.div({
  display: 'grid',
  gridTemplateRows: 'auto auto',
  justifyItems: 'end',
  gap: SPACING.SMALL,
  marginBottom: 'auto',
});

const StyledReplyButton = styled(Button)(styleObject(BUTTON_STYLES.SECONDARY), {
  padding: `${SPACING.SMALL} ${SPACING.MEDIUM}`,
});

type CommentProps = {
  notification: Flipboard.Notification;
  t: Flipboard.TFunction;
} & ConnectModalProps;

const Comment: NotificationContentComponent<CommentProps> = ({
  notification,
  showModal,
  children,
  t,
}) => {
  const item = notification?.referredByItems?.[0];
  const section = notification.sectionLinks?.[0];
  if (!item || !section) {
    return null;
  }

  const onClick = () =>
    showModal<CommentsModalPassedProps>(CommentsModal, {
      item,
      section: SectionUtil.projection(section),
      expandCommentsHeader: true,
    });

  return (
    <StyledItemLink item={item} shownInList forceInternal>
      <CommentWrapper onClick={onClick}>
        {children}
        <NotificationTime notification={notification} />
      </CommentWrapper>
      <CommentMetaWrapper onClick={onClick}>
        <NotificationThumb
          notification={notification}
          shownInList
          disableLink
        />
        <StyledReplyButton tooltip={t('comment')}>
          {sentenceCase(t('reply'))}
        </StyledReplyButton>
      </CommentMetaWrapper>
    </StyledItemLink>
  );
};

export default connector<CommentProps>(connectModal)(withT(Comment));
