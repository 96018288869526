import { Breadcrumbs } from 'Utils/content/structured-data';
import { duc } from 'Utils/url';

export default ({ sitemapPage, type, filter, page }) => {
  if (!sitemapPage) {
    return null;
  }
  const breadcrumbs = new Breadcrumbs();
  breadcrumbs.add('Sitemap', '/sitemap');
  breadcrumbs.add(type.toUpperCase(), `/sitemap/${type}`);
  if (filter) {
    breadcrumbs.add(duc(filter), `/sitemap/${type}/${filter}`);
    if (page) {
      breadcrumbs.add(page, `/sitemap/${type}/${filter}/${page}`);
    }
  }
  return breadcrumbs;
};
