import {
  usageSetNavFrom,
  usageTrackItemEnter,
} from 'Webapp/shared/app/redux/actions/usage-actions';

export default {
  actions: {
    usageSetNavFrom,
    usageTrackItemEnter,
  },
};
