import sentry from 'Utils/sentry';
import { BadSharedLinkDataError, FetchSharedLinkDataError } from 'Utils/errors';
import { fetchSharedLinkData } from 'Webapp/shared/utils/branch';
import { set, setPayloadReducer } from 'Webapp/utils/redux';
import { SHARED_LINK_TYPES } from 'Webapp/action-types';

// reducer
type SharedLinkState = {
  data: Flipboard.SharedLinkData | null;
};
const initialState: SharedLinkState = {
  data: null,
};
export const reducer = setPayloadReducer<
  typeof SHARED_LINK_TYPES,
  SharedLinkState
>(SHARED_LINK_TYPES, initialState);

// actions
const setSharedLinkData = (value: SharedLinkState['data']) =>
  set(SHARED_LINK_TYPES.SET_SHARED_LINK_DATA, 'data', value);

export const clearSharedLinkData = () => setSharedLinkData(null);

export const getSharedLinkData =
  (existingValue?: Flipboard.SharedLinkData): Flipboard.Thunk =>
  async (dispatch, getState) => {
    if (existingValue) {
      dispatch(setSharedLinkData(existingValue));
      return;
    }
    try {
      const sharedLinkData = await fetchSharedLinkData(getState());
      if (!sharedLinkData.remoteId) {
        sentry.captureException(new BadSharedLinkDataError(), {
          sharedLinkData: JSON.stringify(sharedLinkData),
          routing: JSON.stringify(getState().app.routing),
        });
      }
      dispatch(setSharedLinkData(sharedLinkData));
    } catch (e) {
      sentry.captureException(e as Error);
      sentry.captureException(new FetchSharedLinkDataError());
    }
  };

// selectors
export const sharedLinkDataSelector = ({
  sharedLinkData: { data },
}: Flipboard.State) => data;
