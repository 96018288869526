import connector, { Connector } from 'Webapp/utils/connector';
import { shouldRenderBanner } from 'Webapp/shared/app/redux/selectors/banners';
import TopLevelRoutes from 'Webapp/shared/app/components/top-level-routes';

const selectors = {
  shouldRenderBanner,
};

const topLevelRoutesConnector: Connector = {
  selectors,
};

const TopLevelRoutesContainer = connector(topLevelRoutesConnector)(
  TopLevelRoutes,
);

export default TopLevelRoutesContainer;
