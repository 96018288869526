import React from 'react';
import Icon from './icon';

function ClearIcon(props) {
  return (
    <Icon
      name="clear"
      size={14}
      viewBox="0 0 21 21"
      {...props}
      renderComponent={({ color }) => (
        <g
          transform="translate(-16.000000, -16.000000)"
          fill={color}
          fillRule="evenodd"
          stroke="none"
        >
          <path d="M26.5,16 C32.2989899,16 37,20.7010101 37,26.5 C37,32.2989899 32.2989899,37 26.5,37 C20.7010101,37 16,32.2989899 16,26.5 C16,20.7010101 20.7010101,16 26.5,16 Z M30.25,22 L26.5,25.75 L22.75,22 L22,22.75 L25.75,26.5 L22,30.25 L22.75,31 L26.5,27.25 L30.25,31 L31,30.25 L27.25,26.5 L31,22.75 L30.25,22 Z" />
        </g>
      )}
    />
  );
}

export default ClearIcon;
