import getCurrentSection from 'Utils/seo/shared/get-current-section';

export default ({ section }, getState, { t }) => {
  if (section) {
    const currentSection = getCurrentSection(getState());
    if (currentSection && currentSection.isProfile) {
      const { authorDisplayName, authorUsername } = currentSection;
      const authorName = authorDisplayName || authorUsername;
      return `${authorName} (@${authorUsername}) ${t('on_flipboard')}`;
    }
  }
};
