import React from 'react';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import {
  COLORS,
  TEXT_COLORS,
  SURFACE_COLORS,
  DIVIDER_COLORS,
} from 'Style/colors';
import { FONTS, FONT_WEIGHTS } from 'Style/typography';

// Utils
import { GA } from 'Utils/analytics';
import sentenceCase from 'Utils/content/sentence-case';
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';
// Components
import WebLink from 'Webapp/shared/app/components/web-link.js';
import LoadingSpinner from 'ComponentLibrary/icons/loading-spinner';
import { omit } from 'lodash';

export enum StyleVariations {
  NONE = 'none',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  UNSELECTED = 'unselected',
  PRIMARY_TEXT = 'primary-text',
  WHITE_OVERLAY = 'white-overlay',
  FOLLOWED_TOPIC = 'followed-topic',
  UNFOLLOWED_TOPIC = 'unfollowed-topic',
}

export enum StyleModifiers {
  NONE = 'none',
  LITTLE = 'little',
  FAT = 'fat',
  INVERSE = 'inverse',
  BLOCK = 'block',
  WITH_BADGE = 'with-badge',
  INLINE_LINK = 'inline-link',
  NO_LEFT_PADDING = 'no-left-padding',
  NO_RIGHT_PADDING = 'no-right-padding',
  CONDENSED_TEXT = 'condensed-text', // currently only supported on text primary variation
  ALIGN_LEFT = 'align-left',
}
const BUTTON_ACTIVE_STATE_SHADOW = {
  boxShadow: `inset -1px 2px 1px rgba(${COLORS.staticBlack}, 0.2)`,
};
const BUTTON_DISABLED = {
  opacity: '0.3',
  cursor: 'not-allowed',
  // keeps: active;
  pointerEvents: 'none',
};

const BASE_BUTTON_STYLE = {
  border: 'none',
  fontSize: '13px',
  lineHeight: '16px`',
  padding: `${SPACING.BASE} ${SPACING.MEDIUM}`,
  fontFamily: FONTS.FONT_FAKT_FLIPBOARD,
  fontWeight: FONT_WEIGHTS.SEMIBOLD,
  color: TEXT_COLORS.secondary,
  borderRadius: '4px',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  display: 'inline-block',
  whitespace: 'nowrap',
  transition: 'all $animation--duration $animation--timing',
  '&:hover,&:focus': {
    color: TEXT_COLORS.primary,
  },
  '&:active': {
    ...BUTTON_ACTIVE_STATE_SHADOW,
    color: TEXT_COLORS.secondary,
  },
};

export const BUTTON_STYLES = Object.freeze({
  SECONDARY: {
    ...BASE_BUTTON_STYLE,
    color: TEXT_COLORS.secondary,
    border: `1px solid ${DIVIDER_COLORS.secondary}`,
    backgroundColor: SURFACE_COLORS.primary,
    '&:hover,&:focus': {
      color: TEXT_COLORS.primary,
      border: `1px solid ${DIVIDER_COLORS.primary}`,
    },
    '&:active': {
      ...BUTTON_ACTIVE_STATE_SHADOW,
      color: TEXT_COLORS.secondary,
      border: `1px solid ${DIVIDER_COLORS.secondary}`,
    },
    '&:disabled': BUTTON_DISABLED,
  },
  FAT: {
    fontSize: '16px',
    lineHeight: '3.5',
    padding: `0 ${SPACING.BASE4X}`,
  },
  BLOCK: {
    display: 'block',
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: SPACING.MEDIUM,
    },
  },
  OVERLAY: {
    color: TEXT_COLORS.overlay,
  },
  RED_LINK: {
    color: TEXT_COLORS.redLink,
    '&:hover': {
      color: TEXT_COLORS.redLinkHover,
    },
  },
});

interface CommonProps {
  name?: string;
  navFromEventName?: typeof USAGE_EVENT_NAMES[keyof typeof USAGE_EVENT_NAMES];
  label?: string;
  className?: string;
  styleVariation?: StyleVariations;
  styleModifier?: Array<StyleModifiers>;
  clickEventDetails?: object;
  loading?: boolean;
  tooltip?: string;
  preventSentenceCase?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  disabled?: boolean;
}

type AnchorProps = {
  openInNewWindow?: boolean;
  disabled?: boolean;
} & HTMLAnchorElement;

type ButtonProps = {
  ariaDisabled?: boolean;
  ariaInvalid?: boolean;
  ariaDescribedby?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export type CombinedProps = CommonProps & (AnchorProps | ButtonProps);

const StyledButton = styled('button')({
  '&:focus': {
    backgroundColor: SURFACE_COLORS.primary,
    color: TEXT_COLORS.primary,
    '> *': { color: TEXT_COLORS.primary },
  },
});

const isLink = (props: CombinedProps): props is CommonProps & AnchorProps =>
  'href' in props;

const isButton = (props: CombinedProps): props is CommonProps & ButtonProps =>
  !isLink(props);

const withoutCommonProps = (props: CombinedProps) =>
  omit(
    props,
    'label',
    'preventSentenceCase',
    'className',
    'styleVariation',
    'styleModifier',
    'loading',
    'disabled',
    'clickEventDetails',
    'onClick',
    'tooltip',
    'children',
    'name',
  );

const Button = React.forwardRef<HTMLButtonElement, CombinedProps>(
  (props, ref) => {
    const {
      label,
      preventSentenceCase,
      className,
      styleVariation,
      styleModifier,
      loading,
      disabled,
      clickEventDetails,
      onClick,
      tooltip,
      children,
      name,
    } = props;
    const sentenceCasedLabel = preventSentenceCase
      ? label
      : sentenceCase(label);

    const handleClick = (e: React.MouseEvent) => {
      if (disabled) {
        e.preventDefault();
      }
      GA.trackClickButton(name, clickEventDetails);
      if (onClick && !loading) {
        return onClick(e);
      }
    };

    const buttonClasses = classNames(className, {
      'button--base': true,
      'button--primary': styleVariation === StyleVariations.PRIMARY,
      'button--secondary': styleVariation === StyleVariations.SECONDARY,
      'button--unselected': styleVariation === StyleVariations.UNSELECTED,
      'button--primary-text': styleVariation === StyleVariations.PRIMARY_TEXT,
      'button--white-overlay': styleVariation === StyleVariations.WHITE_OVERLAY,
      'button-base-topic':
        styleVariation === StyleVariations.FOLLOWED_TOPIC ||
        styleVariation === StyleVariations.UNFOLLOWED_TOPIC,
      'button--followed-topic':
        styleVariation === StyleVariations.FOLLOWED_TOPIC,
      'button--unfollowed-topic':
        styleVariation === StyleVariations.UNFOLLOWED_TOPIC,
      'button--inverse': styleModifier?.includes(StyleModifiers.INVERSE),
      'button--little': styleModifier?.includes(StyleModifiers.LITTLE),
      'button--fat': styleModifier?.includes(StyleModifiers.FAT),
      'button--block': styleModifier?.includes(StyleModifiers.BLOCK),
      'button--inline-link': styleModifier?.includes(
        StyleModifiers.INLINE_LINK,
      ),
      'button--condensed-text': styleModifier?.includes(
        StyleModifiers.CONDENSED_TEXT,
      ),
      'button--with-badge': styleModifier?.includes(StyleModifiers.WITH_BADGE),
      'button--no-left-padding': styleModifier?.includes(
        StyleModifiers.NO_LEFT_PADDING,
      ),
      'button--no-right-padding': styleModifier?.includes(
        StyleModifiers.NO_RIGHT_PADDING,
      ),
      'button--loading': loading,
      'button--align-left': styleModifier?.includes(StyleModifiers.ALIGN_LEFT),
    });

    if (isLink(props)) {
      const everythingElse = withoutCommonProps(props) as AnchorProps;
      return (
        <WebLink
          {...everythingElse}
          ref={ref}
          buttonName={name}
          className={buttonClasses}
          onClick={handleClick}
          title={tooltip}
        >
          {sentenceCasedLabel || children}
        </WebLink>
      );
    }

    if (isButton(props)) {
      const { ariaDisabled, ariaInvalid, ariaDescribedby, ...restProps } =
        props;

      const everythingElse = withoutCommonProps(restProps) as ButtonProps;
      return (
        <StyledButton
          {...everythingElse}
          ref={ref}
          className={buttonClasses}
          disabled={!ariaDisabled && (disabled || loading)}
          aria-disabled={ariaDisabled || loading ? 'true' : 'false'}
          aria-invalid={ariaInvalid}
          aria-describedby={ariaInvalid ? ariaDescribedby : undefined}
          onClick={handleClick}
          data-vars-button-name={name}
          data-ga-target="button"
          title={tooltip}
        >
          {(loading && <LoadingSpinner />) || sentenceCasedLabel || children}
        </StyledButton>
      );
    }
    // shouldn't be able to get here without a type error somewhere
    return null;
  },
);

export default Button;
