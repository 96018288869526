import getWindow from 'Utils/get-window';
import Debouncer from './debouncer';

const RESIZE_INTERVAL_MS = 200;

class WindowResize {
  constructor(onResize) {
    const debounced = Debouncer.create(onResize, RESIZE_INTERVAL_MS);
    this.throttledOnResize = () => {
      this.cancel = debounced();
    };
  }

  /**
   * Adds a listener for window resizing that calls a callback
   * @param {function} onResize A callback function to call on every resize event (throttled)
   */
  subscribe() {
    getWindow().addEventListener(
      'orientationchange',
      this.throttledOnResize,
      false,
    );
    getWindow().addEventListener('resize', this.throttledOnResize, false);
  }

  /**
   * Removes a listener for window resizing
   * @param {function} onResize A callback function to remove with the listener
   */
  unsubscribe() {
    // Cancel any pending executions
    if (this.cancel) {
      this.cancel();
    }

    getWindow().removeEventListener(
      'orientationchange',
      this.throttledOnResize,
      false,
    );
    getWindow().removeEventListener('resize', this.throttledOnResize, false);
  }
}

export default WindowResize;
