/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Input, { InputIcons } from 'Webapp/shared/app/components/base/input';
import SearchIcon from 'ComponentLibrary/icons/search-new';
import UnstyledButton from 'Webapp/shared/app/components/unstyled-button';
import { StyledNavBarButton } from './logged-in-buttons';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';

function Search({
  isAmp,
  forwardedRef,
  onClick,
  isAuthenticated,
  isLargeScreen,
  isPhone,
  t,
}) {
  if (isAmp) {
    return null;
  }

  if (isAuthenticated || !isLargeScreen) {
    return (
      <li>
        <StyledNavBarButton
          name="navbar-search-icon"
          onClick={onClick}
          stopPropagation={false}
        >
          <SearchIcon toolTip={t('search')} />
          {!isPhone && <span>{t('search')}</span>}
        </StyledNavBarButton>
      </li>
    );
  }
  return (
    <li className="main-nav__search-input-wrapper" ref={forwardedRef}>
      <UnstyledButton
        name="navbar-search-input"
        onClick={onClick}
        stopPropagation={false}
      >
        <Input
          tabIndex={-1}
          name="searchNav"
          placeholder={t('search_flipboard')}
          icon={InputIcons.SEARCH}
        />
      </UnstyledButton>
    </li>
  );
}

Search.propTypes = {
  isAmp: PropTypes.bool,
  forwardedRef: PropTypes.object,
  onClick: PropTypes.func,
  isLargeScreen: PropTypes.bool.isRequired,
  isPhone: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

Search.defaultProps = {
  isAmp: false,
};

const WrappedComponent = connector(
  connectAuthentication,
  connectResponsive,
)(withT(Search));

export default React.forwardRef((props, ref) => (
  <WrappedComponent {...props} forwardedRef={ref} />
));
