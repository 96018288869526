import RenderHintUtil from 'Utils/render-hint-util';
import { getArticlePath, getSectionPath } from 'Utils/content/flipboard-urls';
import StoryboardSkeletonUtil from 'Utils/content/storyboard-skeleton-util';

const sizes = {
  SMALL: 0,
  MEDIUM: 1,
  LARGE: 2,
};

/** Returns an image if the item has a "headerImageSquareURL" specified
 * in the customzier itemRenderHints
 * @param {Object} coverItem   - Flipboard storyboard cover item
 * @return {Object}            - An image object or null
 */
function headerImageSquare(coverItem) {
  const customizations = StoryboardSkeletonUtil.getCustomizations(coverItem);
  const itemRenderHints = customizations && customizations.itemRenderHints;
  const headerImageSquareURL =
    (itemRenderHints && itemRenderHints.headerImageSquareURL) || null;

  if (headerImageSquareURL) {
    return {
      largeURL: headerImageSquareURL,
    };
  }

  return null;
}

/** Returns whether the header brackets should be hidden
 * @param {Object} coverItem  - Flipboard storyboard cover item
 * @return {Boolean}          - True if the brackets should be hidden
 */
function isHiddenBracketCover(coverItem) {
  const customizations = StoryboardSkeletonUtil.getCustomizations(coverItem);
  const itemRenderHints = customizations && customizations.itemRenderHints;

  return (itemRenderHints && itemRenderHints.hideHeaderBrackets) || false;
}

/**
 * Returns whether to hide the header image.
 * @param {Object} coverItem  - Flipboard storyboard cover item
 * @return {Boolean}          - True if the header image should be hidden
 */
function isHiddenHeaderImage(coverItem) {
  const customizations = StoryboardSkeletonUtil.getCustomizations(coverItem);
  const itemRenderHints = customizations && customizations.itemRenderHints;
  return (itemRenderHints && itemRenderHints.hideHeaderImage) || false;
}

/** Returns the value for a header description override
 * @param {Object} coverItem   - Flipboard storyboard cover item
 * @return {String}            - The value from the cover item or null
 */
function headerDescriptionOverride(coverItem = {}) {
  // If coverItem is null, it won't take the default param value of "{}". Default params only
  // cover undefined values.
  const customizations = StoryboardSkeletonUtil.getCustomizations(coverItem);
  const itemRenderHints = customizations && customizations.itemRenderHints;
  return (itemRenderHints && itemRenderHints.headerDescription) || null;
}

function isNumberedGroup(group) {
  return StoryboardSkeletonUtil.getIsNumbered(group) || false;
}

function isIntroGroup(group) {
  const itemRenderHints = getGroupCustomization(group, 'itemRenderHints');
  return itemRenderHints && itemRenderHints.style === 'intro';
}

function groupSize(group) {
  const sizeString = StoryboardSkeletonUtil.getDisplaySize(group) || 'large';

  let size = sizes.LARGE;
  switch (sizeString.toLowerCase()) {
    case 'small':
      size = sizes.SMALL;
      break;
    case 'medium':
      size = sizes.MEDIUM;
      break;
    case 'large':
      size = sizes.LARGE;
      break;
    default:
      break;
  }

  return size;
}

function getGroupCustomization(group, key) {
  return (
    (group &&
      group.customizer &&
      group.customizer.customizations &&
      group.customizer.customizations[key]) ||
    null
  );
}

function groupTitle(group) {
  return getGroupCustomization(group, 'title');
}

function groupSubtitle(group) {
  return getGroupCustomization(group, 'text');
}

function groupTopics(group) {
  const itemRenderHints = getGroupCustomization(group, 'itemRenderHints');
  return (itemRenderHints && itemRenderHints.topics) || null;
}

/**
 * Returns the URL to use when rendering items in storyboards
 * @param {Object} item     - A Flipboard item
 * @return {String}         - The sourceURL of the item.  If the item is
 * a section item, then the section path is returned.
 */
function itemUrl(item) {
  const {
    isSection,
    isArticle,
    sourceURL,
    isFlipboardInternalContent,
    section,
  } = item;

  if (isFlipboardInternalContent && isArticle) {
    return getArticlePath(item, section);
  }

  return isSection ? getSectionPath(item.section) : sourceURL;
}

const getCoverImage = (item, section) => {
  if (item && item.image) {
    return item.image;
  }
  return section.image;
};

const getColorPalette = (image) => {
  const hints = image.hints || image.original_hints;
  const hintObj = RenderHintUtil.parseHints(hints);

  if (typeof hintObj.backgroundcolor !== 'undefined') {
    return hintObj.backgroundcolor;
  }
  return null;
};
/**
 * Returns a background color style override associated with the image as defined in the image hints.
 * Platform returns to us a hint in the format 'backgroundcolor-6b6b6b-4c4c4c'. This represents
 * a common palette to be used to style the storyboard page.
 * @param {Object} image object
 * @param {Number} colorIndex of the palette color;
 * @return {Object} CSS Style object to set/override the bg color palette of an object.
 */
const getBackgroundColorStyle = (image, colorIndex = 0) => {
  if (typeof image === 'undefined' || image === null) {
    return null;
  }
  const palette = getColorPalette(image);
  if (palette) {
    const color = palette[colorIndex];
    return color ? { backgroundColor: `#${color}` } : null;
  }
  return null;
};

/**
 * Returns a border color style override associated with the image as defined in the image hints.
 * Platform returns to us a hint in the format 'backgroundcolor-6b6b6b-4c4c4c'. This represents
 * a common palette to be used to style the storyboard page.
 * @param {Object} image object
 * @param {Number} colorIndex of the palette color;
 * @return {Object} CSS Style object to set/override the bg color palette of an object.
 */
const getBorderColorStyle = (image, colorIndex = 0) => {
  if (typeof image === 'undefined' || image === null) {
    return null;
  }
  const palette = getColorPalette(image);
  if (palette) {
    const color = palette[colorIndex];
    return color ? { borderColor: `#${color}` } : null;
  }
  return null;
};

export default {
  headerImageSquare,
  headerDescriptionOverride,
  isHiddenBracketCover,
  isHiddenHeaderImage,
  isNumberedGroup,
  isIntroGroup,
  groupSize,
  groupTitle,
  groupSubtitle,
  groupTopics,
  sizes,
  itemUrl,
  getCoverImage,
  getColorPalette,
  getBackgroundColorStyle,
  getBorderColorStyle,
};
