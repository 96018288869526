import { connect } from 'react-redux';
import FlapUtil from 'Utils/content/flap-util';
import { getSectionId } from 'Utils/content/flipboard-urls';
import Banner from '../components/banner';

const mapStateToProps = (state) => {
  const {
    app: { isAmp, banners, routing },
    sections,
    profile,
  } = state;
  let currentSection;
  if (routing.url === '/') {
    currentSection = { remoteid: 'home', title: 'cover-stories' };
  } else if (isAmp || routing.query.inviteToken) {
    [currentSection] = sections.entries;
  } else {
    const { params } = routing;
    const sectionId = getSectionId(params);
    const decodedId = decodeURIComponent(sectionId);
    currentSection =
      decodedId && FlapUtil.getSectionByRemoteId(decodedId, sections.entries);
  }

  return {
    banners,
    section: currentSection,
    userInfo: profile.userInfo,
  };
};

const BannerContainer = connect(mapStateToProps)(Banner);

export default BannerContainer;
