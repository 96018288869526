import {
  PROFILE_TYPES,
  MAGAZINE_TYPES,
  CURATOR_PRO_MAGAZINE_TYPES,
  SOCIAL_ACTIVITY_TYPES,
} from 'Webapp/action-types';
import SectionUtil from 'Utils/content/section-util';
import { merger } from 'Utils/redux';

interface ProfileReducerState {
  userInfo: null | Flipboard.UserInfo;
  isLoading: boolean;
  profileLoaded: boolean;
  isUserStateLoading: boolean;
  magazines: Array<Flipboard.Section>;
  contributorMagazines: Array<unknown>;
  smartMagazines: Array<Flipboard.Section>;
  communityGroups: Array<unknown>;
  followers: Array<unknown>;
  followersNextPageKey: null | Flipboard.NextPageKey;
  followersIsLoading: boolean;
  followingIdsLoaded: boolean;
  followingIds: Array<Flipboard.SectionId>;
  following: Array<Flipboard.BasicSection>;
  followingStale: boolean;
  followingLoading: boolean;
  followingLoaded: boolean;
  followingSampleLoaded: boolean;
  stats: null | unknown;
  favorites: Array<Flipboard.Favorite>;
  previousFavorites: Array<Flipboard.Favorite>;
  favoritesUpdating: boolean;
  carouselVersion: number;
  lastFavoritedSectionId: null | Flipboard.SectionId;
  postOnboardingSocialAction: {
    like: false | unknown;
    follow: false | unknown;
    favorite: false | unknown;
    flip: false | unknown;
    accept_invite: false | unknown;
  };
}

const initialState: ProfileReducerState = {
  userInfo: null,
  isLoading: false,
  profileLoaded: false,
  isUserStateLoading: false,
  magazines: [],
  contributorMagazines: [],
  smartMagazines: [],
  communityGroups: [],
  followers: [],
  followersNextPageKey: null,
  followersIsLoading: false,
  followingIdsLoaded: false,
  followingIds: [],
  following: [],
  followingStale: false,
  followingLoading: false,
  followingLoaded: false,
  followingSampleLoaded: false,
  stats: null,
  favorites: [],
  favoritesUpdating: false,
  previousFavorites: [],
  carouselVersion: 0,
  lastFavoritedSectionId: null,
  postOnboardingSocialAction: {
    like: false,
    follow: false,
    favorite: false,
    flip: false,
    accept_invite: false,
  },
};

const getFollowingIds = (state) => {
  const userInfoState = state.userInfo?.states?.find(
    (state) => state.type === 'user',
  );
  const following = userInfoState?.data?.tocSections || [];
  // Some TOC sections are not "valid"
  // Smart magazines aren't technically being "followed"
  // RSS feed sections do not have service=flipboard and have feedType=feed
  // There are multiple Twitter section types, which have service=twitter and either feedType=feed or feedType=profile, depending
  return following
    .filter(
      (s) =>
        SectionUtil.isValid(s) &&
        !SectionUtil.isSmartMagazine(s) &&
        (s.service === 'flipboard' ||
          s.feedType === 'magazine' ||
          s.feedType === 'feed' ||
          s.feedType === 'profile' ||
          s.service === 'twitter'),
    )
    .map((s) => SectionUtil.sectionIdForUpdateFeedRequest(s));
};

export default function profileReducer(state = initialState, action) {
  const merge = merger(state);
  switch (action.type) {
    case PROFILE_TYPES.GET_PROFILE_PENDING: {
      return merge({ isLoading: true, profileLoaded: false });
    }
    case PROFILE_TYPES.GET_PROFILE_SUCCESS: {
      return merge({ isLoading: false, profileLoaded: true });
    }
    case PROFILE_TYPES.GET_PROFILE_INFO_SUCCESS: {
      const contributorMagazines = action.data.contributorMagazines || [];
      return merge({
        userInfo: Object.assign({}, action.data, {
          privateProfile: action.data.privateProfile || false,
        }),
        magazines: action.data.magazines.map((s) => SectionUtil.projection(s)),
        contributorMagazines: contributorMagazines.map((s) =>
          SectionUtil.projection(s),
        ),
        isUserStateLoading: false,
        followingIdsLoaded: true,
      });
    }
    case PROFILE_TYPES.UPDATE_FOLLOWING_IDS: {
      return merge({
        followingIds: getFollowingIds(state),
      });
    }
    case PROFILE_TYPES.GET_PROFILE_SMART_MAGAZINES_SUCCESS: {
      return merge({
        smartMagazines: action.data.map((s) => {
          const projection = SectionUtil.projection(s);
          const prevSection = state.smartMagazines.find(
            (m) => m.remoteid === s.remoteid,
          );
          if (prevSection) {
            projection.image = prevSection.image;
          }
          return projection;
        }),
      });
    }
    case PROFILE_TYPES.GET_SMART_MAGAZINES_COVERS_SUCCESS: {
      return merge({
        smartMagazines: state.smartMagazines.map((m) => ({
          ...m,
          image: m.image || action.covers[m.remoteid],
        })),
      });
    }
    case PROFILE_TYPES.GET_PROFILE_COMMUNITY_GROUPS_SUCCESS: {
      return merge({
        communityGroups: action.data.map((s) => SectionUtil.projection(s)),
      });
    }
    case PROFILE_TYPES.GET_PROFILE_FOLLOWERS: {
      return merge({ followersIsLoading: true });
    }
    case PROFILE_TYPES.GET_PROFILE_FOLLOWERS_SUCCESS: {
      const { data, nextPageKey, isFirstPage } = action;
      const newFollowers = data.map((s) => SectionUtil.projection(s));
      const updatedFollowers = isFirstPage
        ? newFollowers
        : state.followers.concat(newFollowers);
      return merge({
        followers: updatedFollowers,
        followersNextPageKey: nextPageKey,
        followersIsLoading: false,
      });
    }
    case PROFILE_TYPES.GET_PROFILE_FOLLOWERS_FAILED: {
      return merge({ followersIsLoading: true });
    }
    case PROFILE_TYPES.GET_PROFILE_STATS_SUCCESS: {
      return merge({
        stats: action.data,
      });
    }
    case PROFILE_TYPES.GET_FAVORITES_SUCCESS: {
      return merge({
        favorites: action.data.map((s) => SectionUtil.projection(s)),
        carouselVersion: action.version,
      });
    }
    case PROFILE_TYPES.ADD_CAROUSEL_FAVORITE_PENDING: {
      return merge({
        favoritesUpdating: true,
      });
    }
    case PROFILE_TYPES.ADD_CAROUSEL_FAVORITE_SUCCESS: {
      return merge({
        favoritesUpdating: false,
        carouselVersion: action.version,
      });
    }
    case PROFILE_TYPES.ADD_CAROUSEL_FAVORITE_FAILURE: {
      return merge({
        favoritesUpdating: false,
      });
    }
    case PROFILE_TYPES.SET_RECENTLY_PERSONALIZED_TOPIC: {
      return merge(action.payload);
    }
    case PROFILE_TYPES.MOVE_CAROUSEL_FAVORITE_PENDING: {
      const dynamicItems = state.favorites.filter((f) => f.dynamicFeed);
      const normalItems = state.favorites.filter((f) => !f.dynamicFeed);
      normalItems.splice(
        action.toIndex,
        0,
        normalItems.splice(action.fromIndex, 1)[0],
      );
      return merge({
        favorites: dynamicItems.concat(normalItems),
        favoritesUpdating: true,
        previousFavorites: state.favorites,
      });
    }
    case PROFILE_TYPES.MOVE_CAROUSEL_FAVORITE_SUCCESS: {
      return merge({
        carouselVersion: action.version,
        favoritesUpdating: false,
        previousFavorites: [],
      });
    }
    case PROFILE_TYPES.MOVE_CAROUSEL_FAVORITE_FAILURE: {
      return merge({
        favoritesUpdating: false,
        favorites: state.previousFavorites,
      });
    }
    case PROFILE_TYPES.DELETE_SMART_MAGAZINE_SUCCESS: {
      return merge({
        smartMagazines: state.smartMagazines.filter(
          (m) => m.boardId !== action.boardId,
        ),
      });
    }
    case PROFILE_TYPES.DELETE_CAROUSEL_FAVORITE_PENDING: {
      return merge({
        favoritesUpdating: true,
      });
    }
    case PROFILE_TYPES.DELETE_CAROUSEL_FAVORITE_FAILURE: {
      return merge({
        favoritesUpdating: false,
      });
    }
    case PROFILE_TYPES.DELETE_CAROUSEL_FAVORITE_SUCCESS: {
      return merge({
        favorites: state.favorites.filter(
          (f) => f.remoteid !== action.sectionId,
        ),
        favoritesUpdating: false,
        carouselVersion: action.version,
      });
    }
    case PROFILE_TYPES.ADD_SMART_MAGAZINE_CAROUSEL_SUCCESS: {
      const updatedFavorites = state.favorites.map((favorite) => {
        if (favorite.remoteid === action.remoteid) {
          return { ...favorite, isNewFavorite: true };
        }
        return favorite;
      });

      return merge({
        favorites: updatedFavorites,
      });
    }
    case MAGAZINE_TYPES.CREATE_MAGAZINE_SUCCESS: {
      const magazines = [action.magazine, ...state.magazines];
      return merge({ magazines });
    }
    case PROFILE_TYPES.GET_PROFILE_INFO: {
      return merge({ isUserStateLoading: true });
    }
    case PROFILE_TYPES.FOLLOW_SECTION: {
      const followedRemoteIds = action.remoteIds;
      // Optimistically add remoteIds to followed.
      // Will be removed via fetching user info later
      // if failed.
      return merge({
        followingStale: true,
        followingIds: state.followingIds.concat(followedRemoteIds),
      });
    }
    case PROFILE_TYPES.UNFOLLOW_SECTION: {
      // Optimistically remove remoteIds to unfollowed.
      // Will be removed via fetching user info later
      // if failed.
      return merge({
        followingStale: true,
        followingIds: state.followingIds.filter((id) => id !== action.remoteId),
      });
    }
    case PROFILE_TYPES.GET_FOLLOWING_SUCCESS: {
      return merge({
        followingLoaded: true,
        followingStale: false,
        followingLoading: false,
        ...action.payload,
      });
    }
    case PROFILE_TYPES.GET_FOLLOWING_SAMPLE_SUCCESS: {
      return merge({
        followingSampleLoaded: true,
        followingStale: false,
        followingLoading: false,
        ...action.payload,
      });
    }
    case CURATOR_PRO_MAGAZINE_TYPES.DELETE_CURATOR_PRO_MAGAZINE_SUCCESS: {
      return Object.assign({}, state, {
        magazines: state.magazines.filter(
          (m) => m.magazineTarget !== action.magazineTarget,
        ),
      });
    }
    case PROFILE_TYPES.GET_FOLLOWING:
      return merge(action.payload);
    case SOCIAL_ACTIVITY_TYPES.ADD_POST_ONBOARDING_SOCIAL_ACTION: {
      return merge(action.payload);
    }
    default:
      return state;
  }
}
