import React, { Component } from 'react';
import classNames from 'classnames';
import codeSplitLoad from 'Webapp/shared/utils/code-split-load';
import { GA } from 'Utils/analytics';
import logger from 'Utils/logger';
import GlobalVars from 'Utils/global-vars';
import Config from 'Webapp/shared/config';
import { AppTheme, Services, OnboardingFlow } from 'Webapp/enums';

// Utils
import WindowResize from 'Utils/window-resize';
import { setBrowserTheme } from 'Utils/themes';
import FlabContent from '../content/flab';
import getWindow from 'Utils/get-window';
import { setDidNavigate } from 'Utils/history';
import WindowKeyDown, { KEYS } from 'Utils/window-key-down';
import {
  setCookieValue,
  getCookieValue,
  deleteCookie,
} from 'Utils/client-cookie';
import sentry from 'Utils/sentry';
import AdjustUrl from 'Utils/adjust-url';
import TopicUtil from 'Utils/content/topic';
import {
  UE_ACTIVATION_LANDING_URL_COOKIE_NAME,
  UE_ACTIVATION_FROM_COOKIE_NAME,
  UE_ACTIVATION_REFERRER_COOKIE_NAME,
  UE_ACTIVATION_ADJUST_INITIAL_DEEP_LINK_COOKIE_NAME,
  UE_ACTIVATION_SECTION_ID_COOKIE_NAME,
  USAGE_APP_ENTER_TYPES,
  USAGE_NAV_FROMS,
  clientGetUsageSession,
  clientSetUsageSession,
} from 'Utils/analytics/usage';
import { loadRecaptchaScript } from 'Webapp/client/lib/recaptcha';

import TopLevelRoutes from './containers/top-level-routes';
import PreloadUserComponents from 'Webapp/shared/app/components/preload-user-components';
import Onboarding from 'Webapp/shared/app/components/onboarding';
import Footer, { FOOTER_THEMES } from './components/footer';
import Toast from './components/toast';
import PathChangeMonitor from './containers/path-change-monitor';

import { ContainerProps } from 'Webapp/shared/app/route-containers/root';

import withToast from './hocs/withToast';
import withT from 'ComponentLibrary/hocs/withT';
import withMobileGate from './hocs/withMobileGate';
import withHistory from 'Webapp/shared/app/hocs/withHistory';
import withSignupCta from 'Webapp/shared/app/hocs/withSignupCta';

import connector from 'Utils/connector';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';
import connectTheme, {
  ConnectThemeProps,
} from 'Webapp/shared/app/connectors/connectTheme';
import connectAmp, {
  ConnectAmpProps,
} from 'Webapp/shared/app/connectors/connectAmp';
import connectResponsive, {
  ConnectResponsiveProps,
} from 'Webapp/shared/app/connectors/connectResponsive';
import connectLanguage, {
  ConnectLanguageProps,
} from 'Webapp/shared/app/connectors/connectLanguage';
import connectAdjustNavFromRoute, {
  ConnectAdjustNavFromRouteProps,
} from 'Webapp/shared/app/connectors/connectAdjustNavFromRoute';
import connectSocialActivity, {
  ConnectSocialActivityProps,
} from 'Webapp/shared/app/connectors/connectSocialActivity';
import connectRouting, {
  ConnectRoutingProps,
} from 'Webapp/shared/app/connectors/connectRouting';
import connectCurrentUser, {
  ConnectCurrentUserProps,
} from 'Webapp/shared/app/connectors/connectCurrentUser';
import connectFeatureFlags, {
  ConnectFeatureFlagsProps,
} from 'Webapp/shared/app/connectors/connectFeatureFlags';
import connectOnboardingFlow, {
  ConnectOnboardingFlowProps,
} from 'Webapp/shared/app/connectors/connectOnboardingFlow';

const MobileGate = codeSplitLoad('MobileGate');
const OnboardingTopicPicker = codeSplitLoad('OnboardingTopicPicker');
const LoadFullProfile = codeSplitLoad('LoadFullProfile');
const DevelopmentStuff = codeSplitLoad('DevelopmentStuff');

type AppProps = ContainerProps &
  ConnectRoutingProps &
  ConnectCurrentUserProps &
  ConnectResponsiveProps &
  ConnectModalProps &
  ConnectThemeProps &
  ConnectAmpProps &
  ConnectLanguageProps &
  ConnectAdjustNavFromRouteProps &
  ConnectFeatureFlagsProps &
  ConnectSocialActivityProps &
  ConnectOnboardingFlowProps & {
    // TODO convert withHistory
    history: {
      listen: (fn: () => void) => void;
    };
    // TODO convert withMobileGate
    shouldShowMobileGate: boolean;
    // TODO convert withT
    t: Flipboard.TFunction;
    // TODO convert withToast
    toastShowErrorAction: (arg: string) => void;
    toastShowInfoAction: (arg: string) => void;
    shouldShowSignupCta: boolean;
  };

type AppState = {
  gptHasLoaded: boolean;
  caughtError: boolean;
};

let windowResize: WindowResize;
let windowKeyDown: WindowKeyDown;

class App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    windowResize = new WindowResize(this.onResize);
    windowKeyDown = new WindowKeyDown(this.handleKeyDown);
  }
  state = {
    gptHasLoaded: false,
    caughtError: false,
  };
  async componentDidUpdate(prevProps: AppProps) {
    // We have received routing information for the first time
    if (
      this.props.routing.pathname &&
      prevProps.routing.pathname === undefined
    ) {
      // this used to be componentDidMount, but we need the store
      // app.routing populated by TopLeveRoutes before we do all that
      // stuff
      await this.bootstrap();
      return;
    }

    const {
      shouldShowMobileGate,
      showModal,
      currentState,
      editorialBoards,
      appTheme,
      isAuthRoute,
      profile,
      triggerInviteOnboarding,
      readyForMetrics,
      isAccessTokenInvalid,
      routing,
      setAppTheme,
      performPostOnboardingSocialActions,
      isFromConfirmation,
      startOnboardingFlow,
    } = this.props;

    if (routing.pathname !== prevProps.routing.pathname) {
      // reset the app theme when the route changes
      setAppTheme(AppTheme.DEFAULT);
      setDidNavigate();
    }

    if (!prevProps.shouldShowMobileGate && shouldShowMobileGate) {
      showModal(MobileGate);
    }

    if (!prevProps.readyForMetrics && readyForMetrics) {
      this.enableMetricsProcessing();
    }

    if (!prevProps.isAccessTokenInvalid && isAccessTokenInvalid) {
      getWindow().location = Config.LOGIN_URL;
    }

    // TODO: Will not work if userInfo is included on hydration, also need this in mount?
    if (!prevProps.profile?.userInfo && profile?.userInfo) {
      await performPostOnboardingSocialActions();

      if (isFromConfirmation) {
        if (getCookieValue('publisher-onboard')) {
          startOnboardingFlow(OnboardingFlow.BECOME_PUBLISHER);
        } else {
          startOnboardingFlow(OnboardingFlow.POST_VERIFICATION);
        }
      }
    }

    if (triggerInviteOnboarding) {
      this.startInviteOnboarding();
    }

    if (
      (!prevProps.currentState || !prevProps.editorialBoards) &&
      currentState &&
      editorialBoards
    ) {
      this.followOnboardingTopics();
    }

    // Update viewport state
    if (appTheme !== prevProps.appTheme && !isAuthRoute) {
      setBrowserTheme(appTheme);
    }
  }

  componentDidCatch(error: Error) {
    if (getWindow().bugReportErrors) {
      getWindow().bugReportErrors.push(error);
    }
    sentry.captureException(error);
    this.setState({
      caughtError: true,
    });
  }

  componentWillUnmount() {
    windowResize.unsubscribe();
    windowKeyDown.unsubscribe();
  }

  bootstrap = async () => {
    const {
      isAuthenticated,
      isFromCrawler,
      showModal,
      shouldShowMobileGate,
      experimentOverrides,
      forcedTraceLog,
      setABTestFeatureFlags,
      clearAppErrors,
      getEditorialBoards,
      getBrowserExperiments,
      setViewportByBrowserWidth,
      setBanners,
      history,
      featureFlags,
      setFeatureFlagABTestSetupComplete,
      queueLoadMissingSocialActivity,
    } = this.props;
    setViewportByBrowserWidth(getWindow().innerWidth);

    // reset the app theme when mounting
    windowResize.subscribe();
    windowKeyDown.subscribe();
    this.honorUserTheme();
    setBanners();

    history.listen(() => {
      setDidNavigate();

      if (clearAppErrors) {
        clearAppErrors();
      }
    });

    if (forcedTraceLog) {
      logger.info(forcedTraceLog);
    }

    // Set up handling of google ad library loading
    const cmd = getWindow().googletag?.cmd;
    if (cmd) {
      // Enqueue a function onto GPT
      cmd.push(() => this.setState({ gptHasLoaded: true }));
    }

    // Preload initial search results
    getEditorialBoards();

    // Show gate modal if needed
    if (shouldShowMobileGate) {
      showModal(MobileGate);
    }

    await this.bootstrapUsage();

    // Don't track crawler traffic, don't apply experiments
    if (!isFromCrawler) {
      // FLAB setup
      // NOTE: This also does some usage work such as
      // ensuring there is a usage session
      // Get experiments from FLAB in case this is the
      // first load and there were no userid or usage
      // session cookies to do so on the server side.
      // Wait to get them before initializing usage
      await getBrowserExperiments(experimentOverrides);
      setABTestFeatureFlags();
      setFeatureFlagABTestSetupComplete(true);
    }

    // handle messages persisted to cookies
    this.handlePersistedMessaging();

    // "reCAPTCHA works best when it has the most context about interactions with your site"
    // https://developers.google.com/recaptcha/docs/v3
    // https://flipboard.atlassian.net/browse/FL-22192
    if (!isAuthenticated && featureFlags.INCLUDE_JS_EXTERNALS) {
      loadRecaptchaScript();
    }
    queueLoadMissingSocialActivity();
  };

  bootstrapUsage = async () => {
    const { usageBootstrap } = this.props;
    // Do not clobber activation metric details when redirecting back
    // from Auth after successful signup
    const isOnboarding = this.props.routing.query.action === 'onboard';
    if (!this.props.isAuthenticated && !isOnboarding) {
      this.persistActivationMetricDetails();
    }
    await usageBootstrap(clientGetUsageSession, clientSetUsageSession);
    this.trackAppEnter();
  };

  // Persist various details in cookies for later use in reporting onboarding events
  // NOTE: Does not overwrite existing cookies if they exist
  persistActivationMetricDetails = () => {
    const {
      isAmp,
      adjustNavFrom,
      activationReferrer,
      adjustSectionId,
      appUrl,
      isSectionRoute,
      experiments,
      utmParams,
    } = this.props;
    // Expire activation cookies in 1 hour
    const oneHourFromNow = new Date(Date.now() + 1000 * 60 * 60);

    // landing URL
    if (!getCookieValue(UE_ACTIVATION_LANDING_URL_COOKIE_NAME)) {
      setCookieValue(
        UE_ACTIVATION_LANDING_URL_COOKIE_NAME,
        getWindow().location.toString(),
        oneHourFromNow,
      );
    }

    // Adjust nav_from
    if (adjustNavFrom && !getCookieValue(UE_ACTIVATION_FROM_COOKIE_NAME)) {
      setCookieValue(
        UE_ACTIVATION_FROM_COOKIE_NAME,
        adjustNavFrom,
        oneHourFromNow,
      );
    }

    // Activation referrer
    if (
      activationReferrer &&
      !getCookieValue(UE_ACTIVATION_REFERRER_COOKIE_NAME)
    ) {
      setCookieValue(
        UE_ACTIVATION_REFERRER_COOKIE_NAME,
        activationReferrer,
        oneHourFromNow,
      );
    }

    // original Adjust deep link if there is an AppUrl
    if (
      appUrl &&
      !getCookieValue(UE_ACTIVATION_ADJUST_INITIAL_DEEP_LINK_COOKIE_NAME)
    ) {
      const abTestsParam = FlabContent.experimentsForTracking(experiments);
      const adjustDeepLink = AdjustUrl.deepLink(
        appUrl,
        isAmp,
        adjustNavFrom || '',
        adjustSectionId || '',
        abTestsParam,
        undefined,
        utmParams,
        activationReferrer || '',
      );
      if (adjustDeepLink) {
        setCookieValue(
          UE_ACTIVATION_ADJUST_INITIAL_DEEP_LINK_COOKIE_NAME,
          adjustDeepLink,
          oneHourFromNow,
        );
      }
    }

    // if rendering a section on first load, perist sectionId
    if (
      isSectionRoute &&
      adjustSectionId &&
      !getCookieValue(UE_ACTIVATION_SECTION_ID_COOKIE_NAME)
    ) {
      setCookieValue(
        UE_ACTIVATION_SECTION_ID_COOKIE_NAME,
        adjustSectionId,
        oneHourFromNow,
      );
    }
  };

  handlePersistedMessage = (
    cookieName: string,
    action: (arg: string) => void,
  ) => {
    const persistedMessage = getCookieValue(cookieName);
    if (persistedMessage) {
      action(persistedMessage);
      deleteCookie(cookieName);
    } else {
      const translationKeyCookieName = `${cookieName}_key`;
      const persistedTranslationKey = getCookieValue(translationKeyCookieName);
      if (persistedTranslationKey) {
        action(this.props.t(persistedTranslationKey));
        deleteCookie(translationKeyCookieName);
      }
    }
  };

  handlePersistedMessaging = () => {
    const { toastShowErrorAction, toastShowInfoAction } = this.props;
    this.handlePersistedMessage('show_client_error', toastShowErrorAction);
    this.handlePersistedMessage('show_client_info', toastShowInfoAction);
  };

  trackAppEnter = () => {
    const {
      usageTrackAppEnter,
      routing: { pathname, query },
    } = this.props;
    let enterType: string | null = null;
    if (pathname.indexOf('/video-player') > -1) {
      enterType = query.impression
        ? USAGE_APP_ENTER_TYPES.VIDEO_PLAYER_IMPRESSION
        : USAGE_APP_ENTER_TYPES.VIDEO_PLAYER_VIDEO_SELECTED;
    }
    if (query.action === 'loginWithLink') {
      enterType = USAGE_APP_ENTER_TYPES.LOGIN_WITH_LINK;
      GA.trackLogin(true, Services.FLIPBOARD);
    }
    usageTrackAppEnter(pathname, enterType);
  };

  onResize = () => {
    this.props.setViewportByBrowserWidth(getWindow().innerWidth);
  };

  honorUserTheme = () => {
    const { isAuthenticated, setAppThemeOverride } = this.props;
    const systemTheme =
      getWindow() &&
      getWindow().matchMedia('(prefers-color-scheme: dark)').matches
        ? AppTheme.DARK
        : undefined;
    const userSetTheme = isAuthenticated
      ? getWindow().localStorage.getItem('appThemeOverride')
      : undefined;

    // Detect OS theme with media query match and check localstorage for manual override
    setAppThemeOverride(userSetTheme || systemTheme || AppTheme.DEFAULT);
  };

  enableMetricsProcessing = () => {
    const {
      experiments,
      isAuthenticated,
      referrer,
      auth: { uid },
      usageEnableTracking,
    } = this.props;
    const abTests = FlabContent.experimentsForTracking(experiments);

    // Enable immediate GA events (no queuing)
    GA.setReferrer(referrer);
    GA.setAppVersion(Config.VERSION);
    GA.setAbTests(abTests);
    GA.setIsAuthenticated(isAuthenticated);
    GA.setGA4UserId(isAuthenticated ? uid : null); // GA4 considers any non-null to be "logged in"
    GA.enableTracking();
    usageEnableTracking();
  };

  shouldRenderFooter = () => {
    const { isChromeless, isEmbeddable } = this.props;
    return !isChromeless && !isEmbeddable;
  };

  followOnboardingTopics = async () => {
    const { followSections, editorialBoards } = this.props;
    try {
      const cookieValue = getCookieValue('onboarding_followed_topics');
      const onboardingFollowTopics = cookieValue
        ? JSON.parse(cookieValue)
        : false;
      if (onboardingFollowTopics) {
        const sections = TopicUtil.getSelectedTopicsFromEditorialBoards(
          onboardingFollowTopics,
          editorialBoards,
        );
        await followSections(sections, USAGE_NAV_FROMS.FIRST_LAUNCH);
        deleteCookie('onboarding_followed_topics');
      }
    } catch (error) {
      if (error instanceof Error) {
        sentry.captureException(error);
      }
    }
  };

  // Branch Invite Friend to Topic
  startInviteOnboarding = () => {
    const { adjustNavFromRoute, setTriggerInviteOnboarding } = this.props;
    setTriggerInviteOnboarding(false);

    this.showOnboardingTopicPicker({
      navFrom: adjustNavFromRoute,
      inviteFlow: true,
    });
  };

  showOnboardingTopicPicker = async (pickerProps) => {
    const { showModal, loadContextualOnboardingSections } = this.props;
    await loadContextualOnboardingSections();
    showModal(OnboardingTopicPicker, pickerProps);
  };

  handleKeyDown = (e: React.KeyboardEvent) => {
    if (!this.props.isAmp && getWindow()) {
      switch (e.keyCode) {
        case KEYS.TAB: {
          /** TODO: Need to clean this up and probably move this to somewhere else (ie: wherever the modal/window changes?) */
          getWindow().document.body.classList.add('user-is-tabbing');
          break;
        }
      }
    }
  };

  render() {
    const {
      isAuthenticated,
      modals,
      notFound,
      showAmpStory,
      toastProps,
      toastStyle,
      googleAdVisibility,
      isEmbeddable,
    } = this.props;

    const contentComponent = this.state.caughtError ? (
      <TopLevelRoutes caughtError />
    ) : (
      <TopLevelRoutes
        key="top-level-routes"
        isLoggedIn={isAuthenticated}
        notFound={notFound}
      />
    );

    // When rendering full-screen (currently only used for AMP stories),
    // omit most page DOM structure.
    if (showAmpStory) {
      return contentComponent;
    }

    const classes = classNames({
      'google-ad-is-visible': googleAdVisibility !== 0,
      widget: isEmbeddable,
    });
    const footerTheme = FOOTER_THEMES.FULL;

    return (
      <div className="app">
        <PathChangeMonitor
          onChange={() => {
            if (this.state.caughtError) {
              this.setState({ caughtError: false });
            }
          }}
        />
        {isAuthenticated && (
          <React.Fragment>
            <LoadFullProfile />
            <PreloadUserComponents />
          </React.Fragment>
        )}
        <div className={classes}>
          {contentComponent}
          {this.shouldRenderFooter() && <Footer footerTheme={footerTheme} />}
          {modals.map((Modal) => (
            <Modal.component
              {...Modal.props}
              key={Modal.component.displayName || Modal.component.name}
            />
          ))}
          {(toastProps?.message || toastProps?.template) && (
            <Toast
              key={toastProps.message}
              props={toastProps}
              style={toastStyle}
            />
          )}
        </div>
        {!GlobalVars.isProduction && !isEmbeddable && <DevelopmentStuff />}
        <Onboarding />
      </div>
    );
  }
}

export default connector(
  connectCurrentUser,
  connectRouting,
  connectResponsive,
  connectModal,
  connectTheme,
  connectAmp,
  connectLanguage,
  connectAdjustNavFromRoute,
  connectFeatureFlags,
  connectSocialActivity,
  connectOnboardingFlow,
)(withHistory(withT(withMobileGate(withToast(withSignupCta(App))))));
