import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ primary, className, children }) => {
  const HeaderTag = primary ? 'h1' : 'h3';
  return <HeaderTag className={className}>{children}</HeaderTag>;
};

Header.propTypes = {
  primary: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Header;
