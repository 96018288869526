import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { SPACING } from 'Style/spacing';

// Utils
import classNames from 'classnames';
import attribution from 'Utils/content/attribution';
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';
import { isExternalUrl } from 'Utils/content/flipboard-urls';
import { shortFormatLocalized } from 'Utils/date-util';
import { getImageObject } from 'Utils/image-util';

// Components
import WebLink from '../web-link';
import ItemLink from '../item/item-link';
import RedBoltIcon from 'ComponentLibrary/icons/red-bolt';
import Image from '../base/image';
import AuthorAvatar from './author-avatar';
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectIsArticleRoute from 'Webapp/shared/app/connectors/connectIsArticleRoute';

import CurationIcon from 'ComponentLibrary/icons/curation';

const StyledRedBoltIcon = styled(RedBoltIcon)({
  marginRight: SPACING.SMALL,
  minWidth: '12px',
});

const StyledPostAttributionSourceWithBadge = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const StyledYouTubeSourceAttributionImage = styled(Image)({
  height: '20px',
  margin: `0px ${SPACING.SMALL}`,
});

const StyledPostAttributionLinkContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  '.post-attribution__link': {
    display: 'flex',
  },
});
function SourceAttribution({
  item,
  section,
  className,
  onClick,
  shouldRenderAvatar,
  t,
  isArticleRoute,
  hideFlipDateCreated,
  shownInList,
  isCondensedItem,
  avatarSize,
  noLink,
}) {
  const handleClick = (e) => {
    e.stopPropagation();
    if (!onClick) {
      return;
    }
    onClick();
  };

  const url = attribution.getSourceAttributionUrl(item);
  const attributionText = attribution.getSourceAttributionText(
    item,
    t,
    isCondensedItem,
  );
  const text = attributionText;
  const author = item.sectionItemAuthor || item.author;
  if ((shouldRenderAvatar && !author) || !text) {
    return null;
  }

  // no URL found
  if (url === null) {
    const classes = classNames(
      'ui-text--supporting',
      'post-attribution__source',
      className,
    );
    return <address className={classes}>{text}</address>;
  }

  const renderAttributionBadges = (
    <StyledPostAttributionSourceWithBadge>
      {shouldRenderAvatar && author.authorImage !== undefined && (
        <AuthorAvatar
          author={author}
          containerClass="post--ngl-card__source-avatar"
          avatarSize={avatarSize}
        />
      )}
      {!shownInList && item.isSoundcloud && (
        <Image
          ampLayout="fixed"
          className="post-attribution__source-badge"
          image={getImageObject(
            'https://s.flipboard.com/webapp/images/soundcloud-logo-orange.png',
          )}
          ampWidth={32}
          ampHeight={32}
          alt={t('soundcloud_logo')}
          ampNoLoading
        />
      )}
      {!shownInList && item.isYoutube && (
        <StyledYouTubeSourceAttributionImage
          ampLayout="fixed"
          image={getImageObject(
            'https://s.flipboard.com/webapp/images/youtube_social_icon_red.png',
          )}
          ampWidth={32}
          ampHeight={32}
          alt={t('youtube_logo')}
          ampNoLoading
        />
      )}
    </StyledPostAttributionSourceWithBadge>
  );
  const renderIcons = !shouldRenderAvatar ? (
    <React.Fragment>
      {/* TODO: Note this appears to be currently broken as upstream */}
      {item.isHighQuality && !isArticleRoute && <StyledRedBoltIcon />}
      {(item.isStoryboard || item.isMagazine || item.isSectionCover) && (
        <CurationIcon size={22} />
      )}
    </React.Fragment>
  ) : null;

  const getSourceContents = () => (
    <React.Fragment>
      {renderAttributionBadges}
      {text}
      {!shouldRenderAvatar && (
        <React.Fragment>
          {item.price && ` - ${item.price}`}
          {isArticleRoute &&
            !item.isSection &&
            !hideFlipDateCreated &&
            item.dateCreated &&
            ` • ${shortFormatLocalized(item.dateCreated, t)}`}
        </React.Fragment>
      )}
    </React.Fragment>
  );

  const contentsClassName = classNames(className, 'post-attribution__link', {
    'ui-text--supporting': !isArticleRoute,
  });

  let children;

  if (noLink) {
    // If `noLink` is true, wrap the contents in a div.
    children = <div className={contentsClassName}>{getSourceContents()}</div>;
  } else if (isExternalUrl(url)) {
    // if is an external URL, use the ItemLink component so we get usage
    // item enter event, etc.
    children = (
      <ItemLink
        className={contentsClassName}
        {...{ item, section, shownInList }}
        title={text}
      >
        {getSourceContents()}
      </ItemLink>
    );
  } else {
    // Otherwise, use standard Link
    children = (
      <WebLink
        href={url}
        onClick={handleClick}
        className={contentsClassName}
        navFromEventName={USAGE_EVENT_NAMES.SECTION_ENTER}
        title={text}
      >
        {getSourceContents()}
      </WebLink>
    );
  }

  return (
    <address className="post-attribution__source">
      <StyledPostAttributionLinkContainer>
        {renderIcons}
        {children}
      </StyledPostAttributionLinkContainer>
    </address>
  );
}

SourceAttribution.propTypes = {
  item: PropTypes.object.isRequired,
  section: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  shouldRenderAvatar: PropTypes.bool,
  t: PropTypes.func.isRequired,
  isArticleRoute: PropTypes.bool.isRequired,
  hideFlipDateCreated: PropTypes.bool,
  shownInList: PropTypes.bool,
  isCondensedItem: PropTypes.bool,
  avatarSize: PropTypes.number,
  noLink: PropTypes.bool,
};

SourceAttribution.defaultProps = {
  section: null,
  onClick: null,
  className: '',
  shouldRenderAvatar: false,
  hideFlipDateCreated: false,
  shownInList: true,
  isCondensedItem: false,
  noLink: false,
};

export default connector(connectIsArticleRoute)(withT(SourceAttribution));
