/* globals window */
type FlipboardWindow = Window & {
  $store: Flipboard.Store;
  bugReportErrors: Array<Error>;
  googletag?: {
    cmd: Array<() => void>;
  };
};
export default (): FlipboardWindow => {
  if (typeof window === 'undefined') {
    return {} as FlipboardWindow;
  }
  return window as unknown as FlipboardWindow;
};
