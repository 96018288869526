import {
  setIsChromeless,
  getBrowserExperiments,
} from 'Webapp/shared/app/redux/actions/app';
import { getFeatures } from 'Webapp/shared/concepts/user-features';

import {
  setUserFeatureFlags,
  setABTestFeatureFlags,
} from 'Webapp/shared/concepts/feature-flags';
import { AUTH_TYPES } from 'Webapp/action-types';
import uuid from 'uuid/v4';
import {
  ResendConfirmationEmailError,
  PopulateAuthenticationError,
} from 'Utils/errors';
import { toastShowInfoAction, toastShowErrorAction } from './toast-actions';
import { setOnboardingSuccess } from 'Webapp/shared/concepts/onboarding';
import { set, type } from 'Utils/redux';
import sentry from 'Utils/sentry';
import { isAuthenticated } from 'Webapp/shared/app/redux/selectors/auth';
import FlapUtil from 'Utils/content/flap-util';
import { GA } from 'Utils/analytics';

export const frictionlessSetUID = (uid) =>
  set(AUTH_TYPES.FRICTIONLESS_SET_UID, 'uid', uid);

export const setAuthToken = (uid, udid, tuuid) => ({
  type: AUTH_TYPES.GET_AUTH_TOKEN,
  data: { uid, udid, tuuid },
});

export const frictionlessSignup = (email) =>
  async function (dispatch, _, { auth, t }) {
    try {
      const body = { email, password: uuid(), frictionless: true };

      const { data } = await auth.post(`/signup`, body);
      dispatch(frictionlessSetUID(data.userid));
      return data;
    } catch (error) {
      sentry.captureException(error);
      const responseErrorMessage = authFormErrorMessage(error, t);
      dispatch(
        toastShowErrorAction(responseErrorMessage || t('failed_to_subscribe')),
      );
    }
  };

export const resendConfirmation = () =>
  async function (dispatch, _getState, { flap }) {
    try {
      const { data } = await flap.post(
        `/flipboard/resendLastEmailConfirmation`,
      );

      if (!(data && data.success)) {
        dispatch(toastShowErrorAction('Error sending email'));
        throw new ResendConfirmationEmailError();
      }

      dispatch(toastShowInfoAction('Email sent'));
    } catch (error) {
      sentry.captureException(error);
      dispatch(toastShowErrorAction('Error sending email'));
      throw new ResendConfirmationEmailError();
    }
  };

export const authFormUpdate = (changes) => ({
  type: AUTH_TYPES.AUTH_FORM_CHANGE,
  changes,
});

const authFormErrorMessage = (error, t) => {
  if (!error?.errorcode && error?.errormessage) {
    return error.errormessage;
  }
  return FlapUtil.getFlapErrorCodeMessage(error?.errorcode, t);
};

const signup =
  (path) =>
  (realName, email, password) =>
  async (dispatch, _getState, { auth, t }) => {
    dispatch({ type: AUTH_TYPES.SIGNUP_PENDING });
    const body = { realName, email, password };
    try {
      const { data } = await auth.post(path, body);
      if (data.error) {
        throw new Error();
      } else {
        dispatch(setOnboardingSuccess(true));
        dispatch(setSignupCompleted());
        return data;
      }
    } catch (error) {
      dispatch(setOnboardingSuccess(false));
      dispatch({
        type: AUTH_TYPES.SIGNUP_FAILURE,
        errorMessage: authFormErrorMessage(error, t),
      });
      return { error };
    }
  };

export const userSignup = signup('/signup');
export const publisherSignup = signup('/signup_publisher');

export const connectWithSSO =
  (service, token) =>
  async (dispatch, _getState, { auth }) => {
    dispatch({ type: AUTH_TYPES.CONNECT_WITH_SSO_PENDING });
    try {
      const response = await auth.post('/sso', {
        service,
        token,
      });
      return response;
    } catch (error) {
      dispatch({
        type: AUTH_TYPES.CONNECT_WITH_SSO_FAILURE,
        error: error.message,
      });
    }
  };

export const setSSOError =
  (service) =>
  (dispatch, _getState, { t }) => {
    const readableService = service.charAt(0).toUpperCase() + service.slice(1);
    const error = t('sso_login_error', {
      readableService,
    });
    dispatch({
      type: AUTH_TYPES.CONNECT_WITH_SSO_FAILURE,
      error,
    });
  };

export const getPasswordStrength =
  (pword) =>
  async (dispatch, _getState, { auth }) => {
    dispatch({ type: AUTH_TYPES.GET_PASSWORD_STRENGTH_PENDING });
    const { data } = await auth.post('/strength', { pword });

    dispatch({
      type: AUTH_TYPES.GET_PASSWORD_STRENGTH_SUCCESS,
      data,
    });
  };

export const populateAuthentication =
  () =>
  async (dispatch, getState, { auth }) => {
    try {
      if (isAuthenticated(getState())) {
        return;
      }
      const {
        data: { id: uid, udid, tuuid },
      } = await auth.get('/credentials');
      if (uid && udid && tuuid) {
        await dispatch(getFeatures(uid));
        await dispatch(setUserFeatureFlags());
        dispatch(setAuthToken(uid, udid, tuuid));
        dispatch(setIsChromeless(false));
        GA.setGA4UserId(uid);
        dispatch({ type: AUTH_TYPES.RESET_AUTH_FORM });
        // we need to replace experiments now that we are logged in
        dispatch(
          getBrowserExperiments(getState().app.experimentOverrides, true),
        );
        dispatch(setABTestFeatureFlags());
        return;
      }
    } catch (e) {
      sentry.captureException(e);
    }
    throw new PopulateAuthenticationError();
  };

export const setErrorMessage = (errorMessage) => ({
  type: AUTH_TYPES.LOGIN_FAILURE,
  errorMessage,
});
export const setAuthFormLoading = (isLoading) => ({
  type: AUTH_TYPES.SET_AUTH_FORM_LOADING,
  payload: {
    authForm: {
      isLoading,
    },
  },
});

export const login =
  (username, password) =>
  async (dispatch, _getState, { auth, t }) => {
    dispatch({ type: AUTH_TYPES.LOGIN_PENDING });
    try {
      const response = await auth.post('/login', {
        username,
        password,
      });
      return response;
    } catch (error) {
      dispatch({
        type: AUTH_TYPES.LOGIN_FAILURE,
        errorMessage: authFormErrorMessage(error, t),
      });
    }
  };

const authPost =
  (path) =>
  (body) =>
  (_dispatch, _getState, { auth }) =>
    auth.post(path, body);

export const requestLoginLink = (arg) =>
  authPost('/request_login_link')({ email: arg });

export const forgotUsername = (arg) =>
  authPost('/forgot_username')({ email: arg });

export const forgotPassword = authPost('/forgot_password');

export const resetPassword = authPost('/reset_password');

export const setSignupCompleted = () =>
  set(AUTH_TYPES.SET_SIGNUP_COMPLETED, 'signupCompleted', true);

export const logout = () => type(AUTH_TYPES.LOGOUT);
