import React from 'react';
import styled from '@emotion/styled';
import { styleObject } from 'Style/type-helpers';
import { BREAKPOINTS } from 'Style/breakpoints';
import {
  CONTENT_STORY_HEADING_TYPES,
  UI_TEXT_TYPES,
  STATUS_POST_TITLE,
} from 'Style/typography';
import { SPACING } from 'Style/spacing';
import {
  DIVIDER_COLORS,
  SURFACE_COLORS,
  TEXT_COLORS,
  DefaultTextColorStyle,
} from 'Style/colors';

import Image from 'Webapp/shared/app/components/base/image.js';
import SourceAttribution from 'Webapp/shared/app/components/attribution/source.js';
import ItemFlipNote from 'Webapp/shared/app/components/item/item-flip-note';
import ItemLink from 'Webapp/shared/app/components/item/item-link.js';

import connector from 'Utils/connector';
import connectCurrentUser, {
  ConnectCurrentUserProps,
} from 'Webapp/shared/app/connectors/connectCurrentUser';

const IMAGE_SIZES = {
  small: 48,
  large: 80,
};

interface CondensedHeaderProps {
  condensedHeader?: boolean;
  isFlipComposeImage?: boolean;
  isNote?: boolean;
  isStandalonePost?: boolean;
  hasImage?: boolean;
}

export type ItemCoreActionDetailHeaderProps = CondensedHeaderProps &
  ConnectCurrentUserProps & {
    item: Flipboard.Item;
    section: Flipboard.Section;
    className: string;
    expandCommentsHeader?: boolean;
  };

const ArticleHeader = styled.div<CondensedHeaderProps>(
  ({ condensedHeader, hasImage, isNote }) => ({
    minWidth: 0,
    backgroundColor: isNote
      ? SURFACE_COLORS.defaultStandalonePostPrimary
      : SURFACE_COLORS.primaryReverse,
    padding: condensedHeader
      ? `${SPACING.LARGE} ${SPACING.BASE4X} ${SPACING.BASE4X} ${SPACING.LARGE}`
      : `${SPACING.BASE4X} ${SPACING.BASE4X} ${SPACING.BASE4X} ${SPACING.BASE4X}`,
    display: 'grid',
    gridTemplateColumns: hasImage ? 'auto 1fr' : '1fr',
    gap: SPACING.MEDIUM,
    '.media-container': {
      width: condensedHeader ? IMAGE_SIZES.small : IMAGE_SIZES.large,
      height: condensedHeader ? IMAGE_SIZES.small : IMAGE_SIZES.large,
    },
  }),
);

const ImageWrapper = styled.div<CondensedHeaderProps>(
  ({ condensedHeader }) => ({
    borderRadius: 8,
    overflow: 'hidden',
    borderStyle: 'solid',
    borderColor: DIVIDER_COLORS.emphasis,
    borderWidth: condensedHeader ? '1px' : '2px',
    marginBottom: 'auto',
  }),
  styleObject(
    BREAKPOINTS.phone({
      borderWidth: '1px',
    }),
  ),
);

const ArticleHeaderContent = styled.div<CondensedHeaderProps>(
  UI_TEXT_TYPES.BODY_2,
  ({ isNote }) => ({
    display: 'grid',
    gap: SPACING.LARGE,
    overflowY: 'auto',
    maxHeight: '50vh',
    minWidth: 0,
    paddingBottom: '1px', // Hack to prevent scrollbars from appearing when not needed
    paddingRight: SPACING.BASE4X,
    color: isNote ? TEXT_COLORS.secondaryReverse : TEXT_COLORS.tertiary,
    a: {
      color: 'inherit',
    },
  }),
);

const Title = styled.h3<CondensedHeaderProps>(
  ({ isStandalonePost }) => {
    if (isStandalonePost) {
      return { ...STATUS_POST_TITLE, fontSize: '16px', lineHeight: '20px' };
    }
    return CONTENT_STORY_HEADING_TYPES.SMALL;
  },
  ({ condensedHeader }) => ({
    marginBottom: condensedHeader ? SPACING.SMALL : SPACING.BASE,
  }),
  DefaultTextColorStyle(true),
);

const ArticleSource = styled(SourceAttribution)<CondensedHeaderProps>({
  color: TEXT_COLORS.secondaryReverse,
});

const ItemCoreActionDetailHeader: React.FC<ItemCoreActionDetailHeaderProps> = ({
  item,
  className,
  condensedHeader,
  expandCommentsHeader,
}) => {
  const {
    image,
    title,
    isStatus,
    isNote,
    isFlipComposeImage,
    isStandalonePost,
  } = item;
  const imageSize = condensedHeader ? IMAGE_SIZES.small : IMAGE_SIZES.large;

  const hasImage = !!image;

  return (
    <ItemLink item={item} shownInList forceInternal>
      <ArticleHeader
        className={className}
        condensedHeader={condensedHeader}
        isNote={isNote}
        isStandalonePost={isStandalonePost}
        isFlipComposeImage={isFlipComposeImage}
        hasImage={hasImage}
      >
        {!isStatus && hasImage && (
          <ImageWrapper condensedHeader={condensedHeader}>
            <Image
              size={imageSize}
              image={image}
              alt={title}
              crop
              useDumbCroppedImage
            />
          </ImageWrapper>
        )}
        <ArticleHeaderContent
          isNote={isNote}
          hasImage={hasImage}
          isFlipComposeImage={isFlipComposeImage}
        >
          <Title
            condensedHeader={condensedHeader}
            isStandalonePost={isStandalonePost}
            isNote={isNote}
          >
            <ItemFlipNote
              item={item}
              expandBehaviour
              isNote={isNote}
              startExpanded={expandCommentsHeader}
              numLines={condensedHeader ? 1 : 4}
              isOverlay
              overrideBackgroundColor={
                isNote
                  ? SURFACE_COLORS.defaultStandalonePostPrimary
                  : SURFACE_COLORS.primaryReverse
              }
            />
          </Title>
          {!isStandalonePost && <ArticleSource item={item} />}
        </ArticleHeaderContent>
      </ArticleHeader>
    </ItemLink>
  );
};

export default connector<ItemCoreActionDetailHeaderProps>(connectCurrentUser)(
  ItemCoreActionDetailHeader,
);
