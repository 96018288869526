export default (item = {}, section, prod_type = 'web') => ({
  prod_type,
  event_category: 'item',
  item_id: item.id,
  item_type: item.type,
  url: item.sourceURL,
  item_partner_id: item.partnerID,
  partner_id: section && section.partnerId,
  ssid: section && section.remoteid,
  section_id: section && section.ssid && section.ssid.remoteidPlain,
});
