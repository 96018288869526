import { SEARCH_TYPES } from 'Webapp/action-types';

interface SearchReducerState {
  results: Array<Flipboard.FlapSearchResult> | null;
  loading: boolean;
}

const initialState: SearchReducerState = {
  results: null,
  loading: false,
};

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_TYPES.SEARCH:
      return Object.assign({}, state, { loading: true });
    case SEARCH_TYPES.SEARCH_SUCCEEDED: {
      return Object.assign({}, state, {
        loading: false,
        results: action.payload,
      });
    }
    case SEARCH_TYPES.CLEAR_SEARCH: {
      return initialState;
    }
    default:
      return state;
  }
}
