import trackFromEmailConfirmation from 'Webapp/shared/app/components/onboarding-flows/steps/track-from-email-confirmation';
import postVerificationOnboardingTopicPicker from 'Webapp/shared/app/components/onboarding-flows/steps/post-verification-onboarding-topic-picker';

import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

const steps: Array<OnboardingStep> = [
  trackFromEmailConfirmation,
  postVerificationOnboardingTopicPicker,
];

export default steps;
