import basicTitle from 'Utils/seo/title/shared/basic-title';
import getCurrentSection from 'Utils/seo/shared/get-current-section';

export default ({ section }, getState) => {
  if (section) {
    const currentSection = getCurrentSection(getState());

    if (currentSection && currentSection.isStoryboard && currentSection.title) {
      return basicTitle(currentSection.title);
    }
  }
  return null;
};
