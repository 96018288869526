import { Connector } from 'Utils/connector';
import { getAdjustNavFromRoute } from 'Webapp/shared/app/redux/selectors/nav-from';

const selectors = {
  adjustNavFromRoute: getAdjustNavFromRoute,
};

export type ConnectAdjustNavFromRouteProps = Flipboard.ConnectorProps<
  typeof selectors
>;

const connectAdjustNavFromRoute: Connector = {
  selectors,
};

export default connectAdjustNavFromRoute;
