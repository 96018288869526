import React from 'react';
import classnames from 'classnames';

import styled from '@emotion/styled';
import { COLORS, TEXT_COLORS, SURFACE_COLORS } from 'Style/colors';
import { SPACING } from 'Style/spacing';
import { styleOnlyProps } from 'Style/style-helpers';
import { FONT_WEIGHTS, PILL_TAG } from 'Style/typography';

import { PillSize } from 'Webapp/enums';

type PillButtonProps = {
  isSelected?: boolean;
  size?: PillSize;
};
const PillButton = styled(
  'button',
  styleOnlyProps('isSelected', 'size'),
)<PillButtonProps>(
  PILL_TAG,
  {
    display: 'inline-flex',
    padding: `${SPACING.SMALL} ${SPACING.BASE}`,
    borderRadius: 0,
    backgroundColor: SURFACE_COLORS.secondary,
    color: TEXT_COLORS.secondary,
    cursor: 'pointer',
    textAlign: 'left',

    '&:hover': {
      color: TEXT_COLORS.secondary,
    },
    '&:active, &:focus': {
      backgroundColor: SURFACE_COLORS.secondary,
      color: TEXT_COLORS.tertiary,
    },

    '.author-avatar__image': {
      display: 'unset',
    },
  },
  ({ isSelected }) =>
    isSelected && {
      backgroundColor: COLORS.red,
      color: TEXT_COLORS.overlay,
      '&:focus': {
        backgroundColor: COLORS.red,
        color: TEXT_COLORS.overlaySecondary,
      },
    },
  ({ size }) => {
    if (size === PillSize.LARGE) {
      return {
        fontSize: '18px',
        fontWeight: FONT_WEIGHTS.BLACK,
      };
    }
  },
);

type PillProps = {
  isSelected: boolean;
  onClick: () => void;
  size: PillSize;
};

const Pill: React.FC<PillProps> = ({ children, isSelected, onClick, size }) => {
  const classes = classnames({
    pill: true,
    'pill--selected': isSelected,
  });

  return (
    <PillButton
      className={classes}
      disabled={!onClick}
      onClick={onClick}
      isSelected={isSelected}
      size={size}
    >
      {children}
    </PillButton>
  );
};

export default Pill;
