export const getInviteMagazine = ({ magazineInvite }: Flipboard.State) =>
  magazineInvite;

export const getInviteMagazineValidationErrors = ({
  magazineInvite: { validationErrors },
}: Flipboard.State) => validationErrors;

export const getInviteMagazinePendingStatus = ({
  magazineInvite: { pending },
}: Flipboard.State) => pending;
