import { ARTICLES_TYPES } from 'Webapp/action-types';
import { projection } from 'Utils/content/item-util';
import { merger } from 'Utils/redux';

interface ArticlesReducerState {
  articlesByRemoteId: Record<Flipboard.ItemId, Flipboard.ItemDetail>;
  videoArticleRemoteIdHack: Flipboard.ItemId | null;
  loading: boolean;
  getArticleFailed: boolean;
  redirectPath: null | string;
}

const initialState: ArticlesReducerState = {
  articlesByRemoteId: {},
  videoArticleRemoteIdHack: null,
  loading: true,
  getArticleFailed: false,
  redirectPath: null,
};

export default function articleReducer(state = initialState, action) {
  const merge = merger(state);
  switch (action.type) {
    case ARTICLES_TYPES.GET_ARTICLE: {
      return Object.assign({}, state, {
        loading: true,
        getArticleFailed: false,
      });
    }
    case ARTICLES_TYPES.GET_ARTICLE_SUCCEEDED: {
      const {
        payload: { article, remoteId, articleSectionId },
      } = action;

      const articleProjection = projection(article);

      return merge({
        loading: false,
        getArticleFailed: false,
        redirectPath: null,
        articlesByRemoteId: Object.assign({}, state.articlesByRemoteId, {
          [remoteId]: {
            ...articleProjection,
            articleSectionId,
          },
        }),
      });
    }
    case ARTICLES_TYPES.GET_ARTICLE_FAILED: {
      const {
        payload: { redirectPath },
      } = action;
      return merge({
        loading: false,
        getArticleFailed: true,
        redirectPath,
      });
    }
    case ARTICLES_TYPES.GET_ARTICLE_RELATED_SECTION_SUCCEEDED: {
      const {
        payload: { remoteId, relatedSectionType, sectionId, index },
      } = action;

      const article = state.articlesByRemoteId[remoteId];
      if (!article) {
        return state;
      }
      let updatedRelatedSections = article.relatedSections || {};

      let updatedRelatedSectionList = (
        updatedRelatedSections[relatedSectionType] || []
      ).slice();

      if (!updatedRelatedSectionList.includes(sectionId)) {
        if (index != null) {
          // If we receive an index, but it is greater than the size/position we have processed,
          // expand the array and insert.
          if (updatedRelatedSectionList.length <= index) {
            updatedRelatedSectionList = [
              ...updatedRelatedSectionList,
              ...Array(
                Math.max(index + 1 - updatedRelatedSectionList.length, 0),
              ).fill(null),
            ];
          }
          updatedRelatedSectionList[index] = sectionId;
        } else {
          updatedRelatedSectionList.push(sectionId);
        }

        updatedRelatedSections = {
          ...updatedRelatedSections,
          [relatedSectionType]: updatedRelatedSectionList,
        };

        return merge({
          articlesByRemoteId: Object.assign({}, state.articlesByRemoteId, {
            [remoteId]: Object.assign({}, article, {
              relatedSections: updatedRelatedSections,
            }),
          }),
        });
      }
      return state;
    }
    case ARTICLES_TYPES.SET_VIDEO_ARTICLE_ID_HACK: {
      return merge(action.payload);
    }
    default:
      return state;
  }
}
