import { getCurrentArticle } from 'Webapp/shared/app/redux/selectors/article';

export default (_, getState, { t }) => {
  const article = getCurrentArticle(getState());
  if (article && article.isFirstPartyVideo) {
    if (article.description) {
      return article.description;
    }
    const topics = article.topics.map((t) => t.title).join(', ');
    return t('video_seo_description_generic', {
      profileName: article.author && article.author.authorDisplayName,
      topics,
    });
  }
};
