const BASE = 8;

const size = (val: number) => `${val}px`;

export const SPACING = Object.freeze({
  XSMALL: size(BASE / 4), // 2
  SMALL: size(BASE / 2), // 4
  BASE: size(BASE), // 8
  MEDIUM: size(BASE * 1.5), // 12
  LARGE: size(BASE * 2), // 16
  XLARGE: size(BASE * 3), // 24
  BASE4X: size(BASE * 4), // 32
  BASE6X: size(BASE * 6), // 48
  BASE8X: size(BASE * 8), // 64
  BASE10X: size(BASE * 10), // 80
  BASE12X: size(BASE * 12), // 96
});
