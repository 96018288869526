import { APP_TYPES } from 'Webapp/action-types';
import GlobalVars from 'Utils/global-vars';
import FlabContent from 'Webapp/shared/content/flab';
import ViewportTypes from 'Utils/viewport-types';
import logger from 'Utils/logger';
import { set, type } from 'Utils/redux';
import getWindow from 'Utils/get-window';
import getSEOTitle from 'Utils/seo/title';
import getBreadcrumbs from 'Utils/seo/breadcrumbs';
import getSocialGraphTitle from 'Utils/seo/social-graph-title';
import getMetaDescription from 'Utils/seo/meta-description';
import getSocialGraphDescription from 'Utils/seo/social-graph-description';
import { getSEOExperimentsForUrl } from 'Webapp/shared/utils/seo-experiments';
import { getBasicProjectedSection } from 'Utils/content/section-util';
import { PromiseAll } from 'Utils/promise';

import {
  getBasicSections,
  getSectionCovers,
} from 'Webapp/shared/app/redux/actions/section-actions';
import TestConfigValues from 'Utils/ngl/ngl-test-config-values';
import Config from 'Webapp/shared/config';
import { customBanners } from 'Webapp/client/lib/banners';
import sentry from 'Utils/sentry';
import { isAuthenticated } from 'Webapp/shared/app/redux/selectors/auth';
import { isProfileSection } from 'Webapp/predicates';
import Attribution from 'Webapp/utils/content/attribution';
import { isSectionCover } from 'Webapp/utils/content/item-util';

export const setFeatureFlagABTestSetupComplete = set(
  APP_TYPES.SET_FEATURE_FLAG_AB_TEST_SETUP_COMPLETE,
  'featureFlagABTestSetupComplete',
);

export const setSEOExperiments = (url: string) =>
  set(
    APP_TYPES.SET_SEO_EXPERIMENTS,
    'seoExperiments',
    getSEOExperimentsForUrl(url),
  );

export const setTitle =
  (options = {}): Flipboard.Thunk<string> =>
  (dispatch, getState, extraThunkArgs) => {
    const title = getSEOTitle(options, getState, extraThunkArgs);
    const document = getWindow().document;
    if (document) {
      document.title = title;
    }
    dispatch(set(APP_TYPES.SET_APP_TITLE, 'title', title));
    return title;
  };

export const setSocialGraphTitle =
  (options = {}): Flipboard.Thunk =>
  (dispatch, getState, extraThunkArgs) => {
    const socialGraphTitle = getSocialGraphTitle(
      options,
      getState,
      extraThunkArgs,
    );
    dispatch(
      set(
        APP_TYPES.SET_APP_SOCIAL_GRAPH_TITLE,
        'socialGraphTitle',
        socialGraphTitle,
      ),
    );
  };

export const refreshSignedMediaCookie =
  (): Flipboard.Thunk =>
  async (_dispatch, _getState, { signedMediaCookies }) => {
    if (GlobalVars.isDevelopment) {
      return;
    }
    await signedMediaCookies.refreshSignedMediaCookie();
  };

export const setMetaDescription =
  (options = {}): Flipboard.Thunk =>
  (dispatch, getState, extraThunkArgs) => {
    const metaDescription = getMetaDescription(
      options,
      getState,
      extraThunkArgs,
    );
    dispatch(
      set(
        APP_TYPES.SET_APP_META_DESCRIPTION,
        'metaDescription',
        metaDescription,
      ),
    );
  };

export const setSocialGraphDescription =
  (options = {}): Flipboard.Thunk =>
  (dispatch, getState, extraThunkArgs) => {
    const socialGraphDescription = getSocialGraphDescription(
      options,
      getState,
      extraThunkArgs,
    );
    dispatch(
      set(
        APP_TYPES.SET_APP_SOCIAL_GRAPH_DESCRIPTION,
        'socialGraphDescription',
        socialGraphDescription,
      ),
    );
  };

export const setViewportByBrowserWidth = (width: number) => {
  const viewportType = ViewportTypes.getViewportType(width);
  return set(APP_TYPES.SET_APP_VIEWPORT_TYPE, 'viewportType', viewportType);
};

export const setLocale = ({ lang, locale }) => ({
  type: APP_TYPES.SET_APP_LOCALE,
  lang,
  locale,
});

export const getBrowserExperiments =
  (overrides, resetExperiments = false): Flipboard.Thunk =>
  async (dispatch, getState) => {
    const {
      usage: { session: usageSession },
      app: { noExperiments },
      auth: { uid },
    } = getState();
    dispatch(type(APP_TYPES.GET_EXPERIMENTS));

    return FlabContent.requestExperiments(
      uid?.toString(),
      usageSession || undefined,
      overrides,
      resetExperiments,
    )
      .then((experiments) => {
        if (experiments) {
          dispatch(
            set(
              APP_TYPES.GET_EXPERIMENTS_SUCCEEDED,
              'experiments',
              noExperiments ? null : experiments,
            ),
          );
        } else {
          logger.error('failed to fetch experiments');
          dispatch(type(APP_TYPES.GET_EXPERIMENTS_FAILED));
        }
      })
      .catch((error: Error) => {
        logger.error('failed to fetch experiments');
        logger.error(error);
        dispatch(type(APP_TYPES.GET_EXPERIMENTS_FAILED));
      });
  };

export const getExperimentsWithCookies =
  (cookies, overrides): Flipboard.Thunk =>
  async (dispatch) => {
    dispatch(type(APP_TYPES.GET_EXPERIMENTS));

    try {
      const experiments = await FlabContent.getServerSideExperiments(
        cookies,
        overrides,
      );
      dispatch(
        set(APP_TYPES.GET_EXPERIMENTS_SUCCEEDED, 'experiments', experiments),
      );
    } catch (error) {
      logger.error('failed to fetch experiments');
      logger.error(error);
      dispatch(type(APP_TYPES.GET_EXPERIMENTS_FAILED));
    }
  };

// TODO: Remove switching based on query param
export const setNglFeedConfigs =
  (useTestNglFeedConfigs = false): Flipboard.Thunk =>
  async (dispatch, _, { flapStatic }) => {
    if (useTestNglFeedConfigs) {
      dispatch(
        set(
          APP_TYPES.SET_NGL_FEED_CONFIGS,
          'nglFeedConfigs',
          TestConfigValues.NGLFeedConfigs,
        ),
      );
    } else {
      const { data } = await flapStatic.get<{ NGLFeedConfigs: unknown }>(
        '/config.json',
      );
      const NGLFeedConfigs = data.NGLFeedConfigs;
      if (typeof NGLFeedConfigs !== 'undefined') {
        dispatch(
          set(APP_TYPES.SET_NGL_FEED_CONFIGS, 'nglFeedConfigs', NGLFeedConfigs),
        );
      }
    }
  };

export const getVideoAdsEnabled =
  (): Flipboard.Thunk =>
  async (dispatch, _getState, { flapStatic }) => {
    try {
      const {
        data: { enabled },
      } = await flapStatic.get('/videoAds.json');
      dispatch(
        set(APP_TYPES.SET_VIDEO_ADS_ENABLED, 'videoAdsEnabled', enabled),
      );
    } catch (error) {
      if (error instanceof Error) {
        sentry.captureException(error);
      }
      dispatch(set(APP_TYPES.SET_VIDEO_ADS_ENABLED, 'videoAdsEnabled', false));
    }
  };

export const setNavAppStoreUrl = (section: Flipboard.Section | null) => {
  // Default Adjust URL
  let navAppStoreUrl = Config.APP_STORE_URL_FOR_NAV;

  // NGL feed version
  if (section?.isStoryboard) {
    navAppStoreUrl = Config.APP_STORE_URL_FOR_NAV_NGL;
  }
  if (section?.isNglPromoted) {
    navAppStoreUrl = Config.APP_STORE_URL_FOR_NAV_NGL_PROMOTED;
  }

  return set(APP_TYPES.SET_NAV_APP_STORE_URL, 'navAppStoreUrl', navAppStoreUrl);
};

export const setBanners = (): Flipboard.Thunk => async (dispatch) => {
  dispatch(set(APP_TYPES.SET_APP_BANNERS, 'banners', customBanners));
};

export const refreshBanners = () => ({ type: APP_TYPES.REFRESH_APP_BANNERS });

export const setNotFound = set(APP_TYPES.SET_APP_NOT_FOUND, 'notFound');
export const setIsRSS = set(APP_TYPES.SET_APP_IS_RSS, 'isRSS');
export const setIsAmp = set(APP_TYPES.SET_APP_IS_AMP, 'isAmp');
export const setPageCanonicalPath = set(
  APP_TYPES.SET_APP_PAGE_CANONICAL_PATH,
  'pageCanonicalPath',
);
export const setViewport = set(APP_TYPES.SET_APP_VIEWPORT_TYPE, 'viewportType');
export const setExperimentOverrides = set(
  APP_TYPES.SET_EXPERIMENT_OVERRIDES,
  'experimentOverrides',
);
export const setNoExperiments = set(
  APP_TYPES.SET_NO_EXPERIMENTS,
  'noExperiments',
);
export const clearAppErrors = () => type(APP_TYPES.CLEAR_APP_ERRORS);
export const setAppTheme = set(APP_TYPES.SET_APP_THEME, 'appTheme');
export const setAppThemeOverride = set(
  APP_TYPES.SET_APP_THEME_OVERRIDE,
  'appThemeOverride',
);
export const setNavFromOverride = set(
  APP_TYPES.SET_NAV_FROM_OVERRIDE,
  'navFromOverride',
);
export const setAdjustNavFrom = set(
  APP_TYPES.SET_ADJUST_NAV_FROM,
  'adjustNavFrom',
);
export const setAdjustSectionId = set(
  APP_TYPES.SET_ADJUST_SECTION_ID,
  'adjustSectionId',
);
export const setServerComponent = set(
  APP_TYPES.SET_SERVER_COMPONENT,
  'serverComponent',
);
export const setAppUrl = set(APP_TYPES.SET_APP_URL, 'appUrl');
export const setGoogleAdVisibility = set(
  APP_TYPES.SET_GOOGLE_AD_VISIBILITY,
  'googleAdVisibility',
);
export const setIsNewUser = set(APP_TYPES.SET_IS_NEW_USER, 'isNewUser');
export const setIsFromCrawler = set(
  APP_TYPES.SET_IS_FROM_CRAWLER,
  'isFromCrawler',
);
export const setIsFromConfirmation = set(
  APP_TYPES.SET_IS_FROM_CONFIRMATION,
  'isFromConfirmation',
);
export const setIsFromInvitation = set(
  APP_TYPES.SET_IS_FROM_INVITATION,
  'isFromInvitation',
);
export const setIsChromeless = set(APP_TYPES.SET_IS_CHROMELESS, 'isChromeless');
export const setIsEmbeddable = set(APP_TYPES.SET_IS_EMBEDDABLE, 'isEmbeddable');
export const setIsGateDismissed = set(
  APP_TYPES.SET_IS_GATE_DISMISSED,
  'isGateDismissed',
);
export const setSuppressMobileGate = set(
  APP_TYPES.SET_SUPPRESS_MOBILE_GATE,
  'isMobileGateSuppressed',
);
export const setIsWebview = set(APP_TYPES.SET_IS_WEBVIEW, 'isWebView');
export const setIsIos = set(APP_TYPES.SET_IS_IOS, 'isIos');
export const setIsFromBriefing = set(
  APP_TYPES.SET_IS_FROM_BRIEFING,
  'isFromBriefing',
);
export const setUtmParams = set(APP_TYPES.SET_UTM_PARAMS, 'utmParams');
export const setReferrer = set(APP_TYPES.SET_REFERRER, 'referrer');
export const setActivationReferrer = set(
  APP_TYPES.SET_ACTIVATION_REFERRER,
  'activationReferrer',
);
export const setShownVideoAd = set(
  APP_TYPES.SET_SHOWN_VIDEO_AD,
  'shownVideoAd',
);
export const setDidNavigate = set(APP_TYPES.SET_DID_NAVIGATE, 'didNavigate');
export const setDidScrollToBottom = set(
  APP_TYPES.SET_DID_SCROLL_TO_BOTTOM,
  'didScrollToBottom',
);
export const setForcedTraceLog = set(
  APP_TYPES.SET_FORCED_TRACE_LOG,
  'forcedTraceLog',
);
export const setRouting = set(APP_TYPES.SET_ROUTING, 'routing');
export const setJobid = set(APP_TYPES.SET_JOBID, 'jobid');
export const setIsIntegration = set(
  APP_TYPES.SET_IS_INTEGRATION,
  'isIntegration',
);

export const setTriggerInviteOnboarding = set(
  APP_TYPES.SET_TRIGGER_INVITE_ONBOARDING,
  'triggerInviteOnboarding',
);

export const setBreadcrumbs =
  (options): Flipboard.Thunk =>
  (dispatch, getState) => {
    const breadcrumbs = getBreadcrumbs({
      ...options,
      isAuthenticated: isAuthenticated(getState()),
    });
    if (breadcrumbs) {
      return dispatch(
        set(APP_TYPES.SET_BREADCRUMBS, 'breadcrumbs', breadcrumbs.crumbs),
      );
    }
  };

export const resetBreadcrumbs = (): Flipboard.Thunk => (dispatch) => {
  dispatch(set(APP_TYPES.RESET_BREADCRUMBS, 'breadcrumbs', []));
};
export const setServiceWorkerRegistered = set(
  APP_TYPES.SET_SERVICE_WORKER_REGISTERED,
  'serviceWorkerRegistered',
);

export const getMobileHomeTiles =
  (ids: Array<Flipboard.SectionId>): Flipboard.Thunk =>
  async (dispatch) => {
    const [sections] = await PromiseAll<Array<Flipboard.Section>>([
      dispatch(getBasicSections(ids)),
      dispatch(getSectionCovers(ids)),
    ]);
    dispatch(
      set(
        APP_TYPES.SET_HOME_TILE_SECTIONS,
        'mobileHomeTileSections',
        sections.map((section) => getBasicProjectedSection(section)),
      ),
    );
  };

export const hideMainNavCTAs = () =>
  set(APP_TYPES.SET_HIDE_MAIN_NAV_CTAS, 'hideMainNavCTAs', true);

export const showMainNavCTAs = () =>
  set(APP_TYPES.SET_HIDE_MAIN_NAV_CTAS, 'hideMainNavCTAs', false);

export const setMastodonVerificationUrl = (mastodonVerificationUrl: string) =>
  set(
    APP_TYPES.SET_MASTODON_VERIFICATION_URL,
    'mastodonVerificationUrl',
    mastodonVerificationUrl,
  );

const setActivityPubAlternate = (url?: string) =>
  set(APP_TYPES.SET_ACTIVITY_PUB_ALTERNATE, 'activityPubAlternate', url);

export const setActivityPubAlternateArticle = (article: Flipboard.Item) => {
  const status = article.isStatus
    ? article
    : article.referredByItems?.find((i) => i.type === 'status');
  const author = Attribution.getAuthor(status);
  if (isProfileSection(author) && author.apEnabled && author.authorUsername) {
    return setActivityPubAlternate(
      `https://flipboard.com/users/${author.authorUsername}/statuses/${article.remoteServiceItemID}`,
    );
  }
  return setActivityPubAlternate(undefined);
};

export const setActivityPubAlternateActor = (section: Flipboard.Section) => {
  if (isProfileSection(section) && section.apEnabled) {
    return setActivityPubAlternate(
      `https://flipboard.com/users/${section.authorUsername}`,
    );
  }
  if (section.isMagazine) {
    if (section.magazineFederatedURI) {
      return setActivityPubAlternate(section.magazineFederatedURI);
    }
    const coverItem = section.items.find((item) => isSectionCover(item));
    if (coverItem && coverItem.remoteServiceItemID) {
      return setActivityPubAlternate(
        `https://flipboard.com/magazines/${coverItem.remoteServiceItemID}`,
      );
    }
  }
  return setActivityPubAlternate(undefined);
};
