import { Connector } from 'Webapp/utils/connector';
import { isAmp } from 'Webapp/shared/app/redux/selectors/app';

const selectors = { isAmp };

export type ConnectAmpProps = Flipboard.ConnectorProps<typeof selectors>;

const connectAmp: Connector = {
  selectors,
};

export default connectAmp;
