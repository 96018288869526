import qs from 'Utils/qs';
import { stripParams } from 'Utils/url';

/**
 * Generates the deep_link param to be included in the Adjust URL
 * @param {String} appUrl               - App URL to base deep link on
 * @param {Boolean} isAmp               - True if rendering for AMP
 * @param {String} adjust_nav_from      - Adjust-specific nav_from value (optional)
 * @param {String} section_id           - Section ID for showing section (optional)
 * @param {String} ab_tests             - The ab_tests param for deep links
 * @param {String} nav_from             - Standard "nav_from" value used for usage events
 * @param {Object} utmParams            - An object containing UTM parameters: utm_source, utm_medium, utm_campaign (optional)
 * @param {String} refer_url            - The referrer (optional)
 * @return {String}                     - The full deep_link URL
 */
function deepLink(
  appUrl,
  isAmp = false,
  adjust_nav_from,
  section_id,
  ab_tests = undefined,
  nav_from = null,
  utmParams = null,
  refer_url,
) {
  if (!appUrl) {
    return null;
  }

  // Decorate deep link with "from" and "section_id" as necessary
  let deepLinkParams = {};
  if (isAmp) {
    deepLinkParams.amp = true;
  }
  if (adjust_nav_from) {
    deepLinkParams.from = adjust_nav_from;
  }
  if (section_id) {
    deepLinkParams.section_id = section_id;
  }
  if (utmParams) {
    deepLinkParams = Object.assign({}, deepLinkParams, utmParams);
  }
  if (nav_from) {
    deepLinkParams.open_in_app_type = nav_from;
  }
  if (refer_url) {
    deepLinkParams.refer_url = refer_url;
  }
  if (ab_tests) {
    deepLinkParams.ab_tests = ab_tests;
  }

  // A/B tests set last so they appear at the end of the query params... they are the
  // least important for tracking
  const abTestsLastSort = (a, b) => {
    if (a === 'ab_tests') {
      return 1;
    }
    if (b === 'ab_tests') {
      return -1;
    }
    return 0;
  };

  const strippedAppUrl = stripParams(appUrl);
  return deepLinkParams && Object.keys(deepLinkParams).length > 0
    ? `${strippedAppUrl}?${qs.stringify(deepLinkParams, {
        sort: abTestsLastSort,
      })}`
    : strippedAppUrl;
}

/**
 * Generate an Adjust URL with query params
 * @param {String} adjustBaseUrl        - Base Adjust URL
 * @param {String} appUrl               - Deep link app URL (optional)
 * @param {Boolean} isAmp               - True if rendering for AMP
 * @param {String} adjust_nav_from      - Adjust-specific nav_from value (optional)
 * @param {String} section_id           - Section ID for showing section (optional)
 * @param {String} ab_tests             - The ab_tests param for deep links
 * @param {String} nav_from             - Standard "nav_from" value used for usage events
 * @param {Object} utmParams            - An object containing UTM parameters: utm_source, utm_medium, utm_campaign (optional)
 * @param {String} refer_url            - The referrer (optional)
 * @return {String}                     - The full Adjust URL
 */
function urlWithParams(
  adjustBaseUrl,
  appUrl = null,
  isAmp = false,
  adjust_nav_from,
  section_id,
  ab_tests = null,
  nav_from = null,
  utmParams = null,
  refer_url = null,
) {
  // If no deep link, return
  if (!appUrl) {
    return adjustBaseUrl;
  }

  const deepLinkUrl = deepLink(
    appUrl,
    isAmp,
    adjust_nav_from,
    section_id,
    ab_tests,
    nav_from,
    utmParams,
    refer_url,
  );

  return `${adjustBaseUrl}?deep_link=${encodeURIComponent(deepLinkUrl)}`;
}

export default { deepLink, urlWithParams };
