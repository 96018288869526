import GlobalVars from 'Utils/global-vars';
import getWindow from 'Utils/get-window';
import Debouncer from './debouncer';

const SCROLL_INTERVAL_MS = 50;

export class WindowScroll {
  constructor(onScroll) {
    const debounced = Debouncer.create(onScroll, SCROLL_INTERVAL_MS);
    this.throttledOnScroll = () => {
      this.cancel = debounced();
    };
  }

  /**
   * Adds a listener for window scroll that calls a callback
   * @param {function} onResize A callback function to call on every scroll event
   */
  subscribe() {
    getWindow().addEventListener('scroll', this.throttledOnScroll);
  }

  /**
   * Removes a listener for window scrolling
   * @param {function} onResize A callback function to remove with the listener
   */
  unsubscribe() {
    getWindow().removeEventListener('scroll', this.throttledOnScroll);
  }
}

export const getDocumentScrollPosition = () => {
  if (GlobalVars.isServer()) {
    return 0;
  }
  return Math.max(
    getWindow().pageYOffset,
    getWindow().document.documentElement.scrollTop,
    getWindow().document.body.scrollTop,
  );
};
