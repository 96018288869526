const TOPIC_ANCHOR_TRANSLATION_KEY = {
  food: 'topic_food',
  travel: 'topic_travel',
  entertainment: 'topic_entertainment',
  movies: 'topic_movies',
  fashion: 'topic_fashion',
  fitness: 'topic_fitness',
  workout: 'topic_workout',
  donaldtrump: 'topic_donaldtrump',
};

/**
 * Provides text to override the topic tag link text
 * @param {Object} topic    - A Flipboard topic object
 * @return {String}         - Anchor text for the topic tag link
 */
export const topicAnchorText = (topic, t) => {
  const { topicTag, title } = topic;
  const translationKey = TOPIC_ANCHOR_TRANSLATION_KEY[topicTag];
  if (translationKey) {
    return t(translationKey);
  }
  return title || topicTag;
};
