import WebLink from 'Webapp/shared/app/components/web-link';
import { euc } from 'Utils/url';
import NotificationThumb from 'Webapp/shared/app/components/notifications/notification-thumb';
import { NotificationContentComponent } from 'Webapp/shared/app/components/notifications/notification-text-click-strategies/index';

import NotificationTime from 'Webapp/shared/app/components/notifications/notification-time';

export const getSectionIdFromActionURL = (actionURL: Flipboard.ActionURL) => {
  const match = actionURL.match(/^flipboard:\/\/showSection\/([^?]+)\??.*$/);
  return match?.[1];
};

export const getURLFromActionURL = (actionURL: Flipboard.ActionURL) => {
  const match = actionURL.match(
    /^flipboard:\/\/showSection\/resolve%2Fflipboard%2Furl%252F([^?]+)\??.*$/,
  );
  return match?.[1];
};

const ActionUrl: NotificationContentComponent = ({
  notification,
  children,
}) => {
  const actionURL = notification.actionURL;
  if (!actionURL) {
    return null;
  }
  const url = getURLFromActionURL(actionURL);
  const sectionId = getSectionIdFromActionURL(actionURL);
  const hrefValue = url ? `/article/${euc(url)}` : `/section?id=${sectionId}`;
  return (
    <>
      <WebLink href={hrefValue} openInNewWindow>
        {children}
        <NotificationTime notification={notification} />
      </WebLink>
      <WebLink href={hrefValue} openInNewWindow>
        <NotificationThumb notification={notification} />
      </WebLink>
    </>
  );
};

export default ActionUrl;
