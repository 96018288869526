import connector from 'Utils/connector';
import ProfileAvatar from 'Webapp/shared/app/components/navigation/profile-avatar';
import { usageTrackCuratorProVerifiedAccountAccessNavBarEdu } from 'Webapp/shared/app/redux/actions/usage-actions';

export default connector({
  selectors: {
    profileMetrics: ({ profile }) =>
      profile.stats && profile.stats.profileMetrics,
  },
  actions: {
    usageTrackCuratorProVerifiedAccountAccessNavBarEdu,
  },
})(ProfileAvatar);
