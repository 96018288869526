import sentry from 'Utils/sentry';
import { getPathAndSearch } from 'Utils/url';
import { warn } from 'Utils/logger';

const unknown = (msg) =>
  warn(`normalizedRequestPathname - ${msg}`) || 'UNKNOWN';

const segment = '[^/]+';
const username = `@${segment}`;

const normalizedPathname = (url) => {
  try {
    if (typeof url !== 'string') {
      sentry.captureMessage(
        'normalizedRequestPathname: req.url is not a string',
        {
          url,
        },
      );
      return unknown(`url is not a string ${url}`);
    }
    let { pathname } = getPathAndSearch(url);
    if (!pathname) {
      sentry.captureMessage(
        'normalizedRequestPathname: could not get pathname',
        {
          url,
        },
      );
      return unknown(`could not get pathname ${url}`);
    }

    const isMatch = (...args) =>
      new RegExp(['^/', ...args.join('/+'), '/?$'].join('')).test(pathname);

    // replace /%40 with /@
    // we don't use decodeURIComponent or something like that because
    // there are encoded values in the :sourceURLKey :source and
    // params which will screw this all up
    pathname = pathname.replace(/^\/%40/, '/@');

    if (isMatch(username, 'storyboards', segment, 'edit')) {
      return '/@:username/storyboards/:storyboardId/edit';
    }
    if (isMatch(username, '.*-[a-z0-9]{16}')) {
      return '/@:username/:storyboardSlug';
    }
    if (isMatch(username)) {
      return '/@:username';
    }
    if (isMatch(username, segment, 'rss')) {
      return '/@:username/:slug/rss';
    }
    if (isMatch(username, segment, 'widget')) {
      return '/@:username/:slug/widget';
    }
    if (isMatch(username, segment)) {
      return '/@:username/:slug';
    }
    if (isMatch(username, segment, segment, segment, segment)) {
      return '/@:username/:slug/:articleTitle/:sourceURLKey/:source';
    }
    if (isMatch(username, segment, segment, segment)) {
      return '/@:username/:slug/:articleTitle/:source';
    }
    if (isMatch(username, segment, segment)) {
      return '/@:username/:slug/:articleTitle';
    }

    if (isMatch('article', segment, segment, segment)) {
      return '/article/:articleTitle/:sourceURLKey/:source';
    }

    if (isMatch('article', segment, segment)) {
      return '/article/:articleTitle/:sourceURLKey';
    }

    if (isMatch('article', segment)) {
      return '/article/:sourceURL';
    }

    if (isMatch('video-player', segment)) {
      return '/video-player/:source';
    }

    if (isMatch('search', segment)) {
      return '/search/:q';
    }

    if (isMatch('topic', segment, segment, segment)) {
      return '/topic/:topicId/:articleTitle/:sourceURLKey';
    }

    if (isMatch('topic', segment)) {
      return '/topic/:topicId';
    }

    if (isMatch('topic', segment, 'rss')) {
      return '/topic/:topicId/rss';
    }

    if (isMatch('videos', segment, segment, segment)) {
      return '/videos/:topicId/:articleTitle/:sourceURLKey';
    }

    if (isMatch('videos', segment)) {
      return '/videos/:topicId';
    }

    if (isMatch('videos', segment, 'rss')) {
      return '/videos/:topicId/rss';
    }

    if (isMatch('video', segment, segment)) {
      return '/video/:feed/:id';
    }

    if (isMatch('video', segment, segment, segment)) {
      return '/video/:feed/:id/:id';
    }

    if (isMatch('video', segment, segment, segment, segment)) {
      return '/video/:feed/:id/:id/:title';
    }

    if (isMatch('storyboard', segment)) {
      return '/storyboard/:topicId';
    }

    if (isMatch('storyboard', segment, 'rss')) {
      return '/storyboard/:topicId/rss';
    }

    if (isMatch('section', 'sid', segment)) {
      return '/section/sid/:slug';
    }

    if (isMatch('section', 'sid', segment, 'rss')) {
      return '/section/sid/:slug/rss';
    }

    if (isMatch('section', 'sid', segment, 'widget')) {
      return '/section/sid/:slug/widget';
    }

    if (isMatch('section', segment)) {
      return '/section/:slug';
    }

    if (isMatch('section', segment, 'rss')) {
      return '/section/:slug/rss';
    }

    if (isMatch('section', segment, 'widget')) {
      return '/section/:slug/widget';
    }

    if (isMatch('section', segment, segment, segment)) {
      return '/section/:slug/:articleTitle/:sourceURLKey';
    }

    if (isMatch('sitemap', segment)) {
      return '/sitemap/:type';
    }

    if (isMatch('sitemap', segment, segment)) {
      return '/sitemap/:type/:filter';
    }

    if (isMatch('sitemap', segment, segment, segment)) {
      return '/sitemap/:type/:filter/:page';
    }

    if (pathname.match(/^\/api\/v2/)) {
      const withReplacedParams = pathname
        .split('/')
        .map((chunk) => {
          if (chunk.match(/^[0-9]+_/) || chunk.match(/:[mc]:/i)) {
            return ':param';
          }
          return chunk;
        })
        .join('/');
      if (withReplacedParams.match(/\/0$/)) {
        return withReplacedParams;
      }
      return withReplacedParams.replace(/\/[0-9]+$/, '/:param');
    }

    // these should only happen in development, but they are annoying
    if (pathname.match(/^\/assets\//)) {
      return '/assets/:some-asset';
    }

    if (
      [
        '/',
        '/health',
        '/share',
        '/content_unavailable',
        '/following',
        '/section',
        '/settings',
        '/profile',
        '/app-ads.txt',
        '/comscore_pageview',
        '/account/emailSettings',
        '/newsletters',
        '/sitemap',
        '/sitemap/topics',
        '/sitemap/profiles',
        '/sitemap/storyboards',
        '/redirect',
        '/redirect/ws',
      ].indexOf(pathname) > -1
    ) {
      return pathname;
    }
    return unknown(`unable to match ${pathname}`);
  } catch (e) {
    sentry.captureException(e);
    return unknown(`caught ${e}`);
  }
};

export default normalizedPathname;
