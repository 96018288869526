import TopicUtil from 'Utils/content/topic';
import SectionUtil from 'Utils/content/section-util';
import FlapUtil from 'Utils/content/flap-util';
import { getBasicSections } from './section-actions';
export const estimateAudience =
  (topicTags, activeDays = 7) =>
  async (_, __, { flavour }) =>
    flavour.get('/v1/audience', {
      params: {
        topic: topicTags,
        active_days_back: activeDays,
        count_only: true,
      },
    });

export const getRecommendedTopics =
  (urls) =>
  async (_, __, { flavour }) =>
    flavour.post('/v1/recommend/package_tags', { urls });

/**
 * NOTE: This only works for magazine and topic sections
 * @param {*} sectionId
 * @param {Number} numTopics
 * @param {Boolean} includeSelf in the list of "related topics" by default.
 * @returns a number of topics given a sectionId via the flavour API
 */
export const getRelatedTopicsBySection =
  (section, numTopics, includeSelf = true) =>
  async (dispatch, __, { flavour }) => {
    const defaultReturnValue = [];
    if (!section.remoteid) {
      return defaultReturnValue;
    }

    try {
      const { data } = await flavour.get('/v2/section/topics', {
        params: {
          section_id: section.remoteid,
        },
        data: {},
      });
      if (data) {
        const topicRemoteIds = data.topics
          .slice(0, numTopics)
          .map((t) => TopicUtil.getTopicRemoteId(t.topic))
          .filter(
            (remoteid) =>
              includeSelf ||
              FlapUtil.normalizeRemoteid(section.remoteid) !==
                FlapUtil.normalizeRemoteid(remoteid),
          );
        if (topicRemoteIds.length === 0) {
          return defaultReturnValue;
        }
        const sections =
          (await dispatch(getBasicSections(topicRemoteIds))) ||
          defaultReturnValue;
        return sections.map((section) =>
          SectionUtil.getBasicProjectedSection(section),
        );
      }
    } catch (e) {
      return defaultReturnValue;
    }
  };
