import React from 'react';
import PropTypes from 'prop-types';
import GenericImage from './generic-image';
import classNames from 'classnames';

const DumbCroppedImage = (props) => {
  const classes = classNames(props.className, {
    'cropped-image': true,
    'media-container': true,
  });

  return (
    <div className={classes}>
      <GenericImage
        {...props}
        style={{ height: '100%', objectFit: 'cover' }}
        lazy
      />
    </div>
  );
};

DumbCroppedImage.propTypes = {
  className: PropTypes.string,
};

DumbCroppedImage.defaultProps = {
  className: null,
};

export default DumbCroppedImage;
