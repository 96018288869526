import getWindow from 'Utils/get-window';
import { revision } from 'Version';
import sentry from 'Utils/sentry';
import { VideoTrackerError } from 'Utils/errors';
import {
  getCookieValue,
  deleteCookie,
  setCookieValue,
} from 'Utils/client-cookie';

const cookieName = 'pending-video-playback-events';

const VideoTracker = function (video, options, sendUsageEventWithSession) {
  const prodType = options.prod_type;
  let viewMs = 0;
  let playCount = 0;
  let maxQuartile = null;
  let startedAt = null;
  let viewMsUpdatedAt = null;
  const reportedQuartiles = {};

  const getEventsCookieValue = () => {
    const value = getCookieValue(cookieName);
    if (value) {
      try {
        return JSON.parse(value);
      } catch (_) {
        // if we can't parse we have a bad value, throw it out
        deleteCookie(cookieName);
      }
    }
    return {};
  };

  const setEventsCookieValue = (value) => {
    setCookieValue(cookieName, JSON.stringify(value));
  };

  const reportPendingPlaybackEvents = () => {
    const events = getEventsCookieValue();
    Object.keys(events).map((id) => {
      const event = events[id];
      if (Object.keys(event.eventData).length > 0) {
        try {
          sendUsageEventWithSession(
            buildEvent(
              'playback',
              event.eventData,
              // queryParams was renamed to options, but might still be
              // in cookie for a while
              event.queryParams || event.options,
            ),
          );
        } catch (e) {
          sentry.captureException(e);
        }
      }
    });
    setEventsCookieValue({});
  };

  const createPlaybackEvent = () => {
    const events = getEventsCookieValue();
    events[options.item_id] = {
      options,
      eventData: {},
    };
    setEventsCookieValue(events);
  };

  const updatePlaybackEvent = (key, value) => {
    const events = getEventsCookieValue();
    if (!events[options.item_id] || !events[options.item_id].eventData) {
      return sentry.captureException(new VideoTrackerError());
    }
    events[options.item_id].eventData[key] = value;
    setEventsCookieValue(events);
  };

  const updateViewMs = () => {
    if (!startedAt && !viewMsUpdatedAt) {
      return;
    }
    const now = new Date();
    const watchedMsToAdd = viewMsUpdatedAt
      ? now.getTime() - viewMsUpdatedAt.getTime()
      : now.getTime() - startedAt.getTime();
    viewMs += watchedMsToAdd;
    viewMsUpdatedAt = new Date();
    updatePlaybackEvent('time_spent', viewMs);
  };

  const buildEvent = (event_action, eventData, params) => ({
    prod_type: prodType,
    event_category: 'item',
    event_action,
    event_data: {
      item_id: params.item_id,
      item_type: params.item_type || 'video',
      url: params.sourceURL,
      item_partner_id: params.item_partner_id,
      partner_id: params.partner_id,
      type: params.type,
      ssid: params.ssid,
      section_id: params.section_id,
      ...eventData,
    },
    properties: {
      appmode: 'samsung_daily',
      uid: params.uid || 0,
      time: Math.round(Date.now()),
      time_offset: -new Date().getTimezoneOffset(),
      git_version: revision,
      app_version: '1.0.0',
      locale: 'en',
      refer_url: getWindow() && getWindow().document.referrer,
      win_size:
        getWindow() && `${getWindow().innerWidth}x${getWindow().innerHeight}`,
    },
  });

  const reportQuartiles = () => {
    const percentagePlayed = (video.currentTime / video.duration) * 100;
    const percentageBuffer = 1;
    [0, 25, 50, 75, 100].forEach((q) => {
      if (percentagePlayed >= q - percentageBuffer) {
        reportQuartile(q);
      }
    });
  };

  const reportQuartile = (quartile) => {
    if (!maxQuartile || quartile >= maxQuartile) {
      maxQuartile = quartile;
      updatePlaybackEvent('number_items', maxQuartile);
    }
    if (reportedQuartiles[quartile] === true) {
      return;
    }
    reportedQuartiles[quartile] = true;
    sendUsageEventWithSession(
      buildEvent(
        'quartile',
        {
          method: `q${quartile}`,
        },
        options,
      ),
    );
  };

  reportPendingPlaybackEvents();

  video.addEventListener('play', () => {
    createPlaybackEvent();
    startedAt = new Date();
    viewMsUpdatedAt = null;

    if (video.currentTime >= 0 && video.currentTime < 1) {
      playCount = playCount + 1;
      updatePlaybackEvent('view_count', playCount);
    }
    reportQuartiles();
  });

  video.addEventListener('pause', () => {
    viewMsUpdatedAt = null;
    startedAt = null;
  });

  video.addEventListener('timeupdate', () => {
    updateViewMs();
    reportQuartiles();
  });

  video.addEventListener('ended', reportQuartiles);
};

export default VideoTracker;
