import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

import { AcceptMagazineInviteError } from 'Utils/errors';

import {
  onboardingDataSelector,
  setOnboardingData,
} from 'Webapp/shared/concepts/onboarding';

const acceptMagazineInviteConsent: OnboardingStep =
  (nextStep) => (dispatch, getState) => {
    const onboardingData = onboardingDataSelector(getState());
    if (!onboardingData?.magazineInviteSection) {
      throw new AcceptMagazineInviteError(
        'Accept Magazine Invite Flow is missing magazineInviteSection',
      );
    }

    const acceptMagazineInviteConsented =
      onboardingData?.acceptMagazineInviteConsented;
    if (acceptMagazineInviteConsented) {
      return nextStep();
    }
    dispatch(
      setOnboardingData({
        ...onboardingData,
        acceptMagazineInviteConsented: true,
      }),
    );
    nextStep();
  };

export default acceptMagazineInviteConsent;
