import Config from 'Config';
import getWindow from 'Utils/get-window';
// we use fetch here instead of flaxios because there is some bug in
// axios that results in cors cookies not getting set as they should
// https://github.com/axios/axios/issues/2303
export const refreshSignedMediaCookie = () =>
  getWindow().fetch(`${Config.SIGNED_MEDIA_COOKIES_URL}/?device=web`, {
    method: 'GET',
    credentials: 'include',
  });
