import FlabContent from 'Webapp/shared/content/flab.js';
import { isAuthenticated } from 'Webapp/shared/app/redux/selectors/auth';

export const isInABTest =
  (experimentId: number, groupNumber: number, loggedOutOnly = false) =>
  (state: Flipboard.State) => {
    const experiments = state && state.app && state.app.experiments;
    const group =
      experiments && FlabContent.getExperimentGroup(experiments, experimentId);
    return group === groupNumber && !(loggedOutOnly && isAuthenticated(state));
  };
