// all the same as SEO title, reuse
import article from 'Utils/seo/title/article';
import magazine from 'Utils/seo/title/magazine';
import topic from 'Utils/seo/title/topic';
import profile from 'Utils/seo/title/profile';
import home from 'Utils/seo/title/home';
import login from 'Utils/seo/title/login';
import signup from 'Utils/seo/title/signup';
import newsletter from 'Utils/seo/title/newsletter';
import newslettersLanding from 'Utils/seo/title/newsletters-landing';

import section from './section';

export default [
  signup,
  login,
  newsletter,
  newslettersLanding,
  article,
  magazine,
  topic,
  profile,
  section,
  home,
];
