import { Connector } from 'Webapp/utils/connector';
import {
  getComments,
  createComment,
  loadComments,
  purgeComments,
  removeComment,
  reportComment,
} from 'Webapp/shared/concepts/comments';
import {
  usageTrackItemComment,
  usageTrackItemSocialCardView,
} from 'Webapp/shared/app/redux/actions/usage-actions';

const selectors = {
  comments: getComments,
};

const actions = {
  purgeComments,
  createComment,
  loadComments,
  removeComment,
  reportComment,
  usageTrackItemComment,
  usageTrackItemSocialCardView,
};

export type ConnectCommentsProps = Flipboard.ConnectorProps<
  typeof selectors,
  typeof actions
>;

const connectComments: Connector = {
  selectors,
  actions,
};

export default connectComments;
