import React from 'react';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { LAYOUT_SIZES } from 'Style/layout';
import { getPixelNumberValue } from 'Style/style-helpers';

import scrollTo from 'Utils/scroll-to';

// Components
import { StyleModifiers } from 'Webapp/shared/app/components/button';
import { MeatballButton } from 'Webapp/shared/app/hocs/withItemActions.js';
import PinIcon from 'ComponentLibrary/icons/pin.js';
import { PinIconVariant } from 'Webapp/enums';

import connector from 'Webapp/utils/connector';
import connectFeatureFlags, {
  ConnectFeatureFlagsProps,
} from 'Webapp/shared/app/connectors/connectFeatureFlags';
import connectMagazineActions, {
  ConnectMagazineActionsProps,
} from 'Webapp/shared/app/connectors/connectMagazineActions';
import withT from 'ComponentLibrary/hocs/withT.js';

const PinLabel = styled.span({ marginLeft: SPACING.MEDIUM });

type PinFlipButtonProps = {
  item: Flipboard.Item;
  section: Flipboard.Section;
  pin: boolean;
  t: Flipboard.TFunction;
} & ConnectFeatureFlagsProps &
  ConnectMagazineActionsProps;

const PinFlipButton: React.FC<PinFlipButtonProps> = ({
  item,
  section,
  pin,
  pinMagazineItem,
  unpinMagazineItem,
  featureFlags,
  t,
  ...otherProps
}) => {
  const buttonLabel = pin ? t('pin_flip_action') : t('unpin_flip_action');
  const magazineTarget = section.magazineTarget;
  if (!magazineTarget || !featureFlags.PINNED_FLIP) {
    return null;
  }

  const scrollToFirstItem = () =>
    scrollTo(
      0,
      getPixelNumberValue(LAYOUT_SIZES.MAIN_NAV_HEIGHT) +
        getPixelNumberValue(LAYOUT_SIZES.MAGAZINE_HEADER_HEIGHT) +
        getPixelNumberValue(LAYOUT_SIZES.MAGAZINE_TOOL_BANNER_HEIGHT),
    );

  return (
    <MeatballButton
      name={`pin-flip-button-${item.id}`}
      key="pinned-flip--button"
      styleModifier={[StyleModifiers.WITH_BADGE]}
      onClick={async () => {
        if (pin) {
          await pinMagazineItem(section, item.id);
        } else {
          await unpinMagazineItem(section);
        }
        scrollToFirstItem();
      }}
      {...otherProps}
    >
      <PinIcon
        size={19}
        variant={pin ? PinIconVariant.DEFAULT : PinIconVariant.PINNED}
      />
      <PinLabel>{buttonLabel}</PinLabel>
    </MeatballButton>
  );
};

export default connector<PinFlipButtonProps>(
  connectMagazineActions,
  connectFeatureFlags,
)(withT(PinFlipButton));
