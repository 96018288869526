import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import Attribution from 'Utils/content/attribution';

// Components
import ProfileAvatar from '../profile-avatar';

export const AVATAR_SIZES = {
  small: 32,
  large: 64,
};

function AuthorAvatar({
  className,
  author,
  avatarSize,
  imageClass,
  containerClass,
}) {
  if (!author) {
    return null;
  }
  const containerClasses = classNames(className, containerClass, {
    'author-avatar': true,
  });
  return (
    <div className={containerClasses}>
      <ProfileAvatar
        name={Attribution.getAuthorName(author)}
        avatarSize={avatarSize}
        image={author && author.authorImage}
        imageClass={imageClass}
      />
    </div>
  );
}

AuthorAvatar.propTypes = {
  className: PropTypes.string,
  author: PropTypes.object.isRequired,
  avatarSize: PropTypes.number,
  containerClass: PropTypes.string,
  imageClass: PropTypes.string,
};

AuthorAvatar.defaultProps = {
  avatarSize: 35,
  containerClass: '',
  imageClass: '',
};

export default AuthorAvatar;
