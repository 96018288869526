import styled from '@emotion/styled';
import { truncate } from 'Style/truncate';
import { SPACING } from 'Style/spacing';

import ItemLink from 'Webapp/shared/app/components/item/item-link.js';
import NotificationThumb from 'Webapp/shared/app/components/notifications/notification-thumb';
import { NotificationContentComponent } from 'Webapp/shared/app/components/notifications/notification-text-click-strategies/index';
import NotificationTime from 'Webapp/shared/app/components/notifications/notification-time';

const Caption = styled.span(truncate('100%', 2), { marginTop: SPACING.SMALL });

/**
 * Generic Notification click strategy for anything where the first referredByItem is a linkable
 * object.
 */
const LinkItem: NotificationContentComponent<{ showCaption?: boolean }> = ({
  notification,
  children,
  showCaption,
}) => {
  const item = notification?.referredByItems?.[0];
  return (
    <>
      <ItemLink item={item} shownInList forceInternal>
        {children}
        {showCaption && item?.caption && <Caption>{item.caption}</Caption>}
        <NotificationTime notification={notification} />
      </ItemLink>
      <NotificationThumb notification={notification} isItemLink />
    </>
  );
};

export default LinkItem;
