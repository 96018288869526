import styled from '@emotion/styled';
import { styleOnlyProps } from 'Style/style-helpers';

const DisabledWrapper = styled(
  'div',
  styleOnlyProps('disabled'),
)<{ disabled?: boolean }>(({ disabled }) => {
  if (disabled) {
    return { opacity: '20%' };
  }
});

export default DisabledWrapper;
