import modalSignup from 'Webapp/shared/app/components/onboarding-flows/steps/modal-signup';
import trackOnboardAppStartAuthenticated from 'Webapp/shared/app/components/onboarding-flows/steps/track-onboard-app-start-authenticated';
import welcomeModal from 'Webapp/shared/app/components/onboarding-flows/steps/welcome-modal';
import performPostActivationActions from 'Webapp/shared/app/components/onboarding-flows/steps/perform-post-activation-actions';

import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

const steps: Array<OnboardingStep> = [
  modalSignup,
  trackOnboardAppStartAuthenticated,
  welcomeModal,
  performPostActivationActions,
];

export default steps;
