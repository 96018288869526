import { NotificationTextClickStrategy } from 'Webapp/shared/app/components/notifications/notification-text-click-strategies/index';
import { UnrecognizedNotificationError } from 'Webapp/utils/errors';
import sentry from 'Webapp/utils/sentry';

const strategy: NotificationTextClickStrategy = (notification) => {
  sentry.captureException(
    new UnrecognizedNotificationError(
      `notificationType: ${notification.notificationType}`,
    ),
  );
  return null;
};

export default strategy;
