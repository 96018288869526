import { Breadcrumbs } from 'Utils/content/structured-data';
import { euc } from 'Utils/url';
import {
  getProfilePath,
  getGeneralSectionPath,
} from 'Utils/content/flipboard-urls';

export default ({ section, isAuthenticated }) => {
  if (!section || !section.isMagazine) {
    return null;
  }
  const breadcrumbs = new Breadcrumbs();
  if (section.isMagazine) {
    if (section.authorUsername) {
      breadcrumbs.add(
        `@${euc(section.authorUsername)}`,
        getProfilePath(section),
      );
    }

    /**
     * This is so that we do not expose a canonical url that might mess a logged in user up who should be seeing the non-canonical / private variation of the url.
     */
    breadcrumbs.add(
      section.title,
      isAuthenticated ? getGeneralSectionPath(section) : section.canonicalPath,
    );
  }
  return breadcrumbs;
};
