import React from 'react';
import styled from '@emotion/styled';
import { TEXT_COLORS } from 'Style/colors';
import { SPACING } from 'Style/spacing';
import { BODY_TYPES } from 'Style/typography';

import NotificationsModal, {
  NotificationsModalPassedProps,
} from 'Webapp/shared/app/modals/notifications';
import BellIcon from 'ComponentLibrary/icons/bell.js';
import UnstyledButton from 'Webapp/shared/app/components/unstyled-button';

import getWindow from 'Webapp/utils/get-window';
import logger from 'Utils/logger';
import Debouncer from 'Utils/debouncer';

import { NotificationGroupType } from 'Webapp/enums';

import connector from 'Utils/connector';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';
import connectNotifications, {
  ConnectNotificationsProps,
} from 'Webapp/shared/app/connectors/connectNotifications';

const NotificationsButton = styled(UnstyledButton)({
  position: 'relative',
  padding: SPACING.SMALL,
});
const BellOverlay = styled.div({
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  background: 'red',
  width: SPACING.LARGE,
  height: SPACING.LARGE,
  borderRadius: '50%',
});
const NotificationCount = styled.span(BODY_TYPES.XSMALL_EMPHASIS, {
  fontSize: '9px',
  color: TEXT_COLORS.overlay,
  alignSelf: 'center',
});

type NotificationsProps = ConnectNotificationsProps & ConnectModalProps;
interface NotificationsState {
  checkedUnreadCountAt: Date;
  lastUserActivityAt: Date;
  updateIntervalMultiplier: number;
  backgrounded: boolean;
}

const BASE_INTERVAL = 30000;
const ACTIVITY_THRESHOLD = 5000;
const LOG = false;

const log = (...args: Array<unknown>) => LOG && logger.log(...args);

class Notifications extends React.Component<
  NotificationsProps,
  NotificationsState
> {
  constructor(props: NotificationsProps) {
    super(props);
    this.debouncedHandleUserInteraction = Debouncer.create(
      this.handleUserInteraction,
      250,
    ) as EventListener;
  }

  state = {
    checkedUnreadCountAt: new Date(),
    lastUserActivityAt: new Date(),
    updateIntervalMultiplier: 1,
    backgrounded: false,
  };

  componentDidMount() {
    this.update();
    window.addEventListener('blur', this.handleWindowBlur);
    this.movementEvents(getWindow().addEventListener);
  }

  componentDidUpdate(prevProps: NotificationsProps) {
    if (prevProps.filterGroup !== this.props.filterGroup) {
      this.update({ force: true });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('blur', this.handleWindowBlur);
    this.movementEvents(getWindow().removeEventListener);
  }

  timer = -1;

  debouncedHandleUserInteraction: EventListener;

  movementEvents = (
    fn: Window['addEventListener'] | Window['removeEventListener'],
  ) => {
    ['focus', 'scroll', 'mousemove', 'click'].forEach((eventName) =>
      fn(eventName, this.debouncedHandleUserInteraction),
    );
  };

  update = async ({ force } = { force: false }) => {
    log('notifications - update');
    if (this.state.backgrounded) {
      log('notifications - backgrounded, not updating');
      return;
    }
    getWindow().clearTimeout(this.timer);
    log('notifications - clear timeout');
    const { filterGroup, getUnreadCount, isModalShown, getNotifications } =
      this.props;
    const unreadCount = await getUnreadCount();
    if (force || (isModalShown(NotificationsModal) && unreadCount > 0)) {
      getNotifications(filterGroup);
    }
    this.setState(
      {
        updateIntervalMultiplier: this.state.updateIntervalMultiplier * 2,
        checkedUnreadCountAt: new Date(),
      },
      () => {
        log(
          'notifications - set updateIntervalMultiplier: ',
          this.state.updateIntervalMultiplier,
        );
        log(
          'notifications - next updated in: ',
          BASE_INTERVAL * this.state.updateIntervalMultiplier,
        );

        this.timer = getWindow().setTimeout(
          this.update,
          BASE_INTERVAL * this.state.updateIntervalMultiplier,
        );
      },
    );
  };

  handleWindowBlur = () => {
    log('notifications - handling window blur');
    this.setState({ backgrounded: true });
  };

  handleUserInteraction = async () => {
    log('notifications - handling user interaction');
    this.setState({ backgrounded: false });
    const { lastUserActivityAt, checkedUnreadCountAt } = this.state;
    const now = new Date();
    const sinceCheckedUnreadCountAt =
      now.getTime() - checkedUnreadCountAt.getTime();
    if (now.getTime() - lastUserActivityAt.getTime() < ACTIVITY_THRESHOLD) {
      return;
    }
    this.setState({ lastUserActivityAt: now }, () => {
      log('notifications - updated lastUserActivityAt:', lastUserActivityAt);
      if (sinceCheckedUnreadCountAt > BASE_INTERVAL) {
        log(
          'notifications - handling user interaction, been awhile, updating',
          lastUserActivityAt,
        );
        this.setState({ updateIntervalMultiplier: 1 }, this.update);
      } else {
        log(
          'notifications - handling user interaction, updated recently, bailing:',
          sinceCheckedUnreadCountAt,
        );
        log('notifications - resetting interval multiplier');
        this.setState({ updateIntervalMultiplier: 1 });
      }
    });
  };

  handleSetFilterGroup = (group: NotificationGroupType | null) => {
    const { filterGroup, clearNotifications, setNotificationsFilterGroup } =
      this.props;
    const newFilterGroup = group === filterGroup ? null : group;
    setNotificationsFilterGroup(newFilterGroup);
    clearNotifications();
  };

  loadNextPage = () => {
    const { filterGroup } = this.props;
    const lastNotification = this.props.notifications.slice(-1)[0];
    if (lastNotification) {
      this.props.getNotifications(filterGroup, lastNotification.id);
    }
  };

  render() {
    const { unreadCount, isModalShown, dismissSpecificModal, showModal } =
      this.props;

    const showCount = unreadCount > 0;
    const notificationsCount = unreadCount > 9 ? '9+' : unreadCount;
    return (
      <NotificationsButton
        onClick={() => {
          if (isModalShown(NotificationsModal)) {
            return dismissSpecificModal(NotificationsModal);
          }
          showModal<NotificationsModalPassedProps>(NotificationsModal, {
            handleSetFilterGroup: this.handleSetFilterGroup,
            loadNextPage: this.loadNextPage,
          });
        }}
        name="notifications"
      >
        <BellIcon />
        {showCount && (
          <BellOverlay>
            <NotificationCount>{notificationsCount}</NotificationCount>
          </BellOverlay>
        )}
      </NotificationsButton>
    );
  }
}

export default connector<NotificationsProps>(
  connectModal,
  connectNotifications,
)(Notifications);
