import GlobalVars from 'Utils/global-vars';
import ContentServer from './server';
import ContentClient from './client';

/**
 * Wrapper for Content axios instances.
 * To avoid any confusion in a isomorphic application, just import
 * this file as your Content axios instance, and it should work on servers and clients
 * import Content from 'Utils/api/content'
 */
const Content = GlobalVars.isServer() ? ContentServer : ContentClient;
export default Content;
