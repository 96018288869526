import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

import {
  showModal,
  dismissSpecificModal,
} from 'Webapp/shared/app/redux/actions/modal-actions';
import PublisherConfirmEmailModal from 'Webapp/shared/app/modals/publisher-confirm-email';
import { setCookieValue } from 'Webapp/utils/client-cookie';
import { resetOnboarding } from 'Webapp/shared/concepts/onboarding';

const publisherConfirmEmailModal: OnboardingStep = () => (dispatch) => {
  // should already be set during the signup process serverside, but
  // just in case
  setCookieValue('publisher-onboard', '1');
  dispatch(
    showModal(PublisherConfirmEmailModal, {
      onComplete: () => {
        dispatch(dismissSpecificModal(PublisherConfirmEmailModal));
        dispatch(resetOnboarding());
      },
    }),
  );
};

export default publisherConfirmEmailModal;
