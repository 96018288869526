import { factory as flaxiosFactory } from 'Utils/flaxios';
import { setupCache } from 'axios-cache-adapter';

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  exclude: {
    query: false,
  },
});

export const sitemapFactory = () =>
  flaxiosFactory({
    baseURL: `/api/v2/sitemap`,
    adapter: cache.adapter,
  });

export default sitemapFactory();
