import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  usageTrackNavBarEdu,
  usageTrackDialogCloseButtonTap,
  usageTrackTapAction,
  usageTrackTopicPickerCta,
  usageTrackTopicPickerCtaSeeMore,
  usageTrackTopicPickerCtaExit,
} from 'Webapp/shared/app/redux/actions/usage-actions';

// Utils
import {
  isVideoRoute,
  isHomeRoute,
  isNewslettersRoute,
  isSectionRoute,
  isEmailSettingsRoute,
} from '../redux/selectors/routing';
import { getCurrentArticle } from '../redux/selectors/article';
import { getPrimarySection } from '../redux/selectors/section';
import { sharedLinkDataSelector } from 'Webapp/shared/concepts/shared-link';
import Locales from 'Utils/locales';

import { isLargeScreen as isLargeScreenSelector } from 'Webapp/shared/app/redux/selectors/viewport.js';

const mapStateToProps = (state) => {
  const {
    app: { readyForMetrics, didNavigate, lang },
  } = state;
  const currentArticle = getCurrentArticle(state);
  const section = isSectionRoute(state) && getPrimarySection(state);
  const isStoryBoard = section && section.isStoryboard;
  const isFirstPartyVideo = currentArticle && currentArticle.isFirstPartyVideo;
  const isEnglish = Locales.isLangMatch(lang, 'en');
  const videoRoute = isVideoRoute(state);
  const homeRoute = isHomeRoute(state);
  const newslettersRoute = isNewslettersRoute(state);
  const emailSettingsRoute = isEmailSettingsRoute(state);
  const sharedLinkData = sharedLinkDataSelector(state);
  const isLargeScreen = isLargeScreenSelector(state);
  const shouldShowSignupCta =
    !homeRoute &&
    !newslettersRoute &&
    !isStoryBoard &&
    !videoRoute &&
    !emailSettingsRoute &&
    isEnglish &&
    !isFirstPartyVideo &&
    !didNavigate &&
    !sharedLinkData &&
    isLargeScreen;

  /**
   * Eventually swap out different logic. For now, use the exact same
   * logic as the normal CTA while we're running the AB Test
   */
  // const shouldShowTopicPickerCta =
  //   !homeRoute &&
  //   !newslettersRoute &&
  //   !videoRoute &&
  //   !emailSettingsRoute &&
  //   isEnglish &&
  //   !isFirstPartyVideo &&
  //   !didNavigate &&
  //   !sharedLinkData &&
  //   isLargeScreen;

  return {
    readyForMetrics,
    shouldShowSignupCta,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      usageTrackNavBarEdu,
      usageTrackDialogCloseButtonTap,
      usageTrackTapAction,
      usageTrackTopicPickerCta,
      usageTrackTopicPickerCtaSeeMore,
      usageTrackTopicPickerCtaExit,
    },
    dispatch,
  );

const withSignupCta = (Component) =>
  connect(mapStateToProps, mapDispatchToProps)(Component);

export default withSignupCta;
