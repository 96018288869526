import video from './video';
import article from './article';
import magazine from './magazine';
import storyboard from './storyboard';
import topic from './topic';
import profile from './profile';
import analyticsMagazine from './analytics-magazine';
import analyticsStoryboard from './analytics-storyboard';
import sitemapIndex from './sitemap-index';
import sitemapPage from './sitemap-page';

export default [
  video,
  article,
  magazine,
  storyboard,
  topic,
  profile,
  analyticsMagazine,
  analyticsStoryboard,
  sitemapIndex,
  sitemapPage,
];
