import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

import { setSignupCompleted } from 'Webapp/shared/app/redux/actions/auth-actions';
import { setOnboardingPending } from 'Webapp/shared/concepts/onboarding';

const completeSignup: OnboardingStep = (nextStep) => async (dispatch) => {
  dispatch(setOnboardingPending(true));
  dispatch(setSignupCompleted());
  nextStep();
};

export default completeSignup;
