export const URL_REGEXP = /(?:https?):\/\/[^\s/$.?#].[^\s]*/i;
export const URL_REGEXP_CAPTURE = /((?:https?):\/\/[^\s/$.?#].[^\s]*)/i;
export const URL_REGEXP_EXACT = /^(?:https?):\/\/[^\s/$.?#].[^\s]*$/i;
export const EMAIL_REGEXP =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;
export const UUID_REGEXP =
  /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/;

export const WIDGET_MAGAZINE_OR_STORYBOARD_REGEXP =
  /^https?:\/\/(webu-[\w]+.)?(localhost|flipboard.com)(:3000)?\/@[^/]+\/.+-(?:[\w]{16}|[\w]{9})$/;
export const WIDGET_PROFILE_REGEXP =
  /^https?:\/\/(webu-[\w]+.)?(localhost|flipboard.com)(:3000)?\/@[^/]+$/;

export const escapeRegExp = (string) => {
  if (!string) {
    return;
  }
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};
