import Config from 'Config';

import { setCookieValue } from 'Utils/client-cookie';
import { RECAPTCHA_SCORE_COOKIE_NAME } from 'Utils/analytics/usage';
import sentry from 'Utils/sentry';
import Auth from 'Utils/api/auth';
import getWindow from 'Utils/get-window';
import { noOpFn } from 'Utils/no-op-fn';

/**
 * This module implements Google reCAPTCHA v3 according to the following docs:
 * https://developers.google.com/recaptcha/docs/v3
 *
 * In addition, this module can load reCAPTCHA v2 on the same page:
 * https://developers.google.com/recaptcha/docs/faq#can-i-run-recaptcha-v2-and-v3-on-the-same-page
 */

const GOOGLE_RECAPTCHA_LIB_URL = 'https://www.google.com/recaptcha/api.js';

export const loadRecaptchaScript = (onLoad = undefined) => {
  if (getWindow().grecaptcha) {
    return;
  }

  getWindow().onRecaptchaLoad = onLoad || noOpFn;
  const scriptEl = getWindow().document.createElement('script');
  scriptEl.src = `${GOOGLE_RECAPTCHA_LIB_URL}?render=${Config.GOOGLE_RECAPTCHA_PUBLIC_KEY}&onload=onRecaptchaLoad`;
  document.getElementsByTagName('head')[0].appendChild(scriptEl);
};

/**
 * Retrieves a token for server verification
 * @param {String} action - String key used by reCAPTCHA metrics for reCAPTCHA v3
 * @returns {Boolean} - true if recaptcha is enabled, false otherwise
 */
const execute = (action) => {
  if (!window.grecaptcha) {
    throw new Error('No window.grecaptcha');
  }
  return window.grecaptcha.execute(Config.GOOGLE_RECAPTCHA_PUBLIC_KEY, {
    action,
  });
};

const verify = async (version, token) => {
  const response = await Auth.request({
    url: `/verifyV${version}`,
    method: 'POST',
    data: {
      response: token,
    },
    withCredentials: true,
  });
  return response.data;
};

/**
 * Validates the token and returns score
 * @param  {String} action - String key used by reCAPTCHA metrics
 * https://developers.google.com/recaptcha/docs/v3#actions
 */
export const validateRecaptchaV3 = async (action) => {
  try {
    const token = await execute(action);
    const data = await verify(3, token);
    return data;
  } catch (e) {
    sentry.captureException(e);
    return;
  }
};
/**
 * Validates the token and returns JSON object with success key as boolean value
 * @param  {String} token - String token
 * https://developers.google.com/recaptcha/docs/verify#api-response
 */
export const validateRecaptchaV2 = async (token) => {
  try {
    const data = await verify(2, token);
    return data;
  } catch (e) {
    sentry.captureException(e);
    return;
  }
};

/**
 * Set cookie for reCaptcha score in order to be used in outside of auth
 * @param  {Number} reCaptchaScore - score from validateRecaptchaV3 util function
 * Currently the recaptcha score is multiplied by 10 as per request: JIRA: FL-20159
 */
export const setCookieRecaptchaScore = (reCaptchaScore) => {
  setCookieValue(RECAPTCHA_SCORE_COOKIE_NAME, reCaptchaScore * 10);
};

export const THRESHOLD_SCORE = 0.5;
