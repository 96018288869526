import codeSplitLoad from 'Webapp/shared/utils/code-split-load';

import { likeItem } from 'Webapp/shared/app/redux/actions/social-actions.js';
import {
  followSections,
  addToCarousel,
} from 'Webapp/shared/app/redux/actions/profile-actions.js';
import { showModal } from 'Webapp/shared/app/redux/actions/modal-actions';
import { currentUserVerified } from 'Webapp/shared/app/redux/selectors/profile';
import { clearPostOnboardingSocialAction } from 'Webapp/shared/app/redux/actions/social-actions';

const FlipComposeModal = codeSplitLoad('FlipComposeModal');

export const performPostOnboardingSocialActions =
  (): Flipboard.Thunk => async (dispatch, getState) => {
    const {
      auth: { uid },
      profile: {
        postOnboardingSocialAction: { like, follow, favorite, flip },
      },
    } = getState();
    const userIsVerified = currentUserVerified(getState());
    if (like) {
      const { item, section, navFrom } = like as unknown as {
        item: Flipboard.Item;
        section: Flipboard.Section;
        navFrom: string;
      };
      dispatch(likeItem(item, uid, section, navFrom));
    }
    if (follow) {
      const { sections, navFrom } = follow as unknown as {
        sections: Array<Flipboard.Section>;
        navFrom: string;
      };
      await dispatch(followSections(sections, navFrom));
    }
    if (favorite) {
      const { sectionId, sectionTitle } = favorite as unknown as {
        sectionId: Flipboard.SectionId;
        sectionTitle: string;
      };
      dispatch(addToCarousel(sectionId, sectionTitle));
    }

    if (flip && userIsVerified) {
      const { item, section, navFrom } = flip as unknown as {
        item: Flipboard.Item;
        section: Flipboard.Section;
        navFrom: string;
      };
      dispatch(
        showModal(FlipComposeModal, {
          item,
          section,
          navFrom,
        }),
      );
    }
    return dispatch(clearPostOnboardingSocialAction());
  };
