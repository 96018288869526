import { getArticleRemoteId } from 'Utils/content/flipboard-urls';

export function getCurrentArticleRemoteId(state) {
  const { app, articles } = state;

  // we fall back to videoArticleRemoteIdHack so that video urls
  // like: /video/some-title/f75cfbb27e/10d24d7572 work because the
  // remote id isn't in the params
  return (
    (app &&
      app.routing &&
      app.routing.params &&
      getArticleRemoteId(app.routing.params)) ||
    (articles && articles.videoArticleRemoteIdHack)
  );
}

export function getCurrentArticle(state) {
  const { articles } = state;
  const remoteId = getCurrentArticleRemoteId(state);

  if (remoteId) {
    const { articlesByRemoteId } = articles;
    return articlesByRemoteId[remoteId];
  }
}

export function getCurrentArticleSection(state) {
  const article = getCurrentArticle(state);

  const articleSectionId = article && article.articleSectionId;
  if (!articleSectionId) {
    return null;
  }
  const { entries } = state.sections;
  return entries.find((section) => section.remoteid === articleSectionId);
}
