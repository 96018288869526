import qs from 'Utils/qs';
import getWindow from 'Utils/get-window';
import { logRequest, debug } from 'Utils/logger';
import sentry from 'Utils/sentry';
import FlapUtil from 'Utils/content/flap-util';
import GlobalVars from 'Utils/global-vars';
import { parseCookie } from 'Utils/client-cookie';
import { RequestTimeoutError, NoResponseError } from 'Utils/errors';
import filterSensitive from 'Utils/filter-sensitive';
import Config from 'Config';

const FLAP_DEFAULT_PARAMS = {
  device: 'web',
  ver: Config.FLAP_VERSION,
  locale: 'en_US',
};

const FLAP_INTERNAL_DEFAULT_PARAMS = {
  device: 'web',
  ver: Config.FLAP_VERSION,
};

/**
 * Standard logging for request responses
 * @param {String} serviceName - Name of the Endpoint Service of the request
 * @param {String} requestId - id of the request
 * @param {String/Number} code - Response code
 * @param {Object} config - Axios request config
 * @param {String} errorData - Optional error information
 */
function log(serviceName, jobid, code, config, errorData) {
  if (!GlobalVars.isServer()) {
    return;
  }
  const { method, url, baseURL, params, metadata, data } = config;
  const filteredParams = filterSensitive(params);
  const filteredData = filterSensitive(data);
  const duration =
    metadata && metadata.startTime && new Date() - metadata.startTime;
  logRequest(
    jobid || config.headers?.['FL-JOB-ID'],
    serviceName,
    code,
    method && method.toUpperCase(),
    baseURL ? `${baseURL}${url}` : url,
    qs.stringify(filteredParams, { arrayFormat: 'repeat' }),
    filteredData,
    duration,
    errorData,
  );
}

function interceptApiGwRequest(config) {
  const cookies = parseCookie(config.headers.Cookie);
  const oauth2Token = cookies.oauth2_token;

  config.headers.Authorization = `Bearer ${oauth2Token}`;

  if (config.method === 'get' || config.method === 'delete') {
    delete config.data;
  }
}

/**
 * Default response interceptor
 * @param {Object} response - axios response
 * @param {String} serviceName - Name of endpoint service
 */
function interceptResponseHandler(response, serviceName) {
  const { data, config } = response;
  const jobid =
    config.headers['FL-JOB-ID'] || (config.params && config.params.jobid);
  const statusCode = FlapUtil.getResponseStatusCode(response);
  if (statusCode < 200 || statusCode > 299) {
    log(serviceName, jobid, statusCode, config, JSON.stringify(data));
    return Promise.reject(response);
  }
  let name;
  if (response && response.request && response.request.fromCache) {
    name = `${serviceName} (cache)`;
  } else {
    name = serviceName;
  }
  log(name, jobid, statusCode, config);
  return response;
}

/**
 * Default error response interceptor
 * @param {Object} error - axios error
 * @param {String} serviceName - Name of endpoint service
 */
function interceptResponseErrorHandler(error, serviceName) {
  const { response, code } = error;
  sentry.addBreadcrumb(`[${serviceName}] Server Failed Response Interceptor`);

  if (code === 'ECONNABORTED') {
    log(serviceName, null, code, {});
    sentry.captureException(new RequestTimeoutError());
    return Promise.reject(error);
  }

  if (!response) {
    log(serviceName, null, code, {});
    sentry.captureException(new NoResponseError());
    return Promise.reject(error);
  }

  const { config, data } = response;
  const statusCode = FlapUtil.getResponseStatusCode(response);
  const jobid = config.headers['FL-JOB-ID'] || config.params.jobid;
  log(serviceName, jobid, statusCode, config, JSON.stringify(data));
  if (statusCode !== 404) {
    sentry.captureException(error);
  }
  return Promise.reject(error);
}

/**
 * Accesses the meta elements in the DOM to look for a csrf token
 */
function getCsrfTokenFromMetaElement() {
  const metaElement = getWindow().document.querySelector(
    'meta[name="csrf-token"]',
  );
  return metaElement ? metaElement.getAttribute('content') : null;
}

export const maybeOverrideFlapBaseUrl = (req, instance) => {
  if (!GlobalVars.isProduction) {
    const {
      cookies: { FLAP_URL },
    } = req;
    if (FLAP_URL) {
      instance.defaults.baseURL = FLAP_URL;
      debug(`OVERRIDING FLAP baseURL with: ${instance.defaults.baseURL} !!!`);
    }
  }
};

export default {
  FLAP_DEFAULT_PARAMS,
  FLAP_INTERNAL_DEFAULT_PARAMS,
  log,
  interceptApiGwRequest,
  interceptResponseHandler,
  interceptResponseErrorHandler,
  getCsrfTokenFromMetaElement,
};
