import ViewportTypes from 'Utils/viewport-types';

export const viewportType = ({ app: { viewportType } }) => viewportType;

export const isPhone = ({ app: { viewportType } }) =>
  ViewportTypes.isPhone(viewportType);

export const isSmallScreen = ({ app: { viewportType } }) =>
  ViewportTypes.isSmallScreen(viewportType);

export const isTablet = ({ app: { viewportType } }) =>
  ViewportTypes.isTablet(viewportType);

export const isDesktop = ({ app: { viewportType } }) =>
  ViewportTypes.isDesktop(viewportType);

export const isLargeScreen = ({ app: { viewportType } }) =>
  ViewportTypes.isLargeScreen(viewportType);

export const isWideLargeScreen = ({ app: { viewportType } }) =>
  ViewportTypes.isWideLargeScreen(viewportType);

export const isTabletNarrow = ({ app: { viewportType } }) =>
  ViewportTypes.isTabletNarrow(viewportType);

export const isTabletWide = ({ app: { viewportType } }) =>
  ViewportTypes.isTabletWide(viewportType);
