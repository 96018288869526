import React, { Component } from 'react';
import PropTypes from 'prop-types';

import WebModal from '../components/web-modal';

import withHistory from 'Webapp/shared/app/hocs/withHistory';

import connector from 'Utils/connector';
import connectModal from 'Webapp/shared/app/connectors/connectModal';

class ModalWrapper extends Component {
  componentDidMount() {
    const { history } = this.props;
    this.unlisten = history.listen(this.props.dismissModal);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return <WebModal {...this.props} />;
  }
}

ModalWrapper.propTypes = {
  dismissModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default connector(connectModal)(withHistory(ModalWrapper));
