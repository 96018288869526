import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Config from '../../../config';

// Utils
import { AppTheme } from 'Webapp/enums';
import { CUSTOM_BANNER_IDS } from '../../../../client/lib/banners';
import { noOpFn } from 'Utils/no-op-fn';

import { BREAKPOINTS } from 'Style/breakpoints';
import { DIVIDER_COLORS, SURFACE_COLORS } from 'Style/colors';
import { LAYERS } from 'Style/layout';
import { SPACING } from 'Style/spacing';
import { UI_TEXT_TYPES } from 'Style/typography';

// Components
import WebLink from '../web-link';
import FlipboardLogo from 'ComponentLibrary/logos/flipboard';
import BannerImage from './banner-image';
import CloseIcon from 'ComponentLibrary/icons/close';
import Button, {
  StyleVariations,
  StyleModifiers,
} from 'Webapp/shared/app/components/button';
import ModalOverlay from 'ComponentLibrary/modals/modal-overlay';
import UnstyledButton from 'Webapp/shared/app/components/unstyled-button';
import SignupCtaGetAppModal from '../../modals/signup-cta-get-app';
import PlayStore from 'Webapp/shared/app/components/signup-cta/play-store';
import AppStore from 'Webapp/shared/app/components/signup-cta/app-store';

import connector from 'Utils/connector';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectModal from 'Webapp/shared/app/connectors/connectModal';
import connectTheme from 'Webapp/shared/app/connectors/connectTheme';

import withT from 'ComponentLibrary/hocs/withT';
import withHistory from 'Webapp/shared/app/hocs/withHistory';

const BANNER_IMAGES = {
  SMALL:
    'https://cdn.flipboard.com/dev_O/insideflipboard/10282020/download-app-small.png',
  MEDIUM:
    'https://cdn.flipboard.com/dev_O/insideflipboard/10282020/download-app-medium.png',
  LARGE:
    'https://cdn.flipboard.com/dev_O/insideflipboard/10282020/download-app-large.png',
};

const USAGE_BANNER_ID = 'app_install_banner';

const AppLinks = styled.div({
  display: 'flex',
  alignItems: 'center',
  gridTemplateColumns: '1fr 1fr 1fr',
  gap: '8px',
  width: 'fit-content',
});

const StyledAppStore = styled(AppStore)({
  minHeight: '30px',
  maxHeight: '45px',
  margin: '0 auto',
});

const StyledPlayStore = styled(PlayStore)({
  minHeight: '30px',
  maxHeight: '45px',
  margin: '0 auto',
});

const StyledWebLink = styled(WebLink)({
  display: 'contents',
});

const StyledImg = styled.img({
  minHeight: '30px',
  maxHeight: '45px',
  margin: '0 auto',
});

const CtaWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const TitleWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
});

const TitleContent = styled.div({
  maxWidth: '370px',
});

const CloseButton = styled(UnstyledButton)(
  BREAKPOINTS.tabletLandscapeDown({
    position: 'absolute',
    right: SPACING.LARGE,
    top: SPACING.LARGE,
  }),
);

const Banner = styled.div(
  {
    width: '100%',
    borderBottom: `solid 1px ${DIVIDER_COLORS.secondary}`,
    backgkoundColor: SURFACE_COLORS.secondary,
    display: 'grid',
    gridTemplateColumns: '1fr 276px',
    position: 'relative',
    [CloseButton]: {
      position: 'absolute',
      top: SPACING.LARGE,
      right: SPACING.LARGE,
    },
  },
  BREAKPOINTS.desktopUp({
    gridTemplateColumns: '1fr 516px',
  }),
  BREAKPOINTS.desktopLandscapeUp({
    gridTemplateColumns: '1fr 660px',
  }),
);

const BannerTitle = styled.h2(UI_TEXT_TYPES.PAGE_TITLE, {
  marginBottom: SPACING.BASE,
});

const BannerSubtitle = styled.h2(UI_TEXT_TYPES.BODY_2, {
  marginBottom: SPACING.LARGE,
});

const BannerContent = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  padding: `${SPACING.BASE4X} ${SPACING.XLARGE}`,
});

const BannerLogo = styled.div({ marginBottom: SPACING.MEDIUM });

const BannerWelcome = styled.h2(UI_TEXT_TYPES.BODY_2, {
  marginBottom: SPACING.MEDIUM,
});

const AppBannerPhone = styled.div({
  position: 'fixed',
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: SURFACE_COLORS.tertiary,
  padding: `${SPACING.XLARGE} ${SPACING.LARGE}`,
  zIndex: LAYERS.MODAL,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const AppBannerTablet = styled.div({
  width: '100%',
  backgroundColor: SURFACE_COLORS.tertiary,
  position: 'relative',
});

const PhoneBannerTitle = styled.h1(UI_TEXT_TYPES.PAGE_TITLE, {
  marginBottom: SPACING.LARGE,
  textAlign: 'center',
});

const ImageWrapper = styled.div({
  height: '100%',
  position: 'relative',
});

class DownloadAppBanner extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.renderSeparateAppLinks = this.renderSeparateAppLinks.bind(this);
  }

  componentDidMount() {
    const { usageTrackBannerEnter, history } = this.props;
    usageTrackBannerEnter(USAGE_BANNER_ID, history);
  }

  handleClose() {
    const { usageTrackBannerExit, onClose } = this.props;
    usageTrackBannerExit(USAGE_BANNER_ID);
    onClose(CUSTOM_BANNER_IDS.DOWNLOAD_APP);
  }

  renderSeparateAppLinks() {
    const { showModal, t, usageTrackTapAppInstall } = this.props;
    return (
      <AppLinks>
        <UnstyledButton
          name="download-app-banner-qr-download"
          onClick={() => {
            usageTrackTapAppInstall('qr_code');
            showModal(SignupCtaGetAppModal);
          }}
        >
          <StyledImg
            src="https://cdn.flipboard.com/dev_O/insideflipboard/10282020/download-button-qr.png"
            alt={t('use_qr_code')}
          />
        </UnstyledButton>
        <StyledWebLink
          href={Config.APP_STORE_URL_FOR_DESKTOP_APPLE}
          onClick={() => usageTrackTapAppInstall('ios_app_store')}
        >
          <StyledAppStore scale={1.125} />
        </StyledWebLink>
        <StyledWebLink
          href={Config.APP_STORE_URL_FOR_DESKTOP_GOOGLE}
          onClick={() => usageTrackTapAppInstall('google_play_store')}
        >
          <StyledPlayStore scale={1.125} />
        </StyledWebLink>
      </AppLinks>
    );
  }

  render() {
    const {
      profile,
      isDesktop,
      isTablet,
      isPhone,
      appTheme,
      t,
      usageTrackTapAppInstall,
    } = this.props;

    if (!profile) {
      return null;
    }

    const welcomeText =
      profile.name && !profile.frictionless
        ? t('welcome_profile', { name: profile.name })
        : t('welcome_to_flipboard');

    // classNames left for tests
    const titleNode = (
      <TitleWrapper>
        <TitleContent className="download-app-banner__title-content">
          <BannerSubtitle>{welcomeText}</BannerSubtitle>
          <BannerTitle className="ui-text--page-title">
            {t('download_app_banner_title')}
          </BannerTitle>
        </TitleContent>
      </TitleWrapper>
    );

    const closeButtonNode = (
      <CloseButton
        className="download-app-banner__close-button"
        name="download-app-banner-close"
        onClick={this.handleClose}
      >
        <CloseIcon
          color={appTheme === AppTheme.DARK || isDesktop ? '#fff' : '#000'}
        />
      </CloseButton>
    );

    if (isPhone) {
      return (
        <ModalOverlay appTheme={appTheme} onMouseDown={noOpFn}>
          <AppBannerPhone>
            {closeButtonNode}
            <BannerLogo>
              <FlipboardLogo size={30} />
            </BannerLogo>
            <BannerWelcome>{welcomeText}</BannerWelcome>
            <PhoneBannerTitle>
              {t('download_app_banner_title')}
            </PhoneBannerTitle>
            <Button
              name="banner-cta"
              label={t('download_app')}
              styleVariation={StyleVariations.PRIMARY}
              styleModifier={[StyleModifiers.BLOCK]}
              href={Config.APP_STORE_URL_FOR_BANNER}
              onClick={() => usageTrackTapAppInstall('download_app')}
            />
          </AppBannerPhone>
        </ModalOverlay>
      );
    } else if (isTablet) {
      return (
        <AppBannerTablet>
          <BannerContent>
            {titleNode}
            <CtaWrapper>{this.renderSeparateAppLinks()}</CtaWrapper>
          </BannerContent>
          {closeButtonNode}
        </AppBannerTablet>
      );
    }

    return (
      <Banner>
        <BannerContent>
          {titleNode}
          {this.renderSeparateAppLinks()}
        </BannerContent>
        <ImageWrapper>
          <BannerImage
            smallImage={BANNER_IMAGES.SMALL}
            mediumImage={BANNER_IMAGES.MEDIUM}
            largeImage={BANNER_IMAGES.LARGE}
          />
          {closeButtonNode}
        </ImageWrapper>
      </Banner>
    );
  }
}

DownloadAppBanner.propTypes = {
  profile: PropTypes.object,
  isDesktop: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  isPhone: PropTypes.bool.isRequired,
  appTheme: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  usageTrackBannerEnter: PropTypes.func.isRequired,
  usageTrackBannerExit: PropTypes.func.isRequired,
  usageTrackTapAppInstall: PropTypes.func.isRequired,
};

DownloadAppBanner.defaultProps = {
  profile: null,
};

export default connector(
  connectResponsive,
  connectTheme,
  connectModal,
)(withHistory(withT(DownloadAppBanner)));
