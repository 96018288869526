import { SETTINGS_TYPES } from 'Webapp/action-types';
import { SaveState } from 'Webapp/enums';

interface SettingsReducerState {
  isLoading: boolean;
  saveState: SaveState;
}

const initialState: SettingsReducerState = {
  isLoading: false,
  saveState: SaveState.NONE,
};

export default function sectionReducer(state = initialState, action) {
  switch (action.type) {
    case SETTINGS_TYPES.SAVE_SETTINGS_PENDING: {
      return Object.assign({}, state, {
        saveState: SaveState.PENDING,
      });
    }
    case SETTINGS_TYPES.SAVE_SETTINGS_SUCCESS: {
      return Object.assign({}, state, {
        saveState: SaveState.SUCCEEDED,
      });
    }
    case SETTINGS_TYPES.SAVE_SETTINGS_FAILED: {
      return Object.assign({}, state, {
        saveState: SaveState.FAILED,
      });
    }
    case SETTINGS_TYPES.RESET_SETTINGS_SAVE_STATE: {
      return Object.assign({}, state, {
        saveState: SaveState.NONE,
      });
    }
    default:
      return state;
  }
}
