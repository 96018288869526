import FollowYou from 'Webapp/shared/app/components/notifications/content/follow-you';
import { FlapNotificationType } from 'Webapp/enums';
import { NotificationTextClickStrategy } from 'Webapp/shared/app/components/notifications/notification-text-click-strategies/index';

const strategy: NotificationTextClickStrategy = (notification) => {
  if (FlapNotificationType.FOLLOW_YOU !== notification.notificationType) {
    return null;
  }

  const section = notification.sectionLinks?.[0];

  if (!section) {
    return null;
  }

  return FollowYou;
};

export default strategy;
