import React from 'react';
import styled from '@emotion/styled';
import { UI_TEXT_TYPES, UI_SUBHEADING_TYPES } from 'Style/typography';
import { TEXT_COLORS } from 'Style/colors';

// Utils
import sentenceCase from 'Utils/content/sentence-case';
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';
import isIntegerMatch from 'Utils/is-integer-match';
import SectionUtil from 'Utils/content/section-util';
import {
  getMagazineContributorsHeaderText,
  getMagazineContributorsToolBannerText,
  MAX_NUMBER_OF_PROFILES,
} from 'Webapp/utils/content/contributors-list';

// Components
import WebLink from './web-link';
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectCurrentUser, {
  ConnectCurrentUserProps,
} from 'Webapp/shared/app/connectors/connectCurrentUser';

const contributorNameFontStyle = ({
  isNewMagazineCover,
  isNewMagazine,
}: {
  isNewMagazineCover?: boolean;
  isNewMagazine?: boolean;
}) => {
  if (isNewMagazineCover) {
    return { ...UI_SUBHEADING_TYPES.SMALL, color: TEXT_COLORS.overlay };
  } else if (isNewMagazine) {
    return {
      color: TEXT_COLORS.overlay,
    };
  }
  return UI_TEXT_TYPES.SUPPORTING_EMPHASIS;
};

const StyledProfileLink = styled(WebLink, {
  shouldForwardProp: (prop) =>
    prop !== 'isNewMagazineCover' && prop !== 'isNewMagazine',
})(contributorNameFontStyle);

const ProfileLink: React.FC<{
  isNewMagazine?: boolean;
  isNewMagazineCover?: boolean;
  profile: Flipboard.Contributor;
}> = ({ isNewMagazine, isNewMagazineCover, profile }) => (
  <StyledProfileLink
    isNewMagazineCover={isNewMagazineCover}
    isNewMagazine={isNewMagazine || false}
    name="magazine-contributors-description-profile-link"
    href={`/@${profile.authorUsername}`}
    navFromEventName={USAGE_EVENT_NAMES.SECTION_ENTER}
  >
    {profile.authorDisplayName}
  </StyledProfileLink>
);

type MagazineContributorsDescriptionProps = {
  profiles: Array<Flipboard.Contributor>;
  undisplayedCount: number;
  isNewMagazine: boolean;
  isNewMagazineCover: boolean;
  author: Flipboard.Section;
  entireProfiles: Array<Flipboard.Contributor>;
  section: Flipboard.Section;
  isMagazineContributorBanner?: boolean;
} & {
  t: Flipboard.TFunction;
} & ConnectCurrentUserProps;

const MagazineContributorsDescription: React.FC<MagazineContributorsDescriptionProps> =
  ({
    profiles,
    undisplayedCount,
    isNewMagazine,
    isNewMagazineCover,
    t,
    currentUser,
    section,
    entireProfiles,
    isMagazineContributorBanner,
  }) => {
    if (!profiles || profiles.length == 0) {
      return null;
    }

    if (!isNewMagazineCover) {
      const description = [
        `${isNewMagazine ? sentenceCase(t('curated_by')) : t('by')} `,
        <ProfileLink
          isNewMagazine={isNewMagazine}
          isNewMagazineCover={isNewMagazineCover}
          profile={profiles[0]}
          key={profiles[0].authorUsername}
        />,
      ];
      let remainder = profiles.slice(1);
      if (remainder.length > 0) {
        if (isNewMagazine) {
          description.push(<br key="br" />);
        }
        description.push(` ${t('with')} `);

        if (undisplayedCount > 0) {
          remainder.forEach((p, index) => {
            description.push(
              <ProfileLink
                isNewMagazine={isNewMagazine}
                profile={p}
                key={p.authorUsername}
              />,
            );
            if (index !== remainder.length - 1) {
              description.push(', ');
            }
          });
          description.push(` ${t('and')} ${undisplayedCount} ${t('others')}`);
        } else if (remainder.length > 1) {
          const lastIndex = remainder.length - 1;
          const last = remainder[lastIndex];
          remainder = remainder.slice(0, lastIndex);
          remainder.forEach((p, index) => {
            description.push(
              <ProfileLink
                isNewMagazine={isNewMagazine}
                profile={p}
                key={p.authorUsername}
              />,
            );
            if (index !== lastIndex) {
              description.push(', ');
            }
          });
          description.push(` ${t('and')} ${last.authorDisplayName}`);
        } else {
          const last = remainder[remainder.length - 1];
          description.push(
            <ProfileLink
              isNewMagazine={isNewMagazine}
              profile={last}
              key={last.authorUsername}
            />,
          );
        }
      }
      return description;
    } else if (isMagazineContributorBanner && isNewMagazineCover) {
      const bannerProfiles = profiles.slice(0, MAX_NUMBER_OF_PROFILES);

      const profileLinks = bannerProfiles.map((x) => () => (
        <ProfileLink
          isNewMagazineCover={isNewMagazineCover}
          isNewMagazine={isNewMagazine}
          profile={x}
          key={x.authorUsername}
        />
      ));

      return getMagazineContributorsToolBannerText(profileLinks, t);
    }

    let currentUserIsOwnerOrCollaborator: Flipboard.Contributor | false = false;
    // The first element of the profile array doesnt provide any
    // meaningful information such as userid to compare if
    // current user is the magazine owner.
    // section.userid provides that information.
    // Otherwise, we check if the current user is a collaborator.
    if (currentUser) {
      if (SectionUtil.isMagOwner(section, currentUser.userid)) {
        currentUserIsOwnerOrCollaborator = entireProfiles[0];
      }
      if (!currentUserIsOwnerOrCollaborator) {
        const foundProfile = entireProfiles.find((x) =>
          isIntegerMatch(x.userid, currentUser.userid),
        );
        if (foundProfile) {
          currentUserIsOwnerOrCollaborator = foundProfile;
        }
      }
    }

    const remainingProfilesCount = entireProfiles.length - 1;

    const userProfile = currentUserIsOwnerOrCollaborator
      ? currentUserIsOwnerOrCollaborator
      : profiles[0];

    const userProfileLink = () => (
      <ProfileLink
        isNewMagazineCover={isNewMagazineCover}
        isNewMagazine={isNewMagazine}
        profile={userProfile}
        key={userProfile.authorUsername}
      />
    );

    return getMagazineContributorsHeaderText(
      {
        userProfileLink,
        remainingProfilesCount,
        currentUserIsOwnerOrCollaborator: !!currentUserIsOwnerOrCollaborator,
      },
      t,
    );
  };

export default connector(connectCurrentUser)(
  withT(MagazineContributorsDescription),
);
