import { SPACING } from './spacing';
export const LAYOUT_SIZES = Object.freeze({
  MAIN_NAV_HEIGHT: '60px',
  MAIN_COLUMN_WIDTH: '1296px',
  MAIN_COLUMN_HALF_WIDTH: '560px',
  NARROW_COLUMN_WIDTH: '960px',
  LAPTOP_WIDTH: '1440px',
  MAGAZINE_HEADER_HEIGHT: '450px',
  MAGAZINE_HEADER_HEIGHT_PHONE: '495px',
  FLIP_MODE_HEIGHT: '104px',
  MAIN_NAV_PLUS_FLIP_MODE_HEIGHT: '164px',
  FLIP_COMPOSE_MAX_HEIGHT: '550px',
  FLIP_COMPOSE_IMAGE_MIN_HEIGHT: '240px',
  FLIP_COMPOSE_IMAGE_MAX_HEIGHT: '327px',
  MAGAZINE_TOOL_BANNER_HEIGHT: '128px',
  MAGAZINE_TOOL_BANNER_HEIGHT_PHONE: '72px',
  MAGAZINE_TOOL_BANNER_WIDTH: '324px',
  MAGAZINE_TOOL_BANNER_WIDTH_PHONE: '210px',
  POST_DETAIL_MIN_HEIGHT: '450px',
});

const CENTERED_COLUMN_BASE = {
  maxWidth: LAYOUT_SIZES.MAIN_COLUMN_WIDTH,
  margin: '0 auto',
  '@media (max-width: 1328px)': {
    // MAIN_COLUMN_WIDTH + BASE4X
    paddingLeft: SPACING.LARGE,
    paddingRight: SPACING.LARGE,
  },
};

export const PAGE_STYLES = {
  CENTERED_COLUMN: CENTERED_COLUMN_BASE,
  CENTERED_COLUMN_NARROW: {
    ...CENTERED_COLUMN_BASE,
    maxWidth: LAYOUT_SIZES.NARROW_COLUMN_WIDTH,
  },
  CENTERED_COLUMN_LARGE: {
    ...CENTERED_COLUMN_BASE,
    maxWidth: LAYOUT_SIZES.LAPTOP_WIDTH,
  },
  CENTERED_COLUMN_HALF: {
    ...CENTERED_COLUMN_BASE,
    maxWidth: LAYOUT_SIZES.MAIN_COLUMN_HALF_WIDTH,
  },
};

export const LAYERS = {
  DEVELOPMENT_FEATURE_FLAG: 3500,
  PERSISTENT_NOTIFICATION: 3400,
  LOADER: 3100,
  TOAST: 3000,
  MODAL_TOOLTIP: 3000,
  MODAL: 2950,
  TOOLTIP_FIXED: 2900,
  GALLERY: 2850,
  MAIN_NAV: 2900,
  STICKY_BANNER: 2750,
  ABOVE_STICKY_BANNER: 2775,
  STICKY_FOOTER: 2800,
  TOOLTIP: 2775,
  DROPDOWN: 2700,
  ACTION_SHEET: 2800,
  OFFCLICK: 2500,
  BASE: 1,
};

const SECTION_BASE = {
  position: 'relative',
  paddingBottom: SPACING.BASE4X,
};

export const LAYOUT_SECTION = {
  BASE: SECTION_BASE,
  HEADER: {
    ...LAYOUT_SECTION,
    margin: `0 0 ${SPACING.MEDIUM}`,
    display: 'inline-flex',
    alignItems: 'center',
  },
  SUB_HEADER: {
    ...LAYOUT_SECTION,
    margin: `${SPACING.BASE} 0 ${SPACING.MEDIUM}`,
    display: 'block',
  },
};

export const POST_MEDIA_SIZING = {
  mobileHeight: '257px',
  tabletHeight: '270px',
  tabletLandscapeHeight: '366px',
  desktopHeight: '277px',
};
