import GlobalVars from 'Utils/global-vars';
import PublisherServer from './server';
import PublisherClient from './client';

/**
 * Wrapper for Publisher axios instances.
 * To avoid any confusion in a isomorphic application, just import
 * this file as your Publisher axios instance, and it should work on servers and clients
 * import Publisher from 'Utils/api/Publisher'
 */
const Publisher = GlobalVars.isServer() ? PublisherServer : PublisherClient;
export default Publisher;
