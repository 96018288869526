import {
  flipUrl,
  flipSection,
  flipItem,
  pinMagazineItem,
  unpinMagazineItem,
} from 'Webapp/shared/app//redux/actions/magazine-actions';
import { Connector } from 'Webapp/utils/connector';

const selectors = {};

const actions = {
  flipUrl,
  flipSection,
  flipItem,
  pinMagazineItem,
  unpinMagazineItem,
};

export type ConnectMagazineActionsProps = Flipboard.ConnectorProps<
  typeof selectors,
  typeof actions
>;

const connectMagazineActions: Connector = {
  selectors,
  actions,
};

export default connectMagazineActions;
