import { factory as flaxiosFactory } from 'Utils/flaxios';
import sentry from 'Utils/sentry';

// Utils
import getOrigin from 'Utils/get-origin';
import getWindow from 'Utils/get-window';

/**
 * Axios instance to make Publisher requests from web-u client to web-u server
 */
const Publisher = flaxiosFactory({
  baseURL: `${getOrigin()}/api/v2/publishers/`,
  headers: { 'Content-Type': 'application/json' },
  timeout: 30000,
});

/**
 * Request interceptor
 * Attaches a csrf token to the header of all requests
 */
Publisher.interceptors.request.use((config) => {
  if (config.method.toLowerCase() === 'post') {
    const metaElement = getWindow().document.querySelector(
      'meta[name="csrf-token"]',
    );
    const token = metaElement && metaElement.getAttribute('content');
    config.headers['CSRF-Token'] = token;
  }
  return config;
});

/**
 * Handles default and Publisher errors
 */
Publisher.interceptors.response.use(
  (response) => {
    const { data, status } = response;
    if (status < 200 || status > 299) {
      return Promise.reject(data);
    }
    return response;
  },
  (error) => {
    sentry.addBreadcrumb('[Publisher Client] Failed Response Interceptor');
    const { response, code } = error;
    if (code === 'ECONNABORTED') {
      return Promise.reject(new Error('[Publisher Client] Request timed out'));
    }
    if (!response) {
      return Promise.reject(new Error('[Publisher Client] No response'));
    }

    if (response.status !== 404) {
      sentry.captureException(error);
    }
    return Promise.reject(error.response || error);
  },
);

export default Publisher;
