import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { UI_TEXT_TYPES } from 'Style/typography';

// Utils
import getWindow from 'Utils/get-window';

// Components
import Input from 'Webapp/shared/app/components/base/input';
import Label from 'ComponentLibrary/forms/label';
import ModalWrapper from './modal-wrapper';
import ModalControls from 'Webapp/shared/app/components/modal-controls';
import { MODAL_THEMES } from 'ComponentLibrary/modals/modal';
import Button, { StyleVariations } from 'Webapp/shared/app/components/button';
import withMagazineContributorInvitation from 'Webapp/shared/app/hocs/withMagazineContributorInvitation';
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectModal from 'Webapp/shared/app/connectors/connectModal';
const Title = styled.h1(UI_TEXT_TYPES.TITLE, { marginBottom: SPACING.LARGE });
const Description = styled.p(UI_TEXT_TYPES.BODY);

const CopyMessage = styled.p(UI_TEXT_TYPES.BODY, {
  marginBottom: SPACING.MEDIUM,
});
class InviteContributors extends Component {
  constructor(props) {
    super(props);

    this.state = { displayCopyMessage: false };
    this.clearCopyMessage = this.clearCopyMessage.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  handleFocus(e) {
    const input = e.target;
    input.setSelectionRange(0, this.props.inviteUrl.length);
    getWindow().document.execCommand('copy');
    this.setState({ displayCopyMessage: true });
    this.toggleCopyMessageTimeout = getWindow().setTimeout(
      this.clearCopyMessage,
      2000,
    );
  }

  clearCopyMessage() {
    if (this.toggleCopyMessageTimeout) {
      getWindow().clearTimeout(this.toggleCopyMessageTimeout);
      this.toggleCopyMessageTimeout = null;
      this.setState({ displayCopyMessage: false });
    }
  }

  render() {
    const { isPhone, t } = this.props;
    const modalTheme = isPhone ? MODAL_THEMES.FULLSCREEN : MODAL_THEMES.WINDOW;

    return (
      <ModalWrapper name="invite-contributors-modal" modalTheme={modalTheme}>
        <section className="modal__content">
          <Title>{t('invite_contributors')}</Title>
          <div className="field-block">
            <Description>{t('invite_contributors_description')}</Description>
          </div>
          <div className="field-block">
            <Label
              text={t('copy_url_to_invite_contributors')}
              textClassName="field-block__label"
            >
              <Input
                name="invite-contributors-url"
                type="url"
                onFocus={this.handleFocus}
                readOnly
                value={this.props.inviteUrl || ''}
              />
            </Label>
          </div>
          {this.state.displayCopyMessage && (
            <CopyMessage>{t('copied')}</CopyMessage>
          )}
        </section>
        <ModalControls>
          <Button
            name="invite-contributors-close"
            styleVariation={StyleVariations.SECONDARY}
            onClick={this.props.dismissModal}
          >
            {t('close')}
          </Button>
        </ModalControls>
      </ModalWrapper>
    );
  }
}

InviteContributors.propTypes = {
  magazine: PropTypes.object.isRequired,
  isPhone: PropTypes.bool.isRequired,
  inviteUrl: PropTypes.string,
  dismissModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

InviteContributors.defaultProps = {
  inviteUrl: null,
};

export default connector(
  connectModal,
  connectResponsive,
)(withMagazineContributorInvitation(withT(InviteContributors)));
