import React from 'react';
import Icon from './icon';

function SearchIcon(props) {
  return (
    <Icon
      name="search"
      size={22}
      viewBox="0 0 22 22"
      {...props}
      renderComponent={({ color }) => (
        <g
          transform="translate(2.000000, 2.000000)"
          stroke={color}
          strokeWidth="1.5"
          fill="none"
        >
          <circle cx="7" cy="7" r="7" />
          <path d="M12,13 L17,18" strokeLinecap="square" />
        </g>
      )}
    />
  );
}

export default SearchIcon;
