/**
 * Validates whether an array type prop contains one of availble values from a given object
 * @param  {Object} validValuesObject - Object containing values to check for
 * @return {(Object|Boolean)}         - Returns an Error object if it fails, otherwise returns true
 */
function memberOf(validValuesObject) {
  return (propValue, index, componentName, _location, propFullName) => {
    if (!Object.values(validValuesObject).includes(propValue[index])) {
      return new Error(
        `Invalid prop ${propFullName} supplied to ${componentName}. Validation failed.`,
      );
    }
    return true;
  };
}

export default { memberOf };
