import { Connector } from 'Webapp/utils/connector';
import {
  currentUserUid as uid,
  isAuthenticated,
  signupCompleted,
} from 'Webapp/shared/app/redux/selectors/auth';
import {
  resendConfirmation,
  logout,
} from 'Webapp/shared/app/redux/actions/auth-actions.js';

const selectors = { uid, isAuthenticated, signupCompleted };
const actions = { resendConfirmation, logout };

export type ConnectAuthenticationProps = Flipboard.ConnectorProps<
  typeof selectors,
  typeof actions
>;

const connectAuthentication: Connector = {
  selectors,
  actions,
};

export default connectAuthentication;
