import React from 'react';
import styled from '@emotion/styled';
import { BREAKPOINTS } from 'Style/breakpoints';
import { ICON_COLORS } from 'Style/colors';
import { styleObject, componentSelector } from 'Style/type-helpers';
import { LAYERS } from 'Style/layout';

import childrenWithProps from 'Webapp/utils/react-children-with-props';

import ModalControls from 'Webapp/shared/app/components/modal-controls';
import ModalWrapper from 'Webapp/shared/app/modals/modal-wrapper.js';
import ItemCoreActionDetailHeader from 'Webapp/shared/app/components/item-core-action-detail-header';
import {
  MODAL_WINDOW_SIZES,
  MODAL_OVERlAY_MODIFIERS,
  MODAL_THEMES,
  MODAL_MODIFIERS,
} from 'ComponentLibrary/modals/modal.js';
import { StyledCloseNewIcon } from 'ComponentLibrary/icons/close-new.js';

import connector from 'Webapp/utils/connector';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';

const StyledModalWrapper = styled(ModalWrapper)({
  [componentSelector(StyledCloseNewIcon)]: { color: ICON_COLORS.overlay },
});

const ModalFooter = styled.div(
  styleObject(
    BREAKPOINTS.tabletPortraitUp({
      overflow: 'hidden',
      borderRadius: `0 0 10px 10px`,
    }),
  ),
);
type ItemCoreActionDetailBodyProps = {
  footerHeight: number;
};
const ItemCoreActionDetailBody = styled.div<ItemCoreActionDetailBodyProps>(
  ({ footerHeight }) => ({
    overflowY: 'auto',
    height: `calc(100%+${footerHeight})`,
  }),
);

const StyledItemCoreActionDetailHeader = styled(ItemCoreActionDetailHeader)({
  borderRadius: '10px 10px 0 0',
  position: 'sticky',
  top: 0,
  zIndex: LAYERS.BASE,
});

interface ItemCoreActionModalPassedProps {
  item: Flipboard.Item;
  name: string;
  section: Flipboard.Section;
  modalTheme: keyof typeof MODAL_THEMES;
  expandCommentsHeader: boolean | false;
  onDismiss?: () => void;
}

type ItemCoreActionModalProps = ItemCoreActionModalPassedProps &
  ConnectModalProps;

class ItemCoreActionModal extends React.Component<ItemCoreActionModalProps> {
  footerRef = React.createRef<HTMLDivElement>();

  render() {
    const {
      item,
      section,
      dismissModal,
      modalTheme,
      children,
      name,
      onDismiss,
      expandCommentsHeader,
    } = this.props;

    const modifiedChildren =
      this.footerRef && children
        ? childrenWithProps(children, { footerRef: this.footerRef })
        : children;
    const footerHeight = this.footerRef?.current?.clientHeight || 0;

    const showCondensedHeader = !expandCommentsHeader && item.isStandalonePost;
    return (
      <StyledModalWrapper
        name={name}
        size={MODAL_WINDOW_SIZES.MEDIUM}
        overlayModifier={MODAL_OVERlAY_MODIFIERS.LIGHT}
        modalTheme={modalTheme || MODAL_THEMES.WINDOW}
        modifier={MODAL_MODIFIERS.HAS_FIXED_HEADER}
        onDismiss={onDismiss}
      >
        <ModalControls
          modalName="comments-modal"
          fixedControls
          closeButtonSize={18}
          showCloseButton
          onDismiss={dismissModal}
        />
        <StyledItemCoreActionDetailHeader
          item={item}
          section={section}
          condensedHeader={showCondensedHeader}
          expandCommentsHeader={expandCommentsHeader}
        />
        <ItemCoreActionDetailBody footerHeight={footerHeight}>
          {modifiedChildren}
        </ItemCoreActionDetailBody>
        <ModalFooter ref={this.footerRef} />
      </StyledModalWrapper>
    );
  }
}

export default connector<ItemCoreActionModalProps>(connectModal)(
  ItemCoreActionModal,
);
