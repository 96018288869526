import GlobalVars from 'Utils/global-vars';
import logger from 'Utils/logger';
import { revision } from 'Version';
import { setSentry } from 'Utils/sentry';

const init = (sentry, dsn, options) => {
  const combinedOptions = Object.assign(
    {
      dsn,
      environment: GlobalVars.deployEnvironment,
      beforeSend: (event) => {
        if (GlobalVars.environment !== 'development') {
          return event;
        }
        return (
          logger.info(`SENTRY beforeSend ${JSON.stringify(event)}`) || event
        );
      },
      release: revision,
      // TODO REMOVE IF WE TURN SENTRY BACK ON!
      defaultIntegrations: false,
    },
    options,
  );
  if (
    (GlobalVars.environment === 'staging' ||
      GlobalVars.environment === 'production') &&
    !dsn
  ) {
    throw new Error(
      'sentry dsn is not set when initializing on public environment!',
    );
  } else {
    logger.info(
      `Initializing sentry with options: ${JSON.stringify(combinedOptions)}`,
    );
  }

  sentry.init(combinedOptions);
  setSentry(sentry);
};

export default init;
