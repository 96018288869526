import { SEARCH_NAV_TYPES } from 'Webapp/action-types';

interface SearchNavReducerState {
  results: null | Flipboard.FlapSearchResults;
  loading: boolean;
}

const initialState: SearchNavReducerState = {
  results: null,
  loading: false,
};

export default function searchNavReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_NAV_TYPES.SEARCH_NAV:
      return Object.assign({}, state, { loading: true });
    case SEARCH_NAV_TYPES.SEARCH_NAV_SUCCEEDED: {
      return Object.assign({}, state, {
        loading: false,
        results: action.payload,
      });
    }
    case SEARCH_NAV_TYPES.CLEAR_SEARCH_NAV: {
      return initialState;
    }
    default:
      return state;
  }
}
