import React from 'react';
import PropTypes from 'prop-types';

import { addPostOnboardingSocialAction } from 'Webapp/shared/app/redux/actions/social-actions';

// Utils
import HocUtil from 'Utils/hoc-util';

// Components
import SocialActionGate from 'Webapp/shared/app/containers/modals/social-action-gate';
import MobileSocialActionGate from 'Webapp/shared/app/containers/modals/mobile-social-action-gate';

import connector from 'Utils/connector';
import connectModal from 'Webapp/shared/app/connectors/connectModal';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';

function withSocialActionGate(Component) {
  class WrappedComponent extends React.Component {
    showSocialActionGate = (actionType, mobileGateOverride = false) => {
      const { showModal, isPhone } = this.props;

      if (isPhone && !mobileGateOverride) {
        showModal(MobileSocialActionGate);
      } else {
        showModal(SocialActionGate, { actionType, mobileGateOverride });
      }
    };

    render() {
      return (
        <Component
          {...this.props}
          showSocialActionGate={this.showSocialActionGate}
        />
      );
    }
  }

  WrappedComponent.displayName = HocUtil.displayName(Component);
  WrappedComponent.propTypes = {
    showModal: PropTypes.func.isRequired,
    isPhone: PropTypes.bool.isRequired,
  };

  return connector(
    {
      actions: {
        addPostOnboardingSocialAction,
      },
    },
    connectResponsive,
    connectModal,
  )(WrappedComponent);
}

export default withSocialActionGate;
