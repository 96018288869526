import React, { Component } from 'react';
import PropTypes from 'prop-types';
import codeSplitLoad from 'Webapp/shared/utils/code-split-load';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { SURFACE_COLORS, DIVIDER_COLORS } from 'Style/colors';
import { LAYERS, LAYOUT_SIZES } from 'Style/layout';
import { BREAKPOINTS } from 'Style/breakpoints';

// Utils
import { USAGE_NAV_FROMS } from 'Utils/analytics/usage';

// Components
import { MODAL_THEMES, MODAL_MODIFIERS } from 'ComponentLibrary/modals/modal';
import { NavFromContext } from 'ComponentLibrary/context';
import Button, { StyleModifiers } from 'Webapp/shared/app/components/button';
import NavbarLogo from './navbar-logo';
import Search from './search';
import LoggedOutButtons from './logged-out-buttons';
import NavbarAccountVerificationStatus from 'Webapp/shared/app/components/navigation/navbar-account-verification-status';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectAmp from 'Webapp/shared/app/connectors/connectAmp';
import connectModal from 'Webapp/shared/app/connectors/connectModal';
import connectTheme from 'Webapp/shared/app/connectors/connectTheme';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectCurrentUser from 'Webapp/shared/app/connectors/connectCurrentUser';
import connectFlipMode from 'Webapp/shared/app/connectors/connectFlipMode';

const SearchModal = codeSplitLoad('SearchModal');
const FavoritesMenuModal = codeSplitLoad('FavoritesMenuModal');
const FavoritesMenu = codeSplitLoad('FavoritesMenu');
const NavbarMoreMenu = codeSplitLoad('NavbarMoreMenu');
const LoggedInButtons = codeSplitLoad('LoggedInButtons');

const MainNavSecondary = styled.ul({
  display: 'flex',
  gap: SPACING.LARGE,
  alignItems: 'center',
  justifyContent: 'flex-end',
  '> li': {
    display: 'inline-flex',
    cursor: 'pointer',
    '> a': {
      display: 'inline-flex',
    },
  },
});

const StyledNav = styled.nav(({ props }) => {
  const common = {
    width: '100%',
    height: LAYOUT_SIZES.MAIN_NAV_HEIGHT,
    padding: `0 ${SPACING.LARGE} 0 0`,
    position: 'relative',
    display: 'grid',
    gridTemplateRows: `${LAYOUT_SIZES.MAIN_NAV_HEIGHT}`,
    gridGap: SPACING.MEDIUM,
    backgroundColor: SURFACE_COLORS.primary,
    borderBottom: `1px solid ${DIVIDER_COLORS.primary}`,
    zIndex: LAYERS.MAIN_NAV,
    gridTemplateColumns: `${LAYOUT_SIZES.MAIN_NAV_HEIGHT} 1fr auto`,
    ...BREAKPOINTS.desktopUp({
      paddingRight: SPACING.XLARGE,
    }),
  };

  const unverifiedLargeScreen =
    props?.isLargeScreen && !props?.currentUserVerified;
  const verificationBasedStyle = unverifiedLargeScreen
    ? {
        gridTemplateColumns: `${LAYOUT_SIZES.MAIN_NAV_HEIGHT} 1fr auto`,
      }
    : {};

  const authenticationBasedStyle = props?.isAuthenticated
    ? verificationBasedStyle
    : {
        gridTemplateColumns: 'auto auto',
        ...BREAKPOINTS.smallPhone({
          gridTemplateColumns: '136px auto',
          gridGap: '0',
        }),
      };

  return {
    ...common,
    ...authenticationBasedStyle,
  };
});
const StickyHeader = styled.header((props) => {
  if (props?.googleAdVisibility === 0) {
    return {
      position: 'sticky',
      top: '0',
      zIndex: LAYERS.MAIN_NAV,
    };
  }
  return {};
});

class MainNavbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showProfileMenu: false,
      showFavoritesMenu: false,
    };
    this.searchContainer = React.createRef();
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleProfileMenuClick = this.handleProfileMenuClick.bind(this);
    this.handleFavoritesMenuClick = this.handleFavoritesMenuClick.bind(this);
    this.renderFavorites = this.renderFavorites.bind(this);
    this.renderLoggedInButtons = this.renderLoggedInButtons.bind(this);
  }

  componentDidMount() {
    const { usageTrackDisplayOpenInAppButton } = this.props;
    usageTrackDisplayOpenInAppButton();
  }

  getSearchPosition() {
    const searchPosition =
      this.searchContainer &&
      this.searchContainer.current &&
      this.searchContainer.current.getBoundingClientRect();
    return searchPosition || null;
  }

  handleSearchClick() {
    const modalTheme = this.props.isLargeScreen
      ? MODAL_THEMES.DROPDOWN
      : MODAL_THEMES.FULLSCREEN;

    this.props.showModal(SearchModal, {
      autoFocus: !this.props.isPhone,
      variant: modalTheme,
      modifier: MODAL_MODIFIERS.HAS_FIXED_HEADER,
      position: this.getSearchPosition(),
    });
  }

  handleProfileMenuClick() {
    this.setState({ showProfileMenu: !this.state.showProfileMenu });
  }

  handleFavoritesMenuClick() {
    const { showModal } = this.props;
    showModal(FavoritesMenuModal, {
      modifier: MODAL_MODIFIERS.HAS_FIXED_HEADER,
    });
  }

  renderFavorites() {
    const { isAuthenticated, currentSection, isLargeScreen } = this.props;
    if (!isAuthenticated) {
      return null;
    }

    if (isLargeScreen) {
      return <FavoritesMenu currentSection={currentSection} />;
    }

    return <NavbarMoreMenu onClick={this.handleFavoritesMenuClick} />;
  }

  renderLoggedInButtons() {
    return (
      <LoggedInButtons
        profileSection={this.props.profileSection}
        onProfileMenuClick={this.handleProfileMenuClick}
        isProfileMenuOpen={this.state.showProfileMenu}
        showModal={this.props.showModal}
        refreshBanners={this.props.refreshBanners}
        setAppThemeOverride={this.props.setAppThemeOverride}
      />
    );
  }

  render() {
    const {
      isAmp,
      isAuthenticated,
      t,
      isWideLargeScreen,
      googleAdVisibility,
      hideMainNavCTAs,
    } = this.props;

    return (
      <NavFromContext.Provider value={USAGE_NAV_FROMS.NAV_BAR}>
        <StickyHeader googleAdVisibility={googleAdVisibility}>
          <StyledNav props={this.props}>
            <NavbarLogo isAmp={isAmp} isAuthenticated={isAuthenticated} />
            {isAuthenticated && this.renderFavorites()}
            {(!hideMainNavCTAs || isAuthenticated) && (
              <MainNavSecondary>
                {!isAuthenticated && isWideLargeScreen && (
                  <Button
                    name="newsletters"
                    href="/newsletters"
                    tooltip={t('newsletters_sign_up_tooltip')}
                    styleModifier={[
                      StyleModifiers.NO_LEFT_PADDING,
                      StyleModifiers.NO_RIGHT_PADDING,
                    ]}
                  >
                    {t('newsletters_link')}
                  </Button>
                )}
                <NavbarAccountVerificationStatus />
                <Search
                  isAmp={this.props.isAmp}
                  isAuthenticated={this.props.isAuthenticated}
                  onClick={this.handleSearchClick}
                  ref={this.searchContainer}
                />
                {isAuthenticated ? (
                  this.renderLoggedInButtons()
                ) : (
                  <LoggedOutButtons
                    navAppStoreUrl={this.props.navAppStoreUrl}
                  />
                )}
              </MainNavSecondary>
            )}
          </StyledNav>
        </StickyHeader>
      </NavFromContext.Provider>
    );
  }
}

MainNavbar.propTypes = {
  navAppStoreUrl: PropTypes.string.isRequired,
  isAmp: PropTypes.bool,
  profileSection: PropTypes.object,
  setAppThemeOverride: PropTypes.func.isRequired,
  isLargeScreen: PropTypes.bool.isRequired,
  isWideLargeScreen: PropTypes.bool.isRequired,
  isPhone: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  dismissModal: PropTypes.func.isRequired,
  refreshBanners: PropTypes.func.isRequired,
  usageTrackDisplayOpenInAppButton: PropTypes.func.isRequired,
  googleAdVisibility: PropTypes.number,
  isAuthenticated: PropTypes.bool.isRequired,
  currentUserVerified: PropTypes.bool.isRequired,
  currentSection: PropTypes.object,
  t: PropTypes.func.isRequired,
  hasFlipModeFeature: PropTypes.bool.isRequired,
  hideMainNavCTAs: PropTypes.bool.isRequired,
};

MainNavbar.defaultProps = {
  isAmp: false,
  profileSection: null,
  googleAdVisibility: 0,
};

export default connector(
  connectCurrentUser,
  connectTheme,
  connectAmp,
  connectModal,
  connectResponsive,
  connectAuthentication,
  connectFlipMode,
)(withT(MainNavbar));
