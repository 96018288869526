import { factory as flaxiosFactory } from 'Utils/flaxios';
import sentry from 'Utils/sentry';
import getWindow from 'Utils/get-window';
import Locales from 'Utils/locales';
import Config from 'Config';

const flapStatic = flaxiosFactory({
  baseURL: `${Config.FLAP_STATIC_URL}/v1/static/`,
  headers: { 'Content-Type': 'application/json' },
  timeout: 14000,
});

flapStatic.interceptors.request.use((config) => {
  const clientLanguage = getWindow().navigator.language || 'en-US';
  const lang = Locales.getLanguageWithoutRegion(clientLanguage);
  const locale = Locales.getLocaleByLanguage(clientLanguage);
  if (!config.params) {
    config.params = {};
  }
  Object.assign(config.params, {
    lang,
    locale,
    ver: Config.FLAP_VERSION,
    device: 'web',
  });
  return config;
});

flapStatic.interceptors.response.use(
  (config) => config,
  (error) => {
    sentry.addBreadcrumb('[FLAP-STATIC Client] Failed Response Interceptor');
    const { response, code } = error;
    if (code === 'ECONNABORTED') {
      return Promise.reject(
        new Error('[FLAP-STATIC Client] Request timed out'),
      );
    }
    if (!response) {
      return Promise.reject(new Error('[FLAP-STATIC Client] No response'));
    }

    if (response.statusCode !== 404) {
      sentry.captureException(error);
    }
    return Promise.reject(error);
  },
);

export default flapStatic;
