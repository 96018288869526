import { Connector } from 'Utils/connector';

const selectors = {
  lang: ({ app: { lang } }: Flipboard.State) => lang,
  locale: ({ app: { locale } }: Flipboard.State) => locale,
};

export type ConnectLanguageProps = Flipboard.ConnectorProps<typeof selectors>;

const connectLanguage: Connector = {
  selectors,
};

export default connectLanguage;
