import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import HocUtil from 'Utils/hoc-util';
import { getSmartMagazineSection } from 'Utils/content/profile-util';

import withSocialActionGate from '../hocs/withSocialActionGate';
import withNavFrom from './withNavFrom';

import connector from 'Utils/connector';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectModal from 'Webapp/shared/app/connectors/connectModal';
import connectPersonalizedTopic from 'Webapp/shared/app/connectors/connectPersonalizedTopic';

import {
  usageTrackTapShareItem,
  usageTrackTapShareSection,
} from 'Webapp/shared/app/redux/actions/usage-actions';

import { SocialActionType } from 'Webapp/enums';
import PersonalizeTopicModal from 'Webapp/shared/app/containers/modals/personalize-topic';

import {
  addToCarousel,
  deleteFromCarousel,
} from 'Webapp/shared/app/redux/actions/profile-actions';

function withPersonalize(Component) {
  class WrappedComponent extends React.Component {
    handlePersonalize = (personalizeModalProps) => {
      const {
        isAuthenticated,
        showSocialActionGate,
        smartMagazines,
        showModal,
        navFrom,
      } = this.props;
      const { section, callback } = personalizeModalProps;
      if (!section) {
        return null;
      }

      /**
       * If we are creating a brand new section, we can work off of the loaded section (sections/entries/...)
       * If we are modifying an existing section, we need to instead work off of the (profile/smartMagazines/...
       *   as that is where the "personalized" parts are stored.
       */
      const smartMagazineSection =
        isAuthenticated &&
        getSmartMagazineSection(section.remoteid, smartMagazines);
      const loadSection = smartMagazineSection || section;

      if (isAuthenticated) {
        const modalProps = Object.assign({}, personalizeModalProps, {
          navFrom,
          topic: loadSection,
        });

        showModal(PersonalizeTopicModal, modalProps);
        callback && callback();
      } else {
        showSocialActionGate(SocialActionType.PERSONALIZE);
        personalizeModalProps.callback && personalizeModalProps.callback();
      }
    };

    render() {
      return (
        <Component {...this.props} onPersonalize={this.handlePersonalize} />
      );
    }
  }

  // Proxy the wrapped component displayName
  WrappedComponent.displayName = HocUtil.displayName(Component);
  WrappedComponent.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    showSocialActionGate: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    navFrom: PropTypes.string,
    usageTrackTapShareItem: PropTypes.func.isRequired,
    usageTrackTapShareSection: PropTypes.func.isRequired,
    entries: PropTypes.array,
    smartMagazines: PropTypes.array,
    smartMagazineSection: PropTypes.object,
  };

  WrappedComponent.defaultProps = {
    navFrom: null,
    smartMagazines: [],
  };

  const mapStateToProps = (state, { section }) => {
    const {
      sections: { entries },
      profile: { smartMagazines },
    } = state;
    const smartMagazineSection =
      section && getSmartMagazineSection(section, smartMagazines);
    return { smartMagazineSection, entries };
  };

  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        usageTrackTapShareItem,
        usageTrackTapShareSection,
        addToCarousel,
        deleteFromCarousel,
      },
      dispatch,
    );

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    connector(
      connectPersonalizedTopic,
      connectAuthentication,
      connectModal,
    )(withSocialActionGate(withNavFrom(WrappedComponent))),
  );
}

export default withPersonalize;
