import { Connector } from 'Webapp/utils/connector';

import { appTheme, appThemeOverride } from '../redux/selectors/app';

const selectors = { appTheme, appThemeOverride };

export type ConnectThemeProps = Flipboard.ConnectorProps<typeof selectors>;

const connectTheme: Connector = {
  selectors,
};

export default connectTheme;
