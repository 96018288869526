import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { COLORS, TEXT_COLORS } from 'Style/colors';

import connector from 'Utils/connector';

import connectMute from 'Webapp/shared/app/connectors/connectMute';
import withT from 'ComponentLibrary/hocs/withT';

const TopicTag = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

const TopicTitle = styled.span(({ opacity }) => ({
  marginLeft: 18,
  cursor: 'default',
  color: TEXT_COLORS.primary,
  opacity: opacity ?? 1,
}));

const StatusTag = styled.span({
  color: COLORS.red,
});

class RelatedTopicTag extends Component {
  constructor(props) {
    super(props);
    this.handleLessLike = this.handleLessLike.bind(this);
    this.state = {
      isUndoStatus: false,
    };
  }

  async handleLessLike() {
    const { topic, section, updateRelatedItemPreference } = this.props;
    const { isUndoStatus } = this.state;
    const selectedTopic = {
      type: 'topic',
      entity: topic.normalizedRemoteid,
      context: section.sectionID,
      title: topic.title,
    };

    await updateRelatedItemPreference(selectedTopic, isUndoStatus);
    this.setState({
      isUndoStatus: !isUndoStatus,
    });
  }

  render() {
    const { isUndoStatus } = this.state;
    const { topic, t } = this.props;
    return (
      <TopicTag>
        <TopicTitle
          opacity={isUndoStatus ? 0.5 : 1}
        >{`#${topic.title}`}</TopicTitle>
        <StatusTag onClick={this.handleLessLike}>
          {isUndoStatus ? t('undo') : t('show_less')}
        </StatusTag>
      </TopicTag>
    );
  }
}

RelatedTopicTag.propTypes = {
  topic: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  updateRelatedItemPreference: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default connector(connectMute)(withT(RelatedTopicTag));
