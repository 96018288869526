import React from 'react';
import { isSectionLinkAuthor } from 'Webapp/predicates';
import SectionUtil from 'Utils/content/section-util';

import ItemProfileListItem from 'Webapp/shared/app/components/item-profile-list-item';

interface ItemMetricDetailLike {
  item: Flipboard.FlapCommentaryItem;
}

const ItemMetricDetailLike: React.FC<ItemMetricDetailLike> = ({
  item: { sectionLinks },
}) => {
  const author = sectionLinks.find(isSectionLinkAuthor);
  if (!author) {
    return null;
  }
  return <ItemProfileListItem profile={SectionUtil.projection(author)} />;
};

export default ItemMetricDetailLike;
