import SectionUtil from 'Utils/content/section-util';
import FlapUtil from 'Utils/content/flap-util';

export const getSmartMagazineSection = (section, smartMagazines) => {
  if (!section || !smartMagazines) {
    return null;
  }
  const foundSmartMag = smartMagazines.find((sm) =>
    SectionUtil.mightBeSameSection(sm, section),
  );
  return (
    foundSmartMag ||
    getSmartMagazineSectionById(section.remoteid, smartMagazines)
  );
};

export const getSmartMagazineSectionById = (remoteId, smartMagazines) => {
  if (!remoteId || !smartMagazines) {
    return null;
  }
  const mightHaveSmartMag = FlapUtil.isTopicSectionId(remoteId);
  if (mightHaveSmartMag) {
    const foundSmartMag = smartMagazines?.find((sm) =>
      FlapUtil.isRemoteIdSectionMatch(remoteId, sm.rootTopic),
    );
    return foundSmartMag || null;
  }
  return null;
};

/**
 * Filters a list of magazines matching the titles against the query string.
 * @param {Array} magazines - Flipboard magazines
 * @param {String} query - Search term
 */
export const filterMagazinesByTitle = (
  magazines,
  query,
  filterIgnoreMagazines = [],
) => {
  if (!query) {
    return magazines;
  }

  return magazines.filter((magazine) => {
    if (SectionUtil.sectionsIncludesSection(filterIgnoreMagazines, magazine)) {
      return true;
    }
    const title = (magazine.title && magazine.title.toLowerCase()) || '';
    return title.includes(query.toLowerCase());
  });
};

/**
 * Filters out magazines that has exchange flags.
 * @param {Array} magazines - Flipboard magazines
 * https://github.com/Flipboard/services/pull/9443
 */
export const filterOutExchangeMagazines = (magazines) =>
  magazines.filter((magazine) => !magazine.exchange);
