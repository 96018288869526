import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Utils
import EduHints from '../../../../client/lib/edu-hints';

// Components
import UnstyledButton from 'Webapp/shared/app/components/unstyled-button';
import AuthorAvatar from '../attribution/author-avatar';
import AuthorAvatarDefault from '../attribution/author-avatar-default';
import EduDialog from 'ComponentLibrary/utility/edu-dialog';
import {
  ANCHORED_DIALOG_POSITION_X,
  ANCHORED_DIALOG_POSITION_Y,
} from 'ComponentLibrary/utility/anchored-dialog';
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';

import connectFeatureFlags from 'Webapp/shared/app/connectors/connectFeatureFlags';

const STORYBOARD_ACCESS_EDU_HINT = 'storyboard-access';

class NavigationProfileAvatar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isStoryboardEduVisible: false,
    };
    this.handleDismiss = this.handleDismiss.bind(this);
    this.shouldDisplayHint = this.shouldDisplayHint.bind(this);
    this.avatarElement = React.createRef();
  }

  componentDidMount() {
    if (!this.shouldDisplayHint()) {
      return;
    }
    this.setState({ isStoryboardEduVisible: true });
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.profileMetrics &&
      this.props.profileMetrics &&
      this.shouldDisplayHint()
    ) {
      this.setState({ isStoryboardEduVisible: true });
    }
  }

  shouldDisplayHint() {
    const { profileMetrics, featureFlags } = this.props;
    if (!featureFlags.INTERNAL_PACKAGE_MANAGEMENT) {
      return false;
    }

    const hasDismissedEdu = EduHints.hasDismissed(STORYBOARD_ACCESS_EDU_HINT);
    if (hasDismissedEdu) {
      return false;
    }

    const storyboardMetrics =
      profileMetrics && profileMetrics.find((m) => m.type === 'packageCount');

    if (!storyboardMetrics || storyboardMetrics.raw > 0) {
      return false;
    }

    return true;
  }

  handleDismiss() {
    this.setState({ isStoryboardEduVisible: false }, () =>
      EduHints.dismissEdu(STORYBOARD_ACCESS_EDU_HINT),
    );
  }

  render() {
    const {
      profileSection,
      onProfileMenuClick,
      usageTrackCuratorProVerifiedAccountAccessNavBarEdu,
      t,
    } = this.props;

    return (
      <React.Fragment>
        <div
          className="navigation-profile-avatar__wrapper"
          ref={this.avatarElement}
        >
          <UnstyledButton
            name="navbar-profile"
            onClick={onProfileMenuClick}
            className="media-link"
            stopPropagation={false}
          >
            {profileSection ? (
              <AuthorAvatar
                author={profileSection}
                imageClass="main-nav__avatar"
              />
            ) : (
              <AuthorAvatarDefault />
            )}
          </UnstyledButton>
          {this.state.isStoryboardEduVisible && (
            <EduDialog
              targetRef={this.avatarElement}
              fixed
              onMount={usageTrackCuratorProVerifiedAccountAccessNavBarEdu}
              positionX={ANCHORED_DIALOG_POSITION_X.RIGHT}
              positionY={ANCHORED_DIALOG_POSITION_Y.BELOW}
              onDismiss={this.handleDismiss}
            >
              {t('edu_hint_storyboard_access')}
            </EduDialog>
          )}
        </div>
      </React.Fragment>
    );
  }
}

NavigationProfileAvatar.propTypes = {
  profileSection: PropTypes.object,
  profileMetrics: PropTypes.array,
  onProfileMenuClick: PropTypes.func.isRequired,
  featureFlags: PropTypes.object.isRequired,
  usageTrackCuratorProVerifiedAccountAccessNavBarEdu: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

NavigationProfileAvatar.defaultProps = {
  profileSection: null,
  profileMetrics: null,
};

export default connector(connectFeatureFlags)(withT(NavigationProfileAvatar));
