import title from './title';
import storyboard from './storyboard';
import topic from './topic';
import magazine from './magazine';
import profile from './profile';
import article from './article';
import home from './home';
import newsletter from './newsletter';
import newslettersLanding from './newsletters-landing';
import signup from './signup';
import login from './login';

/**
 * NOTE: More than likely if you're adding/modifying this list, you also want to check ../social-graph-title/index.js
 * Verify if there is an exception to this rule.
 */
export default [
  title,
  signup,
  login,
  newslettersLanding,
  newsletter,
  storyboard,
  topic,
  magazine,
  profile,
  article,
  home,
];
