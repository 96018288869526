import { pick, flatMap, chunk, uniq, flattenDeep } from 'lodash';
import { isFlapCommentaryLike } from 'Webapp/predicates';
import isIntegerMatch from 'Utils/is-integer-match';
import { SOCIAL_ACTIVITY_TYPES } from 'Webapp/action-types';
import { currentUserUid } from 'Webapp/shared/app/redux/selectors/auth';
import { getArticleSocialId as getSocialId } from 'Utils/content/item-util.js';
import { type, setPayloadReducer } from 'Utils/redux';

import getWindow from 'Utils/get-window';

interface SocialActivityReducerState {
  [key: Flipboard.SocialId | Flipboard.ItemId]: Flipboard.SocialActivity;
}

const initialState: SocialActivityReducerState = {};

export const defaultSocialActivity: Flipboard.SocialActivity = {
  commentCount: 0,
  shareCount: 0,
  likeCount: 0,
  isLiked: false,
  global: {
    commentCount: 0,
    shareCount: 0,
    likeCount: 0,
  },
};

export const payloadReducer = setPayloadReducer<
  typeof SOCIAL_ACTIVITY_TYPES,
  SocialActivityReducerState
>(
  SOCIAL_ACTIVITY_TYPES,
  initialState,
  SOCIAL_ACTIVITY_TYPES.RESET_SOCIAL_ACTIVITY,
);

export const reducer = (
  state = initialState,
  action: {
    type: SOCIAL_ACTIVITY_TYPES;
    payload: { flipboardSocialId: Flipboard.SocialId | Flipboard.ItemId };
  },
): SocialActivityReducerState => {
  switch (action.type) {
    case SOCIAL_ACTIVITY_TYPES.LIKE_ITEM_PENDING:
    case SOCIAL_ACTIVITY_TYPES.LIKE_ITEM_FAILED:
    case SOCIAL_ACTIVITY_TYPES.LIKE_ITEM_SUCCEEDED:
    case SOCIAL_ACTIVITY_TYPES.UNLIKE_ITEM_PENDING:
    case SOCIAL_ACTIVITY_TYPES.UNLIKE_ITEM_FAILED:
    case SOCIAL_ACTIVITY_TYPES.UNLIKE_ITEM_SUCCEEDED: {
      const { flipboardSocialId } = action.payload;
      let newValue = false;
      const socialActivity = state[flipboardSocialId]
        ? { ...state[flipboardSocialId] }
        : { ...defaultSocialActivity };
      if (
        [
          SOCIAL_ACTIVITY_TYPES.LIKE_ITEM_PENDING,
          SOCIAL_ACTIVITY_TYPES.UNLIKE_ITEM_PENDING,
          SOCIAL_ACTIVITY_TYPES.LIKE_ITEM_FAILED,
          SOCIAL_ACTIVITY_TYPES.UNLIKE_ITEM_FAILED,
        ].includes(action.type)
      ) {
        newValue = socialActivity.isLiked ? false : true;
      } else {
        newValue = socialActivity.isLiked ? true : false;
      }
      socialActivity.isLiked = newValue;
      return {
        ...state,
        [flipboardSocialId]: socialActivity,
      };
    }
  }
  return payloadReducer(state, action);
};

// actions
let loadSocialActivityTimer = 0;
let pendingSocialActivityIdsToLoad: Array<Flipboard.SocialId> = [];
const queueSocialActivityLoad =
  (socialIds: Array<Flipboard.SocialId>): Flipboard.Thunk =>
  (dispatch) => {
    pendingSocialActivityIdsToLoad = uniq(
      pendingSocialActivityIdsToLoad.concat(socialIds),
    );
    getWindow().clearTimeout(loadSocialActivityTimer);
    loadSocialActivityTimer = getWindow().setTimeout(() => {
      const batches = chunk(pendingSocialActivityIdsToLoad, 64);
      batches.forEach((batch) => dispatch(loadSocialActivity(batch)));
      pendingSocialActivityIdsToLoad = [];
    }, 100);
  };

export const updateItemSocialActivity =
  (item: Flipboard.Item): Flipboard.Thunk =>
  (dispatch) =>
    dispatch(queueSocialActivityLoad([item.flipboardSocialId]));

export const queueLoadMissingSocialActivity =
  (): Flipboard.Thunk => (dispatch, getState) => {
    const {
      articles: { articlesByRemoteId },
      socialActivity,
      sections: { entries },
      accessorySections,
    } = getState();
    const accessorySectionSections = flattenDeep(
      Object.values(accessorySections).map((x) => Object.values(x)),
    );
    const sections = [...entries, ...accessorySectionSections];
    const socialIdsThatNeedLoaded = flatMap(sections, (x) => x.items).reduce<
      Array<Flipboard.SocialId>
    >((acc, item) => {
      const socialId = getSocialId(item) as Flipboard.SocialId;
      if (socialId && !socialActivity[socialId]) {
        acc.push(socialId as Flipboard.SocialId);
      }
      return acc;
    }, []);

    Object.values(
      articlesByRemoteId as Record<Flipboard.ItemId, Flipboard.Item>,
    ).forEach((item) => {
      if (item.flipboardSocialId) {
        socialIdsThatNeedLoaded.push(item.flipboardSocialId);
      }
    });

    dispatch(queueSocialActivityLoad(socialIdsThatNeedLoaded));
  };

export const loadSocialActivity =
  (socialIds: Array<Flipboard.SocialId>): Flipboard.Thunk =>
  async (dispatch, getState, { flap }) => {
    const uid = currentUserUid(getState());
    const params = {
      oid: socialIds,
      userid: uid,
    };

    if (socialIds && socialIds.length > 0) {
      const { data } = await flap.get<Flipboard.FlapSocialActivityResponse>(
        `/social/activity`,
        {
          params,
        },
      );
      if (!data) {
        return;
      }
      dispatch(updateSocialActivity(data.items));
    }
  };

export const updateSocialActivity =
  (items: Array<Flipboard.FlapSocialActivityItem>): Flipboard.Thunk =>
  (dispatch, getState) => {
    const {
      socialActivity,
      auth: { uid },
    } = getState();
    // clone for safety
    const updatedSocialActivity = { ...socialActivity };
    items.forEach((i) => {
      const update: Flipboard.SocialActivity = {
        // defaults
        ...defaultSocialActivity,
        // existing
        ...socialActivity[i.id],
        // incoming
        ...pick(i, ['global', 'commentCount', 'shareCount', 'likeCount']),
        isLiked: !!i.commentary.find(
          (x) => isFlapCommentaryLike(x) && isIntegerMatch(x.userid, uid),
        ),
      };
      updatedSocialActivity[i.id] = update;
    });
    dispatch({
      type: SOCIAL_ACTIVITY_TYPES.SET_SOCIAL_ACTIVITY,
      payload: updatedSocialActivity,
    });
  };

export const purgeSocialActivity = () =>
  type(SOCIAL_ACTIVITY_TYPES.RESET_SOCIAL_ACTIVITY);

// helpers
export const getSocialActivity = (
  id: Flipboard.SocialId,
  socialActivity: SocialActivityReducerState,
) => socialActivity[id] || defaultSocialActivity;

export const getSocialActivityValue = (
  item: Flipboard.Item,
  socialActivity: SocialActivityReducerState,
  key: 'shareCount' | 'commentCount' | 'likeCount',
  global = false,
) => {
  const itemSocialActivity = getSocialActivity(
    item?.flipboardSocialId,
    socialActivity,
  );
  const value =
    (global ? itemSocialActivity.global[key] : itemSocialActivity[key]) || 0;
  return value > 0 ? value : null;
};
