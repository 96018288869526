import GlobalVars from 'Utils/global-vars';
import logger from 'Utils/logger';
import * as SentryBrowser from '@sentry/browser';
import * as SentryNode from '@sentry/node';
import express from 'express';

type Value = undefined | string | number | boolean | SentryData;
type SentryArg =
  | string
  | Error
  | ({
      [key: string]: Value;
    } & {
      toJSON?: () => string;
    });

export type SentryData = {
  [key: string]: Value;
} & {
  toJSON?: string;
};

type Sentry = typeof SentryBrowser | typeof SentryNode;
let sentry: Sentry;
export const setSentry = (arg: typeof SentryBrowser | typeof SentryNode) => {
  sentry = arg;
};

const withSentry = (
  withSentry: (sentry: Sentry) => void,
  withoutSentry: () => void,
) => {
  if (sentry && sentry.getCurrentHub().getClient()?.getDsn()) {
    withSentry(sentry);
  } else {
    withoutSentry();
  }
};

/**
 * Abstraction for common logic between different Sentry lib functions.
 * @param {String} method - sentry lib function name
 * @param {String|Object|Error} arg - 1st argument expected by different sentry functions
 * @param {Object} metadata - Optional key/vals for setting extra metadata in the sentry log request
 */
const logAndExecute = (
  method:
    | 'captureException'
    | 'captureMessage'
    | 'addBreadcrumb'
    | 'captureEvent',
  arg: SentryArg,
  metadata?: SentryData,
) =>
  withSentry(
    (Sentry: Sentry) => {
      // If we get something that can be converted to JSON,
      // include as a property of the metadata object
      if (
        arg &&
        metadata &&
        typeof arg !== 'string' &&
        !(arg instanceof Error) &&
        typeof arg.toJSON === 'function'
      ) {
        metadata.toJSON = arg.toJSON();
      }
      Sentry.withScope((scope) => {
        if (metadata) {
          const metadataKeys = Object.keys(metadata);
          metadataKeys.forEach((k) => scope.setExtra(k, metadata[k]));
        }
        if (!GlobalVars.isIntegration) {
          // we don't need to do this reporting in integration, and these
          // are dynamic and hard to record replay
          //
          // DON'T SEND AT ALL FOR RIGHT NOW!
          // temporary? measure to help failing falling over old sentry
          // https://app.slack.com/client/T024LDP71/C2VFLHAFQ/thread/C2VFLHAFQ-1689185925.134419
          //    sentry.captureException(e as Error);
          // TODO restore
          // Sentry[method](arg);
        }
      });
    },
    () => {
      try {
        const loggableError = arg instanceof Error ? arg : JSON.stringify(arg);
        const loggableMetadata = JSON.stringify(metadata);
        logger.error(
          `SENTRY NOT REPORTING Received ${method} error: ${loggableError} - metadata: ${loggableMetadata}`,
        );
        if (typeof arg !== 'string' && arg.stack) {
          logger.error(arg.stack);
        } else {
          logger[method === 'captureMessage' ? 'info' : 'error'](loggableError);
        }
      } catch (error) {
        logger.error('CAUGHT ERROR TRYING TO LOG ERROR!', error, arg);
      }
    },
  );

/**
 * Reports an exception to Sentry
 * @param {Error} error       - Error instance
 * @param {Object} metadata - key/val pairs of custom metadata
 */
const captureException = (
  error: Error = new Error('No error provided'),
  metadata: SentryData = {},
) => {
  if (error instanceof Error) {
    logAndExecute('captureException', error, metadata);
  } else {
    logAndExecute(
      'captureException',
      new Error('No error provided - see additional data'),
      Object.assign({}, error, metadata),
    );
  }
};

/**
 * Reports a message to Sentry without an Error.
 */
const captureMessage = (message: string, metadata?: SentryData) => {
  if (message) {
    logAndExecute('captureMessage', message, metadata);
  } else {
    logAndExecute('captureException', 'empty message', metadata);
  }
};

/**
 * Reports a message to Sentry without an Error.  Adds
 * a "req" object to the metadata with data from an
 * Express request object.
 */
const captureMessageWithRequest = (
  message: string,
  req: express.Request,
  metadata: SentryData = {},
) => {
  captureMessage(
    message,
    Object.assign({}, metadata, {
      req: {
        path: req.path,
        params: req.params,
        headers: req.headers,
        query: req.query,
      },
    }),
  );
};

/**
 * Reports an event to Sentry
 */
const captureEvent = (message = 'No message provided', metadata = {}) =>
  logAndExecute('captureEvent', Object.assign(message, metadata));

const addBreadcrumb = (message: string, data = {}) => {
  logAndExecute('addBreadcrumb', { message, data });
};

export default {
  captureException,
  captureMessage,
  captureMessageWithRequest,
  captureEvent,
  addBreadcrumb,
};
