import modalAcceptMagazineInvite from 'Webapp/shared/app/components/onboarding-flows/steps/modal-accept-magazine-invite';
import showSocialGateMagazineInvite from 'Webapp/shared/app/components/onboarding-flows/steps/show-social-gate-magazine-invite';

import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

const steps: Array<OnboardingStep> = [
  modalAcceptMagazineInvite,
  showSocialGateMagazineInvite,
];

export default steps;
