import React from 'react';

// Utils
import { GA } from 'Utils/analytics';
import replaceTags from 'Utils/i18n/replace-tags';
import SectionUtil from 'Utils/content/section-util.js';

// Components
import ModalControls from 'Webapp/shared/app/components/modal-controls';
import SectionTag, {
  STYLE_VARIATIONS as TOPIC_TAG_STYLE_VARIATIONS,
} from 'Webapp/shared/app/components/section-tag.js';

import Button, { StyleVariations } from 'Webapp/shared/app/components/button';
import CaretIcon from 'ComponentLibrary/icons/caret.js';
import TopicPicker from 'Webapp/shared/app/components/topic-picker.js';
import { TopicCustomizationsContainerProps } from 'Webapp/shared/app/containers/topic-customization';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';

type TopicCustomizationProps = TopicCustomizationsContainerProps &
  ConnectModalProps & {
    topic: Flipboard.Section;
    onSubmit: (arg: Flipboard.Section[]) => void;
    dismissModal: () => void;
    goBack?: () => void;
    selectedCustomizations: Flipboard.Section[];
    t: Flipboard.TFunction;
    forceSubmittable: boolean;
  };

type TopicCustomizationState = {
  selectedCustomizations: Flipboard.Section[];
  sectionGroups: Flipboard.SectionGroup[];
  isLoadingSectionGroups: boolean;
};

class TopicCustomization extends React.Component<
  TopicCustomizationProps,
  TopicCustomizationState
> {
  static defaultProps = {
    selectedCustomizations: [],
    goBack: null,
    forceSubmittable: false,
  };
  state: TopicCustomizationState = {
    selectedCustomizations: this.props.selectedCustomizations.filter(
      (t) => t.title !== this.props.topic.title,
    ),
    sectionGroups: [],
    isLoadingSectionGroups: true,
  };

  async componentDidMount() {
    const { topic, fetchTopicCustomizations, t } = this.props;
    const sectionGroups = await fetchTopicCustomizations(topic.remoteid);

    this.setState({
      sectionGroups: sectionGroups.map((group) => {
        if (!group.title) {
          group.title = t('what_else_do_you_like', {
            topicTitle: topic.title,
          });
        }
        return group;
      }),
      isLoadingSectionGroups: false,
    });
  }

  isSelected(section: Flipboard.Section) {
    return SectionUtil.sectionsIncludesSection(
      this.state.selectedCustomizations,
      section,
    );
  }

  handleSubmit = (pickedTopics: Flipboard.Section[]) => {
    GA.trackClickButton('add favorite - done');
    this.setState(
      {
        isLoadingSectionGroups: true,
        selectedCustomizations: pickedTopics,
      },
      () => this.props.onSubmit(this.state.selectedCustomizations),
    );
  };

  handleBack = () => {
    GA.trackClickButton('add favorite - personalize - back');
    if (this.props.goBack) {
      this.props.goBack();
    }
  };
  render() {
    const { topic, goBack, dismissModal, forceSubmittable, t } = this.props;
    const { sectionGroups, selectedCustomizations, isLoadingSectionGroups } =
      this.state;
    return (
      <TopicPicker
        title={
          <React.Fragment>
            {replaceTags(
              t('personalize_topic', {
                topicTitle: this.props.topic.title,
              }),
              {
                topicTag: () => (
                  <SectionTag
                    section={this.props.topic}
                    styleVariation={TOPIC_TAG_STYLE_VARIATIONS.COLOR_ONLY}
                    isClickable={false}
                  />
                ),
              },
            )}
          </React.Fragment>
        }
        description={t('topic_personalize_description', {
          topicTitle: topic.title,
        })}
        noResultsText={t('no_subtopics_found')}
        className="modal__content"
        onSubmit={this.handleSubmit}
        followableGroups={sectionGroups}
        followed={selectedCustomizations}
        isLoadingFollowableGroups={isLoadingSectionGroups}
        collapsible
      >
        {({
          content,
          submit,
          isSearching,
          clearSearch,
          isSubmittable,
          isLoading,
        }) => (
          <React.Fragment>
            {content}
            {!isLoading && (
              <ModalControls className="topic-controls" fixedControls>
                {goBack && (
                  <Button
                    className="topic-customization__back-button"
                    name="topic-customization-back"
                    onClick={this.handleBack}
                  >
                    <CaretIcon size={9} />
                    &nbsp;{t('back')}
                  </Button>
                )}
                <Button
                  name="topic-customization-cancel"
                  styleVariation={StyleVariations.SECONDARY}
                  onClick={() => {
                    if (isSearching) {
                      return clearSearch();
                    }
                    dismissModal();
                  }}
                >
                  {isSearching ? t('back') : t('cancel')}
                </Button>
                <Button
                  name="topic-customization-done"
                  className="topic-customization__submit-button"
                  styleVariation={StyleVariations.PRIMARY}
                  onClick={submit}
                  disabled={forceSubmittable ? false : !isSubmittable}
                >
                  {isSearching ? t('next') : t('save')}
                </Button>
              </ModalControls>
            )}
          </React.Fragment>
        )}
      </TopicPicker>
    );
  }
}

export default connector<TopicCustomizationProps>(connectModal)(
  withT(TopicCustomization),
);
