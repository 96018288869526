import { NEWSLETTERS_LANDING_TYPES } from 'Webapp/action-types';
import { setPayloadReducer } from 'Utils/redux';

interface NewslettersLandingReducerState {
  configuration: Record<string, { image: string }>;
}
const initialState: NewslettersLandingReducerState = {
  configuration: {},
};

export default setPayloadReducer(NEWSLETTERS_LANDING_TYPES, initialState);
