import React from 'react';
import styled from '@emotion/styled';

import { SPACING } from 'Style/spacing';
import { COLORS } from 'Style/colors';
import { FONTS, FONT_WEIGHTS } from 'Style/typography';

import { durationFormat } from 'Utils/date-util';
import PlayIcon from 'ComponentLibrary/icons/play';

// Components
import SectionTag, {
  STYLE_VARIATIONS as TOPIC_TAG_STYLE_VARIATIONS,
} from '../../section-tag';

import PinnedTag from 'Webapp/shared/app/components/item/card/pinned-tag';

const StyleItemOverlay = styled.div({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',
});

const TagsOverlay = styled.div({
  position: 'absolute',
  left: SPACING.LARGE,
  bottom: SPACING.LARGE,
});

const StyledTopicTag = styled(SectionTag)({
  pointerEvents: 'auto',
});

const DurationOverlay = styled.div({
  fontFamily: FONTS.FONT_FAKT_FLIPBOARD,
  fontWeight: FONT_WEIGHTS.MEDIUM,
  fontSize: '10px',
  letterSpacing: '-0.125px',
  position: 'absolute',
  top: 'unset',
  left: 'unset',
  right: '0',
  bottom: '0',
  margin: SPACING.MEDIUM,
  padding: SPACING.SMALL,
  color: COLORS.staticWhite,
  borderRadius: '2px',
  background: 'rgba(17, 17, 17, 0.6)',
});

type ItemOverlayProps = {
  item: Flipboard.Item;
  showDuration: boolean;
  shownInList: boolean;
  isMedia: boolean;
  topicTag?: Flipboard.Section | null;
};

const ItemOverlay: React.FC<ItemOverlayProps> = ({
  item,
  showDuration,
  shownInList,
  isMedia,
  topicTag,
  ...props
}) => {
  const { duration, isVimeo } = item;

  return (
    <StyleItemOverlay>
      <TagsOverlay>
        {shownInList && <PinnedTag item={item} />}
        {topicTag && (
          <StyledTopicTag
            section={topicTag}
            styleVariation={TOPIC_TAG_STYLE_VARIATIONS.PILL}
          />
        )}
      </TagsOverlay>
      {isMedia && (
        <div>
          {!isVimeo && <PlayIcon {...props} />}
          {showDuration && duration && (
            <DurationOverlay>{durationFormat(duration)}</DurationOverlay>
          )}
        </div>
      )}
    </StyleItemOverlay>
  );
};

ItemOverlay.defaultProps = {
  showDuration: true,
  shownInList: false,
  isMedia: true,
  topicTag: null,
};

export default ItemOverlay;
