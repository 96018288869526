import { MAGAZINE_INVITE_TYPES } from 'Webapp/action-types';
import { set, setPayloadReducer } from 'Utils/redux';

export interface MagazineInviteReducerState {
  sectionId?: Flipboard.SectionId;
  token?: string;
  validationErrors?: Flipboard.FlapAcceptContributorInviteError['validationErrors'];
  pending?: boolean;
}

const initialState: MagazineInviteReducerState = {};

export const reducer = setPayloadReducer<
  typeof MAGAZINE_INVITE_TYPES,
  MagazineInviteReducerState
>(MAGAZINE_INVITE_TYPES, initialState);

export const setMagazineInviteToken = set(
  MAGAZINE_INVITE_TYPES.SET_MAGAZINE_INVITE_TOKEN,
  'token',
);
export const setMagazineInviteSection = set(
  MAGAZINE_INVITE_TYPES.SET_MAGAZINE_INVITE_SECTION,
  'sectionId',
);
export const setInviteValidationErrors = set(
  MAGAZINE_INVITE_TYPES.SET_INVITE_VALIDATION_ERRORS,
  'validationErrors',
);

export const setInviteAcceptLoading = set(
  MAGAZINE_INVITE_TYPES.INVITE_MAGAZINE_ACCEPT_LOADING,
  'pending',
);
