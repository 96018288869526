import getWindow from 'Utils/get-window';
import sentry from 'Utils/sentry';
import {
  FrictionlessFlowType,
  SIGNUP_TYPES,
  SubscribeType,
} from 'Webapp/enums';

const OUTBOUND_LINK = 'outbound-link';
const INTERNAL_LINK = 'internal-link';
const FEED_TYPE_CUSTOM_DIMENSION = 12;
const USER_CUSTOM_DIMENSION = 13;
const FLAB_CUSTOM_DIMENSION = 14;
const EVENT_DETAILS_CUSTOM_DIMENSION = 15;
const EVENT_VALUE = 1; // "Event Value," used in goals https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters#ev
const APP_VERSION_DIMENSION = 16;
const SEND_TYPES = {
  PAGEVIEW: 'pageview',
  EVENT: 'event',
};
export const ACTION_TYPES = {
  CLICK: 'click',
  INPUT_BLUR: 'input blur',
  TRIGGERED: 'triggered',
  USAGE: 'usage',
  LOGIN: 'login',
  LOGOUT: 'logout',
  SIGNUP: 'signup',
  SUBSCRIBE: 'subscribe',
  ACTIVATED: 'activated',
};
const CATEGORY_TYPES = {
  BUTTON: 'button',
  CURATOR_PRO: 'curator-pro',
  FAVORITES_MENU: 'favorites-menu',
  FLIPBOARD_TV: 'flipboard-tv',
  NAVIGATION_BAR: 'navigation-bar',
  ONBOARDING: 'onboarding',
  SEARCH: 'search',
  VIEW_MODAL: 'view-modal',
  AUTH: 'auth',
  NEWSLETTERS: 'newsletters',
};

const flowSubscribeMap = {
  [FrictionlessFlowType.TOPIC]: SubscribeType.TOPIC_PAGE,
  [FrictionlessFlowType.NEWSLETTER]: SubscribeType.NEWSLETTER_PAGE,
  [FrictionlessFlowType.NEWSLETTERS]: SubscribeType.NEWSLETTERS_PAGE,
};

let userId = undefined;
const commonEventData = {
  dimension13: 0, // default to logged-out until set otherwise
};

// Queue used to delay sending events until we have all the
// necessary app state information (specifically, experiments)
const hitQueue = [];
let trackingEnabled = false;

function emitPageview(_title, pagePath, section = null) {
  if (!getWindow().ga) {
    return;
  }
  const eventData = Object.assign({}, commonEventData);
  // if viewing a section, specify the FeedType custom dimension
  if (section) {
    // if the section has been promoted to NGL, decorate the feed type
    const { feedType, isNglPromoted } = section;
    const decoratedFeedType = isNglPromoted
      ? `${feedType}-nglPromoted`
      : feedType;
    eventData[`dimension${FEED_TYPE_CUSTOM_DIMENSION}`] = decoratedFeedType;
  }
  getWindow().ga('send', SEND_TYPES.PAGEVIEW, pagePath, eventData);
}

/**
 * Convert an object into a string format for supplying to a
 * GA custom dimension.  { a: 'x', b: 'y' } => "a:x,b:y"
 * @param {Object} eventDetails    - An object containing arbitrary data to track
 * @return {String}                - Formatted string for a custom dimension
 */
function eventDetailsValue(eventDetails) {
  return (
    eventDetails &&
    Object.keys(eventDetails).reduce((collect, key, index) => {
      const append = `${key}:${eventDetails[key]}`;
      const separator = index === 0 ? '' : ',';
      return `${collect}${separator}${append}`;
    }, '')
  );
}

/**
 * Send an event to GA
 * @param {String} eventCategory        - GA event category
 * @param {String} eventAction          - GA event action
 * @param {String} eventLabel           - GA event label
 * @param {Object} eventDetails         - Data to include in the "event details" custom
 *                                        dimension
 */
function emitEvent(
  eventCategory,
  eventAction,
  eventLabel,
  eventDetails = null,
) {
  try {
    if (!getWindow().ga) {
      return;
    }
    const eventData = Object.assign({}, commonEventData, {
      event_category: eventCategory,
      event_label: eventLabel,
    });

    // include event details custom dimension if specified
    if (eventDetails !== null && Object.keys(eventDetails).length > 0) {
      eventData[`dimension${EVENT_DETAILS_CUSTOM_DIMENSION}`] =
        eventDetailsValue(eventDetails);
    }

    getWindow().ga(
      'send',
      SEND_TYPES.EVENT,
      eventCategory,
      eventAction,
      eventLabel,
      EVENT_VALUE,
      eventData,
    );
  } catch (error) {
    sentry.captureException(error);
  }
}

/**
 * Submit an event to GA4
 * @param {String} eventCategory        - GA event category
 * @param {String} eventAction          - GA event action
 * @param {String} eventLabel           - GA event label
 * @param {Object} eventDetails         - Data to include in the "event details" custom
 *                                        dimension
 */
function emitGA4Event(
  eventCategory,
  eventAction,
  eventLabel,
  eventDetails = null,
) {
  try {
    if (!getWindow().gtag) {
      return;
    }

    const eventData = {
      category: eventCategory,
      label: eventLabel,
      details: eventDetails,
      userId,
    };

    // include event details custom dimension if specified
    if (eventDetails !== null && Object.keys(eventDetails).length > 0) {
      eventData.details = eventDetailsValue(eventDetails);
    }

    const flab = commonEventData[`dimension${FLAB_CUSTOM_DIMENSION}`];
    if (flab) {
      eventData.flab = flab;
    }

    getWindow().gtag('event', eventAction, eventData);
  } catch (error) {
    sentry.captureException(error);
  }
}

/**
 * Flush the tracking queue
 */
function processQueue() {
  while (hitQueue.length > 0) {
    const hit = hitQueue.shift();

    switch (hit.type) {
      case SEND_TYPES.PAGEVIEW: {
        const { title, pagePath, section } = hit;
        emitPageview(title, pagePath, section);
        break;
      }
      case SEND_TYPES.EVENT: {
        const { eventAction, eventCategory, eventLabel, eventDetails } = hit;
        emitEvent(eventCategory, eventAction, eventLabel, eventDetails);
        emitGA4Event(eventCategory, eventAction, eventLabel, eventDetails);
        break;
      }
    }
  }
}

/**
 * Tracks an event
 * Event tracking conventions:
 * https://supermetrics.com/blog/naming-conventions-google-analytics
 * https://support.google.com/analytics/answer/1033068
 * @param  {String} eventCategory - the name of the group of similar events you want to track.
 * @param  {String} eventAction - Either a recommended or custom event
 * For example: Downloads, Outbound Links or YouTube Videos.
 * Make sure to always use the “plural” form to keep your naming consistent.
 * @param  {String} eventLabel - the name of the web page element, whose users’
 * interaction you want to track.
 * For an embedded YouTube Video this could be the title of the video.
 * @param {Object} eventDetails - Additional metadata to associate with the event.  Passed
 * as a hit-level custom dimension (CD15, "EventDetails").  The value sent to GA is a
 * string representation of the comma-separated keys/values like this:
 * { keyA: 'valueA', keyB: 'valueB' } => "keyA:valueA,keyB:valueB"
 */
function trackEvent(
  eventCategory,
  eventAction,
  eventLabel,
  eventDetails = null,
) {
  // Tracking is enabled, emit immediately
  if (trackingEnabled) {
    emitEvent(eventCategory, eventAction, eventLabel, eventDetails);
    return emitGA4Event(eventCategory, eventAction, eventLabel, eventDetails);
  }

  // Tracking disabled, queue for later processing
  const eventHit = {
    type: SEND_TYPES.EVENT,
    eventAction,
    eventCategory,
    eventLabel,
    eventDetails,
  };
  hitQueue.push(eventHit);
}

export default {
  /**
   * Set the app version custom dimension
   * @param {String} version         - The version of the app
   */
  setAppVersion: (version) => {
    commonEventData[`dimension${APP_VERSION_DIMENSION}`] = version;
  },

  /**
   * Set the referrer for all subsequent events
   * @param {?string} referrer     - The referrer for the page
   */
  setReferrer: (referrer) => {
    if (!getWindow().ga) {
      return;
    }

    getWindow().ga('set', 'referrer', referrer);
  },

  /**
   * Set the FLAB experiments custom dimension
   * @param {String} abTests     - String of FLAB A/B tests
   */
  setAbTests: (abTests) => {
    commonEventData[`dimension${FLAB_CUSTOM_DIMENSION}`] = abTests;
  },

  /**
   * Set the user custom dimension for subsequent events
   * @param {Boolean} isLoggedIn       - Logged-in status
   */
  setIsAuthenticated: (isAuthenticated) => {
    commonEventData[`dimension${USER_CUSTOM_DIMENSION}`] = isAuthenticated
      ? 1
      : 0;
  },

  /**
   * Set the GA4 userId once authenticated
   * @param {?number} uid - The Flipboard uid to be set in GA4.  Omit to reset to undefined.
   */
  setGA4UserId: (uid) => {
    userId = uid || null;

    try {
      if (typeof gtag === 'undefined') {
        return;
      }

      gtag('set', 'user_properties', { user_id: userId, crm_id: userId });
    } catch (error) {
      sentry.captureException(error);
    }
  },

  /**
   * Flushes the tracking queue and enables immediate tracking
   */
  enableTracking: () => {
    trackingEnabled = true;
    processQueue();
  },

  linkType: (isAbsoluteUrl, isExternalUrl) => {
    if (isExternalUrl || isAbsoluteUrl) {
      return OUTBOUND_LINK;
    }
    return INTERNAL_LINK;
  },

  trackClickLink: (linkType, url, target = null, context = null) => {
    const eventDetails = {};

    if (target !== null) {
      eventDetails.target = target;
    }

    if (context !== null) {
      eventDetails.context = context;
    }

    trackEvent(linkType, ACTION_TYPES.CLICK, url, eventDetails);
  },

  trackClickButton: (buttonName, eventDetails) => {
    trackEvent(
      CATEGORY_TYPES.BUTTON,
      ACTION_TYPES.CLICK,
      buttonName,
      eventDetails || null,
    );
  },

  trackViewModal: (modalName) => {
    trackEvent(CATEGORY_TYPES.VIEW_MODAL, ACTION_TYPES.USAGE, modalName);
  },

  trackSearch: (query) => {
    trackEvent(CATEGORY_TYPES.SEARCH, ACTION_TYPES.INPUT_BLUR, query);
  },

  trackNavbarEvent: (action, label) => {
    trackEvent(CATEGORY_TYPES.NAVIGATION_BAR, action, label);
  },

  trackCuratorProEvent: (action, label) => {
    trackEvent(CATEGORY_TYPES.CURATOR_PRO, action, label);
  },

  trackFavoritesMenuEvent: (action, label) => {
    trackEvent(CATEGORY_TYPES.FAVORITES_MENU, action, label);
  },

  trackFlipboardTVEvent: (action, label) => {
    trackEvent(CATEGORY_TYPES.FLIPBOARD_TV, action, label);
  },

  /**
   * Tracks a page view.
   * @param {Boolean} flushQueue   - Defaults to false.  If false, adds the page
   * view to the queue of events.  If true, flushes the queue and then emits
   * the current pageview.
   * @param {String} title         - Title of the page
   * @param {String} pagePath      - The path of the page
   * @param {Object} section       - A Flipboard section object, used to determine
   *                                 feedType (custom dimension 12)
   */
  trackPageview: (title, pagePath, section = null) => {
    // Tracking is enabled, emit immediately
    if (trackingEnabled) {
      return emitPageview(title, pagePath, section);
    }

    // Tracking disabled, queue for later processing
    const pageViewHit = {
      type: SEND_TYPES.PAGEVIEW,
      title,
      pagePath,
      section,
    };
    hitQueue.push(pageViewHit);
  },

  /** Tracks exiting signup in the standard flow.
   * @param {Boolean} success - Whether a user signed up successfully.  Defaults to false.
   * @param {String}  service
   **/
  trackStandardSignupExit: (success = false, service = null) => {
    trackEvent(CATEGORY_TYPES.AUTH, ACTION_TYPES.SIGNUP, service, {
      success,
      type: SIGNUP_TYPES.STANDARD,
    });
  },

  /** Tracks exiting signup in the frictionless flow.
   * @param {Boolean} success - Whether a user signed up successfully.  Defaults to false.
   **/
  trackFrictionlessSignupExit: (success = false) => {
    trackEvent(CATEGORY_TYPES.AUTH, ACTION_TYPES.SIGNUP, 'flipboard', {
      success,
      type: SIGNUP_TYPES.FRICTIONLESS,
    });
  },

  /** Tracks login
   * @param {Boolean} success - Whether the login was successful
   * @param {String} service
   **/
  trackLogin: (success = false, service = null) => {
    trackEvent(CATEGORY_TYPES.AUTH, ACTION_TYPES.LOGIN, service, {
      success,
    });
  },

  /**
   * Tracks activation of a new user.  Triggered after a user completes a smart mag topic
   * picker (or aborts it) when they have first logged in.  "First logged in" is determined
   * by the query param "action=onboard"
   */
  trackFirstLaunchActivated: () => {
    trackEvent(
      CATEGORY_TYPES.ONBOARDING,
      ACTION_TYPES.TRIGGERED,
      'onboarding - first launch activation',
    );

    // special just for GA4... we need a unique event name (what old GA "action" populates)
    // in order to designate as a "conversion event" in GA4.
    trackEvent(
      CATEGORY_TYPES.ONBOARDING,
      ACTION_TYPES.ACTIVATED,
      'onboarding - first launch activation',
    );
  },

  /** Tracks submission of emails to subscribe to newsletters
   * @param {String} flowType - The flow variant (topic page, newsletters page, newletter page)
   **/
  trackFrictionlessSubscribeToNewsletter: (flowType) => {
    trackEvent(
      CATEGORY_TYPES.NEWSLETTERS,
      ACTION_TYPES.SUBSCRIBE,
      flowSubscribeMap[flowType],
    );
  },
};
