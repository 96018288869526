import GlobalVars from 'Utils/global-vars';
import FlavourServer from './server';
import FlavourClient from './client';

/**
 * Wrapper for Flavour axios instances.
 * To avoid any confusion in a isomorphic application, just import
 * this file as your Flavour axios instance, and it should work on servers and clients
 * import Flavour from 'Utils/api/flavour'
 */
const Flavour = GlobalVars.isServer() ? FlavourServer : FlavourClient;
export default Flavour;
