import { topicList } from 'Utils/content/item-util';

import getCurrentSection from 'Utils/seo/shared/get-current-section';

export default ({ section }, getState, { t }) => {
  if (section) {
    const currentSection = getCurrentSection(getState());
    if (currentSection && currentSection.isMagazine) {
      const topics = topicList(currentSection.items).join(', ');
      if (currentSection.description) {
        return t('magazine_seo_description', {
          description: currentSection.description,
          topics,
        });
      }
      const followCount =
        (currentSection.metrics && currentSection.metrics.subscribersCount) ||
        0;
      return t('magazine_seo_description_generic', {
        topics,
        profileName: currentSection.authorDisplayName,
        magazineName: currentSection.title,
        followCount,
      });
    }
  }
};
