import ToastTemplateDefault from './default';
import ToastTemplateFlipped from './flipped';

export enum TOAST_TEMPLATES {
  FLIPPED = 'FLIPPED',
}
const toastTemplates = {
  [TOAST_TEMPLATES.FLIPPED]: ToastTemplateFlipped,
};

const getTemplateForProps = (props) =>
  toastTemplates[props.template] || ToastTemplateDefault;

export default getTemplateForProps;
