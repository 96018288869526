import FlapUtil from 'Utils/content/flap-util';
import logger from 'Utils/logger';
import getWindow from 'Utils/get-window';
import { toastShowErrorAction, toastShowInfoAction } from '../toast-actions';
import { CURATOR_PRO_MAGAZINE_TYPES } from 'Webapp/action-types';
import { TOAST_TEMPLATES } from '../../../components/toast-templates';
import updateFeed from 'Utils/api/flap/endpoints/update-feed';
import { currentUserUid } from 'Webapp/shared/app/redux/selectors/auth';
import { queueLoadMissingSocialActivity } from 'Webapp/shared/concepts/social-activity';

const { URLSearchParams } = getWindow();

const DEFAULT_UPDATE_FEED_PARAMS = {
  limit: 30,
  needsReload: true,
};

function processSectionStream(stream) {
  const items = FlapUtil.sectionRawItemsFromStream(stream);
  return {
    section: FlapUtil.getSectionFromStream(stream),
    items,
    nextPageKey: FlapUtil.nextPageKeyFromStream(stream),
    neverLoadMore: FlapUtil.neverLoadMore(stream),
  };
}

export const getMagazine =
  (remoteId) =>
  async (dispatch, getState, { flap, publisher }) => {
    dispatch({ type: CURATOR_PRO_MAGAZINE_TYPES.GET_CURATOR_PRO_MAGAZINE });
    try {
      const params = Object.assign({}, DEFAULT_UPDATE_FEED_PARAMS, {
        sections: remoteId,
      });

      const { data } = await updateFeed(
        flap,
        currentUserUid(getState()),
        params,
      );
      const contributorResponse = await flap.get(
        '/curator/magazineContributors',
        {
          params: { sectionid: remoteId },
        },
      );
      const { section, items, nextPageKey, neverLoadMore } =
        processSectionStream(data.stream);

      const publisherMagId = decodeURIComponent(
        decodeURIComponent(section.magazineTarget),
      ).replace('flipboard/mag-', '');
      const publisherResponse = await publisher.get('/v1/pipe_from_mag', {
        params: {
          mag_id: publisherMagId,
        },
      });

      dispatch(queueLoadMissingSocialActivity());

      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.GET_CURATOR_PRO_MAGAZINE_SUCCESS,
        section,
        items,
        pipes: publisherResponse.data && publisherResponse.data.pipes,
        nextPageKey,
        neverLoadMore,
        contributors: contributorResponse.data.contributors || [],
      });
      return data;
    } catch (error) {
      logger.error(error);
      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.GET_CURATOR_PRO_MAGAZINE_FAILED,
      });
    }
  };

export const loadNextItemsPage =
  (remoteId, pageKey) =>
  async (dispatch, getState, { flap }) => {
    dispatch({
      type: CURATOR_PRO_MAGAZINE_TYPES.GET_CURATOR_PRO_MAGAZINE_NEXT_ITEMS,
    });
    try {
      const params = Object.assign({}, DEFAULT_UPDATE_FEED_PARAMS, {
        sections: remoteId,
        pageKey,
      });

      const { data } = await updateFeed(
        flap,
        currentUserUid(getState()),
        params,
      );

      const { section, items, nextPageKey, neverLoadMore } =
        processSectionStream(data.stream);

      dispatch(queueLoadMissingSocialActivity());

      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.GET_CURATOR_PRO_MAGAZINE_NEXT_ITEMS_SUCCESS,
        section,
        items,
        nextPageKey,
        neverLoadMore,
      });
      return data;
    } catch (error) {
      logger.error(error);
      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.GET_CURATOR_PRO_MAGAZINE_NEXT_ITEMS_FAILED,
      });
    }
  };

export const updateMagazine =
  (target, changes) =>
  async (dispatch, _, { flap, t }) => {
    dispatch({ type: CURATOR_PRO_MAGAZINE_TYPES.UPDATE_CURATOR_PRO_MAGAZINE });
    try {
      const response = await flap.post(`/curator/editMagazine`, {
        target,
        key: Object.keys(changes)[0],
        value: Object.values(changes)[0],
      });
      if (!response.data.success) {
        return dispatch({
          type: CURATOR_PRO_MAGAZINE_TYPES.UPDATE_CURATOR_PRO_MAGAZINE_FAILED,
        });
      }
      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.UPDATE_CURATOR_PRO_MAGAZINE_SUCCESS,
        changes,
      });
    } catch (error) {
      logger.error(error);
      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.UPDATE_CURATOR_PRO_MAGAZINE_FAILED,
      });
      dispatch(toastShowErrorAction(t('fallback_error_message')));
    }
  };

export const deleteMagazine =
  (target) =>
  async (dispatch, _, { flap, t }) => {
    dispatch({ type: CURATOR_PRO_MAGAZINE_TYPES.DELETE_CURATOR_PRO_MAGAZINE });
    try {
      const response = await flap.post(`/curator/destroyMagazine`, {
        target,
      });
      if (!response.data.success) {
        return dispatch({
          type: CURATOR_PRO_MAGAZINE_TYPES.DELETE_CURATOR_PRO_MAGAZINE_FAILED,
        });
      }
      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.DELETE_CURATOR_PRO_MAGAZINE_SUCCESS,
        magazineTarget: target,
      });
      dispatch(toastShowInfoAction(t('magazine_delete_success')));
    } catch (error) {
      logger.error(error);
      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.DELETE_CURATOR_PRO_MAGAZINE_FAILED,
      });
      dispatch(toastShowErrorAction(t('magazine_delete_failure')));
    }
  };

export const updateMagazineCover =
  (target, itemId, imageUrl) =>
  async (dispatch, _, { flap, t }) => {
    dispatch({
      type: CURATOR_PRO_MAGAZINE_TYPES.UPDATE_CURATOR_PRO_MAGAZINE_COVER,
    });
    try {
      const params = new URLSearchParams();
      params.append('target', target);
      params.append('key', 'imageURL');
      params.append('value', imageUrl);
      if (imageUrl) {
        params.append('key', 'coverItemId');
        params.append('value', itemId);
      }
      const response = await flap.post(
        `/curator/editMagazine`,
        {},
        {
          params,
        },
      );
      if (!response.data.success) {
        return dispatch({
          type: CURATOR_PRO_MAGAZINE_TYPES.UPDATE_CURATOR_PRO_MAGAZINE_COVER_FAILED,
        });
      }
      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.UPDATE_CURATOR_PRO_MAGAZINE_COVER_SUCCESS,
        itemId,
      });
      dispatch(toastShowInfoAction(t('update_magazine_cover_success')));
    } catch (error) {
      logger.error(error);
      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.UPDATE_CURATOR_PRO_MAGAZINE_COVER_FAILED,
      });
      dispatch(toastShowErrorAction(t('fallback_error_message')));
    }
  };

export const removeContributor =
  (target, contributorId) =>
  async (dispatch, _, { flap, t }) => {
    dispatch({
      type: CURATOR_PRO_MAGAZINE_TYPES.REMOVE_CURATOR_PRO_MAGAZINE_CONTRIBUTOR,
    });
    try {
      const response = await flap.post('/curator/removeContributor', {
        target,
        contributorid: contributorId,
      });
      if (!response.data.success) {
        return dispatch({
          type: CURATOR_PRO_MAGAZINE_TYPES.REMOVE_CURATOR_PRO_MAGAZINE_CONTRIBUTOR_FAILED,
        });
      }
      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.REMOVE_CURATOR_PRO_MAGAZINE_CONTRIBUTOR_SUCCESS,
        contributorId,
      });
    } catch (error) {
      logger.error(error);
      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.REMOVE_CURATOR_PRO_MAGAZINE_CONTRIBUTOR_FAILED,
      });
      dispatch(toastShowErrorAction(t('fallback_error_message')));
    }
  };

export const flipItem =
  (magazine, url) =>
  async (dispatch, getState, { flap, t }) => {
    dispatch({
      type: CURATOR_PRO_MAGAZINE_TYPES.FLIP_CURATOR_PRO_MAGAZINE_URL,
    });
    try {
      const body = {
        url,
        target: magazine.magazineTarget,
        service: 'flipboard',
      };
      const { data } = await flap.post('/social/shareWithComment', body);
      if (!data.success) {
        return dispatch({
          type: CURATOR_PRO_MAGAZINE_TYPES.FLIP_CURATOR_PRO_MAGAZINE_URL_FAILED,
        });
      }

      const params = Object.assign({}, DEFAULT_UPDATE_FEED_PARAMS, {
        sections: magazine.remoteid,
      });

      const updateFeedResponse = await updateFeed(
        flap,
        currentUserUid(getState()),
        params,
      );
      const { section, items, nextPageKey, neverLoadMore } =
        processSectionStream(updateFeedResponse.data.stream);

      const addedItem = items.find((item) => item.sourceURL === url);

      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.FLIP_CURATOR_PRO_MAGAZINE_URL_SUCCESS,
        section,
        items,
        nextPageKey,
        neverLoadMore,
      });

      dispatch(
        toastShowInfoAction({
          template: TOAST_TEMPLATES.FLIPPED,
          magazine,
        }),
      );
      return addedItem;
    } catch (error) {
      logger.error(error);
      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.FLIP_CURATOR_PRO_MAGAZINE_URL_FAILED,
      });
      dispatch(toastShowErrorAction(t('fallback_error_message')));
    }
  };

export const moveItem =
  (magazineTarget, moveId, position) =>
  async (dispatch, _, { flap, t }) => {
    dispatch({
      type: CURATOR_PRO_MAGAZINE_TYPES.MOVE_CURATOR_PRO_MAGAZINE_ITEM,
      moveId,
      position,
    });

    try {
      const body = {
        target: magazineTarget,
        moveId,
        position,
      };

      const response = await flap.post('/curator/moveItemToPosition', body);
      if (!response.data.success) {
        return dispatch({
          type: CURATOR_PRO_MAGAZINE_TYPES.MOVE_CURATOR_PRO_MAGAZINE_ITEM_FAILED,
        });
      }
      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.MOVE_CURATOR_PRO_MAGAZINE_ITEM_SUCCESS,
        moveId,
        position,
      });
    } catch (error) {
      logger.error(error);

      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.MOVE_CURATOR_PRO_MAGAZINE_ITEM_FAILED,
      });
      dispatch(toastShowErrorAction(t('fallback_error_message')));
    }
  };

export const customizeItem =
  (magazineTarget, item, customizations) =>
  async (dispatch, getState, { flap, t }) => {
    dispatch({
      type: CURATOR_PRO_MAGAZINE_TYPES.CUSTOMIZE_CURATOR_PRO_MAGAZINE_ITEM,
    });
    try {
      const body = {
        target: magazineTarget,
        oid: item.oid,
        customizations: JSON.stringify(customizations),
      };

      const response = await flap.post('/curator/customizeArticle', body);
      if (!response.data.success) {
        return dispatch({
          type: CURATOR_PRO_MAGAZINE_TYPES.CUSTOMIZE_CURATOR_PRO_MAGAZINE_ITEM_FAILED,
        });
      }

      const params = Object.assign({}, DEFAULT_UPDATE_FEED_PARAMS, {
        sections: FlapUtil.getMagazineItemRemoteid(item),
        limit: 1,
      });
      const updateFeedResponse = await updateFeed(
        flap,
        currentUserUid(getState()),
        params,
      );

      const { items } = processSectionStream(updateFeedResponse.data.stream);
      const updatedItem = items.find(
        (i) => i.remoteServiceItemID === item.remoteServiceItemID,
      );

      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.CUSTOMIZE_CURATOR_PRO_MAGAZINE_ITEM_SUCCESS,
        item: updatedItem,
      });
    } catch (error) {
      logger.error(error);
      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.CUSTOMIZE_CURATOR_PRO_MAGAZINE_ITEM_FAILED,
      });
      dispatch(toastShowErrorAction(t('fallback_error_message')));
    }
  };

export const deleteItem =
  (magazineTarget, item) =>
  async (dispatch, _, { flap, t }) => {
    dispatch({
      type: CURATOR_PRO_MAGAZINE_TYPES.REMOVE_CURATOR_PRO_MAGAZINE_ITEM,
    });
    try {
      const body = {
        target: magazineTarget,
        oid: item.anchorId,
      };
      if (item.isStatus) {
        body.type = 'status';
      } else {
        body.service = 'flipboard';
      }
      const response = await flap.post('/social/destroy', body);
      if (!response.data.success) {
        return dispatch({
          type: CURATOR_PRO_MAGAZINE_TYPES.REMOVE_CURATOR_PRO_MAGAZINE_ITEM_FAILED,
        });
      }
      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.REMOVE_CURATOR_PRO_MAGAZINE_ITEM_SUCCESS,
        oid: item.id,
      });
      dispatch(toastShowInfoAction('Item was removed'));
    } catch (error) {
      logger.error(error);
      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.REMOVE_CURATOR_PRO_MAGAZINE_ITEM_FAILED,
      });
      dispatch(toastShowErrorAction(t('fallback_error_message')));
    }
  };

export const addPipe =
  (target, source) =>
  async (dispatch, _, { publisher, t }) => {
    dispatch({
      type: CURATOR_PRO_MAGAZINE_TYPES.ADD_CURATOR_PRO_MAGAZINE_PIPE,
    });
    const params = { target, source };
    try {
      const { data } = await publisher.post(
        '/int/pipes/create',
        {},
        {
          params,
        },
      );
      if (!data.success) {
        dispatch({
          type: CURATOR_PRO_MAGAZINE_TYPES.ADD_CURATOR_PRO_MAGAZINE_PIPE_FAILED,
        });
        return null;
      }
      const pipe = data.item && data.item[0];
      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.ADD_CURATOR_PRO_MAGAZINE_PIPE_SUCCESS,
        pipe,
      });
      dispatch(
        toastShowInfoAction(t('curator_pro_magazine_added_rss')), // TODO
      );
      return pipe;
    } catch (error) {
      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.ADD_CURATOR_PRO_MAGAZINE_PIPE_FAILED,
      });
    }
  };

export const deletePipe =
  (pipeId, uid) =>
  async (dispatch, _, { publisher, t }) => {
    dispatch({
      type: CURATOR_PRO_MAGAZINE_TYPES.DELETE_CURATOR_PRO_MAGAZINE_PIPE,
    });
    try {
      const params = { uid };
      const response = await publisher.delete(`/int/pipe/${pipeId}`, {
        params,
      });
      if (!response.data.success) {
        return dispatch({
          type: CURATOR_PRO_MAGAZINE_TYPES.DELETE_CURATOR_PRO_MAGAZINE_PIPE_FAILED,
        });
      }
      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.DELETE_CURATOR_PRO_MAGAZINE_PIPE_SUCCESS,
        pipeId,
      });
      dispatch(toastShowInfoAction(t('curator_pro_magazine_removed_rss')));
      return response.data.success;
    } catch (error) {
      logger.error(error);
      dispatch({
        type: CURATOR_PRO_MAGAZINE_TYPES.DELETE_CURATOR_PRO_MAGAZINE_PIPE_FAILED,
      });
      dispatch(toastShowErrorAction(t('fallback_error_message')));
    }
  };
