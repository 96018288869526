import React, { Component } from 'react';
import styled from '@emotion/styled';
import { componentSelector } from 'Style/type-helpers';
import { SPACING } from 'Style/spacing';
import { UI_TEXT_TYPES } from 'Style/typography';

// Utils
import {
  USAGE_NAV_FROMS,
  USAGE_DISPLAY_STYLES,
  USAGE_MOBILE_GATE_EXIT_METHODS,
  USAGE_MOBILE_GATE_TYPES,
} from 'Utils/analytics/usage.js';

// Components
import ModalControls from 'Webapp/shared/app/components/modal-controls';
import { MODAL_THEMES } from 'ComponentLibrary/modals/modal.js';
import { StyleVariations } from 'Webapp/shared/app/components/button';
import GetAppButton from 'Webapp/shared/app/containers/get-app-button.js';
import SignupButton from 'Webapp/shared/app/containers/signup-button.js';
import ResendVerificationEmailButton from 'Webapp/shared/app/components/resend-verification-email-button.js';
import FrictionlessForm, {
  FrictionlessSubmit,
} from 'Webapp/shared/app/components/frictionless-form';
import ModalWrapper from './modal-wrapper.js';
import { NavFromContext } from 'ComponentLibrary/context/index.js';
import {
  FrictionlessFlowType,
  SocialActionType,
  OnboardingFlow,
} from 'Webapp/enums';

import withT from 'ComponentLibrary/hocs/withT.js';

import connector from 'Utils/connector';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectCurrentUser from 'Webapp/shared/app/connectors/connectCurrentUser';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';
import connectFeatureFlags, {
  ConnectFeatureFlagsProps,
} from 'Webapp/shared/app/connectors/connectFeatureFlags';

const StyledModal = styled(ModalWrapper)({ padding: SPACING.LARGE });

const ContentGateTitle = styled.h1(
  { marginBottom: SPACING.LARGE },
  UI_TEXT_TYPES.TITLE,
);
const ContentGateDescription = styled.p(UI_TEXT_TYPES.BODY);

const StyledFrictionlessForm = styled(FrictionlessForm)({
  padding: 0,
  width: '100%',
  [componentSelector(FrictionlessSubmit)]: {
    marginTop: SPACING.BASE6X,
  },
});

function renderModalContent({
  actionType,
  t,
  currentUserVerified,
  isAuthenticated,
}) {
  let header = '',
    subHeader = '';

  switch (actionType) {
    case SocialActionType.COMMENTS: {
      header = t('join_the_discussion');
      subHeader = t('signup_to_share_your_ideas');
      break;
    }
    case SocialActionType.FOLLOW: {
      header = t('follow_on_flipboard');
      subHeader = t('sign_up_to_follow');
      break;
    }
    case SocialActionType.FOLLOW_TOPIC: {
      header = t('follow_topic_on_flipboard');
      subHeader = t('sign_up_to_follow_topic');
      break;
    }
    case SocialActionType.ACCEPT_INVITE: {
      header = t('start_contributing_into_this_magazine');
      subHeader = t('sign_up_to_accept_invitation');
      break;
    }
    case SocialActionType.LIKE: {
      header = t('like_to_personalize');
      subHeader = t('sign_up_to_like');
      break;
    }
    case SocialActionType.FAVORITE: {
      header = t('add_to_favorites');
      subHeader = t('sign_up_to_favorite');
      break;
    }
    case SocialActionType.FLIP: {
      header = t('flip_to_save_and_share');
      subHeader =
        isAuthenticated && !currentUserVerified
          ? t('verify_to_flip')
          : t('sign_up_to_flip');
      break;
    }
    case SocialActionType.PERSONALIZE: {
      header = t('tune_your_experience');
      subHeader = t('sign_up_to_personalize');
      break;
    }
    default: {
      header = t('want_more_stories');
      subHeader = t('sign_up_to_see_more');
    }
  }
  return [
    <ContentGateTitle key={0}>{header}</ContentGateTitle>,
    <ContentGateDescription key={1}>{subHeader}</ContentGateDescription>,
  ];
}

type SocialActionGateModalProps = ConnectFeatureFlagsProps &
  ConnectModalProps & {
    actionType: SocialActionType;
    usageTrackSocialGateOverlayDisplayed: (type: string) => void;
    usageTrackSocialGateOverlayExit: (type: string, method: string) => void;
    isPhone: boolean;
    t: Flipboard.TFunction;
    isAuthenticated: boolean;
    currentUserVerified: boolean;
    dismissModal: () => void;
    mobileGateOverride: boolean;
    onboardingFlow: OnboardingFlow;
    continueFlow: boolean;
    onComplete: () => void;
  };

class SocialActionGateModal extends Component<SocialActionGateModalProps> {
  static defaultProps = {
    mobileGateOverride: false,
    continueFlow: false,
  };

  componentDidMount() {
    const { usageTrackSocialGateOverlayDisplayed } = this.props;
    usageTrackSocialGateOverlayDisplayed(this.socialGateUsageType());
  }

  componentWillUnmount() {
    const { usageTrackSocialGateOverlayExit } = this.props;
    usageTrackSocialGateOverlayExit(
      this.socialGateUsageType(),
      USAGE_MOBILE_GATE_EXIT_METHODS.DISMISS,
    );
  }

  socialGateUsageType = () => {
    const { featureFlags, actionType } = this.props;
    const USAGE_KEY =
      USAGE_MOBILE_GATE_TYPES[`FRICTIONLESS_${actionType}_SOCIAL_GATE_OVERLAY`];
    if (featureFlags.FRICTIONLESS_SIGNUP_ONLY && USAGE_KEY) {
      return USAGE_KEY;
    }
    return 'social_gate_overlay';
  };

  modalControls = () => {
    const {
      isAuthenticated,
      currentUserVerified,
      isPhone,
      usageTrackSocialGateOverlayExit,
      mobileGateOverride,
      actionType,
      featureFlags,
      onboardingFlow,
      continueFlow,
      onComplete,
      t,
    } = this.props;

    if (featureFlags.FRICTIONLESS_SIGNUP_ONLY && !isAuthenticated) {
      return (
        <StyledFrictionlessForm
          frictionlessType={FrictionlessFlowType.ACTION_GATE}
          showDisclaimer
          socialGateActionType={actionType}
          showLogin
        />
      );
    }

    if (isPhone && !mobileGateOverride) {
      return (
        <GetAppButton
          name="social-action-gate-signup"
          onClick={() =>
            usageTrackSocialGateOverlayExit(
              this.socialGateUsageType(),
              USAGE_MOBILE_GATE_EXIT_METHODS.OPEN_IN_APP,
            )
          }
        />
      );
    }

    if (isAuthenticated && !currentUserVerified) {
      return (
        <ResendVerificationEmailButton
          name="verify-account-cta-resend-email"
          styleVariation={StyleVariations.PRIMARY}
          onResendEmail={this.props.dismissModal}
        />
      );
    }
    return (
      <SignupButton
        name="social-action-gate-signup"
        label={t('continue')}
        styleVariation={StyleVariations.PRIMARY}
        displayStyle={USAGE_DISPLAY_STYLES.SOCIAL_ACTION_GATE_CTA}
        overrideOnboardingFlow={onboardingFlow}
        continueFlow={continueFlow}
        onClick={onComplete}
      />
    );
  };

  render() {
    return (
      <NavFromContext.Provider value={USAGE_NAV_FROMS.MOBILE_GATE_OVERLAY}>
        <StyledModal name="social-action-gate" modalTheme={MODAL_THEMES.WINDOW}>
          <section className="content-gate modal__content">
            {renderModalContent(this.props)}
          </section>
          <ModalControls
            modalName="social-gate-modal"
            closeButtonSize={18}
            showCloseButton
            onDismiss={this.props.dismissModal}
          >
            {this.modalControls()}
          </ModalControls>
        </StyledModal>
      </NavFromContext.Provider>
    );
  }
}

export default connector(
  connectAuthentication,
  connectCurrentUser,
  connectFeatureFlags,
  connectResponsive,
  connectModal,
)(withT(SocialActionGateModal));
