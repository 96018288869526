import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

// Utils
import HocUtil from 'Utils/hoc-util';
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';
import FlapUtil from 'Utils/content/flap-util';
import { normalizeToArray } from 'Utils/normalize-to-array';

// Actions
import {
  followSections,
  unfollowSection,
} from 'Webapp/shared/app/redux/actions/profile-actions';
import { usageTrackTapFollowSection } from 'Webapp/shared/app/redux/actions/usage-actions';

// Components
import { SocialActionType } from 'Webapp/enums';
import withNavFrom from '../hocs/withNavFrom';
import withSocialActionGate from '../hocs/withSocialActionGate';

import connector from 'Utils/connector';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectUsageSetNavFrom from 'Webapp/shared/app/connectors/connectUsageSetNavFrom';

function withFollow(Component) {
  class WrappedComponent extends React.Component {
    constructor(props) {
      super(props);

      this.isFollowed = this.isFollowed.bind(this);
      this.handleFollow = this.handleFollow.bind(this);
      this.handleUnfollow = this.handleUnfollow.bind(this);
      this.filterUnfollowed = this.filterUnfollowed.bind(this);
    }

    isFollowed(followSection = null) {
      const { followingIds } = this.props;
      const section = followSection || this.props.section;
      if (!section) {
        return false;
      }

      /**
       * NOTE that SOMETIMES, a profile section has a remoteid in the format:
       * `flipboard/user%2F11111111` but is followed as `auth/flipboard/user%2F11111111`
       * and conversely, sometimes the topic is returning with a remoteid as 'auth/...' but is followed
       * without.
       */

      const remoteid = section?.normalizedRemoteid;
      const rootTopicRemoteId = FlapUtil.normalizeRemoteid(
        section?.rootTopic?.remoteid,
      );
      const remoteidPlain = FlapUtil.normalizeRemoteid(
        section?.ssid?.remoteidPlain,
      );
      const sectionId = FlapUtil.normalizeRemoteid(section?.sectionID);

      return followingIds.some((id) => {
        const normalizedId = FlapUtil.normalizeRemoteid(id);
        return (
          normalizedId === remoteid ||
          normalizedId === rootTopicRemoteId ||
          normalizedId === remoteidPlain ||
          normalizedId === sectionId
        );
      });
    }

    async handleFollow(followProps) {
      const {
        isAuthenticated,
        navFrom,
        showSocialActionGate,
        usageSetNavFrom,
        usageTrackTapFollowSection,
        addPostOnboardingSocialAction,
        followSections,
      } = this.props;
      const { callback } = followProps;
      const sections = followProps.sections || this.props.section;
      if (!sections) {
        return;
      }
      if (isAuthenticated) {
        usageSetNavFrom(USAGE_EVENT_NAMES.TAP_SECTION_SUBSCRIBE, navFrom);
        normalizeToArray(sections).forEach((s) =>
          usageTrackTapFollowSection(s),
        );
        await followSections(sections, navFrom);
        callback && callback();
      } else {
        addPostOnboardingSocialAction({
          follow: { sections, navFrom },
        });
        showSocialActionGate(
          this.props.section?.isTopic
            ? SocialActionType.FOLLOW_TOPIC
            : SocialActionType.FOLLOW,
        );
      }
    }

    handleUnfollow(_, followSection = null) {
      const { navFrom, usageSetNavFrom, usageTrackTapFollowSection } =
        this.props;
      const section = followSection || this.props.section;
      if (!section) {
        return;
      }
      usageSetNavFrom(USAGE_EVENT_NAMES.TAP_SECTION_SUBSCRIBE, navFrom);
      usageTrackTapFollowSection(section);
      this.props.unfollowSection(section, navFrom);
    }

    filterUnfollowed(sections) {
      const { followingIds, isAuthenticated } = this.props;
      if (!isAuthenticated || !sections || !followingIds) {
        return [];
      }
      return sections.filter((s) => !followingIds.includes(s.remoteid));
    }

    render() {
      return (
        <Component
          {...this.props}
          isFollowing={this.isFollowed()}
          isFollowingSection={this.isFollowed}
          handleFollow={this.handleFollow}
          handleUnfollow={this.handleUnfollow}
          filterUnfollowed={this.filterUnfollowed}
        />
      );
    }
  }

  // Proxy the wrapped component displayName
  WrappedComponent.displayName = HocUtil.displayName(Component);

  WrappedComponent.propTypes = {
    section: PropTypes.object,
    isAuthenticated: PropTypes.bool.isRequired,
    followingIds: PropTypes.array.isRequired,
    followSections: PropTypes.func.isRequired,
    unfollowSection: PropTypes.func.isRequired,
    navFrom: PropTypes.string,
    showSocialActionGate: PropTypes.func.isRequired,
    usageSetNavFrom: PropTypes.func.isRequired,
    usageTrackTapFollowSection: PropTypes.func.isRequired,
    addPostOnboardingSocialAction: PropTypes.func.isRequired,
  };

  WrappedComponent.defaultProps = {
    navFrom: null,
  };

  const mapStateToProps = ({ profile }) => {
    const { followingIds, followingIdsLoaded } = profile;
    return {
      followingIds,
      followingIdsLoaded,
    };
  };

  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        followSections,
        unfollowSection,
        usageTrackTapFollowSection,
      },
      dispatch,
    );

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    connector(
      connectAuthentication,
      connectUsageSetNavFrom,
    )(withNavFrom(withSocialActionGate(WrappedComponent))),
  );
}

export default withFollow;
