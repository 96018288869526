import { Connector } from 'Webapp/utils/connector';

import {
  viewportType,
  isPhone,
  isSmallScreen,
  isTablet,
  isTabletNarrow,
  isTabletWide,
  isDesktop,
  isLargeScreen,
  isWideLargeScreen,
} from 'Webapp/shared/app/redux/selectors/viewport.js';

const selectors = {
  viewportType,
  isPhone,
  isSmallScreen,
  isTablet,
  isTabletNarrow,
  isTabletWide,
  isDesktop,
  isLargeScreen,
  isWideLargeScreen,
};

export type ConnectResponsiveProps = Flipboard.ConnectorProps<typeof selectors>;

const connectResponsive: Connector = {
  selectors,
};

export default connectResponsive;
