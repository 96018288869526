import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import withT from 'ComponentLibrary/hocs/withT';

function Loading({ className, isView, t }) {
  const classes = classNames(className, 'loading', {
    'loading--view': isView,
  });
  return (
    <div className={classes}>
      <div>{t('loading')}</div>
    </div>
  );
}

Loading.propTypes = {
  isView: PropTypes.bool,
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
};

Loading.defaultProps = {
  isView: false,
};

export default withT(Loading);
