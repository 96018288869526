import codeSplitLoad from 'Webapp/shared/utils/code-split-load.js';

export enum RouteTypes {
  HOME = 'HOME',
  SECTION = 'SECTION',
  ARTICLE = 'ARTICLE',
  SEARCH = 'SEARCH',
  PROFILE = 'PROFILE',
  NEWSLETTERS = 'NEWSLETTERS',
  INVITE = 'INVITE',
  AUTH = 'AUTH',
  UNSPECIFIED = 'UNSPECIFIED',
}

const sectionRoute = {
  component: () => codeSplitLoad('SectionRouteContainer'),
  type: RouteTypes.SECTION,
  preload: 'section',
};

const articleRoute = {
  component: () => codeSplitLoad('ArticleRouteContainer'),
  type: RouteTypes.ARTICLE,
  preload: 'article',
};

export const ErrorRoute = {
  path: '*',
  component: () => codeSplitLoad('ErrorView'),
  exact: false,
  private: false,
  preload: undefined,
  type: RouteTypes.UNSPECIFIED,
};

const routes = [
  {
    path: '/',
    component: () => codeSplitLoad('HomeRouteContainer'),
    type: RouteTypes.HOME,
    preload: 'home',
    exact: true,
  },
  {
    path: '/sitemap/:type/:filter/:page',
    component: () => codeSplitLoad('SitemapPage'),
    preload: 'sitemap-page',
  },
  {
    path: '/sitemap/:type/:filter',
    component: () => codeSplitLoad('SitemapPage'),
    preload: 'sitemap-page',
  },
  {
    path: '/sitemap/:type',
    component: () => codeSplitLoad('SitemapPage'),
    preload: 'sitemap-page',
  },
  {
    path: '/sitemap',
    component: () => codeSplitLoad('SitemapIndex'),
    preload: 'sitemap-index',
    exact: true,
  },
  {
    path: '/account/emailSettings',
    component: () => codeSplitLoad('EmailSettingsView'),
    exact: true,
  },
  {
    path: '/newsletters',
    component: () => codeSplitLoad('NewslettersRouteContainer'),
    type: RouteTypes.NEWSLETTERS,
    preload: 'newsletters',
    exact: true,
  },
  {
    path: '/newsletters/:key',
    component: () => codeSplitLoad('NewslettersLandingRouteContainer'),
    type: RouteTypes.NEWSLETTERS,
    preload: 'newsletters-landing',
    exact: true,
  },
  {
    path: '/profile/storyboards',
    ...sectionRoute,
    exact: true,
  },
  {
    path: '/@:username/:slug/widget',
    component: () => codeSplitLoad('WidgetRouteContainer'),
    preload: 'section',
    exact: true,
  },
  {
    path: '/@:username/storyboards',
    ...sectionRoute,
    exact: true,
  },
  {
    path: '/@:username/storyboards/:storyboardId/edit',
    component: () => codeSplitLoad('CuratorProStoryboardRouteContainer'),
    private: true,
  },
  {
    path: '/@:username/magazines/:magazineId/edit',
    component: () => codeSplitLoad('CuratorProMagazineRouteContainer'),
    private: true,
  },
  {
    path: '/@:username/storyboards/:storyboardId/analytics',
    component: () => codeSplitLoad('StoryboardAnalyticsRouteContainer'),
    exact: true,
    private: true,
  },
  {
    path: '/@:username/magazines/:magazineId/analytics',
    component: () => codeSplitLoad('MagazineAnalyticsRouteContainer'),
    exact: true,
  },
  {
    path: '/@:username/campaigns/:campaignId/edit',
    component: () => codeSplitLoad('CuratorProCampaignRouteContainer'),
    private: true,
  },

  // NOTE: If updating Article routes, also update in flipboard-urls
  {
    path: '/article/:articleTitle/:sourceURLKey',
    ...articleRoute,
  },
  {
    path: '/article/:sourceURL',
    ...articleRoute,
  },
  {
    path: '/section/:slug/:articleTitle/:sourceURLKey',
    ...articleRoute,
  },
  {
    path: '/service/:service/:articleTitle/:sourceURLKey',
    ...articleRoute,
  },
  {
    path: '/@:username/:slug/:articleTitle/:sourceURLKey',
    ...articleRoute,
  },
  {
    path: '/@:username/:articleTitle/:sourceURLKey',
    ...articleRoute,
  },
  {
    path: '/@:username/:slug',
    ...sectionRoute,
  },
  {
    path: '/@:username',
    ...sectionRoute,
  },
  {
    path: '/section/sid/:sid',
    ...sectionRoute,
    sectionSlugRoute: true,
  },
  {
    path: '/section/:slug',
    ...sectionRoute,
    sectionSlugRoute: true,
  },
  {
    path: '/section',
    ...sectionRoute,
  },
  {
    path: '/profile',
    component: () => codeSplitLoad('ProfileRouteContainer'),
    preload: 'profile',
    private: true,
  },
  {
    path: '/analytics',
    component: () => codeSplitLoad('HomeAnalyticsRouteContainer'),
    private: true,
  },
  {
    path: '/settings',
    component: () => codeSplitLoad('SettingsRouteContainer'),
    preload: 'settings',
    private: true,
  },
  {
    path: '/following',
    component: () => codeSplitLoad('FollowingRouteContainer'),
    preload: 'following',
    private: true,
  },
  {
    path: '/search/:q',
    component: () => codeSplitLoad('SearchRouteContainer'),
    type: RouteTypes.SEARCH,
    preload: 'search',
  },
  {
    path: '/video-player/:source',
    component: () => codeSplitLoad('EmbeddableHtmlVideoPlayerView'),
  },
  {
    path: '/embed-tool',
    component: () => codeSplitLoad('EmbedToolView'),
    exact: true,
  },
  {
    path: '/content_unavailable',
    component: () => codeSplitLoad('ErrorView'),
    exact: true,
  },
  {
    // handle video canonical urls like
    // /video/some-title/f75cfbb27e/10d24d7572 we have to handle at
    // this route and not redirect for extractions sake
    path: '/video/*',
    ...articleRoute,
  },
  {
    path: '/:shortcut/:shortcutId/:articleTitle/:sourceURLKey',
    ...articleRoute,
  },
  {
    path: '/:shortcut/:shortcutId',
    ...sectionRoute,
  },
  // auth
  {
    path: '/signup',
    type: RouteTypes.AUTH,
    component: () => codeSplitLoad('SignupRouteContainer'),
    preload: 'signup',
    exact: true,
  },
  {
    path: ['/publisher_signup', '/publisher-signup'],
    type: RouteTypes.AUTH,
    component: () => codeSplitLoad('PublisherSignupRouteContainer'),
    exact: true,
  },
  {
    path: '/login',
    type: RouteTypes.AUTH,
    component: () => codeSplitLoad('LoginRouteContainer'),
    preload: 'login',
    exact: true,
  },
  {
    path: '/logout',
    component: () => codeSplitLoad('Logout'),
    exact: true,
  },
  {
    path: '/forgot-username',
    type: RouteTypes.AUTH,
    component: () => codeSplitLoad('ForgotUsernameContainer'),
    exact: true,
  },
  {
    path: '/forgot-password',
    type: RouteTypes.AUTH,
    component: () => codeSplitLoad('ForgotPasswordContainer'),
    exact: true,
  },
  {
    path: '/reset-password',
    type: RouteTypes.AUTH,
    component: () => codeSplitLoad('ResetPasswordContainer'),
    exact: true,
  },
  {
    path: ['/request_login_link', '/request-login-link'],
    type: RouteTypes.AUTH,
    component: () => codeSplitLoad('RequestLoginLinkContainer'),
  },
  {
    path: '/invite',
    component: () => codeSplitLoad('ShareLanding'),
    type: RouteTypes.INVITE,
    exact: true,
  },
  {
    path: [
      '/donotsellmyinfo',
      '/do-not-sell-my-info',
      '/privacy-settings',
      '/data-protection-settings',
    ],
    component: () => codeSplitLoad('PrivacySettings'),
    exact: true,
  },
  {
    path: ['/tools', '/social-plugins'],
    component: () => codeSplitLoad('EmbedTools'),
    exact: true,
  },
  ErrorRoute,
];

export default routes.map((route) => ({
  exact: false,
  private: false,
  type: RouteTypes.UNSPECIFIED,
  preload: undefined,
  ...route,
}));
