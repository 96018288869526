import Config from 'Config';
import { truncateText } from 'Utils/text';
import { largestUrl, smallestUrl } from 'Utils/image-util';
import Attribution from 'Utils/content/attribution';
import { getArticlePath } from 'Utils/content/flipboard-urls';
import getBreadcrumbs from 'Utils/seo/breadcrumbs';
import { schemaDate, schemaDurationFormat } from 'Utils/date-util';
import { isAbsoluteUrl } from 'Utils/url';
import stripHTML from 'Utils/strip-html';

const { FLIPBOARD_URL } = Config;

const schema = (type, obj) => ({
  '@context': 'https://schema.org',
  '@type': type,
  ...obj,
});

export const FlipboardOrganization = schema('Organization', {
  name: 'Flipboard',
  url: 'https://flipboard.com',
  sameAs: [
    'https://twitter.com/flipboard',
    'https://www.facebook.com/flipboard',
    'https://www.youtube.com/user/InsideFlipboard',
  ],
  logo: schema('ImageObject', {
    url: 'https://s.flipboard.com/assets/webu/images/amp-publisher-logo.jpg',
    width: 600,
    height: 60,
  }),
});

/** Returns the raw JSON representation of structured data
 * for an item.  See https://developers.google.com/search/docs/data-types/article
 * @param {Object} item  - Flipboard item
 * @return {Object}      - The raw JSON of an "Article"
 */
export const itemStructuredData = ({ section, item, t }) => {
  const { sanitizedTitle } = item;
  const name = Attribution.getSourceAttributionText(item, t);
  const datePublished = schemaDate(item.dateCreated);
  const headline = sanitizedTitle && truncateText(sanitizedTitle, 110);
  const description = item.excerpt;

  // article image
  const { image, author, authorImage } = item;
  const imageUrl = image && largestUrl(image);

  // author image
  const authorImageUrl = author && smallestUrl(authorImage);

  const data = {
    mainEntityOfPage: schema('WebPage', {
      '@id': `${FLIPBOARD_URL}${getArticlePath(item, section)}`,
    }),
    author: schema('Person', {
      name,
    }),
    datePublished,
    dateModified: datePublished,
    description: stripHTML(description),
    headline: stripHTML(headline),
    publisher: schema('Organization', {
      name,
      logo: schema('ImageObject', {
        url:
          authorImageUrl ||
          'https://s.flipboard.com/assets/webu/images/avatar-default.png',
      }),
    }),
  };
  if (imageUrl) {
    data.image = schema('ImageObject', {
      url: imageUrl,
    });
  }

  return schema('NewsArticle', data);
};

/** Returns the raw JSON representation of structured data
 * for the home page.  See:
 * https://moz.com/learn/seo/serp-features
 * https://schema.org/SearchAction
 * https://developers.google.com/search/docs/data-types/sitelinks-searchbox
 * @return {Object}         - The raw JSON for the home page strucutred data
 */
export const homePageStructuredData = () => {
  const { FLIPBOARD_URL } = Config;

  return schema('WebSite', {
    url: FLIPBOARD_URL,
    potentialAction: [
      schema('SearchAction', {
        target: `${FLIPBOARD_URL}/search/{search_term_string}`,
        'query-input': 'required name=search_term_string',
      }),
    ],
  });
};

export const storyboardStructuredData = ({ section }) => {
  const {
    canonicalPath,
    coverImage,
    description,
    title: headline,
    author: { authorDisplayName },
  } = section;

  const coverImageUrl = coverImage && largestUrl(coverImage);

  return schema('NewsArticle', {
    mainEntityOfPage: schema('WebPage', {
      '@id': `${FLIPBOARD_URL}${canonicalPath}`,
    }),
    author: schema('Person', {
      name: authorDisplayName,
    }),
    datePublished: section.datePublished,
    dateModified: section.dateModified,
    description: stripHTML(description),
    headline: stripHTML(headline),
    image: schema('ImageObject', {
      url: coverImageUrl,
    }),
    publisher: FlipboardOrganization,
  });
};

export const firstPartyVideoStructuredData = ({ item }) => {
  const {
    sourceURL,
    posterImage,
    title,
    description,
    authorDisplayName,
    dateCreated,
    duration,
  } = item;
  const uploadDate = schemaDate(dateCreated);

  const properties = {
    url: sourceURL,
    embedUrl: sourceURL,
    thumbnailUrl: smallestUrl(posterImage),
    name: stripHTML(title),
    description: stripHTML(description || title),
    uploadDate,
    author: schema('Person', {
      name: authorDisplayName,
    }),
  };

  if (duration) {
    properties.duration = schemaDurationFormat(duration);
  }

  return schema('VideoObject', properties);
};

export class Breadcrumbs {
  constructor() {
    this.crumbs = [];
  }

  add(name, urlOrPath) {
    this.crumbs.push(
      schema('ListItem', {
        position: this.crumbs.length + 1,
        name: stripHTML(name && name.trim && name.trim()),
        item: isAbsoluteUrl(urlOrPath)
          ? urlOrPath
          : `${FLIPBOARD_URL}${urlOrPath}`,
      }),
    );
  }

  structuredData() {
    // Return null if there are no crumbs, or any invalid crumbs
    const invalid =
      this.crumbs.length === 0 || this.crumbs.some((x) => !x.name || !x.item);
    if (invalid) {
      return null;
    }
    return schema('BreadcrumbList', {
      itemListElement: this.crumbs,
    });
  }
}

export const breadcrumbStructuredData = (options) => {
  const breadcrumbs = getBreadcrumbs(options);
  if (breadcrumbs) {
    return breadcrumbs.structuredData();
  }
};
