import GlobalVars from 'Utils/global-vars';
import clientLogger from 'Utils/logger/client';

export type GuardedLogMethod =
  | Console['log']
  | Console['info']
  | Console['warn']
  | Console['error']
  | Console['debug'];

export interface GuardedLog {
  (logMethod: GuardedLogMethod): (
    ...messages: Parameters<GuardedLogMethod>
  ) => void;
}

const logger = GlobalVars.isServer()
  ? eval('require("./server.ts")')
  : clientLogger;

export const { info, debug, warn, error, logRequest } = logger;

export default logger;
