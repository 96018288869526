import React from 'react';
import PropTypes from 'prop-types';
import Amp from './amp';
import classNames from 'classnames';

import withT from 'ComponentLibrary/hocs/withT';

function handleThemeSwitch(variant) {
  switch (variant) {
    case 'red':
      return '#F52828';
    case 'white':
      return '#fff';
    case 'gray':
      return '#666';
    default:
      return '#F52828';
  }
}

function renderLogo(className, size, variant, toolTip, t) {
  return (
    <svg
      key="logo"
      role="img"
      aria-labelledby="logo-icon-title logo-icon-desc"
      className={classNames(className, { 'logo--icon': true })}
      width={size}
      height={size}
      viewBox="0 0 100 100"
      shapeRendering="crispEdges"
    >
      <title id="logo-icon-title">{toolTip}</title>
      <desc id="logo-icon-desc">{t('flipboard_logo_description')}</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Flipboard_01_Lockup"
          fill={handleThemeSwitch(variant)}
          fillRule="nonzero"
        >
          <path
            d="M0,0 L0,100 L100,100 L100,0 L0,0 Z M80,40 L60,40 L60,60 L40,60 L40,80 L20,80 L20,20 L80,20 L80,40 Z"
            id="Shape"
          />
        </g>
      </g>
    </svg>
  );
}

function Flipboard({ className, size, variant, showAmpBadge, toolTip, t }) {
  const tip = toolTip || t('flipboard');
  if (showAmpBadge) {
    return [
      renderLogo(className, size, variant, tip, t),
      <Amp key="amp" size={20} />,
    ];
  }
  return renderLogo(className, size, variant, tip, t);
}

Flipboard.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  variant: PropTypes.string,
  showAmpBadge: PropTypes.bool,
  toolTip: PropTypes.string,
  t: PropTypes.func.isRequired,
};

Flipboard.defaultProps = {
  size: 60,
  variant: 'red',
  showAmpBadge: false,
};

export default withT(Flipboard);
