import { normalizeToArray } from './normalize-to-array';

type Flattenable =
  | Array<Iterable<unknown>>
  | Array<Array<Iterable<unknown>> | Iterable<unknown>>;

export const arrayFlatten = (...args: Flattenable): Array<unknown> => {
  if (args.length === 1) {
    return normalizeToArray(...args[0]);
  }
  return normalizeToArray(...args);
};
