import { Connector } from 'Webapp/utils/connector';

import { usageSetNavFrom } from 'Webapp/shared/app/redux/actions/usage-actions';

const actions = { usageSetNavFrom };

export type ConnectUsageSetNavFromProps = Flipboard.ConnectorProps<
  unknown,
  typeof actions
>;

const connectUsageSetNavFrom: Connector = {
  actions,
};

export default connectUsageSetNavFrom;
