import { topicList } from 'Utils/content/item-util';

import getCurrentSection from 'Utils/seo/shared/get-current-section';

export default ({ section }, getState, { t }) => {
  if (section) {
    const currentSection = getCurrentSection(getState());
    if (currentSection && currentSection.isStoryboard) {
      const topics = topicList(currentSection.items).join(', ');
      return t('storyboard_seo_description', {
        profileName: currentSection.authorDisplayName,
        description: currentSection.description,
        topics,
      });
    }
  }
};
