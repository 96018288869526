import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';
import { currentUserVerified } from 'Webapp/shared/app/redux/selectors/profile';
import publisherConfirmEmailModal from './publisher-confirm-email-modal';

const publisherEnsureVerifiedEmail: OnboardingStep =
  (nextStep) => (dispatch, getState) => {
    if (currentUserVerified(getState())) {
      nextStep();
    } else {
      dispatch(publisherConfirmEmailModal(nextStep));
    }
  };

export default publisherEnsureVerifiedEmail;
