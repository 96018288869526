import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { RouteTypes } from 'Webapp/shared/routes';
import getWindow from 'Utils/get-window';
import qs from 'Utils/qs';
import GlobalVars from 'Utils/global-vars';
import withBreadcrumbs from 'Webapp/shared/app/hocs/withBreadcrumbs';

type TopLevelRouteProps = {
  setRouting: (payload: Partial<Flipboard.State['app']['routing']>) => void;
  resetBreadcrumbs: () => void;
  routeType: RouteTypes;
  children: React.ReactNode;
} & RouteComponentProps;
class TopLevelRoute extends React.Component<TopLevelRouteProps> {
  constructor(props) {
    super(props);
    if (!GlobalVars.isServer()) {
      this.updateRouting();
    }
  }

  componentDidUpdate(prevProps: TopLevelRouteProps) {
    if (prevProps.match.url !== this.props.match.url) {
      this.updateRouting();
    }
  }

  updateRouting = () => {
    const { setRouting, match, location, routeType, resetBreadcrumbs } =
      this.props;
    setRouting({
      ...match,
      ...location,
      type: routeType,
      query: qs.parse(getWindow().location.search, {
        ignoreQueryPrefix: true,
      }) as Record<string, string>,
    });
    // Reset breadcrumbs config
    resetBreadcrumbs();
  };

  render() {
    return this.props.children;
  }
}

export default withRouter(withBreadcrumbs(TopLevelRoute));
