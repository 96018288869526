import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from '@emotion/styled';

import withT from 'ComponentLibrary/hocs/withT';

export const StyledIcon = styled.svg();

const Icon = React.forwardRef(
  (
    {
      name,
      className,
      toolTip,
      size,
      height,
      width,
      viewBox,
      color,
      renderComponent,
      t,
      ...svgProps
    },
    ref,
  ) => {
    const classes = classNames(
      {
        [`${name}-icon`]: true,
      },
      className,
    );

    const Component = renderComponent;
    const derivedToolTip = toolTip || t(name);
    const derivedWidth = width || size;
    const derivedHeight = height || size;
    const derivedViewBox = viewBox || `0 0 ${derivedWidth} ${derivedHeight}`;
    return (
      <StyledIcon
        ref={ref}
        role="img"
        aria-labelledby={`${name}-icon-title`}
        className={classes}
        width={derivedWidth}
        height={derivedHeight}
        viewBox={derivedViewBox}
        {...svgProps}
      >
        <title id={`${name}-icon-title`}>{derivedToolTip}</title>
        <Component color={color} />
      </StyledIcon>
    );
  },
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  toolTip: PropTypes.string,
  size: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  viewBox: PropTypes.string,
  color: PropTypes.string,
  renderComponent: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

Icon.defaultProps = {
  className: '',
  toolTip: null,
  size: 18,
  width: null,
  height: null,
  color: '#999',
};

export default withT(Icon);
