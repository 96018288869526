import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Utils
import HocUtil from 'Utils/hoc-util';

// Actions
import { shortenSection } from 'Webapp/shared/app/redux/actions/section-actions';

import withToast from '../hocs/withToast';
import withT from 'ComponentLibrary/hocs/withT';

function withMagazineContributorInvitation(Component) {
  class WrappedComponent extends React.Component {
    constructor(props) {
      super(props);

      this.state = { inviteUrl: null };
    }

    async componentDidMount() {
      const { magazine, shortenSection, t } = this.props;
      try {
        const inviteUrl = await shortenSection(magazine, true);

        if (inviteUrl) {
          this.setState({ inviteUrl });
        }
      } catch (_) {
        this.props.toastShowErrorAction(t('fallback_error_message'));
      }
    }

    render() {
      return <Component inviteUrl={this.state.inviteUrl} {...this.props} />;
    }
  }

  // Proxy the wrapped component displayName
  WrappedComponent.displayName = HocUtil.displayName(Component);
  WrappedComponent.propTypes = {
    magazine: PropTypes.object.isRequired,
    toastShowErrorAction: PropTypes.func.isRequired,
    shortenSection: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ shortenSection }, dispatch);

  return connect(null, mapDispatchToProps)(withT(withToast(WrappedComponent)));
}

export default withMagazineContributorInvitation;
