import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { TEXT_COLORS, SURFACE_COLORS, COLORS } from 'Style/colors';
import { SPACING } from 'Style/spacing';
import {
  UI_TEXT_TYPES,
  UI_HEADING_TYPES,
  BODY_TYPES,
  NO_TEXT_TRANSFORM,
} from 'Style/typography';

/* Utils */
import DateUtil from 'Utils/date-util';
import { noOpFn } from 'Utils/no-op-fn';

import MagazineThumbnail from 'Webapp/shared/app/components/magazine-thumbnail';
import CheckmarkIcon from 'ComponentLibrary/icons/checkmark';
import LockIcon, { StyledLockIcon } from 'ComponentLibrary/icons/lock';
import UnstyledButton from 'Webapp/shared/app/components/unstyled-button';

import connector from 'Webapp/utils/connector';
import connectFeatureFlags from 'Webapp/shared/app/connectors/connectFeatureFlags';
import connectLanguage from 'Webapp/shared/app/connectors/connectLanguage';
import withT from 'ComponentLibrary/hocs/withT';

const FLIP_MAGAZINE_IMAGE_THUMB_SIZE = 50;
const REDESIGNED_MAGAZINE_IMAGE_THUMB_WIDTH = 56;
const REDESIGNED_MAGAZINE_IMAGE_THUMB_HEIGHT = 69;

const OMIT_STYLE_PROPS = ['isFlipMode', 'isSelected', 'isRedesignedLayout'];
const omitStylingProps = {
  shouldForwardProp: (prop) => !OMIT_STYLE_PROPS.includes(prop),
};
const getImageWidth = (isRedesignedLayout) => {
  if (isRedesignedLayout) {
    return REDESIGNED_MAGAZINE_IMAGE_THUMB_WIDTH;
  }
  return FLIP_MAGAZINE_IMAGE_THUMB_SIZE;
};
const getImageHeight = (isRedesignedLayout) => {
  if (isRedesignedLayout) {
    return REDESIGNED_MAGAZINE_IMAGE_THUMB_HEIGHT;
  }
  return FLIP_MAGAZINE_IMAGE_THUMB_SIZE;
};

const FlipMagazine = styled('li', omitStylingProps)(
  ({ isRedesignedLayout }) => ({
    '.button--unstyled__children': {
      display: 'grid',
      cursor: 'pointer',
      gridGap: isRedesignedLayout ? SPACING.LARGE : SPACING.MEDIUM,
      gridTemplateColumns: `${getImageWidth(isRedesignedLayout)}px 1fr`,
      padding: `${SPACING.SMALL} 0 ${SPACING.SMALL} ${SPACING.SMALL}`,
    },
  }),
  ({ isFlipMode }) => {
    if (isFlipMode) {
      return {
        listStyle: 'none',
        '.button--unstyled__children': {
          gridTemplateColumns: '64px 1fr',
          padding: '0',
          marginBottom: SPACING.MEDIUM,
        },
      };
    }
  },
  ({ isSelected, isRedesignedLayout }) => {
    if (isSelected) {
      return {
        '.button--unstyled__children': {
          gridTemplateColumns: `${getImageWidth(
            isRedesignedLayout,
          )}px 1fr 36px`,
        },
      };
    }
  },
  {
    '&:hover': {
      backgroundColor: SURFACE_COLORS.secondary,
    },
  },
);

const FlipMagazineButton = styled(UnstyledButton)(
  { width: '100%' },
  NO_TEXT_TRANSFORM,
);

const StyledMagazineThumbnail = styled(MagazineThumbnail, omitStylingProps)(
  {
    overflow: 'hidden',
  },
  ({ isFlipMode, isRedesignedLayout }) => {
    if (isFlipMode) {
      return { height: '64px' };
    }
    if (isRedesignedLayout) {
      return {
        height: getImageHeight(isRedesignedLayout),
        width: getImageWidth(isRedesignedLayout),
        borderRadius: SPACING.BASE,
      };
    }
    return { height: `${getImageHeight(isRedesignedLayout)}px` };
  },
);
const EmptyImage = styled('div', omitStylingProps)(
  {
    backgroundColor: COLORS.createMagTile,
  },
  ({ isFlipMode, isRedesignedLayout }) => {
    if (isFlipMode) {
      return { width: '100%', height: '100%' };
    }
    const borderStyles = isRedesignedLayout
      ? {
          border: '2px solid rgba(0, 0, 0, 0.12)',
          borderRadius: SPACING.BASE,
        }
      : {};
    return {
      ...borderStyles,
      width: `${getImageWidth(isRedesignedLayout)}px`,
      height: `${getImageHeight(isRedesignedLayout)}px`,
    };
  },
);

const FlipMagazineBody = styled('div', omitStylingProps)(
  {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  ({ isRedesignedLayout }) => {
    if (isRedesignedLayout) {
      return {
        ...UI_HEADING_TYPES.SMALL_STANDARD,
        display: 'grid',
        color: TEXT_COLORS.primary,
      };
    }
    return UI_TEXT_TYPES.BODY_2;
  },
  ({ isFlipMode }) => {
    if (isFlipMode) {
      return {
        color: TEXT_COLORS.overlay,
        maxWidth: '234px',
        '&:hover': { color: TEXT_COLORS.overlay },
      };
    }
  },
);
const MagazinePublishDate = styled.time(BODY_TYPES.XSMALL_STANDARD, {
  color: TEXT_COLORS.primary,
  textTransform: 'capitalize',
});
const MagazineVisiblityText = styled.div(BODY_TYPES.XSMALL_STANDARD, {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: SPACING.SMALL,
  color: TEXT_COLORS.tertiary,
  textTransform: 'capitalize',
  [StyledLockIcon]: {
    color: TEXT_COLORS.tertiary,
  },
  alignItems: 'center',
});

const CheckmarkWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

function Magazine({
  magazine,
  isSelected,
  onClick,
  isFlipMode,
  featureFlags,
  t,
  lang,
}) {
  const handleClick = (e) => {
    e.preventDefault();
    onClick(magazine);
  };
  const isRedesignedLayout = featureFlags.NEW_FLIP_COMPOSE;

  const privateStatusText = magazine.isPrivate
    ? t('private_magazine')
    : t('public_magazine');

  return (
    <FlipMagazine
      isSelected={isSelected}
      isFlipMode={isFlipMode}
      isRedesignedLayout={isRedesignedLayout}
    >
      <FlipMagazineButton name="flip-compose-magazine" onClick={handleClick}>
        <StyledMagazineThumbnail
          isFlipMode={isFlipMode}
          isRedesignedLayout={isRedesignedLayout}
          height={getImageHeight(isRedesignedLayout)}
          width={getImageWidth(isRedesignedLayout)}
          image={magazine.image}
          fallbackElement={
            <EmptyImage isRedesignedLayout={isRedesignedLayout} />
          }
        />
        <FlipMagazineBody
          isFlipMode={isFlipMode}
          isRedesignedLayout={isRedesignedLayout}
        >
          {magazine.title}
          {isRedesignedLayout && (
            <React.Fragment>
              <MagazinePublishDate>
                {/* Created */}
                Created{' '}
                {DateUtil.friendlyFormatLocalized(
                  magazine.datePublished,
                  t,
                  lang,
                )}
              </MagazinePublishDate>
              <MagazineVisiblityText>
                {magazine.isPrivate && <LockIcon size={10} />}
                <span>{privateStatusText}</span>
              </MagazineVisiblityText>
            </React.Fragment>
          )}
        </FlipMagazineBody>
        {isSelected && (
          <CheckmarkWrapper>
            <CheckmarkIcon color="#E12828" />
          </CheckmarkWrapper>
        )}
      </FlipMagazineButton>
    </FlipMagazine>
  );
}

Magazine.propTypes = {
  magazine: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  featureFlags: PropTypes.object.isRequired,
  isFlipMode: PropTypes.bool,
  t: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};

Magazine.defaultProps = {
  isSelected: false,
  onClick: noOpFn,
  isFlipMode: false,
};

export default connector(connectFeatureFlags, connectLanguage)(withT(Magazine));
