import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

import sentry from 'Utils/sentry';

import { FrictionlessSignupError } from 'Utils/errors';

// actions
import { frictionlessSignup } from 'Webapp/shared/app/redux/actions/auth-actions';
import { toastShowErrorAction } from 'Webapp/shared/app/redux/actions/toast-actions';
import { subscribeToPendingSubscriptionGroups } from 'Webapp/shared/app/redux/actions/account/email-settings-actions';
import {
  onboardingDataSelector,
  setOnboardingSuccess,
  setOnboardingPending,
} from 'Webapp/shared/concepts/onboarding';

const newsletterSignup: OnboardingStep =
  (nextStep) =>
  async (dispatch, getState, { t }) => {
    dispatch(setOnboardingPending(true));
    const onboardingData = onboardingDataSelector(getState());
    if (!onboardingData) {
      throw new FrictionlessSignupError(
        'Newsletter signup data missing onboardingData',
      );
    }
    try {
      const signupResponse = await dispatch(
        frictionlessSignup(onboardingData.email),
      );
      const signupSuccess = !!signupResponse?.success;
      dispatch(setOnboardingSuccess(signupSuccess));
      if (signupSuccess) {
        await dispatch(
          subscribeToPendingSubscriptionGroups(
            onboardingData.pendingSubscriptionGroups,
          ),
        );
      } else {
        throw new FrictionlessSignupError();
      }
    } catch (error) {
      sentry.captureException(error as FrictionlessSignupError);

      dispatch(setOnboardingSuccess(false));
      dispatch(toastShowErrorAction(t('failed_to_sign_up')));
    }

    nextStep();
  };

export default newsletterSignup;
