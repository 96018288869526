/* eslint react/no-find-dom-node: 0 */

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import styled from '@emotion/styled';

// Libs
import { noOpFn } from 'Utils/no-op-fn';
import { imgIsValid } from 'Utils/dom';
import { upgradeToHTTPS } from 'Utils/image-util';
import { ImageLoadingMode } from 'Webapp/enums';

export const THEMES = Object.freeze({
  small: 'small',
  medium: 'medium',
  large: 'large',
  social: 'social',
});

const themeKeyPreferences = {
  [THEMES.small]: [
    'image/webp.smallURL',
    'image/webp.mediumURL',
    'smallURL',
    'mediumURL',
    'image/webp.largeURL',
    'image/webp.xlargeURL',
    'largeURL',
    'xlargeURL',
    'url',
    'imageURL',
    'original_url',
  ],
  [THEMES.medium]: [
    'image/webp.mediumURL',
    'image/webp.largeURL',
    'mediumURL',
    'image/webp.xlargeURL',
    'largeURL',
    'image/webp.smallURL',
    'smallURL',
    'xlargeURL',
    'url',
    'imageURL',
    'original_url',
  ],
  [THEMES.large]: [
    'image/webp.xlargeURL',
    'image/webp.largeURL',
    'xlargeURL',
    'largeURL',
    'mediumURL',
    'smallURL',
    'url',
    'imageURL',
    'original_url',
  ],
  [THEMES.social]: [
    'image/webp.mediumURL',
    'mediumURL',
    'image/webp.smallURL',
    'smallURL',
    'image/webp.largeURL',
    'largeURL',
    'image/webp.xlargeURL',
    'xlargeURL',
    'original_url',
  ],
};

export const getImageSrc = (image, acceptableImageKeys) => {
  if (typeof image === 'string') {
    return image;
  }
  if (!image || Object.keys(image).length === 0) {
    return null;
  }
  let imageSrc = null;
  acceptableImageKeys.find((key) => {
    const found = get(image, key);
    if (found) {
      imageSrc = found;
      return true;
    }
  });
  return imageSrc;
};

export const getBestImageSrc = (variant = 'medium', image) =>
  getImageSrc(image, themeKeyPreferences[variant]);

export const getFallbackImageSrc = (variant = 'medium', image) =>
  getImageSrc(
    image,
    themeKeyPreferences[variant].filter((k) => k.indexOf('webp') === -1),
  );

export const imageType = (path) => {
  try {
    const pathChunks = (path || '').replace(/\?.*$/, '').split('.');
    const end = pathChunks[pathChunks.length - 1];
    const match = end?.match(/[a-z]+/);
    if (!match || !match[0]) {
      return;
    }
    const extension = match[0];
    if (extension === 'jpg' || extension === 'jpeg') {
      return 'image/jpeg';
    }
    if (extension === 'webp') {
      return 'image/webp';
    }
    if (extension === 'png') {
      return 'image/png';
    }
    if (extension === 'gif') {
      return 'image/gif';
    }
    if (extension === 'svg') {
      return 'image/svg+xml';
    }
  } catch (_error) {
    return;
  }
};

const StyledPicture = styled.picture({
  margin: 0,
  maxWidth: '100%',
  verticalAlign: 'bottom',
});

class GenericImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageDidError: false,
    };
    this.imageElement = this.props.forwardedRef || React.createRef();
    this.handleImageError = this.handleImageError.bind(this);
    this.imageIsInvalid = this.imageIsInvalid.bind(this);
  }

  componentDidUpdate() {
    if (!this.state.imageDidError && this.imageIsInvalid()) {
      this.setState({
        imageDidError: true,
      });
    }
  }

  handleImageError(e) {
    if (this.props.onError) {
      this.props.onError(e);
    }
    this.setState({ imageDidError: true });
  }

  imageIsInvalid() {
    // Get the image from the DOM.
    // This is necessary because there is a race condition
    // between the server-side-rendered content loading
    // images in the browser and the client-side React JS
    // mounting.  If the latter is slow, it misses any native
    // "onError" event thrown by the browser in response
    // to failed image loads.
    const img = ReactDOM.findDOMNode(this.imageElement.current);
    return img && img.complete && !imgIsValid(img);
  }

  render() {
    const {
      height,
      width,
      alt,
      fallbackElement,
      className,
      style,
      onClick,
      windowHeight,
      loadingMode,
    } = this.props;

    // HTML rendering
    if (this.state.imageDidError) {
      // Support for supplying a custom fallback element
      return fallbackElement;
    }

    // Apply windowHeight if provided, and if needed
    let updatedStyle = style;
    if (windowHeight && height >= width && height > windowHeight) {
      // If the image is taller than wide or is square, and
      // If the image height is greater than the screen height
      updatedStyle = Object.assign({}, style, {
        maxHeight: windowHeight,
        width: 'auto',
      });
    }

    const bestImageSrc = upgradeToHTTPS(
      getBestImageSrc(this.props.variant, this.props.image),
    );
    const fallbackImageSrc = upgradeToHTTPS(
      getFallbackImageSrc(this.props.variant, this.props.image),
    );
    if (!bestImageSrc && !fallbackImageSrc) {
      return fallbackElement;
    }

    return (
      <StyledPicture>
        <source srcSet={bestImageSrc} type={imageType(bestImageSrc)} />
        <source srcSet={fallbackImageSrc} type={imageType(fallbackImageSrc)} />
        <img
          ref={this.imageElement}
          loading={loadingMode}
          src={fallbackImageSrc}
          className={className}
          style={updatedStyle}
          alt={alt}
          width={width}
          height={height}
          onError={this.handleImageError}
          onLoad={this.props.onLoad}
          onClick={onClick}
        />
      </StyledPicture>
    );
  }
}

GenericImage.propTypes = {
  alt: PropTypes.string.isRequired,
  forwardedRef: PropTypes.object,
  fallbackElement: PropTypes.element,
  className: PropTypes.string,
  style: PropTypes.object,
  windowHeight: PropTypes.number,
  onClick: PropTypes.func,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
  imageLoaded: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  variant: PropTypes.oneOf(Object.keys(THEMES)),
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  loadingMode: PropTypes.oneOf(['lazy', 'eager']),
};

GenericImage.defaultProps = {
  alt: null,
  fallbackElement: null,
  className: '',
  style: {},
  windowHeight: null,
  onClick: noOpFn,
  onError: noOpFn,
  onLoad: noOpFn,
  imageLoaded: false,
  variant: THEMES.medium,
  loadingMode: ImageLoadingMode.lazy,
};

export default GenericImage;
