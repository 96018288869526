import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import freeze from 'redux-freeze';
import GlobalVars from 'Utils/global-vars';
import getWindow from 'Utils/get-window';
import Flap from 'Utils/api/flap';
import FlapStatic from 'Utils/api/flap-static';
import Flavour from 'Utils/api/flavour';
import Content from 'Utils/api/content';
import Flout from 'Utils/api/flout';
import Publisher from 'Utils/api/publisher';
import Sitemap from 'Utils/api/sitemap';
import Auth from 'Utils/api/auth';

import * as signedMediaCookies from 'Utils/api/signed-media-cookies';
import reducers from './reducers/combine';

import { createClientT } from 'i18n';
const t = createClientT();

// Grab the state from a global variable injected into the server-generated HTML
let preloadedState = {};
if (typeof getWindow() !== 'undefined' && getWindow().__PRELOADED_STATE__) {
  preloadedState = getWindow().__PRELOADED_STATE__;
  if (!GlobalVars.isDevelopment) {
    delete getWindow().__PRELOADED_STATE__;
  }
}

const composeEnhancers =
  typeof window !== 'undefined'
    ? getWindow().__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;
const middleware = [
  thunk.withExtraArgument({
    t,
    auth: Auth,
    content: Content,
    flap: Flap,
    flapStatic: FlapStatic,
    flavour: Flavour,
    flout: Flout,
    publisher: Publisher,
    sitemap: Sitemap,
    signedMediaCookies,
  }),
];

if (GlobalVars.isDevelopment) {
  middleware.push(freeze);
  // Uncomment for serious redux logging:
  // middleware.push(store => next => action => {
  //   console.group('Processing action', action);
  //   console.log('before:', store.getState());
  //   const result = next(action);
  //   console.log('after:', store.getState());
  //   console.groupEnd();
  //   return result;
  // });
}

const enhancers = composeEnhancers(
  ...middleware.map((x) => applyMiddleware(x)),
);

const store = createStore(reducers, preloadedState, enhancers);

if (!GlobalVars.isProduction) {
  getWindow().$store = store;
}

export default store;
