import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { TEXT_COLORS, SURFACE_COLORS } from 'Style/colors';
import { LAYOUT_SIZES, LAYERS } from 'Style/layout';
import { SPACING } from 'Style/spacing';

// Components
import UnstyledButton from 'Webapp/shared/app/components/unstyled-button';
import CloseIcon from 'ComponentLibrary/icons/close';
import CaretIcon, { CARET_DIRECTIONS } from 'ComponentLibrary/icons/caret';
import MagazinePicker from 'Webapp/shared/app/components/flip/flip-mode-magazine-picker';
import Magazine from 'Webapp/shared/app/components/flip/magazine';
import SectionLink from 'Webapp/shared/app/components/section-link';

import connector from 'Utils/connector';
import connectFlipMode from 'Webapp/shared/app/connectors/connectFlipMode';
import connectCurrentUser from 'Webapp/shared/app/connectors/connectCurrentUser';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';

const Wrapper = styled.div({
  height: LAYOUT_SIZES.FLIP_MODE_HEIGHT,
  width: '100%',
  backgroundColor: SURFACE_COLORS.primaryReverse,
  position: 'sticky',
  top: LAYOUT_SIZES.MAIN_NAV_HEIGHT,
  zIndex: LAYERS.ACTION_SHEET,
});

const CloseButton = styled(UnstyledButton)((props) => ({
  right: props.isLargeScreen ? null : `${SPACING.BASE}`,
}));

class FlipMode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMagazinePicker: false,
    };

    this.toggleShowMagazinePicker = this.toggleShowMagazinePicker.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    const { flipModeTargetMagazine, setTargetRemoteId, allUserMagazines } =
      this.props;
    const firstUserMagazine = allUserMagazines.length && allUserMagazines[0];
    if (!flipModeTargetMagazine && firstUserMagazine) {
      setTargetRemoteId(firstUserMagazine.remoteid);
    }
  }

  toggleShowMagazinePicker() {
    const { showMagazinePicker } = this.state;

    if (showMagazinePicker) {
      this.setState({ showMagazinePicker: false });
    } else {
      this.setState({ showMagazinePicker: true });
    }
  }

  handleClose() {
    this.props.setFlipModeEnabled(false);
  }

  render() {
    const { flipModeTargetMagazine } = this.props;
    const { showMagazinePicker } = this.state;

    const selectionClassNames = classNames(
      'flip-mode__selection ui-subheading--large-standard',
      {
        'flip-mode__selection--open': showMagazinePicker,
      },
    );

    return (
      <Wrapper>
        <div className="flip-mode centered-column">
          <div className="flip-mode__label ui-subheading--large-standard">
            You are flipping into
          </div>
          <div className={selectionClassNames}>
            <div className="flip-mode__selected-item">
              {flipModeTargetMagazine && (
                <Magazine
                  magazine={flipModeTargetMagazine}
                  onClick={this.toggleShowMagazinePicker}
                  isFlipMode
                />
              )}
            </div>
            <div className="flip-mode__magazine-list">
              {this.state.showMagazinePicker && (
                <MagazinePicker onSelect={this.toggleShowMagazinePicker} />
              )}
            </div>
            <UnstyledButton
              name="flipmode-magazine-list-toggle"
              className="flip-mode__magazine-list-toggle"
              onClick={this.toggleShowMagazinePicker}
            >
              <CaretIcon
                color={TEXT_COLORS.overlay}
                direction={
                  showMagazinePicker
                    ? CARET_DIRECTIONS.up
                    : CARET_DIRECTIONS.down
                }
                size={18}
              />
            </UnstyledButton>
          </div>
          <div className="flip-mode__link-section ui-subheading--large-standard">
            {flipModeTargetMagazine && (
              <SectionLink
                className="flip-mode__section-link"
                section={flipModeTargetMagazine}
              >
                go to magazine
              </SectionLink>
            )}
          </div>
          <CloseButton
            name="flipmode-close"
            className="flip-mode__close"
            onClick={this.handleClose}
          >
            <CloseIcon color={TEXT_COLORS.overlay} />
          </CloseButton>
        </div>
      </Wrapper>
    );
  }
}

FlipMode.propTypes = {
  flipModeTargetMagazine: PropTypes.object,
  setFlipModeEnabled: PropTypes.func.isRequired,
  setTargetRemoteId: PropTypes.func.isRequired,
  allUserMagazines: PropTypes.array.isRequired,
  isLargeScreen: PropTypes.bool.isRequired,
};

FlipMode.defaultProps = {
  flipModeTargetMagazine: null,
};

export default connector(
  connectResponsive,
  connectCurrentUser,
  connectFlipMode,
)(FlipMode);
