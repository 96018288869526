import qs from 'Utils/qs';
import Config from 'Config';

const videoAdTagUrl = (additionalParams) => {
  const adTagUrlParams = {
    iu: Config.VIDEO_AD_TAG_IU,
    tfcd: 0,
    npa: 0,
    sz: '640x480',
    gdfp_req: 1,
    output: 'vast',
    unviewed_position_start: 1,
    env: 'vp',
    impl: 's',
    correlator: '',
    ...additionalParams,
  };
  const baseAdTagUrl = 'https://pubads.g.doubleclick.net/gampad/ads';
  return `${baseAdTagUrl}?${qs.stringify(adTagUrlParams)}`;
};

export default videoAdTagUrl;
