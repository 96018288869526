import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { LAYOUT_SIZES } from 'Style/layout';
import withBreadcrumbs from '../hocs/withBreadcrumbs';

import connector from 'Utils/connector';
import connectVerificationStatus from 'Webapp/shared/app/connectors/connectVerificationStatus';
import connectFlipMode from 'Webapp/shared/app/connectors/connectFlipMode';

const StyledMain = styled.main((props) => ({
  padding: props.isFlipModeEnabled
    ? `${LAYOUT_SIZES.MAIN_NAV_PLUS_FLIP_MODE_HEIGHT} 0 0`
    : null,
}));

const PageClasses = React.forwardRef(
  (
    {
      children,
      className,
      enableFullscreen,
      enableFullWidth,
      isFlipModeEnabled,
    },
    ref,
  ) => {
    const mainClasses = classNames(className, {
      main: !enableFullscreen,
      'main--fullscreen': enableFullscreen,
      'centered-column': !enableFullWidth && !enableFullscreen,
    });
    return (
      <div className="page" ref={ref}>
        <StyledMain
          className={mainClasses}
          isFlipModeEnabled={isFlipModeEnabled}
        >
          {children}
        </StyledMain>
      </div>
    );
  },
);

PageClasses.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  enableFullscreen: PropTypes.bool, // Width & height, used for albums
  enableFullWidth: PropTypes.bool, // Width, used for magazine headers
  isFlipModeEnabled: PropTypes.bool.isRequired,
};

PageClasses.defaultProps = {
  enableFullscreen: false,
  enableFullWidth: false,
  hasBreadcrumbs: false,
};

export default connector(
  connectVerificationStatus,
  connectFlipMode,
)(withBreadcrumbs(PageClasses));
