import GlobalVars from 'Utils/global-vars';
import getWindow from 'Utils/get-window';

const LANG_REGEX = /(^[a-z]+)([-_].+)?/;

const SEARCH_SUPPORTED_LOCALES = ['en', 'fr', 'de'];

const normalize = (s) => {
  const [lang, locale] = (s || '')
    .toLowerCase()
    .replace(/\W+/g, '_')
    .replace(/_+/, '_')
    .split('_');
  return locale ? `${lang}_${locale.toUpperCase()}` : lang;
};

const normalizedCompare = (a, b) => normalize(a) === normalize(b);

export default {
  getClientLocale() {
    if (GlobalVars.isServer()) {
      return;
    }
    const { navigator } = getWindow();
    const language =
      (navigator.languages &&
        navigator.languages.length &&
        navigator.languages.length[0]) ||
      navigator.language ||
      'en';

    return this.getLocaleByLanguage(language);
  },

  /**
   * Gets language from language param that may include region
   * @param {String} lang
   * @return {String} language without region
   */
  getLanguageWithoutRegion(lang) {
    if (!lang || !lang.match) {
      return null;
    }
    const matches = lang.match(LANG_REGEX);
    if (!matches) {
      return null;
    }
    return matches.length > 0 && matches[1];
  },
  /**
   * Gets locale in Flipboard supported format.
   * Note that if a region is not specified in the lang string, then some defaults may be set.
   * @param {String} lang - valid lang strings. eg: 'en', 'en-US', 'en-GB', 'ja', 'en-US,en;q=0.9
   * @return - Flipboard supported locale in the format: 'ja', 'en_US', 'fr_FR', 'fr_CA', etc
   */
  getLocaleByLanguage(lang) {
    if (!lang) {
      return null;
    }
    const locale = lang.split(/,|;/)[0];
    if (normalizedCompare(locale, 'en')) {
      return 'en_US';
    }
    if (normalizedCompare(locale, 'de')) {
      return 'de_DE';
    }
    if (normalizedCompare(locale, 'fr')) {
      return 'fr_FR';
    }
    return normalize(locale);
  },

  /**
   * Checks if lang supports Flipboard search
   * @return {Boolean} - Whether given lang supports search or not
   */
  isSearchSupported() {
    const lang = this.getClientLocale();
    return !!SEARCH_SUPPORTED_LOCALES.find((l) => lang.includes(l));
  },

  isLocaleMatch: normalizedCompare,

  isLangMatch: normalizedCompare,
};
