import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { noOpFn } from 'Utils/no-op-fn';

import { SPACING } from 'Style/spacing';
import AnchoredDialog, {
  ANCHORED_DIALOG_POSITION_X,
  ANCHORED_DIALOG_POSITION_Y,
} from 'ComponentLibrary/utility/anchored-dialog';
import Button, { StyleVariations } from 'Webapp/shared/app/components/button';
import ResendVerificationEmailButton from 'Webapp/shared/app/components/resend-verification-email-button';

import connector from 'Utils/connector';
import connectModal from 'Webapp/shared/app/connectors/connectModal';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectCurrentUser from 'Webapp/shared/app/connectors/connectCurrentUser';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import withT from 'ComponentLibrary/hocs/withT';
import { setCookieValue, getCookieValue } from 'Utils/client-cookie';

const Buttons = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  columnGap: `${SPACING.MEDIUM}`,
  marginTop: `${SPACING.MEDIUM}`,
});

class VerifyAccountAnchoredDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: false,
    };

    this.handleDismiss = this.handleDismiss.bind(this);
    this.updateVisibility = this.updateVisibility.bind(this);
  }

  componentDidMount() {
    this.updateVisibility();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentUser && this.props.currentUser) {
      this.updateVisibility();
    }
  }

  updateVisibility() {
    const { currentUser, currentUserVerified, dismissedCookieName } =
      this.props;

    this.setState({
      display:
        currentUser &&
        !currentUserVerified &&
        !getCookieValue(dismissedCookieName),
    });
  }

  handleDismiss() {
    this.setState({ display: false });
    this.props.onDismiss();
    setCookieValue(this.props.dismissedCookieName, true);
  }

  render() {
    const { copy, redisplayCopy, redisplay, t, isWideLargeScreen } = this.props;

    let styleVariation = redisplay
      ? AnchoredDialog.styleVariations.MENU
      : AnchoredDialog.styleVariations.EDU;
    if (!isWideLargeScreen) {
      styleVariation = AnchoredDialog.styleVariations.FULL_WIDTH;
    }

    const styleModifiers = isWideLargeScreen
      ? [AnchoredDialog.styleModifiers.WIDE]
      : [];

    return (
      (redisplay || this.state.display) && (
        <AnchoredDialog
          targetRef={this.props.targetRef}
          fixed
          styleVariation={styleVariation}
          styleModifiers={styleModifiers}
          positionX={ANCHORED_DIALOG_POSITION_X.RIGHT}
          positionY={ANCHORED_DIALOG_POSITION_Y.BELOW}
          onClickOutside={this.handleDismiss}
        >
          <div>
            <p>{redisplay ? redisplayCopy : copy}</p>
            <Buttons>
              <ResendVerificationEmailButton
                name="verify-account-cta-resend-email"
                styleVariation={
                  redisplay
                    ? StyleVariations.NONE
                    : StyleVariations.WHITE_OVERLAY
                }
                onResendEmail={this.handleDismiss}
              />
              {!redisplay && (
                <Button
                  name="verify-account-cta-dismiss"
                  styleVariation={StyleVariations.PRIMARY}
                  onClick={this.handleDismiss}
                >
                  {t('unverified_edu_got_it')}
                </Button>
              )}
            </Buttons>
          </div>
        </AnchoredDialog>
      )
    );
  }
}

VerifyAccountAnchoredDialog.propTypes = {
  dismissedCookieName: PropTypes.string.isRequired,
  targetRef: PropTypes.object,
  t: PropTypes.func.isRequired,
  resendConfirmation: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  currentUserVerified: PropTypes.bool.isRequired,
  redisplay: PropTypes.bool,
  copy: PropTypes.string.isRequired,
  redisplayCopy: PropTypes.string,
  onDismiss: PropTypes.func,
  isWideLargeScreen: PropTypes.bool.isRequired,
};

VerifyAccountAnchoredDialog.defaultProps = {
  currentUser: null,
  redisplay: false,
  redisplayCopy: '',
  onDismiss: noOpFn,
};

export default connector(
  connectResponsive,
  connectCurrentUser,
  connectAuthentication,
  connectModal,
)(withT(VerifyAccountAnchoredDialog));
