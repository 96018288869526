import getWindow from 'Utils/get-window';
import sentry from 'Utils/sentry';
import logger from 'Utils/logger';
import flaxios from 'Utils/flaxios';
import {
  setCookieValue as clientSetCookieValue,
  getCookieValue as clientGetCookieValue,
} from 'Utils/client-cookie';
import { duc } from 'Utils/url';
import Attribution from 'Utils/content/attribution';

import Config from 'Config';

export const USAGE_PROD_TYPES = {
  WEB: 'web',
  SAMSUNG_DAILY: 'samsung-daily',
  WIDGET: 'webwidget',
};
export const USAGE_SESSION_COOKIE_NAME = 'ue_session';
export const UE_ACTIVATION_FROM_COOKIE_NAME = 'activation_from';
export const UE_ACTIVATION_REFERRER_COOKIE_NAME = 'activation_referrer';
export const UE_ACTIVATION_SECTION_ID_COOKIE_NAME = 'activation_section_id';
export const UE_ACTIVATION_LANDING_URL_COOKIE_NAME = 'activation_landing_url';
export const UE_ACTIVATION_ADJUST_INITIAL_DEEP_LINK_COOKIE_NAME =
  'activation_adjust_initial_deep_link';
export const UE_COOKIE_PATH = 'setcookie';
export const RECAPTCHA_SCORE_COOKIE_NAME = 'recatpcha_score';

export const FALLBACK_SECTION_FEED_TYPE = 'section';
export const HEARTBEAT_DURATION = 5000;
export const USAGE_EVENT_NAMES = {
  ITEM_FLIP: 'itemFlip',
  ITEM_LIKE: 'itemLike',
  ITEM_UNLIKE: 'itemUnlike',
  ITEM_SHARE: 'itemShare',
  ITEM_ENTER: 'itemEnter',
  SECTION_FLIP: 'sectionFlip',
  SECTION_ENTER: 'sectionEnter',
  CURATOR_PRO_ENTER: 'curatorProEnter',
  CURATOR_PRO_MAGAZINE_INVITE_CONTRIBUTOR:
    'curatorProMagazineInviteContributor',
  ANALYTICS_ENTER: 'analyticsEnter',
  SECTION_HEARTBEAT: 'sectionHeartbeat',
  TAP_SECTION_SUBSCRIBE: 'tapSectionSubscribe',
  SECTION_SUBSCRIBE: 'sectionSubscribe',
  SECTION_UNSUBSCRIBE: 'sectionUnsubscribe',
  TAP_MAGAZINE_CREATE: 'tapMagazineCreate',
  MAGAZINE_CREATE: 'magazineCreate',
  DISPLAY_MOBILE_GATE: 'displayMobileGate',
  OPEN_IN_APP: 'openInApp',
  TAP_FLIP: 'tapFlip',
  URL_FLIP: 'urlFlip',
  BANNER_ENTER: 'bannerEnter',
  TOC_ENTER: 'tocEnter',
  CONFIRMATION_EMAIL_ENTER: 'confirmationEmailEnter',
  MAGAZINE_SUBSCRIBE: 'magazineSubscribe',
};

export const USAGE_NAV_FROMS = {
  HOME: 'home', // Used only on the "Home" feed
  SEE_MORE: 'see-more', // Used within the "see more" region of article views
  NGL: 'ngl', // Used within NGL-rendered sections
  FEED: 'feed', // Used for all non-NGL section feeds, except "Home"
  ARTICLE: 'article', // Used for item views that are not videos
  VIDEO: 'video', // Used for video item views
  SEARCH_RESULTS: 'search-results', // Used for search results (both in the modal and on the search results page)
  ZERO_STATE_SEARCH: 'zero-state-search', // Used for the default search results in modal
  NAV_BAR: 'navbar', // Used for menu items clicked in the navigation bar
  STORYBOARD_AUTHOR: 'storyboard-author', // Used for following/unfollowing (subscribing/unsubscribing)
  // NGL feed authors in storyboard  headers
  MOBILE_GATE_BOTTOM_PICKER: 'mobile-gate-bottom-picker',
  MOBILE_GATE_OVERLAY: 'mobile-gate-overlay',
  PUBLISHER_ONBOARDING: 'publisher_onboarding',
  SAMSUNG_DAILY: 'samsung_daily',
  SAMSUNG_DAILY_NEWS_CARD: 'samsung_daily_news_card',
  SHARE: 'share',
  SEO: 'seo',
  WIDGET: 'widget',
  BRIEFING: 'briefing',
  FLIP_COMPOSE: 'flip_compose',
  OWN_PROFILE: 'own_profile',
  OTHER_PROFILE: 'other_profile',
  FIRST_LAUNCH: 'firstlaunch',
  SETTINGS: 'settings',
  TOC: 'toc',
  BACKTRACKING: 'backtracking',
  PROFILE_SECTION_TILE: 'profile_tile', // Used in Profile view, on section tiles
  ANALYTICS_HOME: 'analytics_home',
  CURATOR_PRO_MORE_BUTTON: 'package_editing_more_button', // Used in Curator Pro "3 dot" menu in footer controls
  CURATOR_PRO_MAGAZINE_COPY_CONTRIBUTOR_LINK: 'curator_pro_copy_link',
  CREATE_MAGAZINE: 'create_magazine',
  NAV_BAR_EDU_CTA: 'nav_bar_edu_cta', // Used in the Signup EDU CTA
  SUBTAB: 'subtab',
  PERSONALIZE_SHEET: 'personalize_sheet',
  FOR_YOU_HEADER: 'for_you_header',
  FAST_FLIP: 'fast_flip',
  EMAIL_CONFIRMATION: 'email_confirmation',
  INVITATION: 'invitation',
  MAGAZINE_HEADER: 'magazine_header',
};

export const USAGE_NAV_FROM_OVERRIDES = [
  USAGE_NAV_FROMS.SAMSUNG_DAILY,
  USAGE_NAV_FROMS.SAMSUNG_DAILY_NEWS_CARD,
  USAGE_NAV_FROMS.BRIEFING,
  USAGE_NAV_FROMS.SHARE,
  USAGE_NAV_FROMS.SEO,
  USAGE_NAV_FROMS.WIDGET,
];

export const USAGE_APP_ENTER_TYPES = {
  SIGN_UP: 'signup',
  SIGN_IN: 'signin',
  REQUEST_LOGIN_LINK: 'request_login_link',
  LOGIN_WITH_LINK: 'login_with_link',
  PUBLISHER_SIGNUP: 'publisher_signup',
  CATEGORY_SELECTOR: 'category_selector',
  INTEREST_SELECTOR: 'interest_selector',
  VIDEO_PLAYER_IMPRESSION: 'video_player_impression',
  VIDEO_PLAYER_VIDEO_SELECTED: 'video_player_video_selected',
  FRICTIONLESS_HOMEPAGE_SIGNUP: 'frictionless_homepage_signup',
  FRICTIONLESS_NEWSLETTER_SIGNUP: 'frictionless_newsletter_signup',
  FRICTIONLESS_NEWSLETTERS_SIGNUP: 'frictionless_newsletters_signup',
  FRICTIONLESS_TOPIC_SIGNUP: 'frictionless_topic_signup',
  FRICTIONLESS_INVITE_SIGNUP: 'frictionless_invite_signup',
  FRICTIONLESS_MAIN_SIGNUP: 'frictionless_main_signup',
  FRICTIONLESS_ACTION_GATE_COMMENTS_SIGNUP:
    'frictionless_action_gate_comments_signup',
  FRICTIONLESS_ACTION_GATE_LIKE_SIGNUP: 'frictionless_action_gate_like_signup',
  FRICTIONLESS_ACTION_GATE_FLIP_SIGNUP: 'frictionless_action_gate_flip_signup',
  FRICTIONLESS_ACTION_GATE_ACCEPT_INVITE_SIGNUP:
    'frictionless_action_gate_accept_invite_signup',
  FRICTIONLESS_ACTION_GATE_FOLLOW_SIGNUP:
    'frictionless_action_gate_follow_signup',
  FRICTIONLESS_ACTION_GATE_FOLLOW_TOPIC_SIGNUP:
    'frictionless_action_gate_follow_topic_signup',
  FRICTIONLESS_ACTION_GATE_SIGNUP: 'frictionless_action_gate_signup',
  FRICTIONLESS_ACTION_GATE_FAVORITE_SIGNUP:
    'frictionless_action_gate_add_favorite_signup',
};

export const USAGE_DISPLAY_STYLES = {
  NAV_BAR: 'nav_bar',
  NAV_BAR_EDU_CTA: 'nav_bar_edu_cta',
  CONTENT_GATE_CTA: 'content_gate_cta',
  SOCIAL_ACTION_GATE_CTA: 'social_action_gate_cta',
  BRAND_BANNER_CTA: 'brand_banner_cta',
  CTA_BANNER: 'cta_banner',
  MOBILE_CTA_GATE: 'mobile_cta_gate',
  TOPIC_PICKER_CTA: 'sticky_topic_picker',
};

export const USAGE_GENERAL_DISPLAY_TYPES = {
  NAV_BAR_EDU_CTA: 'nav_bar_edu_cta',
  MOBILE_CTA_GATE: 'mobile_cta_gate',
  TOPIC_PICKER_CTA: 'sticky_topic_picker',
};

export const USAGE_REACHED_END_TYPES = {
  ITEM: 'item',
  SECTION: 'section',
  LOGGED_OUT_HOME: 'logged_out_home',
};

export const USAGE_MOBILE_GATE_EXIT_METHODS = {
  DISMISS: 'dismiss',
  OPEN_IN_APP: 'open_in_app',
};

export const USAGE_FOLLOWING_METHODS = {
  PERSONALIZE_FOR_YOU: 'auth/flipboard/coverstories',
};

export const USAGE_SIGNUP_METHODS = {
  INVITATION: 'invitation',
  HOME: 'home',
  EMAIL_CONFIRMATION: 'email_confirmation',
};

export const USAGE_MOBILE_GATE_TYPES = {
  SOCIAL_GATE_OVERLAY: 'social_gate_overlay',
  FRICTIONLESS_FLIP_SOCIAL_GATE_OVERLAY: 'frictionless_flip_gate_overlay',
  FRICTIONLESS_LIKE_SOCIAL_GATE_OVERLAY: 'frictionless_like_gate_overlay',
  FRICTIONLESS_ACCEPT_INVITE_SOCIAL_GATE_OVERLAY:
    'frictionless_accept_invite_gate_overlay',
  FRICTIONLESS_FOLLOW_SOCIAL_GATE_OVERLAY: 'frictionless_follow_gate_overlay',
  FRICTIONLESS_FOLLOW_TOPIC_SOCIAL_GATE_OVERLAY:
    'frictionless_follow_topic_gate_overlay',
  FRICTIONLESS_PERSONALIZE_SOCIAL_GATE_OVERLAY:
    'frictionless_personalize_gate_overlay',
  FRICTIONLESS_COMMENTS_SOCIAL_GATE_OVERLAY:
    'frictionless_comments_gate_overlay',
};
/**
 * Determines the section_id value for events
 * @param {Object} section - Projected section
 * @return {String} - The section_id value for a
 * usage event
 */
export const usageSectionIdForTracking = (section = {}) => {
  let idForTracking = section.remoteid;

  if (section.ssid && section.ssid.remoteidPlain) {
    idForTracking = section.ssid.remoteidPlain;
  }

  return idForTracking && idForTracking.replace(/^auth\//, '');
};

/**
 * Generates common data used in item events
 * @param {Object} section    - Projected section object that the item is
 *                              being shown in
 * @param {Object} item       - Projected item object
 * @param {Object} data       - (optional) An object to decorate
 * @param {Object} profile    - (optional) Projected profile of the currently logged-in user
 * @return {Object}           - The event data object
 */
export const usageItemEventData = (section, item, data = {}, profile) => {
  data.item_id = item.id;

  if (item.isSection && item.section) {
    // When entering a section via a gateway in a feed
    data.item_type = item.section.feedType || FALLBACK_SECTION_FEED_TYPE;
    data.method = usageSectionIdForTracking(item.section);
  } else {
    data.item_type = item.type;
  }

  const { partnerID, sourceURL } = item;
  if (sourceURL) {
    data.url = sourceURL;
  }
  if (partnerID) {
    data.item_partner_id = partnerID;
  }
  if (item.piped) {
    data.piped = true;
  }

  const magazineAttribution = Attribution.getMagazine(item);
  if (magazineAttribution?.remoteid) {
    data.magazine_id = magazineAttribution.remoteid;
  }

  if (item.additionalUsage && Object.keys(item.additionalUsage).length > 0) {
    data.server_properties = item.additionalUsage;
  }

  return usageSectionEventData(section, data, profile);
};

/**
 * Determines the ssid value for events
 * @param {Object} section - Projected section
 * @return {String} - The section_id value for a
 * usage event
 */
export const usageSsidForTracking = (section = {}) => {
  if (section.ssid && section.ssid.remoteidPlain) {
    return section.remoteid;
  }

  return null;
};

/**
 * Generates common data used in section events
 * @param {Object} section    - Projected section object
 * @param {Object} data       - (optional) An object to decorate
 * @param {Object} profile    - (optional) Projected profile of the currently logged-in user
 * @return {Object}           - The event data object
 */
export const usageSectionEventData = (
  section,
  eventData = {},
  profile = null,
) => {
  if (!section) {
    return eventData;
  }

  eventData.partner_id = section.partnerId;
  eventData.type = section.feedType || FALLBACK_SECTION_FEED_TYPE;

  const ssid = usageSsidForTracking(section);
  if (ssid) {
    eventData.ssid = ssid;
  }

  const sectionId = usageSectionIdForTracking(section);
  if (sectionId) {
    eventData.section_id = sectionId;
  }

  if (profile) {
    eventData.number_items = usageProfileMagazineCount(profile);
  }

  return eventData;
};

export const magazineInviteContributorEventData = (
  section,
  invite,
  options,
) => ({
  ...usageSectionEventData(section),
  magazine_id: invite.magazineTarget,
  url: invite.inviteToken,
  ...options,
});

/**
 * Determines the number of magazines for the purposes of populating the 'number_items'
 * event data property.
 * @param {Object} profile    - (optional) Projected profile of the currently logged-in user
 * @return {Number}           - The total of owned and contributed magazines for a profile
 */
export const usageProfileMagazineCount = (profile) => {
  const { magazines, contributorMagazines } = profile;
  const ownMagazineCount = (magazines && magazines.length) || 0;
  const contributorMagazinesCount =
    (contributorMagazines && contributorMagazines.length) || 0;
  return ownMagazineCount + contributorMagazinesCount;
};

/**
 * Generates common data used in section widget events
 * @param {Object} section      - Projected section being shown in the widget
 * @param {String} url          - URL determined by browser
 * @param {String} layout       - The layout of the widget (portrait, thumbnail, banner)
 * @return {Object}             - The event data object
 */
export const usageWidgetEventData = (section, url, layout = null) => {
  const { remoteid } = section;

  const widgetSpecificData = {
    web_nonce_section_id: `__${getWindow().btoa(remoteid)}`,
    url,
    partner_id: '',
  };
  if (layout) {
    widgetSpecificData.display_style = layout;
  }
  return usageSectionEventData(section, widgetSpecificData);
};

/**
 * Determines the "enter" event name for an item
 * @param {Object} item     - Projected item
 * @return {String}         - Either SECTION_ENTER or ITEM_ENTER
 */
export const usageItemEnterEventName = (item) => {
  const { SECTION_ENTER, ITEM_ENTER } = USAGE_EVENT_NAMES;
  return item.isSection ? SECTION_ENTER : ITEM_ENTER;
};

/**
 * Generates common data used in section events for following the author of
 * a viewed section (typically shown in
 * the section view header)
 * See https://flwiki.flipboard.com/display/DATA/Web+usage+event#Webusageevent-Event(CORE)-EnterItem(enter,item)
 * @param {Object} section            - Projected section for the section being viewed
 * @param {Object} sectionAuthor      - Projected section object for the author being followed
 * @return {Object}   - The event data object
 */
export const usageSectionAuthorEventData = (
  section,
  sectionAuthor,
  data = {},
) => {
  if (!section || !sectionAuthor) {
    return data;
  }

  const eventData = usageSectionEventData(sectionAuthor, data);

  if (section.ssid && section.ssid.remoteidPlain) {
    eventData.method = decodeURIComponent(section.ssid.remoteidPlain);
  } else {
    eventData.method = section.remoteid;
  }

  return eventData;
};

export const usageCuratorProStoryboardPublishData = (
  sectionId,
  url,
  itemCount,
  tagCount,
  previousStoryboardCount,
) => {
  const data = {
    section_id: sectionId,
    url,
    number_items: itemCount,
    tap_count: tagCount,
  };
  if (previousStoryboardCount) {
    data.view_count = previousStoryboardCount;
  }
  return data;
};

/**
 * Decorates event data for activation events
 * @param {Object} data - Initial data to decorate
 * @return {Object}     - Decorated object with common activation event data
 */
export const usageActivationEventData = (eventData = {}) => {
  // landing URL
  const eventUrl = clientGetCookieValue(UE_ACTIVATION_LANDING_URL_COOKIE_NAME);
  if (eventUrl) {
    eventData.event_url = eventUrl;
  }

  // initial Adust deep link
  const deepLink = clientGetCookieValue(
    UE_ACTIVATION_ADJUST_INITIAL_DEEP_LINK_COOKIE_NAME,
  );
  if (deepLink) {
    eventData.url = deepLink;
  }

  // landing sectionId
  const activationSectionId = clientGetCookieValue(
    UE_ACTIVATION_SECTION_ID_COOKIE_NAME,
  );
  if (activationSectionId) {
    eventData.section_id = activationSectionId;
  }

  // override method propety if eventData.method is declared
  if (!eventData.method) {
    // "from"
    const from = clientGetCookieValue(UE_ACTIVATION_FROM_COOKIE_NAME);
    if (from) {
      eventData.method = from;
    }
  }

  // "refer_url"
  const activationReferrer = clientGetCookieValue(
    UE_ACTIVATION_REFERRER_COOKIE_NAME,
  );
  if (activationReferrer) {
    eventData.refer_url = activationReferrer;
  } else {
    // ensure that the referrer from the current request is not sent
    // due to an error writing the cookie
    delete eventData.refer_url;
  }

  return eventData;
};

// Create a usage session from scratch or refresh existing if session_id expired
export const requestUsageSession = (existingSession = null) => {
  const sessionUrl = `${Config.USAGE_URL}/setcookie`;
  const requestOptions = {};
  if (existingSession !== null) {
    const { unique_id, session_id, session_expired } = existingSession;
    if (typeof unique_id !== 'undefined' && typeof session_id !== 'undefined') {
      requestOptions.params = {
        unique_id,
      };

      // omit the session_id if the session is expired
      if (session_expired === false) {
        requestOptions.params.session_id = session_id;
      }
    }
  }

  return flaxios
    .get(sessionUrl, requestOptions)
    .then((response) => {
      if (
        typeof response === 'undefined' ||
        response === null ||
        Object.keys(response).length === 0
      ) {
        const error = new Error('Invalid usage event session response.');
        sentry.captureException(error, {
          usageSessionRequestUrl: requestOptions.url,
          usageSessionRequestParams: requestOptions.params,
          usageSessionResponse: response,
        });
      }

      const { data } = response;
      const { unique_id, session_id } = data;

      if (!unique_id) {
        throw new Error('Missing unique_id in usage event session response.');
      }

      if (session_id === 'session_invalid_error') {
        throw new Error('Invalid usage event session');
      }

      return data;
    })
    .catch((error) => {
      sentry.captureException(error, {
        usageSessionRequestUrl: sessionUrl,
        usageSessionRequestParams: requestOptions.params,
      });
      logger.error(error);
    });
};

/**
 * Emits a usage event
 * @param {Object} event - Usage event
 * @param {Object} customErrorType - Custom error type to emit if an exception occurs
 */
export const sendUsageEvent = async (
  event,
  customErrorType = null,
  defaultProdType = USAGE_PROD_TYPES.WEB,
) => {
  const prodType = event.prodType || defaultProdType;
  const options = {
    params: {
      data: JSON.stringify([event]),
      prod_type: prodType,
    },
    timeout: 17000,
  };

  const usageUrl = `${Config.USAGE_URL}/usage`;
  try {
    const result = await flaxios.get(usageUrl, options);
    return result;
  } catch (e) {
    logger.error('Failed to send usage event');
    sentry.captureException(e, {
      usageEventRequestUrl: usageUrl,
      usageEventRequestParams: options.params,
    });

    if (customErrorType) {
      sentry.captureException(new customErrorType());
    }
  }
};

export const clientGetUsageSession = () => {
  try {
    return JSON.parse(duc(clientGetCookieValue(USAGE_SESSION_COOKIE_NAME)));
  } catch (_) {
    return null;
  }
};

export const clientSetUsageSession = (session) => {
  try {
    clientSetCookieValue(USAGE_SESSION_COOKIE_NAME, JSON.stringify(session));
  } catch (_) {
    return null;
  }
};

export const serverGetUsageSession = (req) => () => {
  try {
    return JSON.parse(req.cookies[USAGE_SESSION_COOKIE_NAME]);
  } catch (_) {
    return null;
  }
};

/**
 * @param {Object} pendingSubscriptionGroups - HOC withFrictionlessSignup's state pendingSubscriptionGroups
 * Returns subscriptions keys that are subscribed with the format of
 * groupid-subscriptionkey
 @example : 'recs-Polit,recs-Climate,recs-PhotoDesk'
 @returns {String}  *
 */

export const getSubscribedKeys = (pendingSubscriptionGroups) =>
  pendingSubscriptionGroups
    .find((x) => x.group === 'recs')
    .subscriptions.filter((subscription) => subscription.isSubscribed)
    .map((s) => `recs-${s.key}`)
    .join(',');
