import React from 'react';
import classNames from 'classnames';
import Config from 'Config';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { TEXT_COLORS, SURFACE_COLORS } from 'Style/colors';
import { UI_TEXT_TYPES } from 'Style/typography';

// Utils
import { USAGE_NAV_FROMS } from 'Utils/analytics/usage';
import { truncate } from 'Style/truncate';

// Components
import { NavFromContext } from 'ComponentLibrary/context';

import WebLink from 'Webapp/shared/app/components/web-link';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectResponsive, {
  ConnectResponsiveProps,
} from 'Webapp/shared/app/connectors/connectResponsive';
import connectFlipMode, {
  ConnectFlipModeProps,
} from 'Webapp/shared/app/connectors/connectFlipMode';
import { BreadcrumbsContainerProps } from 'Webapp/shared/app/containers/navigation/breadcrumbs';

const StyledBreadcrumbsHeader = styled.header({
  backgroundColor: SURFACE_COLORS.primaryReverse,
});

const StyledBreadCrumbsNav = styled.nav({
  padding: `${SPACING.MEDIUM} ${SPACING.LARGE}`,
  margin: '0',
});

const StyledBreadCrumbsItemsULContainer = styled.ul({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
  overflow: 'hidden',
  '> .breadcrumbs__items:first-of-type': {
    borderRight: `1px solid ${TEXT_COLORS.overlay}`,
  },
  span: {
    whiteSpace: 'break-spaces',
    ...truncate('auto', 1),
    maxWidth: '850px',
  },
});

const StyledBreadCrumbsLabel = styled(WebLink)({
  color: TEXT_COLORS.overlay,
});

const StyledBreadCrumbsItems = styled.li({
  display: 'inline-block',
  whiteSpace: 'nowrap',
  color: TEXT_COLORS.overlay,

  '&:hover': {
    color: TEXT_COLORS.overlay,
  },

  '&:not(:first-of-type)': {
    '&:before': {
      content: '"/"',
      color: TEXT_COLORS.secondaryReverse,
      display: 'inline-block',
      marginRight: `${SPACING.MEDIUM}`,
    },
  },

  '&:not(:last-child)': {
    ...UI_TEXT_TYPES.TITLE_SMALL,
    marginRight: `${SPACING.MEDIUM}`,
    color: TEXT_COLORS.secondaryReverse,
    a: { color: 'inherit' },
  },

  '&:last-child': {
    display: 'flex',
  },
});

type BreadcrumbsPassedProp = {
  isFlipModeEnabled: boolean;
};

type BreadcrumbsProps = ConnectFlipModeProps &
  ConnectResponsiveProps &
  BreadcrumbsContainerProps &
  BreadcrumbsPassedProp & {
    t: Flipboard.TFunction;
  };

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  isPhone,
  t,
  breadcrumbs,
}) => {
  if (!breadcrumbs || !breadcrumbs.length) {
    return null;
  }

  const homeItem = (
    <StyledBreadCrumbsItems key="home" className="breadcrumbs__item link">
      <WebLink href="/">{t('home')}</WebLink>
    </StyledBreadCrumbsItems>
  );
  const breadcrumbItems = breadcrumbs.map((crumb, i) => {
    const isLast = i === breadcrumbs.length - 1;
    const classes = classNames('breadcrumbs__item', {
      link: !isLast,
    });

    // omit leaf node on phones
    if (isPhone && isLast) {
      return null;
    }

    const href = crumb.item.replace(Config.FLIPBOARD_URL, '');
    return (
      <StyledBreadCrumbsItems key={`breadcrumb-${i}`} className={classes}>
        <StyledBreadCrumbsLabel
          className="breadcrumbs__label"
          href={href}
          noSameUrlLinks
        >
          {crumb.name}
        </StyledBreadCrumbsLabel>
      </StyledBreadCrumbsItems>
    );
  });
  const items = [homeItem, ...breadcrumbItems];

  return (
    <NavFromContext.Provider value={USAGE_NAV_FROMS.NAV_BAR}>
      <StyledBreadcrumbsHeader className="breadcrumbs__header">
        <StyledBreadCrumbsNav className="breadcrumbs">
          <div className="breadcrumbs__items-wrapper">
            <StyledBreadCrumbsItemsULContainer className="breadcrumbs__items">
              {items}
            </StyledBreadCrumbsItemsULContainer>
          </div>
        </StyledBreadCrumbsNav>
      </StyledBreadcrumbsHeader>
    </NavFromContext.Provider>
  );
};

export default connector(
  connectResponsive,
  connectFlipMode,
)(withT(Breadcrumbs));
