import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

import { usageTrackOnboardAppStartAuthenticated } from 'Webapp/shared/app/redux/actions/usage-actions';

const trackOnboardAppStartAuthenticated: OnboardingStep =
  (nextStep) => (dispatch) => {
    dispatch(usageTrackOnboardAppStartAuthenticated());
    nextStep();
  };

export default trackOnboardAppStartAuthenticated;
