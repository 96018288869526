import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

import { onboardingDataSelector } from 'Webapp/shared/concepts/onboarding';

import { USAGE_NAV_FROMS } from 'Utils/analytics/usage';
import { AcceptMagazineInviteError } from 'Utils/errors';

// Actions
import { followSections } from 'Webapp/shared/app/redux/actions/profile-actions';

const followMagazine: OnboardingStep =
  (nextStep) => async (dispatch, getState) => {
    const onboardingData = onboardingDataSelector(getState());
    if (!onboardingData?.magazineInviteSection) {
      throw new AcceptMagazineInviteError(
        'Accept Magazine Invite Flow is missing magazineInviteSection',
      );
    }
    const section = onboardingData.magazineInviteSection;
    await dispatch(followSections([section], USAGE_NAV_FROMS.FEED));
    nextStep();
  };

export default followMagazine;
