import { NOTIFICATION_TYPES } from 'Webapp/action-types';
import { set, setPayloadReducer } from 'Utils/redux';
import { currentUserUid } from 'Webapp/shared/app/redux/selectors/auth';
import updateFeed from 'Webapp/utils/api/flap/endpoints/update-feed';
import { isFlapNotificationItem } from 'Webapp/predicates';
import { setCookieValue, getCookieValue } from 'Webapp/utils/client-cookie';
import uniqBy from 'lodash/uniqBy';
import GlobalVars from 'Utils/global-vars';
import { NotificationGroupType } from 'Webapp/enums';
import { MAX_UPDATE_FEED_LIMIT } from 'Utils/api/flap/index.js';
import { projection as itemProjection } from 'Utils/content/item-util';
import SectionUtil from 'Utils/content/section-util';

const NOTIFICATION_SECTION_ID =
  'auth/flipboard/curator%2Fnotifications%2Fgroup';
const SECTION_ID_NOTIFICATIONS_SOCIAL =
  'auth/flipboard/curator/notifications/tab/activity';
const SECTION_ID_NOTIFICATIONS_CONTENT =
  'auth/flipboard/curator/notifications/tab/content';

export interface NotificationsReducerState {
  loading: boolean;
  loaded: boolean;
  markedAsReadAt: number;
  pageKey?: Flipboard.NextPageKey;
  entries: Array<Flipboard.Notification>;
  unreadCount: number;
  filterGroup: NotificationGroupType | null;
}

const getCookieMarkedAsReadAt = () => {
  if (GlobalVars.isServer()) {
    return 0;
  }
  const cookieValue = getCookieValue('markedAsReadAt');
  if (cookieValue) {
    return parseInt(cookieValue, 10);
  }
  return 0;
};

const initialState: NotificationsReducerState = {
  entries: [],
  loading: false,
  loaded: false,
  markedAsReadAt: 0,
  unreadCount: 0,
  filterGroup: null,
};

export const reducer = setPayloadReducer<
  typeof NOTIFICATION_TYPES,
  NotificationsReducerState
>(NOTIFICATION_TYPES, initialState);

export const markNotificationsRead =
  (): Flipboard.Thunk =>
  async (dispatch, getState, { flap }) => {
    const {
      notifications: { entries },
    } = getState();
    const firstEntryId = entries[0]?.id;
    if (!firstEntryId) {
      // we have to send at least one oid, if we do it will mark all
      // as read, if we don't it won't mark any as read.  Passing
      // more than one has no additional effect.
      return;
    }
    await flap.post('social/read', {
      sectionid: NOTIFICATION_SECTION_ID,
      oid: firstEntryId,
      service: 'flipboard',
    });

    const markedAsReadAt = new Date().getTime();
    setCookieValue('markedAsReadAt', markedAsReadAt.toString());

    dispatch({
      type: NOTIFICATION_TYPES.SET_NOTIFICATIONS_READ,
      payload: {
        unreadCount: 0,
        markedAsReadAt,
      },
    });
  };

export const markNotificationEntriesRead =
  (): Flipboard.Thunk => async (dispatch, getState) => {
    const {
      notifications: { entries },
    } = getState();
    dispatch({
      type: NOTIFICATION_TYPES.SET_NOTIFICATIONS_READ,
      payload: {
        entries: entries.map((entry) => ({
          ...entry,
          read: true,
        })),
      },
    });
  };

export const getUnreadCount =
  (): Flipboard.Thunk<Promise<number>> =>
  async (dispatch, _getState, { flap }) => {
    const {
      data: { success, unreadcounts: unreadCounts },
    } = await flap.get<{
      success: boolean;
      unreadcounts: Record<string, number>;
    }>('/social/unreadCount', {
      params: {
        service: 'flipboard',
      },
    });
    const unreadCount =
      success === true ? unreadCounts[NOTIFICATION_SECTION_ID] || 0 : 0;
    dispatch(
      set(
        NOTIFICATION_TYPES.SET_NOTIFICATIONS_UNREAD_COUNT,
        'unreadCount',
        unreadCount,
      ),
    );
    return unreadCount;
  };

const getNotificationsSectionId = (
  filterGroup?: NotificationGroupType | null,
) => {
  switch (filterGroup) {
    case NotificationGroupType.SOCIAL:
      return SECTION_ID_NOTIFICATIONS_SOCIAL;
    case NotificationGroupType.CONTENT:
      return SECTION_ID_NOTIFICATIONS_CONTENT;
    default:
      return NOTIFICATION_SECTION_ID;
  }
};

export const clearNotifications = () =>
  set(NOTIFICATION_TYPES.GET_NOTIFICATIONS, 'entries', []);

export const setNotificationsFilterGroup = (
  filterGroup: NotificationGroupType | null,
) =>
  set(NOTIFICATION_TYPES.SET_NOTIFICATIONS_GROUP, 'filterGroup', filterGroup);

const convertFlapNotificationItemToNotification = ({
  id,
  userid,
  text,
  dateCreated,
  notificationType,
  authorDescription,
  authorDisplayName,
  authorImage,
  authorUsername,
  sectionLinks,
  referredByItems,
  sectionID,
  contentImage,
  actionURL,
  service,
}: Flipboard.FlapNotificationItem): Flipboard.Notification => {
  const projectedSectionLinks = sectionLinks?.map(
    (s) => SectionUtil.projection(s) as Flipboard.FlapSectionLink,
  );

  const projectedReferredByItems = referredByItems?.map(
    (item) =>
      itemProjection(
        item,
        projectedSectionLinks?.[0] || null,
        true,
      ) as Flipboard.Item,
  );
  const image = projectedReferredByItems?.[0].image || contentImage;
  return {
    id,
    actionURL,
    text,
    dateCreated,
    notificationType,
    author: {
      authorDescription,
      authorDisplayName,
      authorImage,
      authorUsername,
      userid,
      service,
      username: authorUsername,
    },
    sectionLinks: projectedSectionLinks,
    referredByItems: projectedReferredByItems,
    read: true,
    sectionID,
    image,
  };
};

export const getNotifications =
  (
    groupType?: NotificationGroupType | null,
    pageKey?: Flipboard.NextPageKey,
  ): Flipboard.Thunk<Promise<void>> =>
  async (dispatch, getState, { flap }) => {
    dispatch({
      type: NOTIFICATION_TYPES.GET_NOTIFICATIONS,
      payload: {
        loading: true,
      },
    });

    const response = await updateFeed(flap, currentUserUid(getState()), {
      sections: getNotificationsSectionId(groupType) as Flipboard.SectionId,
      refresh: 'yes',
      limit: MAX_UPDATE_FEED_LIMIT,
      ...(pageKey && { pageKey }),
    });
    const {
      notifications: {
        entries,
        unreadCount,
        markedAsReadAt: storeMarkedAsReadAt,
      },
    } = getState();
    const markedAsReadAt = storeMarkedAsReadAt || getCookieMarkedAsReadAt();
    const notifications = response.data.stream.filter(isFlapNotificationItem);

    // TODO: Mostly just dumped the entire response here, but we should clean it up
    // and make it more sensible.
    dispatch({
      type: NOTIFICATION_TYPES.GET_NOTIFICATIONS_SUCCESS,
      payload: {
        loading: false,
        entries: uniqBy<Flipboard.Notification>(
          entries.concat(
            notifications.map((n, i) => ({
              ...convertFlapNotificationItemToNotification(n),
              read:
                markedAsReadAt > 0
                  ? n.dateCreated < markedAsReadAt / 1000
                  : unreadCount <= i,
            })),
          ),
          (i) => i.id,
        ).sort((a, b) => {
          if (a.dateCreated < b.dateCreated) {
            return 1;
          }
          return -1;
        }),
      },
    });
  };
