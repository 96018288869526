import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setAppThemeOverride, refreshBanners } from '../../redux/actions/app';
import FlapUtil from 'Utils/content/flap-util';
import { getSectionId } from 'Utils/content/flipboard-urls';
import MainNavbar from '../../components/navigation/main-navbar';
import { usageTrackDisplayOpenInAppButton } from 'Webapp/shared/app/redux/actions/usage-actions';

const mapStateToProps = ({ app, auth, sections }) => {
  const remoteid = FlapUtil.getRemoteidByUserid(auth.uid);
  const profileSection = FlapUtil.getSectionByRemoteId(
    remoteid,
    sections.entries,
  );
  const sectionId = getSectionId(app.routing.params);
  const currentSection = sectionId
    ? FlapUtil.getSectionByRemoteId(sectionId, sections.entries)
    : null;

  return {
    hideMainNavCTAs: app.hideMainNavCTAs,
    currentSection,
    profileSection,
    navAppStoreUrl: app.navAppStoreUrl,
    googleAdVisibility: app.googleAdVisibility,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { refreshBanners, setAppThemeOverride, usageTrackDisplayOpenInAppButton },
    dispatch,
  );

const MainNavbarContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainNavbar);

export default MainNavbarContainer;
