import styled from '@emotion/styled';
import React from 'react';
import Icon from './icon';

const StyledIcon = styled(Icon)({
  cursor: 'pointer',
});

function CloseIcon(props) {
  return (
    <StyledIcon
      name="close"
      size={22}
      viewBox="0 0 22 22"
      {...props}
      renderComponent={({ color }) => (
        <g fill={color}>
          <g transform="translate(11.000000, 11.000000) rotate(-45.000000) translate(-11.000000, -11.000000) translate(1.000000, 1.000000)">
            <rect x={9} y={0} width={2} height={20} />
            <rect
              transform="translate(10.000000, 10.000000) rotate(-90.000000) translate(-10.000000, -10.000000)"
              x={9}
              y={0}
              width={2}
              height={20}
            />
          </g>
        </g>
      )}
    />
  );
}

export default CloseIcon;
