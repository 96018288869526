import React from 'react';

import Link from 'ComponentLibrary/navigation/link';

import splitStringToChildren from 'Utils/split-string-to-children';

import { URL_REGEXP_CAPTURE } from 'Utils/reg-exp';

const linkifyText = (string, linkProps, LinkComponent = Link) => {
  const transform = (chunk) => (
    <LinkComponent href={chunk} {...linkProps}>
      {chunk}
    </LinkComponent>
  );
  return splitStringToChildren(string, URL_REGEXP_CAPTURE, transform);
};

export default linkifyText;
