import sentry from 'Utils/sentry';
import GlobalVars from 'Utils/global-vars';

const asyncStrategy = async (strategies, ...args) => {
  for (const strategy of strategies) {
    const result = await strategy(...args);
    if (result) {
      return result;
    }
  }
  return null;
};

const syncStrategy = (strategies, ...args) => {
  for (const strategy of strategies) {
    const result = strategy(...args);
    if (result) {
      return result;
    }
  }
  return null;
};

const runStrategies =
  (strategies, options = { async: false }) =>
  (...args) => {
    try {
      if (options.async) {
        return asyncStrategy(strategies, ...args);
      }
      return syncStrategy(strategies, ...args);
    } catch (e) {
      if (GlobalVars.isTest) {
        throw e;
      }
      sentry.captureException(e);
      return null;
    }
  };

export default runStrategies;
