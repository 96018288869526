import isUnknownType from 'Webapp/shared/app/components/notifications/notification-text-click-strategies/shared/is-unknown-type';
import LinkSection from 'Webapp/shared/app/components/notifications/content/link-section';

const strategy = (notification: Flipboard.Notification) => {
  if (isUnknownType(notification)) {
    return null;
  }
  const sectionLink = notification.sectionLinks?.[0];
  if (!sectionLink) {
    return null;
  }
  return LinkSection;
};

export default strategy;
