import getWindow from 'Utils/get-window';

let alreadyRan = false;

export default () => {
  if (alreadyRan) {
    return;
  }
  alreadyRan = true;
  const { document } = getWindow();

  const script = document.createElement('script');
  script.src = '//imasdk.googleapis.com/js/sdkloader/ima3.js';
  document.body.appendChild(script);
};
