import React from 'react';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';

// Utils
import getWindow from 'Utils/get-window';

class Visible extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.test = throttle(this.test.bind(this), 250);
    this.isInViewport = this.isInViewport.bind(this);
    this.state = {
      moved: false,
    };
  }

  componentDidMount() {
    this.props.checkScroll &&
      getWindow().addEventListener('scroll', this.test, true);
    this.props.checkResize &&
      getWindow().addEventListener('resize', this.test, true);
  }

  shouldComponentUpdate() {
    // we want to yield visibility even if state / props have not
    // changed
    return true;
  }

  componentWillUnmount() {
    this.props.checkScroll &&
      getWindow().removeEventListener('scroll', this.test);
    this.props.checkResize &&
      getWindow().removeEventListener('resize', this.test);
    this.unmounted = true;
  }

  isInViewport() {
    const bounding = this.ref.current.getBoundingClientRect();
    const { bufferTop, bufferBottom } = this.props;
    return (
      bounding.top + bufferTop >= 0 &&
      bounding.bottom - bufferBottom <=
        (getWindow().innerHeight ||
          getWindow().document.documentElement.clientHeight)
    );
  }

  test(moved = true) {
    if (moved && !this.unmounted) {
      this.setState({ moved: true });
    }
    if (this.ref.current) {
      if (this.props.waitUntilMoved && this.state.moved === false) {
        return;
      }
      this.props.children(this.isInViewport());
    }
  }

  render() {
    this.test(false);
    return <div ref={this.ref} />;
  }
}

Visible.defaultProps = {
  bufferTop: 0,
  bufferBottom: 0,
  checkScroll: true,
  checkResize: true,
  waitUntilMoved: false,
};

Visible.propTypes = {
  bufferTop: PropTypes.number,
  bufferBottom: PropTypes.number,
  checkScroll: PropTypes.bool,
  checkResize: PropTypes.bool,
  children: PropTypes.func.isRequired,
  waitUntilMoved: PropTypes.bool.isRequired,
};

export default Visible;
