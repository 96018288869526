/* eslint-disable react/no-find-dom-node */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropValidator from 'Utils/react-prop-validator';
import classnames from 'classnames';
import { Portal } from 'react-portal';

// Utils
import getWindow from 'Utils/get-window';
import WindowResize from 'Utils/window-resize';
import { WindowScroll } from 'Utils/window-scroll';

// Components
import UnstyledButton from 'Webapp/shared/app/components/unstyled-button';
import CloseIcon from 'ComponentLibrary/icons/close';

class AnchoredDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      positionXOverride: null,
      positionYOverride: null,
      isLessLikeAccordionOpen: false,
    };
    this.handleViewportChange = this.handleViewportChange.bind(this);
    this.resizer = new WindowResize(this.handleViewportChange);
    this.scroller = new WindowScroll(this.handleViewportChange);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.getPositionStyles = this.getPositionStyles.bind(this);
    this.reposition = this.reposition.bind(this);
    this.dialogRef = React.createRef();
    this.didReposition = false;
  }

  componentDidMount() {
    this.reposition();
    this.scroller.subscribe();
    this.resizer.subscribe();

    getWindow().addEventListener('mousedown', this.handleClickOutside, true);
  }

  componentDidUpdate(_, prevState) {
    // Rects found in the reposition function are not fully
    // correct until now
    if (!this.didReposition) {
      this.didReposition = true;
      this.reposition();
    }

    // Reposition when the lesslike accordion expands/collapses
    if (
      this.state.isLessLikeAccordionOpen !== prevState.isLessLikeAccordionOpen
    ) {
      this.reposition();
    }
  }

  componentWillUnmount() {
    this.scroller.unsubscribe();
    this.resizer.unsubscribe();
    getWindow().removeEventListener('mousedown', this.handleClickOutside, true);
  }

  handleViewportChange() {
    this.reposition();
    this.props.onResize();
  }

  reposition() {
    if (!this.dialogRef || !this.dialogRef.current) {
      return;
    }

    let update = this.getPositionOverrides();
    const positionStyles = this.getPositionStyles(update);
    update = Object.assign({}, update, { positionStyles });

    this.setState(update);
  }

  getPositionOverrides() {
    const { parentRef } = this.props;
    const update = {};

    if (
      this.props.styleVariation === AnchoredDialog.styleVariations.FULL_WIDTH
    ) {
      return update;
    }
    const bounding = (
      parentRef ? parentRef : this.dialogRef
    ).current.getBoundingClientRect();

    const x = this.state.positionXOverride || this.props.positionX;
    const y = this.state.positionYOverride || this.props.positionY;

    const arrowHeight = 4; // includes margin
    const triggerHeight = 20;
    const topModifier = arrowHeight + triggerHeight;
    const navHeight = 60;

    if (
      y === ANCHORED_DIALOG_POSITION_Y.BELOW &&
      bounding.bottom >=
        (getWindow().innerHeight ||
          getWindow().document.documentElement.clientHeight)
    ) {
      update.positionYOverride = ANCHORED_DIALOG_POSITION_Y.ABOVE;
    }
    if (
      y === ANCHORED_DIALOG_POSITION_Y.ABOVE &&
      bounding.top - bounding.height - topModifier <= navHeight
    ) {
      update.positionYOverride = ANCHORED_DIALOG_POSITION_Y.BELOW;
    }
    if (
      x === ANCHORED_DIALOG_POSITION_X.RIGHT &&
      bounding.right >=
        (getWindow().innerWidth ||
          getWindow().document.documentElement.clientWidth)
    ) {
      update.positionXOverride = ANCHORED_DIALOG_POSITION_X.LEFT;
    }
    if (x === ANCHORED_DIALOG_POSITION_X.LEFT && bounding.left <= 0) {
      update.positionXOverride = ANCHORED_DIALOG_POSITION_X.RIGHT;
    }

    return update;
  }

  getPositionStyles(update = {}) {
    const {
      targetRef,
      fixed,
      styleVariation,
      positionX,
      positionY,
      anchorEdge,
    } = this.props;
    const { positionXOverride, positionYOverride } = this.state;
    const x = update.positionXOverride
      ? update.positionXOverride
      : positionXOverride || positionX;
    const y = update.positionYOverride
      ? update.positionYOverride
      : positionYOverride || positionY;

    const positionStyles = {};
    if (
      targetRef?.current &&
      styleVariation !== AnchoredDialog.styleVariations.MENU_FULL_WIDTH
    ) {
      const rect = targetRef.current.getBoundingClientRect();

      // if positioned below and to the left
      const windowWidth = getWindow().document.documentElement.clientWidth;
      const windowHeight = getWindow().document.documentElement.clientHeight;
      const scrollAdjustment = fixed ? 0 : getWindow().scrollY;
      const rectHalfWidth = rect.width / 2;
      const rectHalfHeight = rect.height / 2;

      const anchorShift = !anchorEdge ? rectHalfWidth : 20;
      const arrowOffset = 20;

      if (y === ANCHORED_DIALOG_POSITION_Y.BELOW) {
        positionStyles.top = rect.bottom + scrollAdjustment;
      } else if (y === ANCHORED_DIALOG_POSITION_Y.CENTER) {
        positionStyles.top = rect.bottom - rectHalfHeight + scrollAdjustment;
      } else if (y === ANCHORED_DIALOG_POSITION_Y.ABOVE) {
        positionStyles.bottom = windowHeight - rect.top - scrollAdjustment;
      }

      if (styleVariation !== AnchoredDialog.styleVariations.FULL_WIDTH) {
        if (x === ANCHORED_DIALOG_POSITION_X.LEFT) {
          positionStyles.right =
            // width of screen
            windowWidth -
            // minus right x pos of rect
            rect.right +
            // + anchor point of the target ref (default 1/2 width of rect)
            anchorShift -
            // minus distance from dialog edge to arrow center
            arrowOffset;
        } else if (x === ANCHORED_DIALOG_POSITION_X.RIGHT) {
          positionStyles.left =
            // left position of rect
            rect.left +
            // + anchor point of the target ref (default 1/2 width of rect)
            anchorShift -
            // minus distance from dialog edge to arrow center
            arrowOffset;
        }
      }
    } else if (
      styleVariation === AnchoredDialog.styleVariations.MENU_FULL_WIDTH
    ) {
      // Applied for MENU_FULL_WIDTH style variation only
      // TODO: Would be nice to refactor to make this more general
      // (and not look for a specific component to anchor to)
      const parent = targetRef.current.parentElement;
      const articleNode = targetRef.current.closest('article');
      const parentRect = parent?.getBoundingClientRect();
      const articleRect = articleNode?.getBoundingClientRect();
      const scrollAdjustment = fixed ? 0 : getWindow().scrollY;
      positionStyles.left =
        (articleRect ? articleRect.left : parentRect.left) + 24;
      positionStyles.top = parentRect.bottom + scrollAdjustment;
      positionStyles.width = `${
        (articleRect ? articleRect.width : parentRect.width) - 32
      }px`;

      update.positionYOverride = ANCHORED_DIALOG_POSITION_Y.BELOW;
    }

    return positionStyles;
  }

  /**
   *
   * @param {*} target object in the dom.
   * @returns the z-index of the target object. If there is no specified z-index, reccursively
   * search upwards in the dom tree, till we find something with a specified z-index. An Element
   * has node type 1. ie: search upwards till we find z-index or the <html>
   */
  getZIndex(target) {
    var z = getWindow().getComputedStyle(target).getPropertyValue('z-index');

    // Climb the tree until we have a specified zIndex or run out of elements (node type 1)
    if (isNaN(z) && target.parentNode.nodeType === 1) {
      return this.getZIndex(target.parentNode);
    }
    return z;
  }

  handleClickOutside(e) {
    const { target } = e;
    const clickedZIndex = this.getZIndex(target);
    const dialogZIndex = this.getZIndex(this.dialogRef.current);

    /**
     * Compare the closest (searching upwards in tree) specified zIndex of the click target
     * with the zIndex of the anchored dialog. Our anchored dialog should have a specified
     * zIndex. The other will either be "auto" or specified (in the case of a modal).
     * If we've clicked on an element that has a higher zIndex (ie: modal on top of anchored dialog),
     * disregard this as a click-outside. If it's lower, the anchored dialog should be visible,
     * and we can proceed with dismissal.
     */
    if (
      this.dialogRef &&
      !this.dialogRef.current.contains(target) &&
      (isNaN(clickedZIndex) || clickedZIndex <= dialogZIndex)
    ) {
      this.props.onClickOutside();
    }
  }
  render() {
    const {
      className,
      id,
      fixed,
      styleVariation,
      styleModifiers,
      positionX,
      positionY,
      children,
      showCloseButton,
      onDismiss,
      onClick,
    } = this.props;

    const x = this.state.positionXOverride || positionX;
    const y = this.state.positionYOverride || positionY;

    const anchoredDialogClassNames = classnames({
      'anchored-dialog': true,
      'anchored-dialog--fixed': fixed,
      'anchored-dialog--tooltip':
        styleVariation === AnchoredDialog.styleVariations.TOOLTIP,
      'anchored-dialog--menu':
        styleVariation === AnchoredDialog.styleVariations.MENU,
      'anchored-dialog--menu-full-width':
        styleVariation === AnchoredDialog.styleVariations.MENU_FULL_WIDTH,
      'anchored-dialog--full-width':
        styleVariation === AnchoredDialog.styleVariations.FULL_WIDTH,
      'anchored-dialog--error':
        styleVariation === AnchoredDialog.styleVariations.ERROR,
      'anchored-dialog--edu':
        styleVariation === AnchoredDialog.styleVariations.EDU,
      [className]: true,
      'anchored-dialog--align-left': x === ANCHORED_DIALOG_POSITION_X.LEFT,
      'anchored-dialog--align-right': x === ANCHORED_DIALOG_POSITION_X.RIGHT,
      'anchored-dialog--align-above': y === ANCHORED_DIALOG_POSITION_Y.ABOVE,
      'anchored-dialog--align-center': y === ANCHORED_DIALOG_POSITION_Y.CENTER,
      'anchored-dialog--align-below': y === ANCHORED_DIALOG_POSITION_Y.BELOW,
      'anchored-dialog--wide': styleModifiers.includes(
        AnchoredDialog.styleModifiers.WIDE,
      ),
    });

    return (
      <Portal>
        <div
          style={this.state.positionStyles}
          ref={this.dialogRef}
          className={anchoredDialogClassNames}
          id={id && id}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({
              isLessLikeAccordionOpen: !this.state.isLessLikeAccordionOpen,
            });
            if (onClick) {
              onClick();
            }
          }}
        >
          {showCloseButton && (
            <div className="anchored-dialog__close">
              <UnstyledButton onClick={onDismiss} name="anchored-dialog-close">
                <CloseIcon size={16} />
              </UnstyledButton>
            </div>
          )}
          {children}
        </div>
      </Portal>
    );
  }
}

const ANCHORED_DIALOG_POSITION_X = {
  RIGHT: 'right',
  LEFT: 'left',
};
const ANCHORED_DIALOG_POSITION_Y = {
  ABOVE: 'above',
  CENTER: 'center',
  BELOW: 'below',
};

AnchoredDialog.styleVariations = {
  TOOLTIP: 'hint',
  MENU: 'menu',
  MENU_FULL_WIDTH: 'menuFullWidth',
  FULL_WIDTH: 'fullWidth',
  ERROR: 'error',
  EDU: 'edu',
  NONE: 'none',
};

AnchoredDialog.styleModifiers = {
  NONE: 'none',
  WIDE: 'wide',
};

AnchoredDialog.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  targetRef: PropTypes.object.isRequired,
  parentRef: PropTypes.object,
  fixed: PropTypes.bool,
  styleVariation: PropTypes.oneOf(
    Object.values(AnchoredDialog.styleVariations),
  ),
  styleModifiers: PropTypes.arrayOf(
    PropValidator.memberOf(AnchoredDialog.styleModifiers),
  ),
  positionX: PropTypes.oneOf(Object.values(ANCHORED_DIALOG_POSITION_X)),
  positionY: PropTypes.oneOf(Object.values(ANCHORED_DIALOG_POSITION_Y)),
  anchorEdge: PropTypes.bool,
  onResize: PropTypes.func,
  onClickOutside: PropTypes.func,
  children: PropTypes.node.isRequired,
  showCloseButton: PropTypes.bool,
  onDismiss: PropTypes.func,
  onClick: PropTypes.func,
};

AnchoredDialog.defaultProps = {
  className: '',
  fixed: false,
  styleVariation: AnchoredDialog.styleVariations.NONE,
  styleModifiers: [AnchoredDialog.styleModifiers.NONE],
  positionX: ANCHORED_DIALOG_POSITION_X.RIGHT,
  positionY: ANCHORED_DIALOG_POSITION_Y.BELOW,
  anchorEdge: false,
  onResize() {},
  onClickOutside() {},
  showCloseButton: false,
  onDismiss() {},
};

export default AnchoredDialog;
export { ANCHORED_DIALOG_POSITION_X, ANCHORED_DIALOG_POSITION_Y };
