import RenderHintUtil from './render-hint-util';
import TopicDefaultFallbackImages from './content/topic-default-fallback-images';
import { safeURL } from 'Utils/url';

const flipboardCdnRegexp = /^https?:\/\/cdn\.flipboard/;

const FLAP_IMAGE_KEYS = [
  'mediumURL',
  'largeURL',
  'xlargeURL',
  'smallURL',
  'url',
  'imageURL',
];

/**
 * Replaces http with https in the image url if the url is flip.it or
 * cdn.flipboard
 * @param {string} imageUrl - Flipboard image object url
 * @return {string} - The URL of the image
 */
export const secureFlipboardImageUrl = (imageUrl) => {
  if (!imageUrl) {
    return imageUrl;
  }
  return imageUrl
    .replace(/^http:\/\/flip\.it/, 'https://flip.it')
    .replace(flipboardCdnRegexp, 'https://cdn.flipboard');
};

/**
 * Returns the URL for the flipboard cdn large image if it exists
 * @param {Object} image - Flipboard image object
 * @return {string} - The URL of the image
 */
export const flipboardCDNLargeImageURL = (image) => {
  if (image.largeURL && !!image.largeURL.match(flipboardCdnRegexp)) {
    return image.largeURL;
  }
};

/**
 * Returns the URL for the image size that is
 * appropriate for feeds
 * @param {Object} image - Flipboard image object
 * @return {string} - The URL of the image
 */
export const imageUrl = (image) => {
  if (!image) {
    return null;
  }

  let imageUrl =
    image &&
    (flipboardCDNLargeImageURL(image) ||
      image.mediumURL ||
      image.largeURL ||
      image.xlargeURL ||
      image.smallURL ||
      image.url ||
      image.imageURL);

  // If image url comes prefixed with //, without the protocol, assume it's http.
  // Not having the protocol causes issues downstream
  if (imageUrl && imageUrl.indexOf('//') === 0) {
    imageUrl = imageUrl.replace('//', 'https://');
  }
  return secureFlipboardImageUrl(imageUrl);
};

/**
 * Returns the URL for the smallest image size
 * @param {Object} image - Flipboard image object
 * @return {string} - The URL of the image
 */
export const smallestUrl = (image) =>
  image &&
  (image.smallURL ||
    image.mediumURL ||
    image.largeURL ||
    image.xlargeURL ||
    null);

/**
 * Returns the URL for the largest image size
 * @param {Object} image - Flipboard image object
 * @return {string} - The URL of the image
 */
export const largestUrl = (image) =>
  image &&
  (image.xlargeURL ||
    image.largeURL ||
    image.mediumURL ||
    image.smallURL ||
    null);

/**
 * Returns the original width of the image
 * @param {Object} image - Flipboard image object
 * @return {number} - The original width
 */
export const originalWidth = (image) => {
  if (!image) {
    return null;
  }

  return image && (image.original_width || image['original-width'] || null);
};

/**
 * Returns the original height of the image
 * @param {Object} image - Flipboard image object
 * @return {Integer} - The original height
 */
export const originalHeight = (image) => {
  if (!image) {
    return null;
  }

  return image && (image.original_height || image['original-height'] || null);
};

/**
 * Returns the width of the image, using "width",
 * "original_width", "original-width" (in that order)
 * @param {Object} image - Flipboard image object
 * @return {number} - The width
 */
export const width = (image) => image.width || originalWidth(image);

/**
 * Returns the height of the image, using "height",
 * "original_height", "original-height" (in that order)
 * @param {Object} image - Flipboard image object
 * @return {Integer} - The height
 */
export const height = (image) => image.height || originalHeight(image);

/**
 * Determines whether an image object has sensible
 * original height and width values
 * @param {Object} image - Flipboard image object
 * @return {Boolean} - true if valid dimensions
 */
export const hasValidDimensions = (image) => {
  // It appears that FLAP gives us a value of 1 if it isn't able to get a width and height value
  const imageWidth = originalWidth(image);
  const imageHeight = originalHeight(image);
  if (!imageWidth || imageWidth < 2 || !imageHeight || imageHeight < 2) {
    return false;
  }
  return true;
};

/**
 * Returns the x,y coodinates of the focus point of the image as defined in the image hints
 * @param {Object} image object
 * @return {Object} x and y coodinates
 */
export const getFocusPoint = (image) => {
  if (typeof image === 'undefined' || image === null) {
    return null;
  }

  const hints = image.hints || image.original_hints;
  const hintObj = RenderHintUtil.parseHints(hints);

  if (typeof hintObj.focus !== 'undefined') {
    return { x: hintObj.focus[0], y: hintObj.focus[1] };
  }

  const imageWidth = width(image);
  const imageHeight = height(image);

  // Could be that the image doesn't have an original width/height, in which
  // case we will later rely on the loaded width/height.
  if (
    isNaN(imageWidth) ||
    imageWidth === null ||
    isNaN(imageHeight) ||
    imageHeight === null
  ) {
    return null;
  }

  return { x: imageWidth * 0.5, y: imageHeight * 0.5 };
};

/**
 * Returns the normalized focus point (values between 0 and 1) for an image
 * @param {Object} An image object
 * @return {Object} Normalized x/y coordinates
 */
export const getFocusPointNormalized = (image) => {
  if (typeof image === 'undefined' || image === null) {
    return null;
  }

  const focusPoint = getFocusPoint(image);
  if (focusPoint === null) {
    return null;
  } // implies that width/height are defined

  const imageWidth = width(image);
  const imageHeight = height(image);

  // Could be that the image doesn't have an original width/height, in which
  // case we will later rely on the loaded width/height.
  if (
    isNaN(imageWidth) ||
    imageWidth === null ||
    isNaN(imageHeight) ||
    imageHeight === null
  ) {
    return null;
  }

  return {
    x: focusPoint.x / imageWidth,
    y: focusPoint.y / imageHeight,
  };
};

/**
 * Returns the dimensions for an image, constrained to a container
and centered on the focus point (if specified)
* @param {Object} container dimensions: { width, height }
* @param {Object} actual image dimensions: { width, height }
* @param {Object} normalized focus point (values from 0 to 1) : { x, y }
* @return {Object} dimensions and shift to apply to the rendered
*                  image: { width, height, shiftX, shiftY }
*/
export const getCroppedImageDimensions = (
  containerDimensions,
  imageDimensions,
  focusPointNormalized,
) => {
  const containerWidth = containerDimensions.width;
  const containerHeight = containerDimensions.height;
  const imageWidth = imageDimensions.width;
  const imageHeight = imageDimensions.height;

  // No container dimensions, just return the image dimensions, unshifted
  if (containerWidth === 0 || containerHeight === 0) {
    return {
      width: imageWidth,
      height: imageHeight,
      shiftX: 0,
      shiftY: 0,
    };
  }

  // Scale to fit the image
  const scaleFactor =
    Math.max(
      containerWidth / imageWidth,
      containerHeight / imageHeight,
    ).toFixed(4) || 1;
  const newImageWidth = imageWidth * scaleFactor;
  const newImageHeight = imageHeight * scaleFactor;

  // Use focus point if specified, center if not specified
  const focusX = focusPointNormalized ? focusPointNormalized.x : 0.5;
  const focusY = focusPointNormalized ? focusPointNormalized.y : 0.5;

  const shiftX = -Math.abs(
    Math.round((newImageWidth - containerWidth) * focusX),
  );
  const shiftY = -Math.abs(
    Math.round((newImageHeight - containerHeight) * focusY),
  );

  return {
    width: newImageWidth,
    height: newImageHeight,
    shiftX,
    shiftY,
  };
};

/**
 * Returns url of image
 * @param  {Object} image - object containing different sized images
 * @return {String}       - URL of image
 */
export const socialImageUrl = ({ smallURL, mediumURL, largeURL, xlargeURL }) =>
  [mediumURL, smallURL, largeURL, xlargeURL].find(
    (url) => url && typeof url === 'string',
  ) || null;

/**
 * Returns a width computed from height, using supplied aspect ratio
 * @param  {number} aspectRatio   - The aspect ratio
 * @param  {number} imageWidth    - The width used to compute the height
 * @param  {number} imageHeight   - The height used to compute the width
 * @return {object}               - The computed dimensions
 */
export const getDimensionsFromRatio = (ratio, imageWidth, imageHeight) => {
  if (!ratio) {
    return null;
  }
  if (!imageWidth && !imageHeight) {
    return null;
  }
  if (imageWidth && imageHeight) {
    return { width: imageWidth, height: imageHeight };
  }
  if (imageHeight) {
    return { width: ratio * imageHeight, height: imageHeight };
  }
  if (imageWidth) {
    return { width: imageWidth, height: imageWidth / ratio };
  }
  return null;
};

/**
 * Returns a URL from topic default fallback images (local server file,
 * not FLAP static response) based on the item id if the supplied URL
 * is a flip.it/tile URL.
 * @param {String} url        - URL to test
 * @return {String}           - Either the url param or a fallback URL
 */
export const topicUrl = (url) => {
  if (!url.match(/flip.it\/tile/gi)) {
    return url;
  }

  const defaultImageBrick =
    TopicDefaultFallbackImages.getDefaultImageBrick(url);
  return defaultImageBrick && defaultImageBrick.imageURL;
};

/**
 * Returns an image object from a supplied section object
 * @param {Object} section - Flipboard section
 * @return {Object}        - Image object or null
 */
export const getImageObjectFromSection = (section) => {
  if (!section) {
    return null;
  }
  if (section.isProfile && section.author && section.author.authorImage) {
    return section.author.authorImage;
  }
  return section.tileImage || section.brick || section.image;
};

/**
 * Returns the attribution copy for an image
 * @param {Object} image    - A Flipboard image object
 * @return {String}         - Attribution copy.  If there is an 'attribution'
 * property on the image, format is "Photo: <attribution>."  If no 'attribution'
 * property, the domain of the image URL is used: "Photo: <image domain>."  If
 * the domain is in a blocked of Flipboard CDN domains, null is returned.
 */
export const getImageAttribution = (image, t) => {
  const { attribution } = image;
  const blockedDomains = [
    'flip.it',
    'cdn.flipboard.com',
    'ic-cdn.flipboard.com',
  ];

  // attribution is present
  if (attribution) {
    return `Photo: ${attribution}`;
  }

  // generate attribution from domain, barring blocked domains
  const url = imageUrl(image);
  const urlObject = safeURL(url);

  if (urlObject && !blockedDomains.includes(urlObject.hostname)) {
    return t('photo_attribution', {
      hostname: urlObject.hostname,
    });
  }

  return null;
};

export const getImageObject = (imageUrl) => ({ mediumURL: imageUrl });

/**
 * Checks if a given URL exactly matches any of the sizes in the given image object
 * @param {String} imageUrl - image URL
 * @param {Object} imageObject - Flap Image Object
 * NOTE: We sometimes have image objects like this:
 * { url: 'http://abc.com/1.jpg', smallURL: 'http://abc.com/2.jpg' }
 * and sometimes
 * { url: 'http://abc.com/1.jpg', smallURL: { url: 'http://abc.com/2.jpg' } }
 * @return {Boolean} - true if given imageURL matches any image size in the imageObject
 */
export const isMatch = (url, imageObject) => {
  if (!imageObject) {
    return false;
  }

  const resizedUrls = FLAP_IMAGE_KEYS.reduce((acc, key) => {
    const val = imageObject[key];
    if (!val) {
      return acc;
    }
    return acc.concat(val.url || val);
  }, []);

  return resizedUrls.includes(url);
};

/**
 * Returns an array of image objects after converting Flipus's raw data into certain data structure format
 * @param {Array<Object>} rawImages    - Flipus's raw data of array images objects
 * @return {Array<Object>}  - Processed array of image objects. This is added to ensure that when retrieving
 * the raw data from Flipus, it can be converted into the data structure that can be utilized in image-util.js
 */
export const processFlippusRawImages = (rawImages) => {
  if (!Array.isArray(rawImages)) {
    return null;
  }
  const processedRawImages = rawImages.map((imageObj) => {
    const obj = {
      smallURL: imageObj.smallURL || (imageObj.small && imageObj.small.url),
      largeURL: imageObj.largeURL || (imageObj.large && imageObj.large.url),
      xlargeURL: imageObj.xlargeURL || (imageObj.xlarge && imageObj.xlarge.url),
      mediumURL: imageObj.mediumURL || (imageObj.medium && imageObj.medium.url),
      url: imageObj.url,
      imageURL:
        imageObj.imageURL || (imageObj.original && imageObj.original.url),
      original_height:
        imageObj.original_height ||
        (imageObj.original && imageObj.original.height),
      original_width:
        imageObj.original_width ||
        (imageObj.original && imageObj.original.width),
    };
    return obj;
  });
  return processedRawImages;
};

export const upgradeToHTTPS = (s) => {
  if (typeof s === 'string') {
    return s.replace(/^http:/, 'https:');
  }
  return s;
};
