import sentry from 'Utils/sentry';
import splitStringToChildren from 'Utils/split-string-to-children.js';

// '.' does not match accross newlines. [\s\S] which matches all whitespace + all NON whitespace, is more inclusive.
const tagRegexp = /(<\w+?>[\s\S]*?<\/\w+?>)/gm;

export type ReplaceTagsReplacmentFunction = (
  content: string,
) => React.ReactNode;
interface ReplaceTagsReplacentMap {
  [key: string]: ReplaceTagsReplacmentFunction;
}

const replaceTags = (string: string, replacements: ReplaceTagsReplacentMap) => {
  const transform = (tag: string) => {
    try {
      const match = tag.match(/^<(\w+)>/);
      const [, tagName] = match || [];
      const result = tag.match(
        new RegExp(`<${tagName}>(.*)</${tagName}>`, 's'),
      );
      const [, contents] = result || [];
      return replacements[tagName] ? replacements[tagName](contents) : tag;
    } catch (e) {
      sentry.captureException(e as Error);
      return '';
    }
  };
  return splitStringToChildren(string, tagRegexp, transform);
};

export default replaceTags;
