import { AxiosResponse } from 'axios';
import WebappConfig from 'Webapp/shared/config';
import GlobalVars from 'Utils/global-vars';
import { SECTIONS_TYPES, MAGAZINE_INVITE_TYPES } from 'Webapp/action-types';
import {
  FlapItemType,
  FlapSectionFeedType,
  FlapSectionType,
  ItemType,
  RelatedSectionType,
  AppTheme,
} from 'Webapp/enums';
// Utils
import logger from 'Utils/logger';
import sentry from 'Utils/sentry';
import FlapUtil from 'Utils/content/flap-util.js';
import NglFeedConfigUtil from 'Utils/ngl/ngl-feed-config-util.js';
import SectionUtil from 'Utils/content/section-util.js';
import { MAX_UPDATE_FEED_LIMIT } from 'Utils/api/flap/index.js';
import {
  isGroup,
  projection as ItemProjection,
} from 'Utils/content/item-util.js';
import Debouncer from 'Utils/debouncer.js';
import ContentGuideUtil from 'Utils/content/content-guide.js';
import isValueless from 'Utils/is-valueless';
import {
  getRecentlyVisitedSections,
  saveContextualSectionExcerpts,
} from 'Webapp/shared/utils/contextual-onboarding.js';
import { arrayFlatten } from 'Utils/array-flatten';
import updateFeed from 'Utils/api/flap/endpoints/update-feed';
import { getSectionId } from 'Utils/content/flipboard-urls.js';
import { PromiseAll } from 'Utils/promise';
import { imageUrl } from 'Utils/image-util';

// Selectors
import { currentUserUid, isAuthenticated } from '../selectors/auth';
import { isAmp, isOnboarding } from '../selectors/app';
import { getPrimarySection } from 'Webapp/shared/app/redux/selectors/section';
import {
  smartMagazineSelector,
  userStateDataSelector,
} from 'Webapp/shared/app/redux/selectors/profile';

// Redux actions
import {
  setNotFound,
  setAppTheme,
  setTitle,
  setSocialGraphTitle,
  setMetaDescription,
  setSocialGraphDescription,
} from '../actions/app';
import { getContentGuide } from '../actions/content-actions';
import {
  fetchProfileSmartMagazines,
  getProfileVideos,
  fetchUserInfo,
} from '../actions/profile-actions';
import { toastShowErrorAction, toastGenericError } from './toast-actions';
import {
  loadRelatedArticlesSection,
  getRelatedTopics,
  loadRelatedSection,
  getProfileMagazinesSection,
  getRelatedProfileStoryboardsSection,
  RELATED_STORYBOARDS_LIMIT,
} from './item-actions';
import { getRelatedTopicsBySection } from './flavour-actions';
import { setContextualFollowSections } from './content-actions';
import { queueLoadMissingSocialActivity } from 'Webapp/shared/concepts/social-activity';
import { fetchAccessorySections } from 'Webapp/shared/app/redux/actions/related-section-actions';
import { setInviteAcceptLoading } from 'Webapp/shared/concepts/magazine-invite';
import flaxios from 'Webapp/utils/flaxios';
import { InviteInvalidError } from 'Utils/errors';

const FIRST_SECTION_PAGE_SIZE = 6;
const FIRST_MAGAZINE_PAGE_SIZE = 7;
const AMP_PAGE_SIZE = 12;
const AMP_MAGAZINE_PAGE_SIZE = 13;
const SUBSEQUENT_SECTION_PAGE_SIZE = 24;
const RSS_PAGE_SIZE = MAX_UPDATE_FEED_LIMIT;
// go big for storyboard, the default limit is likely to not get
// everything we need (first page group+)
const STORYBOARD_PAGE_SIZE = MAX_UPDATE_FEED_LIMIT;
const NUM_CONTEXTUAL_RELATED_TOPICS = 3;
const MAX_RELATED_CONTEXT_SECTIONS = 6;
const MAX_RELATED_TOPICS = 20;

// TODO: Remove once they are available upstream. Use with feature
// flag TOPIC_VERTICAL_SUBSECTIONS
const TEST_VERTICAL_SUBSECTIONS = Object.freeze({
  'flipboard/topic%2Ftravel': [
    'sid/3r5o0kt93u02gsoo/tripsavvy',
    'sid/pct6bqt4mftj3pcf/thetravel',
    'sid/osnldehkjs1geol3/worldatlascom',
  ],
  'flipboard/topic%2Ffood': [
    'sid/qr9275hfve39in1t/fitmencook',
    'sid/gd9bcpq5of38klr5/food',
    'sid/6a2usvgje016o61g/jeremycheng2021',
  ],
  'flipboard/topic%2Fphotography': [
    'sid/aj540okun06a4gga/photographers',
    'sid/7apg8iqbpr445r5q/photographers',
    'sid/v1l98tbccbshad6u/photographers',
    'sid/lbajbgtei3j0bma8/jeremycheng2021',
  ],
  'flipboard/topic%2Fpersonalfinance': [
    'sid/camdpd167g1taksp/kimberlypal2021',
    'sid/5h28gufhve9e2a6j/financebuzz',
    'sid/tofr9jspu2m8452c/wealthgang',
  ],
});

const getLimit = (pageKey, getState, sectionId) => {
  const {
    app: { isRSS, isAmp },
  } = getState();

  if (FlapUtil.isStoryboardSectionId(sectionId)) {
    return STORYBOARD_PAGE_SIZE;
  }
  if (isRSS) {
    return RSS_PAGE_SIZE;
  }
  if (pageKey) {
    return SUBSEQUENT_SECTION_PAGE_SIZE;
  }
  const isMagazine = FlapUtil.isMagazineSectionId(sectionId);
  if (isAmp) {
    return isMagazine ? AMP_MAGAZINE_PAGE_SIZE : AMP_PAGE_SIZE;
  }

  return isMagazine ? FIRST_MAGAZINE_PAGE_SIZE : FIRST_SECTION_PAGE_SIZE;
};

interface GetSectionsByRemoteIdOptions {
  pageKey?: Flipboard.NextPageKey;
  limit?: number;
  refresh?: 'yes';
}
const getSectionsByRemoteId =
  (
    remoteIds: Flipboard.SectionId | Array<Flipboard.SectionId>,
    options: GetSectionsByRemoteIdOptions = {},
  ): Flipboard.Thunk<
    Promise<AxiosResponse<Flipboard.FlapStream> | undefined>
  > =>
  async (dispatch, getState, { flap }) => {
    const isArray = Array.isArray(remoteIds);
    const fetchMultiple = isArray && remoteIds.length > 1;
    try {
      const uid = currentUserUid(getState());
      // do not merge in any valueless options
      Object.keys(options).forEach((key) => {
        if (isValueless(options[key])) {
          delete options[key];
        }
      });

      const params = Object.assign(
        {
          sections: (isArray
            ? remoteIds.join(',')
            : remoteIds) as Flipboard.SectionId,
          limit: fetchMultiple
            ? 0
            : getLimit(options.pageKey, getState, remoteIds),
        },
        options,
      );

      const response = await updateFeed(flap, uid, params);
      return response;
    } catch (e) {
      if (flaxios.isAxiosError(e) && e?.response?.status !== 404) {
        sentry.captureException(e as Error);
      }
      dispatch(setNotFound(true));
      dispatch({
        type: SECTIONS_TYPES.GET_SECTION_FAILED,
        payload: {
          remoteIds,
        },
      });
    }
  };

const getSectionFollowersByUserid = (
  flap,
  serviceUserid,
  uid,
  pageKey = null,
) => {
  const params = {
    service: 'flipboard',
    serviceUserid,
    userid: uid,
    limit: 30,
    pageKey: undefined,
  };
  if (pageKey) {
    params.pageKey = pageKey;
  }

  return flap.get(`/social/followers/${uid}`, { params }).catch((error) => {
    logger.error('failed to get profile section followers');
    logger.error(error);
  });
};

const getFranchiseSection =
  (sectionId, franchise, nglFeedConfigs = [], path) =>
  (dispatch) => {
    const { sections } = franchise;
    const metadata = {
      section: franchise,
    };

    // convert any NGL sections as necessary
    const nglProcessedSections = sections.map((section) => {
      if (NglFeedConfigUtil.sectionIsNgl(nglFeedConfigs, section)) {
        const nglFeedConfig = NglFeedConfigUtil.getConfig(
          nglFeedConfigs,
          section,
        );
        return NglFeedConfigUtil.convertSectionToStoryboard(
          section,
          nglFeedConfig,
        );
      }
      return section;
    });

    // convert sections into section items
    const sectionItems = nglProcessedSections.map((section) =>
      ContentGuideUtil.convertSectionToSectionItem(section),
    );

    const magazines = [];

    // franchise sections are never ephemeral
    const payload = {
      requestedRemoteId: sectionId,
      remoteId: sectionId,
      metadata,
      items: sectionItems,
      rawItems: sections,
      magazines,
      ephemeral: false,
      path,
    };

    dispatch(setTitleAndDescription(path));
    dispatch(setTheme(franchise));
    return dispatch({ type: SECTIONS_TYPES.GET_SECTION_SUCCEEDED, payload });
  };

/**
 * Handles no section found
 * @param {Object} data     - The response object
 * @param {String} remoteId - The remoteid of the section
 */
const handleNoSectionStatus = (data, remoteId) => (dispatch) => {
  const msg = 'No section found in response';
  const metadata = { responseData: data };

  logger.error(msg);
  sentry.captureMessage(msg, metadata);

  dispatch({
    type: SECTIONS_TYPES.GET_SECTION_FAILED,
    payload: {
      remoteId,
    },
  });
  sentry.addBreadcrumb('handleNoSectionStatus');
  dispatch(setNotFound(true));
};

/**
 * Handles noItemStatus from flap
 * @param {Object} data     - The response object
 * @param {String} remoteId - The remoteid of the section
 */
const handleNoItemStatus =
  (data, primarySectionForRoute, remoteId) => (dispatch) => {
    const { stream } = data;
    const sectionEosMetadata = FlapUtil.sectionEosMetadata(stream);

    const msg = `${sectionEosMetadata.noItemStatus} ${sectionEosMetadata.noItemsText}`;
    const metadata = { responseData: data };

    logger[sectionEosMetadata.noItemStatus === 204 ? 'info' : 'error'](msg);

    dispatch({
      type: SECTIONS_TYPES.GET_SECTION_FAILED,
      payload: {
        remoteId,
      },
    });
    if (!primarySectionForRoute) {
      return;
    }
    switch (sectionEosMetadata.noItemStatus) {
      case 403:
      case 410:
        // 403 == magazine is private
        // 410 == magazine is deleted
        // This is a negative if google is indexing it.
        // Treat as `not found` and report to sentry so we can track the frequency
        sentry.captureMessage(msg, metadata);
        sentry.addBreadcrumb('handleNoItemStatus 410');
        dispatch(setNotFound(true));
        break;
      case 404:
        sentry.addBreadcrumb('handleNoItemStatus 404');
        dispatch(setNotFound(true));
        break;
      case 204:
        // no-op, no items is expected
        break;
      default:
        sentry.captureMessage(msg, metadata);
    }
  };

/**
 * Handles responses with non-200 response codes in the data
 * @param {Object} data     - The response object
 * @param {String} remoteId - The remoteid of the section
 */
const handleErrorCodeResponse = (data, remoteId) => (dispatch) => {
  const { message } = data;
  const metadata = { responseData: data };

  dispatch({
    type: SECTIONS_TYPES.GET_SECTION_FAILED,
    payload: {
      remoteId,
    },
  });
  sentry.addBreadcrumb('handleErrorCodeResponse');
  dispatch(setNotFound(true));

  const logMessage = message ? message : 'Failed to load section';
  logger.error(logMessage);
  sentry.captureMessage(logMessage, metadata);
};

const setTheme = (section) => (dispatch, getState) => {
  // Set the app theme for Storyboards
  const appTheme =
    SectionUtil.isStoryboard(section) && isAmp(getState())
      ? AppTheme.DARK
      : AppTheme.DEFAULT;
  dispatch(setAppTheme(appTheme));
};

const setTitleAndDescription = (path) => (dispatch) => {
  // Emit page title/descriptions before GET_SECTION_SUCCEEDED so it's
  // available in app context for page view events
  // Don't override home route title when showing 10 for Today
  if (path !== '/') {
    const options = { section: true };
    dispatch(setTitle(options));
    dispatch(setSocialGraphTitle(options));
    dispatch(setMetaDescription(options));
    dispatch(setSocialGraphDescription(options));
  }
};

const getTopicDescriptions =
  (topicDescriptionsOverride = []) =>
  async (dispatch, _, { flapStatic }) => {
    // Use topicDescriptions to set page description if we have them,
    // otherwise fetch for them
    let topicDescriptions = [];
    if (topicDescriptionsOverride.length === 0) {
      try {
        const { data } = await flapStatic.get('/topicsDescription.json');

        topicDescriptions = data.topics;
        dispatch({
          type: SECTIONS_TYPES.GET_TOPIC_DESCRIPTIONS_SUCCEEDED,
          payload: topicDescriptions,
        });
      } catch (error) {
        dispatch({
          type: SECTIONS_TYPES.GET_TOPIC_DESCRIPTIONS_FAILED,
        });
      }
    } else {
      topicDescriptions = topicDescriptionsOverride;
    }

    return topicDescriptions;
  };

const getFollowers =
  (section, pageKey = undefined) =>
  async (_dispatch, getState, { flap }) => {
    let followers = [];
    let followersNextPageKey = null;
    // If the section is a profile, load the first page of followers
    if (SectionUtil.isProfile(section)) {
      const uid = currentUserUid(getState());
      const followersResponse = await getSectionFollowersByUserid(
        flap,
        section.userid,
        uid,
        pageKey,
      );
      const followersData = followersResponse && followersResponse.data;

      if (followersData && followersData.items) {
        followers = followersData.items;
      }

      followersNextPageKey = followersData && followersData.pageKey;
    }

    return { followers, followersNextPageKey };
  };

export const getSectionFollowers =
  (section, pageKey = undefined) =>
  async (dispatch) => {
    dispatch({
      type: SECTIONS_TYPES.GET_SECTION_FOLLOWERS,
      payload: { remoteId: section.remoteid },
    });

    try {
      const { followers, followersNextPageKey } = await dispatch(
        getFollowers(section, pageKey),
      );
      dispatch({
        type: SECTIONS_TYPES.GET_SECTION_FOLLOWERS_SUCCEEDED,
        payload: {
          remoteId: section.remoteid,
          followers,
          followersNextPageKey,
          isFirstPage: !pageKey,
        },
      });
    } catch (_) {
      dispatch({ type: SECTIONS_TYPES.GET_SECTION_FOLLOWERS_FAILED });
    }
  };

const NO_RENDERABLE_ITEMS_RETRY_LIMIT = 3;
/**
 * Retrives the section, including metadata and items.
 * @param {String} remoteId - The remoteid of the section
 * @param {Boolean} options.forNavigation - True if getting section to display contents
 * @param {Array} options.nglFeedConfigs - NGL feed configs for projecting NGL-promoted sections
 * @param {Array} options.topicDescriptionsOverride - Topic descriptions to render for sections
 * @param {String} options.pageKey - Key for paged FLAP feed requests
 * @param {Array} options.previousRawItems - Array of previously-fetch items for this section.  Used for de-duping.
 * @param {Boolean} options.ephemeral - True if something will automatically reload it when needed.
 * @param {Number} options.limit - number to specify for limit.
 * @param {Number} options.noRenderableItemsRetries - number of times we have retried because we found no renderable items
 * @param {Boolean} options.extractItemsFromGroup - expect items to be in an item group and extract it
 * @param {Boolean} options.preferMagazineContext - use magazine context for section items instead of section
 */

interface GetSectionOptions {
  limit?: number;
  definitelyRefresh?: boolean;
  ephemeral?: boolean;
  excludeContext?: boolean;
  extractItemsFromGroup?: boolean;
  forNavigation?: boolean;
  loadCommentary?: boolean;
  nglFeedConfigs?: Array<unknown>;
  noRenderableItemsRetries?: number;
  pageKey?: string;
  path?: string;
  preferMagazineContext?: boolean;
  previousRawItems?: Array<Flipboard.FlapItem>;
  primarySectionForRoute?: boolean;
  refresh?: string;
  topicDescriptionsOverride?: [];
  topicDescriptions?: Array<Flipboard.TopicDescription>;
}

export const getSection =
  (
    remoteId: Flipboard.SectionId,
    options: GetSectionOptions = {},
  ): Flipboard.Thunk =>
  async (dispatch, getState) => {
    const {
      content,
      app: {
        routing: { query },
      },
    } = getState();

    const combinedOptions = Object.assign(
      {
        path: '/',
        forNavigation: false,
        nglFeedConfigs: [],
        topicDescriptionsOverride: [],
        pageKey: undefined,
        previousRawItems: undefined,
        ephemeral: true,
        noRenderableItemsRetries: 0,
        extractItemsFromGroup: false,
        preferMagazineContext: false,
        excludeContext: false,
        loadCommentary: false,
        primarySectionForRoute: false,
        definitelyRefresh: false,
      },
      options,
    );

    const {
      path,
      nglFeedConfigs,
      pageKey,
      limit,
      noRenderableItemsRetries,
      definitelyRefresh,
    } = combinedOptions;
    if (definitelyRefresh) {
      dispatch(purgeSection(remoteId));
    }
    if (noRenderableItemsRetries > NO_RENDERABLE_ITEMS_RETRY_LIMIT) {
      sentry.captureMessage('Hit section noRenderableItemsRetries limit!', {
        remoteId,
      });
      return dispatch({
        type: SECTIONS_TYPES.GET_SECTION_FAILED,
        payload: {
          remoteId,
        },
      });
    }
    dispatch({
      type: SECTIONS_TYPES.GET_SECTION,
      payload: {
        remoteId,
      },
    });
    let contentGuide = content && content.contentGuide;
    if (!contentGuide) {
      contentGuide = await dispatch(getContentGuide());
    }
    // If this remoteId corresponds to a franchise in the content guide,
    // short-circuit request and just return the data from the content guide
    const franchise = ContentGuideUtil.getFranchise(contentGuide, remoteId);
    if (franchise) {
      return dispatch(
        getFranchiseSection(remoteId, franchise, nglFeedConfigs, path),
      );
    }

    const [response] = await PromiseAll([
      dispatch(
        getSectionsByRemoteId(remoteId, {
          pageKey,
          limit,
          refresh:
            !pageKey && // pageKey is not compatible with refresh
            (definitelyRefresh ||
              (query.refresh && options.primarySectionForRoute))
              ? 'yes'
              : undefined,
        }),
      ),
      options.primarySectionForRoute
        ? dispatch(fetchAccessorySections(remoteId))
        : Promise.resolve(),
    ]);
    // Check for missing response
    if (!response) {
      return dispatch({
        type: SECTIONS_TYPES.GET_SECTION_FAILED,
        payload: {
          remoteId,
        },
      });
    }

    const { data } = response;
    const code = data && data.code;

    // Check for error in response
    if (FlapUtil.isAccessTokenExpired(data)) {
      return dispatch({ type: SECTIONS_TYPES.GET_SECTION_UNAUTHORIZED });
    } else if (code && code !== 200) {
      sentry.addBreadcrumb('getSection non-200');
      return dispatch(handleErrorCodeResponse(data, remoteId));
    }

    if (!data || !data.stream) {
      return dispatch({
        type: SECTIONS_TYPES.GET_SECTION_FAILED,
        payload: {
          remoteId,
        },
      });
    }

    await dispatch(processSectionResponseData(data, remoteId, combinedOptions));
    dispatch(purgeOldSections());

    return null;
  };

const processSectionResponseData =
  (data, remoteId, getSectionOptions): Flipboard.Thunk =>
  async (dispatch, getState) => {
    const { stream } = data;
    const {
      path,
      forNavigation,
      nglFeedConfigs,
      topicDescriptionsOverride,
      pageKey,
      previousRawItems,
      ephemeral,
      noRenderableItemsRetries,
      extractItemsFromGroup,
      preferMagazineContext,
      excludeContext,
      primarySectionForRoute,
    } = getSectionOptions;

    const nextPageKey = FlapUtil.nextPageKeyFromStream(stream);

    // Verify section found
    if (FlapUtil.noItemStatus(stream)) {
      dispatch(handleNoItemStatus(data, primarySectionForRoute, remoteId));
    }

    const section = FlapUtil.getSectionFromStream(stream, nglFeedConfigs);

    const subSections = FlapUtil.getSubTopicsFromStream(stream) || [];
    const currentUserData = userStateDataSelector(getState());

    const { featureFlags } = getState();
    // TODO: fake stupid logic to insert storyboard sub sections. ONLY
    // FOR specific vertical topics
    if (
      featureFlags.TOPIC_VERTICAL_SUBSECTIONS &&
      primarySectionForRoute &&
      remoteId in TEST_VERTICAL_SUBSECTIONS
    ) {
      const sections =
        (await dispatch(
          getBasicSections(TEST_VERTICAL_SUBSECTIONS[remoteId]),
        )) || [];

      subSections.push(...sections.map(SectionUtil.getBasicProjectedSection));
    }

    const sidebar = stream.find((item) => item.type === 'sidebar') || {};

    // Return 404 if no section found
    if (!section) {
      return dispatch(handleNoSectionStatus(data, remoteId));
    }

    const metrics =
      sidebar &&
      sidebar.groups &&
      Array.isArray(sidebar.groups) &&
      sidebar.groups[0] &&
      sidebar.groups[0].metrics;
    if (metrics) {
      section.metrics = metrics;
    }

    const neverLoadMore = FlapUtil.neverLoadMore(stream);
    let rawItems: Array<Flipboard.FlapItem> = [];
    if (extractItemsFromGroup) {
      const groupItem = stream.find((i) => isGroup(i));
      rawItems = (groupItem && groupItem.items) || [];
    } else {
      rawItems = FlapUtil.sectionRawItemsFromStream(stream, previousRawItems);
    }

    // if we don't have any valid items don't bother doing any more
    // work, just move to the next page
    if (!neverLoadMore) {
      // do we have anything to show
      const hasRenderableItems = FlapUtil.getFlatItems(
        rawItems,
        undefined,
      ).some((item) =>
        SectionUtil.isItemRenderable(section, item, currentUserData),
      );
      // are all renderable items packed into a group?
      const isFirstPageGroup = FlapUtil.isFirstPageGroup(pageKey, rawItems);

      if (!hasRenderableItems || isFirstPageGroup) {
        if (nextPageKey) {
          return dispatch(
            getSection(remoteId, {
              ...getSectionOptions,
              pageKey: nextPageKey,
              previousRawItems: rawItems,
              limit: SUBSEQUENT_SECTION_PAGE_SIZE,
              noRenderableItemsRetries: noRenderableItemsRetries + 1,
            }),
          );
        }
      }
    }

    const items = FlapUtil.nglProcessedRawItems(
      rawItems,
      section,
      nglFeedConfigs,
    );

    const magazines = FlapUtil.getMagazinesFromStream(stream);

    const additionalRelatedSectionPromises: Array<Promise<unknown>> = [];
    if (primarySectionForRoute) {
      additionalRelatedSectionPromises.push(dispatch(getFollowers(section)));

      if (SectionUtil.isTopic(section)) {
        additionalRelatedSectionPromises.push(
          dispatch(getTopicDescriptions(topicDescriptionsOverride)),
        );
      }

      if (SectionUtil.isSmartMagazine(section)) {
        additionalRelatedSectionPromises.push(
          dispatch(fetchProfileSmartMagazines()),
        );
      }

      if (SectionUtil.hasVideos(section)) {
        additionalRelatedSectionPromises.push(
          dispatch(getProfileVideos(section.userid)),
        );
      }

      if (SectionUtil.hasStoryboards(section)) {
        additionalRelatedSectionPromises.push(
          dispatch(getStoryboardsSection(section.authorUsername, path)),
        );
      }
    } else {
      // stub followers, followersNextPageKey
      additionalRelatedSectionPromises.push(Promise.resolve({}));
    }

    const additionalRelatedResults = await PromiseAll(
      additionalRelatedSectionPromises,
    );

    const { followers, followersNextPageKey } =
      additionalRelatedResults[0] as unknown as {
        followers: Array<Record<string, string>>;
        followersNextPageKey: Flipboard.NextPageKey;
      };

    if (forNavigation) {
      dispatch(setTheme(section));
    }

    const rootTopic =
      section &&
      SectionUtil.isSmartMagazine(section) &&
      SectionUtil.getRootTopic(section, smartMagazineSelector(getState()));

    const loadedRequestedRemoteId = FlapUtil.getSectionByRemoteId(
      remoteId,
      getState().sections.entries,
    )?.requestedRemoteId;

    await dispatch({
      type: SECTIONS_TYPES.GET_SECTION_SUCCEEDED,
      payload: {
        nextPageKey,
        requestedRemoteId: loadedRequestedRemoteId || remoteId,
        remoteId: section.remoteid || remoteId,
        metadata: {
          section,
        },
        items,
        rawItems,
        magazines,
        followers,
        followersNextPageKey,
        neverLoadMore,
        isLikesSection: FlapUtil.profileLikesRemoteId('') === remoteId,
        isFirstPage: !pageKey,
        ephemeral,
        preferMagazineContext,
        excludeContext,
        primarySectionForRoute,
        subSections,
        rootTopic,
      },
    });

    const authenticated = isAuthenticated(getState());

    const projectedSection = getPrimarySection(getState());
    const relatedSectionPromises: Array<Promise<void>> = [];
    if (SectionUtil.isStoryboard(section)) {
      const coverItem = items.find((item) => item.type === 'sectionCover');

      const author = SectionUtil.getAuthor(projectedSection, coverItem);
      const authorSectionId =
        author.remoteid ||
        (author.authorUsername &&
          getSectionId({
            username: author.authorUsername,
          }));
      if (authorSectionId) {
        await dispatch(getSection(authorSectionId));
      }

      if (primarySectionForRoute && !authenticated) {
        /**
         * Loading related sections for a storyboard while logged out. Ignore for subsequent/secondary
         * section loads.
         */
        if (coverItem) {
          relatedSectionPromises.push(
            dispatch(
              getStoryboardsSection(
                (coverItem || projectedSection).authorUsername,
                path,
              ),
            ),
          );
          relatedSectionPromises.push(
            ...dispatch(getRelatedTopics(remoteId, coverItem)),
          );
          relatedSectionPromises.push(
            dispatch(loadRelatedArticlesSection(remoteId, coverItem)),
          );
        }
      }
    }

    /**
     * Loading related sections for a magazine while logged out. Ignore for subsequent/secondary
     * section loads.
     */
    if (primarySectionForRoute && projectedSection.isMagazine) {
      if (Object.entries(projectedSection?.metrics).length === 0) {
        relatedSectionPromises.push(
          dispatch(getMagazineMetrics(projectedSection)),
        );
      }

      relatedSectionPromises.push(
        dispatch(
          getRelatedProfileStoryboardsSection(remoteId, projectedSection, path),
        ),
      );
      relatedSectionPromises.push(
        dispatch(
          getProfileMagazinesSection(
            remoteId,
            projectedSection,
            projectedSection.author,
          ),
        ),
      );
    }

    /**
     * Loading related sections for primary topic. Ignore for subsequent/secondary
     * section loads. Unfortunately the various related content for a topic all
     * comes from different APIs.
     */

    if (primarySectionForRoute && (projectedSection.isTopic || rootTopic)) {
      const loadRelatedTopics =
        (remoteId, section, rootTopic) => async (dispatch) => {
          const relatedTopics = await dispatch(
            getRelatedTopicsBySection(
              rootTopic || section,
              MAX_RELATED_TOPICS,
              false,
            ),
          );

          if (relatedTopics && relatedTopics.length > 0) {
            dispatch({
              type: SECTIONS_TYPES.SET_TOPICS_FOR_SECTION,
              payload: {
                remoteId,
                topics: relatedTopics,
              },
            });
            const firstThreeTopics = relatedTopics.slice(0, 3);
            await PromiseAll(
              dispatch(getRelatedTopics(remoteId, section, firstThreeTopics)),
            );
          }
        };

      relatedSectionPromises.push(
        dispatch(loadRelatedTopics(remoteId, projectedSection, rootTopic)),
      );
    }
    await PromiseAll(relatedSectionPromises);

    if (!GlobalVars.isServer()) {
      dispatch(queueLoadMissingSocialActivity());
    }

    if (forNavigation) {
      dispatch(setTitleAndDescription(path));
    }
  };

const getMagazineMetrics =
  (section: Flipboard.Section): Flipboard.Thunk =>
  async (dispatch, getState, { flap }) => {
    const uid = currentUserUid(getState());
    const { items, remoteid } = section;
    const coverItem = items.find(
      (item) => item.type === ItemType.SECTION_COVER,
    );
    if (coverItem?.id) {
      const { data } = await flap.get<Flipboard.FlapSocialActivityResponse>(
        '/social/activity',
        {
          params: {
            oid: [coverItem.id],
            userid: uid,
          },
        },
      );
      const metrics = data.items.find((x) => x.metrics);
      dispatch({
        type: SECTIONS_TYPES.SET_METRICS,
        payload: {
          remoteid,
          metrics,
        },
      });
    }
  };

export const acceptContributorInvite =
  (
    remoteid: Flipboard.SectionId,
    section: Flipboard.Section,
    invite: Flipboard.Invite,
  ): Flipboard.Thunk =>
  async (dispatch, _, { flap, t }) => {
    const body = {
      target: invite.magazineTarget,
      inviteToken: invite.inviteToken,
    };

    try {
      const response = await flap.post<
        unknown,
        AxiosResponse<{ code: number }>
      >('/curator/acceptContributorInvite', body);
      if (response && response.data && response.data.code == 200) {
        dispatch({
          type: SECTIONS_TYPES.ACCEPT_CONTRIBUTOR_INVITE_SUCCESS,
          payload: { remoteId: section.remoteid },
        });
        await dispatch(
          getSection(remoteid, {
            primarySectionForRoute: true,
          }),
        );
        await dispatch(fetchUserInfo());
        dispatch(setInviteAcceptLoading(false));
      }
    } catch (e) {
      const error = e as Flipboard.FlapAcceptContributorInviteError;
      if (error.errorcode === FlapUtil.responseCodes.INVITE_VALIDATION_ERROR) {
        const payload = { validationErrors: error.validationErrors };
        dispatch({
          type: MAGAZINE_INVITE_TYPES.SET_INVITE_VALIDATION_ERRORS,
          payload,
        });
        throw new InviteInvalidError();
      }
      dispatch({ type: SECTIONS_TYPES.ACCEPT_CONTRIBUTOR_INVITE_FAILED });
      dispatch(setInviteAcceptLoading(false));
      dispatch(toastShowErrorAction(t('accept_contributor_invite_failure')));
      throw new Error('Accept Contributor Invite Failed');
    }
  };

export const toastInvalidInviteError =
  (): Flipboard.Thunk => (dispatch, getState) => {
    const {
      magazineInvite: { validationErrors },
    } = getState();
    const message =
      validationErrors && Object.values(validationErrors).join('  ');
    if (message) {
      dispatch(toastShowErrorAction(message));
    } else {
      dispatch(toastGenericError());
    }
  };

/**
 * Debounced function to fetch for section covers, so that multiple covers can
 * be retrieved with one request.
 */
let sectionIdsForCovers: Array<Flipboard.SectionId> = [];
const debouncedGetSectionCover = Debouncer.create((dispatch, remoteids) => {
  dispatch(getSectionCovers(remoteids)).finally(() => {
    sectionIdsForCovers = [];
  });
}, 100);

export const getSectionCover =
  (remoteid: Flipboard.SectionId): Flipboard.Thunk =>
  (dispatch) => {
    sectionIdsForCovers.push(remoteid);
    debouncedGetSectionCover(dispatch, sectionIdsForCovers);
  };

export const getSectionCovers =
  (remoteids) =>
  async (dispatch, getState, { flap }) => {
    try {
      const { data } = await flap.get(
        `users/sectionCover/${currentUserUid(getState())}`,
        {
          params: { sections: remoteids.join(',') },
        },
      );
      const sectionCovers = {};
      for (const remoteid of Object.keys(data.result)) {
        sectionCovers[FlapUtil.normalizeRemoteid(remoteid)] =
          data.result[remoteid];
      }
      dispatch({
        type: SECTIONS_TYPES.GET_SECTION_COVERS_SUCCESS,
        sectionCovers,
      });
    } catch (e) {
      dispatch({ type: SECTIONS_TYPES.GET_SECTION_COVERS_FAILED });
    }
  };

/**
 * Fetches a feed of storyboards by a given user.
 * Given the URL path /@:username/storyboards, we need to make an updateFeed to a specific remoteid
 * To do so, we must find out the uid of the user, given the username we have available
 * We can then finally fetch for the storyboards section
 * @param {String} username - username of the user whose storyboards feed we want to request
 * @param {String} path - Current URL path
 * @param {Number} userid - id of the current user (not the owner of the requested section)
 */
export const getStoryboardsSection = (username, path) => async (dispatch) => {
  const response = await dispatch(
    getSectionsByRemoteId(
      `flipboard/username/${username}` as Flipboard.SectionId,
      { limit: 1 },
    ),
  );
  const { data } = response;
  const section = FlapUtil.getSectionFromStream(data.stream);
  const sectionUserId = section.userid;
  await dispatch(
    getSection(
      FlapUtil.getUserStoryboardSectionId(sectionUserId) as Flipboard.SectionId,
      { path },
    ),
  );
};

export const purgeSection = (remoteId) => ({
  type: SECTIONS_TYPES.PURGE_SECTION,
  remoteId,
});

const EPHEMERAL_SECTION_LIMIT = 10;
const purgeOldSections = () => (dispatch, getState) => {
  const {
    sections: { entries },
  } = getState();
  const ephemeralEntries = entries.filter((x) => x.ephemeral);
  const toRemove = ephemeralEntries
    .sort((a, b) => (a.accessedAt > b.accessedAt ? 0 : 1))
    .slice(EPHEMERAL_SECTION_LIMIT);
  toRemove.forEach((entry) => {
    dispatch(purgeSection(entry.remoteid));
  });
};

export const refreshForYou = () =>
  setSectionStale({ remoteid: WebappConfig.FOR_YOU_FEED_REMOTE_ID });

export const setSectionStale = (section) => (dispatch) => {
  dispatch({
    type: SECTIONS_TYPES.SET_SECTION_STALE,
    payload: {
      remoteId: section.remoteid,
    },
  });
  /**
   * If the section passed in is a smartMagazine object we need to refresh the rootTopic as well
   * as in most cases, that is the primary section.
   */

  if (section.isSmartMagazine && section.rootTopic?.remoteid) {
    dispatch({
      type: SECTIONS_TYPES.SET_SECTION_STALE,
      payload: {
        remoteId: section.rootTopic?.remoteid,
      },
    });
  }
};

export const setSectionAccessed = (section) => ({
  type: SECTIONS_TYPES.SET_ACCESSED_AT,
  remoteid: section.remoteid,
});

//
/**
 * First load previous saved contextual sections into redux (for use by
 * onboard topic picker and then secondly persist the most recent visited item
 * into the contextual onboarding cookie.
 * @param {*} item
 */
export const loadPreviousAndSaveCurrentVisitedItems =
  (item) => async (dispatch, getState) => {
    const authenticated = isAuthenticated(getState());
    if (authenticated && isOnboarding(getState())) {
      await dispatch(loadContextualOnboardingSections());
    }
    if (!authenticated) {
      saveContextualSectionExcerpts(item);
    }
  };

/**
 * Extracts the recently visited sections from the contextual onboarding cookie
 * and gets any related sections. Set's the combined list in redux.
 */
export const loadContextualOnboardingSections = () => async (dispatch) => {
  try {
    const savedContextualSections = getRecentlyVisitedSections();

    if (!savedContextualSections) {
      return dispatch(setContextualFollowSections([]));
    }

    const results = await PromiseAll(
      savedContextualSections.map(
        async (section) => await dispatch(getRelatedSectionExcerpts(section)),
      ),
    );

    const relatedContextualSections = arrayFlatten(results).slice(
      0,
      MAX_RELATED_CONTEXT_SECTIONS,
    );

    return dispatch(setContextualFollowSections(relatedContextualSections));
  } catch (e) {
    sentry.captureException(e as Error);
    return dispatch(setContextualFollowSections([]));
  }
};

const getRelatedSectionExcerpts =
  (
    section: Flipboard.Section,
  ): Flipboard.Thunk<Promise<Array<Flipboard.Section>>> =>
  async (dispatch) => {
    const sectionThings: Array<Flipboard.Section> = [];
    if (!section.isTopic || !section.searchRelated) {
      sectionThings.push(section);
    }
    if (section.searchRelated && (section.isTopic || section.isMagazine)) {
      sectionThings.push(
        ...(await dispatch(
          getRelatedTopicsBySection(section, NUM_CONTEXTUAL_RELATED_TOPICS),
        )),
      );
    }
    return sectionThings;
  };

export const loadNextTopicPage =
  (section: Flipboard.Section): Flipboard.Thunk =>
  (dispatch, getState) => {
    const {
      app: {
        routing: { url },
        nglFeedConfigs,
      },
      sections: { topicDescriptions },
    } = getState();

    const { nextPageKey, rawItems } = section;
    const decodedId = decodeURIComponent(
      section.remoteid,
    ) as Flipboard.SectionId;
    return dispatch(
      getSection(decodedId, {
        path: url,
        forNavigation: true,
        pageKey: nextPageKey || undefined,
        nglFeedConfigs,
        topicDescriptions,
        previousRawItems: rawItems,
        loadCommentary: true,
      }),
    );
  };

export const setSectionEphemeral = (remoteId, isEphemeral) => ({
  type: SECTIONS_TYPES.SET_SECTION_EPHEMERAL,
  payload: {
    remoteId,
    isEphemeral,
  },
});

export const getBasicSections =
  (
    remoteIds: Array<Flipboard.SectionId>,
  ): Flipboard.Thunk<Promise<Array<Flipboard.BasicSection> | null>> =>
  async (dispatch, __getState) => {
    try {
      const response = await dispatch(
        getSectionsByRemoteId(remoteIds, { limit: 1 }),
      );
      if (!response) {
        return null;
      }

      const { data } = response;

      const basicSections = FlapUtil.getBasicSectionsFromStream(data.stream);
      return basicSections;
    } catch (e) {
      return null;
    }
  };
export const getBasicSection = (remoteId) => async (dispatch) => {
  const basicSections = await dispatch(getBasicSections([remoteId]));
  return basicSections
    ? SectionUtil.getBasicProjectedSection(basicSections[0])
    : null;
};

export const getBasicSectionWithItems =
  (remoteId, limit = 1) =>
  async (dispatch, __getState) => {
    try {
      const response = await dispatch(
        getSectionsByRemoteId(remoteId, { limit }),
      );
      if (!response) {
        return null;
      }

      const { data } = response;

      const basicSections = FlapUtil.getBasicSectionsFromStream(data.stream);
      const rawItems = FlapUtil.sectionRawItemsFromStream(data.stream);
      if (basicSections.length > 0) {
        return {
          ...basicSections[0],
          items: rawItems.map((item) => ItemProjection(item)),
        };
      }
      return null;
    } catch (e) {
      return null;
    }
  };

export const getSpecialVerticalTopicMagazines =
  (
    targetRemoteId: Flipboard.SectionId,
    remoteId: Flipboard.SectionId,
  ): Flipboard.Thunk =>
  async (dispatch, getState, { flap }) => {
    const uid = currentUserUid(getState());
    const {
      data: { stream },
    } = await updateFeed(flap, uid, {
      sections: remoteId,
      limit: 10, // needed to get magazine topics
    });

    const magazines = stream.filter(
      (x) =>
        x.type === FlapItemType.SECTION &&
        x.section?.type === FlapSectionType.LINK &&
        x.section?.feedType === FlapSectionFeedType.MAGAZINE,
    );
    const remoteids = magazines.reduce((acc, x) => {
      if (x.section?.remoteid) {
        acc.push(x.section.remoteid);
      }
      return acc;
    }, [] as Array<Flipboard.SectionId>);
    const sections = await dispatch(getBasicSections(remoteids));
    const recommendedMagazines = sections.map((section) => {
      const projectedSection = SectionUtil.getBasicProjectedSection(section);
      const currMagazine = magazines.find(
        (magazine) => magazine.section?.remoteid === section.sectionID,
      );
      if (projectedSection) {
        projectedSection.topics = currMagazine?.sectionLinks
          .filter(SectionUtil.isTopic)
          .map(SectionUtil.getBasicProjectedSection);
        return projectedSection;
      }
    });
    return dispatch({
      type: SECTIONS_TYPES.SET_RECOMMENDED_MAGAZINES_FOR_SECTION,
      payload: {
        remoteId: targetRemoteId,
        recommendedMagazines,
      },
    });
  };

export const getSpecialVerticalTopicStoryboards =
  (remoteId, targetRemoteId) => (dispatch) =>
    dispatch(
      loadRelatedSection(
        remoteId,
        null,
        RelatedSectionType.STORYBOARDS,
        targetRemoteId,
        RELATED_STORYBOARDS_LIMIT,
      ),
    );

/**
 * @param {Object} section      - A Flipboard section object
 * @param {Boolean} forMagazine - Set to true to add params for magazines, defaults to false
 * @param {String} rootTopic    - The root topic of the magazine, defaults to null
 * @return {Promise}            - A promise that resolves with a data object containing the shortenSection
 *                                result value, or an error object
 */
export const shortenSection =
  (
    section: Flipboard.Section,
    forMagazine?: boolean | false,
  ): Flipboard.Thunk<Promise<string>> =>
  async (_dispatch, _getState, { flap }) => {
    const body: Record<string, unknown> = {
      sectionid: section.remoteid,
      title: section.title,
    };

    if (forMagazine) {
      const image = SectionUtil.image(section);
      body.imageURL = imageUrl(image);
      body.createAction = 'inviteToContribute';
      // Not used anymore. Don't know if we ever will be? Leaving it in since our APIs are undocumented.
      // if (rootTopic) {
      //   body['event_data.rootTopic'] = rootTopic;
      // }
    }
    try {
      const { data } = await flap.post<
        unknown,
        AxiosResponse<Flipboard.FlapShortenSectionResponse>
      >('/social/shortenSection', body);
      if (data && data.result) {
        return data.result;
      }
      return '';
    } catch (error) {
      sentry.captureMessage(`Error shortening section`);
      sentry.captureException(error as Error);
      return '';
    }
  };
