import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import { ICON_COLORS } from 'Style/colors';
import Icon from './icon';

// Utils
import { AppTheme } from 'Webapp/enums';

import withT from 'ComponentLibrary/hocs/withT';

export const CARET_DIRECTIONS = {
  left: 'left',
  right: 'right',
  up: 'up',
  down: 'down',
};
const DIRECTIONS_ROTATION_DEGREES = {
  left: 90,
  right: 270,
  up: 180,
  down: 0,
};

const StyledIcon = styled(Icon, {
  shouldForwardProp: (prop) => prop != 'direction: ',
})(({ color, direction }) => ({
  color: color || ICON_COLORS.emphasis,
  polyline: {
    color: 'inherit',
    stroke: 'currentColor',
  },
  transform: `rotate(${DIRECTIONS_ROTATION_DEGREES[direction]}deg)`,
}));
function CaretIcon({ color, direction, t, ...props }) {
  return (
    <StyledIcon
      name="caret"
      viewBox="0 0 24 24"
      toolTip={t('expand')}
      direction={direction}
      {...props}
      color={color}
      renderComponent={() => (
        <g stroke="none" fill="none" fillRule="evenodd">
          <polyline strokeWidth="2" points="1 6 12 18 23 6" />
        </g>
      )}
    />
  );
}

CaretIcon.propTypes = {
  size: PropTypes.number,
  direction: PropTypes.oneOf(Object.keys(DIRECTIONS_ROTATION_DEGREES)),
  className: PropTypes.string,
  toolTip: PropTypes.string,
  color: PropTypes.string,
  t: PropTypes.func.isRequired,
  appTheme: PropTypes.oneOf(Object.values(AppTheme)),
};

CaretIcon.defaultProps = {
  size: 16,
  direction: 'left',
  className: '',
  color: '',
  appTheme: AppTheme.DEFAULT,
};

export default withT(CaretIcon);
