import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Config from 'Webapp/shared/config';

// Utils
import { GA } from 'Utils/analytics';

// Components
import WebLink from 'Webapp/shared/app/components/web-link';
import PageClasses from 'Webapp/shared/app/components/page-classes';
import CTABanner, {
  CTA_BANNER_THEMES,
} from 'Webapp/shared/app/components/cta-banner';
import {
  Title,
  PageTitle,
  TitleWrapper,
  Wrapper,
} from 'Webapp/shared/app/components/error';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectAppUrl from 'Webapp/shared/app/connectors/connectAppUrl';
import connectRouting from 'Webapp/shared/app/connectors/connectRouting';

function handleErrorTypes(code, t) {
  switch (code) {
    case '404': {
      return [
        <PageTitle key={0}>{t('404')}</PageTitle>,
        <TitleWrapper key={1}>
          {t('return_to')}{' '}
          <WebLink href={Config.FLIPBOARD_URL}>Flipboard.com</WebLink>
        </TitleWrapper>,
      ];
    }
    case '503': {
      return [
        <PageTitle key={0}>
          {t('our_apologies')}
          <br />
          {t('something_went_wrong')}
        </PageTitle>,
        <TitleWrapper key={1}>{t('please_try_again_later')}</TitleWrapper>,
      ];
    }
    default:
      return <Title key={0}>{t('content_unavailable')}</Title>;
  }
}

class ErrorView extends Component {
  componentDidMount() {
    const { routing } = this.props;
    GA.trackPageview('Error', routing.pathname);
    // Reset Adjust URL in nav bar
    this.props.setNavAppStoreUrl();
  }

  render() {
    return (
      <PageClasses>
        <Wrapper>{handleErrorTypes(this.props.code, this.props.t)}</Wrapper>
        <CTABanner variant={CTA_BANNER_THEMES.RED} />
      </PageClasses>
    );
  }
}

ErrorView.propTypes = {
  routing: PropTypes.object.isRequired,
  code: PropTypes.oneOf(['404', '503']),
  setNavAppStoreUrl: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

ErrorView.defaultProps = {
  code: '404',
};

export default connector(connectRouting, connectAppUrl)(withT(ErrorView));
