import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getDimensionsFromRatio } from 'Utils/image-util';

import withT from 'ComponentLibrary/hocs/withT';

function RedBolt({ size, className, toolTip, t }) {
  const { width, height } = getDimensionsFromRatio(9 / 14, null, size);
  return (
    <svg
      className={`red-bolt ${className}`}
      width={width}
      height={height}
      viewBox="0 0 9 14"
    >
      <title id="red-bolt-icon-title">
        {toolTip || t('verified_publisher')}
      </title>
      <g fill="#F52828" fillRule="evenodd">
        <polygon points="3.78947368 6 9 6 3 14" />
        <polygon points="5.21052632 8 0 8 6 0" />
      </g>
    </svg>
  );
}

RedBolt.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  toolTip: PropTypes.string,
  t: PropTypes.func.isRequired,
};

RedBolt.defaultProps = {
  size: 14,
  className: '',
};

export default withT(RedBolt);
