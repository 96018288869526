import GlobalVars from 'Utils/global-vars';
import FlabServer from './server';
import FlabClient from './client';

/**
 * Wrapper for Flab axios instances.
 * To avoid any confusion in a isomorphic application, just import
 * this file as your Flab axios instance, and it should work on servers and clients
 * import Flab from 'Utils/api/Flab'
 */
const Flab = GlobalVars.isServer() ? FlabServer : FlabClient;
export default Flab;
