import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { userStateDataSelector } from 'Webapp/shared/app/redux/selectors/profile';
import {
  isArticleRoute,
  isSectionRoute,
  isAuthRoute,
} from 'Webapp/shared/app/redux/selectors/routing';
import { isAuthenticated } from 'Webapp/shared/app/redux/selectors/auth';
import {
  setViewportByBrowserWidth,
  getBrowserExperiments,
  clearAppErrors,
  setAppThemeOverride,
  setAppTheme,
  setBanners,
  setIsNewUser,
  setServiceWorkerRegistered,
  setTriggerInviteOnboarding,
  setFeatureFlagABTestSetupComplete,
} from 'Webapp/shared/app/redux/actions/app';
import { loadContextualOnboardingSections } from 'Webapp/shared/app/redux/actions/section-actions';
import {
  followSections,
  performPostActivationActions,
} from 'Webapp/shared/app/redux/actions/profile-actions';
import { getEditorialBoards } from 'Webapp/shared/app/redux/actions/content-actions';
import { setABTestFeatureFlags } from 'Webapp/shared/concepts/feature-flags';
import {
  usageSetReferrer,
  usageSetNavFrom,
  usageBootstrap,
  usageEnableTracking,
  usageTrackAppEnter,
  usageTrackOnboardAppStartAuthenticated,
} from 'Webapp/shared/app/redux/actions/usage-actions';
import { performPostOnboardingSocialActions } from 'Webapp/shared/app/redux/actions/post-onboarding-social-actions';
import Root from 'Webapp/shared/app/root';

const mapStateToProps = (state: Flipboard.State) => {
  const { app, auth, modal, toast, content, profile, magazineInvite } = state;

  const authenticated = isAuthenticated(state);
  const isFromConfirmation = app.isFromConfirmation;
  return {
    auth,
    currentState: userStateDataSelector(state),
    isAuthenticated: authenticated,
    isAccessTokenInvalid: auth.isAccessTokenInvalid,
    readyForMetrics: app.readyForMetrics,
    modals: modal.current,
    experiments: app.noExperiments ? null : app.experiments,
    experimentOverrides: app.experimentOverrides,
    notFound: app.notFound,
    showAmpStory: app.showAmpStory,
    title: app.title,
    toastProps: toast.props,
    toastStyle: toast.style,
    googleAdVisibility: app.googleAdVisibility,
    isFromCrawler: app.isFromCrawler,
    isFromConfirmation,
    isChromeless: app.isChromeless,
    isEmbeddable: app.isEmbeddable,
    navFromOverride: app.navFromOverride,
    adjustNavFrom: app.adjustNavFrom,
    adjustSectionId: app.adjustSectionId,
    referrer: app.referrer,
    activationReferrer: app.activationReferrer,
    appUrl: app.appUrl,
    forcedTraceLog: app.forcedTraceLog,
    utmParams: app.utmParams,
    editorialBoards: content.editorialBoards,
    isArticleRoute: isArticleRoute(state),
    isSectionRoute: isSectionRoute(state),
    seoExperiments: app.seoExperiments,
    flurl: app.flurl,
    isAuthRoute: isAuthRoute(state),
    triggerInviteOnboarding: app.triggerInviteOnboarding,
    postOnboardingSocialAction: profile.postOnboardingSocialAction,
    magazineInvite,
  };
};

const actions = {
  setViewportByBrowserWidth,
  getBrowserExperiments,
  clearAppErrors,
  setAppThemeOverride,
  setAppTheme,
  setBanners,
  setIsNewUser,
  followSections,
  getEditorialBoards,
  usageTrackAppEnter,
  usageSetReferrer,
  usageSetNavFrom,
  usageBootstrap,
  usageEnableTracking,
  usageTrackOnboardAppStartAuthenticated,
  setServiceWorkerRegistered,
  setABTestFeatureFlags,
  setTriggerInviteOnboarding,
  loadContextualOnboardingSections,
  performPostActivationActions,
  setFeatureFlagABTestSetupComplete,
  performPostOnboardingSocialActions,
};

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  Flipboard.ConnectorProps<unknown, typeof actions>;

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(actions, dispatch);

const RootContainer = connect(mapStateToProps, mapDispatchToProps)(Root);

export default RootContainer;
