import { SITEMAP_TYPES } from 'Webapp/action-types';
import { set, setPayloadReducer } from 'Utils/redux';
import { setBreadcrumbs } from 'Webapp/shared/app/redux/actions/app';
import sentry from 'Utils/sentry';

interface GetSitemapPageOptions {
  type: unknown;
  filter: unknown;
  page: number;
}

interface SitemapResponse {
  items: Array<unknown>;
  options: {
    limit: number;
    offset: number;
    order: 'none';
  };
  available: number;
}

interface SitemapReducerState {
  loading: boolean;
  index: {
    loaded: boolean;
    types: {
      topics: SitemapResponse;
      profiles: SitemapResponse;
      storyboards: SitemapResponse;
    };
  };
  page: SitemapResponse & {
    loaded: boolean;
  };
}

const sitemapResponse: SitemapResponse = {
  items: [],
  options: {
    limit: -1,
    offset: -1,
    order: 'none',
  },
  available: 0,
};

const initialState: SitemapReducerState = {
  loading: false,
  index: {
    loaded: false,
    types: {
      topics: sitemapResponse,
      profiles: sitemapResponse,
      storyboards: sitemapResponse,
    },
  },
  page: {
    loaded: false,
    ...sitemapResponse,
  },
};

export const reducer = setPayloadReducer<
  typeof SITEMAP_TYPES,
  SitemapReducerState
>(SITEMAP_TYPES, initialState);

// selectors

const selectSitemap =
  (fn: (state: SitemapReducerState) => Partial<ValueOf<SitemapReducerState>>) =>
  (state: Flipboard.State) =>
    fn(state.sitemap || initialState);

export const selectLoading = selectSitemap((s) => s.loading);
export const selectSitemapIndex = selectSitemap((s) => s.index);
export const selectSitemapPage = selectSitemap((s) => s.page);

export const selectSitemapPageOptions = ({
  app: {
    routing: {
      params: { type, filter, page },
    },
  },
}: Flipboard.State) => ({ type, filter, page });

// actions

export const getSitemapIndex =
  (): Flipboard.Thunk =>
  async (dispatch, _, { sitemap }) => {
    dispatch(set(SITEMAP_TYPES.LOADING, 'loading', true));
    try {
      const { data } = await sitemap.get('/');
      dispatch(
        set(SITEMAP_TYPES.GET_INDEX_SUCCESS, 'index', {
          loaded: true,
          types: data,
        }),
      );
      dispatch(setBreadcrumbs({ sitemapIndex: true }));
    } catch (e) {
      dispatch(
        set(SITEMAP_TYPES.GET_INDEX_FAILURE, 'index', initialState.index),
      );
      sentry.captureException(e as Error);
    } finally {
      dispatch(set(SITEMAP_TYPES.LOADING, 'loading', false));
    }
  };

export const getSitemapPage =
  (options: GetSitemapPageOptions): Flipboard.Thunk =>
  async (dispatch, _, { sitemap }) => {
    dispatch(set(SITEMAP_TYPES.LOADING, 'loading', true));
    try {
      const { type } = options;
      let { filter, page } = options;
      // setBreadcrumbs before defaulting so url matches breadcrumbs
      dispatch(setBreadcrumbs({ sitemapPage: true, type, filter, page }));
      if (type) {
        if (!filter) {
          filter = 'a';
        }
        if (!page) {
          page = 1;
        }
      }
      const { data } = await sitemap.get<SitemapResponse>(
        `/${type}/${filter}/${page}`,
      );
      dispatch(
        set(SITEMAP_TYPES.GET_PAGE_SUCCESS, 'page', {
          loaded: true,
          ...data,
        }),
      );
    } catch (e) {
      dispatch(set(SITEMAP_TYPES.GET_PAGE_FAILURE, 'page', initialState.page));
      sentry.captureException(e as Error);
    } finally {
      dispatch(set(SITEMAP_TYPES.LOADING, 'loading', false));
    }
  };
