import React from 'react';
import PropTypes from 'prop-types';

import { RouteTypes } from 'Webapp/shared/routes';

// Components
import MainNavbar from 'Webapp/shared/app/containers/navigation/main-navbar';
import Breadcrumbs from 'Webapp/shared/app/containers/navigation/breadcrumbs';
import BodyAccountVerificationStatus from 'Webapp/shared/app/components/body-account-verification-status';
import FlipMode from 'Webapp/shared/app/components/flip-mode';

import connector from 'Utils/connector';
import connectRouting from 'Webapp/shared/app/connectors/connectRouting';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectFlipMode from 'Webapp/shared/app/connectors/connectFlipMode';

function TopNavbar({
  routing,
  isAuthenticated,
  hideMainNavCTAs,
  isPhone,
  isChromeless,
  isEmbeddable,
  isFlipModeEnabled,
}) {
  const isHome = routing.type === RouteTypes.HOME;
  // Logged out mobile home doesn't render the main nav
  const shouldRenderMainNav =
    !isChromeless && !isEmbeddable && !(isHome && isPhone && !isAuthenticated);

  if (!shouldRenderMainNav) {
    return null;
  }

  return (
    <React.Fragment>
      <MainNavbar />
      {isFlipModeEnabled && <FlipMode />}
      {(!hideMainNavCTAs || isAuthenticated) && <Breadcrumbs />}
      <BodyAccountVerificationStatus />
    </React.Fragment>
  );
}

TopNavbar.propTypes = {
  routing: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isPhone: PropTypes.bool.isRequired,
  isChromeless: PropTypes.bool.isRequired,
  isEmbeddable: PropTypes.bool.isRequired,
  isFlipModeEnabled: PropTypes.bool.isRequired,
  hideMainNavCTAs: PropTypes.bool.isRequired,
};

export default connector(
  connectResponsive,
  connectRouting,
  connectFlipMode,
)(TopNavbar);
