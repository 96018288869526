import React from 'react';
import styled from '@emotion/styled';

import getNotificationTextClickStrategy from 'Webapp/shared/app/components/notifications/get-notification-text-click-strategy';
import { getAuthorUrl } from 'Utils/content/flipboard-urls.js';

import Bullet from 'Webapp/shared/app/components/bullet';
import WebLink from 'Webapp/shared/app/components/web-link.js';
import AuthorAvatar from 'Webapp/shared/app/components/attribution/author-avatar.js';

import { COLORS, TEXT_COLORS } from 'Style/colors';
import { SPACING } from 'Style/spacing';
import { BODY_TYPES } from 'Style/typography';

const IMAGE_SIZE = 40;

const UnreadIndicator = styled(Bullet)({
  display: 'grid',
  color: COLORS.red,
  fontSize: '24px',
  alignContent: 'center',
  height: `${IMAGE_SIZE}px`,
});

const NotificationDetails = styled.div({
  display: 'grid',
  width: '100%',
  gap: SPACING.BASE,
  gridTemplateColumns: 'auto minmax(1px, 1fr) auto',
  overflowWrap: 'break-word',
});

const NotificationItem = styled.li(BODY_TYPES.SMALL_STANDARD, {
  color: TEXT_COLORS.primary,
  display: 'grid',
  gridTemplateColumns: '16px 1fr',
  gap: SPACING.SMALL,
  alignItems: 'start',
  justifyItems: 'center',
});
const NotificationContents = styled.div({
  display: 'grid',
  overflowWrap: 'anywhere',
});

interface NotificationListItemPassedProps {
  notification: Flipboard.Notification;
}

type NotificationListItemProps = NotificationListItemPassedProps;

const NotificationListItem: React.FC<NotificationListItemProps> = ({
  notification,
}) => {
  const NotificationContentComponent =
    getNotificationTextClickStrategy(notification);

  if (!NotificationContentComponent) {
    return null;
  }
  return (
    <NotificationItem key={notification.id}>
      {!notification.read ? <UnreadIndicator /> : <span />}
      <NotificationDetails>
        <WebLink href={getAuthorUrl(notification.author)}>
          <AuthorAvatar avatarSize={IMAGE_SIZE} author={notification.author} />
        </WebLink>
        <NotificationContentComponent notification={notification}>
          <NotificationContents>{notification.text}</NotificationContents>
        </NotificationContentComponent>
      </NotificationDetails>
    </NotificationItem>
  );
};

export default NotificationListItem;
