import express from 'express';
import GlobalVars from 'Utils/global-vars';

const COOKIE_MAX_AGE = 365 * 24 * 60 * 60 * 1000;

/**
 * Object containing mappings for:
 * - Official Set-Cookie specs (lowercased)
 * - Flap Set-Cookie formats (lowercased)
 * Keys map to metadata used to generate cookie options for the expresss.js Cookie API
 */
const COOKIE_OPTIONS = {
  'max-age': { key: 'maxAge', type: 'number' },
  path: { key: 'path', type: 'string' },
  domain: { key: 'domain', type: 'string' },
  expires: { key: 'expires', type: 'date' },
  'http-only': { key: 'httpOnly', type: 'boolean' },
  httponly: { key: 'httpOnly', type: 'boolean' },
  secure: { key: 'secure', type: 'boolean' },
  signed: { key: 'signed', type: 'boolean' },
  'same-site': { key: 'sameSite', type: 'string|boolean' },
  samesite: { key: 'sameSite', type: 'string|boolean' },
};

type CookieOptions = {
  httpOnly: boolean;
  maxAge?: number;
  path: string;
};
export const getNewFlipboardCookieOptions = (
  env = 'development',
  httpOnly = true,
  expire: boolean | number = false,
  domain = 'flipboard.com',
): CookieOptions => {
  const options: CookieOptions = { httpOnly, path: '/' };
  if (expire === false) {
    options.maxAge = COOKIE_MAX_AGE;
  } else {
    if (!isNaN(Number(expire))) {
      options.maxAge = Number(expire);
    }
  }
  if (env !== 'development') {
    Object.assign(options, {
      secure: true,
      domain,
    });
  }
  return options;
};

/**
 * Converts a key/val object to a cookie string usable in request headers
 */
export const getCookieHeaderValue = (cookies: Record<string, string>): string =>
  Object.entries(cookies).reduce((acc, entry) => {
    const [cookie, value] = entry;
    return value ? `${acc}${cookie}=${value};` : acc;
  }, '');

/**
 * Parses an Array of Set-Cookie header strings
 * This handles the official Cookie options specified in
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie
 * Also handles Flap specific cookie options that do not necessarily match the official specs
 */
type ParseSetCookieHeaderValue = string | number | boolean | Date;
type ParseSetCookieHeaderOption = Record<
  string,
  {
    value: string;
    options: Record<string, ParseSetCookieHeaderValue>;
  }
>;
export const parseSetCookieHeader = (
  setCookiesHeaderValues: string[] | string = [],
) => {
  let headerValues = [] as string[];
  if (Array.isArray(setCookiesHeaderValues)) {
    headerValues = setCookiesHeaderValues;
  }
  if (typeof setCookiesHeaderValues === 'string') {
    headerValues = [setCookiesHeaderValues];
  }
  return headerValues
    .filter((x) => typeof x === 'string')
    .reduce<ParseSetCookieHeaderOption>((acc, setCookie) => {
      const cookieParts = setCookie.split(';');
      const nameAndValue = cookieParts.splice(0, 1)[0];
      const match = nameAndValue.match(/^([^=]+)=(.+)/);
      if (match) {
        const [, cookieName, cookieValue] = match;
        const options = cookieParts.reduce((opts, cookiePart) => {
          if (!cookiePart.trim()) {
            return opts;
          }
          const [k, v] = cookiePart.trim().split('=');
          const cookieOptions =
            COOKIE_OPTIONS[k.toLowerCase() as keyof typeof COOKIE_OPTIONS];
          if (!cookieOptions) {
            return opts;
          }

          const { key, type } = cookieOptions;
          let value: number | boolean | Date | string;
          switch (type) {
            case 'number': {
              value = parseInt(v, 10);
              break;
            }
            case 'boolean': {
              value = true;
              break;
            }
            case 'date': {
              value = new Date(v);
              break;
            }
            case 'string|boolean': {
              value = v || true;
              break;
            }
            case 'string':
            default: {
              value = v;
            }
          }

          opts[key] = value;
          return opts;
        }, {} as Record<string, ParseSetCookieHeaderValue>);
        acc[cookieName] = { value: cookieValue, options };
      }
      return acc;
    }, {});
};

export const getClientReadableCookieOptions = (env = 'development') => {
  const options = { maxAge: COOKIE_MAX_AGE };
  if (env !== 'development') {
    Object.assign(options, {
      path: '/',
      domain: 'flipboard.com',
    });
  }
  return options;
};

/**
 * This function exists to be backwards compatible with how we created cookies up until
 * December 2019. We can likely remove this in a few months.
 */
export const deleteCookie = (
  req: express.Request,
  res: express.Response,
  cookieName: string,
  env: string,
  httpOnly = true,
) => {
  const domains = ['flipboard.com', '.flipboard.com'];
  domains.forEach((domain) => {
    res.clearCookie(
      cookieName,
      getNewFlipboardCookieOptions(env, httpOnly, true, domain),
    );
  });
  delete req.cookies[cookieName];
};

/**
 * Deletes cookies used by Flipboard Web (Universal & Legacy) for authentication
 */
export const deleteFlipboardAuthCookies = (
  req: express.Request,
  res: express.Response,
  env: string,
) => {
  // userid is set by legacy, and legacy acts as if you are logged in
  // if the cookie isn't removed and causes problems on the front end
  // TODO: Move this somewhere that can be shared with cookie creation,
  // so that their names and options are in sync
  const authCookies = [
    { name: 'access_token', httpOnly: true },
    { name: 'userid', httpOnly: false },
    { name: 'oauth2_token', httpOnly: true },
    // SourcePoint-specific cookie that is used to associate
    // consent choices with a Flipboard user
    { name: 'authId', httpOnly: false },
  ];
  authCookies.forEach((cookie) => {
    deleteCookie(req, res, cookie.name, env, cookie.httpOnly);
  });
};

export const setFlipboardCookie = (
  res: express.Response,
  cookieName: string,
  cookieValue: string,
  httpOnly = true,
) => {
  res.cookie(
    cookieName,
    cookieValue,
    getNewFlipboardCookieOptions(GlobalVars.environment, httpOnly),
  );
};

/**
 * Sets given cookies into response
 * @param {Object} res - ExpressJS response
 * @param {String} cookieHeader - cookie in header
 */
export const proxySetCookies = (
  res: express.Response,
  setCookieHeader: string[] | string,
) => {
  const cookies = parseSetCookieHeader(setCookieHeader);
  Object.entries(cookies).forEach(([key, cookie]) =>
    res.cookie(key, cookie.value, cookie.options),
  );
};
