/**
 * Returned an object containing the decoded image hints
 * @param {String} encoded string containing image hints
 * @return {Object} hints object
 */
function parseHints(hints) {
  const hintObj = {};

  if (typeof hints === 'undefined' || hints === null) {
    return hintObj;
  }

  hints.split(',').forEach((hint) => {
    const hintMatches = hint.match(/([^-]+)/g) || [];
    if (hintMatches.length > 1) {
      if (hintMatches[0] === 'corner') {
        // If it's a corner hint, then the property name in the string is separated..
        const propertyName = `${hintMatches.shift()}${hintMatches.shift()}`;
        hintObj[propertyName] = hintMatches;
      } else {
        // Assume the rest to be values
        const propertyName = hintMatches.shift();
        hintObj[propertyName] = hintMatches;
      }
    } else if (hintMatches.length) {
      hintObj[hintMatches[0]] = true;
    }
  });

  return hintObj;
}

export default {
  parseHints,
};
