import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import getWindow from 'Utils/get-window';
import { TOAST_STYLES } from 'Webapp/enums';

// HOCs
import withToast from '../hocs/withToast';

// Components
import CloseIcon from 'ComponentLibrary/icons/close';
import Button from 'Webapp/shared/app/components/button';
import getToastTemplate from './toast-templates';

const DEFAULT_TIMEOUT = 5000;

class Toast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shownLongEnough: false,
    };
    this.handleMovement = this.handleMovement.bind(this);
  }

  componentDidMount() {
    getWindow().addEventListener('scroll', this.handleMovement);
    getWindow().addEventListener('mousemove', this.handleMovement);
    this.timeout = getWindow().setTimeout(() => {
      this.setState({ shownLongEnough: true });
    }, this.props.timeout);
  }

  componentWillUnmount() {
    getWindow().removeEventListener('scroll', this.handleMovement);
    getWindow().removeEventListener('mousemove', this.handleMovement);
    getWindow().clearTimeout(this.timeout);
  }

  handleMovement() {
    if (this.state.shownLongEnough) {
      this.props.toastDismiss();
    }
  }

  render() {
    const { style, props, toastDismiss } = this.props;

    const Template = getToastTemplate(props);

    const classes = classNames({
      'toast-message': true,
      'toast-message--info': style === TOAST_STYLES.INFO,
      'toast-message--error': style === TOAST_STYLES.ERROR,
    });
    return (
      <div className={classes}>
        <div className="toast-message__content">
          <p role="alert" className="ui-text--body">
            <Template {...props} />
          </p>
          <Button
            name="close-toast-message"
            className="toast-message__close"
            onClick={() => toastDismiss()}
          >
            <CloseIcon color="#fff" />
          </Button>
        </div>
      </div>
    );
  }
}

Toast.propTypes = {
  message: PropTypes.string,
  style: PropTypes.string,
  timeout: PropTypes.number,
  toastDismiss: PropTypes.func.isRequired,
  props: PropTypes.object,
};

Toast.defaultProps = {
  message: null,
  style: null,
  timeout: DEFAULT_TIMEOUT,
  props: {},
};

export default withToast(Toast);
