import getWindow from 'Utils/get-window';
import GlobalVars from 'Utils/global-vars';
import sentry from 'Utils/sentry';

const trim = (wsstr: string) => wsstr.replace(/^\s+|\s+$/g, '');

export const parseCookie = (cookie: string) => {
  if (!cookie) {
    return {};
  }
  return cookie
    .split(';')
    .map((pairStr) => trim(pairStr).split('='))
    .reduce((obj, pair) => {
      if (pair.length === 1) {
        return obj;
      }

      const decorated = pair.slice();
      if (decorated.length > 2) {
        decorated[1] = decorated.slice(1).join('=');
      }
      const [key, value] = decorated;

      const pairObject: Record<string, string> = {};
      pairObject[key] = decodeURIComponent(value);

      return Object.assign({}, obj, pairObject);
    }, {} as Record<string, string>);
};

export const getAllCookies = () => parseCookie(getWindow().document.cookie);

export const getCookieValue = (name: string, getCookies = getAllCookies) =>
  getCookies()[name] || null;

export const setEnvironmentCookieValue = (
  name: string,
  value: string,
  expirationDate?: Date,
) => {
  let domain = '.flipboard.com';
  if (GlobalVars.isStaging) {
    domain = 'webu-staging.flipboard.com';
  }
  if (GlobalVars.isDeveloperDeploy) {
    domain = `webu-${GlobalVars.deployEnvironment}.flipboard.com`;
  }
  if (GlobalVars.isDevelopment) {
    domain = 'localhost';
  }
  setCookieValue(name, value, expirationDate, domain);
};

export const setCookieValue = (
  name: string,
  value: string,
  expirationDate?: Date,
  domain?: string,
) => {
  const d =
    domain || (GlobalVars.isDevelopment ? 'localhost' : '.flipboard.com');

  if (!value) {
    return null;
  }

  let cookieValue = `${name}=${encodeURIComponent(value)}; path=/; domain=${d}`;

  if (expirationDate) {
    try {
      cookieValue = `${cookieValue}; expires=${expirationDate.toUTCString()};`;
    } catch (e) {
      if (e instanceof Error) {
        sentry.captureException(e);
      }
    }
  }

  getWindow().document.cookie = cookieValue;
};

export const deleteCookie = (name: string) => {
  if (name != null) {
    getWindow().document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    getWindow().document.cookie = `${name}=; path=/; domain=flipboard.com; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    getWindow().document.cookie = `${name}=; path=/; domain=.flipboard.com; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }

  return null;
};
