import { Connector } from 'Webapp/utils/connector';
import {
  isFlipModeRoute,
  hasFlipModeFeature,
  isFlipModeEnabled,
  flipModeTargetMagazine,
} from '../redux/selectors/flip-mode';
import {
  setFlipModeEnabled,
  setTargetRemoteId,
} from '../redux/actions/flip-mode-actions';
import { flipPending } from '../redux/selectors/magazine';
import { flipItem } from '../redux/actions/magazine-actions';
import { toastDismiss } from '../redux/actions/toast-actions';

const selectors = {
  isFlipModeRoute,
  hasFlipModeFeature,
  isFlipModeEnabled,
  flipModeTargetMagazine,
  flipPending,
};
const actions = {
  setFlipModeEnabled,
  setTargetRemoteId,
  flipItem,
  toastDismiss,
};

export type ConnectFlipModeProps = Flipboard.ConnectorProps<
  typeof selectors,
  typeof actions
>;

const connectFlipMode: Connector = { selectors, actions };

export default connectFlipMode;
