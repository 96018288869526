import connector from 'Utils/connector';
import DownloadAppBanner from '../../components/banners/download-app';
import {
  usageTrackBannerEnter,
  usageTrackBannerExit,
  usageTrackTapAppInstall,
} from 'Webapp/shared/app/redux/actions/usage-actions';

const DownloadAppBannerContainer = connector({
  selectors: {
    profile: (state) => state.profile.userInfo,
  },
  actions: {
    usageTrackBannerEnter,
    usageTrackBannerExit,
    usageTrackTapAppInstall,
  },
})(DownloadAppBanner);

export default DownloadAppBannerContainer;
