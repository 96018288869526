const VIEWPORT_MIN_WIDTHS = {
  TABLET: 600,
  TABLET_LANDSCAPE: 900,
  DESKTOP: 1200,
};

const VIEWPORT_TYPES = {
  PHONE: 'phone',
  TABLET: 'tablet',
  TABLET_LANDSCAPE: 'tablet_landscape',
  DESKTOP: 'desktop',
};

/**
 * Returns a standardized viewport type name based on the width
 * of a viewport.  One of:
 * - desktop
 * - tablet_landscape
 * - tablet
 * - phone
 * @param {Number} width   - The width of the viewport
 * @return {String}        - A viewport type name, defined in VIEWPORT_TYPES
 */
function getViewportType(width) {
  if (width >= VIEWPORT_MIN_WIDTHS.DESKTOP) {
    return VIEWPORT_TYPES.DESKTOP;
  }
  if (width >= VIEWPORT_MIN_WIDTHS.TABLET_LANDSCAPE) {
    return VIEWPORT_TYPES.TABLET_LANDSCAPE;
  }
  if (width >= VIEWPORT_MIN_WIDTHS.TABLET) {
    return VIEWPORT_TYPES.TABLET;
  }
  return VIEWPORT_TYPES.PHONE;
}

function isSmallScreen(viewportType) {
  return (
    viewportType === VIEWPORT_TYPES.PHONE ||
    viewportType === VIEWPORT_TYPES.TABLET
  );
}

function isLargeScreen(viewportType) {
  return (
    // TODO: Remove TABLET to make mutually exclusive with isSmallScreen?
    viewportType === VIEWPORT_TYPES.TABLET ||
    viewportType === VIEWPORT_TYPES.TABLET_LANDSCAPE ||
    viewportType === VIEWPORT_TYPES.DESKTOP
  );
}

function isWideLargeScreen(viewportType) {
  return (
    viewportType === VIEWPORT_TYPES.TABLET_LANDSCAPE ||
    viewportType === VIEWPORT_TYPES.DESKTOP
  );
}

function isDesktop(viewportType) {
  return viewportType === VIEWPORT_TYPES.DESKTOP;
}

function isPhone(viewportType) {
  return viewportType === VIEWPORT_TYPES.PHONE;
}

function isTablet(viewportType) {
  return (
    viewportType !== VIEWPORT_TYPES.DESKTOP &&
    viewportType !== VIEWPORT_TYPES.PHONE
  );
}

function isTabletNarrow(viewportType) {
  return viewportType === VIEWPORT_TYPES.TABLET;
}

function isTabletWide(viewportType) {
  return viewportType === VIEWPORT_TYPES.TABLET_LANDSCAPE;
}

function getUsageAppMode(viewportType) {
  const mobileWebTypes = [
    VIEWPORT_TYPES.PHONE,
    VIEWPORT_TYPES.TABLET,
    VIEWPORT_TYPES.TABLET_LANDSCAPE,
  ];
  if (mobileWebTypes.includes(viewportType)) {
    return 'mobileweb';
  }
  if (viewportType === VIEWPORT_TYPES.DESKTOP) {
    return 'desktop';
  }
  return null;
}

export { VIEWPORT_MIN_WIDTHS, VIEWPORT_TYPES };

export default {
  getViewportType,
  isLargeScreen,
  isWideLargeScreen,
  isDesktop,
  isPhone,
  isTablet,
  isTabletNarrow,
  isTabletWide,
  isSmallScreen,
  getUsageAppMode,
};
