import React from 'react';
import PropTypes from 'prop-types';

// Components
import Button, {
  StyleVariations,
  StyleModifiers,
} from 'Webapp/shared/app/components/button';
import withSocialActionGate from '../../hocs/withSocialActionGate';
import { SocialActionType } from 'Webapp/enums';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectModal from 'Webapp/shared/app/connectors/connectModal';

const FavoriteButton = ({
  addFavorite,
  favoritesFull,
  isAuthenticated,
  loading,
  removeFavorite,
  className,
  showSocialActionGate,
  styleModifier,
  styleVariation,
  remoteIdInCarouselFavorites,
  addPostOnboardingSocialAction,
  section,
  t,
}) => {
  const onClick = (...args) => {
    if (isAuthenticated) {
      remoteIdInCarouselFavorites
        ? removeFavorite(...args)
        : addFavorite(...args);
    } else {
      addPostOnboardingSocialAction({
        favorite: { sectionId: section.remoteid, title: section.title },
      });
      showSocialActionGate(SocialActionType.FAVORITE);
    }
  };
  return (
    <Button
      name="section-header-favorite"
      className={className}
      label={
        remoteIdInCarouselFavorites ? t('remove_favorite') : t('add_favorite')
      }
      styleVariation={styleVariation || StyleVariations.PRIMARY}
      styleModifier={styleModifier || [StyleModifiers.INVERSE]}
      tooltip={
        !remoteIdInCarouselFavorites && favoritesFull
          ? t('favorites_full')
          : null
      }
      disabled={loading || (!remoteIdInCarouselFavorites && favoritesFull)}
      onClick={onClick}
    />
  );
};

FavoriteButton.propTypes = {
  remoteIdInCarouselFavorites: PropTypes.string,
  addFavorite: PropTypes.func.isRequired,
  favoritesFull: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  removeFavorite: PropTypes.func.isRequired,
  className: PropTypes.string,
  showSocialActionGate: PropTypes.func.isRequired,
  addPostOnboardingSocialAction: PropTypes.func.isRequired,
  styleModifier: PropTypes.array,
  styleVariation: PropTypes.string,
  section: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

FavoriteButton.defaultProps = {
  className: null,
};

export default connector(
  connectAuthentication,
  connectModal,
)(withT(withSocialActionGate(FavoriteButton)));
