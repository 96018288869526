import connector from 'Utils/connector';
import {
  usageTrackMobileSocialGatePickerDisplayed,
  usageTrackMobileSocialGatePickerExit,
  usageTrackTapOpenInAppButton,
} from 'Webapp/shared/app/redux/actions/usage-actions';
import MobileSocialActionGate from 'Webapp/shared/app/modals/mobile-social-action-gate';

export default connector({
  actions: {
    usageTrackMobileSocialGatePickerDisplayed,
    usageTrackMobileSocialGatePickerExit,
    usageTrackTapOpenInAppButton,
  },
})(MobileSocialActionGate);
