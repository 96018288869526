import React from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import styled from '@emotion/styled';

const StyledCurationIcon = styled.svg(
  {
    display: 'inline-block',
  },
  (props) => ({
    filter: props?.shadow ? 'drop-shadow(var(--shadow--elevation-icon))' : '',
  }),
);

function CurationIcon({ size, color, shadow }) {
  return (
    <StyledCurationIcon
      shadow={shadow}
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 21 21"
    >
      <g>
        <rect x="4" y="10.5" width="3.25" height="3.25" fill={color} />
        <rect x="4" y="4" width="3.25" height="3.25" fill={color} />
        <rect x="7.25" y="4" width="3.25" height="3.25" fill={color} />
        <rect x="10.5" y="4" width="3.25" height="3.25" fill={color} />
        <rect x="4" y="7.25" width="3.25" height="3.25" fill={color} />
        <rect x="13.75" y="10.5" width="3.25" height="3.25" fill={color} />
        <rect x="13.75" y="7.25" width="3.25" height="3.25" fill={color} />
        <rect x="13.75" y="13.75" width="3.25" height="3.25" fill={color} />
        <rect x="10.5" y="13.75" width="3.25" height="3.25" fill={color} />
        <rect x="7.25" y="13.75" width="3.25" height="3.25" fill={color} />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="21"
          height="21"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </StyledCurationIcon>
  );
}

CurationIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  shadow: PropTypes.bool,
};

CurationIcon.defaultProps = {
  size: 24,
  color: '#F52828',
  shadow: false,
};

export default CurationIcon;
