import { topicList } from 'Utils/content/item-util';

import getCurrentSection from 'Utils/seo/shared/get-current-section';

export default ({ section }, getState, { t }) => {
  if (section) {
    const currentSection = getCurrentSection(getState());
    if (currentSection && currentSection.isMagazine) {
      if (currentSection.description) {
        return currentSection.description;
      }
      const topics = topicList(currentSection.items).join(', ');
      return t('magazine_seo_description_generic', {
        topics,
        profileName: currentSection.authorDisplayName,
        magazineName: currentSection.title,
        followCount:
          currentSection.metrics && currentSection.metrics.subscribersCount,
      });
    }
  }
};
