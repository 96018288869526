import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';
import { onboardingDataSelector } from 'Webapp/shared/concepts/onboarding';
import { usageTrackAcceptContributorInvite } from 'Webapp/shared/app/redux/actions/usage-actions.js';
import { AcceptMagazineInviteError } from 'Utils/errors';
const trackAcceptContributorInvite: OnboardingStep =
  (nextStep) => (dispatch, getState) => {
    const onboardingData = onboardingDataSelector(getState());
    if (!onboardingData?.magazineInviteSection) {
      throw new AcceptMagazineInviteError(
        'Accept Magazine Invite Flow is missing magazineInviteSection',
      );
    }
    const section = onboardingData.magazineInviteSection;
    if (!section.remoteid || !section.invite) {
      throw new AcceptMagazineInviteError(
        'Accept Magazine Invite Flow is missing invite or remoteid',
      );
    }

    dispatch(usageTrackAcceptContributorInvite(section, section.invite));

    nextStep();
  };

export default trackAcceptContributorInvite;
