import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { BREAKPOINTS } from 'Style/breakpoints';
import { TEXT_COLORS } from 'Style/colors';
import { UI_TEXT_TYPES } from 'Style/typography';

// Utils
import { USAGE_DISPLAY_STYLES } from 'Utils/analytics/usage';

// Components
import GetAppButton from 'Webapp/shared/app/containers/get-app-button';
import SignupButton from 'Webapp/shared/app/containers/signup-button';

import connector from 'Utils/connector';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import {
  StyleVariations,
  StyleModifiers,
} from 'Webapp/shared/app/components/button';
import withT from 'ComponentLibrary/hocs/withT';

const StyledCTABanner = styled.section(
  {
    padding: `${SPACING.BASE4X} 0`,
    textAlign: 'center',
  },
  UI_TEXT_TYPES.TITLE,
);

const StyledCTABannerTitle = styled.div(
  {
    marginBottom: `${SPACING.LARGE}`,
    color: TEXT_COLORS.primary,
  },
  BREAKPOINTS.tabletLandscapeUp({
    marginBottom: `${SPACING.XLARGE}`,
  }),
);

function CTABanner({ variant, isLargeScreen, isAuthenticated, t }) {
  if (isAuthenticated) {
    return null;
  }
  const styleModifier = [StyleModifiers.FAT];
  if (variant === 'red') {
    styleModifier.push(StyleModifiers.INVERSE);
  }

  return (
    <StyledCTABanner role="banner" className="cta-banner">
      <StyledCTABannerTitle className="cta-banner__title">
        {t('stories_curated_for_you')}
      </StyledCTABannerTitle>
      {isLargeScreen ? (
        <SignupButton
          name="sign-up-banner"
          label={t('sign_up_now')}
          styleVariation={StyleVariations.PRIMARY}
          styleModifier={styleModifier}
          displayStyle={USAGE_DISPLAY_STYLES.CTA_BANNER}
        />
      ) : (
        <GetAppButton name="get-app-banner" styleModifier={styleModifier} />
      )}
    </StyledCTABanner>
  );
}

export const CTA_BANNER_THEMES = {
  STANDARD: 'standard',
  RED: 'red',
};

CTABanner.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(Object.values(CTA_BANNER_THEMES)),
  isLargeScreen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

CTABanner.defaultProps = {
  variant: CTA_BANNER_THEMES.STANDARD,
};

export default connector(
  connectResponsive,
  connectAuthentication,
)(withT(CTABanner));
