import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { SURFACE_COLORS } from 'Style/colors';
// Components
import AvatarIcon from 'ComponentLibrary/icons/avatar';

const DefaultImageIcon = styled(AvatarIcon)({
  display: 'block',
  paddingTop: 'spacing(small)',
  borderRadius: '50%',
  backgroundColor: SURFACE_COLORS.secondary,
  objectFit: 'cover',
  border: '0.75px solid rgba(0, 0, 0, 0.15)',
});

function AuthorAvatarDefault({ avatarSize, imageClass }) {
  const classes = classNames(imageClass, {
    'author-avatar__image': true,
  });

  return (
    <div fallback="fallback">
      <DefaultImageIcon className={classes} size={avatarSize} />
    </div>
  );
}

AuthorAvatarDefault.propTypes = {
  avatarSize: PropTypes.number.isRequired,
  isAmp: PropTypes.bool,
  imageClass: PropTypes.string,
};

AuthorAvatarDefault.defaultProps = {
  avatarSize: 35,
  isAmp: false,
  imageClass: null,
};

export default AuthorAvatarDefault;
