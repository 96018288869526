import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { SPACING } from 'Style/spacing';
import GlobalVars from 'Utils/global-vars';
import { GA } from 'Utils/analytics';
import { ACTION_TYPES } from 'Utils/analytics/ga';
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';

// Components
import WebLink from '../web-link';
import FlipboardLogo from 'ComponentLibrary/logos/flipboard';
import WebFlipboardLandscapeLogo from '../logos/web-flipboard-landscape';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectRefreshForYou from 'Webapp/shared/app/connectors/connectRefreshForYou';

const StyledMainNavLogo = styled(WebLink, {
  shouldForwardProp: (prop) => prop !== 'isAuthenticated',
})(({ isAuthenticated }) => ({
  position: 'relative',
  display: 'inline-flex',
  alignSelf: 'center',
  alignItems: 'center',
  height: '60px',
  marginLeft: isAuthenticated ? '0' : SPACING.MEDIUM,
  a: {
    display: 'inherit',
    alignItems: 'center',
  },
}));

function NavbarLogo({
  isAmp,
  isAuthenticated,
  isLargeScreen,
  refreshForYou,
  t,
}) {
  const isProduction = GlobalVars.environment === 'production';
  const onClick = () => {
    if (isAuthenticated) {
      refreshForYou();
    }
    const label = isLargeScreen ? 'logo (desktop)' : 'logo (mobile)';
    GA.trackNavbarEvent(ACTION_TYPES.CLICK, label);
  };

  return (
    <StyledMainNavLogo
      href="/"
      onClick={onClick}
      className="media-link"
      navFromEventName={USAGE_EVENT_NAMES.SECTION_ENTER}
      isAuthenticated={isAuthenticated}
      title={t('flipboard_logo_go_to_tooltip')}
    >
      {isAuthenticated ? (
        <FlipboardLogo
          height={30}
          showAmpBadge={!isProduction && isAmp}
          toolTip={t('flipboard_home')}
        />
      ) : (
        <WebFlipboardLandscapeLogo
          height={30}
          showAmpBadge={!isProduction && isAmp}
          toolTip={t('flipboard_home')}
        />
      )}
    </StyledMainNavLogo>
  );
}

NavbarLogo.propTypes = {
  isAmp: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired,
  isLargeScreen: PropTypes.bool.isRequired,
  refreshForYou: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

NavbarLogo.defaultProps = {
  isAmp: false,
};

export default connector(
  connectResponsive,
  connectRefreshForYou,
)(withT(NavbarLogo));
