import ObjectUtil from 'Utils/object-util';
import sanitizeForReact from 'Utils/sanitize-for-react';
import { stripURLs, truncateText } from 'Utils/text';
import Attribution from 'Utils/content/attribution';
import SectionUtil from 'Utils/content/section-util';
import { isAbsoluteUrl, euc, detectMimeType } from 'Utils/url';
import {
  isAboutFlipboardUrl,
  isCdnFlipboardUrl,
  stripQueryStrings,
  getArticlePath,
} from 'Utils/content/flipboard-urls';
import { getImageObject, socialImageUrl, largestUrl } from 'Utils/image-util';
import FlapUtil from 'Utils/content/flap-util';
import GlobalVars from 'Utils/global-vars';
import { uniq } from 'lodash';
import isValueless from 'Utils/is-valueless';

const { createRule, getFromRules } = ObjectUtil;

const SUPPORTED_ITEM_TYPES = [
  'post',
  'image',
  'audio',
  'video',
  'status',
  'sectionCover',
  'group',
  'section',
  'activity',
];

const VIMEO_VIDEO_ID_REGEX = /[^https://player.vimeo.com/video/]+$/;

/**
 * Returns an item's inline items that are images
 * @param {Object}      - Flipboard item
 * @returns {Array}     - An array of image items
 */
export const getInlineImageItems = (item) => {
  const { inlineItems } = item;
  const inlineImages =
    inlineItems !== undefined &&
    inlineItems.length > 0 &&
    inlineItems.filter((inlineItem) => inlineItem.image !== undefined);
  return inlineImages || [];
};

/**
 * Returns an item's inline items that are videos
 * @param {Object}      - Flipboard item
 * @returns {Array}     - An array of video items
 */
export const getInlineVideoItems = (item) => {
  const { inlineItems } = item;
  return (
    inlineItems !== undefined &&
    inlineItems.filter((inlineItem) => inlineItem.type === 'video')
  );
};

/** Returns the first inline video item
 * @param {Object}      - Flipboard item
 * @returns {Object}    - The first inline video item or null
 */
export const getInlineVideoItem = (item) => {
  const inlineVideos = getInlineVideoItems(item);
  return inlineVideos.length > 0 ? inlineVideos[0] : null;
};

export const isAudio = (item) => item.type === 'audio';

/** Returns whether or not the item is high quality
 * @param {Object}      - Flipboard item
 * @returns {Boolean}   - If the item is high quality
 */
export const isHighQuality = (item) =>
  !!(item.additionalUsage && item.additionalUsage.content_quality === 'high');

export const isSoundcloud = (item) => {
  const { audioURL } = item;
  if (!isAudio(item)) {
    return false;
  }
  const audioURLMatches =
    audioURL !== undefined && audioURL.match(/soundcloud/);
  return audioURLMatches && audioURLMatches.length > 0;
};

export const isYoutube = (item) => {
  const { videoService, src } = item;
  return (
    videoService === 'youtube' || (src !== undefined && src.match(/youtube/))
  );
};

export const isVimeo = (item) =>
  !!(
    item.videoService === 'vimeo' ||
    (item.sourceURL && item.sourceURL.match(/^https?:\/\/vimeo/))
  );

export const isHtml5 = (item) =>
  typeof (typeof item.h264URL !== 'undefined'
    ? item.h264URL
    : item.sourceURL) !== 'undefined';

const isVideoSupported = (item) =>
  isFirstPartyVideo(item) ||
  isVimeo(item) ||
  isYoutube(item) ||
  (isHtml5(item) && item.type === 'video');

export const isVideo = (item) => {
  if (isVideoSupported(item)) {
    return true;
  }

  const videoItem = getInlineVideoItem(item);
  return videoItem !== null && isVideoSupported(videoItem);
};

export const isFirstPartyVideo = (item) =>
  !!(item.type === 'video' && !item.videoService && item.videoSiteURL);

export const isImage = (item) => item.type === 'image';
export const isPost = (item) => item.type === 'post';
export const isFranchise = (item) => item.type === 'group';
export const isSection = (item) =>
  !!((item.type === 'section' || item.type === 'feed') && item.section);
export const isStoryboard = (item) =>
  isSection(item) && getSectionItemFeedType(item) === 'bundle';
const isProfile = (item) =>
  isSection(item) && SectionUtil.isProfile(item.section);
export const isSectionCover = (item) => item.type === 'sectionCover';
export const isStatus = (item) => item.type === 'status';
export const isTwitter = (item) => isStatus(item) && item.service === 'twitter';
export const isFlipboard = (item) => item.service === 'flipboard';
export const isGroup = (item) => item.type === 'group';
export const isSidebar = (item) => item.type === 'sidebar';
const isMoreStories = (item) => item.type === 'moreStories';
export const isMedia = (item) => isAudio(item) || isVideo(item);
export const isArticle = (item) =>
  isMedia(item) || isFirstPartyVideo(item) || isPost(item);

export const isNote = (item, original) =>
  isStatus(item) && !(isTwitter(item) || (original && isTwitter(original)));
/**
 * Returns whether the item is a "flip compose image".  These are images
 * that are flipped via native clients by uploading an image
 * @param {Object} item - Flipboard item
 * @returns {Boolean}  - True if the item is a flip compose image
 */
export const isFlipComposeImage = (item) =>
  isImage(item) && isFlipboardInternalContent(item);

export const isStandalonePost = (item, original = null) =>
  isNote(item, original || item.original) || isFlipComposeImage(item);

export const isMagazine = (item) =>
  item.feedType === 'magazine' ||
  (item.section && item.section.feedType === 'magazine');

export const isActivity = (item) => item.type === 'activity';

export const isSupportedType = (item) =>
  SUPPORTED_ITEM_TYPES.includes(item.type);

export const isValid = (item) =>
  !!(
    item.title ||
    item.image ||
    item.text ||
    (item.original && item.original.text)
  );

export const isFlipboardInternalContent = (item) =>
  item.sourceDomain === 'flipboard.com' &&
  !isAbsoluteUrl(item.text) &&
  !isAboutFlipboardUrl(item) &&
  !isCdnFlipboardUrl(item);

/**
 * Keys like canShare, canLike, canReply are omitted when they are
 * true, yes I know. The logic is hard to look at, but:
 * if item key is true, null or undefined, return true
 * if item key is false, return false
 **/
export const castCanValue = (key) => (item) =>
  isValueless(item?.[key]) === true || item[key];

export const getCanShare = castCanValue('canShare');
export const getCanShareLink = castCanValue('canShareLink');
export const getCanReply = castCanValue('canReply');
export const getCanLike = castCanValue('canLike');

export const is404 = (item) =>
  item.sourceDomain === 'flipboard.com' && isAbsoluteUrl(item.text);

export const sanitizedTitle = (item) => {
  const title = isStatus(item) ? item.text || item.title : item.title;

  if (!title) {
    return null;
  }

  const stripped = stripURLs(title, false);

  if (!stripped) {
    return title;
  }

  const trimmed = stripped.trim();

  return sanitizeForReact(trimmed);
};

export const isNonEmptyImage = (_item, image) => {
  if (typeof image === 'string') {
    return !!image;
  }
  return image ? Object.keys(image).length > 0 : false;
};

// TODO: What is the downside of reordering isFlipboardInternalContent?
const IMAGE_RULES = [
  ['albumArtImage', isAudio],
  ['posterImage', isVideo],
  ['section.mainItem.image', isSection],
  ['section.tileImage', isSection],
  ['section.image', isSection],
  ['section.brick', isSection],
  ['mainItem.image', isSectionCover],
  ['mainItem.inlineImage', isSectionCover],
  ['mainItem.posterImage', isSectionCover],
  ['mainItem.albumArtImage', isSectionCover],
  ['section.tileImage', isSectionCover],
  ['customizer.customizations.image', isFlipboardInternalContent],
  ['image'],
  ['inlineImage'],
].map((args) => {
  let [path, ...additionalTests] = args; // eslint-disable-line
  additionalTests = additionalTests.concat([isNonEmptyImage]);
  return createRule(path, ...additionalTests);
});

/** Returns the inline image object of the item
 * @param {Object} item - Flipboard section item
 * @return {Object}
 */
export const getImage = (item) => {
  const image = getFromRules(IMAGE_RULES, item);
  if (typeof image === 'string') {
    return getImageObject(image);
  }
  return image;
};

/**
 * Returns a Video ID for a given video item.
 * Sometimes VideoID is not present in the item. As it's a require field for AMP,
 * In such cases, must parse out the id from the souce URL.
 * Eg. https://player.vimeo.com/video/123456
 * https://player.vimeo.com/video/1234556?title=0&byline=0
 * @param  {item} item - Flipboard item
 * @return {Number}    - Video ID
 */
export const getVideoID = (item) => {
  let { videoID } = item;
  if (videoID) {
    return videoID;
  }

  const { sourceURL } = item;
  const absolutePathUrl = stripQueryStrings(sourceURL);
  const regexMatch =
    absolutePathUrl && absolutePathUrl.match(VIMEO_VIDEO_ID_REGEX);
  if (regexMatch && regexMatch.length > 0) {
    videoID = parseInt(regexMatch[0], 10) || null;
  }
  return videoID || null;
};

/** Returns the SoundCloud track ID for an item
 * @param {Object} item - Flipboard section item
 * @return {String} The SoundCloud track ID
 */
export const getSoundcloudTrackId = (item) => {
  const { sourceDomain, audioURL } = item;

  // This isn't a Soundcloud audio item
  if (sourceDomain !== 'soundcloud.com') {
    return null;
  }

  // Missing audioURL property
  if (audioURL === undefined) {
    return null;
  }

  const regex = /tracks\/(\d+)\//;
  const matches = audioURL.match(regex);

  // The audioURL is not in the expected format
  if (matches === null) {
    return null;
  }

  return matches[1];
};

/** Returns the an excerpt of the text do display
 *  for an item.
 * @param {Object} item - Flipboard item
 * @param {Integer} maxLength - The length to truncate
 * the excerpt to.  No truncation if omitted.
 * @return {String}     - The truncated text to display
 */
export const getExcerpt = (item, maxLength = null) => {
  const { excerptText, text, description } = item;

  let excerpt;
  // storyboard, video
  if (description) {
    excerpt = description;
  } else if (isFlipComposeImage(item)) {
    return null;
  } else {
    excerpt = excerptText || text;
  }

  if (excerpt === undefined) {
    return null;
  }

  excerpt = sanitizeForReact(excerpt);

  if (maxLength !== null && excerpt.length > maxLength) {
    excerpt = excerpt && `${truncateText(excerpt, maxLength)}...`;
  }
  return excerpt;
};

/** Returns the topics associated with an item
 * @param {Object} item   - Flipboard item
 * @return {Array}        - The array of topics for the item
 */
export const getTopics = (item) => {
  if (!Array.isArray(item.sectionLinks)) {
    return [];
  }

  return item.sectionLinks.filter((link) => link.type === 'topic');
};

/** Returns an short string for use as a page anchor for an item
 * within a section.
 * @param {Object} item   - Flipboard item
 * @return {String}       - A short string with "item-<id>"
 */
export const anchorString = (item) => `item-${item.id}`;

/**
 * Returns the author of a section item
 * @param {Object} sectionItem    - Flipboard item that is a section
 *                                 (like when a section is in a feed)
 * @returns {Object}              - The author object
 */
export const getSectionItemAuthor = (sectionItem) =>
  sectionItem?.section?.author || null;

/**
 * Returns whether a section item has valid author metadata to
 * be able to render attribution
 * @param {Object} sectionItem      - Flipboard item that is a section
 *                                 (like when a section is in a feed)
 * @returns {Boolean}              - True if the author exists and is valid
 */
export const sectionItemHasValidAuthorAttribution = (sectionItem) => {
  const author = getSectionItemAuthor(sectionItem);

  if (!author) {
    return false;
  }

  const { authorImage, authorUsername, authorDisplayName } = author;

  const displayName = authorDisplayName || authorUsername;

  return !!(displayName && authorImage);
};

/**
 * Returns the feedType for a section item
 * @param {Object} sectionItem   - Flipboard item that is a section
 *                                 (like when a section is in a feed)
 * @return {String}              - The "feedType" of the section
 */
export const getSectionItemFeedType = (sectionItem) => {
  const { section } = sectionItem;
  return (section && section.feedType) || null;
};

/**
 * Returns whether a section item is a topic
 * @param {Object} sectionItem   - Flipboard item that is a section
 *                                 (like when a section is in a feed)
 * @param {Boolean}              - True if the section is a topic
 */
export const sectionItemIsTopic = (sectionItem) =>
  getSectionItemFeedType(sectionItem) === 'topic';

/**
 * Returns a secure video source URL from an item, if available.
 * Note that AMP does not allow non-secure videos.
 * @param {Object} item - Flipboard item
 * @returns {String}    - URL of a secure video
 */
export const getVideoSourceURL = (item) => {
  const { h264URL, videoSiteURL } = item;
  const videoSourceUrl = h264URL || videoSiteURL;
  if (!videoSourceUrl) {
    return null;
  }
  return videoSourceUrl.indexOf('https') === 0 ? videoSourceUrl : null;
};

/**
 * Returns a secure audio URL from an item, if available.
 * Note that AMP does not allow non-secure audio.
 * @param {Object} item - Flipboard item
 * @returns {String}    - URL of a secure audio
 */
export const getAudioURL = (item) => {
  const { audioURL } = item;
  if (!audioURL) {
    return null;
  }
  return audioURL.indexOf('https') === 0 ? audioURL : null;
};

/**
 * Returns a section for related articles, if available
 * @param  {Object} item - Flipboard item
 * @return {String}      - A "See More" section
 */
export const getRelatedArticlesSection = (item) => {
  if (!item || !item.sectionLinks) {
    return null;
  }
  return item.sectionLinks.find((i) => isMoreStories(i)) || null;
};

/**
 * Returns the sourceURL for the item
 * @param  {Object} item - Flipboard item
 * @return {String} - sourceURL for the item
 */
export const getSourceURL = (item) => {
  // NOTE: Some articles sometimes return as type="status", and a link on the text body.
  // These often become article items after a new request is made. If an article returns
  // with an excerpt with a single link, use that instead as the sourceURL.
  if (item.type === 'status' && isAbsoluteUrl(item.text)) {
    return item.text;
  }

  return item.sourceURL || (isMagazine(item) && item.canonicalPath);
};

/**
 * Returns the URL for opening the item.
 * @param  {Object} item - Flipboard item
 * @return {String} - Link sourceURL for the item
 */
export const getOpenURL = (item) => {
  const openURL = item.openURL;
  const { openURLRedirectVerifier } = item;

  if (openURLRedirectVerifier) {
    return `https://flipboard.com/redirect?url=${euc(openURL)}&v=${euc(
      openURLRedirectVerifier,
    )}`;
  }

  return openURL;
};

/** Returns a flattened array of items, recursively iterating
 * over the item lists contained in "group" type items
 * @param {Array} items  - An array of Flipboard items
 * @return {Array}       - All the items, including those belonging
 *                        to group items in the list, flatted in one array
 */
export const flattenItems = function myself(items) {
  return items.reduce((collect, item) => {
    var itemsToConcat = isGroup(item) ? myself(item.items) : [item];
    return collect.concat(itemsToConcat);
  }, []);
};

/**
 * Returns the first item with an image that satisfies the size requirements for
 * social embedding from an array of items (excluding sectionCover)
 * @param {Array} items    - An array of Flipboard items
 * @return {Object}        - A Flipboard item with an image, or null
 */
export const firstImageItem = (items) => {
  const filteredItems = items.filter(
    (item) => isSupportedType(item) && !isSectionCover(item),
  );
  const flattenedItems = flattenItems(filteredItems);
  const imageItem = flattenedItems.find((item) => {
    const image = getImage(item);
    if (!image) {
      return false;
    }
    return !!socialImageUrl(image);
  });
  return typeof imageItem !== 'undefined' ? imageItem : null;
};

/**
 * Returns the ID of an item, which is normally directly on the item object, but in the case of an item in the likes section items,
 * the original ID is nested in a refersTo object, along with the rest of the original item's props
 * @param  {Object} item - Flipboard item
 * @return {String} - ID of the item or null
 */
export const getArticleItemId = (item) => {
  if (!item) {
    return null;
  }
  if (item.refersTo) {
    return item.refersTo.id || null;
  }
  return item.id || null;
};

/**
 * Returns the flipboardSocialId of an item, which is normally directly on the item object, but in the case of an item in the likes section items,
 * the original flipboardSocialId is nested in a refersTo object, along with the rest of the original item's props
 * @param  {Object} item - Flipboard item
 * @return {String} - flipboardSocialId of the item or null
 */
export const getArticleSocialId = (item) => {
  if (!item) {
    return null;
  }
  if (item.refersTo) {
    return item.refersTo.flipboardSocialId || null;
  }
  return item.flipboardSocialId || null;
};

/**
 * Returns the caption on a status item
 * @param {Object} item - Flipboard item
 * @return {String}     - The caption
 */
export const getCaption = (item) => {
  if (!item) {
    return null;
  }
  const originalItem = getOriginalItemOnly(item);
  if (isTwitter(item) && item.excerpt) {
    return item.excerpt;
  }

  const captionItem =
    item.referredByItems &&
    item.referredByItems.find((i) => i.type === 'status' && i.text);

  if (
    captionItem &&
    ((isFlipComposeImage(item) && originalItem) || !isFlipComposeImage(item))
  ) {
    return captionItem.text;
  }

  // If a status item is reflipped. The caption comes in on the item itself
  // and NOT the referredByItem
  if (item.original) {
    return item.text;
  }

  return null;
};

/**
 * If the item is a reflipped status or flip compose image, use the original item's "text"
 * This is not always projected, so we can't use "caption".
 * If the item does NOT contain an original:
 * - the item will be the "caption" for a flip compose image,
 * - text for a status post
 * - title for everything else.
 */
export const getText = (item) => {
  const originalItem = getOriginalItemOnly(item);
  if (originalItem) {
    return originalItem.text;
  }
  if (isFlipComposeImage(item)) {
    const captionItem =
      item.referredByItems &&
      item.referredByItems.find((i) => i.type === 'status' && i.text);
    return captionItem?.text;
  }
  if (isStatus(item)) {
    return item.text;
  }
  return item.title;
};

/**
 * Generates a Flipboard article url for use by native apps
 * @param {Object} item        - A Flipboard item
 * @returns {String}           - A native app URL for the item
 */
export const appUrl = (item) => {
  const { sourceURL } = item;

  return (
    (sourceURL &&
      `flipboard://showSection/${encodeURIComponent(
        `resolve/flipboard/url%2F${sourceURL}`,
      )}`) ||
    null
  );
};

/**
 * Provides a method for mapping section props onto a projected item
 * Example is when flipping a section (not from a feed), so the section is not a section item, but is being rendered as an item in the flip UI.
 * This section to become item needs both item props and certain section props.
 * @param {Object} section - Flipboard section
 * @return {Object} - A projected item from the provided section, along with the needed section props that a normal projected item doesn't contain
 */
export const projectionFromSection = (section) =>
  Object.assign({}, projection(section), {
    author: section.author,
    brick: section.brick,
    isSection: true,
    isTopic: section.isTopic,
    isProfile: section.isProfile,
    isPrivate: section.isPrivate,
    remoteid: section.remoteid,
    ssid: section.ssid,
    sectionID: section.sectionID,
  });

/**
 * Returns the anchor id of the item, useful when deleting and moving items from a magazine.
 * This is because duplicate items in a magazine need to be distinguished between other identical items
 * in the feed
 * @param {Object} - Flipboard item
 * @return {String} - item id
 */
const anchorRegex = /_pk_=(.*)/;
export const getAnchorId = (item) => {
  if (!item.nextPageKey) {
    return item.id;
  }
  const matches = item.nextPageKey.match(anchorRegex);
  const anchorId = matches && matches[1];
  return anchorId ? decodeURIComponent(anchorId) : item.id;
};

/**
 * Returns the flipped date for an item, if it was flipped into a magazine
 * @param {Object} - Flipboard item
 * @return {Number} - Flipped date timestamp if available
 */
export const getFlipDate = (item) => {
  if (!item.referredByItems || !item.referredByItems.length) {
    return null;
  }
  const statusItem = item.referredByItems[0];
  return statusItem.dateCreated || null;
};

export const getUserIdsForMuting = (author, item) => {
  // HOLY MOLY
  const userIdsForMuting = [];
  const pushUserId = (userId) => userIdsForMuting.push(parseInt(userId, 10));
  // can't believe I feel like i need to do this based on data i've
  // been receiving while smoke testing this
  const pushAllAvailableIdFormats = ({ userId, userID, userid }) => {
    [userId, userID, userid].forEach((id) => id && pushUserId(id));
  };
  try {
    const flipper = Attribution.getFlipper(item);
    if (flipper) {
      pushAllAvailableIdFormats(flipper);
    }
    if (author) {
      pushAllAvailableIdFormats(author);
    }
    if (item.type === 'section') {
      const sectionItemAuthor = getSectionItemAuthor(item);
      if (sectionItemAuthor) {
        pushAllAvailableIdFormats(sectionItemAuthor);
      }
    }
    if (item.type === 'post' && item?.id) {
      const match = item.id.match(/:a:(\d+)-/);
      const matchedUserId = match?.[1];
      if (matchedUserId) {
        pushUserId(matchedUserId);
      }
    }
  } catch (_) {
    // nuthin
  }
  return uniq(userIdsForMuting);
};

const projectItemCommentsPreview = (item) => {
  const { referredByItems } = item;
  if (!referredByItems) {
    return [];
  }
  return referredByItems.filter((i) => i.type === 'comment');
};

const getItemMagazineTarget = (item) => {
  const referredByStatus = item?.referredByItems?.find(
    (i) => i.type === 'status',
  );
  // if you have a magazineTarget on the referred by status
  // you can delete (says Jason)
  return (referredByStatus || item)?.magazineTarget;
};

const getCanDeleteFromMagazine = (item) => !!getItemMagazineTarget(item);

/** Returns a projection of an item with only the
 * useful properties and derived properties for existing
 * React components.
 * @param {Object} item - Flipboard item
 * @param {Object} contextSection - A projected section object for context
 * @param {Boolean} preferMagazineContext - Whether to construct article URLs using magazine context
 * @return {Object}        - The projection of the item
 */
export const projection = (
  item,
  contextSection = null,
  preferMagazineContext = false,
  excludeContext = false,
) => {
  const itemIsSection = isSection(item);
  const itemIsVideo = isVideo(item);
  const itemIsFirstPartyVideo = isFirstPartyVideo(item);
  const itemIsPost = isPost(item);
  const inlineVideoItem = getInlineVideoItem(item);
  const itemIsAudio = isAudio(item);
  const itemIsSoundcloud = isSoundcloud(item);
  const author = Attribution.getAuthor(item);

  const {
    dateCreated,
    title,
    id,
    type,
    section,
    sectionID,
    authorDisplayName,
    authorUsername,
    hostDisplayName,
    sourceDomain,
    artist,
    sourceURLKey,
    reason,
    service,
    sectionLinks = [],
    original,
    referredByItems = [],
    feedTitle,
    activity,
    franchise,
    postedBy,
    items = [],
    mainItem,
    customizer,
    partnerID,
    article,
    originalFlip,
    remoteServiceItemID,
    commentary,
    itemPrice,
    videoSiteURL,
    mimeType,
    posterImage,
    language,
    duration,
    additionalUsage,
    hideTimelineDate,
    authorImage,
    adPreRollOk,
    piped,
    pinned,
  } = item;

  const projectedSection = SectionUtil.projection(section);

  const topics = getTopics(item).map((topic) => SectionUtil.projection(topic));
  const displayTopic = topics.find((topic) => {
    // If no section, return the first topic
    if (!contextSection) {
      return true;
    }

    // Return the first topic not matching the current section
    return topic.topicTag !== contextSection.topicTag;
  });

  const projectedOriginal = original && projection(original);
  const projected = {
    // pass-through:
    additionalUsage: additionalUsage || {},
    dateCreated,
    title,
    id,
    type,
    authorDisplayName,
    authorUsername,
    hostDisplayName,
    sourceDomain,
    artist,
    sourceURLKey,
    reason,
    service,
    original: projectedOriginal,
    feedTitle,
    activity,
    franchise,
    postedBy,
    mainItem,
    customizer,
    partnerID,
    article,
    originalFlip,
    videoSiteURL,
    mimeType,
    posterImage,
    remoteServiceItemID,
    language,
    price: itemPrice,
    duration,
    adPreRollOk,
    piped,
    // derived:
    canShare: getCanShare(item),
    canShareLink: getCanShareLink(item),
    canReply: getCanReply(item),
    canLike: getCanLike(item),
    userIdsForMuting: getUserIdsForMuting(author, item),
    excludeContext,
    hideTimelineDate,
    isAudio: itemIsAudio,
    isHighQuality: isHighQuality(item),
    isPost: itemIsPost,
    isSoundcloud: itemIsSoundcloud,
    isFirstPartyVideo: itemIsFirstPartyVideo,
    isVideo: itemIsVideo,
    isYoutube: isYoutube(item),
    isVimeo: isVimeo(item),
    isHtml5: isHtml5(item),
    isImage: isImage(item),
    isFlipComposeImage: isFlipComposeImage(item),
    isFranchise: isFranchise(item),
    isSectionCover: isSectionCover(item),
    isStatus: isStatus(item),
    isTwitter: isTwitter(item),
    isFlipboard: isFlipboard(item),
    isMedia: isMedia(item),
    isGroup: isGroup(item),
    isSidebar: isSidebar(item),
    isSection: itemIsSection,
    isStoryboard: isStoryboard(item),
    isProfile: isProfile(item),
    isMagazine: isMagazine(item),
    isActivity: isActivity(item),
    isValid: isValid(item),
    isArticle: isArticle(item),
    isNote: isNote(item, projectedOriginal),
    isStandalonePost: isStandalonePost(item, projectedOriginal),
    anchorId: getAnchorId(item),
    dateFlipped: getFlipDate(item),
    isFlipboardInternalContent: isFlipboardInternalContent(item),
    text: getText(item),
    caption: getCaption(item),
    items: items.map((child) => projection(child)),
    sanitizedTitle: sanitizedTitle(item),
    sectionItemHasValidAuthorAttribution:
      itemIsSection && sectionItemHasValidAuthorAttribution(item),
    sectionItemAuthor: itemIsSection && getSectionItemAuthor(item),
    sectionItemIsTopic: itemIsSection && sectionItemIsTopic(item),
    sourceURL: getSourceURL(item),
    openURL: getOpenURL(item),
    getSectionItemFeedType: itemIsSection && getSectionItemFeedType(item),
    excerpt: getExcerpt(item),
    description: getExcerpt(item),
    audioURL: itemIsAudio && getAudioURL(item),
    soundcloudTrackId: itemIsSoundcloud && getSoundcloudTrackId(item),
    image: getImage(item),
    videoID: itemIsVideo && getVideoID(item),
    videoSourceURL: itemIsVideo && getVideoSourceURL(item),
    author: author && SectionUtil.projection(author),
    authorImage: authorImage || (author && getImage(author)),
    anchorString: anchorString(item),
    inlineImageItems: getInlineImageItems(item),
    inlineVideoItem: inlineVideoItem && projection(inlineVideoItem),
    sectionLinks: sectionLinks.map((sectionLink) =>
      SectionUtil.projection(sectionLink),
    ),
    referredByItems: referredByItems.map((referredByItem) =>
      projection(referredByItem, contextSection),
    ),
    refersTo: item.refersTo && projection(item.refersTo),
    topics,
    section: projectedSection,
    commentary,
    oid: getArticleItemId(item),
    flipboardSocialId: getArticleSocialId(item),
    appUrl: appUrl(item),
    displayTopic,
    commentsPreview: projectItemCommentsPreview(item),
    pinned: pinned || false,
    sectionID,
    magazineTarget: getItemMagazineTarget(item),
    canDeleteFromMagazine: getCanDeleteFromMagazine(item),
  };

  const magazine = Attribution.getMagazine(projected);
  const internalUrlSection = preferMagazineContext
    ? magazine && SectionUtil.projection(magazine)
    : contextSection;

  projected.internalURL = itemLinkUrl(projected, internalUrlSection);

  if (GlobalVars.environment === 'development') {
    projected.rawItem = item;
  }

  return projected;
};

/**
 * Returns the data for the inline AML JSON of an item
 * @param {String} amlJson - A string containing AML JSON
 * @return {Object}        - The parsed AML, consisting of the metadata
 * object as well as the array of "figure" objects found in the "content"
 * property that contain "image" items.  Note: processing is done on the
 * data in the figure images in order to project them into "items of image type."
 */
export const parseAmlJson = (amlJson) => {
  const aml = JSON.parse(amlJson);
  if (!aml) {
    return null;
  }
  const { content, media, metadata } = aml;
  if (!content || !media || !metadata) {
    return null;
  }
  const imageItems = content
    .map((contentItem) => {
      if (contentItem.type === 'figure') {
        const mediaItem = media[contentItem.id];
        const { type } = mediaItem;
        if (type === 'image') {
          const { caption, height, width } = mediaItem;
          const originalWidth = mediaItem['original-width'];
          const originalHeight = mediaItem['original-height'];
          const title = caption && caption[0] && sanitizeForReact(caption[0]);

          return projection({
            type: 'image',
            title,
            image: {
              height: height && parseInt(height, 10),
              width: width && parseInt(width, 10),
              url: mediaItem.url,
              original_width: originalWidth && parseInt(originalWidth, 10),
              original_height: originalHeight && parseInt(originalHeight, 10),
              hints: mediaItem.hints,
            },
          });
        }
      }

      return null;
    })
    .filter((imageItem) => imageItem !== null);

  return { metadata, imageItems };
};

/**
 * Returns the inline AML JSON for the item
 * @param {Object} item      - Flipboard item
 * @return {String}          - The inline AML JSON or null
 */
export const getUrlJsonInline = (item) =>
  (item && item.article && item.article.urlJsonInline) || null;

/**
 * Returns an array of the first related topic name
 * for each item in an array of items.
 * @param {Array} items       - An array of Flipboard items
 * @return {Array}            - An array of topic names
 */
export const topicList = (items) =>
  items.reduce((current, item) => {
    // return a maximum of 3 related topics
    if (current.length > 2) {
      return current;
    }

    const currentTopics = getTopics(item);
    if (currentTopics.length === 0) {
      return current;
    }

    const currentTopicTitle = currentTopics[0].title;
    if (!current.includes(currentTopicTitle)) {
      return current.concat(currentTopicTitle);
    }

    return current;
  }, []);

/** Returns a URL for linking to an item.  Context sensitive, depending
 * on if the item is shown in a list if the user is authenticated, etc.
 * @param {Object} item             - A projected Flipboard item
 * @param {Object} section          - A projected Flipboard section
 * (for when the item) is rendered in a feed
 */
export const itemLinkUrl = (item, section = null) => {
  if (!item.isFlipboard && item.openURL) {
    return item.openURL;
  }
  // TODO: Determine if item that are storyboards need to handle both cases below,
  // where the canonicalPath can be in the section, or in the item
  if (item.isStoryboard) {
    return (item.section && item.section.canonicalPath) || item.canonicalPath;
  }
  if (item.isSection) {
    if (item.section) {
      return item.section.canonicalPath;
    }
    return item.canonicalPath;
  }
  // Open on publisher when rendering in a storyboard
  if (!item.isFlipboardInternalContent && section && section.isStoryboard) {
    return item.sourceURL;
  }

  return getArticlePath(item, section);
};

/**
 * Returns the main (non-publisher, etc) sections from a content guide section
 * @param {Array} items    - Array of projected section items from a content guide section
 * @return {Array}         - Array of main section items
 */
export const contentGuideMainItems = (items) =>
  items &&
  items.filter(
    (item) => item.isSection && item.section && item.section.subhead === '',
  );

/**
 * Returns the publisher sections from a content guide section
 * @param {Array} items                   - Array of projected section items from a content guide section
 * @return {Array}                        - Array of publisher section items
 */
export const contentGuidePublisherItems = (items) =>
  items &&
  items.filter(
    (item) =>
      item.isSection && item.section && item.section.subhead === 'Publishers',
  );

/**
 * Decorates a projected Flipboard item with properties needed for
 * rendering RSS.
 * @param {Object} item           - A projected Flipboard item object
 * @param {String} flipboardUrl   - The base Flipboard url for generating URLs
 * @return {Object}               - The item decorated with properties
 * needed for RSS rendering
 */
export const decorateRssItem = (item, flipboardUrl) => {
  const { topics, image, referredByItems } = item;
  const rss = {};

  // category
  rss.topics =
    topics &&
    topics.length > 0 &&
    topics.slice(0, 5).map((topic) => ({
      '@domain': `${flipboardUrl}${topic.canonicalPath}`,
      '#text': topic.title,
    }));

  // publication date
  const statusItem =
    referredByItems && referredByItems.find((i) => i.type === 'status');
  const pubDate = statusItem ? statusItem.dateCreated : item.dateCreated;
  rss.pubDate = new Date(pubDate ? pubDate * 1000 : Date.now()).toUTCString();

  // media
  const imageUrl = image && largestUrl(image);
  if (imageUrl) {
    rss.imageUrl = imageUrl;
    rss.imageType = detectMimeType(imageUrl);
    rss.imageWidth = image.original_width;
    rss.imageHeight = image.original_height;
  }
  return Object.assign({}, item, { rss });
};

const DEFAULT_NUM_TOPICS = 3;
const STORYBOARD_NUM_TOPICS = 2;

export const getTopicsToLoad = (item) => {
  const topics = getTopics(item);

  const numTopics = isSectionCover(item)
    ? STORYBOARD_NUM_TOPICS
    : DEFAULT_NUM_TOPICS;
  /**
   * We have no need to pre-load the c:video topic section on first party video pages.
   * Currently the only page that needs more than one topic is the new storyboard page.
   *  TODO: Figure out logic for loading more topics.
   **/
  return (
    topics &&
    topics
      .filter((topic) => {
        if (isFirstPartyVideo(item)) {
          return topic.topicTag !== 'c:video';
        }
        return true;
      })
      .slice(0, numTopics)
  );
};

export const getRelatedSectionsByIdKey = (item, entries, sectionIdKey) =>
  item?.relatedSections?.[sectionIdKey]?.map((remoteid) =>
    FlapUtil.getSectionByRemoteId(remoteid, entries),
  );

export const getFirstRelatedSectionByIdKey = (item, entries, sectionIdKey) =>
  getRelatedSectionsByIdKey(item, entries, sectionIdKey)?.[0];

export const isItemMuted = (item, currentUserData) =>
  !!(
    currentUserData?.mutedAuthors?.some((mutedAuthor) =>
      item.userIdsForMuting?.includes(parseInt(mutedAuthor.authorID, 10)),
    ) || currentUserData?.mutedSourceDomains?.includes(item.sourceDomain)
  );

export const getOriginalItemOnly = (item) => {
  const { type, original, referredByItems } = item;

  if (type === 'status' || isFlipComposeImage(item)) {
    // check for 'original'
    if (original !== undefined) {
      return original;
    }

    // check for 'originalFlip' in status referredByItems
    const statusItem =
      referredByItems && item.referredByItems.find((i) => i.type === 'status');
    const originalFlip = statusItem && statusItem.originalFlip;
    if (originalFlip) {
      return originalFlip;
    }
  }
  return null;
};

/**
 * Returns the original item associated with an item object
 * @param {Object} item   - A Flipboard item object
 * @return {Object}       - The originally flipped item object
 */
export const getOriginalItem = (item) => {
  const original = getOriginalItemOnly(item);
  return original || item;
};

export const mightBeSameItem = (item1, item2) => {
  if (!!item1.title && !!item2.title) {
    return item1.title === item2.title;
  }
  return item1.id === item2.id;
};
