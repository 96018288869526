import React from 'react';

// Components
import Image, {
  THEMES as ImageThemes,
} from 'Webapp/shared/app/components/base/image';
import ModalWrapper from './modal-wrapper';
import ModalControls from 'Webapp/shared/app/components/modal-controls';
import {
  MODAL_THEMES,
  MODAL_WINDOW_SIZES,
} from 'ComponentLibrary/modals/modal';
import withT from 'ComponentLibrary/hocs/withT';

import { getImageObjectFromSection } from 'Utils/image-util';

import connector from 'Utils/connector';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';
import connectResponsive, {
  ConnectResponsiveProps,
} from 'Webapp/shared/app/connectors/connectResponsive';
export interface ImageModalPassedProps {
  item: Flipboard.Item;
}
type ImageModalProps = ImageModalPassedProps &
  ConnectModalProps &
  ConnectResponsiveProps;

const ImageModal: React.FC<ImageModalProps> = ({
  item,
  dismissModal,
  isPhone,
}) => {
  const imageToRender = getImageObjectFromSection(item);
  return (
    <ModalWrapper
      name="image-modal"
      size={MODAL_WINDOW_SIZES.AUTO}
      modalTheme={MODAL_THEMES.WINDOW}
    >
      <Image
        image={imageToRender}
        alt={item?.caption || 'image'}
        crop
        useDumbCroppedImage
        variant={isPhone ? ImageThemes.medium : ImageThemes.large}
      />
      <ModalControls
        showCloseButton
        closeButtonSize={16}
        onDismiss={dismissModal}
        modalName="image modal"
      />
    </ModalWrapper>
  );
};

export default connector(connectModal, connectResponsive)(withT(ImageModal));
