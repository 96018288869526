import { APP_TYPES } from 'Webapp/action-types';
import { VIEWPORT_TYPES } from 'Utils/viewport-types.js';
import { AppTheme } from 'Webapp/enums';
import { USAGE_NAV_FROM_OVERRIDES } from 'Utils/analytics/usage.js';
import Config from '../../../../shared/config';
import GlobalVars from 'Utils/global-vars';
import { merger } from 'Utils/redux';
import { RouteTypes } from 'Webapp/shared/routes';

interface AppReducerState {
  isAmp: boolean;
  isRSS: boolean;
  notFound: boolean;
  readyForMetrics: boolean;
  title: null | string;
  forcedTraceLog: null | boolean;
  socialGraphTitle: null | string;
  metaDescription: null | string;
  socialGraphDescription: null | string;
  pageCanonicalPath: null | string;
  experiments: unknown;
  experimentOverrides: null;
  noExperiments: boolean;
  viewportType: string; // TODO enumify VIEWPORT_TYPES;
  nglFeedConfigs: Array<unknown>;
  appTheme: string; // TODO enumify AppTheme.DEFAULT;
  appThemeOverride: null | string;
  navAppStoreUrl: string;
  serverComponent: unknown;
  showAmpStory: boolean;
  banners: Array<unknown>;
  appUrl: null | string;
  jobid: null | string;
  googleAdVisibility: number;
  isNewUser: boolean;
  navFromOverride: null | string;
  adjustNavFrom: undefined | string;
  adjustSectionId: undefined | string;
  isFromCrawler: boolean;
  isFromConfirmation: boolean;
  userFeatures: Record<string, unknown>; // TODO enumify;
  lang: string;
  locale: string;
  isChromeless: boolean;
  isEmbeddable: boolean;
  isGateDismissed: boolean;
  isMobileGateSuppressed: boolean;
  isWebView: boolean;
  isIos: boolean;
  isFromBriefing: boolean;
  utmParams: Record<string, unknown>;
  referrer: null | string;
  activationReferrer: null | string;
  shownVideoAd: boolean;
  didNavigate: boolean;
  didScrollToBottom: boolean;
  breadcrumbs: Array<Flipboard.Breadcrumb>;
  routing: {
    url: string;
    type: RouteTypes | undefined;
    match: {
      params: Record<string, unknown>;
    };
    params: Record<string, unknown>;
    query: Record<string, string>;
    path: string;
    pathname: string;
    search: string;
  };
  seoExperiments: Array<{ experiment: number; group: number }>;
  flurl: null | string;
  serviceWorkerRegistered: boolean;
  videoAdsEnabled: null | boolean;
  mobileHomeTileSections: Array<unknown>;
  sharedLinkData: Flipboard.SharedLinkData | null;
  featureFlagABTestSetupComplete: boolean;
  hideMainNavCTAs: boolean;
  triggerInviteOnboarding: boolean;
  mastodonVerificationUrl?: string;
  activityPubAlternate?: string;
}

const initialState = {
  isAmp: false,
  isRSS: false,
  notFound: false,
  readyForMetrics: false,
  title: null,
  forcedTraceLog: null,
  socialGraphTitle: null,
  metaDescription: null,
  socialGraphDescription: null,
  pageCanonicalPath: null,
  experiments: null,
  experimentOverrides: null,
  noExperiments: GlobalVars.isTest ? true : false,
  viewportType: VIEWPORT_TYPES.PHONE,
  nglFeedConfigs: [],
  appTheme: AppTheme.DEFAULT,
  appThemeOverride: null,
  navAppStoreUrl: Config.APP_STORE_URL_FOR_NAV,
  serverComponent: null,
  showAmpStory: false,
  banners: [],
  appUrl: null,
  jobid: null,
  googleAdVisibility: 0,
  isNewUser: false,
  navFromOverride: null,
  adjustNavFrom: undefined,
  adjustSectionId: undefined,
  isFromCrawler: false,
  isFromConfirmation: false,
  userFeatures: {},
  lang: 'en',
  locale: 'en_US',
  isChromeless: false,
  isEmbeddable: false,
  isGateDismissed: false,
  isMobileGateSuppressed: false,
  isWebView: false,
  isIos: false,
  isFromBriefing: false,
  utmParams: {},
  referrer: null,
  activationReferrer: null,
  shownVideoAd: false,
  didNavigate: false,
  didScrollToBottom: false,
  breadcrumbs: [],
  routing: {
    url: '/',
    type: undefined,
    match: {
      params: {},
    },
    params: {},
    query: {},
    path: '/',
    pathname: '/',
    search: '',
  },
  seoExperiments: [],
  flurl: null,
  serviceWorkerRegistered: false,
  videoAdsEnabled: null,
  mobileHomeTileSections: [],
  triggerInviteOnboarding: false,
  sharedLinkData: null,
  featureFlagABTestSetupComplete: false,
  hideMainNavCTAs: false,
};

// TODO: Revisit when final amp story decision is made
// function shouldRenderFullScreenComponent(state) {
//   const { isAmp, serverComponent, isAlbum } = state;
//   return isAmp && /Article/.test(serverComponent) && isAlbum;
// }

function stateWithFullScreenUpdates(state: AppReducerState) {
  // update props related to full screen rendering
  // TODO: Refactor if/when other scenarios necessitate full screen
  // rendering besides showing AMP stories
  // TODO: Revisit when final amp story decision is made
  const showAmpStory = false;
  // const showAmpStory = shouldRenderFullScreenComponent(state);
  let newState = merger<AppReducerState>(state)({
    showAmpStory,
  });
  if (showAmpStory) {
    newState = merger(newState)({
      appTheme: AppTheme.DARK,
    });
  }
  return newState;
}

export default function appReducer(
  state: AppReducerState = initialState,
  action,
) {
  const merge = merger(state);
  switch (action.type) {
    case APP_TYPES.SET_APP_IS_AMP: {
      return stateWithFullScreenUpdates(merge(action.payload));
    }
    case APP_TYPES.GET_EXPERIMENTS_SUCCEEDED: {
      return merge({
        readyForMetrics: !GlobalVars.isServer(),
        experiments: action.payload.experiments,
      });
    }
    case APP_TYPES.GET_EXPERIMENTS_FAILED: {
      return merge({
        readyForMetrics: !GlobalVars.isServer(),
      });
    }
    case APP_TYPES.CLEAR_APP_ERRORS: {
      return merge({ notFound: false });
    }
    case APP_TYPES.SET_ADJUST_NAV_FROM: {
      const { adjustNavFrom } = action.payload;
      // Do not allow resetting overriden nav_from values
      if (
        state.adjustNavFrom !== undefined &&
        USAGE_NAV_FROM_OVERRIDES.includes(state.adjustNavFrom)
      ) {
        return state;
      }
      return merge({ adjustNavFrom });
    }
    case APP_TYPES.SET_SERVER_COMPONENT: {
      const { serverComponent } = action.payload;
      if (!serverComponent) {
        return state;
      }
      const componentRegex = /^Connect\((.*)\)$/;
      const matches = serverComponent.match(componentRegex);
      if (matches && matches.length > 1) {
        return stateWithFullScreenUpdates(
          merge({
            serverComponent: matches[1],
          }),
        );
      }
      return state;
    }
    case APP_TYPES.SET_APP_LOCALE: {
      const { lang, locale } = action;
      return merge({ lang, locale });
    }
    case APP_TYPES.REFRESH_APP_BANNERS: {
      // Gives new instance to banners, to that changes are triggered in the banner component
      // This is because we use localStorage to determine visible banners
      return merge({ banners: [...state.banners] });
    }
    case APP_TYPES.SET_APP_SOCIAL_GRAPH_TITLE:
    case APP_TYPES.SET_APP_SOCIAL_GRAPH_DESCRIPTION:
    case APP_TYPES.SET_APP_TITLE:
    case APP_TYPES.SET_APP_IS_RSS:
    case APP_TYPES.SET_APP_PAGE_CANONICAL_PATH:
    case APP_TYPES.SET_APP_NOT_FOUND:
    case APP_TYPES.SET_APP_VIEWPORT_TYPE:
    case APP_TYPES.SET_ADJUST_SECTION_ID:
    case APP_TYPES.SET_NAV_FROM_OVERRIDE:
    case APP_TYPES.SET_APP_BANNERS:
    case APP_TYPES.SET_EXPERIMENT_OVERRIDES:
    case APP_TYPES.SET_APP_THEME_OVERRIDE:
    case APP_TYPES.SET_IS_NEW_USER:
    case APP_TYPES.SET_NO_EXPERIMENTS:
    case APP_TYPES.SET_IS_GATE_DISMISSED:
    case APP_TYPES.SET_IS_EMBEDDABLE:
    case APP_TYPES.SET_IS_WEBVIEW:
    case APP_TYPES.SET_IS_IOS:
    case APP_TYPES.SET_NGL_FEED_CONFIGS:
    case APP_TYPES.SET_SUPPRESS_MOBILE_GATE:
    case APP_TYPES.SET_REFERRER:
    case APP_TYPES.SET_ACTIVATION_REFERRER:
    case APP_TYPES.SET_IS_FROM_BRIEFING:
    case APP_TYPES.SET_IS_CHROMELESS:
    case APP_TYPES.SET_IS_INTEGRATION:
    case APP_TYPES.SET_APP_THEME:
    case APP_TYPES.SET_NAV_APP_STORE_URL:
    case APP_TYPES.SET_IS_FROM_CRAWLER:
    case APP_TYPES.SET_IS_FROM_CONFIRMATION:
    case APP_TYPES.SET_IS_FROM_INVITATION:
    case APP_TYPES.SET_APP_URL:
    case APP_TYPES.SET_UTM_PARAMS:
    case APP_TYPES.SET_GOOGLE_AD_VISIBILITY:
    case APP_TYPES.SET_SHOWN_VIDEO_AD:
    case APP_TYPES.SET_DID_NAVIGATE:
    case APP_TYPES.SET_DID_SCROLL_TO_BOTTOM:
    case APP_TYPES.SET_FORCED_TRACE_LOG:
    case APP_TYPES.SET_APP_META_DESCRIPTION:
    case APP_TYPES.SET_SEO_EXPERIMENTS:
    case APP_TYPES.SET_BREADCRUMBS:
    case APP_TYPES.RESET_BREADCRUMBS:
    case APP_TYPES.SET_ROUTING:
    case APP_TYPES.SET_VIDEO_ADS_ENABLED:
    case APP_TYPES.SET_HOME_TILE_SECTIONS:
    case APP_TYPES.SET_JOBID:
    case APP_TYPES.SET_TRIGGER_INVITE_ONBOARDING:
    case APP_TYPES.SET_SERVICE_WORKER_REGISTERED:
    case APP_TYPES.SET_HIDE_MAIN_NAV_CTAS:
    case APP_TYPES.SET_MASTODON_VERIFICATION_URL:
    case APP_TYPES.SET_ACTIVITY_PUB_ALTERNATE:
    case APP_TYPES.SET_FEATURE_FLAG_AB_TEST_SETUP_COMPLETE: {
      return merge(action.payload);
    }
    default:
      return state;
  }
}
