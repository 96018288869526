import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Context

// Libs
import { imageUrl } from 'Utils/image-util';

// Components
import Image from '../base/image';
import ItemOverlay from '../item/card/item-overlay';

import connector from 'Utils/connector';
import connectAmp from 'Webapp/shared/app/connectors/connectAmp';
import ItemLink from '../item/item-link';

class Audio extends Component {
  constructor(props) {
    super(props);

    this.iframeContainer = React.createRef();

    this.state = {
      playingAudio: false,
    };

    this.handlePlayInline = this.handlePlayInline.bind(this);
  }

  handlePlayInline() {
    this.setState({
      playingAudio: true,
    });
  }

  render() {
    const {
      item,
      section,
      ampHeight,
      useCrop,
      fallbackElement,
      isAmp,
      shownInList,
    } = this.props;
    const { audioURL, image } = item;
    const imageURL = imageUrl(image);
    const { soundcloudTrackId } = item;

    if (isAmp) {
      if (item.isSoundcloud) {
        if (soundcloudTrackId === null) {
          return null;
        }
        return (
          <div className="audio-container">
            <amp-soundcloud
              layout="fixed-height"
              width="auto"
              height={ampHeight}
              data-trackid={soundcloudTrackId}
            />
          </div>
        );
      } else if (audioURL) {
        return (
          <div className="audio-container">
            <amp-audio
              layout="fixed-height"
              width="auto"
              height={ampHeight}
              src={audioURL}
              artwork={imageURL}
            />
          </div>
        );
      }
    }
    // HTML rendering
    // Playing audio
    // TODO: Support custom HTML5 audio players for non-soundcloud https://jira.flipboard.com/browse/WEB-6549
    if (this.state.playingAudio) {
      const setBackgroundImage = () => {
        // Wait till the iframe loads to set the background image, otherwise the change is too abrupt
        imageURL &&
          this.iframeContainer.current.setAttribute(
            'style',
            `background-image: url(${imageURL})`,
          );
      };
      const soundcloudSrc = `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${soundcloudTrackId}`;
      return (
        <div
          className="media-container responsive-iframe soundcloud-container"
          ref={this.iframeContainer}
        >
          <iframe
            onLoad={setBackgroundImage}
            src={soundcloudSrc}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          />
        </div>
      );
    }
    // Static image
    const imageElement = (
      <Image
        image={image}
        ampHeight={ampHeight}
        alt={item.title}
        fallbackElement={fallbackElement}
        crop={useCrop}
      />
    );
    if (!item.isSoundcloud || !soundcloudTrackId) {
      return (
        <ItemLink item={item} section={section} shownInList={shownInList}>
          {imageElement}
        </ItemLink>
      );
    }
    return (
      <div className="image-frame" onClick={this.handlePlayInline}>
        {imageElement}
        <ItemOverlay item={item} size={90} border shownInList={shownInList} />
      </div>
    );
  }
}

Audio.propTypes = {
  item: PropTypes.object.isRequired,
  section: PropTypes.object,
  ampHeight: PropTypes.number,
  useCrop: PropTypes.bool,
  isAmp: PropTypes.bool,
  fallbackElement: PropTypes.element,
  shownInList: PropTypes.bool,
};

Audio.defaultProps = {
  ampHeight: 180,
  useCrop: true,
  isAmp: false,
  fallbackElement: null,
};

export default connector(connectAmp)(Audio);
