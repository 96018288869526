import React from 'react';
import { GA } from 'Utils/analytics';
import classNames from 'classnames';

interface UnstyledButtonProps {
  name: string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  stopPropagation?: false;
}

const UnstyledButton: React.FC<UnstyledButtonProps> = ({
  name,
  children,
  onClick,
  className,
  stopPropagation,
  ...props
}) => {
  const handleClick = (e) => {
    if (name) {
      GA.trackClickButton(name);
    }
    if (stopPropagation !== false) {
      e.stopPropagation();
    }
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button
      className={classNames('button--unstyled', className)}
      onClick={handleClick}
      data-vars-button-name={name}
      role="button"
      {...props}
    >
      <div className="button--unstyled__children">{children}</div>
    </button>
  );
};

export default UnstyledButton;
