import { FlapNotificationType } from 'Webapp/enums';

export default (notification) => {
  if (
    !Object.values(FlapNotificationType).includes(notification.notificationType)
  ) {
    return true;
  }
  return false;
};
