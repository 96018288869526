import getWindow from 'Utils/get-window';

const LOCAL_STORAGE_EDU_KEY = 'viewed_edu';

/**
 * Retrieves an array of dimissed EDU hints from browser localStorage
 * @returns {Array}
 */
function getEduHintsFromLocalStorage() {
  const { localStorage } = getWindow();
  const storageItem = localStorage.getItem(LOCAL_STORAGE_EDU_KEY);
  if (!storageItem) {
    return null;
  }
  try {
    const dismissedEduHints = JSON.parse(storageItem);
    return Array.isArray(dismissedEduHints) ? dismissedEduHints : null;
  } catch (_error) {
    localStorage.removeItem(LOCAL_STORAGE_EDU_KEY);
    return null;
  }
}

/**
 * Checks if a specific EDU hint has been dismissed
 * @param {String} eduName
 * @returns {Boolean}
 */
function hasDismissed(eduName) {
  const dismissedEduHints = getEduHintsFromLocalStorage();
  return dismissedEduHints ? dismissedEduHints.includes(eduName) : false;
}

/**
 * Adds the given EDU name into the list of dismissed EDUs, then persists in localStorage
 * @param {String} eduName
 */
function dismissEdu(eduName) {
  const dismissedEduHints = getEduHintsFromLocalStorage() || [];
  if (dismissedEduHints.includes(eduName)) {
    return;
  }
  localStorage.setItem(
    LOCAL_STORAGE_EDU_KEY,
    JSON.stringify(dismissedEduHints.concat(eduName)),
  );
}

export default { hasDismissed, dismissEdu };
