import React from 'react';

import codeSplitLoad from 'Webapp/shared/utils/code-split-load.js';

import { MODAL_THEMES } from 'ComponentLibrary/modals/modal.js';

import ItemCoreActionModal from 'Webapp/shared/app/modals/item-core-action-modal';

import connector from 'Webapp/utils/connector';
import connectComments, {
  ConnectCommentsProps,
} from 'Webapp/shared/app/connectors/connectComments';
import connectResponsive, {
  ConnectResponsiveProps,
} from 'Webapp/shared/app/connectors/connectResponsive';
import connectSocialActivity, {
  ConnectSocialActivityProps,
} from 'Webapp/shared/app/connectors/connectSocialActivity';
import { getSocialActivityValue } from 'Webapp/shared/concepts/social-activity';

const Comments = codeSplitLoad('Comments');

export interface CommentsModalPassedProps {
  item: Flipboard.Item;
  section: Flipboard.Section;
  expandCommentsHeader: boolean;
}

type CommentsModalProps = ConnectCommentsProps &
  CommentsModalPassedProps &
  ConnectResponsiveProps &
  ConnectSocialActivityProps;

const CommentsModal: React.FC<CommentsModalProps> = ({
  item,
  isPhone,
  section,
  socialActivity,
  expandCommentsHeader,
  purgeComments,
}) => {
  // TODO enum MODAL_THEMES
  let modalTheme: string;

  const commentCount = getSocialActivityValue(
    item,
    socialActivity,
    'commentCount',
  );
  const oneOrMoreComments = commentCount && commentCount > 0;

  if (isPhone) {
    modalTheme = oneOrMoreComments
      ? MODAL_THEMES.FULLSCREEN
      : MODAL_THEMES.BOTTOM_SHEET;
  } else {
    modalTheme = MODAL_THEMES.WINDOW;
  }

  return (
    <ItemCoreActionModal
      item={item}
      section={section}
      name="comments"
      modalTheme={modalTheme}
      onDismiss={purgeComments}
      expandCommentsHeader={expandCommentsHeader}
    >
      <Comments
        item={item}
        section={section}
        condensedHeader={isPhone ? oneOrMoreComments : undefined}
        expandCommentsHeader={expandCommentsHeader}
      />
    </ItemCoreActionModal>
  );
};

export default connector<CommentsModalProps>(
  connectComments,
  connectSocialActivity,
  connectResponsive,
)(CommentsModal);
