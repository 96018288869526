import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';
import { OnboardingFlow, SocialActionType } from 'Webapp/enums';
// Components
import SocialActionGate from 'Webapp/shared/app/containers/modals/social-action-gate';
import MobileSocialActionGate from 'Webapp/shared/app/containers/modals/mobile-social-action-gate';

import { isPhone as isPhoneSelector } from 'Webapp/shared/app/redux/selectors/viewport.js';

// actions
import {
  showModal,
  dismissSpecificModal,
} from 'Webapp/shared/app/redux/actions/modal-actions';

const showSocialGateMagazineInvite: OnboardingStep =
  (nextStep) => (dispatch, getState) => {
    const isPhone = isPhoneSelector(getState());
    const featureFlags = getState().featureFlags;
    if (isPhone) {
      dispatch(
        showModal(MobileSocialActionGate, {
          onComplete: () => {
            dispatch(dismissSpecificModal(MobileSocialActionGate));
            nextStep();
          },
        }),
      );
    } else {
      dispatch(
        showModal(SocialActionGate, {
          onboardingFlow: featureFlags.INVITE_MAGAZINE_CONTRIBUTOR
            ? OnboardingFlow.ACCEPT_MAGAZINE_INVITE_SIGNUP_NEW
            : OnboardingFlow.ACCEPT_MAGAZINE_INVITE_SIGNUP_OLD,
          continueFlow: true,
          onComplete: () => {
            dispatch(dismissSpecificModal(SocialActionGate));
            nextStep();
          },
          actionType: SocialActionType.ACCEPT_INVITE,
        }),
      );
    }
  };

export default showSocialGateMagazineInvite;
