import { connect } from 'react-redux';

const getValidThanksSubscription = (userInfo) => {
  if (!userInfo || !userInfo.myServices) {
    return false;
  }
  const thanks = userInfo.myServices.find((s) => s.service === 'thanks');
  if (!thanks) {
    return false;
  }
  return thanks.subscriptionLevel === 'all';
};

const withThanksSubscription = (Component) => {
  const mapStateToProps = ({ profile: { userInfo } }) => ({
    validThanksSubscription: getValidThanksSubscription(userInfo),
  });

  return connect(mapStateToProps)(Component);
};

export default withThanksSubscription;
