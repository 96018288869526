import React from 'react';

import codeSplitLoad from 'Webapp/shared/utils/code-split-load.js';
const Comments = codeSplitLoad('Comments');

class PreloadUserComponents extends React.Component {
  // on mount we fetch some more split code that would slow down the
  // user if they weren't preloaded by the time the user caused them
  // to mount
  componentDidMount() {
    Comments.preload();
  }
  render() {
    return null;
  }
}
export default PreloadUserComponents;
