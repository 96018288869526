import { styleObject, StyleObject } from 'Style/type-helpers';

type Helper = (styleObject: StyleObject) => Record<string, StyleObject>;
type Helpers = Record<string, Helper>;
const helpers: Helpers = {
  // Should be used sparingly, for small phone layout bugs
  smallPhone: (style) => ({ '@media  (max-width: 374px)': style }),
  phone: (style) => ({ '@media (max-width: 599px)': styleObject(style) }),
  tabletPortraitUp: (style) => ({ '@media (min-width: 600px)': style }),
  tabletPortraitOnly: (style) => ({
    '@media (min-width: 600px) and (max-width: 899px)': style,
  }),
  tabletPortraitDown: (style) => ({
    '@media (max-width: 899px)': style,
  }),
  tabletLandscapeUp: (style) => ({
    '@media (min-width: 900px)': style,
  }),
  tabletLandscapeOnly: (style) => ({
    '@media (min-width: 900px) and (max-width: 1199px)': style,
  }),
  tablet: (style) => ({
    '@media (min-width: 600px) and (max-width: 1199px)': style,
  }),
  tabletLandscapeDown: (style) => ({
    '@media (max-width: 1199px)': style,
  }),
  desktopUp: (style) => ({
    '@media (min-width: 1200px)': style,
  }),
  desktopLandscapeUp: (style) => ({
    '@media (min-width: 1800px)': style,
  }),
  thumbnailWidget: (style) => ({
    '@media (max-width: 499px) and (max-height: 499px)': style,
  }),
};

export const BREAKPOINTS = Object.freeze(helpers);
