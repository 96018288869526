import { Connector } from 'Webapp/utils/connector';
import { refreshForYou } from 'Webapp/shared/app/redux/actions/section-actions';

const actions = {
  refreshForYou,
};

export type ConnectRefreshForYouProps = Flipboard.ConnectorProps<
  unknown,
  typeof actions
>;

const connectRefreshForYou: Connector = {
  actions,
};

export default connectRefreshForYou;
