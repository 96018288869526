import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import formatDate from 'date-fns/format';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import addSeconds from 'date-fns/addSeconds';
import fromUnixTime from 'date-fns/fromUnixTime';

const minMs = 60 * 1000;
const hourMs = 60 * minMs;
const dayMs = 24 * hourMs;

/**
 * Format differs by age:
 * < 1 hour: 'Now' or optionally, 3 minutes
 * > 1 hour, < 1 day: '1h' to '23h'
 * > 1 day, < 7 days: short version ('2d' for 2 days)
 * > 7 days in same year: 'May 5'
 * > 7 days in previous years: full date (May 5, 2018)
 * @param {Number} timestamp    - Flipboard timestamp value (seconds since epoch)
 * @param {Function} t - i18n translation function
 * @param {String} lang - language to format string to
 * @param {Date} referenceTime      - (optional) Reference time to compare the timstamp against. Defaults to now.
 * @return {String}    - A friendly-formated string representation
 */
function friendlyFormat(timestamp, t, lang = 'en', referenceTime = null) {
  if (!timestamp) {
    return null;
  }

  const storyboardDate = new Date(timestamp * 1000);
  const now = referenceTime || new Date();
  const storyboardAge = now - storyboardDate;

  const storyboardAgeHours = storyboardAge / hourMs;
  const storyboardAgeDays = storyboardAge / dayMs;

  if (storyboardAgeHours < 1) {
    return t('date_util_now');
  }

  // Older than an hour, newer than 24 hours
  if (storyboardAgeHours < 24) {
    const hours = Math.floor(storyboardAgeHours);
    return t('datetime_relative', {
      datetime: `${hours} ${t('date_util_hour', { count: hours })}`,
    });
  }

  // older than a day, newer than 7 days
  if (storyboardAgeDays < 7) {
    const days = Math.floor(storyboardAgeDays);
    return t('datetime_relative', {
      datetime: `${days} ${t('date_util_day', { count: days })}`,
    });
  }

  // older than 7 days, same year
  const storyboardYear = storyboardDate.getFullYear();
  const currentYear = now.getFullYear();
  if (currentYear === storyboardYear) {
    return storyboardDate.toLocaleString(lang, {
      month: 'short',
      day: 'numeric',
    });
  }

  // older storyboard
  return storyboardDate.toLocaleString(lang, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
}

/**
 * Translates timestamps/times with optional translation key overrides
 * < 1 hour: nowKey
 * > 1 hour: hoursAgoKey
 * > 1 day: daysAgoKey
 * > 7 days in same year: param
 * > 7 days in previous years: fullDateKey
 * @param {Number|String} time          - Flipboard timestamp value (seconds since epoch) or ISO Date String
 * @param {Function} t                  - i18n translation function
 * @param {String} lang                 - (optional) Language
 * @param {Date} referenceTime          - (optional) Reference time to compare the timstamp against. TODO: this appears to be not used.
 * @param {String} nowKey               - (optional) i18n key for "now."  Defaults to "date_util_now"
 * @param {String} minutesAgoKey        - (optional) i18n key for "n hours ago" ages.  Defaults to "date_util_minute_ago_with_count"
 * @param {String} hoursAgoKey          - (optional) i18n key for "n hours ago" ages.  Defaults to "date_util_hour_ago_with_count"
 * @param {String} daysAgoKey           - (optional) i18n key for "n days ago" ages.  Defaults to "date_util_day_ago_with_count"
 * @param {String} fullDateKey          - (optional) i18n key for "> 7 days ago" ages. Defaults to null (just use Date.toLocaleString).
 * If specified, use translation interpolation key "timestamp" for the timestamp value.
 * @return {String}                     - The localized friendly time ago
 */
function friendlyFormatLocalized(
  time,
  t,
  lang = 'en',
  referenceTime,
  nowKey = 'date_util_now',
  minutesAgoKey = 'date_util_minute_ago_with_count',
  hoursAgoKey = 'date_util_hour_ago_with_count',
  daysAgoKey = 'date_util_day_ago_with_count',
  fullDateKey = null,
  displayTime = false,
) {
  if (!time) {
    return null;
  }
  const timeIn = typeof time === 'string' ? time : time * 1000;
  const date = new Date(timeIn);
  const now = referenceTime || new Date();
  const age = now - date;
  const ageDays = age / dayMs;
  const ageHours = age / hourMs;
  const ageMinutes = age / minMs;

  // newer than a minute
  if (ageMinutes < 1) {
    return t(nowKey);
  }

  // older than a minute, newer than an hour
  if (ageHours < 1 && ageMinutes > 1) {
    const minutes = Math.floor(ageMinutes);
    return t(minutesAgoKey, { count: minutes });
  }

  // older than an hour, newer than 24 hours
  if (ageHours > 1 && ageDays < 1) {
    const hours = Math.floor(ageHours);
    return t(hoursAgoKey, { count: hours });
  }

  // older than a day, newer than 7 days
  if (ageDays < 7) {
    const days = Math.floor(ageDays);
    return t(daysAgoKey, { count: days });
  }

  // older than 7 days
  const year = date.getFullYear();
  const currentYear = now.getFullYear();
  // vary format depending on same year or not
  const format =
    currentYear === year
      ? { month: 'long', day: 'numeric' }
      : {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        };

  if (displayTime) {
    Object.assign(format, { hour: 'numeric', minute: 'numeric' });
  }
  const localizedDate = date.toLocaleString(lang, format);

  // fullDateKey supplied for interpolation
  if (fullDateKey) {
    return t(fullDateKey, { timestamp: localizedDate });
  }

  return localizedDate;
}

/**
 * Return date from date or number epoch in seconds
 * @param {Date|Number} datelike - Date or Flipboard timestamp value (seconds since epoch)
 * @return {Date}
 */
const dateFromDatelike = (datelike) => {
  if (datelike instanceof Date) {
    return datelike;
  }
  return new Date(datelike * 1000);
};

/**
 * Format time with complete information
 * @param {Date|Number} datelike - Date or Flipboard timestamp value (seconds since epoch)
 * @param {Bool} useTime         - Determines whether or not to use the time, default is true
 * @return {String}              - A string representation with complete info: Example: April 23, 2018, 3:01 PM
 */
function completeFormat(
  datelike,
  lang = 'en',
  includeTime = true,
  includeTimezone = false,
) {
  const date = dateFromDatelike(datelike);
  const format = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  if (includeTime) {
    Object.assign(format, {
      hour: 'numeric',
      minute: 'numeric',
    });
  }
  if (includeTimezone) {
    Object.assign(format, {
      timeZoneName: 'short',
    });
  }
  return date.toLocaleString(lang, format);
}

/**
 * Builds a date object given the number of days from now/base date
 * as well as the absolute time to set it to.
 * @param {Number} daysFromNow - number of days to increment on top of "now"/baseDate
 * @param {Number} hour - absolute hour value, 1-12
 * @param {Number} minute - absolute minute value, 0-59
 * @param {String} period  - AM/PM
 * @param {Date} baseDate - base date to calculate from - defaults to now
 * @return {Date} - date with modified values
 */
function getDateFromNow(
  daysFromNow = 0,
  hour = 12,
  minute = 0,
  period = 'AM',
  baseDate = new Date(),
) {
  if (!Number.isInteger(parseInt(daysFromNow, 10))) {
    return null;
  }
  baseDate.setDate(baseDate.getDate() + parseInt(daysFromNow, 10));

  let computerHour = parseInt(hour, 10);
  if (period === 'AM' && computerHour === 12) {
    computerHour = 0;
  } else if (period === 'PM' && computerHour !== 12) {
    computerHour = computerHour + 12;
  }

  baseDate.setHours(computerHour, minute, 0);
  return baseDate;
}

/**
 * Performs the inverse of getDateFromNow(), where a timestamp is given,
 * and the number of days from "now"/baseDate, absolute hour, minute and period
 * value are also calculated
 * @param {Number} timestamp - Unix timestamp
 * @param {Date Object} baseDate - optional base date (mostly used for tests)
 * @return {Object} contains breakdown of date compared to "now"/baseDate
 */
function getDateBreakdown(timestamp, baseDate = new Date()) {
  const date = new Date(timestamp);
  let hour = date.getHours();
  const period = hour >= 12 ? 'PM' : 'AM';
  const minute = date.getMinutes();
  hour = hour > 12 ? hour - 12 : hour;
  const daysFromNow = differenceInCalendarDays(date, baseDate);
  return { hour, minute, period, daysFromNow };
}

/**
 * As date-fns differenceInHours returns integers only, calculate more accurate
 * hour difference with decimals
 * @param {Date} leftDate
 * @param {Date} rightDate
 * @return {Number}
 */
function getGranularHoursDifference(leftDate, rightDate) {
  return differenceInMinutes(leftDate, rightDate) / 60;
}

/**
 * Format seconds to simple human readable duration
 * @param {Number} duration - Number of seconds
 * @return {String}         - string formatted duration
 */
export const durationFormat = (duration) => {
  if (!duration) {
    return '--:--';
  }
  return formatDate(addSeconds(new Date(0), parseInt(duration, 10)), 'm:ss');
};

/**
 * Format seconds to ISO8601 duration
 * @param {Number} duration - Number of seconds
 * @return {String}         - string formatted duration
 */
// https://en.wikipedia.org/wiki/ISO_8601#Durations
export const schemaDurationFormat = (duration) => {
  let seconds = 0,
    minutes = 0,
    hours = 0;
  if (duration) {
    const date = addSeconds(new Date(0), parseInt(duration, 10));
    seconds = date.getUTCSeconds();
    minutes = date.getUTCMinutes();
    hours = date.getUTCHours();
  }
  return `PT${hours}H${minutes}M${seconds}S`;
};

/**
 * Converts unixTime to iso date string
 * @param {Number} unixTime - unix timestamp
 * @return {String}         - string formatted to iso string
 */
export const schemaDate = (unixTime) => fromUnixTime(unixTime).toISOString();

/**
 * Translates timestamps with optional translation key overrides
 * @param {Number} timestamp            - Flipboard timestamp value (seconds since epoch)
 * @param {Function} t                  - i18n translation function
 * @param {Date} referenceTime          - (optional) Reference time to compare the timstamp against.
 * @param {String} nowKey               - (optional) i18n key for "now."  Defaults to "date_util_now"
 * @param {String} minutesAbbrKey       - (optional) i18n key for "n hours ago" ages.  Defaults to "date_util_minute_ago_abbr_with_count"
 * @param {String} hoursAbbrKey         - (optional) i18n key for "n hours ago" ages.  Defaults to "date_util_hour_ago_abbr_with_count"
 * @param {String} daysAbbrKey          - (optional) i18n key for "n days ago" ages.  Defaults to "date_util_day_ago_abbr_with_count"
 */
export const shortFormatLocalized = (
  timestamp,
  t,
  referenceTime,
  nowKey = 'date_util_now',
  minutesAbbrKey = 'date_util_minute_ago_abbr_with_count',
  hoursAbbrKey = 'date_util_hour_ago_abbr_with_count',
  daysAbbrKey = 'date_util_day_ago_abbr_with_count',
) => {
  if (!timestamp) {
    return null;
  }
  const date = new Date(timestamp * 1000);
  const now = referenceTime || new Date();
  const age = now - date;
  const ageDays = age / dayMs;
  const ageHours = age / hourMs;
  const ageMinutes = age / minMs;

  // newer than a minute
  if (ageMinutes < 1) {
    return t(nowKey);
  }

  // older than a minute, newer than an hour
  if (ageHours < 1 && ageMinutes > 1) {
    const minutes = Math.floor(ageMinutes);
    return t(minutesAbbrKey, { count: minutes });
  }

  // older than an hour, newer than 24 hours
  if (ageHours > 1 && ageDays < 1) {
    const hours = Math.floor(ageHours);
    return t(hoursAbbrKey, { count: hours });
  }

  const days = Math.floor(ageDays);
  return t(daysAbbrKey, { count: days });
};

export default {
  friendlyFormat,
  friendlyFormatLocalized,
  completeFormat,
  getDateFromNow,
  getDateBreakdown,
  getGranularHoursDifference,
};
