import { Breadcrumbs } from 'Utils/content/structured-data';

export default ({ sitemapIndex }) => {
  if (!sitemapIndex) {
    return null;
  }
  const breadcrumbs = new Breadcrumbs();
  breadcrumbs.add('Sitemap', '/sitemap');
  return breadcrumbs;
};
