import React from 'react';

import codeSplitLoad from 'Webapp/shared/utils/code-split-load';

// Components
import { StyleVariations } from 'Webapp/shared/app/components/button';
import CreateFlipIcon from 'ComponentLibrary/icons/create-flip';
import DefaultItemActionButton from 'Webapp/shared/app/components/item-actions/default-item-action-button';

import connector from 'Webapp/utils/connector';
import connectFeatureFlags, {
  ConnectFeatureFlagsProps,
} from 'Webapp/shared/app/connectors/connectFeatureFlags';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';
import connectResponsive, {
  ConnectResponsiveProps,
} from 'Webapp/shared/app/connectors/connectResponsive';
import connectUsageTrackFlip, {
  ConnectUsageTrackFlipProps,
} from 'Webapp/shared/app/connectors/connectUsageTrackFlip';
import connectUsageSetNavFrom, {
  ConnectUsageSetNavFromProps,
} from 'Webapp/shared/app/connectors/connectUsageSetNavFrom';
import withT from 'ComponentLibrary/hocs/withT.js';
import connectCurrentUser, {
  ConnectCurrentUserProps,
} from 'Webapp/shared/app/connectors/connectCurrentUser';
import {
  USAGE_EVENT_NAMES,
  USAGE_NAV_FROMS,
} from 'Webapp/utils/analytics/usage';
import RequireVerifiedEmail from 'Webapp/shared/app/modals/require-verified-email';

const FlipComposeModal = codeSplitLoad('FlipComposeModal');

type CreateStoryButtonProps = {
  section: Flipboard.Section;
  t: Flipboard.TFunction;
} & ConnectFeatureFlagsProps &
  ConnectModalProps &
  ConnectResponsiveProps &
  ConnectUsageTrackFlipProps &
  ConnectUsageSetNavFromProps &
  ConnectCurrentUserProps;

const CreateStoryButton: React.FC<CreateStoryButtonProps> = ({
  section,
  featureFlags,
  showModal,
  usageTrackTapFlip,
  usageSetNavFrom,
  isSmallScreen,
  isPhone,
  currentUser,
  t,
}) => {
  if (
    !featureFlags.NEW_MAGAZINE_COVER &&
    !featureFlags.NEW_FLIP_COMPOSE &&
    !featureFlags.ADD_TO_MAGAZINE_BUTTON
  ) {
    return null;
  }
  let styleVariation = isSmallScreen
    ? StyleVariations.PRIMARY_TEXT
    : StyleVariations.SECONDARY;
  if (featureFlags.ADD_TO_MAGAZINE_BUTTON && !featureFlags.NEW_MAGAZINE_COVER) {
    styleVariation = isPhone ? StyleVariations.NONE : StyleVariations.PRIMARY;
  }

  const handleShowModal = () => {
    if (section.exchange && !currentUser?.confirmedEmail) {
      showModal(RequireVerifiedEmail, {
        magazineInvite: true,
      });
    } else {
      showModal(FlipComposeModal, {
        currentMagazine: section,
        navFrom: USAGE_NAV_FROMS.MAGAZINE_HEADER,
      });
    }
  };

  return (
    <DefaultItemActionButton
      styleVariation={styleVariation}
      onClick={() => {
        usageSetNavFrom(
          USAGE_EVENT_NAMES.TAP_FLIP,
          USAGE_NAV_FROMS.MAGAZINE_HEADER,
        );
        usageTrackTapFlip();
        handleShowModal();
      }}
    >
      {featureFlags.NEW_MAGAZINE_COVER && <CreateFlipIcon size={18} isFilled />}

      <span>{t('add_to_magazine')}</span>
    </DefaultItemActionButton>
  );
};

export default connector<CreateStoryButtonProps>(
  connectModal,
  connectResponsive,
  connectFeatureFlags,
  connectUsageTrackFlip,
  connectUsageSetNavFrom,
  connectCurrentUser,
)(withT(CreateStoryButton));
