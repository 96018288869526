import { AUTH_TYPES, SECTIONS_TYPES, PROFILE_TYPES } from 'Webapp/action-types';
import { merger } from 'Utils/redux';

interface AuthReducerState {
  uid: Flipboard.UserId;
  udid: number;
  tuuid: number;
  isAccessTokenInvalid: boolean;
  authForm: {
    realName: string;
    email: string;
    username: string;
    password: string;
    errorMessage: null | string;
    passwordStrength: null | Flipboard.PasswordStrength;
    requestToken: null | string;
    isLoading: boolean;
    isLoadingPasswordStrength: boolean;
  };
  signupCompleted: boolean;
}

const initialState: AuthReducerState = {
  uid: 0 as Flipboard.UserId,
  udid: 0,
  tuuid: 0,
  isAccessTokenInvalid: false,
  authForm: {
    realName: '',
    email: '',
    username: '',
    password: '',
    errorMessage: null,
    passwordStrength: null,
    requestToken: null, // used by QR Login
    isLoading: false,
    isLoadingPasswordStrength: false,
  },
  signupCompleted: false,
};

// TODO this type isn't as good as it should be, ideally we should not
// have to do this, we should just use Flipboard.Action, but it will
// require you to conform to using `payload`, which means all these
// actions need updated
interface Action {
  type: Flipboard.Action<AUTH_TYPES & SECTIONS_TYPES & PROFILE_TYPES>['type'];
  data: { uid: Flipboard.UserId; udid: number; tuuid: number };
  payload: Partial<AuthReducerState>;
  changes: Record<string, unknown>;
  errorMessage: string;
  error: string;
}

export default function authReducer(
  state: AuthReducerState = initialState,
  action: Action,
) {
  const merge = merger<AuthReducerState>(state);
  switch (action.type) {
    case AUTH_TYPES.GET_AUTH_TOKEN: {
      const uid = action.data.uid || 0;
      const parsedUid =
        typeof uid === 'string' ? Number.parseInt(uid, 10) : uid;

      return merge({
        uid: parsedUid as unknown as Flipboard.UserId,
        udid: action.data.udid,
        tuuid: action.data.tuuid,
      });
    }
    case AUTH_TYPES.RESET_AUTH_FORM: {
      return merge({ authForm: initialState.authForm });
    }
    case PROFILE_TYPES.GET_PROFILE_UNAUTHORIZED:
    case SECTIONS_TYPES.GET_SECTION_UNAUTHORIZED: {
      if (state.uid === (0 as Flipboard.UserId)) {
        return state;
      }
      return merge({ isAccessTokenInvalid: true });
    }
    case AUTH_TYPES.FRICTIONLESS_SET_UID: {
      return merge(action.payload);
    }
    case AUTH_TYPES.AUTH_FORM_CHANGE: {
      return merge({
        authForm: {
          ...state.authForm,
          ...action.changes,
        },
      });
    }
    case AUTH_TYPES.LOGIN_PENDING:
    case AUTH_TYPES.SIGNUP_PENDING: {
      return merge({
        authForm: {
          ...state.authForm,
          isLoading: true,
          errorMessage: null,
        },
      });
    }
    case AUTH_TYPES.LOGIN_FAILURE:
    case AUTH_TYPES.SIGNUP_FAILURE: {
      return merge({
        authForm: {
          ...state.authForm,
          errorMessage: action?.errorMessage,
          isLoading: false,
        },
      });
    }
    case AUTH_TYPES.CONNECT_WITH_SSO_FAILURE: {
      return merge({
        authForm: {
          ...state.authForm,
          errorMessage: action.error,
          isLoading: false,
        },
      });
    }
    case AUTH_TYPES.GET_PASSWORD_STRENGTH_PENDING: {
      return merge({
        authForm: {
          ...state.authForm,
          isLoadingPasswordStrength: true,
        },
      });
    }
    case AUTH_TYPES.GET_PASSWORD_STRENGTH_SUCCESS: {
      return merge({
        authForm: {
          ...state.authForm,
          passwordStrength:
            action.data as unknown as Flipboard.PasswordStrength,
          isLoadingPasswordStrength: false,
        },
      });
    }
    case AUTH_TYPES.SET_AUTH_FORM_LOADING: {
      return merge({
        authForm: {
          ...state.authForm,
          isLoading: action.payload.authForm?.isLoading || false,
        },
      });
    }
    case AUTH_TYPES.SET_SIGNUP_COMPLETED: {
      return merge(action.payload);
    }
    case AUTH_TYPES.LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
}
