import React from 'react';
import PropTypes from 'prop-types';
import withT from 'ComponentLibrary/hocs/withT';

// Components
import ModalWrapper from './modal-wrapper';
import ModalControls from 'Webapp/shared/app/components/modal-controls';
import { MODAL_WINDOW_SIZES } from 'ComponentLibrary/modals/modal';
import Image from '../components/base/image';

import connector from 'Utils/connector';
import connectModal from 'Webapp/shared/app/connectors/connectModal';

const SIGNUP_CTA_GET_APP_MODAL_DISPLAY_NAME = 'signup-cta-get-app-modal';

function SignupCtaGetAppModal({ dismissModal, t }) {
  return (
    <ModalWrapper
      name={SIGNUP_CTA_GET_APP_MODAL_DISPLAY_NAME}
      className="signup-cta-get-app-modal"
      size={MODAL_WINDOW_SIZES.MEDIUM}
    >
      <ModalControls
        modalName={SIGNUP_CTA_GET_APP_MODAL_DISPLAY_NAME}
        showCloseButton
        fixedControls
        verticalControls
        onDismiss={dismissModal}
      >
        <h1 className="signup-cta-get-app-modal__title brand-text--title">
          {t('qr_app_download_title')}
        </h1>
        <Image image="https://s3.amazonaws.com/s.flipboard.com/assets/webu/images/signup-cta-get-app-qr-code.png" />
      </ModalControls>
    </ModalWrapper>
  );
}

SignupCtaGetAppModal.propTypes = {
  dismissModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default connector(connectModal)(withT(SignupCtaGetAppModal));
