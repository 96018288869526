import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Utils
import sharedRoutes, { ErrorRoute } from 'Webapp/shared/routes';

// Components
import Loading from './loading';
import ErrorView from '../views/error';
import TopNavbar from '../containers/top-navbar';
import Banner from '../containers/banner';
import TopLevelRoute from '../containers/top-level-route';

import connector from 'Utils/connector';
import connectRouting from 'Webapp/shared/app/connectors/connectRouting';

const componentCache = {};

interface TopLevelRoutesProps {
  notFound: boolean;
  caughtError: boolean;
  isLoggedIn: boolean;
  routing: Flipboard.State['app']['routing'];
  shouldRenderBanner: boolean;
}

const TopLevelRoutes: React.FC<TopLevelRoutesProps> = ({
  notFound,
  caughtError,
  isLoggedIn,
  routing: { path },
  shouldRenderBanner,
}) => (
  <Switch>
    {notFound || caughtError ? (
      <Route
        key={ErrorRoute.path}
        path={ErrorRoute.path}
        render={() => (
          <TopLevelRoute type={ErrorRoute.type}>
            <TopNavbar />
            <ErrorView code={notFound ? '404' : '503'} path={path} />
          </TopLevelRoute>
        )}
      />
    ) : (
      sharedRoutes.map((route) => {
        const key =
          typeof route.path === 'string' ? route.path : route.path.join('|');
        const RouteComponent = componentCache[key] || route.component();
        if (!componentCache[key]) {
          componentCache[key] = RouteComponent;
        }

        return (
          <Route
            key={key}
            exact={route.exact}
            path={route.path}
            strict={false}
            render={() => {
              if (route.private && !isLoggedIn) {
                return <Redirect to="/" />;
              }
              return (
                <TopLevelRoute routeType={route.type}>
                  <TopNavbar />
                  {shouldRenderBanner && <Banner />}
                  <RouteComponent fallback={<Loading isView />} />
                </TopLevelRoute>
              );
            }}
          />
        );
      })
    )}
  </Switch>
);

export default connector<TopLevelRoutesProps>(connectRouting)(TopLevelRoutes);
