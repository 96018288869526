import React from 'react';

import ClickableDiv from 'Webapp/shared/app/components/clickable-div';
import DefaultItemActionButton from 'Webapp/shared/app/components/item-actions/default-item-action-button';
import { ItemButtonWithMetric } from 'Webapp/shared/app/hocs/withItemActions.js';
import CommentIcon from 'ComponentLibrary/icons/new_social/comment.js';
import ItemMetric from 'Webapp/shared/app/components/item-actions/item-metric';
import DisabledWrapper from 'Webapp/shared/app/components/disabled-wrapper';
import CommentsModal, {
  CommentsModalPassedProps,
} from 'Webapp/shared/app/modals/comments';

import { SocialActionType } from 'Webapp/enums';
import { getSocialActivityValue } from 'Webapp/shared/concepts/social-activity';
import withSocialActionGate from 'Webapp/shared/app/hocs/withSocialActionGate.js';

import connector from 'Webapp/utils/connector';
import connectFeatureFlags, {
  ConnectFeatureFlagsProps,
} from 'Webapp/shared/app/connectors/connectFeatureFlags';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';
import connectSocialActivity, {
  ConnectSocialActivityProps,
} from 'Webapp/shared/app/connectors/connectSocialActivity';
import connectAuthentication, {
  ConnectAuthenticationProps,
} from 'Webapp/shared/app/connectors/connectAuthentication';

// should use ButtonProps type here ↓ when it is converted

export type ItemCommentsButtonProps = {
  item: Flipboard.Item;
  section: Flipboard.Section;
  children: Array<React.ReactNode>;
  expandCommentsHeader: boolean;
  size: number;
  className: string;
} & ConnectModalProps &
  ConnectFeatureFlagsProps &
  ConnectSocialActivityProps &
  ConnectAuthenticationProps & {
    // TODO convert withSocialActionGate
    showSocialActionGate: (key: SocialActionType) => void;
  };

const ItemCommentsButton = ({
  item,
  showModal,
  isAuthenticated,
  socialActivity,
  featureFlags,
  children: customCommentButtonContents,
  showSocialActionGate,
  section,
  expandCommentsHeader,
  className,
  size,
  ...otherProps
}: ItemCommentsButtonProps) => {
  const { canReply } = item;
  const showComments = () => {
    showModal<CommentsModalPassedProps>(CommentsModal, {
      item,
      section,
      expandCommentsHeader,
    });
  };
  const commentCount = getSocialActivityValue(
    item,
    socialActivity,
    'commentCount',
    false,
  );

  const buttonAction = (e) => {
    e.preventDefault();
    e.stopPropagation();
    return isAuthenticated
      ? showComments()
      : showSocialActionGate(SocialActionType.COMMENTS);
  };
  const disabled = !canReply;
  if (featureFlags.COMMENTS) {
    return customCommentButtonContents ? (
      <DisabledWrapper disabled={disabled}>
        <ClickableDiv
          className={className}
          name={`item-comments-button-${item.id}`}
          onClick={buttonAction}
          disabled={!canReply}
        >
          {customCommentButtonContents}
        </ClickableDiv>
      </DisabledWrapper>
    ) : (
      <ItemButtonWithMetric key="item-comments-button" className={className}>
        <DisabledWrapper disabled={disabled}>
          <DefaultItemActionButton
            name={`item-comments-button-${item.id}`}
            onClick={buttonAction}
            disabled={!canReply}
            {...otherProps}
          >
            <CommentIcon size={size} />
          </DefaultItemActionButton>
        </DisabledWrapper>
        <DisabledWrapper disabled={disabled}>
          {
            /* Split out the following for consistent styling with other
           icons that have a separate "metric" component. In the case of comments
           the icon and metric have the same "action" */
            commentCount ? (
              <ClickableDiv
                className=""
                name="comment-metric-detail"
                onClick={buttonAction}
                disabled={!canReply}
              >
                <ItemMetric metricValue={commentCount} />
              </ClickableDiv>
            ) : (
              <ItemMetric />
            )
          }
        </DisabledWrapper>
      </ItemButtonWithMetric>
    );
  }
  /**
   * Returning empty span for grid layouts that expect an object here.
   * Checking for null is ugly as react doesn't actually see the null
   * till component render is executed.
   */
  return <span key="comment-spacer-button" />;
};

export default connector<ItemCommentsButtonProps>(
  connectModal,
  connectAuthentication,
  connectFeatureFlags,
  connectSocialActivity,
)(withSocialActionGate(ItemCommentsButton));
