import AcceptedInvite from 'Webapp/shared/app/components/notifications/content/accepted-invite';
import { NotificationTextClickStrategy } from 'Webapp/shared/app/components/notifications/notification-text-click-strategies/index';

import { FlapNotificationType } from 'Webapp/enums';

const strategy: NotificationTextClickStrategy = (notification) => {
  if (FlapNotificationType.ACCEPTED_INVITE !== notification.notificationType) {
    return null;
  }
  return AcceptedInvite;
};

export default strategy;
