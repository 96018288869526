import React from 'react';
import Icon from './icon';

function AvatarIcon(props) {
  return (
    <Icon
      name="avatar"
      viewBox="0 0 18 18"
      {...props}
      renderComponent={({ color }) => (
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-3.000000, -6.000000)" fill={color}>
            <ellipse cx="12" cy="10.6368" rx="4.5" ry="4.1472" />
            <path d="M21.5877197,24.1546951 C21.5877197,19.9823522 17.2951514,16.6 12,16.6 C6.70484863,16.6 2.41228031,19.9823522 2.41228031,24.1546951 C2.41228031,24.1546951 21.5877197,24.1546951 21.5877197,24.1546951 Z" />
          </g>
        </g>
      )}
    />
  );
}

export default AvatarIcon;
