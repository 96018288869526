import React from 'react';
import PropTypes from 'prop-types';

import scrollTo from 'Utils/scroll-to';
import comscorePageView from 'Utils/comscore-page-view';

import connector from 'Utils/connector';
import connectRouting from 'Webapp/shared/app/connectors/connectRouting';

class PathChangeMonitor extends React.Component {
  constructor(props) {
    super(props);
    this.authPages = this.authPages.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.routing.pathname !== prevProps.routing.pathname) {
      this.pathChanged(prevProps);
    }
  }

  pathChanged(prevProps) {
    this.props.onChange && this.props.onChange();
    scrollTo();
    comscorePageView();
    this.authPages(prevProps);
  }

  authPages(prevProps) {
    const { isAuthRoute, setIsChromeless } = this.props;
    if (isAuthRoute) {
      setIsChromeless(true);
    } else if (!isAuthRoute && prevProps.isAuthRoute) {
      setIsChromeless(false);
    }
  }

  render() {
    return null;
  }
}

PathChangeMonitor.propTypes = {
  onChange: PropTypes.func,
  routing: PropTypes.object.isRequired,
  isAuthRoute: PropTypes.bool.isRequired,
  setIsChromeless: PropTypes.func.isRequired,
};

export default connector(connectRouting)(PathChangeMonitor);
