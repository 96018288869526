import { connect } from 'react-redux';
import Breadcrumbs from '../../components/navigation/breadcrumbs';

const mapStateToProps = (state: Flipboard.State) => {
  const { app } = state;
  const breadcrumbs = app.breadcrumbs;
  return { breadcrumbs };
};

export type BreadcrumbsContainerProps = ReturnType<typeof mapStateToProps>;

const BreadcrumbsContainer = connect(mapStateToProps)(Breadcrumbs);

export default BreadcrumbsContainer;
