import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

import codeSplitLoad from 'Webapp/shared/utils/code-split-load';
// actions
import {
  showModal,
  dismissSpecificModal,
} from 'Webapp/shared/app/redux/actions/modal-actions';
const SignupModal = codeSplitLoad('SignupModal');

const modalSignup: OnboardingStep = (nextStep) => (dispatch) => {
  dispatch(
    showModal(SignupModal, {
      onComplete: () => {
        dispatch(dismissSpecificModal(SignupModal));
        nextStep();
      },
    }),
  );
};

export default modalSignup;
