import {
  getEmailSettingsMapping,
  getEmailSettings,
  subscribeToEmail,
  subscribeToAll,
  unsubscribeFromPreselected,
  savePendingSubscriptionGroups,
  subscribeToPendingSubscriptionGroups,
  preSelectSubscription,
} from 'Webapp/shared/app/redux/actions/account/email-settings-actions';
import {
  emailGroups,
  emailGroupsLoaded,
  emailSubscriptions,
  statesLoaded,
  preselectUnsubscribeComplete,
  saveState,
  emailFromCode,
} from 'Webapp/shared/app/redux/selectors/email-settings';

export default {
  selectors: {
    emailGroups,
    emailGroupsLoaded,
    subscriptionGroups: emailSubscriptions,
    statesLoaded,
    preselectUnsubscribeComplete,
    emailSettingsSaveState: saveState,
    emailFromCode,
  },
  actions: {
    getEmailSettingsMapping,
    getEmailSettings,
    subscribeToEmail,
    subscribeToAll,
    unsubscribeFromPreselected,
    savePendingSubscriptionGroups,
    subscribeToPendingSubscriptionGroups,
    preSelectSubscription,
  },
};
