import getCurrentSection from 'Utils/seo/shared/get-current-section';
import basicTitle from 'Utils/seo/title/shared/basic-title';

const TRUNCATE_TITLE_THRESHOLD = 58;
const TRUNCATE_TITLE_TO = 44;
const JUST_THE_TITLE_LOWER = 46;
const JUST_THE_TITLE_UPPER = TRUNCATE_TITLE_THRESHOLD - 1;
const FLIPBOARD_POSTFIX_LOWER = 41;
const FLIPBOARD_POSTFIX_UPPER = JUST_THE_TITLE_LOWER - 1;
const USERNAME_POSTFIX_LOWER = 28;
const USERNAME_POSTFIX_UPPER = FLIPBOARD_POSTFIX_LOWER - 1;
const FLIPBOARD_AND_USERNAME_POSTFIX_UPPER = USERNAME_POSTFIX_LOWER - 1;

export default ({ section }, getState) => {
  if (section) {
    const currentSection = getCurrentSection(getState());
    if (currentSection) {
      const { isMagazine, isSmartMagazine } = currentSection;
      if (isMagazine || isSmartMagazine) {
        const { title: magazineTitle, authorUsername } = currentSection;
        if (!magazineTitle) {
          return;
        }

        const cleanedTitle = magazineTitle.replace(/\s+/g, ' ');

        if (cleanedTitle.length >= TRUNCATE_TITLE_THRESHOLD) {
          return basicTitle(`${cleanedTitle.slice(0, TRUNCATE_TITLE_TO)}…`);
        }

        if (
          cleanedTitle.length >= JUST_THE_TITLE_LOWER &&
          cleanedTitle.length <= JUST_THE_TITLE_UPPER
        ) {
          return cleanedTitle;
        }

        if (
          cleanedTitle.length >= FLIPBOARD_POSTFIX_LOWER &&
          cleanedTitle.length <= FLIPBOARD_POSTFIX_UPPER
        ) {
          return basicTitle(cleanedTitle);
        }

        if (
          cleanedTitle.length >= USERNAME_POSTFIX_LOWER &&
          cleanedTitle.length <= USERNAME_POSTFIX_UPPER
        ) {
          return `${cleanedTitle} | @${authorUsername}`;
        }

        if (cleanedTitle.length <= FLIPBOARD_AND_USERNAME_POSTFIX_UPPER) {
          return basicTitle(`${cleanedTitle} | @${authorUsername}`);
        }
      }
    }
  }
};
