import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqBy from 'lodash/uniqBy';

import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { UI_TEXT_TYPES } from 'Style/typography';
import { COLORS } from 'Style/colors';

import Debouncer from 'Utils/debouncer';
import FlapUtil from 'Utils/content/flap-util';
import SectionUtil from 'Utils/content/section-util';

// Components
import Input, { InputIcons } from 'Webapp/shared/app/components/base/input';
import TopicPill from 'Webapp/shared/app/components/topic-pill';
import Loading from 'Webapp/shared/app/components/loading';
import Button, {
  StyleVariations,
  StyleModifiers,
} from 'Webapp/shared/app/components/button';
import CaretIcon from 'ComponentLibrary/icons/caret';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectSearch from 'Webapp/shared/app/connectors/connectSearch';

const StyledSubHeading = styled.h2({
  paddingBottom: SPACING.BASE,
});
const TopicPickerGroup = styled.div({
  padding: `0 0 ${SPACING.BASE4X}`,
});

const SearchHint = styled.div({
  ...UI_TEXT_TYPES.BODY,
  lineHeight: SPACING.XLARGE,
  height: SPACING.XLARGE,
});

const PickerLoading = styled(Loading)({
  paddingTop: SPACING.BASE,
  height: SPACING.XLARGE,
});

const getDirtyComparator = (list) =>
  list
    .map((x) => FlapUtil.normalizeRemoteid(x.remoteid))
    .sort()
    .join();

class TopicPicker extends Component {
  constructor(props) {
    super(props);

    const { followed, collapsible, collapseSelectedLimit } = props;
    this.state = {
      toFollow: followed,
      toFollowSelected: followed,
      followableSelected: [],
      searchResults: [],
      searchResultsSelected: [],
      initialSearchResultsSelected: [],
      searchQuery: '',
      searchFocused: false,
      isSearchLoading: false,
      searchPrompt: null,
      isSubmitting: false,
      collapsed: collapsible && followed.length > collapseSelectedLimit,
    };
    this.searchSectionsDebounced = Debouncer.create(this.handleSearch, 250);
    this.searchRef = React.createRef();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.followed.length !== this.props.followed.length) {
      this.setState({
        toFollow: this.props.followed,
        toFollowSelected: this.props.followed,
      });
    }
  }

  handleChangeSearch = (e) => {
    this.setState({ searchQuery: e.target.value }, () => {
      const { searchQuery } = this.state;
      if (!searchQuery) {
        return;
      }
      this.searchSectionsDebounced(searchQuery);
    });
  };

  handleBlurSearch = () => {
    const { searchQuery } = this.state;
    if (!searchQuery) {
      this.setState({ searchFocused: false, searchPrompt: null });
    }
  };

  handleFocusSearch = () => {
    this.setState({ searchFocused: true });
  };

  handleSearch = async (searchQuery = null) => {
    this.setState({ isSearchLoading: true, selectedExpanded: false });
    const { toFollowSelected, followableSelected } = this.state;
    const { searchTopics } = this.props;
    const selected = [...toFollowSelected, ...followableSelected];
    const searchResults = await searchTopics(searchQuery);

    const searchResultsSelected = searchResults.filter((r) =>
      selected.some((s) => SectionUtil.mightBeSameSection(s, r)),
    );
    this.setState({
      searchResults,
      searchResultsSelected,
      initialSearchResultsSelected: searchResultsSelected,
      isSearchLoading: false,
    });
  };

  handleSearchPrompt = (section) => () => {
    this.setState({ searchFocused: true, searchPrompt: section.title }, () => {
      this.searchRef.current.focus();
    });
  };

  handleToggleSection = (selectedStateKey) => (section) => {
    const selected = this.state[selectedStateKey];
    const isSelected = selected.some((s) => s.remoteid === section.remoteid);
    this.setState({
      [selectedStateKey]: isSelected
        ? selected.filter((s) => !SectionUtil.mightBeSameSection(s, section))
        : [section, ...selected],
    });
  };

  handleClearSearch = () => {
    this.setState({
      searchQuery: '',
      searchFocused: false,
      searchPrompt: null,
    });
  };

  submit = () => {
    const { toFollowSelected, followableSelected } = this.state;
    this.setState({ isSubmitting: true }, async () => {
      try {
        await this.props.onSubmit([...toFollowSelected, ...followableSelected]);
      } catch (_) {
        // no-op
      } finally {
        this.setState({ isSubmitting: false });
      }
    });
  };

  removeUnselectedSearchResults(unselectedSearchResults, list) {
    return list.filter(
      (t) =>
        !unselectedSearchResults.some((u) =>
          SectionUtil.mightBeSameSection(t, u),
        ),
    );
  }

  submitSearch = () => {
    const {
      toFollow,
      toFollowSelected,
      followableSelected,
      searchResults,
      searchResultsSelected,
    } = this.state;
    const unselectedSearchResults = searchResults.filter(
      (r) =>
        !searchResultsSelected.some((s) =>
          SectionUtil.mightBeSameSection(s, r),
        ),
    );
    const toFollowUpdated = this.removeUnselectedSearchResults(
      unselectedSearchResults,
      toFollow,
    );
    const toFollowSelectedUpdated = this.removeUnselectedSearchResults(
      unselectedSearchResults,
      toFollowSelected,
    );
    const followableSelectedUpdated = this.removeUnselectedSearchResults(
      unselectedSearchResults,
      followableSelected,
    );

    this.setState({
      toFollow: [...searchResultsSelected, ...toFollowUpdated],
      toFollowSelected: [...searchResultsSelected, ...toFollowSelectedUpdated],
      followableSelected: followableSelectedUpdated,
      searchPrompt: null,
      searchFocused: false,
      searchResults: [],
      searchResultsSelected: [],
      initialSearchResultsSelected: [],
      searchQuery: '',
    });
  };

  isDirty() {
    const {
      toFollowSelected,
      followableSelected,
      searchResultsSelected,
      initialSearchResultsSelected,
    } = this.state;
    return (
      getDirtyComparator([...toFollowSelected, ...followableSelected]) !==
        getDirtyComparator(this.props.followed) ||
      getDirtyComparator(searchResultsSelected) !==
        getDirtyComparator(initialSearchResultsSelected)
    );
  }

  render() {
    const {
      t,
      children,
      title,
      description,
      selectedTitle,
      followableGroups,
      isLoadingFollowableGroups,
      className,
      collapsible,
      collapseSelectedLimit,
      searchText,
      noResultsText,
    } = this.props;
    const {
      toFollow,
      toFollowSelected,
      followableSelected,
      searchResultsSelected,
      searchQuery,
      searchResults,
      searchFocused,
      isSearchLoading,
      searchPrompt,
      collapsed,
    } = this.state;

    const isLoading = isSearchLoading || isLoadingFollowableGroups;

    const hasSearchResults = searchQuery && searchResults.length > 0;
    const shouldBeCollapsed = collapsible && collapsed;

    const followingDisplayed = uniqBy(
      shouldBeCollapsed ? toFollow.slice(0, collapseSelectedLimit) : toFollow,
      'remoteid',
    );

    const showGroups =
      hasSearchResults ||
      (!searchQuery && !searchPrompt && followableGroups.length > 0);

    const groups = hasSearchResults
      ? [{ subsections: searchResults }]
      : followableGroups;

    const isDirty = this.isDirty();
    const isSearching = !!(searchFocused && searchQuery);

    const content = (
      <div
        className={classNames(
          'topic-customization__content modal__content',
          className,
        )}
      >
        <header className="topic-customization__header">
          <h1 className="topic-customization__title ui-heading--medium">
            <span>{title}</span>
          </h1>
        </header>
        <p className="ui-body--large-standard">{description}</p>
        <div className="topic-customization__search-wrapper">
          <Input
            name="passion-picker-topic-customization-search"
            placeholder={t('search')}
            icon={InputIcons.SEARCH}
            value={searchQuery}
            hasValue={!!searchQuery}
            onBlur={this.handleBlurSearch}
            onFocus={this.handleFocusSearch}
            onChange={this.handleChangeSearch}
            onClear={this.handleClearSearch}
            ref={this.searchRef}
          />
          <SearchHint>
            {(searchFocused || searchQuery) && (searchPrompt || searchText)}
          </SearchHint>
        </div>
        {isLoading ? (
          <PickerLoading />
        ) : (
          <div className="topic-customization__topic-lists">
            {!searchQuery && !searchPrompt && followingDisplayed.length > 0 && (
              <React.Fragment>
                {selectedTitle && (
                  <StyledSubHeading className="ui-subheading--large-standard">
                    {selectedTitle}
                  </StyledSubHeading>
                )}
                <TopicPickerGroup>
                  <ul className="topic-customization__pills-list">
                    {followingDisplayed.map((topic) => (
                      <TopicPill
                        topic={topic}
                        isSelected={SectionUtil.sectionsIncludesSection(
                          toFollowSelected,
                          topic,
                        )}
                        onClick={this.handleToggleSection('toFollowSelected')}
                        key={topic.remoteid}
                      />
                    ))}
                    <br />
                    {collapsible && toFollow.length > collapseSelectedLimit && (
                      <Button
                        name="expand-selected-topics"
                        className="topic-customization__expand-selected"
                        styleVariation={StyleVariations.PRIMARY_TEXT}
                        styleModifier={[StyleModifiers.NO_LEFT_PADDING]}
                        onClick={() =>
                          this.setState({ collapsed: !this.state.collapsed })
                        }
                      >
                        <span>{collapsed ? t('see_more') : t('see_less')}</span>
                        <CaretIcon
                          className="expand-selected__caret-icon"
                          direction={collapsed ? 'down' : 'up'}
                          size={12}
                          color={COLORS.red}
                        />
                      </Button>
                    )}
                  </ul>
                </TopicPickerGroup>
              </React.Fragment>
            )}
            {showGroups &&
              (hasSearchResults ? (
                <TopicPickerGroup key="search-results">
                  <ul className="topic-customization__pills-list">
                    {searchResults.map((section) => (
                      <TopicPill
                        topic={section}
                        isSelected={SectionUtil.sectionsIncludesSection(
                          searchResultsSelected,
                          section,
                        )}
                        onClick={this.handleToggleSection(
                          'searchResultsSelected',
                        )}
                        key={section.remoteid}
                      />
                    ))}
                  </ul>
                </TopicPickerGroup>
              ) : (
                groups.map((group, i) => {
                  const sections = group.subsections.filter(
                    // do not show items in followable that are
                    // already in the top section
                    // TopStories can't be followed or unfollowed
                    (section) =>
                      !section.remoteid.match(
                        /^flipboard\/franchiseFeature.*TopStories$/,
                      ) &&
                      !SectionUtil.sectionsIncludesSection(toFollow, section),
                  );
                  if (sections.length < 1) {
                    // do not show empty groups
                    return null;
                  }
                  return (
                    <TopicPickerGroup key={group.title || i}>
                      <StyledSubHeading className="ui-subheading--large-standard">
                        {group.title}
                      </StyledSubHeading>
                      <ul className="topic-customization__pills-list">
                        {sections.map((section) => (
                          <TopicPill
                            topic={section}
                            isSelected={SectionUtil.sectionsIncludesSection(
                              followableSelected,
                              section,
                            )}
                            onClick={
                              SectionUtil.isPersonalizeSearchTrigger(section)
                                ? this.handleSearchPrompt(section)
                                : this.handleToggleSection('followableSelected')
                            }
                            key={section.remoteid}
                          />
                        ))}
                      </ul>
                    </TopicPickerGroup>
                  );
                })
              ))}
          </div>
        )}
        {isSearching && !isLoading && !hasSearchResults && (
          <span className="ui-text--body">{noResultsText}</span>
        )}
      </div>
    );
    const isSubmittable = !this.state.isSubmitting && isDirty;
    return children({
      content,
      submit: isSearching ? this.submitSearch : this.submit,
      clearSearch: this.handleClearSearch,
      isSubmittable,
      isLoading,
      isSearching,
      isDirty,
    });
  }
}

TopicPicker.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  followed: PropTypes.array,
  followable: PropTypes.array,
  isAuthenticated: PropTypes.bool.isRequired,
  children: PropTypes.func.isRequired,
  title: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  noResultsText: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  followableGroups: PropTypes.array.isRequired,
  isLoadingFollowableGroups: PropTypes.bool.isRequired,
  collapsible: PropTypes.bool,
  collapseSelectedLimit: PropTypes.number,
  selectedTitle: PropTypes.string,
  searchText: PropTypes.string,
  searchTopics: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

TopicPicker.defaultProps = {
  followableGroups: [],
  collapsible: false,
  collapseSelectedLimit: 16,
};

export default connector(
  connectAuthentication,
  connectSearch,
)(withT(TopicPicker));
