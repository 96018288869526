import connector from 'Utils/connector';
import { usageTrackItemShare } from 'Webapp/shared/app/redux/actions/usage-actions';
import Share from 'Webapp/shared/app/modals/share';
import { getShortUrl } from 'Webapp/shared/app/redux/actions/item-actions';

export default connector({
  actions: {
    usageTrackItemShare,
    getShortUrl,
  },
})(Share);
