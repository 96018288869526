import {
  getImage,
  isGroup,
  isSectionCover,
  isSection,
} from 'Utils/content/item-util';
import { largestUrl } from 'Utils/image-util';
import StoryboardRenderingUtil from 'Utils/content/storyboard-rendering-util';

const GROUP_SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

const NglUtil = {
  GROUP_SIZES,

  sidFromRemoteid: (remoteid) => {
    const sidRegex = /^sid\/(.*)\/.*/g;
    const matches = sidRegex.exec(remoteid);
    if (matches === null) {
      return null;
    }
    if (matches.length < 2) {
      return null;
    }
    return matches[1];
  },

  getConfig: (nglFeedConfigs = [], section) => {
    if (!section) {
      return null;
    }

    return nglFeedConfigs.find((feedConfig) => {
      const { remoteIds } = feedConfig;
      if (typeof remoteIds === 'undefined') {
        return null;
      }

      // try matching exactly in case it is the long form remoteid (non-sid)
      const config = remoteIds.find(
        (remoteid) => remoteid === section.remoteid,
      );
      if (typeof config !== 'undefined') {
        return config;
      }

      // try with truncated SIDs
      const truncatedSids = remoteIds.map((remoteid) => {
        const sid = NglUtil.sidFromRemoteid(remoteid);
        return sid && sid.slice(0, -1);
      });

      const sectionSid = NglUtil.sidFromRemoteid(section.remoteid);
      if (sectionSid === null) {
        return null;
      }

      const truncatedSectionSid = sectionSid.slice(0, -1);
      return truncatedSids.find(
        (truncatedSid) => truncatedSid === truncatedSectionSid,
      );
    });
  },

  sectionIsNgl: (nglFeedConfigs = [], section) =>
    !!NglUtil.getConfig(nglFeedConfigs, section),

  getSectionCoverImage: (nglFeedConfig, section, items) => {
    const { headerImageURL, headerImageWidth, headerImageHeight } =
      nglFeedConfig;

    if (typeof headerImageURL !== 'undefined') {
      // with the NGL feed config overriding the header image
      const image = { largeURL: headerImageURL };

      if (typeof headerImageWidth !== 'undefined') {
        image.original_width = headerImageWidth;
      }

      if (typeof headerImageHeight !== 'undefined') {
        image.original_height = headerImageHeight;
      }

      return image;
    }

    if (typeof section.image !== 'undefined') {
      // with an image defined on the section
      return section.image;
    }

    if (typeof section.tileImage !== 'undefined') {
      // with a tile image defined on the section
      return section.tileImage;
    }

    // with no NGL feed config image or section image, use first item large image
    const firstImageItem = items.find((item) => {
      const image = getImage(item);
      return image && !!largestUrl(image);
    });
    if (typeof firstImageItem !== 'undefined') {
      return getImage(firstImageItem);
    }
  },

  generateSectionCoverAuthor: (nglFeedConfig, section) => {
    const { headerAuthorName, headerAuthorAvatarURL, headerAuthorRemoteId } =
      nglFeedConfig;

    const authorDisplayName =
      typeof headerAuthorName !== 'undefined'
        ? headerAuthorName
        : section.authorDisplayName;

    const authorImage =
      typeof headerAuthorAvatarURL !== 'undefined'
        ? { largeURL: headerAuthorAvatarURL }
        : section.authorImage;

    const author = {
      type: 'author',
      feedType: 'profile',
      authorUsername: section.authorUsername,
      authorDisplayName,
      authorImage,
    };

    if (typeof headerAuthorRemoteId !== 'undefined') {
      author.remoteid = headerAuthorRemoteId;
      // remove authorUsername so that the profile URL generation falls back to remoteid
      if (typeof author.authorUsername !== 'undefined') {
        delete author.authorUsername;
      }
    }

    return author;
  },

  generateSectionCoverCustomizer: (nglFeedConfig) => {
    const {
      headerImageURL,
      headerImageSquareURL,
      hideHeaderBrackets,
      headerDescription,
    } = nglFeedConfig;
    const itemRenderHints = {};

    if (typeof headerImageSquareURL !== 'undefined') {
      itemRenderHints.headerImageSquareURL = headerImageSquareURL;
    }

    if (typeof hideHeaderBrackets !== 'undefined') {
      itemRenderHints.hideHeaderBrackets = hideHeaderBrackets;
    }

    if (typeof headerDescription !== 'undefined') {
      itemRenderHints.headerDescription = headerDescription;
    }

    if (
      typeof headerImageURL === 'undefined' &&
      typeof headerImageSquareURL === 'undefined'
    ) {
      itemRenderHints.hideHeaderImage = true;
    }

    return {
      customizations: {
        itemRenderHints,
      },
    };
  },

  generateSectionCover: (nglFeedConfig, section, items) => {
    const { title, authorUsername, authorDisplayName, authorImage } = section;

    const coverItem = {
      type: 'sectionCover',
      title,
      authorUsername,
      authorDisplayName,
      authorImage,
      section: {},
    };

    // cover image
    const coverImage =
      nglFeedConfig &&
      NglUtil.getSectionCoverImage(nglFeedConfig, section, items);
    if (coverImage) {
      coverItem.section.tileImage = coverImage;
    }

    // cover author
    const coverAuthor =
      nglFeedConfig &&
      NglUtil.generateSectionCoverAuthor(nglFeedConfig, section);
    if (coverAuthor) {
      coverItem.sectionLinks = [coverAuthor];
    }

    // cover customizer
    if (nglFeedConfig) {
      coverItem.customizer =
        NglUtil.generateSectionCoverCustomizer(nglFeedConfig);
    }

    return coverItem;
  },

  convertSectionToStoryboard: (section, nglFeedConfig) => {
    const { hideHeaderBrackets, headerDescription } = nglFeedConfig;
    const converted = Object.assign({}, section, {
      type: 'feed',
      feedType: 'bundle',
      isNglPromoted: true,
    });

    if (typeof hideHeaderBrackets !== 'undefined') {
      converted.nglHideHeaderBrackets = hideHeaderBrackets;
    }

    if (typeof headerDescription !== 'undefined') {
      converted.nglHeaderDescription = headerDescription;
    }

    const overriddenHeaderImage = NglUtil.getSectionCoverImage(
      nglFeedConfig,
      section,
      [],
    );
    if (overriddenHeaderImage) {
      converted.image = overriddenHeaderImage;
    }

    return converted;
  },

  getGroupCustomizer: (
    size = GROUP_SIZES.small,
    title = null,
    numbered = false,
  ) => {
    const customizer = {
      customizations: {
        itemRenderHints: {
          size: size || GROUP_SIZES.small,
          numbered,
        },
      },
    };
    if (typeof title !== 'undefined' && title !== null) {
      customizer.customizations.title = title;
    }
    return customizer;
  },

  convertToStoryboardGroup: (item, size = GROUP_SIZES.small) => {
    // decorate an existing group
    if (isGroup(item)) {
      return Object.assign({}, item, {
        customizer: NglUtil.getGroupCustomizer(size, item.title),
      });
    }

    // convert an individual item to a large size group
    return {
      type: 'group',
      id: item.id,
      customizer: NglUtil.getGroupCustomizer(GROUP_SIZES.large),
      items: [item],
    };
  },

  convertItemsToStoryboardGroup: (
    items,
    size = GROUP_SIZES.small,
    title,
    numbered,
    id,
  ) => ({
    type: 'group',
    customizer: NglUtil.getGroupCustomizer(size, title, numbered),
    items,
    id,
  }),

  getSectionStoryboardGroups: (nglFeedConfig, feedItems) => {
    if (typeof feedItems === 'undefined') {
      return [];
    }
    let items = feedItems.slice();
    const { numberFirst10Items, hideLastItemInFeed, firstItemOfGroupLarge } =
      nglFeedConfig || {};

    // Remove last item of if specified
    if (hideLastItemInFeed === true) {
      const lastItem = items[items.length - 1];
      if (!isGroup(lastItem)) {
        items.pop();
      }
    }

    // Remove any sectionCover items
    items = items.filter((item) => !isSectionCover(item));
    // If first item is a group, convert groups to storyboard s
    const firstItem = items[0];
    if (firstItem && isGroup(firstItem)) {
      if (!firstItemOfGroupLarge) {
        return items.map((item) => {
          const group = isGroup(item);
          return NglUtil.convertItemsToStoryboardGroup(
            group ? item.items : [item],
            group ? GROUP_SIZES.small : GROUP_SIZES.large,
            group && item.title,
            false,
            item.id,
          );
        });
      }

      // "firstItemOfGroupLarge" is set, split each group item into one
      // large group with the first item and one small group with the
      // remaining items
      return items.reduce((collect, item) => {
        if (isGroup(item)) {
          const largeGroupItems = item.items.slice(0, 1);
          const largeGroup =
            largeGroupItems.length > 0 &&
            NglUtil.convertItemsToStoryboardGroup(
              largeGroupItems,
              GROUP_SIZES.large,
              item.title,
              StoryboardRenderingUtil.isNumberedGroup(item),
              largeGroupItems[0].id,
            );
          if (largeGroup) {
            collect.push(largeGroup);
          }

          const smallGroupItems = item.items.slice(1);
          const smallGroup =
            smallGroupItems.length > 0 &&
            NglUtil.convertItemsToStoryboardGroup(
              smallGroupItems,
              GROUP_SIZES.small,
              null,
              StoryboardRenderingUtil.isNumberedGroup(item),
              smallGroupItems[0].id,
            );
          if (smallGroup) {
            collect.push(smallGroup);
          }
        } else {
          collect.push(item);
        }

        return collect;
      }, []);
    }

    // If first item is not a group, convert items to storyboard  groups
    if (numberFirst10Items) {
      const firstGroup = NglUtil.convertItemsToStoryboardGroup(
        items.slice(0, 10),
        GROUP_SIZES.large,
        null,
        true,
        'generated-group-id-1',
      );
      const secondGroup = NglUtil.convertItemsToStoryboardGroup(
        items.slice(10),
        GROUP_SIZES.small,
        null,
        null,
        'generated-group-id-2',
      );
      return [firstGroup, secondGroup];
    }

    return [
      NglUtil.convertItemsToStoryboardGroup(
        items,
        GROUP_SIZES.large,
        null,
        null,
        'generated-group-id-1',
      ),
    ];
  },

  getSectionStoryboardtems: (nglFeedConfigs, section, items) => {
    const nglFeedConfig = NglUtil.getConfig(nglFeedConfigs, section);
    const coverItem = NglUtil.generateSectionCover(
      nglFeedConfig,
      section,
      items,
    );
    const groups = NglUtil.getSectionStoryboardGroups(nglFeedConfig, items);
    return [coverItem, ...groups];
  },

  convertSectionItemsToNgl: (nglFeedConfigs, items) =>
    items.map((item) => {
      if (!isSection(item)) {
        return item;
      }
      if (typeof item.section === 'undefined') {
        return item;
      }
      if (!NglUtil.sectionIsNgl(nglFeedConfigs, item.section)) {
        return item;
      }

      const itemSection = item.section;
      const nglFeedConfig = NglUtil.getConfig(nglFeedConfigs, itemSection);

      // item section is NGL...convert to storyboard
      item.section = NglUtil.convertSectionToStoryboard(
        itemSection,
        nglFeedConfig,
      );

      // get the cover item image
      const nglImage =
        nglFeedConfig &&
        NglUtil.getSectionCoverImage(nglFeedConfig, itemSection, []);
      if (nglImage) {
        item.image = nglImage;
      }

      return item;
    }),

  /**
   * Returns the items for the storyboard .  Provides projection to a groups
   * in the case where no groups are present.  All params after "items"
   * are used in the generation of the group, if necessary.
   * NOTE: This does not handle mixes of group and non-group items in the
   * same feed.  If any group items are present, all items will be
   * returned unchanged.
   * @param {Array} items     - An array of projected Flipboard items
   * @param {String} size     - One of GROUP_SIZES values (optional, default: small)
   * @param {String} title    - A title for the generated group (optional)
   * @param {Bool} numbered   - Whether to number items in the group (optional, default: false)
   * @param {String} id       - An id for the generated group
   * @return {Array}          - An array of Flipboard group items
   */
  groupsFromItems: (
    items,
    size = GROUP_SIZES.small,
    title = null,
    numbered = false,
    id = 'generated-group-id-1',
  ) => {
    const groupItems = items.filter((item) => isGroup(item));
    if (groupItems.length) {
      return items;
    }

    return [
      NglUtil.convertItemsToStoryboardGroup(items, size, title, numbered, id),
    ];
  },
};

export default NglUtil;
