import { ACCOUNT_EMAIL_SETTINGS_TYPES } from 'Webapp/action-types';
import { merger } from 'Utils/redux';
import { SaveState } from 'Webapp/enums';

interface EmailGroup {
  name: string;
  subscriptions: Array<EmailSubscription>;
}

interface EmailSubscription {
  key: string;
}

interface EmailSettingsReducerState {
  emailFromCode: string | null;
  groups: Array<EmailGroup>;
  states: Array<unknown>;
  groupsLoaded: boolean;
  statesLoaded: boolean;
  preselectUnsubscribeComplete: boolean;
  saveState: SaveState;
}

const initialState: EmailSettingsReducerState = {
  emailFromCode: null,
  groups: [],
  states: [],
  groupsLoaded: false,
  statesLoaded: false,
  preselectUnsubscribeComplete: false,
  saveState: SaveState.NONE,
};

export default function emailSettingsReducer(state = initialState, action) {
  const merge = merger(state);

  switch (action.type) {
    case ACCOUNT_EMAIL_SETTINGS_TYPES.GET_EMAIL_CONFIG_SUCCEEDED: {
      // account for varying response when internal/external Flaps
      const groups = action?.payload?.groups?.groups || action?.payload?.groups;
      return merge({
        groups,
        groupsLoaded: true,
      });
    }
    case ACCOUNT_EMAIL_SETTINGS_TYPES.GET_EMAIL_SETTINGS: {
      return merge({
        statesLoaded: false,
      });
    }
    case ACCOUNT_EMAIL_SETTINGS_TYPES.GET_EMAIL_SETTINGS_SUCCEEDED: {
      const {
        payload: { states },
      } = action;
      return merge({
        states,
        statesLoaded: true,
      });
    }
    case ACCOUNT_EMAIL_SETTINGS_TYPES.SET_PRESELECT_UNSUBSCRIBE_COMPLETE: {
      return merge(action.payload);
    }
    case ACCOUNT_EMAIL_SETTINGS_TYPES.SAVE_EMAIL_SETTINGS_PENDING: {
      return merge({
        saveState: SaveState.PENDING,
      });
    }
    case ACCOUNT_EMAIL_SETTINGS_TYPES.SAVE_EMAIL_SETTINGS_SUCCEEDED: {
      return merge({
        saveState: SaveState.SUCCEEDED,
      });
    }
    case ACCOUNT_EMAIL_SETTINGS_TYPES.SAVE_EMAIL_SETTINGS_FAILED: {
      return merge({
        saveState: SaveState.FAILED,
      });
    }
    case ACCOUNT_EMAIL_SETTINGS_TYPES.RESET_EMAIL_SETTINGS_SAVE_STATE: {
      return merge({
        saveState: SaveState.NONE,
      });
    }
    case ACCOUNT_EMAIL_SETTINGS_TYPES.SET_EMAIL_FROM_CODE: {
      return merge(action.payload);
    }
    case ACCOUNT_EMAIL_SETTINGS_TYPES.SET_PRESELECT_SUBSCRIPTION: {
      const { payload } = action;
      const updatedGroups = state.groups.map((group) => {
        if (group.name === payload.groupType) {
          return {
            ...group,
            subscriptions: group.subscriptions.map((subscriptions) => {
              if (subscriptions.key === payload.subscriptionKey) {
                return { ...subscriptions, isSubscribed: true };
              }
              return subscriptions;
            }),
          };
        }
        return group;
      });

      return { ...state, groups: updatedGroups };
    }
    default:
      return state;
  }
}
