import { connect } from 'react-redux';
import TopNavbar from '../components/navigation/top-navbar';

const mapStateToProps = ({ app, auth }) => ({
  isAuthenticated: auth.uid !== 0,
  isChromeless: app.isChromeless,
  isEmbeddable: app.isEmbeddable,
  hideMainNavCTAs: app.hideMainNavCTAs,
});

const TopNavbarContainer = connect(mapStateToProps, null)(TopNavbar);

export default TopNavbarContainer;
