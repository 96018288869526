import React from 'react';
import styled from '@emotion/styled';
import { BREAKPOINTS } from 'Style/breakpoints';
import { SPACING } from 'Style/spacing';
import { UI_TEXT_TYPES } from 'Style/typography';
import { styleObject } from 'Style/type-helpers';
import { truncate } from 'Style/truncate';
import { styleOnlyProps } from 'Style/style-helpers';

import TopicUtil from 'Utils/content/topic';
import SectionUtil from 'Webapp/utils/content/section-util';

import { PillSize } from 'Webapp/enums';

import TopicPill from 'Webapp/shared/app/components/topic-pill';
import SectionPill from 'Webapp/shared/app/components/section-pill';

import connector from 'Webapp/utils/connector';

import withT from 'ComponentLibrary/hocs/withT';

export const SectionPickerHeader = styled.div(
  {
    padding: `${SPACING.BASE8X} ${SPACING.BASE8X} 0 ${SPACING.BASE8X}`,
  },
  styleObject(
    BREAKPOINTS.phone({
      padding: `${SPACING.XLARGE} ${SPACING.XLARGE} 0 ${SPACING.XLARGE}`,
    }),
  ),
);

const Title = styled.h1(UI_TEXT_TYPES.PAGE_TITLE, {
  marginBottom: SPACING.BASE,
});

const SubTitle = styled.h2(UI_TEXT_TYPES.BODY);

const PillContainer = styled.li({
  display: 'inline',
});

export const SectionPickerContent = styled.div(
  {
    margin: `${SPACING.BASE6X} ${SPACING.BASE6X} 0 ${SPACING.BASE6X}`,
  },
  styleObject(
    BREAKPOINTS.phone({
      margin: `${SPACING.BASE} ${SPACING.BASE} 0 ${SPACING.BASE}`,
    }),
  ),
);
type PickerListProps = { truncateLines?: number };

const PickerList = styled(
  'ul',
  styleOnlyProps('truncateLines'),
)<PickerListProps>(
  ({ truncateLines }) => {
    if (truncateLines) {
      return { height: '100%', ...truncate('98%', truncateLines) };
    }
  },
  // Normally we would use flex/grid with a "gap". However that doesn't work with the truncation
  // so we have to use margin between lines.
  {
    '.pill': { marginBottom: SPACING.LARGE },
  },
);
// Same as with the bottom margin, the fake "gap" object will give us some more consistency in how the
// ellipsis is painted, (and separate it from the last displayed element).
const PillGap = styled.span({ display: 'inline-block', width: SPACING.LARGE });

type OnboardingSectionPickerProps = {
  titleText: string;
  subTitleText?: string;
  pickerSections: Flipboard.BasicSection[];
  selectedSections: Flipboard.BasicSection[];
  truncateLines?: number;
  setSelectedSections: (sections: Flipboard.BasicSection[]) => void;
};

const OnboardingSectionPicker: React.FC<OnboardingSectionPickerProps> = ({
  titleText,
  subTitleText,
  pickerSections,
  selectedSections,
  setSelectedSections,
  truncateLines,
}) => {
  const isSelected = (section: Flipboard.BasicSection) =>
    selectedSections &&
    (SectionUtil.sectionsIncludesSection(selectedSections, section) ||
      !!selectedSections.find((s) => TopicUtil.isEqual(s, section)));

  const handleToggleSection = (section: Flipboard.BasicSection) => {
    if (isSelected(section)) {
      setSelectedSections(
        selectedSections.filter(
          (s) =>
            !SectionUtil.mightBeSameSection(s, section) &&
            !TopicUtil.isEqual(s, section),
        ),
      );
    } else {
      setSelectedSections([...selectedSections, section]);
    }
  };

  const renderTopic = (topic: Flipboard.BasicSection) => (
    <TopicPill
      topic={topic}
      isSelected={isSelected(topic)}
      onClick={handleToggleSection}
      key={topic.remoteid}
      size={PillSize.LARGE}
    />
  );

  const renderSection = (section: Flipboard.BasicSection) => {
    let Pill: React.ReactNode = null;

    if (SectionUtil.isTopic(section)) {
      Pill = renderTopic(section);
    }
    if (section.isMagazine || section.isProfile) {
      Pill = (
        <SectionPill
          section={section}
          isSelected={isSelected(section)}
          onClick={handleToggleSection}
          key={section.remoteid}
          size={PillSize.LARGE}
        />
      );
    }
    if (Pill) {
      return (
        <PillContainer key={section.remoteid}>
          {Pill}
          <PillGap />
        </PillContainer>
      );
    }
    return null;
  };
  return (
    <>
      <SectionPickerHeader className="modal__header">
        <Title>{titleText}</Title>
        {subTitleText && <SubTitle>{subTitleText}</SubTitle>}
      </SectionPickerHeader>
      {pickerSections && (
        <SectionPickerContent className="modal__content">
          <PickerList truncateLines={truncateLines}>
            {pickerSections &&
              pickerSections.map((section) => renderSection(section))}
          </PickerList>
        </SectionPickerContent>
      )}
    </>
  );
};

export default connector<OnboardingSectionPickerProps>()(
  withT(OnboardingSectionPicker),
);
