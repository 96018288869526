import { Connector } from 'Webapp/utils/connector';
import {
  clearNotifications,
  getUnreadCount,
  getNotifications,
  markNotificationsRead,
  markNotificationEntriesRead,
  setNotificationsFilterGroup,
} from 'Webapp/shared/concepts/notifications';

const selectors = {
  notifications: ({ notifications: { entries } }: Flipboard.State) => entries,
  unreadCount: ({ notifications: { unreadCount } }: Flipboard.State) =>
    unreadCount,
  loading: ({ notifications: { loading } }: Flipboard.State) => loading,
  filterGroup: ({ notifications: { filterGroup } }: Flipboard.State) =>
    filterGroup,
};

const actions = {
  clearNotifications,
  getUnreadCount,
  getNotifications,
  markNotificationsRead,
  markNotificationEntriesRead,
  setNotificationsFilterGroup,
};

export type ConnectNotificationsProps = Flipboard.ConnectorProps<
  typeof selectors,
  typeof actions
>;

const connectNotifications: Connector = {
  selectors,
  actions,
};

export default connectNotifications;
