import React from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    this.props.onError(error, errorInfo);
  }
  render() {
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  onError: PropTypes.func.isRequired,
};

export default ErrorBoundary;
