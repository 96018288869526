// TODO: Test
/**
 * Checks whether an image has been successfully loaded by the browser and
 * rendered into the DOM.
 * @param {Object} img   - An HTML DOM IMG element
 * @returns {Boolean}    - True if the image is loaded and has valid dimensions
 */
export const imgIsValid = (img) => {
  if (!img) {
    return false;
  }
  if (typeof img !== 'object') {
    return false;
  }

  const { tagName, complete, naturalWidth, naturalHeight } = img;

  if (tagName !== 'IMG') {
    return false;
  }
  if (!complete) {
    return false;
  }
  if (naturalWidth === null || naturalWidth === 0) {
    return false;
  }
  if (naturalHeight === null || naturalHeight === 0) {
    return false;
  }

  return true;
};
