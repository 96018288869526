import getWindow from 'Utils/get-window';
import { factory } from 'Utils/flaxios';

const instance = factory({
  baseURL: `/api/v2/auth`,
  withCredentials: true,
  timeout: 15000,
  headers: { 'Content-Type': 'application/json' },
});

instance.interceptors.request.use((config) => {
  if (config.method.toLowerCase() === 'post') {
    const metaElement = getWindow().document.querySelector(
      'meta[name="csrf-token"]',
    );
    const token = metaElement && metaElement.getAttribute('content');
    config.headers['CSRF-Token'] = token;
  }
  return config;
});

export default instance;
