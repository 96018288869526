import React from 'react';
import { LAYOUT_SIZES, LAYERS } from 'Style/layout';
import styled from '@emotion/styled';
import { BREAKPOINTS } from 'Style/breakpoints';
import { SURFACE_COLORS, TEXT_COLORS, COLORS } from 'Style/colors';
import { SPACING } from 'Style/spacing';
import { UI_HEADING_TYPES, BODY_TYPES } from 'Style/typography';
import { styleObject } from 'Style/type-helpers';
import { styleOnlyProps } from 'Style/style-helpers';
import Visible from 'Webapp/shared/app/components/visible';
import LoadingSpinner from 'ComponentLibrary/icons/loading-spinner';

import UnstyledButton from 'Webapp/shared/app/components/unstyled-button';
import CloseNewIcon from 'ComponentLibrary/icons/close-new.js';

import NotificationsList from 'Webapp/shared/app/components/notifications/notifications-list';
import { NotificationGroupType } from 'Webapp/enums';

import connector from 'Utils/connector';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';
import connectNotifications, {
  ConnectNotificationsProps,
} from 'Webapp/shared/app/connectors/connectNotifications';
import connectResponsive, {
  ConnectResponsiveProps,
} from 'Webapp/shared/app/connectors/connectResponsive';

import withT from 'ComponentLibrary/hocs/withT';

const Overlay = styled.div({
  zIndex: LAYERS.MODAL,
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const NotificationsModalHeader = styled.div({
  background: SURFACE_COLORS.primary,
  position: 'sticky',
  width: '100%',
  marginRight: 'auto',
  padding: SPACING.LARGE,
  top: 0,
  zIndex: 1,
});

const NotificationsModalContents = styled.div(
  {
    position: 'fixed',
    top: LAYOUT_SIZES.MAIN_NAV_HEIGHT,
    right: 0,
    width: '400px',
    height: '67vh',
    overflow: 'auto',
    background: SURFACE_COLORS.primary,
    gap: SPACING.LARGE,
    boxShadow: 'var(--shadow--elevation-2)',
    borderRadius: '0 0 0 3px',
  },

  styleObject(
    BREAKPOINTS.phone({
      width: '100%',
      // We are using a "sticky" header. Sticky is slightly weird, and "100%" has odd behaviour with a sticky header.
      // 100% changes behaviour when you start scrolling. So subtracting the nav height makes the infinite scroll trigger
      // behave as expected.
      height: `calc(100% - ${LAYOUT_SIZES.MAIN_NAV_HEIGHT})`,
      paddingBottom: SPACING.BASE4X,
    }),
  ),
);

const CloseButton = styled(UnstyledButton)({
  position: 'absolute',
  right: SPACING.LARGE,
  top: SPACING.LARGE,
  zIndex: 100,
});

const Title = styled.h1(UI_HEADING_TYPES.LARGE, {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  '.loading-spinner': {
    height: SPACING.XLARGE,
  },
});

const ListFilters = styled.div(BODY_TYPES.XSMALL_STANDARD, {
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  gap: SPACING.BASE,
  marginTop: SPACING.XLARGE,
  marginRight: 'auto',
  marginBottom: 'auto',
  width: 'fit-content',
});
type FilterButtonTypes = { selected: boolean | false };
const FilterButton = styled(
  UnstyledButton,
  styleOnlyProps('selected'),
)<FilterButtonTypes>(
  ({ selected }) => (
    BODY_TYPES.XSMALL_STANDARD,
    {
      backgroundColor: selected ? COLORS.red : SURFACE_COLORS.primary,
      color: selected ? TEXT_COLORS.overlay : COLORS.red,
      border: `2px solid ${COLORS.red}`,
      borderRadius: SPACING.LARGE,
      height: SPACING.BASE4X,
      width: SPACING.BASE10X,
    }
  ),
);

export interface NotificationsModalPassedProps {
  handleSetFilterGroup: (group: NotificationGroupType | null) => void;
  loadNextPage: () => void;
}

type NotificationsModalProps = NotificationsModalPassedProps &
  ConnectModalProps &
  ConnectNotificationsProps &
  ConnectResponsiveProps & {
    t: Flipboard.TFunction;
  };

class NotificationsModal extends React.Component<NotificationsModalProps> {
  async componentDidMount() {
    await this.props.getNotifications();
    this.props.markNotificationsRead();
  }

  componentWillUnmount() {
    this.props.markNotificationsRead();
    this.props.markNotificationEntriesRead();
  }

  handleClickFilterButton = (group: NotificationGroupType) => {
    const { handleSetFilterGroup, filterGroup } = this.props;
    if (filterGroup === group) {
      handleSetFilterGroup(null);
    } else {
      handleSetFilterGroup(group);
    }
  };

  render() {
    const {
      filterGroup,
      dismissSpecificModal,
      loading,
      loadNextPage,
      isLargeScreen,
      t,
    } = this.props;

    const dismissOnClick = (e) => {
      if (isLargeScreen) {
        e.stopPropagation();
      }
    };

    return (
      <Overlay
        onClick={() => dismissSpecificModal(ConnectedNotificationsModal)}
      >
        <NotificationsModalContents onClick={dismissOnClick}>
          <NotificationsModalHeader>
            <CloseButton
              name="download-app-banner-close"
              onClick={() =>
                this.props.dismissSpecificModal(ConnectedNotificationsModal)
              }
            >
              <CloseNewIcon size={24} />
            </CloseButton>
            <Title>
              {t('notifications')} {loading && <LoadingSpinner />}
            </Title>
            <ListFilters>
              <FilterButton
                name="filter_notifications_social"
                onClick={() =>
                  this.handleClickFilterButton(NotificationGroupType.SOCIAL)
                }
                selected={filterGroup === NotificationGroupType.SOCIAL}
              >
                Social
              </FilterButton>
              <FilterButton
                name="filter_notifications_content"
                onClick={() =>
                  this.handleClickFilterButton(NotificationGroupType.CONTENT)
                }
                selected={filterGroup === NotificationGroupType.CONTENT}
              >
                Content
              </FilterButton>
            </ListFilters>
          </NotificationsModalHeader>
          <NotificationsList />
          <Visible bufferBottom={600}>
            {(isVisible: boolean) => {
              if (isVisible && !loading) {
                loadNextPage();
              }
            }}
          </Visible>
        </NotificationsModalContents>
      </Overlay>
    );
  }
}

const ConnectedNotificationsModal = connector<NotificationsModalProps>(
  connectModal,
  connectNotifications,
  connectResponsive,
)(withT(NotificationsModal));

export default ConnectedNotificationsModal;
