import { connect } from 'react-redux';

// Utils
import AdjustUrl from 'Utils/adjust-url';
import FlabContent from '../../content/flab';

function withAdjustUrl(Component) {
  const mapStateToProps = ({
    app: {
      appUrl,
      isAmp,
      adjustNavFrom,
      adjustSectionId,
      utmParams,
      noExperiments,
      experiments,
      referrer,
    },
  }) => {
    // Curry the AdjustUrl util function so consuming
    // components don't need to consume a bunch of HOCs
    /**
     * Generates an Adjust URL using various app state dependencies.
     * Only requires params that vary depending on where the link
     * is rendered in the app.
     * @param {String} adjustBaseUrl -- Base URL for Adjust (associated with a particular Adjust campaign)
     * @param {*} navFrom -- usage navFrom
     */
    const getAdjustUrl = (adjustBaseUrl, navFrom) =>
      AdjustUrl.urlWithParams(
        adjustBaseUrl,
        appUrl,
        isAmp,
        adjustNavFrom,
        adjustSectionId,
        noExperiments ? null : FlabContent.experimentsForTracking(experiments),
        navFrom,
        utmParams,
        referrer,
      );

    return { getAdjustUrl };
  };

  return connect(mapStateToProps)(Component);
}

export default withAdjustUrl;
