import getWindow from 'Utils/get-window';
import '../sw';
import logger from 'Utils/logger';

export const registerServiceWorker = () => {
  if (!getWindow()) {
    return Promise.reject(new Error('window object not found'));
  }

  const navigator = getWindow().navigator;
  const { serviceWorkerUrl } = getWindow().CLIENT_CONFIG;

  if (navigator && 'serviceWorker' in navigator && serviceWorkerUrl) {
    return navigator.serviceWorker
      .register(serviceWorkerUrl, { scope: '/' })
      .then((reg) => {
        // registration worked
        logger.debug(`Registration succeeded. Scope is ${reg.scope}`);
      })
      .catch((error) => {
        // registration failed
        logger.debug(`Registration failed with ${error}`);
      });
  }

  return Promise.reject(new Error('Browser does not support service workers'));
};
