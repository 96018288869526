import { NotificationContentComponent } from 'Webapp/shared/app/components/notifications/notification-text-click-strategies/index';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';

import SectionLink from 'Webapp/shared/app/components/section-link';
import FollowButton from 'Webapp/shared/app/components/follow-button';
import NotificationThumb from 'Webapp/shared/app/components/notifications/notification-thumb';
import NotificationTime from 'Webapp/shared/app/components/notifications/notification-time';

import withT from 'ComponentLibrary/hocs/withT';
import connector from 'Webapp/utils/connector';

const FollowWrapper = styled.div({
  display: 'grid',
  gridTemplateRows: '1fr auto',
  gap: SPACING.SMALL,
});
const FollowMetaWrapper = styled.div({
  display: 'grid',
  gridTemplateRows: 'auto auto',
  justifyItems: 'end',
  gap: SPACING.SMALL,
  marginBottom: 'auto',
});

interface FollowYouProps {
  t: Flipboard.TFunction;
}
const FollowYou: NotificationContentComponent<FollowYouProps> = ({
  notification,
  children,
  t,
}) => {
  const section = notification.sectionLinks?.[0];

  return (
    <>
      <FollowWrapper>
        <SectionLink section={section} openInNewWindow={false}>
          {children}
          <NotificationTime notification={notification} />
        </SectionLink>
      </FollowWrapper>
      <FollowMetaWrapper>
        <NotificationThumb notification={notification} />
        <FollowButton tooltip={t('comment')} section={section} />
      </FollowMetaWrapper>
    </>
  );
};

export default connector<FollowYouProps>()(withT(FollowYou));
