import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Config from 'Config';

import {
  loadRecaptchaScript,
  validateRecaptchaV3,
  validateRecaptchaV2,
  setCookieRecaptchaScore,
  THRESHOLD_SCORE,
} from 'Webapp/client/lib/recaptcha';
import HocUtil from 'Utils/hoc-util';
import sentry from 'Utils/sentry';
import getWindow from 'Utils/get-window';
import { ReCaptchaValidationError } from 'Utils/errors';

import ReCaptcha from 'ComponentLibrary/recaptcha/recaptcha';

import connector from 'Utils/connector';

import connectTheme from 'Webapp/shared/app/connectors/connectTheme';

const StyledReCaptcha = styled(ReCaptcha)({
  display: 'inline-block',
});

const initialState = {
  isReCaptchaPending: false,
  failedV3Validation: false,
  failedV2Validation: false,
};

const withReCaptcha = (Component) => {
  class WrappedComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = initialState;
    }
    componentDidMount() {
      loadRecaptchaScript();
    }

    reset = () => {
      this.setState(initialState);
    };

    validateReCaptcha = async (type, onValidated) => {
      this.setState({ isReCaptchaPending: true });
      this.onValidated = onValidated;
      const reCaptchaResponse = await validateRecaptchaV3(type);
      setCookieRecaptchaScore(reCaptchaResponse?.score);
      if (reCaptchaResponse?.score > THRESHOLD_SCORE) {
        await onValidated();
        this.reset();
      } else {
        if (getWindow().grecaptcha) {
          this.setState({
            isReCaptchaPending: true,
            failedV3Validation: true,
          });
        }
      }
    };

    v2CheckboxCallback = async (token) => {
      try {
        this.setState({
          isReCaptchaPending: true,
        });
        const response = await validateRecaptchaV2(token);
        if (response.success) {
          await this.onValidated();
          this.reset();
        } else {
          this.setState({
            failedV2Validation: true,
            isReCaptchaPending: false,
          });
          throw new ReCaptchaValidationError();
        }
      } catch (e) {
        this.setState({
          failedV2Validation: true,
          isReCaptchaPending: false,
        });
        sentry.captureException(e);
        throw e;
      }
    };

    render() {
      const { failedV2Validation, failedV3Validation, isReCaptchaPending } =
        this.state;
      const { appThemeOverride, appTheme } = this.props;
      const variant =
        appThemeOverride === 'dark' ||
        (!appThemeOverride && appTheme === 'dark')
          ? 'dark'
          : 'light';
      const ReCaptchaUI = this.state.failedV3Validation ? (
        <StyledReCaptcha
          elementID="recaptcha-v2"
          sitekey={Config.GOOGLE_RECAPTCHA_PUBLIC_KEY_V2}
          verifyCallback={this.v2CheckboxCallback}
          failedV3Validation={failedV3Validation}
          themeColor={variant}
          isRecaptchaOn
        />
      ) : null;

      return (
        <Component
          {...this.props}
          ReCaptchaUI={ReCaptchaUI}
          validateReCaptcha={this.validateReCaptcha}
          reCaptchaFailedValidation={failedV2Validation && failedV3Validation}
          isReCaptchaPending={isReCaptchaPending}
        />
      );
    }
  }
  WrappedComponent.propTypes = {
    appTheme: PropTypes.string,
    appThemeOverride: PropTypes.string,
  };
  WrappedComponent.displayName = HocUtil.displayName(Component);
  return connector(connectTheme)(WrappedComponent);
};

export default withReCaptcha;
