import React from 'react';
import PropTypes from 'prop-types';
import Link from 'ComponentLibrary/navigation/link';
import { NavFromContext } from 'ComponentLibrary/context';
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';

import connector from 'Utils/connector';
import connectAmp from 'Webapp/shared/app/connectors/connectAmp';
import connectGATarget from 'Webapp/shared/app/connectors/connectGATarget';
import connectUsageSetNavFrom from 'Webapp/shared/app/connectors/connectUsageSetNavFrom';

function WebLink({ usageSetNavFrom, navFromEventName, onClick, ...linkProps }) {
  const onClickWithSetNavFrom =
    (navFrom) =>
    (...args) => {
      if (navFrom && navFromEventName) {
        usageSetNavFrom(navFromEventName, navFrom);
      }
      if (onClick) {
        onClick(...args);
      }
    };
  return (
    <NavFromContext.Consumer>
      {(navFrom) => (
        <Link {...linkProps} onClick={onClickWithSetNavFrom(navFrom)} />
      )}
    </NavFromContext.Consumer>
  );
}

WebLink.propTypes = {
  isAmp: PropTypes.bool.isRequired,
  gaTarget: PropTypes.string,
  navFromEventName: PropTypes.oneOf(Object.values(USAGE_EVENT_NAMES)),
  onClick: PropTypes.func,
  styleVariation: PropTypes.string,
  usageSetNavFrom: PropTypes.func.isRequired,
};

export default connector(
  connectGATarget,
  connectAmp,
  connectUsageSetNavFrom,
)(WebLink);
