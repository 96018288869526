/* eslint-disable no-undef */

import getWindow from 'Utils/get-window';
import { euc } from 'Utils/url';
import Config from 'Config';

const TWITTER_SHARE_URL = 'https://twitter.com/intent/tweet';
const FACEBOOK_SHARE_URL = 'https://www.facebook.com/dialog/share';
const LINKEDIN_SHARE_URL = 'https://www.linkedin.com/sharing/share-offsite';

/**
 * Handles sharing on Facebook
 * @param  {String} url - Url to be shared
 */
const shareOnFacebook = function (url) {
  getWindow().open(
    `${FACEBOOK_SHARE_URL}?app_id=${Config.FACEBOOK_SDK_APP_ID}&display=popup&href=${url}&fallback_redirect_uri=${Config.FLIPBOARD_URL}`,
  );
};

const shareOnTwitter = function (url, title) {
  getWindow().open(
    `${TWITTER_SHARE_URL}?via=flipboard&original_referer=${
      getWindow().location.href
    }&url=${url}&text=${euc(title)}`,
  );
};

const shareOnLinkedin = function (url) {
  getWindow().open(
    `${LINKEDIN_SHARE_URL}?url=${url}`,
    '_blank',
    'height=600,width=400',
  );
};

export default { shareOnFacebook, shareOnTwitter, shareOnLinkedin };
