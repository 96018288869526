import React from 'react';
import styled from '@emotion/styled';
import { isSectionLinkAuthor, isSectionLinkMagazine } from 'Webapp/predicates';

import MagazineThumbnail from 'Webapp/shared/app/components/magazine-thumbnail';
import withT from 'ComponentLibrary/hocs/withT';

import SectionLink from 'Webapp/shared/app/components/section-link';
import SectionUtil from 'Utils/content/section-util';
import { SPACING } from 'Style/spacing';
import { BODY_TYPES } from 'Style/typography';
import { TEXT_COLORS } from 'Style/colors';
import connector from 'Utils/connector';

const StyledSectionLink = styled(SectionLink)({
  display: 'flex',
  flexDirection: 'row',
  gap: SPACING.BASE,
});

const StyledSectionLinkItems = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
});
const MagazineAuthorName = styled.h5(BODY_TYPES.XSMALL_STANDARD, {
  color: TEXT_COLORS.tertiary,
});

const MagazineTitle = styled.h5(BODY_TYPES.SMALL_STANDARD, {
  color: TEXT_COLORS.primary,
});

const StyledMagazineThumbnail = styled(MagazineThumbnail)({
  borderRadius: SPACING.BASE,
});

interface ItemMetricDetailFlip {
  item: Flipboard.Item;
  t: Flipboard.TFunction;
}

const ItemMetricDetailFlip: React.FC<ItemMetricDetailFlip> = ({
  item: { sectionLinks },
  t,
}) => {
  const author = sectionLinks.find(isSectionLinkAuthor);
  const magazine = sectionLinks.find(isSectionLinkMagazine);
  if (!author || !magazine) {
    return null;
  }
  return (
    <StyledSectionLink
      section={SectionUtil.projection(magazine)}
      openInNewWindow
    >
      <StyledMagazineThumbnail size={40} image={magazine.image} />
      <StyledSectionLinkItems>
        <MagazineTitle>{magazine.title}</MagazineTitle>
        <MagazineAuthorName>
          {t('downcase_by_author', {
            authorDisplayName: author.authorDisplayName,
          })}
        </MagazineAuthorName>
      </StyledSectionLinkItems>
    </StyledSectionLink>
  );
};

export default connector()(withT(ItemMetricDetailFlip));
