import isUnknownType from 'Webapp/shared/app/components/notifications/notification-text-click-strategies/shared/is-unknown-type';
import { NotificationTextClickStrategy } from 'Webapp/shared/app/components/notifications/notification-text-click-strategies/index';
import ActionUrl, {
  getSectionIdFromActionURL,
  getURLFromActionURL,
} from 'Webapp/shared/app/components/notifications/content/action-url';

const strategy: NotificationTextClickStrategy = (notification) => {
  if (isUnknownType(notification)) {
    return null;
  }
  const actionURL = notification?.actionURL;
  if (!actionURL) {
    return null;
  }
  const url = getURLFromActionURL(actionURL);
  const sectionId = getSectionIdFromActionURL(actionURL);
  if (!url && !sectionId) {
    return null;
  }
  return ActionUrl;
};

export default strategy;
