import { URL_REGEXP } from 'Utils/reg-exp';

export const truncateText = (text, length, appendEllipsis = false) => {
  if (typeof text !== 'string') {
    return text;
  }
  const truncated = text.split(/\s/).reduce((accum, current) => {
    if (accum.length < length) {
      return `${accum} ${current}`;
    }

    return accum.trim();
  }, '');
  const isShortened = truncated.length < text.length;
  return appendEllipsis && isShortened ? `${truncated}...` : truncated;
};

export const stripURLs = (text, replaceWithEllipsis = true) => {
  const stripped = text.replace(new RegExp(URL_REGEXP, 'gi'), '');
  const zeroLengthReplacement = replaceWithEllipsis ? '\u2026' : null;

  return stripped.length === 0 ? zeroLengthReplacement : stripped;
};
