import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Utils
import SocialUtil from 'Utils/social-util';
import getWindow from 'Utils/get-window';
import { getSectionShareLink } from 'Utils/content/flipboard-urls';
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';

// Components
import ModalControls from 'Webapp/shared/app/components/modal-controls';
import { MODAL_THEMES } from 'ComponentLibrary/modals/modal';
import Input from 'Webapp/shared/app/components/base/input';
import Button, {
  StyleVariations,
  StyleModifiers,
} from 'Webapp/shared/app/components/button';
import FacebookIcon from 'ComponentLibrary/icons/facebook';
import TwitterIcon from 'ComponentLibrary/icons/twitter';
import ItemCondensed from '../components/item/item-condensed';
import ModalWrapper from './modal-wrapper';

import withNavFrom from '../hocs/withNavFrom';
import withToast from '../hocs/withToast';
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectUsageSetNavFrom from 'Webapp/shared/app/connectors/connectUsageSetNavFrom';
import connectModal from 'Webapp/shared/app/connectors/connectModal';

class ShareModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shareLink: null,
      isLoading: true,
      displayCopyMessage: false,
    };

    this.handleFocus = this.handleFocus.bind(this);
    this.clearCopyMessage = this.clearCopyMessage.bind(this);
    this.handleClickFacebookButton = this.handleClickFacebookButton.bind(this);
    this.handleClickTwitterButton = this.handleClickTwitterButton.bind(this);
  }

  async componentDidMount() {
    const { item, section, getShortUrl, t } = this.props;
    // non-section item is provided
    if (item && !item.isSection && !item.isSectionCover) {
      if (item && item.isFlipboardInternalContent) {
        this.setState({ shareLink: item.sourceURL, isLoading: false });
        return;
      }
      try {
        const shortUrl = await getShortUrl(item);
        if (shortUrl) {
          this.setState({ shareLink: shortUrl, isLoading: false });
        } else {
          this.setState({ isLoading: false });
        }
      } catch (_) {
        this.props.dismissModal();
        this.props.toastShowErrorAction(t('fallback_error_message'));
      }
    } else {
      // Account for case where section is rendered as an item in a feed
      const sectionToShare = (item && item.section) || section;
      this.setState({
        shareLink: getSectionShareLink(sectionToShare, this.props.uid),
        isLoading: false,
      });
    }
  }

  componentWillUnmount() {
    if (this.toggleCopyMessageTimeout) {
      this.clearCopyMessage();
    }
  }

  handleFocus(e) {
    const input = e.target;
    input.setSelectionRange(0, this.state.shareLink.length);
    getWindow().document.execCommand('copy');
    this.setState({ displayCopyMessage: true });
    this.toggleCopyMessageTimeout = getWindow().setTimeout(
      this.clearCopyMessage,
      2000,
    );
  }

  clearCopyMessage() {
    if (this.toggleCopyMessageTimeout) {
      getWindow().clearTimeout(this.toggleCopyMessageTimeout);
      this.toggleCopyMessageTimeout = null;
      this.setState({ displayCopyMessage: false });
    }
  }

  handleClickFacebookButton() {
    const { item, section, navFrom, usageSetNavFrom, usageTrackItemShare } =
      this.props;
    if (navFrom) {
      usageSetNavFrom(USAGE_EVENT_NAMES.ITEM_SHARE, navFrom);
    }
    usageTrackItemShare(item, section, 'facebook');
    SocialUtil.shareOnFacebook(this.state.shareLink);
  }

  handleClickTwitterButton() {
    const { item, section, navFrom, usageSetNavFrom, usageTrackItemShare } =
      this.props;
    if (navFrom) {
      usageSetNavFrom(USAGE_EVENT_NAMES.ITEM_SHARE, navFrom);
    }
    usageTrackItemShare(item, section, 'twitter');
    const shareText = item ? item.sanitizedTitle : section.title;
    SocialUtil.shareOnTwitter(this.state.shareLink, shareText);
  }

  render() {
    const { section, item, dismissModal, t } = this.props;
    const { isLoading, shareLink, displayCopyMessage } = this.state;
    const remoteid = (section && section.remoteid) || (item && item.remoteid);
    return (
      <ModalWrapper name="share" modalTheme={MODAL_THEMES.WINDOW}>
        <section className="share modal__content">
          {isLoading ? (
            <p className="ui-text--body">{t('generating_link')}</p>
          ) : (
            <div>
              <h1 className="ui-text--title share__title">{t('share')}</h1>
              {item && (
                <div className="share__item-wrapper">
                  <ItemCondensed item={item} shownInList={false} />
                </div>
              )}
              <Button
                name="share-facebook"
                className="button--with-badge--facebook button--with-badge--share button--with-badge--facebook--share no-text-transform"
                styleModifier={[
                  StyleModifiers.WITH_BADGE,
                  StyleModifiers.BLOCK,
                ]}
                onClick={this.handleClickFacebookButton}
                clickEventDetails={{ remoteid }}
              >
                <FacebookIcon />
                <span>{t('share_on_facebook')}</span>
              </Button>
              <Button
                name="share-twitter"
                className="button--with-badge--twitter button--with-badge--share button--with-badge--twitter--share no-text-transform"
                styleModifier={[
                  StyleModifiers.WITH_BADGE,
                  StyleModifiers.BLOCK,
                ]}
                onClick={this.handleClickTwitterButton}
                clickEventDetails={{ remoteid }}
              >
                <TwitterIcon />
                <span>{t('share_on_twitter')}</span>
              </Button>
              <p className="ui-text--body" key={3}>
                {t('share_the_link_below')}
              </p>
              <Input value={shareLink} onFocus={this.handleFocus} readOnly />
              {displayCopyMessage && (
                <p className="share__copy-link-message ui-text--body">
                  {t('copied')}
                </p>
              )}
            </div>
          )}
        </section>
        <ModalControls>
          <Button
            name="create-magazine-dismiss"
            label={t('cancel')}
            styleVariation={StyleVariations.SECONDARY}
            onClick={dismissModal}
          />
        </ModalControls>
      </ModalWrapper>
    );
  }
}

ShareModal.propTypes = {
  dismissModal: PropTypes.func.isRequired,
  section: PropTypes.object,
  item: PropTypes.object,
  navFrom: PropTypes.string,
  uid: PropTypes.number,
  t: PropTypes.func.isRequired,
  toastShowErrorAction: PropTypes.func.isRequired,
  usageSetNavFrom: PropTypes.func.isRequired,
  usageTrackItemShare: PropTypes.func.isRequired,
  getShortUrl: PropTypes.func.isRequired,
};

ShareModal.defaultProps = {
  section: null,
  item: null,
  navFrom: null,
  uid: null,
};

export default connector(
  connectUsageSetNavFrom,
  connectAuthentication,
  connectModal,
)(withNavFrom(withToast(withT(ShareModal))));
