import React from 'react';
import styled from '@emotion/styled';
import { BREAKPOINTS } from 'Style/breakpoints';
import { LAYERS } from 'Style/layout';
import { styleObject } from 'Style/type-helpers';
import {
  BODY_TYPES,
  CONTENT_HEADING_TYPES,
  CONTENT_BODY,
} from 'Style/typography';
import { SPACING } from 'Style/spacing';
import { SURFACE_COLORS, TEXT_COLORS, DIVIDER_COLORS } from 'Style/colors';
import { truncate } from 'Style/truncate';

import ModalControls from 'Webapp/shared/app/components/modal-controls';
import ModalWrapper from 'Webapp/shared/app/modals/modal-wrapper.js';
import {
  MODAL_WINDOW_SIZES,
  MODAL_OVERlAY_MODIFIERS,
  MODAL_THEMES,
  MODAL_MODIFIERS,
} from 'ComponentLibrary/modals/modal.js';
import MagazineThumbnail from 'Webapp/shared/app/components/magazine-thumbnail';
import MagazineContributorsDescription from 'Webapp/shared/app/components/magazine-contributors-description';

import connector from 'Webapp/utils/connector';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';
import connectResponsive, {
  ConnectResponsiveProps,
} from 'Webapp/shared/app/connectors/connectResponsive';

import withT from 'ComponentLibrary/hocs/withT';
import ItemProfileListItem from 'Webapp/shared/app/components/item-profile-list-item';

const MAGAZINE_THUMB_WIDTH = 79;
const MAGAZINE_THUMB_HEIGHT = 96;

const Wrapper = styled(ModalWrapper)(
  { overflow: 'hidden' },
  styleObject(
    BREAKPOINTS.tabletPortraitUp({
      borderRadius: '10px',
    }),
  ),
);

const Header = styled.div({
  backgroundColor: SURFACE_COLORS.primaryReverse,
  padding: `${SPACING.BASE4X} ${SPACING.BASE8X} ${SPACING.BASE4X} ${SPACING.BASE4X}`,
  borderRadius: '10px 10px 0 0',
  position: 'sticky',
  top: 0,
  zIndex: LAYERS.BASE,
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: SPACING.MEDIUM,
});

const StyledMagazineThumbnail = styled(MagazineThumbnail)({
  borderRadius: SPACING.BASE,
});
const MagazineDetails = styled.div({
  display: 'grid',
  gap: SPACING.SMALL,
  height: 'fit-content',
  alignSelf: 'center',
});

const MagazineTitle = styled.h2(
  CONTENT_HEADING_TYPES.LARGE,
  truncate('100%', 2),
  {
    color: TEXT_COLORS.overlay,
  },
);
const MagazineHeaderAttribution = styled.p(CONTENT_BODY, {
  color: TEXT_COLORS.overlay,
  a: { ...CONTENT_BODY, color: TEXT_COLORS.overlay },
});

const Body = styled.div({
  padding: `${SPACING.BASE4X} ${SPACING.LARGE}`,
  overflowY: 'scroll',
});

const ContributorCount = styled('div')(BODY_TYPES.XSMALL_STANDARD, {
  color: TEXT_COLORS.tertiary,
});
const ContributorList = styled.ul({});
const ContributorListItem = styled.li({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  padding: `${SPACING.MEDIUM} 0`,
  borderBottom: `1px solid ${DIVIDER_COLORS.primary}`,
  '&:last-child': {
    border: 'none',
  },
});
const OwnerBadge = styled.div(BODY_TYPES.XSMALL_EMPHASIS, {
  display: 'grid',
  padding: `6px ${SPACING.BASE}`,
  alignItems: 'center',
  justifyItems: 'center',
  height: 'fit-content',
  background: '#287AF5',
  borderRadius: '8px',
  textTransform: 'uppercase',
  color: TEXT_COLORS.overlay,
});

type MagazineContributorsModalProps = {
  magazine: Flipboard.Section;
  allProfiles: Flipboard.Section[];
  t: Flipboard.TFunction;
} & ConnectModalProps &
  ConnectResponsiveProps;

const MagazineContributorsModal: React.FC<MagazineContributorsModalProps> = ({
  magazine,
  allProfiles,
  isPhone,
  dismissModal,
  t,
}) => (
  <Wrapper
    name={name}
    size={MODAL_WINDOW_SIZES.MEDIUM}
    overlayModifier={MODAL_OVERlAY_MODIFIERS.LIGHT}
    modalTheme={isPhone ? MODAL_THEMES.FULLSCREEN : MODAL_THEMES.WINDOW}
    modifier={MODAL_MODIFIERS.HAS_FIXED_HEADER}
  >
    <ModalControls
      modalName="comments-modal"
      fixedControls
      closeButtonSize={18}
      showCloseButton
      onDismiss={dismissModal}
    />
    <Header>
      <StyledMagazineThumbnail
        width={MAGAZINE_THUMB_WIDTH}
        height={MAGAZINE_THUMB_HEIGHT}
        image={magazine.image}
      />
      <MagazineDetails>
        <MagazineTitle>{magazine.title}</MagazineTitle>
        <MagazineHeaderAttribution>
          <MagazineContributorsDescription
            section={magazine}
            profiles={allProfiles}
            entireProfiles={allProfiles}
            undisplayedCount={allProfiles.length - 1}
            isNewMagazineCover
          />
        </MagazineHeaderAttribution>
      </MagazineDetails>
    </Header>
    <Body>
      <ContributorCount>
        {t('contributor_count', { count: allProfiles.length })}
      </ContributorCount>
      <ContributorList>
        {allProfiles.map((profile) => {
          const profileIsMagOwner =
            profile.authorUsername === magazine.authorUsername;
          return (
            <ContributorListItem key={profile.userid}>
              <ItemProfileListItem profile={profile} />
              {profileIsMagOwner && <OwnerBadge>{t('owner')}</OwnerBadge>}
            </ContributorListItem>
          );
        })}
      </ContributorList>
    </Body>
  </Wrapper>
);
export default connector<MagazineContributorsModalProps>(
  connectModal,
  connectResponsive,
)(withT(MagazineContributorsModal));
