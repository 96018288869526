import { getCurrentArticle } from 'Webapp/shared/app/redux/selectors/article';

export default (_, getState, { t }) => {
  const article = getCurrentArticle(getState());
  if (article && article.isFirstPartyVideo) {
    const topics = article.topics.map((i) => i.title).join(', ');
    if (article.description) {
      return topics
        ? t('video_seo_description', {
            description: article.description,
            topics,
          })
        : article.description;
    }
    return topics
      ? t('video_seo_description_generic', {
          profileName:
            article && article.author && article.author.authorDisplayName,
          topics,
        })
      : t('video_seo_description_generic_no_topics', {
          profileName:
            article && article.author && article.author.authorDisplayName,
        });
  }
};
