import { isSectionRoute } from 'Webapp/shared/app/redux/selectors/routing';

export const isFlipModeEnabled = ({ flipMode: { enabled } }) => enabled;

export const flipModeTargetMagazine = ({
  flipMode: { targetRemoteId },
  profile,
}) => {
  const { magazines, contributorMagazines } = profile;
  return (
    magazines.find((m) => m.remoteid === targetRemoteId) ||
    contributorMagazines.find((cm) => cm.remoteid === targetRemoteId)
  );
};

export const isFlipModeRoute = (state) => isSectionRoute(state);

export const hasFlipModeFeature = ({ featureFlags }) =>
  featureFlags.FAST_FLIP || false;
