import React from 'react';
import PropTypes from 'prop-types';
function PlayIcon(props) {
  const { size } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
        fill="#262626"
        fillOpacity="0.6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6754 14.3246L34.7279 23.7279L18.6754 33.1313V14.3246Z"
        fill="white"
      />
    </svg>
  );
}

PlayIcon.propTypes = {
  size: PropTypes.number,
  border: PropTypes.bool,
  className: PropTypes.string,
};

PlayIcon.defaultProps = {
  size: 32,
  border: false,
  className: '',
};

export default PlayIcon;
