import React from 'react';
import PropTypes from 'prop-types';
import codeSplitLoad from 'Webapp/shared/utils/code-split-load';

import {
  hasValidDimensions,
  originalWidth,
  originalHeight,
  getFocusPointNormalized,
} from 'Utils/image-util';

import AmpImage from './amp-image';
import DumbCroppedImage from './dumb-cropped-image';
import GenericImage from './generic-image';

import connector from 'Utils/connector';
import connectAmp from 'Webapp/shared/app/connectors/connectAmp';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';

export { THEMES } from './generic-image';

const CroppedImage = codeSplitLoad('CroppedImage');

const Image = ({
  isAmp,
  crop,
  useDumbCroppedImage,
  isAuthenticated,
  size,
  image,
  fallbackElement,
  ...props
}) => {
  if (!image) {
    return fallbackElement ? fallbackElement : null;
  }

  let height = size;
  let width = size;
  if (typeof image !== 'string' && !size) {
    const valid = hasValidDimensions(image);
    width = valid ? originalWidth(image) : null;
    height = valid ? originalHeight(image) : null;
    if (size) {
      width = size;
      height = size;
    }
  }

  const updatedProps = Object.assign(props, {
    fallbackElement,
    image,
    // if size is unset, height and width will be undefined and not
    // override props.height / props.width
    height,
    width,
  });

  if (isAmp) {
    return (updatedProps.width && updatedProps.height) ||
      (updatedProps.ampWidth && updatedProps.ampHeight) ? (
      <AmpImage {...updatedProps} />
    ) : null;
  }
  if (crop && isAuthenticated && !useDumbCroppedImage) {
    const focusPointNormalized = getFocusPointNormalized(image);
    return (
      <CroppedImage
        {...updatedProps}
        focusPointNormalized={focusPointNormalized}
      />
    );
  }
  if (crop && (!isAuthenticated || useDumbCroppedImage)) {
    return <DumbCroppedImage {...updatedProps} />;
  }
  return <GenericImage {...updatedProps} />;
};

Image.propTypes = {
  isAmp: PropTypes.bool,
  crop: PropTypes.bool,
  useDumbCroppedImage: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  size: PropTypes.number,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  fallbackElement: PropTypes.node,
  ampClass: PropTypes.string,
};

Image.defaultProps = {
  isAmp: false,
  crop: false,
  useDumbCroppedImage: false,
  isAuthenticated: false,
  image: {},
  fallbackElement: null,
  size: null,
};

export default connector(connectAuthentication, connectAmp)(Image);
