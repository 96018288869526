import GlobalVars from 'Utils/global-vars';
import FlapServer from 'Utils/api/flap-server-factory';
import FlapClient from 'Utils/api/flap/client';

/**
 * Wrapper for Flap axios instances.
 * To avoid any confusion in a isomorphic application, just import
 * this file as your Flap axios instance, and it should work on servers and clients
 * import Flap from 'Utils/api/flap'
 */
const Flap = GlobalVars.isServer() ? FlapServer : FlapClient;

export const MAX_UPDATE_FEED_LIMIT = 30;

export default Flap;
