import React from 'react';
import Icon from './icon';

function CheckmarkIcon(props) {
  return (
    <Icon
      name="checkmark"
      {...props}
      viewBox="0 0 18 18"
      renderComponent={({ color }) => (
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          transform="translate(-397.000000, -511.000000)"
        >
          <g transform="translate(128.000000, 42.000000)" fill={color}>
            <g transform="translate(24.000000, 450.000000)">
              <g transform="translate(242.000000, 16.000000)">
                <polygon points="9.94974747 14.9852814 6.41421356 11.4497475 5 12.863961 9.94974747 17.8137085 19.8492424 7.91421356 18.4350288 6.5" />
              </g>
            </g>
          </g>
        </g>
      )}
    />
  );
}

export default CheckmarkIcon;
