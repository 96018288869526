import React from 'react';
import PropTypes from 'prop-types';

// Utils
import getWindow from 'Utils/get-window';

class WaitFor extends React.Component {
  constructor(props) {
    super(props);

    // retry for two minutes
    this.retryCount = 0;
    this.maxRetries = 240;
    this.intervalDuration = 500;

    this.performCheck = this.performCheck.bind(this);
  }

  componentDidMount() {
    this.checkInterval = getWindow().setInterval(
      this.performCheck,
      this.intervalDuration,
    );
    this.performCheck();
  }

  componentWillUnmount() {
    getWindow().clearInterval(this.checkInterval);
  }

  performCheck() {
    if (this.retryCount > this.maxRetries) {
      return getWindow().clearInterval(this.checkInterval);
    }

    const ready = this.props.predicate();

    if (!ready) {
      this.retryCount++;
    } else {
      getWindow().clearInterval(this.checkInterval);
      this.props.onWaitComplete();
    }
  }

  render() {
    return null;
  }
}

WaitFor.propTypes = {
  predicate: PropTypes.func.isRequired,
  onWaitComplete: PropTypes.func.isRequired,
};

export default WaitFor;
