import { Breadcrumbs } from 'Utils/content/structured-data';
import { getStoryboardAnalyticsUrl } from 'Utils/content/flipboard-urls';

const analyticsRoot = '/analytics';

export default ({ analyticsStoryboard }) => {
  if (!analyticsStoryboard || !analyticsStoryboard.isStoryboard) {
    return null;
  }

  const breadcrumbs = new Breadcrumbs();
  breadcrumbs.add('Analytics', analyticsRoot);

  const username =
    analyticsStoryboard.author && analyticsStoryboard.author.authorUsername;
  const id = analyticsStoryboard.storyboardDefinitionId;
  if (username && id) {
    breadcrumbs.add(
      analyticsStoryboard.title,
      getStoryboardAnalyticsUrl(username, id),
    );
  }

  return breadcrumbs;
};
