import {
  updateRelatedItemPreference,
  updateUserContentOptions,
} from 'Webapp/shared/app/redux/actions/social-actions.js';
import {
  muteAuthor,
  unmuteAuthor,
  muteSourceDomain,
  unmuteSourceDomain,
} from 'Webapp/shared/app/redux/actions/muting-actions';
import { Connector } from 'Webapp/utils/connector';

const actions = {
  muteAuthor,
  unmuteAuthor,
  muteSourceDomain,
  unmuteSourceDomain,
  updateRelatedItemPreference,
  updateUserContentOptions,
};
export type ConnectMuteProps = Flipboard.ConnectorProps<
  unknown,
  typeof actions
>;
const connectMute: Connector = {
  actions,
};

export default connectMute;
