import { SOCIAL_ACTIVITY_TYPES, MUTING_TYPES } from 'Webapp/action-types';
import { set } from 'Utils/redux';
import {
  toastShowInfoAction,
  toastShowErrorAction,
  toastGenericError,
} from 'Webapp/shared/app/redux/actions/toast-actions';
import {
  usageTrackItemLike,
  usageTrackItemUnlike,
  usageSetNavFrom,
} from 'Webapp/shared/app/redux/actions/usage-actions';
import { putState } from 'Webapp/shared/app/redux/actions/profile-actions';
import { updateItemSocialActivity } from 'Webapp/shared/concepts/social-activity';
import { userStateDataSelector } from '../selectors/profile';
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';
import { UnknownAPIError } from 'Utils/errors';

/**
 * Likes an item
 * @param {Object} item - Flipboard item object
 * @param {Number} uid  - Flipboard userid
 * @param {String} articleRemoteId - (optional) RemoteID for the article when liking on an article route
 * @param {Object} section - (optional) Flipboard section object the item is shown within
 * @param {String} navFrom - (optional) usage navFrom value
 * @return {Promise}    - A promise that resolves as an object
 */
export const likeItem =
  (item, uid, section, navFrom = undefined) =>
  async (dispatch, _, { flap, t }) => {
    try {
      if (!item) {
        return null;
      }
      dispatch({
        type: SOCIAL_ACTIVITY_TYPES.LIKE_ITEM_PENDING,
        payload: {
          flipboardSocialId: item.flipboardSocialId,
        },
      });
      const params = {
        oid: item.flipboardSocialId,
        url: item.sourceURL,
        service: item.service,
        sectionid: section && section.sectionID,
        remoteServiceItemID: item.remoteServiceItemID,
        userid: uid,
      };
      dispatch({ type: SOCIAL_ACTIVITY_TYPES.LIKE_ITEM });
      if (navFrom) {
        dispatch(usageSetNavFrom(USAGE_EVENT_NAMES.ITEM_LIKE, navFrom));
      }
      const { data } = await flap.post('/social/like', {}, { params });
      if (!data.success) {
        throw new UnknownAPIError();
      }

      dispatch({
        type: SOCIAL_ACTIVITY_TYPES.LIKE_ITEM_SUCCEEDED,
        payload: {
          flipboardSocialId: item.flipboardSocialId,
        },
      });
      dispatch(usageTrackItemLike(item, section));
      dispatch(updateItemSocialActivity(item));
    } catch (e) {
      dispatch({
        type: SOCIAL_ACTIVITY_TYPES.LIKE_ITEM_FAILED,
        payload: {
          flipboardSocialId: item.flipboardSocialId,
        },
      });
      dispatch(
        toastShowErrorAction(
          t('like_item_failure', {
            itemTitle: item.title,
          }),
        ),
      );
    }
  };

/**
 * Unlikes an item
 * @param {Object} item - Flipboard item object
 * @param {Number} uid  - Flipboard userid
 * @param {String} articleRemoteId  - RemoteId of the article
 * @param {Object} section - (optional) Flipboard section object the item is shown within
 * @param {String} navFrom - (optional) usage navFrom value
 * @return {Promise}    - A promise that resolves as an object
 */
export const unlikeItem =
  (item, uid, section = null, navFrom = null) =>
  async (dispatch, _, { flap, t }) => {
    try {
      if (!item) {
        return null;
      }
      dispatch({
        type: SOCIAL_ACTIVITY_TYPES.UNLIKE_ITEM_PENDING,
        payload: {
          flipboardSocialId: item.flipboardSocialId,
        },
      });
      const params = {
        oid: item.flipboardSocialId,
        service: item.service,
        userid: uid,
      };
      dispatch({ type: SOCIAL_ACTIVITY_TYPES.UNLIKE_ITEM });
      if (navFrom) {
        dispatch(usageSetNavFrom(USAGE_EVENT_NAMES.ITEM_UNLIKE, navFrom));
      }
      const { data } = await flap.post('/social/unlike', {}, { params });
      if (!data.success) {
        throw new UnknownAPIError();
      }

      dispatch({
        type: SOCIAL_ACTIVITY_TYPES.UNLIKE_ITEM_SUCCEEDED,
        payload: {
          flipboardSocialId: item.flipboardSocialId,
        },
      });
      dispatch(usageTrackItemUnlike(item, section));
      dispatch(updateItemSocialActivity(item));
    } catch (_) {
      dispatch({
        type: SOCIAL_ACTIVITY_TYPES.UNLIKE_ITEM_FAILED,
        payload: {
          flipboardSocialId: item.flipboardSocialId,
        },
      });
      dispatch(
        toastShowErrorAction(
          t('unlike_item_failure', {
            itemTitle: item.title,
          }),
        ),
      );
    }
  };

/**
 * Updates user's preference feed (lesslike)  with positive/negative feedback from
 * item's related topics
 * @param {Object} selectedTopic  - related topic to the item object that user selected
 * @param {Boolean} isPositivePreference  - boolean flag whether to undo negative less like or not
 */
export const updateRelatedItemPreference =
  (selectedTopic, isPositivePreference) =>
  async (dispatch, _, { flap, t }) => {
    const flapPreferenceEndpoint = isPositivePreference
      ? '/flipboard/positivePreferences'
      : '/flipboard/negativePreferences';
    const toastKey = isPositivePreference
      ? 'show_more_contents'
      : 'show_less_contents';
    try {
      dispatch({ type: MUTING_TYPES.LESS_LIKE_ITEM });
      await flap.post(flapPreferenceEndpoint, selectedTopic);
      dispatch({ type: MUTING_TYPES.LESS_LIKE_ITEM_SUCCEEDED });
      dispatch(
        toastShowInfoAction(t(toastKey, { label: selectedTopic.title })),
      );
    } catch (error) {
      dispatch({ type: MUTING_TYPES.LESS_LIKE_ITEM_FAILED });
      dispatch(toastGenericError());
      throw error;
    }
  };

/**
 * Update user's content option including sourceDomains and Authors
 * @param {Object} contentOptions - user's pending content options
 */
export const updateUserContentOptions =
  (contentOptions) =>
  async (dispatch, getState, { t }) => {
    try {
      const { sourceDomains, authors } = contentOptions;

      await dispatch(
        putState(() => {
          const state = getState();
          const currentUserState = userStateDataSelector(state);

          let newState;
          if (currentUserState?.mutedSourceDomains) {
            const updatedSourceDomain = [];
            for (const domainURL in sourceDomains) {
              if (sourceDomains[domainURL] === true) {
                updatedSourceDomain.push(domainURL);
              }
            }

            newState = Object.assign({}, currentUserState, {
              mutedSourceDomains: updatedSourceDomain,
            });
          }

          if (currentUserState?.mutedAuthors) {
            const updatedMutedAuthors = authors.filter(
              (author) => author.isCurrentlyUnsubscribed === true,
            );

            newState = Object.assign({}, newState, {
              mutedAuthors: updatedMutedAuthors,
            });
          }

          dispatch({ type: MUTING_TYPES.UPDATE_MUTED_CONTENTS, newState });
          dispatch(toastShowInfoAction(t('content_options_update_success')));
          return newState;
        }),
      );
    } catch (e) {
      dispatch({ type: MUTING_TYPES.UPDATE_MUTE_ITEM_FAILED });
      dispatch(toastShowErrorAction(t('Failed to update content options')));
    }
  };

export const addPostOnboardingSocialAction = set(
  SOCIAL_ACTIVITY_TYPES.ADD_POST_ONBOARDING_SOCIAL_ACTION,
  'postOnboardingSocialAction',
);

export const clearPostOnboardingSocialAction = () =>
  addPostOnboardingSocialAction({});
