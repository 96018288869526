import replaceTags, {
  ReplaceTagsReplacmentFunction,
} from 'Utils/i18n/replace-tags';

type GetMagazineContributorsHeaderTextOptions = {
  userProfileLink: ReplaceTagsReplacmentFunction;
  remainingProfilesCount: number;
  currentUserIsOwnerOrCollaborator: boolean;
};

export const MAX_NUMBER_OF_PROFILES = 3;

export const getMagazineContributorsHeaderText = (
  options: GetMagazineContributorsHeaderTextOptions,
  t: Flipboard.TFunction,
) => {
  const {
    userProfileLink,
    remainingProfilesCount,
    currentUserIsOwnerOrCollaborator,
  } = options;

  if (remainingProfilesCount <= 0) {
    return '';
  }

  const translateText = currentUserIsOwnerOrCollaborator
    ? t('contributor_header_you_and_others', { remainingProfilesCount })
    : t('contributor_header_others', { remainingProfilesCount });

  return replaceTags(translateText, {
    userProfileLink,
  });
};

export const getMagazineContributorsToolBannerText = (
  profileLinks: Array<ReplaceTagsReplacmentFunction>,
  t: Flipboard.TFunction,
) => {
  if (!profileLinks || !profileLinks.length) {
    return '';
  }
  let translateText;

  if (profileLinks.length >= MAX_NUMBER_OF_PROFILES) {
    translateText = t('contributor_description_three_users');
    return replaceTags(translateText, {
      ownerProfileLink: profileLinks[0],
      secondProfileLink: profileLinks[1],
      thirdProfileLink: profileLinks[2],
    });
  } else if (profileLinks.length === 2) {
    translateText = t('contributor_description_two_users');
    return replaceTags(translateText, {
      ownerProfileLink: profileLinks[0],
      secondProfileLink: profileLinks[1],
    });
  }

  translateText = t('contributor_description_one_user');
  return replaceTags(translateText, {
    ownerProfileLink: profileLinks[0],
  });
};
