import React, { useState } from 'react';
import classNames from 'classnames';

import { ItemMetricDetailType, ItemActionButtonType } from 'Webapp/enums';

import { USAGE_EVENT_NAMES, USAGE_NAV_FROMS } from 'Utils/analytics/usage.js';
import sentenceCase from 'Utils/content/sentence-case';

// Components
import { StyleModifiers } from 'Webapp/shared/app/components/button';
import OldFlipIcon from 'ComponentLibrary/icons/flip.js';
import ItemMetricDetailButton from 'Webapp/shared/app/components/item-metric-detail-button';
import { getSocialActivityValue } from 'Webapp/shared/concepts/social-activity';
import DefaultItemActionButton from 'Webapp/shared/app/components/item-actions/default-item-action-button';
import { ItemButtonWithMetric } from 'Webapp/shared/app/hocs/withItemActions.js';
import ItemMetric from 'Webapp/shared/app/components/item-actions/item-metric';
import DisabledWrapper from 'Webapp/shared/app/components/disabled-wrapper';

import withT from 'ComponentLibrary/hocs/withT';
import withFlip from 'Webapp/shared/app/hocs/withFlip';

import connector from 'Utils/connector';
import connectFlipMode, {
  ConnectFlipModeProps,
} from 'Webapp/shared/app/connectors/connectFlipMode';
import connectUsageSetNavFrom, {
  ConnectUsageSetNavFromProps,
} from 'Webapp/shared/app/connectors/connectUsageSetNavFrom';
import connectUsageTrackFlip, {
  ConnectUsageTrackFlipProps,
} from 'Webapp/shared/app/connectors/connectUsageTrackFlip';
import connectCurrentUser, {
  ConnectCurrentUserProps,
} from 'Webapp/shared/app/connectors/connectCurrentUser';
import connectSocialActivity, {
  ConnectSocialActivityProps,
} from 'Webapp/shared/app/connectors/connectSocialActivity';
import connectFeatureFlags, {
  ConnectFeatureFlagsProps,
} from 'Webapp/shared/app/connectors/connectFeatureFlags';

type FlipButtonProps = {
  item: Flipboard.Item;
  section?: Flipboard.Section;
  isTooltipMenu: boolean;
  showSocialMetrics: boolean;
  size: number;
  onFlip: (flipProps: Record<string, unknown>) => void;
  t: Flipboard.TFunction;
} & ConnectUsageSetNavFromProps &
  ConnectUsageTrackFlipProps &
  ConnectSocialActivityProps &
  ConnectFeatureFlagsProps &
  ConnectFlipModeProps &
  ConnectCurrentUserProps;

const FlipButton: React.FC<FlipButtonProps> = ({
  section,
  item,
  onFlip,
  isTooltipMenu,
  t,
  isFlipModeEnabled,
  flipModeTargetMagazine,
  toastDismiss,
  flipItem,
  usageSetNavFrom,
  usageTrackTapFlipItem,
  profile,
  showSocialMetrics,
  socialActivity,
  featureFlags,
  size,
  ...otherProps
}) => {
  const [flipPending, setFlipPending] = useState(false);
  const iconSize = size;

  const useFlipMode = isFlipModeEnabled && flipModeTargetMagazine && item;

  const flipButtonClasses = classNames('social-actions__button', {
    'social-actions__button--flip-mode': useFlipMode,
  });

  const handleFlip = async () => {
    if (useFlipMode) {
      toastDismiss();
      usageSetNavFrom(USAGE_EVENT_NAMES.TAP_FLIP, USAGE_NAV_FROMS.FAST_FLIP);
      usageTrackTapFlipItem(section, item, profile);
      setFlipPending(true);
      await flipItem(
        item,
        flipModeTargetMagazine,
        '',
        USAGE_NAV_FROMS.FAST_FLIP,
      );
      setFlipPending(false);

      return;
    }

    onFlip({ section, item });
  };
  const hideIcon = isTooltipMenu && !item && section?.isTopic;
  const styleModifier: Array<StyleModifiers> = [];
  if (isTooltipMenu) {
    styleModifier.push(StyleModifiers.BLOCK);
    if (hideIcon) {
      styleModifier.push(StyleModifiers.ALIGN_LEFT);
    } else {
      styleModifier.push(StyleModifiers.WITH_BADGE);
    }
  }

  // Yes, we receive the commentary as "share count". Flip count is
  // found in share count. Share count is not found anywhere.
  const showGlobalFlips = !section?.isMagazine;
  const flipCount = getSocialActivityValue(
    item,
    socialActivity,
    'shareCount',
    showGlobalFlips,
  );
  const disabled = (!item?.canShare && !item?.canShareLink) || flipPending;

  return (
    <ItemButtonWithMetric key={ItemActionButtonType.FLIP} {...otherProps}>
      <DisabledWrapper disabled={disabled}>
        <DefaultItemActionButton
          name="social-action-flip"
          tooltip={t('flip')}
          isTooltipMenu={isTooltipMenu}
          className={flipButtonClasses}
          styleModifier={styleModifier}
          onClick={handleFlip}
          disabled={disabled}
        >
          {!hideIcon && <OldFlipIcon size={iconSize} isFilled={false} />}
          {isTooltipMenu && <span>{sentenceCase(t('flip'))}</span>}
        </DefaultItemActionButton>
      </DisabledWrapper>
      <DisabledWrapper disabled={disabled}>
        {showSocialMetrics &&
          (featureFlags.ITEM_METRIC_DETAIL && flipCount && !showGlobalFlips ? (
            <ItemMetricDetailButton
              item={item}
              section={section}
              itemMetricDetailType={ItemMetricDetailType.FLIP}
              disabled={disabled}
            >
              <ItemMetric metricValue={flipCount} />
            </ItemMetricDetailButton>
          ) : (
            <ItemMetric metricValue={flipCount} />
          ))}
      </DisabledWrapper>
    </ItemButtonWithMetric>
  );
};

export default connector(
  connectFlipMode,
  connectUsageSetNavFrom,
  connectUsageTrackFlip,
  connectCurrentUser,
  connectSocialActivity,
  connectFeatureFlags,
)(withFlip(withT(FlipButton)));
