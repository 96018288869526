import omit from 'lodash/omit';
import { factory as flaxiosFactory } from 'Utils/flaxios';
import qs from 'Utils/qs';
import ApiHelpers from 'Utils/api/helpers';
import Config from 'Config';
import { parseCookie as parseClientCookie } from 'Utils/client-cookie';
import {
  parseSetCookieHeader,
  getCookieHeaderValue,
} from 'Utils/server-cookie';
import isIntegerMatch from 'Utils/is-integer-match';

const { FLAP_NAME } = Config;

// we omit instead of include becuase we aren't sure what cookies
// flap might set or want passed back
//
// we know flap doesn't need these
const cleanCookiesForFlap = (cookies = {}) =>
  omit(cookies, [
    '_csrf',
    'activation_adjust_initial_deep_link',
    'activation_from',
    'activation_landing_url',
    'activation_section_id',
    'markedAsReadAt',
    'pendingInvite',
    'pending-video-playback-events',
    'recatpcha_score',
    'redirect_after_auth',
    'webu_session.sig',
    'webu_session',
  ]);

export const flapServerFactory = (options = {}) => {
  const defaults = {
    baseURL: Config.FLAP_API_GW_URL,
    defaultParams: ApiHelpers.FLAP_DEFAULT_PARAMS,
    useAuthTokens: true,
  };

  const serverConfig = Object.assign({}, defaults, options);

  /**
   * Axios instance to make Flap requests from web-u server to Flap
   */
  const flap = flaxiosFactory({
    baseURL: serverConfig.baseURL,
    timeout: 13000, // allow haproxy and flap to decide
  });

  // Default query param serializer
  flap.defaults.paramsSerializer = (params) =>
    qs.stringify(params, { arrayFormat: 'repeat' });

  /**
   * Request interceptor for Flap requests made from the server
   * @param {Object} config - axios config
   * @returns {Object} config - modified axios config
   */
  flap.interceptors.request.use((config) => {
    if (config.url === '/health') {
      return config;
    }

    // Set start time of request
    config.metadata = { startTime: new Date() };

    // Add default params
    config.params = Object.assign(
      {},
      serverConfig.defaultParams,
      config.params,
    );

    const cookies = cleanCookiesForFlap(
      parseClientCookie(config.headers.Cookie),
    );

    config.headers = Object.assign({}, serverConfig.headers, config.headers, {
      Cookie: getCookieHeaderValue(cookies),
    });

    // include bearer when instructed too and no other auth is
    // provided
    if (serverConfig.useAuthTokens) {
      if (!config.params.tuuid) {
        const cookies = parseClientCookie(config.headers.Cookie);
        const oauth2Token = cookies.oauth2_token;

        if (oauth2Token) {
          config.headers.Authorization = `Bearer ${oauth2Token}`;
        }
      }
    } else {
      config.params.id = config.params.id || 0;
      config.params.udid = config.params.udid || 0;
      config.params.tuuid = config.params.tuuid || 0;
    }

    // nosession=true prevents Flap from looking up userids by
    // udid/tuuid pairs in the session table.
    if (
      isIntegerMatch(config.params.id, 0) &&
      config.url !== '/flipboard/login'
    ) {
      config.params.nosession = true;
    }

    if (config.method === 'post' || config.method === 'put') {
      // Most Flap POST and PUT endpoints require form-urlencoded request bodies.
      // Occasionally, only JSON bodies are accepted. When that's the case, use param json=true to
      // force application/json format
      if (config.params.json) {
        config.headers['Content-Type'] = 'application/json';
      } else {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        config.data =
          typeof config.data !== 'object'
            ? config.data
            : qs.stringify(config.data, { arrayFormat: 'repeat' });
      }
    } else {
      // GET and DELETE requests should not have a body, even if they are empty.
      // Having a body seems to cause issues with the API Gateway + Cloudfront
      delete config.data;
    }
    return config;
  });

  /**
   * Response interceptor for Flap requests
   */
  flap.interceptors.response.use(
    (response) => ApiHelpers.interceptResponseHandler(response, FLAP_NAME),
    (error) => ApiHelpers.interceptResponseErrorHandler(error, FLAP_NAME),
  );

  return flap;
};

export const flapServerWithFlapCookiesFactory = (req) => () => {
  const instance = flapServerFactory({
    baseURL: req.isAuthenticated ? Config.FLAP_API_GW_URL : Config.FLAP_URL,
    useAuthTokens: req.isAuthenticated,
  });
  instance.interceptors.request.use((config) => {
    if (req.flapCookieValue) {
      const cookies = parseClientCookie(config.headers.Cookie);
      cookies.flap = req.flapCookieValue;
      config.headers.Cookie = getCookieHeaderValue(cookies);
    }
    return config;
  });
  instance.interceptors.response.use((response) => {
    if (req.flapCookieValue) {
      return response;
    }
    if (response.headers['set-cookie']) {
      const cookies = parseSetCookieHeader(response.headers['set-cookie']);
      req.flapCookieValue = cookies.flap ? cookies.flap.value : undefined;
    }
    return response;
  });
  return instance;
};

export default flapServerFactory();
