import stripTags from 'striptags';

// We are using striptags here because it is a small library with no
// dependencies which does not rely on regular expressions because it
// does not try to do anything fancy like allow certain attributes and
// block others.  Note that the second arg to striptags should NOT be
// used if you want it to be safe.

export default (arg) => {
  if (typeof arg !== 'string') {
    return '';
  }
  // if there are p or br tags, put in a placeholder
  let output = arg.replace(/<(p|br)/g, '__NEWLINE__<$1');
  // strip all html tags
  output = stripTags(output);
  // replace extra whitespace
  output = output.replace(/\s+/g, ' ');
  // replace newline placeholders with a newline
  output = output.replace(/(?:__NEWLINE__)+/g, '\n');

  return output;
};
