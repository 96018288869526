export const getPixelNumberValue = (pixelString: string): number => {
  const number = parseFloat(pixelString);
  if (isNaN(number)) {
    return 0;
  }
  return number;
};

export const styleOnlyProps = (...styleOnlyPropNames: Array<string>) => ({
  shouldForwardProp: (prop: string) => !styleOnlyPropNames.includes(prop),
});
