import React from 'react';
import styled from '@emotion/styled';
import ModalWrapper from 'Webapp/shared/app/modals/modal-wrapper.js';
import Button, { StyleVariations } from 'Webapp/shared/app/components/button';
import ResendVerificationEmailButton from 'Webapp/shared/app/components/resend-verification-email-button';

import {
  MODAL_THEMES,
  MODAL_WINDOW_SIZES,
} from 'ComponentLibrary/modals/modal';
import ModalControls from 'Webapp/shared/app/components/modal-controls';

import { SPACING } from 'Style/spacing';
import { UI_TEXT_TYPES } from 'Style/typography';

import withT from 'ComponentLibrary/hocs/withT';
import connector from 'Utils/connector';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';

const Title = styled.h1(
  { margin: SPACING.LARGE, marginTop: SPACING.BASE6X },
  UI_TEXT_TYPES.TITLE,
);

const MessageDescription = styled.p(UI_TEXT_TYPES.BODY, {
  marginLeft: SPACING.LARGE,
  marginBottom: SPACING.LARGE,
});

const RequireVerifiedEmail: React.FC<
  { t: Flipboard.TFunction; magazineInvite: boolean } & ConnectModalProps
> = ({ dismissModal, t, magazineInvite }) => (
  <ModalWrapper
    name="required-verified-email"
    modalTheme={MODAL_THEMES.WINDOW}
    size={MODAL_WINDOW_SIZES.SMALL}
  >
    <ModalControls
      showCloseButton
      closeButtonSize={16}
      onDismiss={dismissModal}
      modalName="required-verified-email"
    />

    <Title>
      {magazineInvite ? t('verify_your_email') : t('verify_your_email_message')}
    </Title>
    {magazineInvite && (
      <MessageDescription>{t('verify_your_email_message')}</MessageDescription>
    )}

    <ModalControls>
      <Button
        name="required-verified-email-cancel"
        label={t('cancel')}
        styleVariation={StyleVariations.SECONDARY}
        onClick={dismissModal}
      />
      <ResendVerificationEmailButton
        styleVariation={StyleVariations.PRIMARY}
        name="required-verified-email-resend"
        onResendEmail={dismissModal}
      />
    </ModalControls>
  </ModalWrapper>
);

export default connector(connectModal)(withT(RequireVerifiedEmail));
