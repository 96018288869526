import React from 'react';
import ModalWrapper from 'Webapp/shared/app/modals/modal-wrapper';
import ModalControls from 'Webapp/shared/app/components/modal-controls';
import { MODAL_THEMES } from 'ComponentLibrary/modals/modal';
import Button, { StyleVariations } from 'Webapp/shared/app/components/button';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectResponsive, {
  ConnectResponsiveProps,
} from 'Webapp/shared/app/connectors/connectResponsive';

interface PublisherConfirmEmailPassedProps {
  onComplete: () => void;
}
type PublisherConfirmEmailProps = PublisherConfirmEmailPassedProps &
  ConnectResponsiveProps & {
    t: Flipboard.TFunction;
  };

const PublisherConfirmEmail: React.FC<PublisherConfirmEmailProps> = ({
  t,
  isPhone,
  onComplete,
}) => {
  const modalTheme = isPhone ? MODAL_THEMES.FULLSCREEN : MODAL_THEMES.WINDOW;

  return (
    <ModalWrapper name="publisher-confirm-email" modalTheme={modalTheme}>
      <section className="modal__content">
        <h1 className="invite-contributors__title ui-text--title">
          {t('confirm_email')}
        </h1>
        <div className="field-block">
          <p className="ui-text--body">{t('publisher_confirm_email')}</p>
        </div>
      </section>
      <ModalControls>
        <Button
          name="accept-contributor-invite-close"
          styleVariation={StyleVariations.PRIMARY}
          onClick={onComplete}
        >
          {t('close')}
        </Button>
      </ModalControls>
    </ModalWrapper>
  );
};

export default connector(connectResponsive)(withT(PublisherConfirmEmail));
