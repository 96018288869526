import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { DIVIDER_COLORS } from 'Style/colors';
import { BODY_TYPES } from 'Style/typography';
import { truncate } from 'Style/truncate';

import sentenceCase from 'Utils/content/sentence-case';
import Attribution from 'Utils/content/attribution';
import { getAuthorUrl } from 'Utils/content/flipboard-urls';

import withT from 'ComponentLibrary/hocs/withT';

import AuthorAvatar from 'Webapp/shared/app/components/attribution/author-avatar';
import WebLink from 'Webapp/shared/app/components/web-link';

const StyledAuthorAttributionContainer = styled(WebLink, {
  shouldForwardProp: (prop) => prop !== 'avatarSize',
})(({ avatarSize }) => ({
  ...BODY_TYPES.SMALL_STANDARD,
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: `${avatarSize}px auto`,
  gap: `${SPACING.BASE}`,
  marginBottom: `${SPACING.LARGE}`,
  '.author-avatar__image': {
    border: `1px solid ${DIVIDER_COLORS.primary}`,
    'amp-img': {
      borderRadius: '50%',
    },
  },
}));
const UnclickableWrapper =
  StyledAuthorAttributionContainer.withComponent('div');

const CuratorText = styled.span({ display: 'flex', ...truncate('100%', 2) });

function CuratorAttribution({ className, avatarSize, item, isClickable, t }) {
  const author = item.sectionItemAuthor || item.author;
  const authorURL = getAuthorUrl(author);
  const authorDisplayName = (
    <div>
      <CuratorText>{sentenceCase(t('curated_by'))}</CuratorText>
      <CuratorText>{Attribution.getAuthorName(author)}</CuratorText>
    </div>
  );

  const curatorAttributionContents = (
    <>
      <AuthorAvatar avatarSize={avatarSize} author={author} />
      {authorDisplayName}
    </>
  );
  if (isClickable) {
    return (
      <StyledAuthorAttributionContainer
        avatarSize={avatarSize}
        className={className}
        href={authorURL}
      >
        {curatorAttributionContents}
      </StyledAuthorAttributionContainer>
    );
  }
  return (
    <UnclickableWrapper avatarSize={avatarSize} className={className}>
      {curatorAttributionContents}
    </UnclickableWrapper>
  );
}

CuratorAttribution.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
  avatarSize: PropTypes.number,
  isClickable: PropTypes.bool,
  t: PropTypes.func,
};

CuratorAttribution.defaultProps = { avatarSize: 32, isClickable: true };

export default withT(CuratorAttribution);
