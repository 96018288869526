// Live FLAB experiments
// NOTE: Remember to include in live experiments below
// Example:
/*
  NEW_TOPIC_LAYOUT: {
    id: 695,
    groups: {
      control_1: 1,
      control_2: 2,
      experiment: 3,
    },
    loggedOutOnly: true,
  },
*/
// Multi Group Example
/*
  FLIP_WIZARD_REWORK: {
    id: 842,
    groups: {
      control_1: 1,
      control_2: 2,
      [`${AB_TESTS_PRIMARY_TREATMENT_GROUP}_1`]: 3,
      [`${AB_TESTS_PRIMARY_TREATMENT_GROUP}_2`]: 4,
    },
 */
export const AB_TESTS_PRIMARY_TREATMENT_GROUP = 'experiment';
export const AB_TESTS = {
  POST_ONBOARDING_FAVORITES: {
    id: 733,
    groups: {
      control_1: 1,
      control_2: 2,
      [AB_TESTS_PRIMARY_TREATMENT_GROUP]: 3,
    },
    loggedOutOnly: false,
  },
  UNVERIFIED_MESSAGING: {
    id: 779,
    groups: {
      control_1: 1,
      control_2: 2,
      [AB_TESTS_PRIMARY_TREATMENT_GROUP]: 3,
    },
    loggedOutOnly: false,
  },
  TOPIC_PICKER_CTA: {
    id: 834,
    groups: {
      control_1: 1,
      control_2: 2,
      [AB_TESTS_PRIMARY_TREATMENT_GROUP]: 3,
    },
    loggedOutOnly: false,
  },
  PRIVACY_CHANGE_NOTICE: {
    id: 839,
    groups: {
      // Using flab to allow us to turn on/off the notice easily. Only 1 "group" is needed here.
      [AB_TESTS_PRIMARY_TREATMENT_GROUP]: 1,
    },
    loggedOutOnly: false,
  },
  FLIP_WIZARD_REWORK: {
    id: 855,
    groups: {
      control_1: 1,
      control_2: 2,
      [AB_TESTS_PRIMARY_TREATMENT_GROUP]: 3,
    },
    loggedOutOnly: false,
  },
};
