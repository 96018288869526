import SectionUtil from 'Utils/content/section-util';
import getNormalizedHashtag from 'Utils/seo/shared/get-normalized-hashtag';
import getCurrentSection from 'Utils/seo/shared/get-current-section';

export default ({ section }, getState, { t }) => {
  if (section) {
    const currentSection = getCurrentSection(getState());
    if (currentSection && currentSection.isTopic) {
      const {
        sections: { topicDescriptions },
        app: { lang },
      } = getState();
      if (lang === 'en') {
        const customDescription = SectionUtil.customizedTopicText(
          `/topic/${currentSection.topicTag}`,
          'metaDescription',
          topicDescriptions,
        );
        if (customDescription) {
          return customDescription;
        }
      }
      if (currentSection.description) {
        return currentSection.description;
      }
      return t('topic_seo_description_generic', {
        topicName: getNormalizedHashtag(currentSection.title),
      });
    }
  }
};
