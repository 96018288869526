import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

// Utils
import HocUtil from 'Utils/hoc-util';

// Actions
import { purgeSection } from 'Webapp/shared/app/redux/actions/section-actions';
import { removeItemFromMagazine } from 'Webapp/shared/app/redux/actions/magazine-actions';
import { removeContributor } from 'Webapp/shared/app/redux/actions/curator-pro/magazine-actions';

// Components
import ConfirmModal from '../modals/confirm-dialog';

// HOC
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectModal from 'Webapp/shared/app/connectors/connectModal';

function withRemoveFromMagazine(Component) {
  class WrappedComponent extends React.Component {
    constructor(props) {
      super(props);
      this.handleRemove = this.handleRemove.bind(this);
      this.handleConfirmRemove = this.handleConfirmRemove.bind(this);
    }

    handleRemove(removeProps) {
      const { showModal, dismissModal, t } = this.props;
      const { callback } = removeProps;
      const confirmRemoveProps = {
        removeProps,
        name: 'remove',
        title: t('remove_from_magazine'),
        message: t('confirm_remove_from_magazine'),
        confirmLabel: t('remove'),
        onConfirm: () => this.handleConfirmRemove(removeProps),
        dismissModal,
      };
      showModal(ConfirmModal, confirmRemoveProps);
      callback && callback();
    }

    handleConfirmRemove(removeProps) {
      const { item, section } = removeProps;
      this.props.removeItemFromMagazine(item, section);
    }

    handleRemovingUser(removeUserProps) {
      const { magazineTarget, userid } = removeUserProps;
      this.props.removeContributor(magazineTarget, userid);
    }

    render() {
      return (
        <Component
          {...this.props}
          onRemoveItemFromMagazine={this.handleRemove}
          onRemoveUserFromMagazine={this.handleRemovingUser}
        />
      );
    }
  }

  // Proxy the wrapped component displayName
  WrappedComponent.displayName = HocUtil.displayName(Component);
  WrappedComponent.propTypes = {
    showModal: PropTypes.func.isRequired,
    dismissModal: PropTypes.func.isRequired,
    removeItemFromMagazine: PropTypes.func.isRequired,
    removeContributor: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      { removeItemFromMagazine, removeContributor, purgeSection },
      dispatch,
    );

  return connect(
    null,
    mapDispatchToProps,
  )(connector(connectModal)(withT(WrappedComponent)));
}

export default withRemoveFromMagazine;
