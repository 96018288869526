import Config from 'Config';
import { AB_TESTS } from 'Webapp/shared/utils/ab-tests';

// Configuration key/values
export default Object.assign({}, Config, {
  VERSION: '2.0.0',
  HOME_PAGE_DESCRIPTION: `Flipboard curates the world's stories so you can focus \
on investing in yourself, staying informed, and getting involved. With curated \
packages that offer insights and inspiration for any interest, Flipboard will \
make you feel like you’ve spent your time well.`,
  ABOUT_BASE_URL: 'https://about.flipboard.com',
  HELP_CENTER_URL: 'https://flipboard.helpshift.com/hc/en/',
  APP_STORE_URL_FOR_BANNER_AMP: 'https://app.adjust.com/t8vlfi5',
  APP_STORE_URL_FOR_BANNER: 'https://app.adjust.com/hpmhk0i',
  APP_STORE_URL_FOR_HOME_AMP: 'https://app.adjust.com/1cf0nu',
  APP_STORE_URL_FOR_HOME: 'https://app.adjust.com/j6wmd5e',
  APP_STORE_URL_FOR_NAV_AMP: 'https://app.adjust.com/gagbw58',
  APP_STORE_URL_FOR_NAV: 'https://app.adjust.com/f9ozcyo',
  APP_STORE_URL_FOR_NAV_NGL: 'https://app.adjust.com/a31qn2h',
  APP_STORE_URL_FOR_NAV_NGL_PROMOTED: 'https://app.adjust.com/xgw09xb',
  APP_STORE_URL_FOR_SOCIAL_GATE: 'https://app.adjust.com/hqzh2fm',
  APP_STORE_URL_FOR_MOBILE_GATE: 'https://app.adjust.com/lq23xzl',
  APP_STORE_URL_FOR_SIGNUP_CTA: 'https://app.adjust.com/gxsr2bb',
  APP_STORE_URL_FOR_DESKTOP_APPLE:
    'https://app.adjust.com/ek5qk69?redirect=https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Fflipboard-latest-stories%2Fid358801284%23%3Fplatform%3Diphone',
  APP_STORE_URL_FOR_DESKTOP_GOOGLE:
    'https://app.adjust.com/o5mhwuy?redirect=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dflipboard.app%26hl%3Den_US%26gl%3DUS',
  GA_TRACKING_ID: 'UA-39724124-2',
  SHARE_DEFAULT_IMAGE_URL:
    'https://cdn.flipboard.com/itemshare/Logo-250X250.png',
  STATIC_FAVICON_URL: 'https://s.flipboard.com/webapp/images/favicon',
  HOME_SECTION_ORDER: [
    'News',
    'Entertainment',
    'Technology',
    'Travel',
    'Food',
    'Sports',
    'Flipboard TV',
  ],
  DEFAULT_SECTIONS: [
    {
      path: '/@storyboards/us-storyboards-jl2f9vsjz',
      remoteid: 'sid/jl2f9vsjz/storyboards',
      title: 'Storyboards',
      locale: 'en_US',
    },
    {
      remoteid: 'flipboard/topstories/groupid/tv',
      title: 'Flipboard TV',
      locale: 'en_US',
      path: '/section/flipboard%2Ftopstories%2Fgroupid%2Ftv',
    },
    {
      title: 'Technology',
      topicTag: 'technology',
      remoteid: 'flipboard/topic%2Ftechnology',
      path: '/topic/technology',
    },
    {
      title: 'News',
      topicTag: 'news',
      remoteid: 'flipboard/topic%2Fnews',
      path: '/topic/news',
    },
    {
      title: 'Entertainment',
      topicTag: 'entertainment',
      remoteid: 'flipboard/topic%2Fentertainment',
      path: '/topic/entertainment',
    },
    {
      title: 'Design',
      topicTag: 'design',
      remoteid: 'flipboard/topic%2Fdesign',
      path: '/topic/design',
    },
    {
      title: 'Health',
      topicTag: 'health',
      remoteid: 'flipboard/topic%2Fhealth',
      path: '/topic/health',
    },
    {
      title: 'Food',
      topicTag: 'food',
      remoteid: 'flipboard/topic%2Ffood',
      path: '/topic/food',
    },
    {
      title: 'Travel',
      topicTag: 'travel',
      remoteid: 'flipboard/topic%2Ftravel',
      path: '/topic/travel',
    },
    {
      title: 'Sports',
      topicTag: 'sports',
      remoteid: 'flipboard/topic%2Fsports',
      path: '/topic/sports',
    },
  ],
  // Populate with active experiment IDs.  Example: [452, AB_TESTS.POST_ONBOARDING_FAVORITES.id]
  ACTIVE_UID_EXPERIMENTS: [AB_TESTS.FLIP_WIZARD_REWORK.id],
  ACTIVE_UNIQUE_ID_EXPERIMENTS: [
    AB_TESTS.POST_ONBOARDING_FAVORITES.id,
    AB_TESTS.UNVERIFIED_MESSAGING.id,
    AB_TESTS.TOPIC_PICKER_CTA.id,
    AB_TESTS.PRIVACY_CHANGE_NOTICE.id,
  ],
  /**
   * TODO: verify that these two are ALWAYS the same. the cover_stories remote id
   * appears as the ssid.remoteidPlain, and preferrably we'd always use that?
   */
  FOR_YOU_FEED_REMOTE_ID: 'sid/9c5m2uqk25jki1at',
  COVER_STORIES_REMOTE_ID: 'flipboard/coverstories',
  // Activated users automatically follow "Inside Flipboard" magazines
  POST_ACTIVATION_AUTO_FOLLOW_REMOTE_IDS: ['sid/5rjsrb93z/flipboard'],
  MAX_AVATAR_UPLOAD_SIZE: 16 * 1024 * 1000,
  HOME_FEED_ITEM_LIMIT: 12,
  PWA_MANIFEST_URL: `${Config.FLIPBOARD_URL}/manifest.webmanifest`,
});
