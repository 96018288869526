import comment from './comment';
import followYou from './follow-you';
import follow from './follow';
import stats from './stats';
import acceptedInvite from './accepted-invite';
import milestone from './milestone';
import addedPost from './added-post';
import linkItem from './link-item';
import linkSection from './link-section';
import actionUrl from './action-url';
import defaultStrategy from './default-strategy';

export type NotificationContentComponent<Props = unknown> = React.FC<
  {
    notification: Flipboard.Notification;
  } & Props
>;

export type NotificationTextClickStrategy = (
  notification: Flipboard.Notification,
) => NotificationContentComponent | null;

const strategies: Array<NotificationTextClickStrategy> = [
  comment,
  acceptedInvite,
  followYou,
  follow,
  stats,
  milestone,
  addedPost,
  /* Generic Handlers Follow*/
  linkItem,
  linkSection,
  actionUrl,
  defaultStrategy,
];

export default strategies;
