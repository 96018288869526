import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Pill from 'Webapp/shared/app/components/buttons/pill';
import { PillSize } from 'Webapp/enums';

function SectionPill({ section, isSelected, onClick, size }) {
  const handleClick = onClick ? () => onClick(section, isSelected) : undefined;
  const prefixClassName = classnames('pill__section-prefix', {
    'pill__section-prefix--selected': isSelected,
  });

  return (
    <Pill isSelected={isSelected} onClick={handleClick} size={size}>
      {section.isProfile && <span className={prefixClassName}>Profile: </span>}
      {section.isMagazine && (
        <span className={prefixClassName}>Magazine: </span>
      )}
      <span>{section.title}</span>
    </Pill>
  );
}

SectionPill.propTypes = {
  section: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(Object.values(PillSize)),
};

SectionPill.defaultProps = {
  isSelected: false,
  size: PillSize.SMALL,
};

export default SectionPill;
