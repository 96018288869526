import sentry from 'Utils/sentry';
const CAPTURE_DOMAIN_URL = /\/\/(?:www\.)?([\w-.]+)/;

const codingURIComponent =
  (fn) =>
  (string, sentryCaptureException = sentry.captureException) => {
    try {
      return fn(string);
    } catch (e) {
      sentryCaptureException(e, {
        string,
      });
      throw new Error(`Could not ${fn.name}`);
    }
  };

export const euc = codingURIComponent(encodeURIComponent);

export const duc = codingURIComponent(decodeURIComponent);

/**
 * encoding compatible with RFC 3986 (which reserves !, ', (, ), and *)
 * See https://stackoverflow.com/questions/18251399/why-doesnt-encodeuricomponent-encode-single-quotes-apostrophes/18251730
 * @param  {String} str - String to be encoded
 * @return {String} - RFC3986 compatible encoded string
 */
export const rfc3986EncodeURIComponent = (str) =>
  euc(str).replace(/[!'()*]/g, escape);
/**
 * Returns whether the given url is absolute or not.
 * @param  {String} url - Any url
 * @return {Boolean}    - True if the given url is absolute, false otherwise.
 */

/**
 * Wraps window.URL in try/catch so it won't error when passed an invalid source
 * https://developer.mozilla.org/en-US/docs/Web/API/URL/URL
 *
 * @param {String|Object} url   A USVString or any other object with a stringifier
 * @param {String}        base  Optional. A USVString to use as a base wher url is relative
 * @return {Object|null}
 */
export const safeURL = (source, base) => {
  try {
    return new URL(source, base);
  } catch (e) {
    return null;
  }
};

export const isAbsoluteUrl = (string) => {
  const url = safeURL(string);
  return !!url?.protocol?.match(/^https?:$/);
};

/**
 * Returns the domain for a given URL
 * @param {String} url - Any url
 * @return {String} Domain of given URL
 */
export const getDomain = (url) => {
  if (!url) {
    return null;
  }
  const matches = url.match(CAPTURE_DOMAIN_URL);
  if (!matches || matches.length === 0) {
    return null;
  }
  return matches[1];
};

/**
 * Returns the pathname and search for a given pathname+search
 * @param {String} href, pathname+search
 * @return {Object} Object containing pathname and search
 */
export const getPathAndSearch = (arg) => {
  let href = arg;
  if (!href) {
    return {
      pathname: null,
      search: null,
    };
  }
  if (isAbsoluteUrl(href)) {
    href = href.replace(/https?:\/\/[^/]+/, '');
  }
  const chunks = href.split('?');
  let search;
  if (chunks[1]) {
    search = `?${chunks.slice(1, chunks.length).join('?')}`;
  }
  return {
    pathname: chunks[0],
    search,
  };
};

/**
 * Attempts to detect the mime type from the given URL using its extension
 *
 * @param {String} url url from which the function will detect mime type
 * @returns {String} the mime type or null
 */
export const detectMimeType = (url) => {
  const split = url.split('.');
  switch (split[split.length - 1]) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'gif':
      return 'image/gif';
    default:
      return null;
  }
};

/**
 * Strips query params from URL
 * @param {String} url
 * @return {String}
 */
export const stripParams = (url) => url && url.split && url.split('?')[0];
