import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { putState } from 'Webapp/shared/app/redux/actions/profile-actions';
import { userStateDataSelector } from 'Webapp/shared/app/redux/selectors/profile';
import { MUTING_TYPES } from 'Webapp/action-types';

import {
  toastShowInfoAction,
  toastShowErrorAction,
} from 'Webapp/shared/app/redux/actions/toast-actions.js';
import isIntegerMatch from 'Utils/is-integer-match';

const getFlapUserStateMutedAuthor = ({
  authorDisplayName,
  userid,
  service,
  username,
}: Flipboard.MutableAuthor): Flipboard.FlapUserStateMutedAuthor => ({
  authorDisplayName,
  authorID: userid.toString(),
  authorUsername: username,
  serviceName: service,
});

export const muteSourceDomain =
  (domain: string): Flipboard.Thunk =>
  async (dispatch, getState, { t }) => {
    try {
      await dispatch(
        putState(() => {
          const currentUserState = userStateDataSelector(getState());
          const update = { ...currentUserState };
          update.mutedSourceDomains = uniq([
            ...(update.mutedSourceDomains || []),
            domain,
          ]);
          return update;
        }),
      );
      dispatch({
        type: MUTING_TYPES.MUTE_SOURCE_DOMAIN_SUCCEEDED,
        payload: {
          domain,
        },
      });
      dispatch(
        toastShowInfoAction(
          t('mute_toast_message', {
            label: domain,
          }),
        ),
      );
    } catch (e) {
      dispatch(toastShowErrorAction(t('mute_failure')));
    }
  };

export const unmuteSourceDomain =
  (domain: string): Flipboard.Thunk =>
  async (dispatch, getState, { t }) => {
    try {
      await dispatch(
        putState(() => {
          const currentUserState = userStateDataSelector(getState());
          const update = { ...currentUserState };
          update.mutedSourceDomains = (
            currentUserState?.mutedSourceDomains || []
          ).filter((d) => d !== domain);
          return update;
        }),
      );
      dispatch({
        type: MUTING_TYPES.UNMUTE_SOURCE_DOMAIN_SUCCEEDED,
        payload: {
          domain,
        },
      });
      dispatch(
        toastShowInfoAction(
          t('unmute_toast_message', {
            label: domain,
          }),
        ),
      );
    } catch (e) {
      dispatch(toastShowErrorAction(t('mute_failure')));
    }
  };

export const muteAuthor =
  (author: Flipboard.MutableAuthor): Flipboard.Thunk =>
  async (dispatch, getState, { t }) => {
    try {
      await dispatch(
        putState(() => {
          const flapUserStateMutedAuthor = getFlapUserStateMutedAuthor(author);
          const currentUserState = userStateDataSelector(getState());
          const update = { ...currentUserState };
          update.mutedAuthors = uniqBy(
            [...(update.mutedAuthors || []), flapUserStateMutedAuthor],
            'authorID',
          );
          return update;
        }),
      );
      dispatch({
        type: MUTING_TYPES.MUTE_AUTHOR_SUCCEEDED,
        payload: {
          author,
        },
      });
      dispatch(
        toastShowInfoAction(
          t('mute_toast_message', {
            label: author.authorDisplayName,
          }),
        ),
      );
    } catch (e) {
      dispatch(toastShowErrorAction(t('mute_failure')));
    }
  };

export const unmuteAuthor =
  (author: Flipboard.MutableAuthor): Flipboard.Thunk =>
  async (dispatch, getState, { t }) => {
    try {
      await dispatch(
        putState(() => {
          const flapUserStateMutedAuthor = getFlapUserStateMutedAuthor(author);
          const currentUserState = userStateDataSelector(getState());
          const update = { ...currentUserState };
          update.mutedAuthors = (currentUserState?.mutedAuthors || []).filter(
            (a) =>
              !isIntegerMatch(a.authorID, flapUserStateMutedAuthor.authorID),
          );
          return update;
        }),
      );
      dispatch({
        type: MUTING_TYPES.UNMUTE_AUTHOR_SUCCEEDED,
        payload: {
          author,
        },
      });
      dispatch(
        toastShowInfoAction(
          t('unmute_toast_message', {
            label: author.authorDisplayName,
          }),
        ),
      );
    } catch (e) {
      dispatch(toastShowErrorAction(t('mute_failure')));
    }
  };
