import { CAMPAIGN_TYPES } from 'Webapp/action-types';
import { setPayloadReducer } from 'Utils/redux';

import StoryboardSkeletonUtil from 'Utils/content/storyboard-skeleton-util';

import { toastShowErrorAction } from 'Webapp/shared/app/redux/actions/toast-actions';

const initialState = {
  entries: [],
  nextPageKey: null,
  isLoading: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CAMPAIGN_TYPES.GET_CAMPAIGNS_SUCCESS: {
      const { campaigns, nextPageKey, append } = action;
      const projections = campaigns.map((item) =>
        StoryboardSkeletonUtil.curatorProProjection(item, null, true),
      );
      const updatedEntries = append
        ? state.entries.concat(projections)
        : projections;

      return Object.assign({}, state, {
        entries: updatedEntries,
        nextPageKey,
        isLoading: false,
      });
    }
    default:
      return setPayloadReducer(CAMPAIGN_TYPES, initialState)(state, action);
  }
};

export const fetchCampaigns =
  (nextPageKey) =>
  async (dispatch, _, { content, t }) => {
    dispatch({ type: CAMPAIGN_TYPES.GET_CAMPAIGNS });
    const params = { limit: 30 };
    if (nextPageKey) {
      params.next = nextPageKey;
    }
    try {
      const response = await content.get(`/packages/campaigns`, { params });
      dispatch({
        type: CAMPAIGN_TYPES.GET_CAMPAIGNS_SUCCESS,
        campaigns: response.data.items || [],
        nextPageKey: response.data.next || null,
        append: !!nextPageKey,
      });
    } catch (error) {
      dispatch({
        type: CAMPAIGN_TYPES.GET_CAMPAIGNS_FAILED,
      });
      dispatch(toastShowErrorAction(t('fallback_error_message')));
    }
  };
