import express from 'express';

const crawlerUAs = [
  'aolbuild',
  'baidu',
  'bingbot',
  'duckduckgo',
  'adsbot-google',
  'googlebot',
  'mediapartners-google',
  'google-structured-data-testing-tool',
  'teoma',
  'slurp',
  'yandex',
  'ahrefsbot',
  'deepcrawl',
  'flipboardproxy',
  'netnewswire',
  'newsify',
  'feedfetcher',
  'protopage',
  'feedbin',
  'dlvr.it',
  'twitterbot',
  'feedr',
  'microsoft profiling',
  'applenewsbot',
  'easybib',
  'pingbot',
  'feed::find',
  'node-fetch',
  'ccbot',
  'vable',
  'newsbot',
  'hypefactors',
  'postmanruntime',
  'newsblur',
  'awariosmartbot',
  'automattic',
  'acebook',
  'guzzlehttp',
  'accompanybot',
  'youbot',
  'fastfeed',
  'jamroom',
  'serendeputy',
  'searchenginebot',
  'feedparser',
  'slackbot',
  'facebookexternalhit',
  'zapier',
  'universalfeedparser',
  'feedlybot',
  'unirest-java',
  'omgili',
  'magpie-crawler',
  'feedburner',
  'googleproducer',
  'wordpress',
  'feedly',
  'linkedinbot',
  'superfeedr',
  'social news desk',
  'whatsapp',
  'mediatoolkitbot',
  'freshrss',
  'ilearn.sfsu',
  'mastodon',
  'eyemonit',
  'netvibes',
  'tiny tiny',
  'sffeedreader',
  'grammarly',
  'sogou web spider',
];

const matcher = new RegExp(crawlerUAs.join('|'), 'i');

const isCrawler = (req: express.Request) => {
  if (req.isAuthenticated) {
    return false;
  }
  if (req.isRSS) {
    return true;
  }
  const UA = req.get('user-agent');

  if (UA && !!UA.match(matcher)) {
    return true;
  }
  return false;
};

export default isCrawler;
