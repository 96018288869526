import React from 'react';
import {
  itemStructuredData,
  breadcrumbStructuredData,
} from 'Utils/content/structured-data';
import StructuredData from './';

const ItemStructuredData = (props) => (
  <React.Fragment>
    <StructuredData getData={breadcrumbStructuredData} {...props} />
    <StructuredData getData={itemStructuredData} {...props} />
  </React.Fragment>
);

export default ItemStructuredData;
