import { set, type, setPayloadReducer } from 'Webapp/utils/redux';
import { ONBOARDING_TYPES } from 'Webapp/action-types';
import { USAGE_APP_ENTER_TYPES } from 'Utils/analytics/usage';
import { FrictionlessFlowType, OnboardingFlow } from 'Webapp/enums';
import { profileNeedsMoreFollowsForOnboarding } from 'Webapp/shared/app/redux/selectors/profile';
import { isAuthenticated } from 'Webapp/shared/app/redux/selectors/auth';

// reducer
type OnboardingState = {
  data: Flipboard.OnboardingData | null;
  success: boolean;
  pending: boolean;
  flow: OnboardingFlow | null;
  currentStep: number | null;
};
const initialState: OnboardingState = {
  data: null,
  success: false,
  pending: false,
  flow: null,
  currentStep: null,
};

export const reducer = setPayloadReducer<
  typeof ONBOARDING_TYPES,
  OnboardingState
>(ONBOARDING_TYPES, initialState, ONBOARDING_TYPES.RESET_ONBOARDING);

// actions
export const startOnboardingFlow =
  (flow: OnboardingFlow, data?: OnboardingState['data']): Flipboard.Thunk =>
  async (dispatch) => {
    // await to allow component update instead of batching with
    // setting new onboarding
    await dispatch(resetOnboarding());
    if (data) {
      dispatch(setOnboardingData(data));
    }
    dispatch(set(ONBOARDING_TYPES.SET_ONBOARDING_FLOW, 'flow', flow));
  };

// allows an onboarding flow to be "started" without losing previous
// onboarding data like success.  This is useful for things like
// the /signup landing page where you land in what is essentially an
// in-progress onboarding flow
export const continueOnboardingFlow =
  (onboardingFlow: OnboardingFlow): Flipboard.Thunk =>
  (dispatch, getState) => {
    const { success, pending, data } = getState().onboarding;
    // start new flow which will reset everything
    dispatch(startOnboardingFlow(onboardingFlow));
    dispatch(setOnboardingSuccess(success));
    dispatch(setOnboardingPending(pending));
    dispatch(setOnboardingData(data));
  };

export const setCurrentOnboardingStep = (number: number) =>
  set(ONBOARDING_TYPES.SET_CURRENT_ONBOARDING_STEP, 'currentStep', number);

export const setOnboardingData = (value: OnboardingState['data']) =>
  set(ONBOARDING_TYPES.SET_ONBOARDING_DATA, 'data', value);

export const setOnboardingSuccess = (value: OnboardingState['success']) =>
  set(ONBOARDING_TYPES.SET_ONBOARDING_SUCCESS, 'success', value);

export const setOnboardingPending = (value: OnboardingState['pending']) =>
  set(ONBOARDING_TYPES.SET_ONBOARDING_PENDING, 'pending', value);

export const resetOnboarding = () => type(ONBOARDING_TYPES.RESET_ONBOARDING);

export const startAcceptMagazineFlow =
  (section: Flipboard.Section): Flipboard.Thunk =>
  (dispatch, getState) => {
    const authenticated = isAuthenticated(getState());

    if (!section.invite) {
      return;
    }

    if (!authenticated) {
      dispatch(
        startOnboardingFlow(
          OnboardingFlow.ACCEPT_MAGAZINE_INVITE_UNAUTHENTICATED_NEW,
          {
            magazineInviteSection: section,
          },
        ),
      );
    } else {
      dispatch(
        startOnboardingFlow(
          OnboardingFlow.ACCEPT_MAGAZINE_INVITE_AUTHENTICATED_NEW,
          {
            magazineInviteSection: section,
          },
        ),
      );
    }
  };
// TODO: Not sure if this is the best place for this
export const startOldAcceptMagazineFlow =
  (section): Flipboard.Thunk =>
  (dispatch, getState) => {
    const authenticated = isAuthenticated(getState());
    const onboardingData = onboardingDataSelector(getState());

    if (!section.invite) {
      return;
    }

    if (!authenticated) {
      dispatch(
        startOnboardingFlow(
          OnboardingFlow.ACCEPT_MAGAZINE_INVITE_UNAUTHENTICATED_OLD,
          {
            magazineInviteSection: section,
          },
        ),
      );
    } else {
      if (onboardingData?.acceptMagazineInviteConsented) {
        dispatch(
          continueOnboardingFlow(
            OnboardingFlow.ACCEPT_MAGAZINE_INVITE_AUTHENTICATED_OLD,
          ),
        );
      } else {
        dispatch(
          startOnboardingFlow(
            OnboardingFlow.ACCEPT_MAGAZINE_INVITE_AUTHENTICATED_OLD,
            {
              magazineInviteSection: section,
            },
          ),
        );
      }
    }
  };

// selectors
export const getCurrentOnboardingFlow = ({
  onboarding: { flow },
}: Flipboard.State) => flow;

export const getCurrentOnboardingStep = ({
  onboarding: { currentStep },
}: Flipboard.State) => currentStep;

export const onboardingDataSelector = ({
  onboarding: { data },
}: Flipboard.State) => data;

export const onboardingSuccessSelector = ({
  onboarding: { success },
}: Flipboard.State) => success;

export const onboardingPendingSelector = ({
  onboarding: { pending },
}: Flipboard.State) => pending;

export const isFromConfirmationSelector = ({
  app: { isFromConfirmation },
}: Flipboard.State) => isFromConfirmation;

export const frictionlessUserHasInsufficientFollowsSelector = (
  state: Flipboard.State,
) => {
  const { app, profile } = state;
  const authenticated = isAuthenticated(state);
  const isFromConfirmation = app.isFromConfirmation;
  const userInfo = profile?.userInfo as unknown as null | {
    frictionless: boolean;
  };
  const profileNeedsMoreFollows = profileNeedsMoreFollowsForOnboarding(state);
  return (
    authenticated &&
    isFromConfirmation &&
    userInfo?.frictionless &&
    profileNeedsMoreFollows
  );
};

// Utility

const frictionlessFlowEnterTypeMap = {
  [FrictionlessFlowType.HOMEPAGE]:
    USAGE_APP_ENTER_TYPES.FRICTIONLESS_HOMEPAGE_SIGNUP,
  [FrictionlessFlowType.NEWSLETTER]:
    USAGE_APP_ENTER_TYPES.FRICTIONLESS_NEWSLETTER_SIGNUP,
  [FrictionlessFlowType.NEWSLETTERS]:
    USAGE_APP_ENTER_TYPES.FRICTIONLESS_NEWSLETTERS_SIGNUP,
  [FrictionlessFlowType.TOPIC]: USAGE_APP_ENTER_TYPES.FRICTIONLESS_TOPIC_SIGNUP,
  [FrictionlessFlowType.INVITE]:
    USAGE_APP_ENTER_TYPES.FRICTIONLESS_INVITE_SIGNUP,
  [FrictionlessFlowType.MAIN_SIGNUP]:
    USAGE_APP_ENTER_TYPES.FRICTIONLESS_MAIN_SIGNUP,
  [FrictionlessFlowType.ACTION_GATE]:
    USAGE_APP_ENTER_TYPES.FRICTIONLESS_ACTION_GATE_SIGNUP,
};

export const getFrictionlessFlowUsageType = (
  frictionlessFlowType: FrictionlessFlowType,
  socialGateActionType = null,
) => {
  if (!frictionlessFlowType) {
    return null;
  }
  if (frictionlessFlowType === FrictionlessFlowType.ACTION_GATE) {
    return (
      USAGE_APP_ENTER_TYPES[
        `FRICTIONLESS_ACTION_GATE_${socialGateActionType}_SIGNUP`
      ] || USAGE_APP_ENTER_TYPES.FRICTIONLESS_ACTION_GATE_SIGNUP
    );
  }
  return frictionlessFlowEnterTypeMap[frictionlessFlowType] || null;
};
