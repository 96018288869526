import { Connector } from 'Webapp/utils/connector';
import {
  searchTopics,
  search,
} from 'Webapp/shared/app/redux/actions/search-actions';

const selectors = {};

const actions = { searchTopics, search };

export type ConnectSearchProps = Flipboard.ConnectorProps<
  typeof selectors,
  typeof actions
>;
const connectSearch: Connector = {
  selectors,
  actions,
};
export default connectSearch;
