import React from 'react';
import PropTypes from 'prop-types';

// Utils
import classNames from 'classnames';
import { AppTheme } from 'Webapp/enums';
import { MODAL_OVERlAY_MODIFIERS } from './modal';

const OVERLAY_ID = 'modal-overlay';

function ModalOverlay({ children, overlayModifier, appTheme, onMouseDown }) {
  const overlayClasses = classNames({
    'modal-overlay': true,
    'modal-overlay--light': overlayModifier === MODAL_OVERlAY_MODIFIERS.LIGHT,
    'modal-overlay--dark-theme': appTheme === AppTheme.DARK,
    'modal-overlay--dark-theme--light':
      appTheme === AppTheme.DARK &&
      overlayModifier === MODAL_OVERlAY_MODIFIERS.LIGHT,
  });

  return (
    <div
      data-name={OVERLAY_ID}
      className={overlayClasses}
      onMouseDown={onMouseDown}
    >
      {children}
    </div>
  );
}

ModalOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  onMouseDown: PropTypes.func.isRequired,
  overlayModifier: PropTypes.string,
  appTheme: PropTypes.string.isRequired,
};

ModalOverlay.defaultProps = {
  overlayModifier: null,
};

export default ModalOverlay;
