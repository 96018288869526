import { BLOCKING_TYPES } from 'Webapp/action-types';
import { LoadState } from 'Webapp/enums';
import { setPayloadReducer } from 'Webapp/utils/redux';

interface BlockingReducerState {
  blockedProfiles: Array<Flipboard.BlockableProfile>;
  loaded: LoadState;
}

const initialState: BlockingReducerState = {
  blockedProfiles: [],
  loaded: LoadState.NONE,
};

const blockingReducer = setPayloadReducer(BLOCKING_TYPES, initialState);
export default blockingReducer;
