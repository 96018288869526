// Utils
import getWindowUtil from 'Utils/get-window';

export const getOrigin = (getWindow = getWindowUtil) => {
  const w = getWindow();
  if (w && w.location && w.location.origin) {
    return w.location.origin;
  }
};

export default getOrigin;
