/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { COLORS } from 'Style/colors';
import { buttonActiveStateShadow } from 'Style/definitions';
import { FONTS, FONT_WEIGHTS } from 'Style/typography';
import { SPACING } from 'Style/spacing';

// Utils
import { topicAnchorText } from 'Utils/seo/topic-anchor-text';

// Components
import SectionLink from 'Webapp/shared/app/components/section-link';
import withT from 'ComponentLibrary/hocs/withT';

export const STYLE_VARIATIONS = {
  TEXT: 'text',
  PILL: 'pill',
  COLOR_ONLY: 'color-only',
  CAPITALIZE_COLOR: 'capitalize-color',
  INHERIT_UNDERLINE: 'inherit-underline',
};

/* EMOTION STYLES */
const sectionTagStyle = {
  display: 'inline-block',
  padding: `${SPACING.SMALL} ${SPACING.BASE}`,
  fontFamily: FONTS.FONT_FAKT_FLIPBOARD_CONDENSED,
  fontWeight: FONT_WEIGHTS.SEMIBOLD,
  fontSize: '12px',
  textTransform: 'uppercase',
  textDecoration: 'none',
  border: 'none',
};

const sectionTagInheritUnderlineStyle = {
  padding: 'unset',
  fontFamily: 'inherit',
  fontWeight: 'inherit',
  fontSize: 'inherit',
  textTransform: 'capitalize',
  textDecoration: 'underline',
  '&:before': { textDecoration: 'inherit' },
  color: 'inherit',
  a: {
    color: 'inherit',
  },
};
const sectionTagTextStyle = {
  color: COLORS.red,
  '&:hover, &:focus': {
    color: COLORS.red,
    backgroundColor: 'transparent',
    filter: 'brightness(0.9)',
    // color: COLORS.red, // DARKEN 10%;
  },
};
const sectionTagColorOnlyStyle = {
  fontFamily: 'unset',
  fontWeight: 'unset',
  fontSize: 'unset',
  textTransform: 'unset',
  padding: '0',
  color: COLORS.red,
  a: {
    color: COLORS.red,
  },
  '&:hover, &:focus': {
    backgroundColor: 'transparent',
    filter: 'brightness(0.9)',
    // color: COLORS.red, // DARKEN 10%;
  },
};

const sectionTagPillStyle = {
  background: COLORS.red,
  color: 'var(--color--text-overlay)',
  fontSize: '14px',
  padding: SPACING.BASE,
  '&:hover, &:focus': {
    filter: 'brightness(0.9)',
    // background: COLORS.red, // DARKEN 10%
    color: 'var(--color--text-overlay)',
  },
  '&:active': {
    ...buttonActiveStateShadow,
    color: 'rgba(var(--color--text-overlay), 0.8)',
  },
  '&::before': {
    marginRight: SPACING.SMALL,
    color: '#fff', // darken(#fff, 20%)',
    filter: 'brightness(0.8)',
  },
};

/* Style Objects */
const applyProps = (props) => {
  const styles = {};
  if (props.section?.isTopic) {
    styles['&::before'] = {
      content: '"#"',
      display: 'inline-block',
    };
  }
  let additionalStyles = {};
  if (props.styleVariation === STYLE_VARIATIONS.INHERIT_UNDERLINE) {
    additionalStyles = sectionTagInheritUnderlineStyle;
  }

  if (props.styleVariation === STYLE_VARIATIONS.TEXT) {
    additionalStyles = sectionTagTextStyle;
  }

  if (props.styleVariation === STYLE_VARIATIONS.COLOR_ONLY) {
    additionalStyles = sectionTagColorOnlyStyle;
  }

  if (props.styleVariation === STYLE_VARIATIONS.CAPITALIZE_COLOR) {
    additionalStyles = {
      ...sectionTagColorOnlyStyle,
      textTransform: 'capitalize',
    };
  }

  if (props.styleVariation === STYLE_VARIATIONS.PILL) {
    additionalStyles = sectionTagPillStyle;
  }
  return [styles, additionalStyles];
};

/**
 * shouldForwardProp will be evaluated against each prop and only pass it to
 * the component if true
 */

const ClickableTag = styled(SectionLink, {
  shouldForwardProp: (prop) => prop !== 'styleVariation',
})(sectionTagStyle, applyProps);

const UnclickableTag = styled('div', {
  shouldForwardProp: (prop) => prop !== 'styleVariation' && prop !== 'section',
})(sectionTagStyle, applyProps);

function SectionTag({ className, section, styleVariation, t, isClickable }) {
  // Formerly this was a TopicTag class. Styles are now being used/shared
  // for non topics, but hanging on to these class names for backwards
  // compatability. TODO: Refactor them out.
  const classes = classNames(className, {
    'topic-tag': true,
    'topic-tag--text': styleVariation === STYLE_VARIATIONS.TEXT,
  });

  if (!isClickable) {
    return (
      <UnclickableTag
        section={section}
        styleVariation={styleVariation}
        className={classes}
      >
        {topicAnchorText(section, t)}
      </UnclickableTag>
    );
  }

  return (
    <ClickableTag
      styleVariation={styleVariation}
      className={classes}
      data-test-id="topic-tag"
      section={section}
    >
      {topicAnchorText(section, t)}
    </ClickableTag>
  );
}

SectionTag.propTypes = {
  className: PropTypes.string,
  section: PropTypes.object.isRequired,
  styleVariation: PropTypes.oneOf(Object.values(STYLE_VARIATIONS)),
  t: PropTypes.func.isRequired,
  isClickable: PropTypes.bool,
};

SectionTag.defaultProps = {
  styleVariation: STYLE_VARIATIONS.TEXT,
  isClickable: true,
};

export default withT(SectionTag);
