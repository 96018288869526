import modalSignup from 'Webapp/shared/app/components/onboarding-flows/steps/modal-signup';
import modalCompleteProfile from 'Webapp/shared/app/components/onboarding-flows/steps/modal-complete-profile';

import acceptMagazineInviteConsent from 'Webapp/shared/app/components/onboarding-flows/steps/accept-magazine-invite-consent';
import acceptMagazineInvite from 'Webapp/shared/app/components/onboarding-flows/steps/accept-magazine-invite';
import onboardingTopicPicker from 'Webapp/shared/app/components/onboarding-flows/steps/onboarding-topic-picker';
import followMagazine from 'Webapp/shared/app/components/onboarding-flows/steps/follow-magazine';
import modalYoureIn from 'Webapp/shared/app/components/onboarding-flows/steps/modal-youre-in';
import trackAcceptContributorInvite from 'Webapp/shared/app/components/onboarding-flows/steps/track-accept-contributor-invite';
import performPostActivationActions from 'Webapp/shared/app/components/onboarding-flows/steps/perform-post-activation-actions';
import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

const steps: Array<OnboardingStep> = [
  acceptMagazineInviteConsent,
  modalSignup,
  acceptMagazineInvite,
  trackAcceptContributorInvite,
  onboardingTopicPicker,
  modalCompleteProfile,
  followMagazine,
  performPostActivationActions,
  modalYoureIn,
];

export default steps;
