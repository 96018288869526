import axios from 'axios';
import logger from 'Utils/logger';
import SearchResultsUtil from 'Utils/content/search-results-util';

import { SEARCH_TYPES } from 'Webapp/action-types';

export const FLAP_SEARCH_DEFAULT_PARAMS = {
  // no one knows what this is but it is required and `medium2` the
  // only value for `categories`
  categories: 'medium2',
  nostream: true,
  usessid: true,
};

interface SearchSectionsParams {
  q: string;
  see_more?: string;
}

export const searchSections =
  (searchParams: SearchSectionsParams, isTopic = false): Flipboard.Thunk =>
  async (_dispatch, _getState, { flap }) => {
    try {
      const params = {
        ...FLAP_SEARCH_DEFAULT_PARAMS,
        ...searchParams,
      };
      const { data } = await flap.get<Flipboard.FlapSearchResults>(
        '/social/sectionSearch',
        { params },
      );

      if (data?.stream) {
        if (isTopic) {
          return SearchResultsUtil.topicsProjection(data.stream);
        }
        return SearchResultsUtil.projection(data.stream);
      }
      return [];
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        logger.error('failed to search for sections');
        const data = error.response?.data;

        logger.error(
          `Search Failure: ${JSON.stringify(error)}, data: ${
            data && JSON.stringify(data)
          }`,
        );
      }
    }
  };

/**
 * Search for sections based on a query string
 * @param  {String} query - Search term
 * @return {Function} - A function that takes the dispatch parameter
 */
export const search =
  (query: string): Flipboard.Thunk =>
  async (dispatch) => {
    dispatch({ type: SEARCH_TYPES.SEARCH });
    const results = await dispatch(searchSections({ q: query }));

    dispatch({
      type: SEARCH_TYPES.SEARCH_SUCCEEDED,
      payload: results,
    });
  };

export const searchTopics = (query: string) =>
  searchSections({ q: query, see_more: 'vertical' }, true);
