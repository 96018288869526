import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import loadable from '@loadable/component';

// Utils
import getWindow from 'Utils/get-window';
import GlobalVars from 'Utils/global-vars';
import sentry from 'Utils/sentry';
import loadIMA from 'Utils/load-ima';
// import { getAdForVideo } from 'Utils/api/ads';

import ErrorBoundary from 'ComponentLibrary/errors/error-boundary';

// hocs
import withThanksSubscription from '../../hocs/withThanksSubscription';

import connector from 'Utils/connector';
import connectFirstPartyVideo from 'Webapp/shared/app/connectors/connectFirstPartyVideo';
import connectSendUsageEventWithSession from 'Webapp/shared/app/connectors/connectSendUsageEventWithSession';
import withIsIos from '../../hocs/withIsIos';
import withT from 'ComponentLibrary/hocs/withT';
import VideoTracker from 'Webapp/shared/utils/video-tracker';

let Videojs = () => null;
let VideojsContribAds = () => null;
let VideojsIMA = () => null;
let CanAutoplay = () => null;

if (!GlobalVars.isServer()) {
  Videojs = loadable.lib(() => import('video.js'));
  VideojsContribAds = loadable.lib(() => import('videojs-contrib-ads'));
  VideojsIMA = loadable.lib(() => import('videojs-ima'));
  CanAutoplay = loadable.lib(() => import('can-autoplay'));
}

// BEGIN BIG HACK
//
// TODO remove this hack
//
// After switching to webpack 5 we are seeing the refs for
// loadable.lib only working one time.  The second time the components
// created by loadable.lib were mounted, the ref callback was not ever
// being called.

// The idea behind this hack is that it works the first time, so we
// store it and access it that way in the future rather than getting
// it from the component ref each time

// FWIW we also tried updating the @loadable/* libraries and that did
// not help, seems like the issue hasn't been addressed.

// See:
// our switch to webpack 5
// https://github.com/Flipboard/web-universal/pull/3356/

// possible related bug / issue with loadable
// https://github.com/gregberge/loadable-components/issues/829

// object to store dynamic libs in these libs will be accessed by
// loadableLibs[key] in component logic
//
// :(
const loadableLibs = {};
const memoizeLoadableLib = (key) => (ref) => {
  // if we have dynamic lib at key arleady do nothing
  if (loadableLibs[key]) {
    return;
  }
  // if the ref looks like an import, store at key
  if (ref?.default) {
    loadableLibs[key] = ref.default;
  }
};
// END BIG HACK

class HTMLVideo extends Component {
  constructor(props) {
    super(props);
    this.videoElementRef = React.createRef();

    this.loadVideoJSTimer = null;
    this.requestedSignedMediaCookie = false;

    this.refreshSignedMediaCookie = this.refreshSignedMediaCookie.bind(this);
    this.setupVideo = this.setupVideo.bind(this);
    this.shouldShowAd = this.shouldShowAd.bind(this);
    this.onError = this.onError.bind(this);
    this.getSource = this.getSource.bind(this);
    this.sourceRequiresCredentials = this.sourceRequiresCredentials.bind(this);
    this.state = {
      adsResponse: null,
      setupComplete: false,
      adsBlocked: false,
    };
  }

  async componentDidMount() {
    loadIMA();
    if (this.sourceRequiresCredentials()) {
      await this.refreshSignedMediaCookie();
    }
    this.setupVideo();
    // getAdForVideo().then(adsResponse => this.setState({ adsResponse }));
  }

  componentDidUpdate(prevProps) {
    if (!this.player) {
      return;
    }
    const { play } = this.props;
    if (!this.player.seeking() && prevProps.play !== play) {
      this.player[play ? 'play' : 'pause']();
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
    getWindow().clearTimeout(this.loadVideoJSTimer);
    getWindow().clearTimeout(this.setupCompleteTimer);
  }

  async refreshSignedMediaCookie() {
    await this.props.refreshSignedMediaCookie();
    // If the above doesn't actually work, and the src in question
    // requires signed cookies, loading the media will fail.  If the
    // src does not require it, the video should still work
    this.requestedSignedMediaCookie = true;
  }

  shouldShowAd() {
    const {
      prerollAds,
      validThanksSubscription,
      shownVideoAd,
      adShowPercentage,
      videoAdsEnabled,
    } = this.props;
    if (!videoAdsEnabled || !prerollAds || validThanksSubscription) {
      return false;
    }
    if (!shownVideoAd) {
      return true;
    }
    const randomPercentage = Math.random() * 100;
    return randomPercentage < adShowPercentage;
  }

  getSource() {
    const { source } = this.props;
    let src = source;

    // TODO here to debug / block VE problem, remove later
    if (src.indexOf('s3.amazonaws.com/ve1') > -1) {
      src = 'https://cdn.flipboard.com/dev_O/unavailable.mp4';
    }
    return src || '';
  }

  sourceRequiresCredentials() {
    return this.getSource().indexOf('ms.flipboard.com') > -1;
  }

  setupVideo() {
    const { isIos, sendUsageEventWithSession } = this.props;
    // this is async loaded in footer-assets
    const googleIMAloaded = getWindow().google && getWindow().google.ima;
    // Don't wait on canAutoplay when rendering in iOS
    const autoplayNotReady = !isIos && !loadableLibs.canAutoplay;

    const credentialsNotReady = this.sourceRequiresCredentials()
      ? !this.requestedSignedMediaCookie
      : false;

    if (
      !googleIMAloaded ||
      !loadableLibs.videojs ||
      !loadableLibs.videojsContribAds ||
      !loadableLibs.videojsIMA ||
      credentialsNotReady ||
      autoplayNotReady
    ) {
      this.loadVideoJSTimer = getWindow().setTimeout(() => {
        this.setupVideo();
      }, 100);
      return;
    }

    loadableLibs.videojs.registerPlugin('ads', loadableLibs.videojsContribAds);

    const {
      poster,
      videoJSOptions,
      track,
      videoTrackerOptions,
      videoEvents,
      videoAdTagUrl,
      shownVideoAd,
      mimeType,
      t,
    } = this.props;

    const showAd = this.shouldShowAd();

    const src = this.getSource();

    this.player = loadableLibs.videojs(
      this.videoElementRef.current,
      {
        // black poster until we have decided what we are doing
        poster:
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQAAAAA3bvkkAAAACklEQVQI12NgAAAAAgAB4iG8MwAAAABJRU5ErkJggg==',
        fill: true,
        controls: true,
        sources: [
          {
            src,
            type: mimeType,
            withCredentials: this.sourceRequiresCredentials(),
          },
        ],
        ...videoJSOptions,
      },
      () => {
        const video = this.videoElementRef.current;
        if (video) {
          if (track) {
            new VideoTracker(
              video,
              videoTrackerOptions,
              sendUsageEventWithSession,
            );
          }
        }

        const showVideoIos = () => {
          this.player.poster(poster);
          // HACK to remove spinner in iOS
          this.setupCompleteTimer = getWindow().setTimeout(() => {
            this.player.removeClass('vjs-waiting');
            this.setState({
              setupComplete: true,
            });
          }, 100);
        };
        const showVideoNonIos = () => {
          loadableLibs.canAutoplay.video({ muted: false }).then((result) => {
            this.setupCompleteTimer = getWindow().setTimeout(
              () =>
                this.setState({
                  setupComplete: true,
                }),
              100,
            );
            if (result.result === false || !this.props.autoPlay) {
              // if we can't autoplay show the poster
              this.player.poster(poster);
              return;
            }
            this.player.play();
          });
        };
        const showVideo = isIos ? showVideoIos : showVideoNonIos;

        if (showAd) {
          // wait until the ad is ready to play
          this.player.on('adsready', () => {
            showVideo();
            // set shownVideoAd if we think we actually have
            if (!shownVideoAd) {
              this.props.setShownVideoAd(true);
            }
          });
          // we didn't get an ad, go ahead and play
          this.player.on('adserror', showVideo);
        } else {
          showVideo();
        }
      },
    );

    this.player.on('ended', () => {
      this.player.poster(poster);
      this.player.hasStarted(false);
    });

    this.player.on('error', () => {
      if (GlobalVars.isDevelopment) {
        getWindow().alert(
          'Unable to play video.  This is development, is this a signed media thing? Do you have signed media cookies?  Visit this same page in production to get those.',
        );
      }
    });

    Object.keys(videoEvents).forEach((key) =>
      this.player.on(key, videoEvents[key]),
    );

    if (showAd) {
      this.player.ima({
        adLabel: t('advertisement'),
        adTagUrl: videoAdTagUrl,
      });
    }
  }

  onError(error, errorInfo) {
    if (error.message.match(/Loading chunk.*failed/)) {
      this.setState({ adsBlocked: true });
    } else {
      sentry.captureError(error, errorInfo);
    }
  }

  render() {
    const { isIos, t } = this.props;
    const className = classNames('video-player', this.props.className, {
      'video-player--waiting': !this.state.setupComplete,
    });
    return (
      <div className={className}>
        {this.state.adsBlocked ? (
          <div className="video-player__disable-ad-blocker">
            <span>{t('disable_ad_blocker_to_see_content')}</span>
          </div>
        ) : (
          <div className="video-player--hide" data-vjs-player>
            <React.Fragment>
              <ErrorBoundary onError={this.onError}>
                <Videojs ref={memoizeLoadableLib('videojs')} />
                <VideojsContribAds
                  ref={memoizeLoadableLib('videojsContribAds')}
                />
                <VideojsIMA ref={memoizeLoadableLib('videojsIMA')} />
                <CanAutoplay ref={memoizeLoadableLib('canAutoplay')} />
              </ErrorBoundary>
              <video
                controls
                playsInline={isIos}
                preload="auto"
                className="video-js vjs-waiting vjs-big-play-centered"
                crossOrigin={
                  this.sourceRequiresCredentials()
                    ? 'use-credentials'
                    : undefined
                }
                ref={this.videoElementRef}
              />
            </React.Fragment>
          </div>
        )}
      </div>
    );
  }
}

HTMLVideo.defaultProps = {
  videoEvents: {},
  videoJSOptions: {},
  videoTrackerOptions: {},
  track: false,
  play: false,
  autoPlay: false,
  prerollAds: false,
  validThanksSubscription: false,
};

HTMLVideo.propTypes = {
  source: PropTypes.string.isRequired,
  videoEvents: PropTypes.object,
  videoJSOptions: PropTypes.object,
  videoTrackerOptions: PropTypes.object,
  track: PropTypes.bool,
  poster: PropTypes.string,
  className: PropTypes.string,
  prerollAds: PropTypes.bool,
  autoPlay: PropTypes.bool,
  play: PropTypes.bool,
  validThanksSubscription: PropTypes.bool,
  adShowPercentage: PropTypes.number.isRequired,
  videoAdTagUrl: PropTypes.string.isRequired,
  shownVideoAd: PropTypes.bool.isRequired,
  setShownVideoAd: PropTypes.func.isRequired,
  refreshSignedMediaCookie: PropTypes.func.isRequired,
  isIos: PropTypes.bool.isRequired,
  sendUsageEventWithSession: PropTypes.func.isRequired,
  videoAdsEnabled: PropTypes.bool.isRequired,
  mimeType: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default connector(
  connectFirstPartyVideo,
  connectSendUsageEventWithSession,
)(withT(withIsIos(withThanksSubscription(HTMLVideo))));
