import getCurrentSection from 'Utils/seo/shared/get-current-section';
import SectionUtil from 'Utils/content/section-util';

export default ({ section }, getState, { t }) => {
  if (section) {
    const currentSection = getCurrentSection(getState());
    if (currentSection && currentSection.isTopic) {
      const {
        sections: { topicDescriptions },
        app: { lang },
      } = getState();
      if (lang === 'en') {
        const customTitle = SectionUtil.customizedTopicText(
          `/topic/${currentSection.topicTag}`,
          'title',
          topicDescriptions,
        );
        if (customTitle) {
          return customTitle;
        }
      }
      return t('topic_seo_title', {
        topicTitle: currentSection.title,
      });
    }
  }
};
