import { TOAST_TYPES } from 'Webapp/action-types';
import { TOAST_STYLES } from 'Webapp/enums';

interface ToastReducerState {
  props: {
    message: null | string;
  };
  style: null | TOAST_STYLES;
}
const initialState: ToastReducerState = {
  props: {
    message: null,
  },
  style: null,
};

export default function toastReducer(state = initialState, action) {
  switch (action.type) {
    case TOAST_TYPES.SHOW_INFO: {
      const { props } = action;
      return Object.assign({}, state, { style: TOAST_STYLES.INFO, props });
    }
    case TOAST_TYPES.SHOW_ERROR: {
      const { props } = action;
      return Object.assign({}, state, { style: TOAST_STYLES.ERROR, props });
    }
    case TOAST_TYPES.DISMISS: {
      return Object.assign({}, initialState);
    }
    default:
      return state;
  }
}
