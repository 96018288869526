import React from 'react';

import { isSectionLinkProfileMention } from 'Webapp/predicates';
import linkifyText from 'Webapp/utils/linkify-text.js';
import { getUserUrlFromUsername } from 'Utils/content/flipboard-urls.js';
import WebLink from 'Webapp/shared/app/components/web-link.js';
import AtMention from 'Webapp/shared/app/components/at-mention';
import { smallestUrl } from 'Webapp/utils/image-util.js';
import sentry from 'Webapp/utils/sentry';

import connector from 'Utils/connector';
import connectFeatureFlags, {
  ConnectFeatureFlagsProps,
} from 'Webapp/shared/app/connectors/connectFeatureFlags';

type CommentTextProps = {
  sectionLinks: Array<Flipboard.FlapSectionLink>;
  linkify?: boolean;
  children: string;
} & ConnectFeatureFlagsProps;

const CommentText: React.FC<CommentTextProps> = ({
  sectionLinks,
  children,
  linkify = false,
  featureFlags,
}) => {
  if (!children) {
    return null;
  }
  const fakeNewlines = (n: number) => (
    <>
      {Array.from(Array(n)).map(() => (
        <br key="x" />
      ))}
    </>
  );
  const nodes: Array<React.ReactNode> = [];
  const pushNodes = (...args: Array<React.ReactNode>) =>
    args.forEach((arg) => {
      if (typeof arg === 'string') {
        // insert br tags for newlines
        const breaks = arg.match(/\n+/g);
        const withBreaks = arg.split(/\n+/g).reduce((acc, item, index) => {
          acc.push(item);
          acc.push(fakeNewlines((breaks?.[index] || '').length));
          return acc;
        }, [] as Array<React.ReactNode>);
        withBreaks.forEach((chunk) => {
          // linkify remaining strings as required
          nodes.push(
            linkify && typeof chunk === 'string' ? linkifyText(chunk) : chunk,
          );
        });
      } else {
        nodes.push(arg);
      }
    });
  // sort because the sectionLinks aren't guaranteed to show up in the order
  // of their text's appearance in the comment.
  const profileLinks = sectionLinks
    .filter(isSectionLinkProfileMention)
    .sort((a, b) => a.referringTextLoc[0] - b.referringTextLoc[0]);

  try {
    if (profileLinks.length > 0) {
      let lastIndex = 0;

      for (let i = 0, l = profileLinks.length; i < l; i++) {
        const {
          referringTextLoc: [startIndex, offset],
          username,
          title: name,
          image,
        } = profileLinks[i];
        const endIndex = startIndex + offset;
        const text = children.substring(lastIndex, startIndex);
        const linkHref = getUserUrlFromUsername(username);
        lastIndex = endIndex;

        if (linkHref) {
          pushNodes(
            text,
            <WebLink openInNewWindow key={i} href={linkHref} stopPropagation>
              <AtMention
                name={name}
                avatar={smallestUrl(image)}
                username={username}
              >
                {featureFlags.PILL_REMOVAL ? username : name}
              </AtMention>
            </WebLink>,
          );
        } else {
          pushNodes(
            text,
            <AtMention
              name={name}
              avatar={smallestUrl(image)}
              username={username}
            >
              {featureFlags.PILL_REMOVAL ? username : name}
            </AtMention>,
          );
        }

        if (i === profileLinks.length - 1 && endIndex < children.length) {
          const lastSegment = children.substring(lastIndex);
          pushNodes(lastSegment);
        }
      }
    } else {
      pushNodes(children);
    }
  } catch (e) {
    sentry.captureException(e as Error);
    pushNodes(children);
  }

  return <>{nodes}</>;
};

export default connector<CommentTextProps>(connectFeatureFlags)(CommentText);
