import Config from 'Config';
import {
  setShownVideoAd,
  refreshSignedMediaCookie,
} from '../redux/actions/app';
import { videoAdTagUrl, shownVideoAd } from '../redux/selectors/video';

export default {
  selectors: {
    videoAdTagUrl,
    shownVideoAd,
    adShowPercentage: () => Config.VIDEO_AD_SHOW_PERCENTAGE,
    videoAdsEnabled: ({ app: { videoAdsEnabled } }) => videoAdsEnabled || false,
  },
  actions: { setShownVideoAd, refreshSignedMediaCookie },
};
