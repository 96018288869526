import React from 'react';
import PropTypes from 'prop-types';
import { AppTheme } from 'Webapp/enums';
import FlipboardLandscape from 'ComponentLibrary/logos/flipboard-landscape';

import connector from 'Utils/connector';
import connectTheme from 'Webapp/shared/app/connectors/connectTheme';

function WebFlipboardLandscape(props) {
  return <FlipboardLandscape {...props} />;
}

WebFlipboardLandscape.propTypes = {
  appTheme: PropTypes.oneOf(Object.values(AppTheme)),
};

export default connector(connectTheme)(WebFlipboardLandscape);
