import publisherEnsureVerifiedEmail from 'Webapp/shared/app/components/onboarding-flows/steps/publisher-ensure-verified-email';
import publisherModal from 'Webapp/shared/app/components/onboarding-flows/steps/publisher-modal';
import createMagazineModal from 'Webapp/shared/app/components/onboarding-flows/steps/create-magazine-modal';

import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

const steps: Array<OnboardingStep> = [
  publisherEnsureVerifiedEmail,
  publisherModal,
  createMagazineModal,
];

export default steps;
