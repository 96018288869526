import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

import codeSplitLoad from 'Webapp/shared/utils/code-split-load';

import {
  showModal,
  dismissSpecificModal,
} from 'Webapp/shared/app/redux/actions/modal-actions';
const YoureInModal = codeSplitLoad('YoureInConfirmationModal');

const youreInModal: OnboardingStep = (nextStep) => (dispatch) => {
  dispatch(
    showModal(YoureInModal, {
      onComplete: () => {
        dispatch(dismissSpecificModal(YoureInModal));
        nextStep();
      },
    }),
  );
};

export default youreInModal;
