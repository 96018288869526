import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { COLORS, ICON_COLORS } from 'Style/colors';
import Icon from '../icon';

export const StyledLikeIcon = styled(Icon)({
  color: ICON_COLORS.emphasis,
});

function LikeIcon({ isLiked, size, ...props }) {
  return (
    <StyledLikeIcon
      name="like"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      isLiked={isLiked}
      {...props}
      renderComponent={() => {
        if (isLiked) {
          return (
            <>
              <path
                d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z"
                fill={COLORS.red}
              />
              <path
                d="M4.38521 9.48679C3.93442 5.83739 8.62741 4.59137 10.1617 7.17261C11.5157 4.59141 16.3892 5.83739 15.9384 9.48679C15.6745 11.623 13.321 14.6493 10.1617 15.8065C6.91246 14.6493 4.64908 11.623 4.38521 9.48679Z"
                fill={COLORS.staticWhite}
              />
              <path
                d="M7.87091 9.19684C7.46039 9.78875 6.72352 9.98835 6.22507 9.64265C5.72661 9.29695 5.65533 8.53686 6.06585 7.94494C6.47637 7.35303 7.21324 7.15343 7.71169 7.49914C8.21015 7.84484 8.28143 8.60492 7.87091 9.19684Z"
                fill={COLORS.staticWhite}
              />
            </>
          );
        }
        return (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.9727 3.12982C8.52038 1.63232 6.32904 1.348 4.50782 1.99529C2.29089 2.78321 0.510002 4.96682 0.88251 8.11869C1.09588 9.92403 2.09418 12.0052 3.60513 13.841C5.12489 15.6874 7.21869 17.3542 9.70978 18.2814C9.89978 18.3521 10.109 18.3511 10.2984 18.2786C12.7214 17.3511 14.8116 15.6862 16.3445 13.8445C17.8677 12.0145 18.9044 9.93183 19.1186 8.11869C19.4935 4.947 17.6251 2.77403 15.392 1.99383C13.571 1.35763 11.3595 1.61962 9.9727 3.12982ZM5.06597 3.56572C3.52649 4.11287 2.2632 5.60084 2.53766 7.92307C2.703 9.32209 3.51823 11.1128 4.89197 12.7818C6.1947 14.3646 7.95028 15.7765 9.99618 16.6048C11.9865 15.7762 13.7439 14.3637 15.0635 12.7783C16.4574 11.1036 17.2991 9.31429 17.4635 7.92307C17.7356 5.62065 16.4304 4.12209 14.8422 3.56723C13.2023 2.99426 11.4753 3.46891 10.7452 4.92341C10.6074 5.19815 10.3294 5.37466 10.0221 5.38265C9.71479 5.39063 9.42805 5.22878 9.27608 4.96157C8.41645 3.45007 6.64871 3.00319 5.06597 3.56572Z"
            fill="currentColor"
          />
        );
      }}
    />
  );
}

LikeIcon.propTypes = {
  size: PropTypes.number,
  isLiked: PropTypes.bool,
};

LikeIcon.defaultProps = {
  size: 20,
  isLiked: false,
};

export default LikeIcon;
