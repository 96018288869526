import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getSmartMagazineSection } from 'Utils/content/profile-util';

// Components
import ModalWrapper from './modal-wrapper';
import { MODAL_THEMES, MODAL_MODIFIERS } from 'ComponentLibrary/modals/modal';
import AddFavoriteConfirmation from 'Webapp/shared/app/modals/add-favorite-confirmation-modal';
import TopicCustomization from 'Webapp/shared/app/containers/topic-customization';

import withHistory from 'Webapp/shared/app/hocs/withHistory';
import withFollow from 'Webapp/shared/app/hocs/withFollow';
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectModal from 'Webapp/shared/app/connectors/connectModal';

const MAX_FAVORITES = 10;
function PersonalizeTopicModal({
  section,
  createSmartMagazine,
  updateSmartMagazine,
  showModal,
  dismissModal,
  isPhone,
  smartMagazines,
  addSmartMagazineToFavorites,
  handleFollow,
  favorites,
}) {
  const topic = getSmartMagazineSection(section, smartMagazines) || section;

  const addToFavorites = () => {
    addSmartMagazineToFavorites(topic);
  };

  const handleCreate = (customizations) => {
    createSmartMagazine(topic, customizations).then(() => {
      handleFollow({ sections: topic });

      dismissModal();

      if (favorites && favorites.length <= MAX_FAVORITES) {
        showModal(AddFavoriteConfirmation, {
          topic,
          onSubmit: addToFavorites,
        });
      }
    });
  };

  const handleEdit = (customizations) => {
    const currentTopicIds = topic.subsections.map((t) => t.remoteid);
    const newTopicIds = customizations.map((c) => c.remoteid);
    const addSections = newTopicIds.filter((t) => !currentTopicIds.includes(t));
    const removeSections = currentTopicIds.filter(
      (t) => !newTopicIds.includes(t),
    );
    updateSmartMagazine(topic, addSections, removeSections);
    dismissModal();
  };

  const modalTheme = isPhone ? MODAL_THEMES.FULLSCREEN : MODAL_THEMES.WINDOW;
  return (
    <ModalWrapper
      name="personalize-modal"
      modalTheme={modalTheme}
      modifier={MODAL_MODIFIERS.HAS_NO_HEADER}
    >
      <React.Fragment>
        <TopicCustomization
          topic={topic.isSmartMagazine ? topic.rootTopic : topic}
          selectedCustomizations={
            topic.isSmartMagazine ? topic.subsections : [topic]
          }
          onSubmit={topic.isSmartMagazine ? handleEdit : handleCreate}
        />
      </React.Fragment>
    </ModalWrapper>
  );
}

PersonalizeTopicModal.propTypes = {
  section: PropTypes.object,
  t: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  dismissModal: PropTypes.func.isRequired,
  isPhone: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  smartMagazines: PropTypes.array,
  createSmartMagazine: PropTypes.func.isRequired,
  updateSmartMagazine: PropTypes.func.isRequired,
  addSmartMagazineToFavorites: PropTypes.func.isRequired,
  handleFollow: PropTypes.func.isRequired,
  favorites: PropTypes.array,
};
PersonalizeTopicModal.defaultProps = { smartMagazines: [] };

export default connector(
  connectResponsive,
  connectModal,
)(withFollow(withHistory(withT(PersonalizeTopicModal))));
