import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const WithHistory = (Component) => {
  // eslint-disable-next-line react/prefer-stateless-function
  class WithHistoryWrapper extends React.Component {
    render() {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { location: _location, match: _match, ...props } = this.props;
      return <Component {...props} />;
    }
  }
  WithHistoryWrapper.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  return withRouter(WithHistoryWrapper);
};

export default WithHistory;
