import React from 'react';
import PropTypes from 'prop-types';
import { AppTheme } from 'Webapp/enums';
import Modal from 'ComponentLibrary/modals/modal';

import connector from 'Utils/connector';
import connectTheme from 'Webapp/shared/app/connectors/connectTheme';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';

function WebModal(props) {
  return <Modal {...props} />;
}

WebModal.propTypes = {
  appTheme: PropTypes.oneOf(Object.values(AppTheme)),
  isLargeScreen: PropTypes.bool.isRequired,
};

export default connector(connectResponsive, connectTheme)(WebModal);
