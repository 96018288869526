import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Pill from 'Webapp/shared/app/components/buttons/pill';
import { PillSize } from 'Webapp/enums';

function TopicPill({ topic, isSelected, onClick, size }) {
  const handleClick = onClick ? () => onClick(topic, isSelected) : undefined;
  const hashTagClassName = classnames('pill__topic-hashtag', {
    'pill__topic-hashtag--selected': isSelected,
  });

  return (
    <Pill isSelected={isSelected} onClick={handleClick} size={size}>
      {(topic.isTopic || topic.feedType === 'topic') && (
        <span className={hashTagClassName}>#</span>
      )}
      <span className="pill__topic-title">{topic.title}</span>
    </Pill>
  );
}

TopicPill.propTypes = {
  topic: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(Object.values(PillSize)),
};

TopicPill.defaultProps = {
  isSelected: false,
  size: PillSize.SMALL,
};

export default TopicPill;
