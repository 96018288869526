import { NotificationContentComponent } from 'Webapp/shared/app/components/notifications/notification-text-click-strategies/index';
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';

import { getProfilePath } from 'Utils/content/flipboard-urls';

import WebLink from 'Webapp/shared/app/components/web-link';
import NotificationThumb from 'Webapp/shared/app/components/notifications/notification-thumb';
import NotificationTime from 'Webapp/shared/app/components/notifications/notification-time';

const AcceptedInvite: NotificationContentComponent = ({
  notification,
  children,
}) => {
  const profileUrl = getProfilePath(notification.author);

  return (
    <>
      <WebLink
        href={profileUrl}
        navFromEventName={USAGE_EVENT_NAMES.SECTION_ENTER}
      >
        {children}
        <NotificationTime notification={notification} />
      </WebLink>
      <NotificationThumb notification={notification} />
    </>
  );
};

export default AcceptedInvite;
