import React, { Component } from 'react';
import PropTypes from 'prop-types';

import connector from 'Utils/connector';

import connectCurrentUser from 'Webapp/shared/app/connectors/connectCurrentUser';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';

import styled from '@emotion/styled';
import { SURFACE_COLORS, TEXT_COLORS, ALERTS } from 'Style/colors';
import { SPACING } from 'Style/spacing';
import { BODY_TYPES } from 'Style/typography';
import UnstyledButton from 'Webapp/shared/app/components/unstyled-button';
import VerifyAccountAnchoredDialog from 'Webapp/shared/app/components/verify-account-anchored-dialog';
import withT from 'ComponentLibrary/hocs/withT';
import connectFeatureFlags from 'Webapp/shared/app/connectors/connectFeatureFlags';

const Wrapper = styled.div({
  backgroundColor: `${SURFACE_COLORS.tertiary}`,
  padding: `${SPACING.BASE} ${SPACING.MEDIUM}`,
  display: 'flex',
  alignItems: 'center',
  columnGap: `${SPACING.BASE}`,
  whiteSpace: 'nowrap',
});

const Prefix = styled.span(BODY_TYPES.SMALL_STANDARD, {
  marginRight: `${SPACING.SMALL}`,
});

const Status = styled.span(BODY_TYPES.SMALL_STANDARD, {
  color: `${TEXT_COLORS.primary}`,
});

const WarningDot = styled.div({
  display: 'inline-block',
  width: '18px',
  height: '18px',
  borderRadius: '18px',
  backgroundColor: `${ALERTS.warning}`,
});

class AccountVerificationStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redisplay: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);

    this.statusElement = React.createRef();
  }

  handleClick(e) {
    e.preventDefault();
    this.setState({ redisplay: true });
  }

  handleDismiss() {
    this.setState({ redisplay: false });
  }

  render() {
    const { currentUserVerified, t, isWideLargeScreen, featureFlags } =
      this.props;
    const { redisplay } = this.state;

    const SmallScreenWrapper = styled.div({
      textAlign: 'center',
      padding: `${SPACING.LARGE}`,
      borderBottom: `1px solid ${SURFACE_COLORS.secondary}`,
    });

    const unverifiedMessageKey = featureFlags.UNVERIFIED_MESSAGING
      ? 'confirm_your_email'
      : 'account_status_value_unverified';

    const content = (
      <React.Fragment>
        <UnstyledButton onClick={this.handleClick} name="account-status">
          <Wrapper ref={this.statusElement}>
            <div>
              <Prefix>{`${t('account_status_label')}:`}</Prefix>
              <Status>
                {currentUserVerified
                  ? t('account_status_value_verified')
                  : t(unverifiedMessageKey)}
              </Status>
            </div>
            {!currentUserVerified && <WarningDot />}
          </Wrapper>
        </UnstyledButton>
        <VerifyAccountAnchoredDialog
          targetRef={this.statusElement}
          dismissedCookieName="dismissed_profile_header_unverified_account_cta"
          copy={t('unverified_edu_profile_header_copy')}
          redisplayCopy={t('unverified_edu_profile_header_redisplayed_copy')}
          redisplay={redisplay}
          onDismiss={this.handleDismiss}
        />
      </React.Fragment>
    );

    return isWideLargeScreen ? (
      content
    ) : (
      <SmallScreenWrapper>{content}</SmallScreenWrapper>
    );
  }
}

AccountVerificationStatus.propTypes = {
  currentUser: PropTypes.object,
  currentUserVerified: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  isWideLargeScreen: PropTypes.bool.isRequired,
  featureFlags: PropTypes.object.isRequired,
};

AccountVerificationStatus.defaultProps = {
  currentUser: null,
};

export default connector(
  connectCurrentUser,
  connectResponsive,
  connectFeatureFlags,
)(withT(AccountVerificationStatus));
