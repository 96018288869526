import { Connector } from 'Webapp/utils/connector';
import {
  purgeSocialActivity,
  queueLoadMissingSocialActivity,
} from 'Webapp/shared/concepts/social-activity';

const selectors = {
  socialActivity: ({ socialActivity }) => socialActivity,
};
const actions = {
  purgeSocialActivity,
  queueLoadMissingSocialActivity,
};

export type ConnectSocialActivityProps = Flipboard.ConnectorProps<
  typeof selectors,
  typeof actions
>;

const connectSocialActivity: Connector = {
  selectors,
  actions,
};

export default connectSocialActivity;
