import React from 'react';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { SURFACE_COLORS, DIVIDER_COLORS } from 'Style/colors';
import { SPACING } from 'Style/spacing';
import { LAYERS } from 'Style/layout';

// Components
import UnstyledButton from 'Webapp/shared/app/components/unstyled-button';
import CloseNewIcon from 'ComponentLibrary/icons/close-new';

interface ModalControlsProps {
  className?: string;
  fixedControls?: boolean;
  verticalControls?: boolean;
  showCloseButton?: boolean;
  closeButtonSize?: number;
  onDismiss?: () => void;
  modalName?: string;
  children?: React.ReactNode;
}

const Controls = styled('footer', {
  shouldForwardProp: (prop) =>
    prop !== 'fixedControls' && prop !== 'verticalControls',
})(
  {
    padding: SPACING.LARGE,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    background: SURFACE_COLORS.primary,
    borderRadius: `0 0 ${SPACING.MEDIUM} ${SPACING.MEDIUM}`,
    '.button--block': {
      marginBottom: '0', // Override block style button stacking margin
    },
  },
  (props: Pick<ModalControlsProps, 'fixedControls' | 'verticalControls'>) => {
    if (props.fixedControls) {
      return { borderTop: `1px solid ${DIVIDER_COLORS.primary} ` };
    }
    return {};
  },
  (props: Pick<ModalControlsProps, 'fixedControls' | 'verticalControls'>) => {
    if (props.verticalControls) {
      return {
        flexDirection: 'column',
        justifyContent: 'center',
        padding: SPACING.BASE6X,
      };
    }
    return { ' > * + *': { marginLeft: SPACING.MEDIUM } };
  },
);

const SingleCloseButton = styled.div({
  position: 'absolute',
  top: SPACING.MEDIUM,
  right: SPACING.MEDIUM,
  // bump base in case modal header is positioned
  zIndex: LAYERS.BASE + 10,
});

const ModalControls: React.FC<ModalControlsProps> = ({
  className,
  fixedControls,
  verticalControls,
  showCloseButton,
  closeButtonSize,
  onDismiss,
  modalName,
  children,
}) => {
  const classes = classNames(className, {
    modal__controls: true,
  });

  return (
    <React.Fragment>
      {showCloseButton && onDismiss && modalName && (
        <SingleCloseButton>
          <UnstyledButton onClick={onDismiss} name={`${modalName}-close`}>
            <CloseNewIcon size={closeButtonSize || 24} />
          </UnstyledButton>
        </SingleCloseButton>
      )}
      {children && (
        <Controls
          className={classes}
          fixedControls={fixedControls}
          verticalControls={verticalControls}
        >
          {children}
        </Controls>
      )}
    </React.Fragment>
  );
};

export default ModalControls;
