import codeSplitLoad from 'Webapp/shared/utils/code-split-load';
import { OnboardingStep } from 'Webapp/shared/app/components/onboarding';

import { showModal } from 'Webapp/shared/app/redux/actions/modal-actions';
const WelcomeModal = codeSplitLoad('WelcomeModal');

const welcomeModal: OnboardingStep = (nextStep) => async (dispatch) => {
  dispatch(showModal(WelcomeModal, { onComplete: nextStep }));
};

export default welcomeModal;
