import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Utils
import HocUtil from 'Utils/hoc-util';

// Components
import ConfirmModal from '../modals/confirm-dialog';
import { flagItem } from 'Webapp/shared/app/redux/actions/item-actions';

// HOC
import withToast from '../hocs/withToast';
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectModal from 'Webapp/shared/app/connectors/connectModal';
import connectComments from 'Webapp/shared/app/connectors/connectComments';

function withReport(Component) {
  class WrappedComponent extends React.Component {
    handleReport = (reportProps) => {
      const { showModal, dismissModal, t } = this.props;
      const { callback } = reportProps;
      const confirmReportProps = {
        name: 'report',
        title: reportProps.comment ? t('are_you_sure') : t('report_content'),
        message: reportProps.comment
          ? t('report_comment')
          : t('report_content_confirm'),
        confirmLabel: t('report'),
        onConfirm: () => this.handleConfirmReport(reportProps),
        dismissModal,
      };
      showModal(ConfirmModal, confirmReportProps);
      callback && callback();
    };

    handleConfirmReport = async (reportProps) => {
      const { section, item, comment, callbackAfterConfirm } = reportProps;
      const {
        toastShowInfoAction,
        toastShowErrorAction,
        dismissModal,
        reportComment,
        flagItem,
        t,
      } = this.props;
      try {
        reportProps.comment
          ? await reportComment(section, item, comment)
          : await flagItem(section, item);
        callbackAfterConfirm && callbackAfterConfirm();
        toastShowInfoAction(t('report_content_success'));
        dismissModal();
      } catch (_) {
        toastShowErrorAction(t('report_content_failure'));
        dismissModal();
      }
    };

    render() {
      return <Component {...this.props} onReport={this.handleReport} />;
    }
  }

  // Proxy the wrapped component displayName
  WrappedComponent.displayName = HocUtil.displayName(Component);
  WrappedComponent.propTypes = {
    showModal: PropTypes.func.isRequired,
    dismissModal: PropTypes.func.isRequired,
    reportComment: PropTypes.func.isRequired,
    toastShowInfoAction: PropTypes.func.isRequired,
    toastShowErrorAction: PropTypes.func.isRequired,
    flagItem: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };
  const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ flagItem }, dispatch);

  return connect(
    null,
    mapDispatchToProps,
  )(
    connector(
      connectModal,
      connectComments,
    )(withT(withToast(WrappedComponent))),
  );
}

export default withReport;
