import { Connector } from 'Webapp/utils/connector';
import { setActiveFeatureFlags } from 'Webapp/shared/concepts/feature-flags';

const selectors = {
  featureFlags: ({ featureFlags }: Flipboard.State) => featureFlags,
};
const actions = {
  setActiveFeatureFlags,
};

export type ConnectFeatureFlagsProps = Flipboard.ConnectorProps<
  typeof selectors,
  typeof actions
>;

const connectFeatureFlags: Connector = {
  selectors,
  actions,
};

export default connectFeatureFlags;
