import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { componentSelector } from 'Style/type-helpers';
import { styleOnlyProps } from 'Style/style-helpers';

import { StyledIcon } from 'ComponentLibrary/icons/icon';
import Button from 'Webapp/shared/app/components/button';

const DefaultItemActionButton = styled(
  Button,
  styleOnlyProps('isTooltipMenu'),
)<{
  isTooltipMenu?: boolean;
}>((props) => {
  if (props.isTooltipMenu) {
    return {};
  }
  return {
    display: 'inline-flex',
    padding: SPACING.MEDIUM,
    gap: SPACING.BASE,

    [componentSelector(StyledIcon)]: { color: 'inherit' },
    '&:focus': {
      backgroundColor: 'unset',
      color: 'unset',
    },
  };
});
export default DefaultItemActionButton;
