import {
  isArticleRoute,
  isSectionRoute,
} from 'Webapp/shared/app/redux/selectors/routing';

export const gaTarget = (state) => {
  if (isArticleRoute(state)) {
    return 'item';
  } else if (isSectionRoute(state)) {
    return 'section';
  }

  return null;
};
