import React from 'react';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { SURFACE_COLORS, TEXT_COLORS } from 'Style/colors';
import { CARD_OVERLAY_TAG } from 'Style/typography';

import PinIcon from 'ComponentLibrary/icons/pin.js';
import { PinIconVariant } from 'Webapp/enums';

import withT from 'ComponentLibrary/hocs/withT';
import connector from 'Utils/connector';
import connectFeatureFlags, {
  ConnectFeatureFlagsProps,
} from 'Webapp/shared/app/connectors/connectFeatureFlags';

export const PinnedTagBadge = styled.div(CARD_OVERLAY_TAG, {
  backgroundColor: SURFACE_COLORS.primary,
  color: TEXT_COLORS.primary,
  width: 'fit-content',
  height: 'fit-content',
  padding: `${SPACING.SMALL} ${SPACING.BASE} ${SPACING.SMALL} ${SPACING.SMALL}`,
  display: 'flex',
  gap: SPACING.SMALL,
});

type PinnedTagProps = {
  className: string;
  item: Flipboard.Item;
  t: Flipboard.TFunction;
} & ConnectFeatureFlagsProps;

const PinnedTag: React.FC<PinnedTagProps> = ({
  className,
  item,
  featureFlags,
  t,
}) => {
  if (!item || !item.pinned || !featureFlags.PINNED_FLIP) {
    return null;
  }
  return (
    <PinnedTagBadge className={className}>
      <PinIcon size={14} variant={PinIconVariant.FILLED} />
      <span>{t('pinned_badge_text')}</span>
    </PinnedTagBadge>
  );
};

export default connector(connectFeatureFlags)(withT(PinnedTag));
