import React from 'react';
import PropTypes from 'prop-types';

// Utils
import getWindow from 'Utils/get-window';

// Components
import WaitFor from 'ComponentLibrary/utility/wait-for';

import connector from 'Utils/connector';
import connectFeatureFlags from 'Webapp/shared/app/connectors/connectFeatureFlags';

const getConsentData = () => {
  let tcData = null;

  try {
    const api = getWindow().__tcfapi;
    if (api) {
      api('getTCData', 2, (data, success) => {
        if (success) {
          tcData = data;
        }
      });
    }
  } catch (e) {
    return null;
  }

  return tcData;
};

function gdprAppliesValue() {
  const consentData = getConsentData();
  return consentData && consentData.gdprApplies;
}

function waitFor() {
  const value = gdprAppliesValue();
  return (
    typeof value !== 'undefined' &&
    value !== null &&
    !!getWindow()._sp_?.gdpr?.loadPrivacyManagerModal
  );
}

function gdprApplies() {
  const gdprApplies = gdprAppliesValue();
  return gdprApplies && gdprApplies !== 'false';
}

class GdprApplies extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gdprApplies: false,
    };
  }

  render() {
    const { FORCE_GDPR_APPLIES } = this.props.featureFlags;
    return (
      <React.Fragment>
        <WaitFor
          predicate={waitFor}
          onWaitComplete={() =>
            this.setState({
              gdprApplies: FORCE_GDPR_APPLIES === true ? true : gdprApplies(),
            })
          }
        />
        {typeof this.props.children === 'function'
          ? this.props.children(this.state.gdprApplies)
          : null}
        {typeof this.props.children !== 'function' && this.state.gdprApplies
          ? this.props.children
          : null}
      </React.Fragment>
    );
  }
}

GdprApplies.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  featureFlags: PropTypes.shape({
    FORCE_GDPR_APPLIES: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connector(connectFeatureFlags)(GdprApplies);
