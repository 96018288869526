/* eslint no-console: 0 */
import { GuardedLog } from 'Utils/logger';
import GlobalVars from 'Utils/global-vars';
const guardedLog: GuardedLog =
  (logMethod) =>
  (...messages) => {
    if (GlobalVars.environment === 'test') {
      return;
    }
    if (!GlobalVars.isProduction || GlobalVars.isServer()) {
      logMethod(...messages);
    }
  };

export const log = guardedLog(console.log);
export const info = guardedLog(console.info);
export const warn = guardedLog(console.warn);
export const error = guardedLog(console.error);
export const debug = guardedLog(console.debug);

export default {
  log,
  info,
  warn,
  error,
  debug,
};
