import React from 'react';
import { ItemMetricDetailType } from 'Webapp/enums';
import UnstyledButton from 'Webapp/shared/app/components/unstyled-button';
import ItemMetricDetail, {
  ItemMetricDetailPassedProps,
} from 'Webapp/shared/app/modals/item-metric-detail';

import connector from 'Webapp/utils/connector';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';

type ItemMetricDetailButtonProps = {
  className: string;
  item: Flipboard.Item;
  section: Flipboard.Section;
  itemMetricDetailType: ItemMetricDetailType;
  children: React.ReactNode;
} & ConnectModalProps;

const ItemMetricDetailButton: React.FC<ItemMetricDetailButtonProps> = ({
  className,
  item,
  section,
  children,
  itemMetricDetailType,
  showModal,
}) => (
  <UnstyledButton
    name="item-metric-detail"
    className={className}
    onClick={(e) => {
      e.preventDefault();
      showModal<ItemMetricDetailPassedProps>(ItemMetricDetail, {
        item,
        section,
        itemMetricDetailType,
      });
    }}
  >
    {children}
  </UnstyledButton>
);

export default connector<ItemMetricDetailButtonProps>(connectModal)(
  ItemMetricDetailButton,
);
