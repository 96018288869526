import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from '@emotion/styled';

import { GA } from 'Utils/analytics';
import { AppTheme } from 'Webapp/enums';
import { KEYS } from 'Utils/window-key-down';
import getWindow from 'Utils/get-window';
import { getDocumentScrollPosition } from 'Utils/window-scroll';
import scrollTo from 'Utils/scroll-to';
import ModalOverlay from './modal-overlay';
import { SPACING } from 'Style/spacing';

const OVERLAY_ID = 'modal-overlay';

const ModalContents = styled.section({
  boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.6)',
  borderRadius: SPACING.MEDIUM,
  userSelect: 'text',
});
class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      /**
       * We Must keep track of the scroll position for the content below the modal.
       * The reason for this is because the body gets a "position" fixed, which makes
       * us lose the scroll position. This is done to prevent the body from scrolling
       * when the modal is open, on mobile devices. This is a widely known issue.
       * See more at: https://medium.com/jsdownunder/locking-body-scroll-for-all-devices-22def9615177
       */
      currentScrollPosition: getDocumentScrollPosition(),
    };

    this.handleKeyUpEvent = this.handleKeyUpEvent.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleDismissModal = this.handleDismissModal.bind(this);
    this.keyUpListener = {
      handleEvent: this.handleKeyUpEvent,
    };
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    getWindow().document.body.classList.add('prevent-overflow');
    getWindow().document.addEventListener('keyup', this.keyUpListener);
    GA.trackViewModal(this.props.name);
    this.modalRef && this.modalRef.current.focus();
  }

  componentWillUnmount() {
    getWindow().document.body.classList.remove('prevent-overflow');
    const { currentScrollPosition } = this.state;
    scrollTo(0, currentScrollPosition);
    getWindow().document.removeEventListener('keyup', this.keyUpListener);
  }

  handleKeyUpEvent(event) {
    if (this.props.disableDismiss) {
      return;
    }
    if (event.keyCode === KEYS.ESCAPE) {
      this.handleDismissModal();
    }
  }

  // We use mouse down instead of click, because if there are inputs inside of the modal,
  // and users try to highlight the contents of the input, and let go of the mouse outside of the modal,
  // it triggers the modal to close undesirably.
  handleMouseDown(e) {
    const isOverlay = e.target.getAttribute('data-name') === OVERLAY_ID;
    if (!isOverlay || this.props.disableDismiss) {
      e.stopPropagation();

      return;
    }
    this.handleDismissModal();
  }

  contentDistanceToWindowTop() {
    const distance = getWindow()
      .document.getElementById('content')
      .getBoundingClientRect().y;
    return distance > 0 ? distance : 0;
  }

  handleDismissModal() {
    const { interceptDismiss, dismissModal, onDismiss } = this.props;
    // Delegate dismiss action to downstream components
    if (interceptDismiss) {
      return interceptDismiss();
    }

    if (onDismiss) {
      onDismiss();
    }

    dismissModal();
  }

  render() {
    const {
      position,
      modalTheme,
      modifier,
      overlayModifier,
      size,
      appTheme,
      isLargeScreen,
      className,
    } = this.props;
    // Style position is used for the dropdown modal, in order to align perfectly with search
    // - 8 accounts for padding offset
    const style =
      (position && {
        left: position.left - 8,
        width: 'auto',
      }) ||
      {};

    if (
      !isLargeScreen &&
      ![MODAL_THEMES.BOTTOM_SHEET, MODAL_THEMES.FULLSCREEN].includes(modalTheme)
    ) {
      style.top = this.contentDistanceToWindowTop();
    }

    const modalClasses = classNames(className, {
      modal: true,
      'modal--fullscreen': modalTheme === MODAL_THEMES.FULLSCREEN,
      'modal--has-fixed-header': modifier === MODAL_MODIFIERS.HAS_FIXED_HEADER,
      'modal--has-nav-and-fixed-header-and-controls':
        modifier === MODAL_MODIFIERS.HAS_NAV_AND_FIXED_HEADER_AND_CONTROLS,
      'modal--has-fixed-header-and-controls':
        modifier === MODAL_MODIFIERS.HAS_FIXED_HEADER_AND_CONTROLS,
      'modal--has-no-header': modifier === MODAL_MODIFIERS.HAS_NO_HEADER,
      'modal--dropdown': modalTheme === MODAL_THEMES.DROPDOWN,
      'modal--window': modalTheme === MODAL_THEMES.WINDOW,
      'modal--window--auto':
        modalTheme === MODAL_THEMES.WINDOW && size === MODAL_WINDOW_SIZES.AUTO,
      'modal--window--medium':
        modalTheme === MODAL_THEMES.WINDOW &&
        size === MODAL_WINDOW_SIZES.MEDIUM,
      'modal--window--large':
        modalTheme === MODAL_THEMES.WINDOW && size === MODAL_WINDOW_SIZES.LARGE,
      'modal--bottom-sheet': modalTheme === MODAL_THEMES.BOTTOM_SHEET,
      'theme--dark': appTheme === AppTheme.DARK,
    });

    // NOTE: aria-labelledby prop is for Safari voice over functionality.
    // See https://stackoverflow.com/questions/35094631/how-do-i-get-voice-over-focus-to-my-modal#35139467

    return (
      <ModalOverlay
        onMouseDown={this.handleMouseDown}
        overlayModifier={overlayModifier}
        appTheme={appTheme}
      >
        <ModalContents
          tabIndex={0}
          ref={this.modalRef}
          className={modalClasses}
          onClick={(e) => e.stopPropagation()}
          style={style}
          aria-modal
          aria-labelledby="test-label"
        >
          <div className="modal__label" id="test-label">
            {this.props.name}
          </div>
          {this.props.children}
        </ModalContents>
      </ModalOverlay>
    );
  }
}

export const MODAL_THEMES = {
  FULLSCREEN: 'fullscreen',
  DROPDOWN: 'dropdown',
  WINDOW: 'window',
  BOTTOM_SHEET: 'bottomsheet',
};

export const MODAL_MODIFIERS = {
  HAS_FIXED_HEADER: 'has-fixed-header',
  HAS_NAV_AND_FIXED_HEADER_AND_CONTROLS:
    'has-nav-and-fixed-header-and-controls',
  HAS_FIXED_HEADER_AND_CONTROLS: 'has-fixed-header-and-controls',
  HAS_NO_HEADER: 'has-no-header',
  // NOTE: Applying just "fixed controls" is done on the ModalControls component
};

export const MODAL_WINDOW_SIZES = {
  AUTO: 'auto',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export const MODAL_OVERlAY_MODIFIERS = {
  LIGHT: 'light',
  NORMAL: 'normal',
  // NOTE: Dark theme variation gets applied by appTheme - no need to apply manually
};

Modal.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(MODAL_WINDOW_SIZES)),
  modalTheme: PropTypes.oneOf(Object.values(MODAL_THEMES)),
  modifier: PropTypes.oneOf(Object.values(MODAL_MODIFIERS)),
  overlayModifier: PropTypes.oneOf(Object.values(MODAL_OVERlAY_MODIFIERS)),
  appTheme: PropTypes.oneOf(Object.values(AppTheme)),
  children: PropTypes.node,
  position: PropTypes.object,
  dismissModal: PropTypes.func.isRequired,
  onDismiss: PropTypes.func,
  interceptDismiss: PropTypes.func,
  googleAdVisibility: PropTypes.number,
  isLargeScreen: PropTypes.bool,
  disableDismiss: PropTypes.bool,
  history: PropTypes.object.isRequired,
  className: PropTypes.string,
};

Modal.defaultProps = {
  modalTheme: MODAL_THEMES.WINDOW,
  size: MODAL_WINDOW_SIZES.SMALL,
  overlayModifier: MODAL_OVERlAY_MODIFIERS.NORMAL,
  children: [],
  position: null,
  googleAdVisibility: 0,
  disableDismiss: false,
  interceptDismiss: null,
  className: '',
  appTheme: AppTheme.DEFAULT,
  isLargeScreen: true,
  onDismiss: undefined,
};

export default Modal;
